<template>
  <transition appear name="slide">
    <div class="cookie cookie-notice" v-if="!cookies_accepted">
      <div class="cookie-wrap">
        <h2 class="d-none d-md-block">
          {{ $t("cookies_popup.title") }}
        </h2>
        <div class="cookie-content">
          <VMarkdownView
            class="markdown-content d-none d-md-block"
            :mdtext="$t('cookies_popup.content')"
          />
          <VMarkdownView
            class="markdown-content d-sm-none"
            :mdtext="$t('cookies_popup.content_short')"
          />
        </div>
        <b-button-group class="cookie-buttons">
          <b-link
            class="align-self-center pl-3 pr-3 font-italic"
            :to="{ name: 'cookies' }"
          >
            {{ $t("cookies_popup.button_more_info") }}
          </b-link>
          <b-btn class="mt-0" variant="primary" @click="acceptCookies">{{
            $t("cookies_popup.button_content")
          }}</b-btn>
        </b-button-group>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import VMarkdownView from "@/components/VMarkdownView";
import cookies_content_es_mx from "raw-loader!@/static/COOKIES_es-MX.txt";
import cookies_content_en_us from "raw-loader!@/static/COOKIES_en-US.txt";
import cookies_content_short_es_mx from "raw-loader!@/static/COOKIES_short_es-MX.txt";
import cookies_content_short_en_us from "raw-loader!@/static/COOKIES_short_en-US.txt";

export default {
  components: {
    VMarkdownView,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      cookies_accepted: state => state.siteconf.cookies_accepted,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        cookies_popup: {
          content: cookies_content_en_us,
          content_short: cookies_content_short_en_us,
          title: "Cookies notice",
          button_content: "I Accept",
          button_more_info: "More information",
        },
      },
    },
  },
  i18n: {
    messages: {
      "en-US": {
        cookies_popup: {
          title: "Cookies notice",
          content: cookies_content_en_us,
          content_short: cookies_content_short_en_us,
          button_content: "I Accept",
          button_more_info: "More information",
        },
      },
      "es-MX": {
        cookies_popup: {
          title: "Aviso de Cookies",
          content: cookies_content_es_mx,
          content_short: cookies_content_short_es_mx,
          button_content: "Acepto",
          button_more_info: "Más información",
        },
      },
    },
  },
  methods: {
    acceptCookies() {
      this.$store.commit("siteconf/MUTATE_ACCEPT_COOKIES", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie {
  bottom: 0;
  left: 0;
  right: 0;
  background: $gray-200;
  opacity: 1;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.cookie-wrap {
  width: 70%;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  @include media-breakpoint-up("sm") {
    flex-flow: column;
    display: flex;
  }
  @include media-breakpoint-down("sm") {
    width: 100%;
    text-align: center;
    .btn-group {
      display: inline-flex;
    }
  }
}

.cookie-content {
  .markdown-content {
    @include media-breakpoint-down("sm") {
      padding-top: 0rem !important;
    }
  }
}

.cookie-buttons {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  > * {
    margin: rem(5) 0;
  }

  button {
    border-radius: 0.6rem !important;
  }
}

.slide-enter,
.slide-leave-to {
  transform: translate(0px, 12.5em);
}
.slide-enter-to,
.slide-leave {
  transform: translate(0px, 0px);
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s ease-in;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
