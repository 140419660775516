<template>
  <div v-if="show">
    <v-plus-link
      @click="showModal"
      :icon="read_only ? 'info-circle' : 'plus-circle'"
    >
      <vue-element-loading :active="loading_uploaded_files" />
      {{ fileconfig.short_description }}
      <b-badge
        class="ml-2 mr-2"
        pill
        variant="info"
        v-if="filtered_files.length > 0"
      >
        {{ filtered_files.length }}
      </b-badge>
      <b-progress
        :value="upload_progress"
        variant="info"
        striped
        animated
        class="mb-2"
        v-if="upload_progress > -1"
      >
      </b-progress>
    </v-plus-link>

    <b-modal
      :ref="fileconfig.uuid"
      :title="fileconfig.short_description"
      size="xl"
      modal-class="modal-square-theme"
      centered
      @ok="handleOkModal($event)"
      :ok-title="$t('insurancerefund_file_loader.append_button')"
      :cancel-title="$t('insurancerefund_file_loader.cancel_button')"
      cancel-variant="secondary"
      :ok-variant="read_only ? 'hidden' : 'primary'"
    >
      <b-container fluid>
        <b-row>
          <b-col v-if="!read_only" cols="12" lg="6">
            <div v-if="!fileconfig.file_model_is_embedded_image">
              <h6 class="mt-4 text-uppercase font-weight-bold">
                {{ $t("insurancerefund_file_loader.download_model_title") }}
              </h6>
              <p class="mt-4">
                {{ $t("insurancerefund_file_loader.model_instructions") }}
              </p>
              <p>
                <strong>{{
                  $t("insurancerefund_file_loader.download_model_text")
                }}</strong>
              </p>
              <b-link
                :id="'link_model' + fileconfig.uuid"
                :href="fileconfig.file_model"
                target="_blank"
                class="btn btn-primary-inline"
              >
                {{ fileconfig.short_description }}
              </b-link>
              <b-tooltip show :target="'link_model' + fileconfig.uuid">
                {{ $t("insurancerefund_file_loader.download_model_text") }}
              </b-tooltip>
            </div>
            <div v-else>
              <div class="position-relative">
                <div class="position-absolute h-100 d-flex justify-content-center align-items-center">
                  <div>
                    <strong>
                      {{ fileconfig.embedded_image_over_text }}
                    </strong>
                  </div>
                </div>
                <div>
                  <b-img :src="fileconfig.file_model" fluid />
                </div>
              </div>
            </div>
            <h6 class="mt-5 mb-4 text-uppercase font-weight-bold">
              {{ $t("insurancerefund_file_loader.upload_file_title") }}
            </h6>
            <b-form class="mr-0 mr-lg-5">
              <b-form-group
                class="text-left"
                :label="$t('insurancerefund_file_loader.file_label')"
                label-for="file"
                :invalid-feedback="form_invalid_feedback.file"
                :state="form_state.file"
              >
                <div v-if="upload_progress > -1">
                  {{ $t("insurancerefund_file_loader.uploading_file") }}
                  <b-progress
                    :value="upload_progress"
                    variant="info"
                    striped
                    animated
                    class="mb-2"
                  >
                  </b-progress>
                </div>
                <b-form-file
                  v-model="form.file"
                  :ref="'fileinput' + fileconfig.uuid"
                  required
                  :state="Boolean(form.file) || form.file"
                  :placeholder="
                    $t('insurancerefund_file_loader.file_placeholder')
                  "
                  :browse-text="$t('insurancerefund_file_loader.browse_text')"
                >
                </b-form-file>
              </b-form-group>
              <b-button-group>
                <b-button
                  type="submit"
                  variant="primary-inline"
                  v-on:click="sendForm($event)"
                >
                  {{ $t("insurancerefund_file_loader.submit_button") }}
                </b-button>
              </b-button-group>
            </b-form>
          </b-col>
          <b-col cols="12" lg="6">
            <div
              v-if="
                insurancerefund_files ||
                  (static_files && static_files.length > 0)
              "
            >
              <vue-element-loading :active="loading_uploaded_files" />
              <h6 class="mt-4 text-uppercase font-weight-bold">
                {{ $t("insurancerefund_file_loader.uploaded_files") }}
              </h6>
              <b-table
                responsive
                class="mt-4"
                stacked="sm"
                hover
                show-progress
                :items="filtered_files"
                :fields="files_table_fields"
                head-variant="gray"
                outlined
              >
                <template v-slot:cell(file)="row" class="file">
                  <b-link
                    :id="'uploaded_file' + row.item.uuid"
                    :href="row.item.file"
                    target="_blank"
                    class=""
                  >
                    {{ row.item.file_original_filename }}
                  </b-link>
                </template>
                <template v-slot:cell(actions)="row" class="actions">
                  <b-link
                    @click="deleteFile(row.item.uuid)"
                    class="text-center table-link-action"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </b-link>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import VPlusLink from "@/components/VPlusLink";
import { mapState } from "vuex";

export default {
  name: "insurancerefund-file-loader",
  props: {
    fileconfig: Object,
    i_uuid: String,
    insurancerefund_uuid: String,
    pt_uuid: String,
    read_only: {
      default: false,
      type: Boolean,
    },
    staticfiles: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      show: true,
      loading_uploaded_files: false,
      upload_progress: -1,
      form: {
        file: null,
      },
      form_state: {
        file: undefined,
      },
      form_invalid_feedback: {
        file: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      insurancerefund_files: state =>
        state.insurancerefundfiles.insurancerefund_files,
    }),
    files_table_fields() {
      let items = [
        {
          key: "file",
          label: this.$t(
            "insurancerefund_file_loader.upload_table_head_filename"
          ),
        },
      ];

      if (!this.read_only) {
        items.push({
          key: "actions",
          label: this.$t(
            "insurancerefund_file_loader.upload_table_head_actions"
          ),
        });
      }
      return items;
    },
    filtered_files: function() {
      if (this.staticfiles) {
        return this.staticfiles;
      }
      let filtered = [];
      const vm = this;
      this.insurancerefund_files.forEach(function(item) {
        if (
          item.insurancerefund == vm.insurancerefund_uuid &&
          item.required_file_config == vm.fileconfig.uuid
        ) {
          filtered.push(item);
        }
      });
      return filtered;
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancerefund_file_loader: {
          none: "None",
          file_placeholder: "Load file",
          file_label: "File",
          upload_table_head_filename: "File name",
          upload_table_head_actions: "Actions",
          submit_button: "Upload file",
          cancel_button: "Back",
          download_model_text: "Download model here",
          download_model_title: "Download model",
          upload_file_title: "Upload file",
          append_button: "Append",
          model_instructions:
            "Download the model linked below, fill it and then upload again",
          uploading_file: "Uploading file, wait...",
          uploaded_files: "Uploaded files",
          browse_text: "Browse",
          error: {
            retrieving_insurancerefund_files: "Cannot load files",
            deleting_file: "Cannot delete file",
          },
        },
      },
    },
  },
  mounted: function() {
    this.reloadUploadedFiles();
  },
  methods: {
    reloadUploadedFiles() {
      if (this.staticfiles) {
        return;
      }
      this.loading_uploaded_files = true;
      this.$store
        .dispatch(
          "insurancerefundfiles/LOAD_PATIENT_INSURANCE_INSURANCEREFUND_FILES",
          {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            insurancerefund_uuid: this.insurancerefund_uuid,
          }
        )
        .then(
          () => {
            this.loading_uploaded_files = false;
          },
          err => {
            this.$sentry({ capture: err });
            this.loading_uploaded_files = false;
            this.mnotify_error({
              text: this.$t(
                "insurancerefund_file_loader.error.retrieving_insurancerefund_files"
              ),
            });
          }
        );
    },
    sendForm(evt) {
      evt.preventDefault();
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("required_file_config", this.fileconfig.uuid);
      if (this.form.file) {
        formData.append("file_original_filename", this.form.file.name);
      }
      this.$store
        .dispatch(
          "insurancerefundfiles/CREATE_PATIENT_INSURANCE_INSURANCEREFUND_FILE",
          {
            onUploadProgress: function(progressEvent) {
              this.upload_progress = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }.bind(this),
            data: formData,
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            insurancerefund_uuid: this.insurancerefund_uuid,
          }
        )
        .then(
          () => {
            this.upload_progress = -1;
            this.reloadUploadedFiles();
            this.hideModal();
            this.resetFileInput();
          },
          err => {
            this.upload_progress = -1;
            if (this.form.file == null) {
              this.form.file = false;
            }
            this.showResponseError(err);
            this.putFormFeedback(err.response);
          }
        );
    },
    deleteFile(ref_uuid) {
      this.loading_uploaded_files = true;
      this.$store
        .dispatch(
          "insurancerefundfiles/DELETE_PATIENT_INSURANCE_INSURANCEREFUND_FILE",
          {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            insurancerefund_uuid: this.insurancerefund_uuid,
            ref_uuid: ref_uuid,
          }
        )
        .then(
          () => {
            this.reloadUploadedFiles();
          },
          () => {
            this.loading_uploaded_files = false;
            this.mnotify_error({
              text: this.$t("insurancerefund_file_loader.error.deleting_file"),
            });
          }
        );
    },
    handleOkModal(evt) {
      this.sendForm(evt);
    },
    resetFileInput() {
      this.$refs["fileinput" + this.fileconfig.uuid].reset();
    },
    showModal() {
      this.$refs[this.fileconfig.uuid].show();
    },
    hideModal() {
      this.form.file = undefined;
      this.$refs[this.fileconfig.uuid].hide();
    },
  },
  components: {
    VPlusLink,
  },
};
</script>

<style scoped>
a.btn {
  text-decoration: none;
}
</style>
