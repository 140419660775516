import {
  translation_public_plain_list,
  // translation_private_plain_list,
  translation_available_list,
  translation_bestlangtag_list,
} from "@/lib/api-client.js";
// import { getAxiosConfig as axconf } from "@/lib/api-config.js";
import { getAxiosConfigNoAuth as axconf_noauth } from "@/lib/api-config.js";

import Vue from "vue";
const I18N_START_STATUS_NOT_LOADED = 0;
const I18N_START_STATUS_LOADING = 1;
const I18N_START_STATUS_LOADED = 2;
const I18N_START_STATUS_ERROR = 3;

var getLocalStorageItem = function(item_name, default_value = undefined) {
  try {
    return JSON.parse(localStorage.getItem(item_name)) || default_value;
  } catch (err) {
    return default_value;
  }
};

export default {
  namespaced: true,
  state: {
    i18n: undefined,
    available_langs: {},
    best_availabe_langtag: undefined,
    best_lang: undefined,
    best_langtag: "en",
    active_langtag: localStorage.getItem("active_langtag") || "und",
    active_lang: getLocalStorageItem("active_lang", {
      langtag: "und",
      direction: "ltr",
      name: "Language",
    }),
    i18n_start_status: I18N_START_STATUS_NOT_LOADED,
    messages: {},
  },
  actions: {
    SET_INITIAL_LANG_DATA: ({ commit }, payload) => {
      return new Promise(resolve => {
        commit("SET_I18N_START_STATUS", I18N_START_STATUS_LOADING);
        commit("MUTATE_BEST_AVAILABLE_LANGTAG", payload.langtag);
        commit("STORE_AVAILABLE_LANGS", payload.available_langs);
        if (payload.is_private_translation) {
          commit("STORE_PUBLIC_TRANSLATION_MESSAGES", payload.translations);
        } else {
          commit("STORE_PUBLIC_TRANSLATION_MESSAGES", payload.translations);
        }
        commit("SWITCH_ACTIVE_LANG", { langtag: payload.best_langtag });
        commit("SET_I18N_START_STATUS", I18N_START_STATUS_LOADED);

        resolve(true);
      });
    },
    LOAD_BEST_LANGTAG: ({ commit }) => {
      return new Promise((resolve, reject) => {
        translation_bestlangtag_list({
          $config: axconf_noauth({}, true),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("MUTATE_CALCULATE_BEST_AVAILABLE_LANGTAG", response.data);
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PUBLIC_LANGUAGE_TRANSLATIONS: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        var now = new Number(Date.now());
        var expire = new Number(
          parseInt(
            localStorage.getItem("public_locale_" + payload.langtag + "_expire")
          )
        );
        if (
          isNaN(expire) ||
          expire <= 0 ||
          now > expire ||
          process.env.NODE_ENV === "development"
        ) {
          translation_public_plain_list({
            $config: axconf_noauth(),
            role: "guest",
            roleUuid: "1",
            bcp47Langtag: payload.langtag,
            keyNamespaceText: "globalpdv",
          }).then(
            response => {
              commit("STORE_PUBLIC_TRANSLATION_MESSAGES", response.data);
              resolve(response.data);
            },
            err => {
              reject(err);
            }
          );
        } else {
          resolve(
            JSON.parse(localStorage.getItem("locale_" + payload.langtag))
          );
        }
      });
    },
    // LOAD_PRIVATE_LANGUAGE_TRANSLATIONS: ({ commit }, payload) => {
    //   return new Promise((resolve, reject) => {
    //     var now = new Number(Date.now());
    //     var expire = new Number(
    //       parseInt(
    //         localStorage.getItem(
    //           "private_locale_" + payload.langtag + "_expire"
    //         )
    //       )
    //     );
    //     if (
    //       isNaN(expire) ||
    //       expire <= 0 ||
    //       now > expire ||
    //       process.env.NODE_ENV === "development"
    //     ) {
    //       translation_private_plain_list({
    //         $config: axconf(),
    //         role: "guest",
    //         roleUuid: "1",
    //         bcp47Langtag: payload.langtag,
    //         keyNamespaceText: "globalpdv",
    //       }).then(
    //         response => {
    //           commit("STORE_PRIVATE_TRANSLATION_MESSAGES", response.data);
    //           resolve(response.data);
    //         },
    //         err => {
    //           reject(err);
    //         }
    //       );
    //     } else {
    //       resolve(
    //         JSON.parse(localStorage.getItem("locale_" + payload.langtag))
    //       );
    //     }
    //   });
    // },
    LOAD_AVAILABLE_LANGS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        translation_available_list({
          $config: axconf_noauth(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("STORE_AVAILABLE_LANGS", response.data.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    SET_I18N_START_STATUS: (state, status) => {
      Vue.set(state, "i18n_start_status", status);
    },
    SET_I18N_BACKEND: (state, i18n) => {
      Vue.set(state, "i18n", i18n);
    },
    // Scores explanation:
    // 100	These codes represent the same language, possibly after filling in values and normalizing.Norwegian Bokmål → Norwegian
    // 96 - 99	These codes indicate a minor regional difference.Australian English → British English
    // 91 - 95	These codes indicate a significant but unproblematic regional difference.American English → British English
    // 86 - 90	People who understand language A are likely, for linguistic or demographic reasons, to understand language B.Afrikaans → Dutch, Tamil → English
    // 81 - 85	These languages are related, but the difference may be problematic.Simplified Chinese → Traditional Chinese
    // 76 - 80	These languages are related by their macrolanguage.Moroccan Arabic → Egyptian Arabic
    // 51 - 75	These codes indicate a significant barrier to understanding.Japanese → Japanese in Hepburn romanization
    // 21 - 50	These codes are a poor match in multiple ways.Hong Kong Cantonese → mainland Mandarin Chinese
    // 1 - 20	These are different languages that use the same script.English → French, Arabic → Urdu
    MUTATE_CALCULATE_BEST_AVAILABLE_LANGTAG: (state, payload) => {
      var best_availabe_langtag = undefined;
      payload.forEach(function(item) {
        if (best_availabe_langtag) {
          return;
        }
        if (item["score"] >= 86) {
          best_availabe_langtag = item["langtag"];
          Vue.set(state, "best_availabe_langtag", item["langtag"]);
        }
      });
    },
    MUTATE_BEST_AVAILABLE_LANGTAG: (state, langtag) => {
      Vue.set(state, "best_availabe_langtag", langtag);
    },
    SWITCH_ACTIVE_LANG: (state, payload) => {
      var active_lang = state.available_langs[payload.langtag];

      if (!active_lang) {
        return;
      }

      localStorage.setItem("active_langtag", active_lang.langtag);
      localStorage.setItem("active_lang", JSON.stringify(active_lang));

      Vue.set(state, "active_langtag", active_lang.langtag);
      Vue.set(state, "active_lang", active_lang);

      var messages = JSON.parse(
        localStorage.getItem("locale_" + active_lang.langtag)
      );
      state.i18n.setLocaleMessage(active_lang.langtag, messages);
      state.i18n.locale = active_lang.langtag;
    },
    STORE_PUBLIC_TRANSLATION_MESSAGES: (state, payload) => {
      for (var langtag in payload) {
        localStorage.setItem(
          "public_locale_" + langtag,
          JSON.stringify(payload[langtag]["globalpdv"])
        );
        localStorage.setItem(
          "public_locale_" + langtag + "_expire",
          Date.now() + 10000000
        );
        if (localStorage.getItem("locale_" + langtag) == null) {
          localStorage.setItem(
            "locale_" + langtag + "_expire",
            Date.now() + 10000000 // about 3h
          );
          localStorage.setItem(
            "locale_" + langtag,
            JSON.stringify(payload[langtag]["globalpdv"])
          );
        }
      }
    },
    // STORE_PRIVATE_TRANSLATION_MESSAGES: (state, payload) => {
    //   for (var langtag in payload) {
    //     localStorage.setItem(
    //       "private_locale_" + langtag,
    //       JSON.stringify(payload[langtag]["globalpdv"])
    //     );
    //     localStorage.setItem(
    //       "private_locale_" + langtag + "_expire",
    //       Date.now() + 10000000
    //     );
    //     localStorage.setItem(
    //       "locale_" + langtag + "_expire",
    //       Date.now() + 10000000 // about 3h
    //     );
    //     localStorage.setItem(
    //       "locale_" + langtag,
    //       JSON.stringify(payload[langtag]["globalpdv"])
    //     );
    //   }
    // },
    STORE_AVAILABLE_LANGS: (state, available_langs) => {
      var _avail = {};
      // por algún motivo sin sentido, a pesar de que el request a avail langs
      // devuelve 200, esta variable se convierte en undefined. Esta variable
      // representa el atributo json results ({results: []}) que no debería
      // estar vacío en ningún momento. Se oculta el error ya que sólo se ha
      // visto en un usuario.
      // Enlace sentry para futuras investigaciones:
      // https://sentry.globalstorepharma.com/global-medika/globalpdv-com/issues/1803/?referrer=alert_email
      if (!available_langs) {
        return;
      }
      available_langs.forEach(function(item) {
        _avail[item.langtag] = item;
      });
      Vue.set(state, "available_langs", _avail);
    },
  },
  getters: {
    isI18nLoaded: state => {
      return state.i18n_start_status == I18N_START_STATUS_LOADED;
    },
    isI18nError: state => {
      return state.i18n_start_status == I18N_START_STATUS_ERROR;
    },
    activeLangtag: state => {
      return state.active_langtag;
    },
    availableLangs: state => {
      return state.available_langs;
    },
    bestLangtag: state => {
      return state.best_langtag;
    },
    bestAvailableLangtag: state => {
      if (state.best_availabe_langtag) {
        return state.best_availabe_langtag;
      }

      if (!state.available_langs) {
        return undefined;
      }

      var langtag = undefined;
      for (var key in state.available_langs) {
        if (state.available_langs[key]["default"]) {
          langtag = state.available_langs[key]["langtag"];
        }
      }
      return langtag;
    },
    switchedOrBestLang: state => {
      if (localStorage.getItem("active_lang")) {
        return JSON.parse(localStorage.getItem("active_lang"));
      }
      if (state.best_lang) {
        return state.best_lang;
      }
      return state.active_lang;
    },
    getPublicLangtagMessages: langtag => {
      return JSON.parse(localStorage.getItem("public_locale_" + langtag));
    },
    getPrivateLangtagMessages: langtag => {
      return JSON.parse(localStorage.getItem("private_locale_" + langtag));
    },
    getLangtagMessages: langtag => {
      return JSON.parse(localStorage.getItem("locale_" + langtag));
    },
    // isPublicLangtagLocalStorageExpired: langtag => {
    //   var now = new Number(Date.now());
    //   var expire = new Number(
    //     parseInt(localStorage.getItem("public_locale_" + langtag + "_expire"))
    //   );
    //   if (isNaN(expire) || expire <= 0 || now > expire) {
    //     return true;
    //   }
    //   return false;
    // },
    // isPrivateLangtagLocalStorageExpired: langtag => {
    //   var now = new Number(Date.now());
    //   var expire = new Number(
    //     parseInt(localStorage.getItem("private_locale_" + langtag + "_expire"))
    //   );
    //   if (isNaN(expire) || expire <= 0 || now > expire) {
    //     return true;
    //   }
    //   return false;
    // },
    // isLangtagLocalStorageExpired: langtag => {
    //   var now = new Number(Date.now());
    //   var expire = new Number(
    //     parseInt(localStorage.getItem("locale_" + langtag + "_expire"))
    //   );
    //   if (isNaN(expire) || expire <= 0 || now > expire) {
    //     return true;
    //   }
    //   return false;
    // },
  },
};
