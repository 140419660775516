<template>
  <div>
    <vue-element-loading :active="isBusy" />
    <b-container v-show="!show_selected" class="pl-0 pr-0 pb-5">
      <b-row no-gutters>
        <b-col cols="12" xl="4" lg="4" sm="4">
          <b-form>
            <VBootstrapSearchInput
              v-model="search_term"
              :placeholder="$t('recipe_drug_selector.search_placeholder')"
              v-on:submit="onSearchSubmit($event)"
            ></VBootstrapSearchInput>
          </b-form>
        </b-col>
        <b-col cols="12" xl="4" lg="4" sm="4"></b-col>
        <b-col cols="12" xl="4" lg="4" sm="4">
          <div v-if="show_provider_filter && provider_options">
            <b-form-group
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              :label="$t('recipe_drug_selector.provider_label')"
              label-for="provider_selector-firstname"
            >
              <b-form-select
                id="provider_selector"
                v-model="selected_provider"
                :options="provider_options"
              ></b-form-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-table
      id="drugs-table"
      responsive
      stacked="sm"
      :busy.sync="isBusy"
      :items="tableProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      head-variant="gray"
      outlined
      v-show="!show_selected"
    >
      <template v-slot:cell(default_description)="row">
        <div
          v-bind:class="{
            clickable:
              row.item.avail_prices.length > 1 && !row.value.hide_caret,
          }"
          @click.stop="toggleShowAllPrices(row)"
        >
          {{ row.value.description }}
          <font-awesome-icon
            v-if="row.item.avail_prices.length > 1 && !row.value.hide_caret"
            icon="caret-down"
          />
        </div>
      </template>
      <template v-slot:cell(avail_prices)="row">
        <div
          v-for="(price, index) in row.value"
          :key="index"
          class="text-left pb-1 selectable"
        >
          <b-button
            size="sm price-button"
            v-bind:class="{ 'd-none': index > 0 && !price.show }"
            variant="primary"
            @click="selectProduct(row.item, price)"
          >
            {{
              $formatMoney(
                price.all_included_price,
                price.all_included_price_currency
              )
            }}
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col md="12">
        <b-pagination
          align="center"
          v-show="!show_selected"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="float-md-right"
        />
      </b-col>
    </b-row>
    <b-row v-show="show_selected" class="pb-4">
      <b-col>
        <h6 class="pb-2 pt-2 font-weight-bold text-uppercase">
          {{ $t("recipe_drug_selector.selected_drug") }}
        </h6>
      </b-col>
    </b-row>
    <b-row v-if="show_selected">
      <!-- SELECTED DRUG INFO -->
      <b-col cols="12" lg="9" md="12" sm="12">
        <b-container class="pl-0">
          <b-row no-gutters>
            <b-col lg="2" class="pb-4">
              <div class="d-inline-block">
                <PictureFallback
                  alt="Thumbnail"
                  thumbnail
                  :picture="selected_drug.picture"
                />
              </div>
            </b-col>
            <b-col lg="10" class="pl-lg-3 pt-2">
              <div class="pb-1 selectable">
                {{ $t("recipe_drug_selector.medicament") }}:
                <strong>{{ selected_drug.default_description }}</strong>
              </div>
              <div class="pb-3 selectable">
                {{ $t("recipe_drug_selector.laboratory") }}:
                <strong>{{ selected_drug.laboratory_name }}</strong>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>

      <b-col cols="12" lg="3" md="12" sm="12">
        <b-form-group
          label-cols="12"
          label-cols-sm="12"
          label-cols-md="12"
          label-cols-lg="4"
          label-cols-xl="4"
          class="text-left"
          :label="$t('recipe_drug_selector.quantity_label')"
          label-for="quantity"
        >
          <b-form-input
            id="quantity"
            class=""
            type="number"
            v-model.trim="form.quantity"
            required
            :state="form_state.quantity"
            :placeholder="$t('recipe_drug_selector.quantity_placeholder')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VBootstrapSearchInput from "@/components/VBootstrapSearchInput";
import PictureFallback from "@/components/PictureFallback";
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  name: "insurancebridge-recipe-drug-selector",
  props: {
    pt_uuid: String,
    i_uuid: String,
    show_provider_filter: {
      type: Boolean,
      default: false,
    },
    show_selected: Boolean,
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      selected_provider: undefined,
      search_term: "",
      form: {
        batch: "L0000",
        expiry_date: new Date(),
        quantity: "1",
      },
      form_state: {
        batch: undefined,
        expiry_date: undefined,
        quantity: undefined,
      },
      form_invalid_feedback: {
        batch: undefined,
        expiry_date: undefined,
        quantity: undefined,
      },
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      selected_drug: undefined,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "ean",
          label: this.$t("recipe_drug_selector.headtable_ean"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "default_description",
          label: this.$t("recipe_drug_selector.headtable_drug_name"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value) => {
            if (!this.selected_provider) {
              return {
                description: value,
                hide_caret: false,
              };
            }
            return {
              description: value,
              hide_caret: true,
            };
          },
        },
        {
          key: "avail_prices",
          label: this.$t("recipe_drug_selector.headtable_price"),
          formatter: (value) => {
            if (!this.selected_provider) {
              return value;
            }
            return value.filter((price) => {
              return String(price.manager) == String(this.selected_provider);
            });
          },
        },
      ];
    },
    provider_options() {
      if (!this.insurance_providers_list) {
        return undefined;
      }
      if (!this.insurance_providers_list[this.i_uuid]) {
        return undefined;
      }
      var options = [];
      this.insurance_providers_list[this.i_uuid].forEach((pharmacy_obj) => {
        options.push({
          value: pharmacy_obj.uuid,
          text: pharmacy_obj.public_name,
        });
      });
      return options;
    },
    ...mapState({
      insurance_providers_list: (state) =>
        state.organization.insurance_providers_list,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        recipe_drug_selector: {
          headtable_ean: "EAN",
          headtable_drug_name: "Name",
          headtable_price: "Price",
          medicament: "Medicament",
          laboratory: "Laboratory",
          add: "Add",
          batch_label: "Batch",
          batch_placeholder: "Batch",
          expiry_date_label: "Expiry date",
          expiry_date_placeholder: "Expiry date",
          quantity_label: "Quantity",
          quantity_placeholder: "Quantity",
          search_placeholder: "Search pharmacy drug...",
          show_more_prices: "Show more prices",
          provider_label: "Provider",
          selected_drug: "Selected drug",
        },
      },
    },
  },
  watch: {
    show_provider_filter: function (newv) {
      if (newv) {
        this.loadInsuranceProvidersList();
      } else {
        this.selected_provider = undefined;
        // this.onProviderChange();
      }
    },
    selected_provider: function () {
      this.onProviderChange();
    },
  },
  mounted: function () {
    this.loadInsuranceProvidersList();
  },
  methods: {
    ...mapActions({
      INSURANCE_PROVIDERS_LIST: "organization/INSURANCE_PROVIDERS_LIST",
    }),
    onProviderChange() {
      this.currentPage = 1;
      this.$root.$emit("bv::refresh::table", "drugs-table");
    },
    loadInsuranceProvidersList() {
      if (
        !this.insurance_providers_list ||
        !this.insurance_providers_list[this.i_uuid]
      ) {
        this.INSURANCE_PROVIDERS_LIST({ i_uuid: this.i_uuid });
      }
    },
    toggleShowAllPrices(row) {
      row.item.avail_prices.forEach((element) => {
        if (element.show) {
          this.$set(element, "show", false);
        } else {
          this.$set(element, "show", true);
        }
      });
    },
    cleanSelection() {
      this.selected_drug = undefined;
      this.form.batch = "L";
      this.form.expiry_date = "2018-11-11T11:11";
      this.form.quantity = 1;
    },
    selectProduct(product, price) {
      var selected_drug = Object.assign({}, product);
      var _price = Object.assign({}, price);
      selected_drug.price = _price;
      this.selected_drug = selected_drug;

      var _selected_product = this.selected_drug;
      delete _selected_product.avail_prices;
      this.$emit("product:selected", {
        product: _selected_product,
        extra_fields: this.form,
      });
    },
    onSearchSubmit(evt) {
      evt.preventDefault();
      this.$root.$emit("bv::refresh::table", "drugs-table");
    },
    // tableProvider (ctx) {
    tableProvider() {
      try {
        return this.$store
          .dispatch("drugs/REQUEST_PATIENT_INSURANCE_DRUGS_IN", {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            per_page: this.perPage,
            current_page: this.currentPage,
            search: this.search_term,
            organization_uuid: this.selected_provider,
          })
          .then(
            (response) => {
              const items = response.data.results;
              // el backend nos ordena los precios de menor a mayor, pero por
              // precaución también los reordenamos en el front
              items.forEach((item) => {
                item.avail_prices.sort(function (a, b) {
                  if (
                    new Number(a.system_all_included_price) >
                    new Number(b.system_all_included_price)
                  ) {
                    return 1;
                  }
                  if (
                    new Number(a.system_all_included_price) <
                    new Number(b.system_all_included_price)
                  ) {
                    return -1;
                  }
                  return 0;
                });
              });
              this.isBusy = false;
              this.totalRows = response.data.count;
              return items;
            },
            (err) => {
              this.$sentry({ capture: err });
              this.isBusy = false;
              return [];
            }
          );
      } catch (err) {
        this.$sentry({ capture: err });
        this.isBusy = false;
        return [];
      }
    },
  },
  components: {
    VBootstrapSearchInput,
    PictureFallback,
  },
};
</script>

<style lang="scss" scoped>
#drugs-table /deep/ thead tr th:last-child {
  width: 10%;
}

.price-button {
  width: 100%;
}
@include media-breakpoint-down(sm) {
  /deep/ table.b-table > tbody > tr > td {
    grid-template-columns: 30% auto !important;

    &::before {
      text-align: left !important;
    }
  }
}
</style>
