<template>
  <div>
    <vue-element-loading :active="loading_payments" />
    <VTableToolbarFilter
      :is_filtered="is_filtered"
      :filters_count="filters_count"
      v-on:showFilters="showFilters=!showFilters"
      v-on:clearFilters="clearFilters"
      v-on:applyFilter="applyFilter"
    />
    <b-table
      id="insurancerisk-table-list"
      ref="insurancerisk_table"
      responsive
      outlined
      head-variant="gray"
      stacked="md"
      :busy.sync="isBusy"
      :items="tableProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      v-show="!selected_drug"
    >

      <template v-slot:cell(documentation)="data">
        <div v-if="data.item.invoice_request">
          <div v-if="getInvoiceDocs(data.item.documentation.documents)">
            <div
              v-for="(doc, index) in getInvoiceDocs(data.item.documentation.documents)"
              :key="index"
              class="pull-left"
            >
              <a v-if="doc.file_type==5" :href="doc.file">| PDF</a>
              <a v-if="doc.file_type==6" :href="doc.file">| XML</a>
            </div>
          </div>
          <div v-else>
            <b-badge
              class="p-2"
              variant="success"
            >{{ $t("insurancerisk_table_list.invoice_requested") }}</b-badge>
          </div>
        </div>
        <div v-else>
          <b-button
            size="sm"
            variant="primary-inline"
            :id="'invoice_popover' + data.item.uuid"
            @click="openInvoicePopOver('invoice_popover' + data.item.uuid)"
          >
            {{ $t("insurancerisk_table_list.request_invoice") }}
          </b-button>
          <b-popover
            :ref="'invoice_popover' + data.item.uuid"
            :target="'invoice_popover' + data.item.uuid"
          >
            <template v-slot:title>
              <b-button
                @click="closeInvoicePopOver('invoice_popover' + data.item.uuid)"
                class="close"
                variant="close"
                aria-label="Close"
              >
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              {{ $t("insurancerisk_table_list.request_invoice") }}
            </template>
            <div v-if="!data.item.payment.payed">
              <h5 class="mb-2">
                {{ $t("insurancerisk_table_list.form_invoice_not_payed_title") }}
              </h5>
            </div>
            <div v-else>
              <h3 class="mb-2">
                {{ $t("insurancerisk_table_list.form_invoice_title") }}
              </h3>
              <p>
                {{ $t("insurancerisk_table_list.form_invoice_subtext") }}
              </p>
              <b-form-group
                :label="$t('insurancerisk_table_list.form_invoice_name')"
                label-for="invoice_name"
                label-cols="12"
                :state="form_state.name"
                class="mb-1"
                :invalid-feedback="form_invalid_feedback.name"
              >
                <b-form-input
                  ref="invoice_name"
                  id="invoice_name"
                  v-model="form.name"
                  :state="form_state.name"
                ></b-form-input>
              </b-form-group>
              {{ form_invalid_feedback.invoice_rfc }}
              <b-form-group
                :label="$t('insurancerisk_table_list.form_invoice_rfc')"
                label-for="invoice_rfc"
                label-cols="12"
                :state="form_state.rfc"
                class="mb-1"
                :invalid-feedback="form_invalid_feedback.rfc"
              >
                <b-form-input
                  ref="invoice_rfc"
                  id="invoice_rfc"
                  v-model="form.rfc"
                  :state="form_state.rfc"
                ></b-form-input>
              </b-form-group>
              <div class="m-5 d-flex justify-content-between">
                <b-button
                  type="submit"
                  variant="primary"
                  @click="onInvoiceFormSubmit(data.item)"
                >
                  {{ $t("insurancerisk_table_list.form_button_ok") }}
                </b-button>
              </div>
            </div>
          </b-popover>
        </div>
      </template>


      <template v-if="showFilters" slot="top-row" slot-scope="{ fields }">
        <td v-for="field in fields" :key="field.key">
          <b-form @submit.prevent="applyFilter">
            <VBootstrapFormGroup
              :type="filters[field.key].type ? filters[field.key].type : 'input'"
              label-sr-only
              stack-form-group
              v-if="filters && filters[field.key]"
              :name="field.key"
              :options="filters[field.key].options"
              :placeholder="field.label"
              v-model.trim="filters[field.key]['model']"
              v-on:input="if (filters[field.key].type == 'select') {applyFilter();}"
            >
            </VBootstrapFormGroup>
          </b-form>

        </td>
      </template>

      <template v-slot:cell(payment_reference)="data">
        <div
          v-for="(transaction, index) in data.item.payment.transactions"
          :key="index"
        >
          <div :id="'intention' + transaction.intention.uuid">
            {{ transaction.intention.uuid.split('-')[0] }}
          </div>
          <b-popover
            :target="'intention' + transaction.intention.uuid"
            triggers="hover"
            placement="top"
          >
            <div class="selectable">
              {{ transaction.intention.uuid }}
            </div>
          </b-popover>
        </div>
      </template>

      <template v-slot:cell(payment_method)="data">
        <div v-if="data.item.payment">
          <div
            v-for="(payment_transaction, index) in data.item.payment.transactions"
            :key="index"
          >
            {{ translatePaymentMethod(payment_transaction.payment_method.payment_method) }}
            <font-awesome-icon
              v-if="payment_transaction.payment_method.payment_method == 4"
              class="clickable"
              :id="'payment_risk_' + data.item.uuid"
              icon="info-circle"
            />
            <div v-if="payment_transaction.payment_method.payment_method == 4">
              <b-popover
                :target="'payment_risk_' + data.item.uuid"
                :title="$t('insurancerisk_table_list.service_bank_wire_title')"
              >
                <div>
                  <p v-if="payment_transaction.service_bank_wire_bank_name" class="mt-1 mb-1">
                    <strong>
                      {{ $t("insurancerisk_table_list.service_bank_wire_bank_name") }}:
                    </strong>
                    {{ payment_transaction.service_bank_wire_bank_name }}
                  </p>
                  <p v-if="payment_transaction.service_bank_wire_account_name" class="mt-1 mb-1">
                    <strong>
                      {{
                        $t("insurancerisk_table_list.service_bank_wire_account_name")
                      }}:
                    </strong>
                    {{ payment_transaction.service_bank_wire_account_name }}
                  </p>
                  <p v-if="payment_transaction.service_bank_wire_number" class="mt-1 mb-1">
                    <strong>
                      {{ $t("insurancerisk_table_list.service_bank_wire_number") }}:
                    </strong>
                    {{ payment_transaction.service_bank_wire_number }}
                  </p>
                  <p v-if="payment_transaction.service_bank_wire_IBWT" class="mt-1 mb-1">
                    <strong>
                      {{ $t("insurancerisk_table_list.service_bank_wire_IBWT") }}:
                    </strong>
                    {{ payment_transaction.service_bank_wire_IBWT }}
                  </p>
                  <p v-if="payment_transaction.service_bank_wire_SWIFT" class="mt-1 mb-1">
                    <strong>
                      {{ $t("insurancerisk_table_list.service_bank_wire_SWIFT") }}:
                    </strong>
                    {{ payment_transaction.service_bank_wire_SWIFT }}
                  </p>
                  <p v-if="payment_transaction.service_bank_wire_CHIPS" class="mt-1 mb-1">
                    <strong>
                      {{ $t("insurancerisk_table_list.service_bank_wire_CHIPS") }}:
                    </strong>
                    {{ payment_transaction.service_bank_wire_CHIPS }}
                  </p>
                  <div class="legal mt-3 text-justify small">
                    {{ $t("insurancerisk_table_list.service_bank_legal_text") }}
                  </div>
                </div>
              </b-popover>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:cell(payment_status)="data">
        <b-badge
          v-if="data.item.payment.payed"
          class="p-2"
          variant="success"
        >{{ $t("insurancerisk_table_list.payment_status_payed") }}</b-badge>
        <b-badge
          v-else-if="data.item.payment.referenced"
          class="p-2"
          variant="primary"
        >{{ $t("insurancerisk_table_list.payment_status_referenced") }}</b-badge>
        <b-badge
          v-else
          class="p-2"
          variant="warning"
        >
          {{ $t("insurancerisk_table_list.payment_status_pending") }}
        </b-badge>
      </template>

      <template v-slot:cell(patient)="data">
        <div v-if="data.item && data.item.patient_address">
          {{ data.item.patient_address.firstname }}
        </div>
      </template>

      <template v-slot:cell(insured_name)="data">
        <div>
          {{ data.item.insured_name }}
        </div>
        <div v-if="data.item.is_beneficiary">
          <small>
            {{ $t("insurancerisk_table_list.is_beneficiary") }}
          </small>
        </div>
      </template>

      <template v-slot:cell(drugs)="data">
        <div
          class="btn"
          :id="'insurancerefund-invoicedrugs-popover' + data.item.uuid"
          v-if="parseRefundDrugs(data.item)"
        >
          <font-awesome-icon icon="eye" />
        </div>
        <b-popover
          v-if="parseRefundDrugs(data.item)"
          triggers="hover focus click"
          :target="'insurancerefund-invoicedrugs-popover' + data.item.uuid"
          :placement="'auto'"
        >
          <div
            v-for="(drug, index) in parseRefundDrugs(data.item)"
            :key="index"
          >
            <small>{{ drug.quantity }} x</small>
            {{ drug.default_description }}
          </div>
        </b-popover>
      </template>
    </b-table>
    <b-row>
      <b-col md="12">
        <b-pagination
          align="center"
          v-show="!selected_drug"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="float-md-right"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import VTableToolbarFilter from "@/components/VTableToolbarFilter";

// Esto tiene sentido porque los índices de los payment aquí están ordenados de
// modo que coincidan con el ID de payment method, 0, 1, 2, 3...
const payment_method_translator = [
  "insurancerisk_table_list.payment_method_none",
  "insurancerisk_table_list.payment_method_ewallet_paypal",
  "insurancerisk_table_list.payment_method_ewallet_openpay",
  "insurancerisk_table_list.payment_method_debit_credit_card",
  "insurancerisk_table_list.payment_method_bank_transfer",
  "insurancerisk_table_list.payment_method_direct_debit",
  "insurancerisk_table_list.payment_method_voucher",
  "insurancerisk_table_list.payment_method_cash",
  "insurancerisk_table_list.payment_method_cash_paynet",
  "insurancerisk_table_list.payment_method_webpay",
];

export default {
  name: "insurance-risk-payment-table-list",
  components: { VBootstrapFormGroup, VTableToolbarFilter },
  props: {},
  // computed: {
  //   ...mapGetters("organization", ["getInsuranceDataByUUID"]),
  //   ...mapGetters("naturalperson", ["getPatientDataByUUID"])
  // },
  data() {
    return {
      search_term: "",
      datepicker_options: {
        format: "YYYY-MM-DD",
      },
      showFilters: false,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      sortBy: "created_date",
      sortDesc: true,
      totalRows: 0,
      loading_payments: true,
      selected_drug: undefined,
      is_filtered: false,
      form: {
        invoice_name: "",
        invoice_rfc: "",
      },
      form_state: {
        invoice_name: undefined,
        invoice_rfc: undefined,
      },
      form_invalid_feedback: {
        invoice_name: undefined,
        invoice_rfc: undefined,
      },
      filters: {
        insured_name: {
          filter_key: "insuredName",
          model: null,
        },
        policy_num: {
          filter_key: "policyNum",
          model: null,
        },
        insurance_name: {
          filter_key: "insuranceName",
          model: null,
        },
        under: {
          type: "select",
          options: [
            {
              value: null,
              text: "",
            },
            {
              value: "1",
              text: this.$t("insurancerisk_table_list.filter_under_deductible"),
            },
            {
              value: "2",
              text: this.$t(
                "insurancerisk_table_list.filter_under_coinsurance"
              ),
            },
            {
              value: "3",
              text: this.$t(
                "insurancerisk_table_list.filter_under_ded_and_coi"
              ),
            },
          ],
          model: null,
        },
      },
    };
  },
  // computed: {
  //   ...mapState({
  //     country_fields: state => state.siteconf.country_fields,
  //   }),
  // },
  computed: {
    filters_count() {
      let filters_count = 0;
      this._.forIn(this.filters, function (item) {
        if (item.model) {
          filters_count++;
        }
      });
      return filters_count;
    },
    fields() {
      return [
        {
          key: "created_date",
          label: this.$t("insurancerisk_table_list.headtable_date"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value) => {
            return this.i18nFormatDate(value);
          },
          sortable: true,
        },
        {
          key: "insured_name",
          label: this.$t("insurancerisk_table_list.headtable_insured_name"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
        },
        {
          key: "policy_num",
          label: this.$t("insurancerisk_table_list.headtable_policy_num"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
        },
        {
          key: "insurance_name",
          label: this.$t("insurancerisk_table_list.headtable_insurance"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            if (
              !item.insurance_organization ||
              !item.insurance_organization.public_name
            ) {
              return "";
            }
            return item.insurance_organization.public_name;
          },
        },
        {
          key: "payment_method",
          label: this.$t("insurancerisk_table_list.headtable_payment_method"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
        },
        {
          key: "under",
          label: this.$t("insurancerisk_table_list.headtable_under"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
          formatter: (value, key, item) => {
            if (item.has_deductible && item.has_coinsurance) {
              return (
                this.$t("insurancerisk_table_list.under_deductible") +
                "/" +
                this.$t("insurancerisk_table_list.under_coinsurance")
              );
            }
            if (item.has_deductible) {
              return this.$t("insurancerisk_table_list.under_deductible");
            }
            if (item.has_coinsurance) {
              return this.$t("insurancerisk_table_list.under_coinsurance");
            }
          },
        },

        // referencia de pago de webpay
        {
          key: "service_auth_code",
          label: this.$t(
            "insurancerisk_table_list.headtable_service_auth_code"
          ),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
          formatter: (value, key, item) => {
            let data = "";
            item.payment.transactions.forEach((element) => {
              if (element.intention.service_auth_code) {
                data = data + element.intention.service_auth_code;
              }
            });
            return data;
          },
        },
        // uuid del payment intention
        {
          key: "payment_reference",
          label: this.$t(
            "insurancerisk_table_list.headtable_payment_reference"
          ),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
          formatter: (value, key, item) => {
            let data = "";
            item.payment.transactions.forEach((element) => {
              data = data + element.intention.uuid;
            });
            return data;
          },
        },
        {
          key: "payment_status",
          label: this.$t("insurancerisk_table_list.headtable_payment_status"),
          class: "selectable d-md-none d-lg-table-cell text-center",
          thClass: "unselectable d-md-none d-lg-table-cell text-center",
        },
        {
          key: "documentation",
          label: this.$t("insurancerisk_table_list.headtable_invoice"),
          class: "selectable text-center",
          thClass: "unselectable text-center",
        },
        {
          key: "total",
          label: this.$t("insurancerisk_table_list.headtable_total"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
          formatter: (value, key, item) => {
            let total = this.$formatMoney(
              item.amount.total,
              item.amount.total_currency
            );
            return total;
            // return "--";
          },
        },
        // {
        //   key: "actions",
        //   label: "",
        //   thClass: this.show_action_column ? "" : "d-none",
        //   tdClass: this.show_action_column ? "" : "d-none",
        // },
      ];
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancerisk_table_list: {
          headtable_date: "Date",
          headtable_insured_name: "Insured name",
          headtable_policy_num: "Policy number",
          headtable_insurance: "Insurance",
          headtable_payment_method: "Payment method",
          headtable_invoice: "Invoice",
          payment_method_none: "None",
          payment_method_ewallet_paypal: "Ewallet paypal",
          payment_method_ewallet_openpay: "Ewallet openpay",
          payment_method_debit_credit_card: "Debit credit card",
          payment_method_bank_transfer: "Bank transfer",
          payment_method_direct_debit: "Direct debit",
          payment_method_voucher: "Voucher",
          payment_method_cash: "Cash",
          payment_method_cash_paynet: "Cash paynet",
          payment_method_webpay: "WebPay Plus",
          headtable_under: "Under",
          under_deductible: "Deductible",
          under_coinsurance: "Coinsurance",
          headtable_service_auth_code: "Bank Auth. Number",
          headtable_payment_reference: "Payment Reference",
          headtable_total: "Amount",
          headtable_payment_status: "Payment status",
          is_beneficiary: "Beneficiary",
          payment_status_pending: "Pending",
          payment_status_referenced: "Referenced",
          payment_status_payed: "Payed",
          service_bank_wire_number: "Bank wire number",
          service_bank_wire_bank_name: "Bank name",
          service_bank_wire_account_name: "Account name",
          service_bank_wire_IBWT: "Bank wire IBWT",
          service_bank_wire_SWIFT: "Bank wire SWIFT",
          service_bank_wire_CHIPS: "Bank wire CHIPS",
          service_bank_legal_text:
            "Important: If you select this option, you will have a period of 72 hours from this moment to make your Deposit or Transfer, if you do not do so in this period of time, we will return your products for sale and your order will be canceled. The shipment of your product will be made from the confirmation of the payment of your order.",
          service_bank_wire_title: "Bank data",
          filter_under_deductible: "Deductible",
          filter_under_coinsurance: "Coinsurance",
          filter_under_ded_and_coi: "Deductible/Coinsurance",
          request_invoice: "Invoice",
          form_invoice_name: "Name",
          form_invoice_rfc: "RFC",
          form_invoice_title: "Request your invoice",
          form_invoice_not_payed_title:
            "The invoice cannot be requested until the payment is done",
          form_button_cancel: "Cancel",
          form_button_ok: "Request",
          form_invoice_subtext: "Please fill the form and click on Request.",
          invoice_requested: "Invoice requested",
          error: {
            cannot_load_payments: "Cannot load payments",
            cannot_request_invoice: "Cannot request invoice",
          },
        },
      },
    },
  },
  methods: {
    getInvoiceDocs(documents) {
      const result = documents.filter(
        (doc) => doc.file_type == 5 || doc.file_type == 6
      );
      if (result[0]) {
        return result;
      }
      return undefined;
    },
    openInvoicePopOver(popover_uuid) {
      this.$refs[popover_uuid].$emit("open");
    },
    closeInvoicePopOver(popover_uuid) {
      this.$refs[popover_uuid].$emit("close");
    },
    onInvoiceFormSubmit(item) {
      this.$store
        .dispatch("drugtransaction/INSURANCERISKPAYMENT_REQUEST_INVOICE", {
          insuranceriskpayment_uuid: item.uuid,
          data: this.form,
        })
        .then(
          (res) => {
            item.invoice_request = res.data.uuid;
          },
          (err) => {
            this.mnotify_error({
              text: this.$t(
                "insurancerisk_table_list.error.cannot_request_invoice"
              ),
            });
            this.putFormFeedback(err.response);
          }
        );
    },
    clearFilters() {
      this._.forIn(this.filters, function (item) {
        item.model = null;
      });
      this.applyFilter();
    },
    applyFilter() {
      if (this.filters_count > 0) {
        this.is_filtered = true;
      } else {
        this.is_filtered = false;
      }
      this.$refs.insurancerisk_table.refresh();
    },
    translatePaymentMethod(method) {
      if (!payment_method_translator[method]) {
        return "Unknown";
      }
      return this.$t(payment_method_translator[method]);
    },
    tableProvider() {
      let filters = {};
      this._.forIn(this.filters, function (item, key) {
        if (!item.model) {
          return;
        }
        let filter_key = key;
        if (item.filter_key) {
          filter_key = item.filter_key;
        }
        filters[filter_key] = item.model;
      });

      if (this.sortBy) {
        filters["ordering"] = this.sortBy;
        if (this.sortDesc) {
          filters["ordering"] = "-" + filters["ordering"];
        }
      }

      try {
        return this.$store
          .dispatch(
            "drugtransaction/DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_LIST",
            {
              limit: this.perPage,
              current_page: this.currentPage,
              ...filters,
            }
          )
          .then(
            (response) => {
              const items = response.data.results;
              this.isBusy = false;
              this.loading_payments = false;
              this.totalRows = response.data.count;
              return items;
            },
            (err) => {
              this.$sentry({ capture: err });
              this.mnotify_error({
                text: this.$t(
                  "insurancerisk_table_list.error.cannot_load_payments"
                ),
              });
              this.isBusy = false;
              this.loading_payments = false;
              return [];
            }
          );
      } catch (e) {
        this.$sentry({ capture: e });
        this.mnotify_error({
          text: this.$t("insurancerisk_table_list.error.cannot_load_payments"),
        });
        this.isBusy = false;
        this.loading_payments = false;
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#insurancerisk-table-list /deep/ thead th:last-child {
  width: 10%;
}

@include media-breakpoint-down(sm) {
  /deep/ table.b-table > tbody > tr > td {
    grid-template-columns: 30% auto !important;

    &::before {
      text-align: left !important;
    }
  }
}
</style>
