<template>
  <VLayout upline :title="$t('terms_conditions.title')">
    <VMarkdownView :mdtext="$t('terms_conditions.legal_text')" class="text-justify" />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VMarkdownView from "@/components/VMarkdownView";
import terms_conditions_es_mx from "raw-loader!@/static/TERMS_CONDITIONS_es-MX.txt";
import terms_conditions_en_us from "raw-loader!@/static/TERMS_CONDITIONS_en-US.txt";

export default {
  i18n: {
    messages: {
      "en-US": {
        terms_conditions: {
          title: "Terms and Conditions for the use of the GLOBAL PDV Site",
          legal_text: terms_conditions_en_us,
        },
      },
      "es-MX": {
        terms_conditions: {
          title: "Términos y Condiciones de Uso",
          legal_text: terms_conditions_es_mx,
        },
      },
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        terms_conditions: {
          title: "Global PDV terms & conditions",
          legal_text: terms_conditions_en_us,
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "terms_conditions.title",
          to: { name: "terms-conditions" },
        },
      ]);
    },
  },

  components: {
    VLayout,
    VMarkdownView,
  },
};
</script>

<style lang="scss">
ol {
  list-style-type: upper-roman;
}
</style>
