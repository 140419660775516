import {
  drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create,
  drugtransaction_nopatient_insurance_insurancebridge_recipe_create,
  drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list,
  drugtransaction_nopatient_insurance_insurancebridge_recipe_list,
  drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update,
  drugtransaction_nopatient_insurance_insurancebridge_recipe_update,
  drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create,
  drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create,
  drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update,
  drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update,
  drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete,
  drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    recipeslist: {
      items: [],
      currentPage: 0,
      perPage: 20,
      totalRows: 0,
    },
    recipes: [],
    patient_insuranceauthorization_recipes: [],
    patient_insurancebridge_recipes: [],
    patient_insurancerefund_recipes: [],

    active_patient_insuranceauthorization_recipe: {
      drugs: [],
    },
    active_patient_insurancebridge_recipe: {
      drugs: [],
    },
    active_patient_insuranceauthorization_recipe_products: [],
    active_patient_insurancebridge_recipe_products: [],
    active_patient_insuranceauthorization_recipe_dirty_products: [],
    active_patient_insurancebridge_recipe_dirty_products: [],
    active_patient_insuranceauthorization_recipe_summary: {
      subtotal: 0,
      taxes: 0,
      total: 0,
      currency: 0,
    },
    active_patient_insurancebridge_recipe_summary: {
      subtotal: 0,
      taxes: 0,
      total: 0,
      currency: 0,
    },
  },
  actions: {
    LOAD_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list({
          $config: axconf(),
          role: "patient",
          roleUuid: payload.pt_uuid || 1,
          patientUuid: payload.pt_uuid,
          insuranceUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
        }).then(
          response => {
            commit(
              "MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES",
              response.data.results
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insurancebridge_recipe_list({
          $config: axconf(),
          role: "patient",
          roleUuid: payload.pt_uuid || 1,
          patientUuid: payload.pt_uuid,
          insuranceUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
        }).then(
          response => {
            commit(
              "MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES",
              response.data.results
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    CREATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_RECIPE: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create(
          {
            $config: axconf(),
            role: "patient",
            roleUuid: payload.pt_uuid || 1,
            patientUuid: payload.pt_uuid,
            insuranceUuid: payload.i_uuid,
            insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
            data: payload.data,
          }
        ).then(
          response => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE",
              response.data
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    CREATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insurancebridge_recipe_create({
          $config: axconf(),
          role: "patient",
          roleUuid: payload.pt_uuid || 1,
          patientUuid: payload.pt_uuid,
          insuranceUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
          data: payload.data,
        }).then(
          response => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE",
              response.data
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    // Añadir producto a receta de authorization de aseguradora
    // payload = {
    //   pt_uuid: String,
    //   i_uuid: String,
    //   r_uuid: String,
    //   data: Object, // debe ser un objeto existente en state.active_patient_insuranceauthorization_recipe_dirty_products
    // }
    CREATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_RECIPE_DRUG: (
      { commit, state },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        let indexof = state.active_patient_insuranceauthorization_recipe_products.indexOf(
          payload.data
        );
        if (indexof < 0) {
          reject(
            "PLEASE, SEND A OBJECT PRESENT IN state.active_patient_insuranceauthorization_recipe_dirty_products"
          );
          return;
        }
        drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create(
          {
            $config: axconf(),
            role: "patient",
            roleUuid: payload.pt_uuid || 1,
            patientUuid: payload.pt_uuid,
            insuranceUuid: payload.i_uuid,
            insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
            recipeUuid: payload.r_uuid, // recipe uuid
            data: payload.data,
          }
        ).then(
          response => {
            resolve(response);
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_UPDATE_PRODUCT",
              {
                original: payload.data,
                new: response.data,
              }
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
          },
          err => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
            reject(err);
          }
        );
      });
    },
    // Añadir producto a receta de ins bridge de aseguradora
    // payload = {
    //   pt_uuid: String,
    //   i_uuid: String,
    //   r_uuid: String,
    //   data: Object, // debe ser un objeto existente en state.active_patient_insuranceauthorization_recipe_dirty_products
    // }
    CREATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE_DRUG: (
      { commit, state },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        let indexof = state.active_patient_insurancebridge_recipe_products.indexOf(
          payload.data
        );
        if (indexof < 0) {
          reject(
            "PLEASE, SEND A OBJECT PRESENT IN state.active_patient_insurancebridge_recipe_dirty_products"
          );
          return;
        }
        drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create({
          $config: axconf(),
          role: "patient",
          roleUuid: payload.pt_uuid || 1,
          patientUuid: payload.pt_uuid,
          insuranceUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
          recipeUuid: payload.r_uuid, // recipe uuid
          data: payload.data,
        }).then(
          response => {
            resolve(response);
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_UPDATE_PRODUCT",
              {
                original: payload.data,
                new: response.data,
              }
            );
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
          },
          err => {
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
            reject(err);
          }
        );
      });
    },
    PATCH_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_RECIPE_DRUG: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update(
          {
            $config: axconf(),
            role: "guest",
            roleUuid: "1",
            ptUuid: payload.pt_uuid,
            iUuid: payload.i_uuid,
            insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
            rUuid: payload.r_uuid, // recipe uuid
            rdUuid: payload.rd_uuid,
            data: payload.data,
          }
        ).then(
          response => {
            resolve(response);
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.item
            );
            //commit('MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_UPDATE_PRODUCT', payload.data);
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
          },
          err => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
            reject(err);
          }
        );
      });
    },
    PATCH_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE_DRUG: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update(
          {
            $config: axconf(),
            role: "guest",
            roleUuid: "1",
            ptUuid: payload.pt_uuid,
            iUuid: payload.i_uuid,
            insurancebridgeUuid: payload.insurancebridge_uuid,
            rUuid: payload.r_uuid, // recipe uuid
            rdUuid: payload.rd_uuid,
            data: payload.data,
          }
        ).then(
          response => {
            resolve(response);
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.item
            );
            //commit('MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_UPDATE_PRODUCT', payload.data);
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
          },
          err => {
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
            reject(err);
          }
        );
      });
    },
    UPDATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_RECIPE: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update(
          {
            $config: axconf(),
            role: "guest",
            roleUuid: "1",
            ptUuid: payload.pt_uuid,
            iUuid: payload.i_uuid,
            insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
            rUuid: payload.r_uuid,
            data: payload.data,
          }
        ).then(
          response => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE",
              response.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    UPDATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insurancebridge_recipe_update({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
          rUuid: payload.r_uuid,
          data: payload.data,
        }).then(
          response => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE",
              response.data
            );
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    // Añade un producto nuevo a la receta de paciente activa
    ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_PUSH_PRODUCT: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        if (!payload.product.uuid) {
          reject({ error: "No product uuid" });
          return;
        }
        if (!payload.product.price || !payload.product.price.uuid) {
          reject({ error: "No valid product price" });
          // TODO show error
          return;
        }

        commit(
          "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_PUSH_PRODUCT",
          payload
        );
        commit("MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY");
        resolve({ data: "ok" });
      });
    },
    ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_PUSH_PRODUCT: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        if (!payload.product.uuid) {
          reject({ error: "No product uuid" });
          return;
        }
        if (!payload.product.price || !payload.product.price.uuid) {
          reject({ error: "No valid product price" });
          // TODO show error
          return;
        }

        commit(
          "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_PUSH_PRODUCT",
          payload
        );
        commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
        resolve({ data: "ok" });
      });
    },
    DELETE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_RECIPE_DRUG: (
      { commit },
      payload
    ) => {
      if (!payload.data.uuid) {
        return new Promise((resolve, reject) => {
          try {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
          } catch (e) {
            reject(e);
          }
          resolve({ data: "ok" });
        });
      }

      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete(
          {
            $config: axconf(),
            role: "guest",
            roleUuid: "1",
            ptUuid: payload.pt_uuid,
            iUuid: payload.i_uuid,
            insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
            rUuid: payload.r_uuid,
            rdUuid: payload.data.uuid,
          }
        ).then(
          response => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY"
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DELETE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE_DRUG: (
      { commit },
      payload
    ) => {
      if (!payload.data.uuid) {
        return new Promise((resolve, reject) => {
          try {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_PRODUCT",
              payload.data
            );
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
          } catch (e) {
            reject(e);
          }
          resolve({ data: "ok" });
        });
      }

      return new Promise((resolve, reject) => {
        drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
          rUuid: payload.r_uuid,
          rdUuid: payload.data.uuid,
        }).then(
          response => {
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_DIRTY_PRODUCT",
              payload.data
            );
            commit(
              "MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_PRODUCT",
              payload.data
            );
            commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    // coloca una rectea como receta activa y calcula los totales
    SET_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ACTIVE_RECIPE: (
      { commit },
      payload
    ) => {
      return new Promise(resolve => {
        commit(
          "MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ACTIVE_RECIPE",
          payload
        );
        commit("MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY");
        resolve("ok");
      });
    },
    // coloca una rectea como receta activa y calcula los totales
    SET_PATIENT_INSURANCE_INSURANCEBRIDGE_ACTIVE_RECIPE: (
      { commit },
      payload
    ) => {
      return new Promise(resolve => {
        commit(
          "MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ACTIVE_RECIPE",
          payload
        );
        commit("MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY");
        resolve("ok");
      });
    },
  },
  mutations: {
    MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_UPDATE_PRODUCT: (
      state,
      payload
    ) => {
      let indexof = state.active_patient_insuranceauthorization_recipe_products.indexOf(
        payload.original
      );
      if (indexof >= 0) {
        let products =
          state.active_patient_insuranceauthorization_recipe_products;
        products[indexof] = Object.assign(products[indexof], payload.new);
        Vue.set(
          state,
          "active_patient_insuranceauthorization_recipe_products",
          products
        );
      }
    },
    MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_UPDATE_PRODUCT: (
      state,
      payload
    ) => {
      let indexof = state.active_patient_insurancebridge_recipe_products.indexOf(
        payload.original
      );
      if (indexof >= 0) {
        let products = state.active_patient_insurancebridge_recipe_products;
        products[indexof] = Object.assign(products[indexof], payload.new);
        Vue.set(
          state,
          "active_patient_insurancebridge_recipe_products",
          products
        );
      }
    },
    // Calcula los totales de la receta activa
    MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_SUMMARY: state => {
      var subtotal = 0;
      var taxes = 0;
      var total = 0;
      var currency = "XXX";
      state.active_patient_insuranceauthorization_recipe_products.forEach(
        element => {
          total += element.price.all_included_price * element.quantity;
          subtotal += element.price.no_tax_price * element.quantity;
          taxes +=
            element.price.all_included_price - element.price.no_tax_price;
          currency = element.price.base_currency;
        }
      );
      Vue.set(state, "active_patient_insuranceauthorization_recipe_summary", {
        subtotal: subtotal,
        taxes: taxes,
        total: total,
        currency: currency,
      });
    },
    // Calcula los totales de la receta activa
    MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_SUMMARY: state => {
      var subtotal = 0;
      var taxes = 0;
      var total = 0;
      var currency = "XXX";
      state.active_patient_insurancebridge_recipe_products.forEach(element => {
        total += element.price.all_included_price * element.quantity;
        subtotal += element.price.no_tax_price * element.quantity;
        taxes += element.price.all_included_price - element.price.no_tax_price;
        currency = element.price.base_currency;
      });
      Vue.set(state, "active_patient_insurancebridge_recipe_summary", {
        subtotal: subtotal,
        taxes: taxes,
        total: total,
        currency: currency,
      });
    },
    // Añade un producto nuevo al objeto de receta activo actualmente
    MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_PUSH_PRODUCT: (
      state,
      payload
    ) => {
      let exists = false;
      state.active_patient_insuranceauthorization_recipe_products.forEach(
        drug => {
          if (payload.product == drug) {
            exists = true;
          }
        }
      );
      if (exists) {
        return;
      }

      let product = Object.assign({}, payload.product);
      // put product id as parent id
      product.parent = product.uuid;
      delete product.uuid;

      // put price id as parent id
      product.price.parent = product.price.uuid;
      delete product.price.uuid;

      // put data from extra_fields (batch, quantity, etc)
      if (payload.extra_fields.expiry_date) {
        var expiry_date = new Date(payload.extra_fields.expiry_date);
        var day = expiry_date.getDate();
        var monthIndex = expiry_date.getMonth() + 1;
        var year = expiry_date.getFullYear();
        payload.extra_fields.expiry_date = year + "-" + monthIndex + "-" + day;
      }
      product = Object.assign(product, payload.extra_fields);

      // put data in recipe drugs
      let active_recipe_products =
        state.active_patient_insuranceauthorization_recipe_products;
      active_recipe_products.push(product);
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe",
        active_recipe_products
      );

      // put data in dirty drugs
      let adirty =
        state.active_patient_insuranceauthorization_recipe_dirty_products;
      adirty.push(product);
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_dirty_products",
        adirty
      );
    },
    MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_PUSH_PRODUCT: (
      state,
      payload
    ) => {
      let exists = false;
      state.active_patient_insurancebridge_recipe_products.forEach(drug => {
        if (payload.product == drug) {
          exists = true;
        }
      });
      if (exists) {
        return;
      }

      let product = Object.assign({}, payload.product);
      // put product id as parent id
      product.parent = product.uuid;
      delete product.uuid;

      // put price id as parent id
      product.price.parent = product.price.uuid;
      delete product.price.uuid;

      // put data from extra_fields (batch, quantity, etc)
      if (payload.extra_fields.expiry_date) {
        var expiry_date = new Date(payload.extra_fields.expiry_date);
        var day = expiry_date.getDate();
        var monthIndex = expiry_date.getMonth() + 1;
        var year = expiry_date.getFullYear();
        payload.extra_fields.expiry_date = year + "-" + monthIndex + "-" + day;
      }
      product = Object.assign(product, payload.extra_fields);

      // put data in recipe drugs
      let active_recipe_products =
        state.active_patient_insurancebridge_recipe_products;
      active_recipe_products.push(product);
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe",
        active_recipe_products
      );

      // put data in dirty drugs
      let adirty = state.active_patient_insurancebridge_recipe_dirty_products;
      adirty.push(product);
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe_dirty_products",
        adirty
      );
    },
    // elimina un producto de la lista de productos sucios
    MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_DIRTY_PRODUCT: (
      state,
      payload
    ) => {
      let adirty =
        state.active_patient_insuranceauthorization_recipe_dirty_products;
      let index_to_delete = adirty.indexOf(payload);
      adirty.splice(index_to_delete, 1);
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_dirty_products",
        adirty
      );
    },
    // elimina un producto de la lista de productos sucios
    MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_DIRTY_PRODUCT: (
      state,
      payload
    ) => {
      let adirty = state.active_patient_insurancebridge_recipe_dirty_products;
      let index_to_delete = adirty.indexOf(payload);
      adirty.splice(index_to_delete, 1);
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe_dirty_products",
        adirty
      );
    },
    // elimina un producto de la lista de productos
    MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE_DELETE_PRODUCT: (
      state,
      payload
    ) => {
      let products =
        state.active_patient_insuranceauthorization_recipe_products;
      let index_to_delete = products.indexOf(payload);
      products.splice(index_to_delete, 1);
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_products",
        products
      );
    },
    // elimina un producto de la lista de productos
    MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_DELETE_PRODUCT: (
      state,
      payload
    ) => {
      let products = state.active_patient_insurancebridge_recipe_products;
      let index_to_delete = products.indexOf(payload);
      products.splice(index_to_delete, 1);
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe_products",
        products
      );
    },
    // coloca todas las recetas de un paciente obtenidas en state
    MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES: (
      state,
      payload
    ) => {
      Vue.set(state, "patient_insuranceauthorization_recipes", payload);
    },
    // coloca todas las recetas de un paciente obtenidas en state
    MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES: (state, payload) => {
      Vue.set(state, "patient_insurancebridge_recipes", payload);
    },
    MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES_RESET: state => {
      Vue.set(state, "patient_insuranceauthorization_recipes", []);
    },
    MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES_RESET: state => {
      Vue.set(state, "patient_insurancebridge_recipes", []);
    },
    MUTATE_PATIENT_INSURANCE_INSURANCEREFUND_ALL_RECIPES_RESET: state => {
      Vue.set(state, "patient_insurancerefund_recipes", []);
    },
    // busca entre todas las recetas de paciente actuales la receta indicada
    // en payload y la actualiza
    MUTATE_ACTIVE_PATIENT_INSURANCEAUTHORIZATION_RECIPE: (state, payload) => {
      let patient_insuranceauthorization_recipes =
        state.patient_insuranceauthorization_recipes;
      patient_insuranceauthorization_recipes.forEach(recipe => {
        if (recipe.uuid == payload.uuid) {
          recipe = payload;
        }
      });
      Vue.set(
        state,
        "patient_insuranceauthorization_recipes",
        patient_insuranceauthorization_recipes
      );
      Vue.set(state, "active_patient_insuranceauthorization_recipe", payload);
    },
    // busca entre todas las recetas de paciente actuales la receta indicada
    // en payload y la actualiza
    MUTATE_ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE: (state, payload) => {
      let patient_insurancebridge_recipes =
        state.patient_insurancebridge_recipes;
      patient_insurancebridge_recipes.forEach(recipe => {
        if (recipe.uuid == payload.uuid) {
          recipe = payload;
        }
      });
      Vue.set(
        state,
        "patient_insurancebridge_recipes",
        patient_insurancebridge_recipes
      );
      Vue.set(state, "active_patient_insurancebridge_recipe", payload);
    },
    // hace switch de la receta activa actualmente
    MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ACTIVE_RECIPE: (
      state,
      payload
    ) => {
      Vue.set(state, "active_patient_insuranceauthorization_recipe", payload);
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_dirty_products",
        []
      );
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_products",
        payload.drugs
      );
      Vue.set(state, "active_patient_insuranceauthorization_recipe_summary", {
        subtotal: 0,
        taxes: 0,
        total: 0,
        currency: 0,
      });
    },
    // hace switch de la receta activa actualmente
    MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ACTIVE_RECIPE: (
      state,
      payload
    ) => {
      Vue.set(state, "active_patient_insurancebridge_recipe", payload);
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe_dirty_products",
        []
      );
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe_products",
        payload.drugs
      );
      Vue.set(state, "active_patient_insurancebridge_recipe_summary", {
        subtotal: 0,
        taxes: 0,
        total: 0,
        currency: 0,
      });
    },
    // devuelve todos los state de la receta activa a su estado original
    MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ACTIVE_RESET: state => {
      Vue.set(state, "active_patient_insuranceauthorization_recipe", {
        drugs: [],
      });
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_dirty_products",
        []
      );
      Vue.set(
        state,
        "active_patient_insuranceauthorization_recipe_products",
        []
      );
      Vue.set(state, "active_patient_insuranceauthorization_recipe_summary", {
        subtotal: 0,
        taxes: 0,
        total: 0,
        currency: 0,
      });
    },
    MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ACTIVE_RESET: state => {
      Vue.set(state, "active_patient_insurancebridge_recipe", {
        drugs: [],
      });
      Vue.set(
        state,
        "active_patient_insurancebridge_recipe_dirty_products",
        []
      );
      Vue.set(state, "active_patient_insurancebridge_recipe_products", []);
      Vue.set(state, "active_patient_insurancebridge_recipe_summary", {
        subtotal: 0,
        taxes: 0,
        total: 0,
        currency: 0,
      });
    },
  },
  getters: {
    getPatientInsuranceRecipeByInsuranceBridgeUUID: state => insurancebridge_uuid => {
      let found_recipe = undefined;
      state.patient_insurancebridge_recipes.forEach(recipe => {
        if (recipe.insurancebridge == insurancebridge_uuid) {
          found_recipe = recipe;
        }
      });
      return found_recipe;
    },
    getPatientInsuranceRecipeByInsuranceAuthorizationUUID: state => insuranceauthorization_uuid => {
      let found_recipe = undefined;
      state.patient_insuranceauthorization_recipes.forEach(recipe => {
        if (recipe.insuranceauthorization == insuranceauthorization_uuid) {
          found_recipe = recipe;
        }
      });
      return found_recipe;
    },
    getPatientInsuranceRecipeByRecipeUUID: state => r_uuid => {
      let found_recipe = undefined;
      state.patient_insuranceauthorization_recipes.forEach(recipe => {
        if (recipe.uuid == r_uuid) {
          found_recipe = recipe;
        }
      });
      return found_recipe;
    },
    getPatientInsuranceBridgeRecipeByRecipeUUID: state => r_uuid => {
      let found_recipe = undefined;
      state.patient_insurancebridge_recipes.forEach(recipe => {
        if (recipe.uuid == r_uuid) {
          found_recipe = recipe;
        }
      });
      return found_recipe;
    },
    getPatientInsuraceAuthorizationActiveRecipe: state => {
      return state.active_patient_insuranceauthorization_recipe;
    },
    getPatientInsuraceBridgeActiveRecipe: state => {
      return state.active_patient_insurancebridge_recipe;
    },
    isInsuranceAuthorizationProductDirty: state => product => {
      let indexof = state.active_patient_insuranceauthorization_recipe_dirty_products.indexOf(
        product
      );
      if (indexof >= 0) {
        return true;
      }
      return false;
    },
    isInsuranceBridgeProductDirty: state => product => {
      let indexof = state.active_patient_insurancebridge_recipe_dirty_products.indexOf(
        product
      );
      if (indexof >= 0) {
        return true;
      }
      return false;
    },
    // recipesList: (state) => (p_uuid) => (i_uuid) => {
    //   var p_i_uuid = p_uuid + i_uuid;
    //   return state.recipeslist[p_i_uuid];
    // },
    // getRecipe: (state) => (r_uuid) => {
    //   var found_recipe = null;
    //   // TODO: implement a break
    //   state.recipes.forEach(element => {
    //     if (element.uuid == r_uuid) {
    //       found_recipe = element;
    //     }
    //   });
    //   if (found_recipe != null) {
    //     return found_recipe;
    //   }
    // }
  },
};
