<template>
  <div>
    <vue-element-loading :active="loading" />
    <b-form @submit="sendForm" @reset="onReset" v-if="show">
      <b-alert :show="Boolean(form.uuid)" variant="warning">{{
        $t("customer_data.forbidden_edit_message")
      }}</b-alert>

      <h6 class="pb-3">{{ $t("customer_data.insured_data_title") }}</h6>
      <b-container fluid class="pr-0 pl-0">
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              type="select"
              name="insurance"
              required
              :label="$t('customer_data.insurance_label')"
              :placeholder="$t('customer_data.insurance_placeholder')"
              :invalid_feedback="form_invalid_feedback.manager_uuid"
              :state="form_state.manager_uuid"
              v-model.trim="form.manager_uuid"
              label-cols-lg="12"
              label-cols-xl="12"
            >
              <template v-slot:options>
                <option
                  v-for="(insurance, index) in insurance_allmodule_list"
                  :key="index"
                  :value="insurance.uuid"
                >
                  {{ insurance.public_name }}
                </option>
              </template>
            </VBootstrapFormGroup>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="firstname"
              required
              :label="$t('customer_data.firstname_label')"
              :placeholder="$t('customer_data.firstname_placeholder')"
              :invalid_feedback="form_invalid_feedback.firstname"
              :state="form_state.firstname"
              v-model.trim="form.firstname"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-row>
              <b-col cols="12" md="8">
                <VBootstrapFormGroup
                  required
                  :disabled="Boolean(form.uuid)"
                  type="date2"
                  name="birth_date"
                  :label="$t('customer_data.birth_date_label')"
                  :placeholder="$t('customer_data.birth_date_placeholder')"
                  :invalid_feedback="form_invalid_feedback.birth_date"
                  :state="form_state.birth_date"
                  v-model.trim="form.birth_date"
                  label-cols-lg="12"
                  label-cols-xl="12"
                />
              </b-col>
              <b-col cols="12" md="4">
                <VBootstrapFormGroup
                  required
                  :disabled="Boolean(form.uuid)"
                  name="gender"
                  type="radio"
                  :label="$t('customer_data.gender_label')"
                  :placeholder="$t('customer_data.gender_placeholder')"
                  :invalid_feedback="form_invalid_feedback.gender"
                  :state="form_state.gender"
                  v-model="form.gender"
                  label-cols-lg="12"
                  label-cols-xl="12"
                >
                  <template v-slot:options>
                    <b-form-radio value="1">M</b-form-radio>
                    <b-form-radio value="2">F</b-form-radio>
                  </template>
                </VBootstrapFormGroup>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="policy_num"
              required
              :label="$t('customer_data.policy_num_label')"
              :placeholder="$t('customer_data.policy_num_placeholder')"
              :invalid_feedback="form_invalid_feedback.policy_num"
              :state="form_state.policy_num"
              v-model.trim="form.policy_num"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="incumbent_name"
              :label="$t('customer_data.incumbent_name_label')"
              :placeholder="$t('customer_data.incumbent_name_placeholder')"
              :invalid_feedback="form_invalid_feedback.incumbent_name"
              :state="form_state.incumbent_name"
              v-model.trim="form.incumbent_name"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              type="select"
              name="is_beneficiary"
              :label="$t('customer_data.is_beneficiary_label')"
              :placeholder="$t('customer_data.is_beneficiary_placeholder')"
              :invalid_feedback="form_invalid_feedback.is_beneficiary"
              :state="form_state.is_beneficiary"
              v-model.trim="form.is_beneficiary"
              label-cols-lg="12"
              label-cols-xl="12"
            >
              <template v-slot:options>
                <option value="true">{{
                  $t("customer_data.is_beneficiary_yes")
                }}</option>
                <option value="false">{{
                  $t("customer_data.is_beneficiary_no")
                }}</option>
              </template>
            </VBootstrapFormGroup>
          </b-col>
        </b-row>
        <b-row v-show="form.is_beneficiary == 'true'">
          <b-col cols="12" md="4" lg="4"></b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="beneficiary_email"
              :label="$t('customer_data.beneficiary_email_label')"
              :placeholder="$t('customer_data.beneficiary_email_placeholder')"
              :invalid_feedback="form_invalid_feedback.beneficiary_email"
              :state="form_state.beneficiary_email"
              v-model.trim="form.beneficiary_email"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="beneficiary_relation"
              :label="$t('customer_data.beneficiary_relation_label')"
              :placeholder="
                $t('customer_data.beneficiary_relation_placeholder')
              "
              :invalid_feedback="form_invalid_feedback.beneficiary_relation"
              :state="form_state.beneficiary_relation"
              v-model.trim="form.beneficiary_relation"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" lg="2">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              type="currencyraw"
              name="deductible"
              :label="$t('customer_data.deductible_label')"
              :placeholder="$t('customer_data.deductible_placeholder')"
              :invalid_feedback="form_invalid_feedback.deductible"
              :state="form_state.deductible"
              :preset_currency="form.deductible_currency"
              v-on:parsed="form.deductible_currency = $event.currency"
              v-model.trim="form.deductible"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="2" lg="2">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              type="currencyraw"
              name="coinsurance"
              ref="coinsurance"
              :label="$t('customer_data.coinsurance_label')"
              :placeholder="$t('customer_data.coinsurance_placeholder')"
              :invalid_feedback="form_invalid_feedback.coinsurance"
              :state="form_state.coinsurance"
              v-on:parsed="toggleCoinsuranceType($event.currency)"
              v-model.trim="form.coinsurance"
              :preset_currency="form.coinsurance_type_string"
              :extra_currency="['%']"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              type="currencyraw"
              name="coinsurance_limit"
              :label="$t('customer_data.coinsurance_limit_label')"
              :placeholder="$t('customer_data.coinsurance_limit_placeholder')"
              :invalid_feedback="form_invalid_feedback.coinsurance_limit"
              :state="form_state.coinsurance_limit"
              v-on:parsed="form.coinsurance_limit_currency = $event.currency"
              :preset_currency="form.coinsurance_limit_currency"
              v-model.trim="form.coinsurance_limit"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="6" md="2" lg="2">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              type="date2"
              name="antiquity"
              :label="$t('customer_data.antiquity_label')"
              :placeholder="$t('customer_data.antiquity_placeholder')"
              :invalid_feedback="form_invalid_feedback.antiquity"
              :state="form_state.antiquity"
              v-model.trim="form.antiquity"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="6" md="2" lg="2">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              type="date2"
              name="validity_date"
              :label="$t('customer_data.validity_date_label')"
              :placeholder="$t('customer_data.validity_date_placeholder')"
              :invalid_feedback="form_invalid_feedback.validity_date"
              :state="form_state.validity_date"
              v-model.trim="form.validity_date"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="contracted_plan"
              :label="$t('customer_data.contracted_plan_label')"
              :placeholder="$t('customer_data.contracted_plan_placeholder')"
              :invalid_feedback="form_invalid_feedback.contracted_plan"
              :state="form_state.contracted_plan"
              v-model.trim="form.contracted_plan"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              type="textarea"
              name="observations"
              :label="$t('customer_data.observations_label')"
              :placeholder="$t('customer_data.observations_placeholder')"
              :invalid_feedback="form_invalid_feedback.observations"
              :state="form_state.observations"
              v-model.trim="form.observations"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
        </b-row>
      </b-container>

      <h6 class="pb-3 pt-5">{{ $t("customer_data.contact_data_title") }}</h6>
      <b-container fluid class="pr-0 pl-0">
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="address"
              :label="$t('customer_data.address_label')"
              :placeholder="$t('customer_data.address_placeholder')"
              :invalid_feedback="form_invalid_feedback.address"
              :state="form_state.address"
              v-model.trim="form.address"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="colony"
              :label="$t('customer_data.colony_label')"
              :placeholder="$t('customer_data.colony_placeholder')"
              :invalid_feedback="form_invalid_feedback.colony"
              :state="form_state.colony"
              v-model.trim="form.colony"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="town"
              :label="$t('customer_data.town_label')"
              :placeholder="$t('customer_data.town_placeholder')"
              :invalid_feedback="form_invalid_feedback.town"
              :state="form_state.town"
              v-model.trim="form.town"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="federal_entity"
              :label="$t('customer_data.federal_entity_label')"
              :placeholder="$t('customer_data.federal_entity_placeholder')"
              :invalid_feedback="form_invalid_feedback.federal_entity"
              :state="form_state.federal_entity"
              v-model.trim="form.federal_entity"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="postal_code"
              :label="$t('customer_data.postal_code_label')"
              :placeholder="$t('customer_data.postal_code_placeholder')"
              :invalid_feedback="form_invalid_feedback.postal_code"
              :state="form_state.postal_code"
              v-model.trim="form.postal_code"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="country"
              type="country"
              required
              :label="$t('customer_data.country_label')"
              :placeholder="$t('customer_data.country_placeholder')"
              :invalid_feedback="form_invalid_feedback.country"
              :state="form_state.country"
              v-model.trim="form.country"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="phone"
              type="phone"
              :label="$t('customer_data.phone_label')"
              :placeholder="$t('customer_data.phone_placeholder')"
              :invalid_feedback="form_invalid_feedback.phone"
              :state="form_state.phone"
              v-model.trim="form.phone"
              v-on:parsed="handlePhoneValid"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="mobile"
              type="phone"
              :label="$t('customer_data.mobile_label')"
              :placeholder="$t('customer_data.mobile_placeholder')"
              :invalid_feedback="form_invalid_feedback.mobile"
              :state="form_state.mobile"
              v-model="form.mobile"
              v-on:parsed="handleMobileValid"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              :disabled="Boolean(form.uuid)"
              name="has_whatsapp"
              type="radio"
              :label="$t('customer_data.has_whatsapp_label')"
              :placeholder="$t('customer_data.has_whatsapp_placeholder')"
              :invalid_feedback="form_invalid_feedback.has_whatsapp"
              :state="form_state.has_whatsapp"
              v-model="form.has_whatsapp"
              label-cols-lg="12"
              label-cols-xl="12"
            >
              <b-form-radio value="true">{{
                $t("customer_data.has_whatsapp_yes")
              }}</b-form-radio>
              <b-form-radio value="false">{{
                $t("customer_data.has_whatsapp_no")
              }}</b-form-radio>
            </VBootstrapFormGroup>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="4">
            <VBootstrapFormGroup
              required
              :disabled="Boolean(form.uuid)"
              name="email"
              :label="$t('customer_data.email_label')"
              :placeholder="$t('customer_data.email_placeholder')"
              :invalid_feedback="form_invalid_feedback.email"
              :state="form_state.email"
              v-model.trim="form.email"
              label-cols-lg="12"
              label-cols-xl="12"
            />
          </b-col>
        </b-row>
      </b-container>

      <div class="buttons-container">
        <b-button @click="$router.go(-1)" variant="secondary">{{
          $t("customer_data.cancel_button")
        }}</b-button>

        <b-button v-if="!Boolean(form.uuid)" type="submit" variant="primary">{{
          $t("customer_data.submit_button")
        }}</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
const COINSURANCE_TYPE_PERCENT = 0;
const COINSURANCE_TYPE_STATIC = 1;

// import moment from "moment";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import { mapState } from "vuex";

export default {
  name: "patient-customer-create-form",
  components: {
    VBootstrapFormGroup,
  },
  data() {
    return {
      form: {
        coinsurance: undefined,
        deductible: undefined,
        coinsurance_currency: undefined,
        // estos dos deben ir en consonancia
        coinsurance_type: COINSURANCE_TYPE_PERCENT,
        coinsurance_type_string: "%",
        //
        // este valor debe ser seteado antes de mostrar el input ya que
        // el valor mostrado no es reactivo desde el formulario sino que
        // es un preset. De este modo el form debe ser configurado al valor
        // correcto antes de mostrar los inputs (v-if="")
        deductible_currency: "MXN",
        identification_type: null,
        identification_number: "",
        identification_expiration: "",
        manager_uuid: undefined,
        policy_num: undefined,
        is_beneficiary: "false",
        beneficiary_email: undefined,
        contracted_plan: undefined,
        firstname: undefined,
        // contractor_name: undefined,
        // insurance_amount: undefined,
        beneficiary_relation: undefined,
        coinsurance_limit: undefined,
        // este valor debe ser seteado antes de mostrar el input ya que
        // el valor mostrado no es reactivo desde el formulario sino que
        // es un preset. De este modo el form debe ser configurado al valor
        // correcto antes de mostrar los inputs (v-if="")
        coinsurance_limit_currency: "MXN",
        // is_vip: undefined,
        birth_date: undefined,
        gender: undefined,
        incumbent_name: undefined,
        antiquity: undefined,
        validity_date: undefined,
        observations: "",
        address: undefined,
        federal_entity: undefined,
        phone: undefined,
        email: undefined,
        colony: undefined,
        postal_code: undefined,
        mobile: undefined,
        town: undefined,
        country: undefined,
        has_whatsapp: undefined,
      },
      form_invalid_feedback: {},
      form_state: {},
      show: false,
      loading: false,
      updated: false,
    };
  },
  computed: {
    ...mapState({
      insurance_allmodule_list: state =>
        state.organization.insurance_allmodule_list,
      has_sgmm: state => state.organization.has_sgmm,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        customer_data: {
          insured_data_title: "Insured data",
          contact_data_title: "Contact data",
          firstname_label: "Firstname",
          firstname_desc: "Firstname",
          firstname_placeholder: "Firstname",
          mobile_label: "Mobile",
          mobile_desc: "Mobile",
          mobile_placeholder: "Mobile",
          phone_label: "Phone",
          phone_desc: "Phone",
          phone_placeholder: "Phone",
          identification_number_placeholder: "Identification number",
          identification_number_label: "Identification number",
          identification_number_desc: "Identification number",
          identification_type_label: "Identification type",
          identification_type_desc: "Identification type",
          identification_type_placeholder: "Identification type",
          identification_expiration_label: "Identification expiration",
          identification_expiration_desc: "Identification expiration",
          identification_expiration_placeholder: "Identification expiration",
          address_label: "Tax address",
          address_desc: "Tax address",
          address_placeholder: "Tax address",
          town_label: "Tax town",
          town_desc: "Tax town",
          town_placeholder: "Tax town",
          colony_label: "Tax colony",
          colony_desc: "Tax colony",
          colony_placeholder: "Tax colony",
          postal_code_label: "Tax postal code",
          postal_code_desc: "Tax postal code",
          postal_code_placeholder: "Tax postal code",
          federal_entity_label: "Tax federal entity",
          federal_entity_desc: "Tax federal entity",
          federal_entity_placeholder: "Tax federal entity",
          select_option: "Please select an option",
          id_type_passport: "Passport",
          id_type_ine: "INE",
          submit_button: "Save",
          reset_button: "Reset",
          cancel_button: "Cancel",
          subscribe: "Subscribe to our newsletter",
          offers: "Receive special offers",
          country_label: "Country",
          country_desc: "Country",
          country_placeholder: "Country",
          insurance_label: "Insurance",
          insurance_placeholder: "Insurance",
          policy_num_label: "Policy number",
          policy_num_placeholder: "Policy number",
          is_beneficiary_label: "Is beneficiary",
          is_beneficiary_placeholder: "Is beneficiary",
          is_beneficiary_yes: "Yes",
          is_beneficiary_no: "No",
          beneficiary_email_label: "Beneficiary email",
          beneficiary_email_placeholder: "Beneficiary email",
          deductible_label: "Deductible",
          deductible_placeholder: "Deductible",
          coinsurance_label: "Coinsurance",
          coinsurance_placeholder: "Coinsurance",
          contracted_plan_label: "Contracted plan",
          contracted_plan_placeholder: "Contracted plan",
          // contractor_name_label: "Contractor name",
          // contractor_name_placeholder: "Contractor name",
          // insurance_amount_label: "Insurance amount",
          // insurance_amount_placeholder: "Insurance amount",
          beneficiary_relation_label: "Beneficiary relation",
          beneficiary_relation_placeholder: "Beneficiary relation",
          coinsurance_limit_label: "Coinsurance limit",
          coinsurance_limit_placeholder: "Coinsurance limit",
          // is_vip_label: "Is VIP",
          // is_vip_yes: "Yes",
          // is_vip_no: "No",
          birth_date_label: "Birth date",
          birth_date_placeholder: "Birth date",
          gender_label: "Gender",
          incumbent_name_label: "Incumbent name",
          incumbent_name_placeholder: "Incumbent name",
          antiquity_label: "Antiquity",
          antiquity_placeholder: "Antiquity",
          validity_date_label: "Validity date",
          validity_date_placeholder: "Validity date",
          observations_label: "Observations",
          observations_placeholder: "Observations",
          email_label: "Email",
          email_placeholder: "Email",
          has_whatsapp_label: "has Whatsapp",
          has_whatsapp_yes: "Yes",
          has_whatsapp_no: "No",
          forbidden_edit_message:
            "Edit action has been disabled. Contact with your Insurance to update data.",
          error: {
            saving_patientdata: "Error saving data, check form errors.",
            retrieve_patient_data: "Cannot retrieve patient data",
            retrieving_insurances: "Cannot retrieve insurances",
          },
        },
      },
    },
  },
  created() {
    this.loading = true;
    // this.loadCustomerData();
    this.load();
  },
  methods: {
    load() {
      this.$store.dispatch("organization/INSURANCE_LIST").then(
        () => {
          this.loadCustomerData();
        },
        () => {
          this.loading = false;
          this.show = true;
          this.mnotify_error({
            text: this.$t("customer_data.error.retrieving_insurances"),
          });
        }
      );
    },
    loadCustomerData() {
      if (this.$route.params.patient_customer_uuid != undefined) {
        this.$store
          .dispatch("organization/GET_PATIENT_CUSTOMER", {
            patient_customer_uuid: this.$route.params.patient_customer_uuid,
          })
          .then(
            response => {
              var patient_customer = response.data;
              if (patient_customer) {
                this.form = this.preDataIn(patient_customer);
                this.updated = true;
                this.patient_customer_uuid = patient_customer.uuid;
              }
              this.loading = false;
              this.show = true;
            },
            e => {
              this.$sentry({ capture: e });
              this.mnotify_error({
                text: this.$t("patient_create.error.retrieve_patient_data"),
              });
              this.loading = false;
              this.show = true;
            }
          );
      } else {
        this.loading = false;
        this.show = true;
      }
    },
    sendForm(evt) {
      evt.preventDefault();
      this.loading = true;
      var data = this.form;
      var url = "organization/NEW_PATIENT_CUSTOMER";
      var data_send = { data: data };
      if (this.updated) {
        url = "organization/PATCH_PATIENT_CUSTOMER";
        data_send = {
          data: data,
          patient_customer_uuid: this.patient_customer_uuid,
        };
      }
      this.$store
        .dispatch(url, data_send)
        .then(
          () => {
            // Esta pantalla (formulario) es un paso obligatorio cuando el usuario
            // se logea por primera vez, no permitiendo hacer otra cosa hasta
            // que se cree un record. Si es el primer record, el usuario ha
            // llegado hasta aquí de forma obligada, por lo que lo devolvemos
            // al home, en caso contrario, el usuario ha llegado ªdesde el listado
            // de sgmm, por lo que lo mandamos hasta ahí.
            let first_record = !this.has_sgmm;
            // En cualquier caso, siempre marcamos esto como true
            this.loading = true;
            this.$store
              .dispatch("organization/SET_HAS_SGMM", true)
              .then(() => {
                // Tras crear un nuevo record se vuelve a cargar la config ya que
                // la configuración puede cambiar sensiblemente según tipo de
                // registro o cantidad
                this.loading = true;
                this.$store
                  .dispatch("siteconf/LOAD_INITIAL_CONFIG", {
                    data: {},
                  })
                  .finally(() => {
                    this.loading = false;
                    if (first_record) {
                      this.$router.push({ name: "userhome" });
                    } else {
                      this.$router.push({
                        name: "account_major_medical_insurance_list",
                      });
                    }
                  });
              })
              .finally(() => {
                this.loading = false;
              });
          },
          err => {
            this.showResponseError(err);
            this.putFormFeedback(err.response);
            this.mnotify_error({
              text: this.$t("patient_create.error.saving_patientdata"),
            });
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    handleMobileValid() {
      // nop
    },
    handlePhoneValid() {
      // nop
    },
    preDataIn(original_data) {
      var data = Object.assign({}, original_data);
      data["gender"] = String(data["gender"]);
      // data["birth_date"] = moment(data["birth_date"], "YYYY-MM-DD");
      // data["antiquity"] = moment(data["antiquity"], "YYYY-MM-DD");
      // data["validity_date"] = moment(data["validity_date"], "YYYY-MM-DD");
      data["is_beneficiary"] = String(data["is_beneficiary"]);
      // data["is_vip"] = String(data["is_vip"]);
      data["has_whatsapp"] = String(data["has_whatsapp"]);
      if (!data["coinsurance_limit_currency"]) {
        data["coinsurance_limit_currency"] = "USD";
      }

      if (data.coinsurance_type == COINSURANCE_TYPE_STATIC) {
        if (!data.coinsurance_currency) {
          data["coinsurance_type_string"] = "--";
        } else {
          data["coinsurance_type_string"] = data.coinsurance_currency;
        }
      } else {
        data["coinsurance_type_string"] = "%";
      }

      if (data.coinsurance) {
        data.coinsurance = parseFloat(data.coinsurance).toString();
      }
      if (data.coinsurance_limit) {
        data.coinsurance_limit = parseFloat(data.coinsurance_limit).toString();
      }
      if (data.deductible) {
        data.deductible = parseFloat(data.deductible).toString();
      }

      return data;
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      for (var prop in this.form) {
        if (this.form.hasOwnProperty(prop)) {
          delete this.form[prop];
        }
      }
      /* Trick to reset/clear native browser form validation state */
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    toggleDeductibleCurrency(currency) {
      this.form.deductible_currency = currency;
    },
    toggleCoinsuranceLimitCurrency(currency) {
      this.form.coinsurance_limit_currency = currency;
    },
    toggleCoinsuranceType(type) {
      this.$nextTick(() => {
        if (type == "%") {
          this.form.coinsurance_type_string = "%";
          this.form.coinsurance_currency = undefined;
          this.form.coinsurance_type = 0;
        } else {
          this.form.coinsurance_type_string = type;
          this.form.coinsurance_currency = type;
          this.form.coinsurance_type = 1;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-radius-select {
  border-radius: 0px;
  border-radius: $input-border-radius;
}
</style>
