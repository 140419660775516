// dependency-free notification library
import "@/../node_modules/noty/lib/noty.css";
import "@/../node_modules/noty/lib/themes/metroui.css";
import Noty from "noty";
import router from "@/router";
import store from "@/store";

export const dummyResponseInterceptor = response => {
  return response;
};

export const notificationErrorInterceptor = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // Keep app manage these errors
    //
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    //
    // console.log(error.request);
    // console.log(error);
    var n = null;
    if (error.message) {
      n = new Noty({
        theme: "metroui",
        type: "error",
        modal: true,
        layout: "center",
        text: error.message,
        buttons: [
          Noty.button(
            "Retry",
            "btn btn-primary btn-lg center-block w-100",
            () => {
              router.go();
              n.close();
            }
          ),
        ],
      });
      let errcode = -1;
      if (error.response) {
        errcode = error.response.status || -1;
      }
      store.dispatch("appstatus/PUSH_NETWORK_ERROR", {
        errcode: errcode,
        noty: n,
      });
    } else {
      n = new Noty({
        theme: "metroui",
        type: "error",
        modal: true,
        layout: "center",
        text: "There is a problem with your client. Please, try again.",
        buttons: [
          Noty.button(
            "Retry",
            "btn btn-primary btn-lg center-block w-100",
            () => {
              router.go();
              n.close();
            }
          ),
        ],
      });
      let errcode = -1;
      if (error.response) {
        errcode = error.response.status || -1;
      }
      store.dispatch("appstatus/PUSH_NETWORK_ERROR", {
        errcode: errcode,
        noty: n,
      });
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log("Error", error.message);
    n = new Noty({
      theme: "metroui",
      type: "error",
      modal: true,
      layout: "center",
      text: error.message,
      buttons: [
        Noty.button(
          "Retry",
          "btn btn-primary btn-lg center-block w-100",
          () => {
            router.go();
            n.close();
          }
        ),
      ],
    });
    let errcode = -1;
    if (error.response) {
      errcode = error.response.status || -1;
    }
    store.dispatch("appstatus/PUSH_NETWORK_ERROR", {
      errcode: errcode,
      noty: n,
    });
  }
};
