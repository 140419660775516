import moment from "moment";
import { mapGetters } from "vuex";

const VueI18nFallback = {
  install(Vue) {
    Vue.mixin({
      beforeCreate: function() {
        if (this.$options.fallbackI18n) {
          const messages = this.$options.fallbackI18n.messages;
          for (var lang in messages) {
            this.$root.$i18n.mergeLocaleMessage(lang, messages[lang]);
          }
        }
      },
      computed: {
        ...mapGetters("i18nmap", ["activeLangtag"]),
      },
      methods: {
        i18nFormatDate(strdate, format = "L") {
          var date = moment(strdate);
          if (!date.isValid()) {
            return null;
          }
          date.locale(this.activeLangtag);
          return date.format(format);
        },
      },
    });
  },
};
export default VueI18nFallback;
