<template>
  <div>
    <p v-if="payment_configuration.bank_wire_bank_name" class="mt-1 mb-1">
      <strong>
        {{ $t("checkout_control_payment_bank_transfer.bank_wire_bank_name") }}:
      </strong>
      {{ payment_configuration.bank_wire_bank_name }}
    </p>
    <p v-if="payment_configuration.bank_wire_account_name" class="mt-1 mb-1">
      <strong>
        {{
          $t("checkout_control_payment_bank_transfer.bank_wire_account_name")
        }}:
      </strong>
      {{ payment_configuration.bank_wire_account_name }}
    </p>
    <p v-if="payment_configuration.bank_wire_number" class="mt-1 mb-1">
      <strong>
        {{ $t("checkout_control_payment_bank_transfer.bank_wire_number") }}:
      </strong>
      {{ payment_configuration.bank_wire_number }}
    </p>
    <p v-if="payment_configuration.bank_wire_IBWT" class="mt-1 mb-1">
      <strong>
        {{ $t("checkout_control_payment_bank_transfer.bank_wire_IBWT") }}:
      </strong>
      {{ payment_configuration.bank_wire_IBWT }}
    </p>
    <p v-if="payment_configuration.bank_wire_SWIFT" class="mt-1 mb-1">
      <strong>
        {{ $t("checkout_control_payment_bank_transfer.bank_wire_SWIFT") }}:
      </strong>
      {{ payment_configuration.bank_wire_SWIFT }}
    </p>
    <p v-if="payment_configuration.bank_wire_CHIPS" class="mt-1 mb-1">
      <strong>
        {{ $t("checkout_control_payment_bank_transfer.bank_wire_CHIPS") }}:
      </strong>
      {{ payment_configuration.bank_wire_CHIPS }}
    </p>
    <b-button
      size="sm"
      variant="primary"
      class="mt-1 mb-1"
      @click="payWithBankTransfer()"
    >
      {{ $t("checkout_control_payment_bank_transfer.pay") }}
    </b-button>
    <div class="legal mt-3 text-justify small">
      {{ $t("checkout_control_payment_bank_transfer.legal") }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    payment_configuration: {
      type: Object,
      default: undefined,
    },
    pharmacy_uuid: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    payWithBankTransfer() {
      this.$emit("payment-authorized", {
        pharmacy_uuid: this.pharmacy_uuid,
        payment_conf: this.payment_configuration,
      });
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        checkout_control_payment_bank_transfer: {
          pay: "Pay with bank transfer",
          bank_wire_number: "Bank wire number",
          bank_wire_bank_name: "Bank name",
          bank_wire_account_name: "Account name",
          bank_wire_IBWT: "Bank wire IBWT",
          bank_wire_SWIFT: "Bank wire SWIFT",
          bank_wire_CHIPS: "Bank wire CHIPS",
          legal:
            "Important: If you select this option, you will have a period of 72 hours from this moment to make your Deposit or Transfer, if you do not do so in this period of time, we will return your products for sale and your order will be canceled. The shipment of your product will be made from the confirmation of the payment of your order.",
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
