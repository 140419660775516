<template>
  <b-input-group>
    <b-form-input
      v-bind="$attrs"
      :type="passwordFieldType"
      v-bind:value="value"
      @input="onInput"
      v-on:blur="$emit('blur')"
      v-on:focus="$emit('focus')"
    ></b-form-input>
    <b-input-group-append>
      <font-awesome-icon
        :icon="passwordIcon"
        @click="showHidePassword"
        class="my-auto mx-2"
      />
    </b-input-group-append>
  </b-input-group>
</template>


<script>
export default {
  name: "password-input",
  props: {
    value: String,
  },
  data () {
    return {
      hidePassword: true,
    };
  },
  computed: {
    passwordFieldType () {
      return this.hidePassword ? "password" : "text";
    },
    passwordIcon () {
      return this.hidePassword ? "eye" : "eye-slash";
    },
  },
  methods: {
    onInput (payload) {
      this.$emit('input', payload);
    },
    showHidePassword () {
      this.hidePassword = !this.hidePassword;
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
