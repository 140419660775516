<template>
  <div>
    <b-modal
      size="xl"
      modal-class="modal-square-theme"
      v-model="modal_show"
      centered
      :title="$t('insurancebridge_confirm_feedback.title')"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12" lg="7">
            <p>{{ $t('insurancebridge_confirm_feedback.patient') }}
              <strong v-if="recipes">
                {{ recipes[0].patient_address.firstname }}
              </strong>
            </p>
          </b-col>
          <b-col cols="12 d-flex" lg="5">
            <div class="d-flex">
              <component class="spech_ico pull-left pt-1 mr-3" v-bind:is="spech_icon" alt="Info" />
              <p class="font-italic">{{ $t('insurancebridge_confirm_feedback.confirm_text') }}</p>
            </div>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-for="(recipe, index) in recipes" v-bind:key="index">
              <h6 class="mt-5 mb-4 text-uppercase font-weight-bold">{{ $t('insurancebridge_confirm_feedback.table_title') }}</h6>
              <VDrugsSummary stacked="lg" :items="recipe.drugs" :showamount="false" />
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-button size="sm" class="float-right" variant="primary" @click="modal_show=false">{{ $t('insurancebridge_confirm_feedback.close_button') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VDrugsSummary from "@/components/insurancebridge/VDrugsSummary";
import SpeechBubbleIcon from "@/static/ico/speech-bubble-with-letter-i.svg";
import { mapGetters } from "vuex";

export default {
  name: "insurancebridge-confirm-feedback",
  data() {
    return {
      modal_show: false,
      recipes: undefined,
      insurancebridge: undefined,
      spech_icon: SpeechBubbleIcon,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancebridge_confirm_feedback: {
          title: "Request done",
          close_button: "Close",
          table_title: "Drugs",
          patient: "Patient:",
          recipe_id: "# Recipe ID:",
          confirm_text:
            "The document will be sended to the insurance. After your request has been processed, you will receive an email",
        },
      },
    },
  },
  computed: {
    ...mapGetters("insurancebridge", ["getFeedbackInsuranceBridge"]),
  },
  mounted: function () {
    this.showFeedbackBridge();
  },
  methods: {
    showFeedbackBridge() {
      if (!this.getFeedbackInsuranceBridge) {
        return;
      }
      this.modal_show = true;
      this.insurancebridge = Object.assign({}, this.getFeedbackInsuranceBridge);
      this.$store.commit(
        "insurancebridge/MUTATE_FEEDBACK_INSURANCEBRIDGE",
        undefined
      );
      if (this.insurancebridge.recipe) {
        this.recipes = [this.insurancebridge.recipe];
      }
    },
  },
  components: {
    VDrugsSummary,
    SpeechBubbleIcon,
  },
};
</script>

<style lang="scss" scoped>
.spech_ico {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 4rem;
  height: 3rem;
  color: $corp-color1;
  fill: $corp-color1;
  stroke: $white;
}
</style>
