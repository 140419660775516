import {
  organization_pharmacy_create,
  organization_my_list,
  organization_insurance_insuranceauthorization_list,
  organization_insurance_insuranceallmodule_list,
  organization_insurance_insurancerefund_list,
  organization_insurance_insurancebridge_list,
  organization_patientcustomer_insurance_self_list,
  organization_patientcustomer_insurance_self_read,
  organization_patientcustomer_insurance_self_partial_update,
  organization_patientcustomer_insurance_self_create,
  organization_insurance_insuranceauthorization_providers_list,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";
import get from "@/../node_modules/lodash/get";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    profiles: {
      pharmacist: {
        uuid: null,
      },
      insuranceworker: {
        uuid: null,
      },
    },
    my_pharmacies: [],
    my_insurances: [],
    insurance_authorization_list: [],
    insurance_bridge_list: [],
    allow_insurance_authorization_guide: false,
    allow_insurance_bridge_guide: false,
    allow_insurance_refund_guide: false,
    insurance_allmodule_list: [],
    insurance_refund_list: [],
    insurance_providers_list: undefined,
    is_insurance_authorization_list_loaded: false,
    is_insurance_bridge_list_loaded: false,
    is_insurance_refund_list_loaded: false,
    patient_customer_list: undefined,
    has_sgmm: undefined,
    has_authorization_insurances: undefined,
    has_bridge_insurances: undefined,
    has_refund_insurances: undefined,
  },
  actions: {
    SET_HAS_SGMM: ({ commit }, has_sgmm) => {
      return new Promise(resolve => {
        commit("MUTATE_HAS_SGMM", has_sgmm);
        resolve(has_sgmm);
      });
    },
    SET_HAS_AUTHORIZATION_INSURANCES: ({ commit }, has) => {
      return new Promise(resolve => {
        commit("MUTATE_HAS_AUTHORIZATION_INSURANCES", has);
        resolve(has);
      });
    },
    SET_HAS_BRIDGE_INSURANCES: ({ commit }, has) => {
      return new Promise(resolve => {
        commit("MUTATE_HAS_BRIDGE_INSURANCES", has);
        resolve(has);
      });
    },
    SET_HAS_REFUND_INSURANCES: ({ commit }, has) => {
      return new Promise(resolve => {
        commit("MUTATE_HAS_REFUND_INSURANCES", has);
        resolve(has);
      });
    },
    // get those organizations that current user can manage
    MY: ({ commit }) => {
      return new Promise((resolve, reject) => {
        //commit('MUTATE_MY', []);
        organization_my_list({
          role: "guest",
          roleUuid: "1",
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_MY", response.data.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    // get a list of available insurances
    // TODO: implements pagination behavior
    INSURANCE_PROVIDERS_LIST: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        organization_insurance_insuranceauthorization_providers_list({
          role: "guest",
          roleUuid: "1",
          iUuid: payload.i_uuid,
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_INSURANCE_PROVIDERS_LIST", {
              list: response.data.results,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    INSURANCE_AUTHORIZATION_LIST: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        organization_insurance_insuranceauthorization_list({
          role: "guest",
          roleUuid: "1",
          ordering: "public_name",
          insuranceAgainstUser: get(payload, "insurance_against_user", true),
          $config: axconf(),
        }).then(
          response => {
            commit(
              "MUTATE_INSURANCE_AUTHORIZATION_LIST",
              response.data.results
            );
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    INSURANCE_BRIDGE_LIST: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        organization_insurance_insurancebridge_list({
          role: "guest",
          roleUuid: "1",
          ordering: "public_name",
          insuranceAgainstUser: get(payload, "insurance_against_user", true),
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_INSURANCE_BRIDGE_LIST", response.data.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    INSURANCE_LIST: ({ commit }) => {
      return new Promise((resolve, reject) => {
        organization_insurance_insuranceallmodule_list({
          role: "guest",
          roleUuid: "1",
          ordering: "public_name",
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_INSURANCE_ALLMODULE_LIST", response.data.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    INSURANCE_REFUND_LIST: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        organization_insurance_insurancerefund_list({
          role: "guest",
          roleUuid: "1",
          ordering: "public_name",
          insuranceAgainstUser: get(payload, "insurance_against_user", true),
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_INSURANCE_REFUND_LIST", response.data.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_CUSTOMER_SELF_LIST: ({ commit }, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        organization_patientcustomer_insurance_self_list({
          $config: axconf(),
          role: "patient",
          roleUuid: 1,
          offset: offset,
          limit: payload.per_page,
          search: payload.search || undefined,
          managerUuid: payload.manager_uuid,
          managerConfigurationShowInDeductibleCoinsurancePayment:
            payload.show_in_risk_payment,
        }).then(
          response => {
            commit("MUTATE_PATIENT_CUSTOMER_LIST", response.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    GET_PATIENT_CUSTOMER: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        organization_patientcustomer_insurance_self_read({
          $config: axconf(),
          role: "patient",
          roleUuid: "1",
          patientCustomerUuid: payload.patient_customer_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    PATCH_PATIENT_CUSTOMER: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        organization_patientcustomer_insurance_self_partial_update({
          $config: axconf(),
          role: "patient",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          data: payload.data,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    NEW_PATIENT_CUSTOMER: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        organization_patientcustomer_insurance_self_create({
          data: payload.data,
          role: "patient",
          roleUuid: "1",
          $config: axconf(),
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    NEW_PHARMACY: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        organization_pharmacy_create({
          data: payload.data,
          role: "guest",
          roleUuid: "1",
          $config: axconf(),
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_HAS_AUTHORIZATION_INSURANCES: (state, has) => {
      Vue.set(state, "has_authorization_insurances", has);
    },
    MUTATE_HAS_BRIDGE_INSURANCES: (state, has) => {
      Vue.set(state, "has_bridge_insurances", has);
    },
    MUTATE_HAS_REFUND_INSURANCES: (state, has) => {
      Vue.set(state, "has_refund_insurances", has);
    },
    MUTATE_HAS_SGMM: (state, has_sgmm) => {
      Vue.set(state, "has_sgmm", has_sgmm);
    },
    MUTATE_PATIENT_CUSTOMER_LIST: (state, payload) => {
      Vue.set(state, "patient_customer_list", payload);
    },
    SET_PHARMACIST_UUID: (state, uuid) => {
      //localStorage.setItem('jwt', jwt_access);
      state.profiles.pharmacist.uuid = uuid;
    },
    // foreach api result (a list of organizations)
    // and store separately pharmacies and insurances
    // TYPE_INSURANCE_COMPANY = 1
    // TYPE_PHARMACY_COMPANY = 2
    // TYPE_LABORATORY_COMPANY = 3
    MUTATE_MY: (state, my) => {
      var pharmacies = [];
      var insurances = [];
      my.forEach(function(org) {
        if (org.organization_type == 2) {
          pharmacies.push(org);
        } else if (org.organization_type == 1) {
          insurances.push(org);
        }
      });
      Vue.set(state, "my_pharmacies", my);
      Vue.set(state, "my_insurances", insurances);
    },
    MUTATE_INSURANCE_PROVIDERS_LIST: (state, payload) => {
      var insurance_providers_list = state.insurance_providers_list;
      if (!insurance_providers_list) {
        insurance_providers_list = {};
      }
      insurance_providers_list[payload.i_uuid] = payload.list;
      state.insurance_providers_list = Object.assign(
        {},
        insurance_providers_list
      );
    },
    MUTATE_INSURANCE_AUTHORIZATION_LIST: (state, list) => {
      Vue.set(state, "insurance_authorization_list", list);
      Vue.set(state, "is_insurance_authorization_list_loaded", true);
      list.forEach(element => {
        if (element.configuration.allow_pdv_authorization_guide) {
          Vue.set(state, "allow_insurance_authorization_guide", true);
        }
      });
    },
    MUTATE_INSURANCE_BRIDGE_LIST: (state, list) => {
      Vue.set(state, "insurance_bridge_list", list);
      Vue.set(state, "is_insurance_bridge_list_loaded", true);
      list.forEach(element => {
        if (element.configuration.allow_pdv_bridge_guide) {
          Vue.set(state, "allow_insurance_bridge_guide", true);
        }
      });
    },
    MUTATE_INSURANCE_ALLMODULE_LIST: (state, list) => {
      Vue.set(state, "insurance_allmodule_list", list);
    },
    MUTATE_INSURANCE_REFUND_LIST: (state, list) => {
      Vue.set(state, "insurance_refund_list", list);
      Vue.set(state, "is_insurance_refund_list_loaded", true);
      list.forEach(element => {
        if (element.configuration.allow_pdv_refund_guide) {
          Vue.set(state, "allow_insurance_refund_guide", true);
        }
      });
    },
  },
  getters: {
    userHasSgmm: state => {
      return state.has_sgmm;
    },
    // isInsuranceListLoaded: state => {
    //   return state.is_insurance_authorization_list_loaded;
    // },
    isPharmacistProfileLoaded: state => {
      return state.profiles.pharmacist.uuid != null;
    },
    getPharmacyDataByUUID: state => p_uuid => {
      var found_org = null;
      state.my_pharmacies.forEach(function(org) {
        if (org.uuid == p_uuid) {
          found_org = org;
        }
      });
      return found_org;
    },
    getInsuranceProvidersByUUID: state => i_uuid => {
      return state.insurance_providers_list[i_uuid];
    },
    getInsuranceDataByUUID: state => i_uuid => {
      var found_org = null;
      state.insurance_authorization_list.forEach(function(org) {
        if (org.uuid == i_uuid) {
          found_org = org;
        }
      });
      if (!found_org) {
        state.insurance_refund_list.forEach(function(org) {
          if (org.uuid == i_uuid) {
            found_org = org;
          }
        });
      }
      if (!found_org) {
        state.insurance_bridge_list.forEach(function(org) {
          if (org.uuid == i_uuid) {
            found_org = org;
          }
        });
      }
      return found_org;
    },
    getInsuranceConfiguration: state => i_uuid => {
      var found_org = null;
      state.insurance_authorization_list.forEach(function(org) {
        if (org.uuid == i_uuid) {
          found_org = org;
        }
      });

      if (!found_org) {
        state.insurance_refund_list.forEach(function(org) {
          if (org.uuid == i_uuid) {
            found_org = org;
          }
        });
      }

      if (!found_org) {
        state.insurance_bridge_list.forEach(function(org) {
          if (org.uuid == i_uuid) {
            found_org = org;
          }
        });
      }

      if (!found_org) {
        return undefined;
      }

      return found_org.configuration;
    },
  },
};
