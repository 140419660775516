<template>
  <div>
    <b-button
      variant="primary-inline"
      @click="patientSelectorShow = true"
      class="h-100"
    >
      {{ $t('insurance_prescription_patient_selector.button') }}
    </b-button>
    <b-modal
      size="xl"
      modal-class="modal-square-theme"
      v-model="patientSelectorShow"
      centered
      :title="$t('insurance_prescription_patient_selector.button')"
    >
      <MajorMedicalInsuranceTableList
        :perPage="perPage"
        :allowEditAddress="false"
        :allowSelectPatient="true"
        v-on:patient-selected="onPatientSelected"
        :preset-filters="presetFilters"
      />
    </b-modal>
  </div>
</template>


<script>
import MajorMedicalInsuranceTableList from "@/components/MajorMedicalInsuranceTableList";

export default {
  name: "v-major-medical-insurance-patient-selector",
  components: {
    MajorMedicalInsuranceTableList,
  },
  props: {
    perPage: {
      type: Number,
      default: 5,
    },
    "preset-filters": {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      patientSelectorShow: false,
    };
  },
  methods: {
    onPatientSelected(patient) {
      this.patientSelectorShow = false;
      this.$emit("patient-selected", patient);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_prescription_patient_selector: {
          button: "Load patient",
        },
      },
    },
  },
};
</script>
