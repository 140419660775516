<template>
  <div>
    <vue-element-loading :active="isBusy && !manual" />
    <b-container v-show="!show_selected && !manual" class="pl-0 pr-0 pb-5">
      <b-row no-gutters>
        <b-col cols="12" xl="4" lg="4" sm="4">
          <b-form>
            <VBootstrapSearchInput
              v-model="search_term"
              :placeholder="
                $t('insurance_refund_drug_selector.search_placeholder')
              "
              v-on:submit="onSearchSubmit($event)"
            ></VBootstrapSearchInput>
          </b-form>
        </b-col>
        <b-col cols="12" xl="4" lg="4" sm="4"></b-col>
        <b-col cols="12" xl="4" lg="4" sm="4"></b-col>
      </b-row>
    </b-container>

    <b-table
      id="drugs-table"
      ref="drugs-table"
      responsive
      stacked="sm"
      :busy.sync="isBusy"
      :items="tableProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      head-variant="gray"
      outlined
      v-show="!show_selected && !manual"
    >
      <template v-slot:cell(default_description)="row">
        <div>
          {{ row.value.description }}
        </div>
      </template>
      <template v-slot:cell(user_prices_actions)="row" v-if="userprices">
        <div class="text-left pb-1 selectable">
          <b-button
            size="sm price-button"
            variant="primary"
            @click="selectProduct(row.item, null)"
          >
            {{ $t("insurance_refund_drug_selector.select") }}
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col md="12">
        <b-pagination
          align="center"
          v-show="!show_selected && !manual"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="float-md-right"
        />
      </b-col>
    </b-row>
    <b-row v-show="show_selected && !manual" class="pb-4">
      <b-col>
        <h6 class="pb-2 pt-2 font-weight-bold text-uppercase">
          {{ $t("insurance_refund_drug_selector.selected_drug") }}
        </h6>
      </b-col>
    </b-row>
    <b-row v-if="(show_selected && selected_drug) || manual">
      <!-- SELECTED DRUG INFO -->
      <b-col cols="12" lg="7" md="12" sm="12">
        <b-container class="pl-0">
          <b-row no-gutters v-if="!manual">
            <b-col lg="2" class="pb-4">
              <div class="d-inline-block">
                <PictureFallback
                  v-if="selected_drug"
                  thumbnail
                  alt="Thumbnail"
                  :picture="selected_drug.picture"
                />
              </div>
            </b-col>
            <b-col lg="10" class="pl-lg-3 pt-2">
              <div class="pb-1 selectable">
                {{ $t("insurance_refund_drug_selector.medicament") }}:
                <strong>{{ selected_drug.default_description }}</strong>
              </div>
              <div class="pb-3 selectable">
                {{ $t("insurance_refund_drug_selector.laboratory") }}:
                <strong>{{ selected_drug.laboratory_name }}</strong>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="manual">
            <b-col cols="12">
              <VBootstrapFormGroup
                name="manual_product_name"
                required
                :label="
                  $t('insurance_refund_drug_selector.manual_product_name_label')
                "
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="12"
                label-cols-xl="12"
                v-on:input="handleFormChanged"
                :placeholder="
                  $t(
                    'insurance_refund_drug_selector.manual_product_name_placeholder'
                  )
                "
                :invalid_feedback="form_invalid_feedback.manual_product_name"
                :state="form_state.manual_product_name"
                v-model.trim="form.manual_product_name"
                stack-form-group
              />
            </b-col>
            <b-col cols="12" lg="6">
              <VBootstrapFormGroup
                name="manual_product_ean"
                :label="
                  $t('insurance_refund_drug_selector.manual_product_ean_label')
                "
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="12"
                label-cols-xl="12"
                v-on:input="handleFormChanged"
                :placeholder="
                  $t(
                    'insurance_refund_drug_selector.manual_product_ean_placeholder'
                  )
                "
                :invalid_feedback="form_invalid_feedback.manual_product_ean"
                :state="form_state.manual_product_ean"
                v-model.trim="form.manual_product_ean"
                stack-form-group
              />
            </b-col>
            <b-col cols="12" lg="6">
              <VBootstrapFormGroup
                name="manual_laboratory_name"
                :label="
                  $t(
                    'insurance_refund_drug_selector.manual_laboratory_name_label'
                  )
                "
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="12"
                label-cols-xl="12"
                v-on:input="handleFormChanged"
                :placeholder="
                  $t(
                    'insurance_refund_drug_selector.manual_laboratory_name_placeholder'
                  )
                "
                :invalid_feedback="form_invalid_feedback.manual_laboratory_name"
                :state="form_state.manual_laboratory_name"
                v-model.trim="form.manual_laboratory_name"
                stack-form-group
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <VBootstrapFormGroup
                name="batch"
                :label="$t('insurance_refund_drug_selector.batch_label')"
                label-cols-xl="12"
                label-cols-lg="12"
                label-cols-md="12"
                label-cols-sm="12"
                label-cols="12"
                v-on:input="handleFormChanged"
                :placeholder="
                  $t('insurance_refund_drug_selector.batch_placeholder')
                "
                :invalid_feedback="form_invalid_feedback.batch"
                :state="form_state.batch"
                v-model.trim="form.batch"
              />
            </b-col>
            <b-col cols="12" lg="6">
              <VBootstrapFormGroup
                type="date"
                name="expiry_date"
                :label="$t('insurance_refund_drug_selector.expiry_date_label')"
                label-cols-xl="12"
                label-cols-lg="12"
                label-cols-md="12"
                label-cols-sm="12"
                label-cols="12"
                v-on:input="handleFormChanged"
                :placeholder="
                  $t('insurance_refund_drug_selector.expiry_date_placeholder')
                "
                :invalid_feedback="form_invalid_feedback.expiry_date"
                :state="form_state.expiry_date"
                v-model.trim="form.expiry_date"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>

      <b-col cols="12" lg="5" md="12" sm="12">
        <VBootstrapFormGroup
          v-if="userprices"
          type="currency"
          name="userprice"
          required
          :label="$t('insurance_refund_drug_selector.userprice_label') + ':'"
          label-cols-xl="12"
          label-cols-lg="12"
          :placeholder="
            $t('insurance_refund_drug_selector.userprice_placeholder')
          "
          :invalid_feedback="form_invalid_feedback.userprice"
          v-on:parsed="handleCurrencyChanged"
          v-on:input="handleFormChanged"
          :state="form_state.userprice"
          v-model.trim="form.userprice"
        />

        <b-form-group v-if="userprices" class="text-left">
          <b-form-checkbox
            v-on:input="handleFormChanged"
            v-model="form.userprice_has_vat"
          >
            {{ $t("insurance_refund_drug_selector.has_vat") }} +16%
            <span v-if="form.userprice && form.userprice_has_vat">
              ({{ valueAddPercent(form.userprice, 16) }}
              {{ form.userprice_currency }})
            </span>
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label-cols="12"
          label-cols-sm="12"
          label-cols-md="12"
          label-cols-lg="4"
          label-cols-xl="4"
          class="text-left"
          :label="$t('insurance_refund_drug_selector.quantity_label')"
          label-for="quantity"
        >
          <b-form-input
            id="quantity"
            class=""
            type="number"
            v-model.trim="form.quantity"
            v-on:input="handleFormChanged"
            required
            :state="form_state.quantity"
            :placeholder="
              $t('insurance_refund_drug_selector.quantity_placeholder')
            "
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VBootstrapSearchInput from "@/components/VBootstrapSearchInput";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import PictureFallback from "@/components/PictureFallback";
import { Money } from "@/lib/money.js";
import { mapActions } from "vuex";

export default {
  name: "insurancerefund-recipe-drug-selector",
  props: {
    pt_uuid: String,
    i_uuid: String,
    manual: {
      type: Boolean,
      default: false,
    },
    init_search: {
      type: String,
      default: undefined,
    },
    show_selected: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    userprices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search_term: "",
      form: {
        batch: "L0000",
        expiry_date: undefined,
        quantity: "1",
      },
      form_state: {
        batch: undefined,
        expiry_date: undefined,
        quantity: undefined,
      },
      form_invalid_feedback: {
        batch: undefined,
        expiry_date: undefined,
        quantity: undefined,
      },
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      selected_drug: undefined,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "ean",
          label: this.$t("insurance_refund_drug_selector.headtable_ean"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "default_description",
          label: this.$t("insurance_refund_drug_selector.headtable_drug_name"),
          class: "selectable",
          thClass: "unselectable",
          formatter: value => {
            return {
              description: value,
              hide_caret: true,
            };
          },
        },
        {
          key: "user_prices_actions",
          label: "",
        },
      ];
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_refund_drug_selector: {
          headtable_ean: "EAN",
          headtable_drug_name: "Name",
          headtable_price: "Price",
          medicament: "Medicament",
          laboratory: "Laboratory",
          add: "Add",
          select: "Select",
          batch_label: "Batch",
          batch_placeholder: "Batch",
          expiry_date_label: "Expiry date",
          expiry_date_placeholder: "Expiry date",
          manual_product_name_label: "Product name",
          manual_product_name_placeholder: "Product name",
          manual_product_ean_label: "Product EAN",
          manual_product_ean_placeholder: "Product EAN",
          manual_laboratory_name_label: "Laboratory",
          manual_laboratory_name_placeholder: "Laboratory",
          quantity_label: "Quantity",
          quantity_placeholder: "Quantity",
          search_placeholder: "Search pharmacy drug...",
          show_more_prices: "Show more prices",
          provider_label: "Provider",
          selected_drug: "Selected drug",
          userprice_label: "Price",
          userprice_placeholder: "Price without taxes",
          has_vat: "VAT",
        },
      },
    },
  },
  // watch: {
  //   init_search: function(newState, oldState) {
  //     if (newState != oldState) {
  //       this.search_term = newState;
  //       this.onSearchSubmit({});
  //     }
  //   }
  // },
  mounted: function() {
    if (this.init_search) {
      this.search_term = this.init_search;
      this.onSearchSubmit();
    }
  },
  methods: {
    handleCurrencyChanged(parsed) {
      this.form.userprice_currency = parsed.currency;
      this.handleFormChanged();
    },
    ...mapActions({
      INSURANCE_PROVIDERS_LIST: "organization/INSURANCE_PROVIDERS_LIST",
    }),
    onProviderChange() {
      this.currentPage = 1;
      this.$root.$emit("bv::refresh::table", "drugs-table");
    },
    valueAddPercent(value, percent) {
      let _value = Number(value);
      let _percent = Number(percent);
      return Number(_value + _value * (_percent / 100));
    },
    cleanSelection() {
      this.selected_drug = undefined;
      this.form.batch = "L";
      this.form.expiry_date = "2018-11-11T11:11";
      this.form.quantity = 1;
    },
    formatPrice(raw, currency) {
      return new Money(raw, currency).toLocaleString();
    },
    handleFormChanged() {
      this.$emit("extra_fields_changed", Object.assign({}, this.form));
    },
    selectProduct(product) {
      var selected_drug = Object.assign({}, product);
      this.selected_drug = selected_drug;
      var _selected_product = this.selected_drug;
      delete _selected_product.avail_prices;
      this.$emit("product:selected", {
        product: _selected_product,
        extra_fields: this.form,
      });
    },
    onSearchSubmit(evt) {
      if (evt) {
        evt.preventDefault();
        // si este método se llama con evento, significa que ha sido
        // lanzado desde el input de búsqueda, así que lanzamos un
        // evento indicando que el término de búsqueda ha cambiado
        this.$emit("query_search_changed", {
          new_search_term: this.search_term,
        });
      }
      this.$refs["drugs-table"].refresh();
    },
    // tableProvider (ctx) {
    tableProvider() {
      try {
        return this.$store
          .dispatch("drugs/REQUEST_PATIENT_INSURANCE_DRUGS_IN", {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            per_page: this.perPage,
            current_page: this.currentPage,
            search: this.search_term,
            organization_uuid: undefined,
          })
          .then(
            response => {
              const items = response.data.results;
              // el backend nos ordena los precios de menor a mayor, pero por
              // precaución también los reordenamos en el front
              items.forEach(item => {
                item.avail_prices.sort(function(a, b) {
                  if (
                    new Number(a.system_all_included_price) >
                    new Number(b.system_all_included_price)
                  ) {
                    return 1;
                  }
                  if (
                    new Number(a.system_all_included_price) <
                    new Number(b.system_all_included_price)
                  ) {
                    return -1;
                  }
                  return 0;
                });
              });
              this.isBusy = false;
              this.$emit("drugs_loading_stop");
              this.totalRows = response.data.count;
              return items;
            },
            err => {
              this.$sentry({ capture: err });
              this.isBusy = false;
              this.$emit("drugs_loading_stop");
              return [];
            }
          );
      } catch (err) {
        this.$sentry({ capture: err });
        this.isBusy = false;
        this.$emit("drugs_loading_stop");
        return [];
      }
    },
  },
  components: {
    VBootstrapSearchInput,
    VBootstrapFormGroup,
    PictureFallback,
  },
};
</script>

<style lang="scss" scoped>
#drugs-table /deep/ thead tr th:last-child {
  width: 10%;
}

.price-button {
  width: 100%;
}
@include media-breakpoint-down(sm) {
  /deep/ table.b-table > tbody > tr > td {
    grid-template-columns: 30% auto !important;

    &::before {
      text-align: left !important;
    }
  }
}
</style>
