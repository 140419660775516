<template>
  <div>
    <vue-element-loading :active="loading" :is-full-screen="true" />
    <b-form @submit="sendForm" @reset="onReset" v-if="show && !loading">
      <b-container fluid class="pr-0 pl-0 mt-5">
        <b-row>
          <b-col cols="12" lg="6" md="6" sm="12">
            <VBootstrapFormGroup
              required
              name="firstname"
              :label="$t('address_create.firstname_label')"
              :placeholder="$t('address_create.firstname_placeholder')"
              :invalid_feedback="form_invalid_feedback.firstname"
              :state="form_state.firstname"
              v-model.trim="form.firstname"
            />
            <VBootstrapFormGroup
              name="identification_number"
              :label="$t('address_create.identification_number_label')"
              :placeholder="$t('address_create.identification_number_placeholder')"
              :invalid_feedback="form_invalid_feedback.identification_number"
              :state="form_state.identification_number"
              v-model.trim="form.identification_number"
            />
            <VBootstrapFormGroup
              name="colony"
              :label="$t('address_create.colony_label')"
              :placeholder="$t('address_create.colony_placeholder')"
              :invalid_feedback="form_invalid_feedback.colony"
              :state="form_state.colony"
              v-model.trim="form.colony"
            />
            <VBootstrapFormGroup
              required
              name="federal_entity"
              :label="$t('address_create.federal_entity_label')"
              :placeholder="$t('address_create.federal_entity_placeholder')"
              :invalid_feedback="form_invalid_feedback.federal_entity"
              :state="form_state.federal_entity"
              v-model.trim="form.federal_entity"
            />
            <VBootstrapFormGroup
              required
              name="postal_code"
              :label="$t('address_create.postal_code_label')"
              :placeholder="$t('address_create.postal_code_placeholder')"
              :invalid_feedback="form_invalid_feedback.postal_code"
              :state="form_state.postal_code"
              v-model.trim="form.postal_code"
            />
            <VBootstrapFormGroup
              required
              name="phone"
              type="phone"
              :label="$t('address_create.phone_label')"
              :placeholder="$t('address_create.phone_placeholder')"
              :invalid_feedback="form_invalid_feedback.phone"
              :state="form_state.phone"
              v-model.trim="form.phone"
            />
          </b-col>

          <b-col cols="12" lg="6" md="6" sm="12">
            <VBootstrapFormGroup
              name="business_name"
              :label="$t('address_create.business_name_label')"
              :placeholder="$t('address_create.business_name_placeholder')"
              :invalid_feedback="form_invalid_feedback.business_name"
              :state="form_state.business_name"
              v-model.trim="form.business_name"
            />
            <VBootstrapFormGroup
              required
              name="address"
              :label="$t('address_create.address_label')"
              :placeholder="$t('address_create.address_placeholder')"
              :invalid_feedback="form_invalid_feedback.address"
              :state="form_state.address"
              v-model.trim="form.address"
            />
            <VBootstrapFormGroup
              required
              name="town"
              :label="$t('address_create.town_label')"
              :placeholder="$t('address_create.town_placeholder')"
              :invalid_feedback="form_invalid_feedback.town"
              :state="form_state.town"
              v-model.trim="form.town"
            />
            <VBootstrapFormGroup
              required
              name="country"
              type="country"
              :label="$t('address_create.country_label')"
              :placeholder="$t('address_create.country_placeholder')"
              :invalid_feedback="form_invalid_feedback.country"
              :state="form_state.country"
              v-model.trim="form.country"
            />
            <VBootstrapFormGroup
              required
              name="mobile"
              type="phone"
              :label="$t('address_create.mobile_label')"
              :placeholder="$t('address_create.mobile_placeholder')"
              :invalid_feedback="form_invalid_feedback.mobile"
              :state="form_state.mobile"
              v-model.trim="form.mobile"
            />
          </b-col>
        </b-row>
      </b-container>
      <div class="buttons-container">
        <b-button
          @click="$router.go(-1)"
          variant="secondary"
          class="d-none d-sm-none d-md-block"
        >{{ $t('address_create.return_button') }}</b-button>

        <b-button
          variant="primary"
          size="lg"
          type="submit"
        >{{ $t('address_create.save_button') }}</b-button>
      </div>

    </b-form>
  </div>
</template>


<script>
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import { mapState } from "vuex";

export default {
  name: "patient-create-form",
  data() {
    return {
      form: {
        firstname: "",
        identification_number: "",
        colony: "",
        federal_entity: "",
        postal_code: "",
        phone: "",
        business_name: "",
        address: "",
        town: "",
        country: "",
        mobile: "",
      },
      form_invalid_feedback: {},
      form_state: {},
      show: true,
      updated: false,
      loading: false,
      pt_uuid: this.$route.params.pt_uuid,
    };
  },
  computed: {
    ...mapState({
      person: (state) => state.naturalperson.person,
    }),
  },
  props: {
    a_uuid: String,
  },
  fallbackI18n: {
    messages: {
      und: {
        address_create: {
          country_label: "Country",
          country_placeholder: "Country",
          business_name_label: "Business",
          business_name_placeholder: "Business",
          firstname_label: "Firstname",
          firstname_placeholder: "Firstname",
          lastname_label: "Lastname",
          lastname_placeholder: "Lastname",
          mobile_label: "Mobile",
          mobile_placeholder: "Mobile",
          phone_label: "Phone",
          phone_desc: "Phone",
          phone_placeholder: "Phone",
          phone_extension_label: "Phone extension",
          phone_extension_placeholder: "Phone extension",
          identification_number_placeholder: "Identification number",
          identification_number_label: "Identification number",
          identification_type_label: "Identification type",
          identification_type_placeholder: "Identification type",
          identification_expiration_label: "Identification expiration",
          identification_expiration_placeholder: "Identification expiration",
          address_label: "Tax address",
          address_placeholder: "Tax address",
          town_label: "Tax town",
          town_placeholder: "Tax town",
          colony_label: "Tax colony",
          colony_placeholder: "Tax colony",
          postal_code_label: "Tax postal code",
          postal_code_placeholder: "Tax postal code",
          federal_entity_label: "Tax federal entity",
          federal_entity_placeholder: "Tax federal entity",
          id_type_passport: "Passport",
          id_type_ine: "INE",
          submit_button: "Save",
          reset_button: "Reset",
          address_added: "Address added",
          main: "Main Address",
          save_button: "Save",
          return_button: "Back",
        },
      },
    },
  },
  created() {
    this.loadPerson();

    if (this.a_uuid) {
      this.loadAddress();
    }
  },
  watch: {
    $route() {
      this.loadPerson();
      if (this.a_uuid) {
        this.loadAddress();
      } else {
        this.loadPatientData();
      }
    },
  },
  methods: {
    loadPerson: function () {
      this.loading = true;
      this.$store
        .dispatch("naturalperson/REQUEST_PERSON", { pt_uuid: this.pt_uuid })
        .then(
          () => {
            this.loading = false;
          },
          (e) => {
            this.loading = false;
            this.$sentry({ capture: e });
            this.$router.push({ name: "fallback-error-page" });
          }
        );
    },
    loadAddress() {
      this.loading = true;
      this.$store
        .dispatch("naturalperson/GET_ADDRESS", {
          a_uuid: this.a_uuid,
          pt_uuid: this.pt_uuid,
        })
        .then(
          (response) => {
            this.loading = false;
            var address = response.data;
            if (address) {
              this.form = address;
              this.a_uuid = address.uuid;
              this.updated = true;
            }
          },
          (e) => {
            this.loading = false;
            this.$sentry({ capture: e });
            this.$router.push({ name: "fallback-error-page" });
          }
        );
    },
    sendForm(evt) {
      evt.preventDefault();
      var data = this.form;
      data.person = this.person.uuid;
      var url = "naturalperson/NEW_ADDRESS";
      var data_send = { data: data, pt_uuid: this.pt_uuid };
      if (this.updated) {
        url = "naturalperson/PATCH_ADDRESS";
        data_send = { data: data, a_uuid: this.a_uuid, pt_uuid: this.pt_uuid };
      }
      this.$store.dispatch(url, data_send).then(
        () => {
          this.$router.push({
            name: "addresses",
            props: { pt_uuid: this.pt_uuid },
          });
        },
        (err) => {
          this.showResponseError(err);
          this.putFormFeedback(err.response);
          this.mnotify_error({
            text: this.$t("patient_create.error.saving_patientdata"),
          });
        }
      );
    },
    beforeMount: function () {
      this.loadPerson();
    },
    mounted: function () {
      this.loadPerson();
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      for (var prop in this.form) {
        if (this.form.hasOwnProperty(prop)) {
          delete this.form[prop];
        }
      }
      /* Trick to reset/clear native browser form validation state */
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
  components: {
    VBootstrapFormGroup,
  },
};
</script>
