<template>
  <div>
    <vue-element-loading :active="loading" :is-full-screen="true" />
    <b-form v-if="show" @submit="restUpdateInsuranceRefund">
      <b-container fluid class="pr-0 pl-0">
        <b-row>
          <b-col cols="12" lg="6" md="6" sm="12">
            <div id="invoice-left-form" class="mr-md-3">
              <legend
                class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 text-uppercase"
              >{{ $t('insurance_refund_invoice.patient_data_title') }}</legend>
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                class="text-left"
                :label="$t('insurance_refund_invoice.firstname_label')"
                :invalid-feedback="form_invalid_feedback.patient_address.firstname"
                :state="form_state.patient_address.firstname"
              >
                <template v-slot:label>
                  {{ $t('insurance_refund_invoice.firstname_label') }} <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="patient_address-firstname"
                    type="text"
                    v-model.trim="form.patient_address.firstname"
                    required
                    :state="form_state.patient_address.firstname"
                    :placeholder="$t('insurance_refund_invoice.firstname_placeholder')"
                  ></b-form-input>
                  <b-input-group-append>
                    <VMajorMedicalInsurancePatientSelector
                      v-if="$route.params.i_uuid"
                      v-on:patient-selected="onPatientSelected"
                      :preset-filters="{manager_uuid: $route.params.i_uuid}"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <VBootstrapFormGroup
                name="email"
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                required
                :label="$t('insurance_refund_invoice.email_label')"
                :placeholder="$t('insurance_refund_invoice.email_placeholder')"
                :invalid_feedback="form_invalid_feedback.patient_address.email"
                :state="form_state.patient_address.email"
                v-model.trim="form.patient_address.email"
              />
              <VBootstrapFormGroup
                name="policy_num"
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                required
                :label="$t('insurance_refund_invoice.policy_num_label')"
                :placeholder="$t('insurance_refund_invoice.policy_num_placeholder')"
                :invalid_feedback="form_invalid_feedback.policy_num"
                :state="form_state.policy_num"
                v-model.trim="form.policy_num"
              />
              <VBootstrapFormGroup
                name="sinister_num"
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                :label="sinister_num_label"
                :placeholder="sinister_num_placeholder"
                :invalid_feedback="form_invalid_feedback.sinister_num"
                :state="form_state.sinister_num"
                v-model.trim="form.sinister_num"
              />
              <b-row>
                <b-col cols="0" lg="4"></b-col>
                <b-col cols="0" lg="4">
                  <b-form-group
                    vertical
                    class="text-left"
                    :label="$t('insurance_refund_invoice.deductible_label')"
                    label-for="deductible"
                    :invalid-feedback="form_invalid_feedback.insurance.deductible"
                    :state="form_state.insurance.deductible"
                  >
                    <b-input-group>
                      <b-form-input
                        id="deductible"
                        type="text"
                        v-model.trim="form.insurance.deductible"
                        :state="form_state.insurance.deductible"
                        :placeholder="$t('insurance_refund_invoice.deductible_placeholder')"
                      ></b-form-input>
                      <b-dropdown
                        size="sm"
                        :text="form.insurance.deductible_currency"
                        variant="outline-primary-thin"
                        slot="append"
                      >
                        <b-dropdown-item @click="toggleDeductibleCurrency('USD');">USD</b-dropdown-item>
                        <b-dropdown-item @click="toggleDeductibleCurrency('MXN');">MXN</b-dropdown-item>
                      </b-dropdown>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="0" lg="4" class="pl-1">
                  <b-form-group
                    vertical
                    class="text-left pl-2"
                    :label="$t('insurance_refund_invoice.coinsurance_label')"
                    label-for="coinsurance"
                    :invalid-feedback="form_invalid_feedback.insurance.coinsurance"
                    :state="form_state.insurance.coinsurance"
                  >
                    <b-input-group>
                      <b-form-input
                        id="coinsurance"
                        type="text"
                        v-model.trim="form.insurance.coinsurance"
                        :state="form_state.insurance.coinsurance"
                        :placeholder="$t('insurance_refund_invoice.coinsurance_placeholder')"
                      ></b-form-input>
                      <b-dropdown
                        size="sm"
                        :text="form.coinsurance_type_string"
                        variant="outline-primary-thin"
                        slot="append"
                      >
                        <b-dropdown-item @click="toggleCoinsuranceType('MXN');">MXN</b-dropdown-item>
                        <b-dropdown-item @click="toggleCoinsuranceType('USD');">USD</b-dropdown-item>
                        <b-dropdown-item @click="toggleCoinsuranceType(0);">%</b-dropdown-item>
                      </b-dropdown>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6" sm="12">
            <div id="invoice-right-form" class="ml-md-3">
              <legend
                class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 text-uppercase"
              >{{ $t('insurance_refund_invoice.recipe_files_title') }}</legend>
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                class="text-left"
                :label="$t('insurance_refund_invoice.recipe_file_label')"
                label-for="recipe_file"
                :invalid-feedback="form_invalid_feedback.recipe_file"
                :state="form_state.recipe_file"
              >
                <template v-slot:label>
                  {{ $t('insurance_refund_invoice.recipe_file_label') }} <span class="text-danger">*</span>
                </template>
                <div v-if="form._recipe_file">
                  <b-link
                    :href="form._recipe_file"
                    target="_blank"
                  >{{ $t('insurance_refund_invoice.file') }}: {{ form.recipe_file_original_filename }}</b-link>
                </div>
                <b-form-file
                  v-model="form.recipe_file"
                  :required="!form._recipe_file"
                  class="z-i0"
                  :state="Boolean(form.recipe_file) || form.recipe_file"
                  :placeholder="$t('insurance_refund_invoice.recipe_file_placeholder')"
                  :browse-text="$t('insurance_refund_invoice.recipe_file_browse_text')"
                ></b-form-file>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                class="text-left"
                :label="$t('insurance_refund_invoice.deductible_payment_file_label')"
                label-for="deductible_payment_file"
                :invalid-feedback="form_invalid_feedback.deductible_payment_file"
                :state="form_state.deductible_payment_file"
              >
                <div v-if="form._deductible_payment_file">
                  <b-link
                    :href="form._deductible_payment_file"
                    target="_blank"
                  >{{ $t('insurance_refund_invoice.file') }}: {{ form.deductible_payment_file_original_filename }}</b-link>
                </div>
                <b-form-file
                  v-model="form.deductible_payment_file"
                  class="z-i0"
                  :state="Boolean(form.deductible_payment_file) || form.deductible_payment_file"
                  :placeholder="$t('insurance_refund_invoice.deductible_payment_file_placeholder')"
                  :browse-text="$t('insurance_refund_invoice.deductible_upload_browse_text')"
                ></b-form-file>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                class="text-left"
                :label="$t('insurance_refund_invoice.coinsurance_payment_file_label')"
                label-for="coinsurance_payment_file"
                :invalid-feedback="form_invalid_feedback.coinsurance_payment_file"
                :state="form_state.coinsurance_payment_file"
              >
                <div v-if="form._coinsurance_payment_file">
                  <b-link
                    :href="form._coinsurance_payment_file"
                    target="_blank"
                  >{{ $t('insurance_refund_invoice.file') }}: {{ form.coinsurance_payment_file_original_filename }}</b-link>
                </div>
                <b-form-file
                  v-model="form.coinsurance_payment_file"
                  class="z-i0"
                  :state="Boolean(form.coinsurance_payment_file) || form.coinsurance_payment_file"
                  :placeholder="$t('insurance_refund_invoice.coinsurance_payment_file_placeholder')"
                  :browse-text="$t('insurance_refund_invoice.deductible_upload_browse_text')"
                ></b-form-file>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-modal
        id="invoice-modal"
        size="xl"
        modal-class="modal-square-theme"
        v-model="invoiceModalShow"
        centered
        :title="$t('insurance_refund_invoice.invoice_add_title')"
        @ok="handleInvoiceModalOkButton($event)"
        :ok-title="$t('insurance_refund_invoice.invoice_add_ok_button')"
        :cancel-title="$t('insurance_refund_invoice.invoice_add_cancel_button')"
        cancel-variant="secondary"
        ok-variant="primary"
        :cancel-disabled="invoice_loading"
        :ok-disabled="invoice_loading"
        :busy="invoice_loading"
        :hide-header="invoice_loading"
        :no-close-on-backdrop="invoice_loading"
        :no-close-on-esc="invoice_loading"
      >
        <vue-element-loading :active="invoice_loading" />
        <legend
          class="col-form-label text-uppercase col-form-label-lg font-weight-bold pt-0 mb-3 title"
        >{{ $t('insurance_refund_invoice.invoice_add_subtitle') }}</legend>
        <b-container fluid>
          <b-row>
            <b-col cols="12" lg="6" md="6" sm="12">
              <VBootstrapFormGroup
                name="provider_name"
                required
                :label="$t('insurance_refund_invoice.provider_name_label')"
                :placeholder="$t('insurance_refund_invoice.provider_name_placeholder')"
                :invalid_feedback="invoice_form_invalid_feedback.provider_name"
                :state="invoice_form_state.provider_name"
                v-model.trim="invoice_form.provider_name"
              />
              <VBootstrapFormGroup
                name="provider_business_name"
                required
                :label="$t('insurance_refund_invoice.provider_business_name_label')"
                :placeholder="$t('insurance_refund_invoice.provider_business_name_placeholder')"
                :invalid_feedback="invoice_form_invalid_feedback.provider_business_name"
                :state="invoice_form_state.provider_business_name"
                v-model.trim="invoice_form.provider_business_name"
              />
              <VBootstrapFormGroup
                name="provider_rfc"
                required
                :label="$t('insurance_refund_invoice.provider_rfc_label')"
                :placeholder="$t('insurance_refund_invoice.provider_rfc_placeholder')"
                :invalid_feedback="invoice_form_invalid_feedback.provider_rfc"
                :state="invoice_form_state.provider_rfc"
                v-model.trim="invoice_form.provider_rfc"
              />
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="4"
                label-cols-xl="4"
                class="text-left"
                :label="$t('insurance_refund_invoice.invoice_file_label')"
                label-for="invoice_file"
                :invalid-feedback="invoice_form_invalid_feedback.invoice_file"
                :state="invoice_form_state.invoice_file"
              >
                <template v-slot:label>
                  {{ $t('insurance_refund_invoice.invoice_file_label') }} <span class="text-danger">*</span>
                </template>
                <div v-if="invoice_form._invoice_file">
                  <b-link
                    :href="form._invoice_file"
                    target="_blank"
                  >{{ $t('insurance_refund_invoice.file_name') }}: {{ invoice_form.invoice_file_original_filename }}</b-link>
                </div>
                <b-form-file
                  v-model="invoice_form.invoice_file"
                  class="z-i0"
                  :state="Boolean(invoice_form.invoice_file) || invoice_form.invoice_file"
                  accept="image/jpeg, image/png, image/bmp, image/tiff, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  :placeholder="$t('insurance_refund_invoice.invoice_file_placeholder')"
                  :browse-text="$t('insurance_refund_invoice.invoice_file_browse_text')"
                ></b-form-file>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="4"
                label-cols-xl="4"
                class="text-left"
                :label="$t('insurance_refund_invoice.invoice_file_xml_label')"
                label-for="invoice_file_xml"
                :invalid-feedback="invoice_form_invalid_feedback.invoice_file_xml"
                :state="invoice_form_state.invoice_file_xml"
              >
                <template v-slot:label>
                  {{ $t('insurance_refund_invoice.invoice_file_xml_label') }} <span class="text-danger">*</span>
                </template>
                <div v-if="invoice_form._invoice_file_xml">
                  <b-link
                    :href="form._invoice_file_xml"
                    target="_blank"
                  >{{ $t('insurance_refund_invoice.file_name') }}: {{ invoice_form.invoice_file_xml_original_filename }}</b-link>
                </div>
                <b-form-file
                  v-model="invoice_form.invoice_file_xml"
                  class="z-i0"
                  :state="Boolean(invoice_form.invoice_file_xml) || invoice_form.invoice_file_xml"
                  accept="application/xml"
                  :placeholder="$t('insurance_refund_invoice.invoice_file_xml_placeholder')"
                  :browse-text="$t('insurance_refund_invoice.invoice_file_xml_browse_text')"
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="6" sm="12">
              <b-card class="rounded" bg-variant="original" border-variant="original" text-variant>
                <div slot="header">
                  <VBootstrapFormGroup
                    name="tax_folio_num"
                    required
                    :label="$t('insurance_refund_invoice.tax_folio_num_label')"
                    :placeholder="$t('insurance_refund_invoice.tax_folio_num_placeholder')"
                    :invalid_feedback="invoice_form_invalid_feedback.tax_folio_num"
                    :state="invoice_form_state.tax_folio_num"
                    v-model.trim="invoice_form.tax_folio_num"
                    stack-form-group
                  />
                </div>
                <b-container fluid>
                  <b-row>
                    <b-col cols="12" lg="6" md="6" sm="12">
                      <VBootstrapFormGroup
                        label-cols="12"
                        label-cols-lg="12"
                        label-cols-xl="12"
                        size="sm"
                        name="serial_num"
                        required
                        :label="$t('insurance_refund_invoice.serial_num_label')"
                        :placeholder="$t('insurance_refund_invoice.serial_num_placeholder')"
                        :invalid_feedback="invoice_form_invalid_feedback.serial_num"
                        :state="invoice_form_state.serial_num"
                        v-model.trim="invoice_form.serial_num"
                        stack-form-group
                      />
                    </b-col>
                    <b-col cols="12" lg="6" md="6" sm="12">
                      <VBootstrapFormGroup
                        label-cols="12"
                        label-cols-lg="12"
                        label-cols-xl="12"
                        size="sm"
                        name="folio_num"
                        required
                        :label="$t('insurance_refund_invoice.folio_num_label')"
                        :placeholder="$t('insurance_refund_invoice.folio_num_placeholder')"
                        :invalid_feedback="invoice_form_invalid_feedback.folio_num"
                        :state="invoice_form_state.folio_num"
                        v-model.trim="invoice_form.folio_num"
                        stack-form-group
                      />
                    </b-col>
                  </b-row>
                </b-container>
                <VBootstrapFormGroup
                  type="datetime"
                  label-cols="12"
                  label-cols-lg="12"
                  label-cols-xl="12"
                  size="sm"
                  name="invoice_datetime"
                  required
                  :label="$t('insurance_refund_invoice.invoice_datetime_label')"
                  :placeholder="$t('insurance_refund_invoice.invoice_datetime_placeholder')"
                  :invalid_feedback="invoice_form_invalid_feedback.invoice_datetime"
                  :state="invoice_form_state.invoice_datetime"
                  v-model.trim="invoice_form.invoice_datetime"
                  stack-form-group
                />
                <VBootstrapFormGroup
                  label-cols="12"
                  label-cols-lg="12"
                  label-cols-xl="12"
                  size="sm"
                  name="issuer_certificate_serial_num"
                  required
                  :label="$t('insurance_refund_invoice.issuer_certificate_serial_num_label')"
                  :placeholder="$t('insurance_refund_invoice.issuer_certificate_serial_num_placeholder')"
                  :invalid_feedback="invoice_form_invalid_feedback.issuer_certificate_serial_num"
                  :state="invoice_form_state.issuer_certificate_serial_num"
                  v-model.trim="invoice_form.issuer_certificate_serial_num"
                  stack-form-group
                />

                <VBootstrapFormGroup
                  label-cols="12"
                  label-cols-lg="12"
                  label-cols-xl="12"
                  size="sm"
                  name="sat_certificate_serial_num"
                  required
                  :label="$t('insurance_refund_invoice.sat_certificate_serial_num_label')"
                  :placeholder="$t('insurance_refund_invoice.sat_certificate_serial_num_placeholder')"
                  :invalid_feedback="invoice_form_invalid_feedback.sat_certificate_serial_num"
                  :state="invoice_form_state.sat_certificate_serial_num"
                  v-model.trim="invoice_form.sat_certificate_serial_num"
                  stack-form-group
                />
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <legend
                class="col-form-label text-uppercase col-form-label-lg font-weight-bold pt-0 mb-3 title"
              >{{ $t('insurance_refund_invoice.add_products_title') }}</legend>
              <b-container fluid class="pb-4">
                <b-row>
                  <b-col cols="12" xl="4" lg="4" sm="4">
                    <b-form>
                      <VBootstrapSearchInput
                        v-model="invoice_search_term"
                        :placeholder="$t('insurance_refund_drug_selector.search_placeholder')"
                        v-on:submit="onSearchSubmit($event)"
                      ></VBootstrapSearchInput>
                    </b-form>
                  </b-col>
                  <b-col cols="12" xl="4" lg="4" sm="4"></b-col>
                  <b-col cols="12" xl="4" lg="4" sm="4">
                    <VPlusLink
                      @click="manualDrugSelectorShow = true"
                      icon="plus-circle"
                    >{{ $t('insurance_refund_invoice.add_manual_product_link') }}</VPlusLink>
                  </b-col>
                </b-row>
              </b-container>
              <VDrugsSummary
                id="invoice-drugs-summary"
                :items="invoice_products"
                actionbutton
                dirtycolumn
                actionscolumn
                :showprovider="false"
                :state="drugs_validation_state"
                :invalid-feedback="$t('insurance_refund_invoice.error.no_drugs')"
                v-on:actionButtonClick="drugSelectorShow = true"
                v-on:deteleButtonClick="removeProductFromInvoiceForm"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal
        id="existent-drugs-modal"
        size="xl"
        modal-class="modal-square-theme"
        v-model="drugSelectorShow"
        centered
        :title="$t('insurance_refund_invoice.drug_selector_title')"
        :hide-footer="!tmp_stack_product"
        @ok="moveTemporallyStackProductToInvoice($event)"
        @cancel="handleInvoiceDrugModalCancelButton($event)"
        :ok-title="$t('insurance_refund_invoice.drug_selector_primary_button')"
        :cancel-title="$t('insurance_refund_invoice.drug_selector_secondary_button')"
        cancel-variant="secondary"
        ok-variant="primary"
        :ok-disabled="(
          // No activamos el botón de añadir el producto hasta que
          // se validen los datos introducidos
          // Esto además valida si el valor introducido es un número
          !tmp_stack_product ||
            Number(tmp_stack_product.userprice) <= 0 ||
            isNaN(Number(tmp_stack_product.userprice))
        )"
      >
        <b-container fluid>
          <b-row>
            <b-col>
              <span v-if="tmp_stack_product == undefined">
                {{ $t('insurance_refund_invoice.drug_selector_help_text') }}
              </span>
              <InsuranceRefundInvoiceDrugSelector
                v-on:product:selected="temporallyStackProduct"
                :init_search="invoice_search_term"
                v-on:extra_fields_changed="handleTmpStackProductExtraFields"
                v-on:drugs_loading_stop="invoice_search_term=undefined"
                :show_selected="!(tmp_stack_product == undefined)"
                :i_uuid="this.$route.params.i_uuid"
                userprices
                :perPage="5"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-modal>

      <b-modal
        id="manual-drugs-modal"
        size="xl"
        modal-class="modal-square-theme"
        v-model="manualDrugSelectorShow"
        centered
        :title="$t('insurance_refund_invoice.manual_drug_selector_title')"
        :hide-footer="false"
        @ok="moveTemporallyStackProductToInvoice($event)"
        @cancel="handleInvoiceDrugModalCancelButton($event)"
        :ok-title="$t('insurance_refund_invoice.drug_selector_primary_button')"
        :cancel-title="$t('insurance_refund_invoice.drug_selector_secondary_button')"
        cancel-variant="secondary"
        ok-variant="primary"
        :ok-disabled="(
          // No activamos el botón de añadir el producto hasta que
          // se validen los datos introducidos
          // Esto además valida si el valor introducido es un número
          !tmp_stack_product ||
            Number(tmp_stack_product.userprice) <= 0 ||
            isNaN(Number(tmp_stack_product.userprice)) ||
            !tmp_stack_product.manual_product_name
        )"
      >
        <b-container fluid>
          <b-row>
            <b-col>
              <InsuranceRefundInvoiceDrugSelector
                v-on:product:selected="temporallyStackProduct"
                v-on:extra_fields_changed="handleTmpStackProductExtraFields"
                :show_selected="!(tmp_stack_product == undefined)"
                :i_uuid="this.$route.params.i_uuid"
                manual
                userprices
                :perPage="5"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-modal>

      <b-container fluid class="mt-5 pl-0 pr-0" no-gutters>
        <b-row no-gutters>
          <b-col col lg="12" md="auto">
            <vue-element-loading :active="loading_invoices" :is-full-screen="false" />
            <i18n path="insurance_refund_invoice.up_button_explain_text" tag="div">
              <span place="add_invoice_link">
                <strong class="text-uppercase">
                  {{ $t('insurance_refund_invoice.add_invoice_link') }}*
                </strong>
              </span>
            </i18n>
            <b-button @click="loadNewInvoice()" variant="outline-primary-inline-compact">
              <b-icon class="mr-1" icon="plus-circle" aria-hidden="true"></b-icon> {{ $t('insurance_refund_invoice.add_invoice_link') }}*
            </b-button>
            <vue-element-loading :active="products_summary_loading" />
            <div v-for="(invoice, index) in allinvoices" :key="index">
              <h5
                class="text-uppercase mt-5"
              >{{ $t('insurance_refund_invoice.invoice_druglist_title') }} {{ invoice.tax_folio_num }} {{ invoice.folio_num }}</h5>
              <VDrugsSummary
                :items="invoice.item_list.drugs"
                dirtycolumn
                actionbutton
                :showprovider="false"
                :actionbuttontext="$t('insurance_refund_invoice.edit_invoice_button')"
                actionbuttonicon="pen"
                v-on:actionButtonClick="loadInvoiceAndShowForm(invoice)"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
      <VDrugsSummary
        v-if="allinvoices && allinvoices.length > 0"
        :items="allproducts"
        :amount="amount"
        :showdrugtable="false"
        v-on:summary:currency_switch="switchCurrency()"
      />
      <b-container fluid class="mt-5 pr-0" no-gutters>
        <b-row no-gutters class="pr-0">
          <b-col col lg="12" md="auto">
            <vue-element-loading :active="save_loading" />
            <b-button
              type="submit"
              id="save-button"
              variant="primary"
              class="pull-right"
            >{{ $t('insurance_refund_invoice.button_save') }}</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>


<script>
import InsuranceRefundInvoiceDrugSelector from "@/components/insurancerefund/InsuranceRefundInvoiceDrugSelector";
import VMajorMedicalInsurancePatientSelector from "@/components/VMajorMedicalInsurancePatientSelector";
import VBootstrapSearchInput from "@/components/VBootstrapSearchInput";
import VDrugsSummary from "@/components/insurancerefund/VDrugsSummary";
import VPlusLink from "@/components/VPlusLink";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { buildAmount } from "@/lib/amount.js";
import moment from "moment";

export default {
  name: "insurancerefund-invoice",
  data() {
    return {
      drugs_validation_state: null,
      invoice_search_term: undefined,
      invoiceModalShow: false,
      drugSelectorShow: false,
      manualDrugSelectorShow: false,

      tmp_stack_product: undefined,
      invoice_products_to_be_deleted: [],
      invoice_products: [],
      invoice_form: {},
      invoice_form_state: {},
      invoice_form_invalid_feedback: {},
      form: {
        patient_address: {
          firstname: "",
          lastname: " ",
          email: "",
        },
        invoice_num: "",
        invoice_file: null,
        invoice_file_xml: null,
        insurance: {
          coinsurance: undefined,
          deductible: undefined,
          coinsurance_currency: undefined,
          coinsurance_type: 0,
          deductible_currency: "MXN",
        },
        // esto lo sacamos fuera de insurance{} porque realmente no es parte
        // del objeto insurance. Además, presenta problemas en Observer si
        // lo mantenemos dentro de insurance{} (no se actualiza en el view
        // aunque se actualice en data.form.insurance.)
        //
        // Lo mantenemos dentro de form{} porque queremos que este valor esté
        // disponible en componentes hijos a los que se le pasa el subobjeto
        // form{}
        coinsurance_type_string: "%",
        preferred_currency: "MXN",
      },
      form_invalid_feedback: {
        insurance: {
          coinsurance: undefined,
          deductible: undefined,
          coinsurance_type: undefined,
          coinsurance_currency: undefined,
          deductible_currency: undefined,
        },
        patient_address: {
          firstname: undefined,
          lastname: undefined,
          email: undefined,
        },
      },
      form_state: {
        insurance: {
          coinsurance: undefined,
          deductible: undefined,
          coinsurance_type: undefined,
          coinsurance_currency: undefined,
          deductible_currency: undefined,
        },
        patient_address: {
          firstname: undefined,
          lastname: undefined,
          email: undefined,
        },
      },
      show: false,
      loading: true,
      loading_invoices: false,
      invoice_loading: false,
      products_summary_loading: false,
      save_loading: false,
    };
  },
  computed: {
    ...mapGetters("organization", ["getInsuranceConfiguration"]),
    ...mapGetters("insurancerefund", ["getDraftInsuranceRefundByUUID"]),
    ...mapState({
      invoicelist: (state) => state.refundinvoice.invoicelist,
    }),
    ...mapState({
      is_insurance_refund_list_loaded: (state) =>
        state.organization.is_insurance_refund_list_loaded,
    }),
    allinvoices: function () {
      return this.invoicelist.results;
    },
    insuranceconfiguration: function () {
      return this.getInsuranceConfiguration(this.$route.params.i_uuid);
    },
    sinister_num_label: function () {
      let configuration = this.insuranceconfiguration;
      if (!configuration || !configuration.sinister_num_type) {
        return undefined;
      }
      if (configuration.sinister_num_type == 1) {
        return this.$t("insurance_refund_invoice.sinister_num_label");
      }

      if (configuration.sinister_num_type == 2) {
        return this.$t("insurance_refund_invoice.claim_num_label");
      }
      return undefined;
    },
    sinister_num_placeholder: function () {
      let configuration = this.insuranceconfiguration;
      if (!configuration || !configuration.sinister_num_type) {
        return undefined;
      }
      if (configuration.sinister_num_type == 1) {
        return this.$t("insurance_refund_invoice.sinister_num_placeholder");
      }
      if (configuration.sinister_num_type == 2) {
        return this.$t("insurance_refund_invoice.claim_num_placeholder");
      }
      return undefined;
    },
    // Deshabilitamos la caché porque necesitamos en todo momento
    // los productos actualizados para realizar el cálculo del total.
    allproducts: {
      cache: false,
      get() {
        if (!this.allinvoices) {
          return undefined;
        }
        let products = [];
        this.allinvoices.forEach((invoice) => {
          invoice.item_list.drugs.forEach((element) => {
            products.push(Object.assign({}, element));
          });
        });
        return products;
      },
    },
    // Deshabilitamos la caché en amount porque necesitamos cálculo
    // contínuo del amount, de otro modo modificaríamos el coaseguro
    // o el deducible y no obtendríamos el total actualizado
    amount: {
      cache: false,
      get() {
        var amount = buildAmount(
          this.allproducts,
          {
            coinsurance: this.form.insurance.coinsurance,
            coinsurance_type: this.form.insurance.coinsurance_type,
            deductible: this.form.insurance.deductible,
            deductible_currency: this.form.insurance.deductible_currency,
            coinsurance_currency: this.form.insurance.coinsurance_currency,
          },
          this.form.preferred_currency,
          // true to spread risk using coinsurance and deductible
          true
        );
        // la primera vez que se llama a buildAmount, form.preferred_currency
        // es == undefined. buildAmount buscará la moneda más adecuada (mirando
        // las monedas de los productos), con lo cual, una vez llamado a
        // buildAmount, podemos colocar la moneda encontrada en
        // form.preferred_currency
        // if (this.amount.preferred_currency) {
        //   this.form.preferred_currency = this.amount.preferred_currency;
        // }
        return amount;
      },
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_refund_invoice: {
          edit_invoice_button: "Edit invoice",
          add_invoice_link: "Add invoice",
          invoice_druglist_title: "Invoice",
          invoice_add_subtitle: "Invoice data",
          invoice_add_title: "Catch invoice",
          button_save: "Save",
          drug_selector_title: "Select drug",
          drug_selector_primary_button: "Append",
          drug_selector_secondary_button: "Back",
          provider_name_label: "Provider",
          provider_name_placeholder: "Provider",
          provider_business_name_label: "Provider legal name",
          provider_business_name_placeholder: "Provider legal name",
          provider_rfc_label: "Provider RFC",
          provider_rfc_placeholder: "Provider RFC",
          file_name: "File name",
          invoice_file_label: "File",
          invoice_file_placeholder: "Select a file...",
          invoice_file_browse_text: "",
          invoice_file_xml_label: "XML File",
          invoice_file_xml_placeholder: "Select a xml file...",
          invoice_file_xml_browse_text: "",
          recipe_file_browse_text: "Browse",
          tax_folio_num_label: "Folio number",
          tax_folio_num_placeholder: "Folio number",
          serial_num_label: "Serial Num",
          serial_num_placeholder: "Serial Num",
          folio_num_label: "Folio Num",
          folio_num_placeholder: "Folio Num",
          invoice_datetime_label: "Invoice date and time",
          invoice_datetime_placeholder: "dd/mm/aa 00:00:00",
          issuer_certificate_serial_num_label:
            "Issuer Certificate serial number",
          issuer_certificate_serial_num_placeholder:
            "Issuer Certificate serial number",
          sat_certificate_serial_num_label: "SAT Certificate serial number",
          sat_certificate_serial_num_placeholder:
            "SAT Certificate serial number",
          add_products_title: "Add products",
          invoice_add_ok_button: "Save invoice",
          invoice_add_cancel_button: "Cancel",
          deductible_payment_file_label: "Deductible",
          deductible_payment_file_placeholder: "Add deductible payment",
          coinsurance_payment_file_label: "Coinsurance",
          coinsurance_payment_file_placeholder: "Add coinsurance payment",
          firstname_label: "Patient name",
          firstname_placeholder: "Patient name",
          email_label: "Email",
          email_placeholder: "Email",
          policy_num_label: "Policy number",
          policy_num_placeholder: "Policy number",
          incident_num_label: "Incident number",
          incident_num_placeholder: "Incident number",
          patient_data_title: "Patient data",
          recipe_files_title: "Documents",
          deductible_label: "Deductible",
          deductible_placeholder: "",
          coinsurance_label: "Coinsurance",
          coinsurance_placeholder: "",
          deductible_upload_browse_text: "Browse",
          file: "File",
          recipe_file_label: "RX",
          recipe_file_placeholder: "RX",
          sinister_num_label: "Sinister number",
          sinister_num_placeholder: "Sinister number",
          manual_drug_selector_title: "Insert product manually",
          drug_selector_help_text:
            "Enter your medication in the search box and click the orange button to add it",
          claim_num_label: "Claim number",
          claim_num_placeholder: "Claim number",
          add_manual_product_link: "Insert product manually",
          up_button_explain_text:
            "Please add your invoice details by clicking on the {add_invoice_link} button",
          error: {
            no_drugs: "Please, select one drug",
            saving_product: "Error encountered while saving product",
            saving_invoice: "Error saving invoice, check form errors.",
            load_invoices: "Cannot load invoices",
            refund_not_found: "Refund not found",
          },
        },
      },
    },
  },
  mounted: function () {
    this.loadInsuranceRefundList().then(
      () => {
        this.restLoadInvoices();
        this.loadRefund();
      },
      () => {
        this.$router.push({ name: "fallback-error-page" });
      }
    );
  },
  methods: {
    ...mapActions({
      REST_LOAD_INSURANCEREFUND_ALL_INVOICES:
        "refundinvoice/REST_LOAD_INSURANCEREFUND_ALL_INVOICES",
      REST_UPSERT_INSURANCEREFUND_INVOICE:
        "refundinvoice/REST_UPSERT_INSURANCEREFUND_INVOICE",
      REST_UPSERT_INSURANCEREFUND_INVOICE_DRUG:
        "refundinvoice/REST_UPSERT_INSURANCEREFUND_INVOICE_DRUG",
      REST_DELETE_INSURANCEREFUND_INVOICE_PRODUCT:
        "refundinvoice/REST_DELETE_INSURANCEREFUND_INVOICE_PRODUCT",
      REST_UPDATE_DRAFT_INSURANCEREFUND:
        "insurancerefund/REST_UPDATE_DRAFT_INSURANCEREFUND",
      INSURANCE_REFUND_LIST: "organization/INSURANCE_REFUND_LIST",
    }),
    loadInsuranceRefundList() {
      if (!this.is_insurance_refund_list_loaded) {
        return this.INSURANCE_REFUND_LIST();
      }
      return Promise.resolve("OK");
    },
    onSearchSubmit(evt) {
      this.drugSelectorShow = true;
      evt.preventDefault();
    },
    onPatientSelected(patient) {
      const firstname = patient.firstname || "";
      const lastname = patient.lastname || "";
      this.form.patient_address.firstname = `${firstname} ${lastname}`.trim();
      this.form.patient_address.email = patient.email;
      this.form.policy_num = patient.policy_num;
      this.form.insurance.deductible = this.$formatInternal(patient.deductible);
      this.toggleDeductibleCurrency(patient.deductible_currency);
      this.form.insurance.coinsurance = this.$formatInternal(
        patient.coinsurance
      );
      if (patient.coinsurance_type === 0) {
        this.toggleCoinsuranceType(0);
      } else {
        this.toggleCoinsuranceType(patient.coinsurance_currency);
      }
    },
    // Carga el reembolso indicado
    loadRefund() {
      this.loading = true;
      this.$store
        .dispatch("insurancerefund/LOAD_ALL_INSURANCE_DRAFT_INSURANCEREFUND", {
          pt_uuid: this.$route.params.pt_uuid,
        })
        .then(
          () => {
            let insurancerefund = this.getDraftInsuranceRefundByUUID(
              this.$route.params.i_uuid,
              this.$route.params.insurancerefund_uuid
            );
            if (!insurancerefund) {
              this.$router.push({ name: "fallback-error-page" });
              this.loading = false;
              return;
            }

            this.$store.commit(
              "insurancerefund/MUTATE_DRAFT_INSURANCEREFUND",
              insurancerefund
            );
            this.form = this.preDataIn(insurancerefund);
            this.show = true;
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.$sentry({ capture: err });
            this.$router.push({ name: "fallback-error-page" });
          }
        );
    },
    // Formatea los datos recibidos de core para meterlos en this.form
    preDataIn(_data) {
      var data = Object.assign({}, _data);
      if (data.recipe_file) {
        data["_recipe_file"] = data.recipe_file;
        data.recipe_file = undefined;
      }

      if (data.deductible_payment_file) {
        data["_deductible_payment_file"] = data.deductible_payment_file;
        data.deductible_payment_file = undefined;
      }

      if (data.coinsurance_payment_file) {
        data["_coinsurance_payment_file"] = data.coinsurance_payment_file;
        data.coinsurance_payment_file = undefined;
      }

      if (data.insurance && data.insurance.coinsurance_type == 1) {
        if (!data.insurance.coinsurance_currency) {
          data["coinsurance_type_string"] = "%";
          data["insurance"]["coinsurance_type"] = 0;
        } else {
          data["coinsurance_type_string"] = data.insurance.coinsurance_currency;
        }
      } else {
        data["coinsurance_type_string"] = "%";
      }

      data.insurance.deductible = this.$formatInternal(
        data.insurance.deductible
      );
      data.insurance.coinsurance = this.$formatInternal(
        data.insurance.coinsurance
      );

      if (!data.insurance.deductible) {
        data.insurance.deductible = "0.00";
      }
      if (!data.insurance.coinsurance) {
        data.insurance.coinsurance = "0.00";
      }

      // Por defecto la moneda es MXN
      if (data.insurance.deductible == "0.00") {
        data.insurance.deductible_currency = "MXN";
      }

      return data;
    },
    // Carga todas las facturas desde core
    restLoadInvoices() {
      // Limpiamos el store de facturas (puede quedarse sucio de un reembolso
      // anterior)
      this.$store.commit("refundinvoice/MUTATE_INSURANCEREFUND_ALL_INVOICES", {
        results: [],
      });
      this.loading_invoices = true;
      this.REST_LOAD_INSURANCEREFUND_ALL_INVOICES({
        insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
        insurance_uuid: this.$route.params.i_uuid,
      }).then(
        () => {
          this.loading_invoices = false;
        },
        (err) => {
          this.loading_invoices = false;
          this.$sentry({ capture: err });
          this.$router.push({ name: "fallback-error-page" });
        }
      );
    },
    switchCurrency() {
      if (this.form.preferred_currency == "USD") {
        this.form.preferred_currency = "MXN";
      } else {
        this.form.preferred_currency = "USD";
      }
      this.$forceUpdate();
    },
    handleInvoiceModalOkButton(evt) {
      evt.preventDefault();
      this.restUpsertInvoice({
        invoice_products_to_be_deleted: this.invoice_products_to_be_deleted,
        invoice_products: this.invoice_products,
        invoice_form: this.invoice_form,
      });
    },
    _restUpdateInsuranceRefundFiles() {
      if (
        !this.form.recipe_file &&
        !this.form.deductible_payment_file &&
        !this.form.coinsurance_payment_file
      ) {
        return new Promise((resolve) => {
          resolve("NO FILES");
        });
      }
      let formData = new FormData();
      if (this.form.recipe_file) {
        formData.append("recipe_file", this.form.recipe_file);
        formData.append(
          "recipe_file_original_filename",
          this.form.recipe_file.name
        );
      }

      if (this.form.recipe_file) {
        formData.append("recipe_file", this.form.recipe_file);
        formData.append(
          "recipe_file_original_filename",
          this.form.recipe_file.name
        );
      }

      if (this.form.deductible_payment_file) {
        formData.append(
          "deductible_payment_file",
          this.form.deductible_payment_file
        );
        formData.append(
          "deductible_payment_file_original_filename",
          this.form.deductible_payment_file.name
        );
      }

      if (this.form.coinsurance_payment_file) {
        formData.append(
          "coinsurance_payment_file",
          this.form.coinsurance_payment_file
        );
        formData.append(
          "coinsurance_payment_file_original_filename",
          this.form.coinsurance_payment_file.name
        );
      }

      return this.REST_UPDATE_DRAFT_INSURANCEREFUND({
        insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
        i_uuid: this.$route.params.i_uuid,
        data: formData,
      });
    },
    // Actualiza el reembolso (las facturas van por separado)
    restUpdateInsuranceRefund(evt) {
      this.save_loading = true;
      this.loading = true;
      evt.preventDefault();
      let rawform = Object.assign({}, this.form);
      delete rawform.recipe_file;
      delete rawform.recipe_file_original_filename;
      delete rawform.deductible_payment_file;
      delete rawform.deductible_payment_file_original_filename;
      delete rawform.coinsurance_payment_file;
      delete rawform.coinsurance_payment_file_original_filename;
      if (!rawform.insurance.deductible) {
        rawform.insurance.deductible = 0;
      }
      if (!rawform.insurance.coinsurance) {
        rawform.insurance.coinsurance = 0;
      }

      this._restUpdateInsuranceRefundFiles().then(
        () => {
          this.REST_UPDATE_DRAFT_INSURANCEREFUND({
            insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
            i_uuid: this.$route.params.i_uuid,
            data: rawform,
          }).then(
            () => {
              this.routeToRefund();
              this.save_loading = false;
              this.loading = false;
            },
            (err) => {
              this.save_loading = false;
              this.loading = false;
              this.showResponseError(err);
              this.putFormFeedback(err.response);
            }
          );
        },
        (err) => {
          this.save_loading = false;
          this.loading = false;
          this.showResponseError(err);
          this.putFormFeedback(err.response);
        }
      );
    },
    routeToRefund() {
      this.$router.push({
        name: "nopatient_insurancerefund_home",
        params: {
          i_uuid: this.$route.params.i_uuid,
          insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
        },
      });
    },
    // Crea o actualiza la factura actual de this.invoice_form,
    // this.invoice_products, etc
    restUpsertInvoice(payload) {
      this._restSaveInvoiceForm(payload);
    },
    // Guarda la factura pasada por payload en core. Recupera el uuid de la
    // factura y la guarda en el objeto de form (necesario para borrar/guardar
    // productos.)
    _restSaveInvoiceForm(payload) {
      // crea un objeto de formulario. Necesario porque este form contiene
      // archivos.
      let formData = new FormData();
      for (var key in payload.invoice_form) {
        if (
          payload.invoice_form[key] === null ||
          payload.invoice_form[key] === undefined
        ) {
          continue;
        }
        if (key == "invoice_datetime") {
          let parsed = moment(
            payload.invoice_form[key],
            "DD/MM/YYYY hh:mm A"
          ).format("YYYY-MM-DDTHH:MM");
          formData.append(key, parsed);
        } else {
          formData.append(key, payload.invoice_form[key]);
        }
      }
      if (payload.invoice_form.invoice_file) {
        formData.append(
          "invoice_file_original_filename",
          payload.invoice_form.invoice_file.name
        );
      }
      if (payload.invoice_form.invoice_file_xml) {
        formData.append(
          "invoice_file_xml_original_filename",
          payload.invoice_form.invoice_file_xml.name
        );
      }
      // Llama al store que a su vez hace la petición a la API para
      // update/insert la factura
      this.invoice_loading = true;
      this.REST_UPSERT_INSURANCEREFUND_INVOICE({
        insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
        insurance_uuid: this.$route.params.i_uuid,
        invoice_uuid: payload.invoice_form.uuid,
        data: formData,
      }).then(
        (response) => {
          // Si la factura se crea, necesitamos el uuid para guardar
          // los productos.
          this.invoice_form["uuid"] = response.data.uuid;
          let delete_promises = this._restDeleteInvoiceProducts();
          let upsert_promises = this._restUpsertInvoiceProducts();
          let all_promises = Array.prototype.concat(
            delete_promises,
            upsert_promises
          );
          Promise.all(all_promises)
            .then(
              () => {
                this.cleanInvoiceGarbage();
                this.invoiceModalShow = false;
                this.invoice_loading = false;
              },
              (reason) => {
                this.showResponseError(reason);
                this.invoice_loading = false;
              }
            )
            .catch((err) => {
              this.showResponseError(err);
              this.invoice_loading = false;
            });
        },
        (err) => {
          this.showResponseError(err);
          this.putFormFeedback(err.response, {
            feedbackattr: "invoice_form_invalid_feedback",
            stateattr: "invoice_form_state",
          });
          this.invoice_loading = false;
        }
      );
    },
    // Elimina los resíduos dejados durante la edición/añadición de una factura
    cleanInvoiceGarbage() {
      this.invoice_form = {};
      this.invoice_products = [];
      this.invoice_form_state = {};
      this.invoice_form_invalid_feedback = {};
      this.invoice_products_to_be_deleted = [];
    },
    // Quita un producto del listado de productos a ser eliminados
    // Útil cuando ya se ha eliminado el producto.
    // Elimina los productos de core
    _restDeleteInvoiceProducts() {
      let promises = [];
      let products_to_be_deleted = this.invoice_products_to_be_deleted;
      products_to_be_deleted.forEach((product) => {
        if (product.already_deleted) {
          return;
        }
        let promise = this.REST_DELETE_INSURANCEREFUND_INVOICE_PRODUCT({
          insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
          insurance_uuid: this.$route.params.i_uuid,
          invoice_uuid: product._invoice_uuid,
          snapdrug_uuid: product.uuid,
        });
        promise.then(
          () => {
            product["already_deleted"] = true;
          },
          (err) => {
            // TODO: Mostrar error de no poder eliminar un producto
            this.$sentry({ capture: err });
            this.invoice_products.push(
              Object.assign({}, product, { dirty: true, fail_delete: true })
            );
          }
        );
        promises.push(promise);
      });
      return promises;
    },
    _restUpsertInvoiceProducts() {
      let promises = [];

      if (this.invoice_products.length <= 0) {
        this.drugs_validation_state = false;
        return new Promise((resolve, reject) => {
          reject("NO DRUGS");
        });
      }

      this.invoice_products.forEach((product) => {
        if (!product.dirt) {
          return;
        }

        // por defecto la fecha en el form no está
        // seteada, lo que se traduce en undefined,
        // pero cuando se interactúa con el input
        // y luego se borra el contenido, el parser
        // interpreta la fecha "" como inválida.
        // Aquí normalizamos esto.
        if (product.expiry_date == "Invalid date") {
          product.expiry_date = undefined;
        }

        // core permite no setear estos campos, pero no
        // permite setearlos a blank, esto es: permite
        // null pero no permite "".
        if (!product.manual_product_ean) {
          product.manual_product_ean = undefined;
        }
        if (!product.manual_laboratory_name) {
          product.manual_laboratory_name = undefined;
        }

        // core no permite más de 4 decimales, aquí normalizamos
        // el contenido
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
        // Numbers are returned as-is.
        // undefined turns into NaN.
        // null turns into 0.
        // true turns into 1; false turns into 0.
        //
        // When Number is called as a constructor (with new), it creates a Number object,
        // which is not a primitive. For example, typeof new Number(42) === "object",
        // and new Number(42) !== 42 (although new Number(42) == 42).
        let userprice = Number(product.userprice);
        if (isNaN(userprice)) {
          userprice = 0;
        }
        userprice = this._.round(userprice, 3);

        let one_promise = this.REST_UPSERT_INSURANCEREFUND_INVOICE_DRUG({
          insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
          insurance_uuid: this.$route.params.i_uuid,
          invoice_uuid: this.invoice_form.uuid,
          snapdrug_uuid: product.uuid,
          data: {
            userprice: userprice,
            userprice_currency: product.userprice_currency,
            userprice_has_vat: product.userprice_has_vat,
            parent_drug_uuid: product._parent_uuid,
            quantity: product.quantity,
            batch: product.batch,
            expiry_date: product.expiry_date,
            manual_product_ean: product.manual_product_ean,
            manual_product_name: product.manual_product_name,
            manual_laboratory_name: product.manual_laboratory_name,
          },
        });
        promises.push(one_promise);
        one_promise.then(undefined, (err) => {
          this.mnotify_error({
            text: this.$t("insurance_refund_invoice.error.saving_product"),
            debug: err,
          });
        });
      });
      return promises;
    },
    // Selección de un producto, añadimos.
    moveTemporallyStackProductToInvoice(evt) {
      evt.preventDefault();
      this.drugs_validation_state = true;
      // Agregamos a la lista de productos de la factura
      let invoice_product = Object.assign({}, this.tmp_stack_product);
      // borramos el uuid ya que hace referencia el uuid
      // del producto y no al uuid del snapshot
      invoice_product["_parent_uuid"] = invoice_product.uuid;
      delete invoice_product.uuid;
      // Indica que el producto está "sucio": necesita ser guardado en DB
      // Esto ocurre cuando se carga una factura que ya tenía productos, no
      // todos ellos necesitan serguardados, sólo los nuevos añadidos o los
      // modificados.
      invoice_product["dirt"] = true;
      this.invoice_products.push(invoice_product);
      // Quitamos del selector de productos
      this.tmp_stack_product = undefined;
      // Volvemos a mostrar el modal de factura
      this.drugSelectorShow = false;
      this.manualDrugSelectorShow = false;
      this.invoiceModalShow = true;
    },
    valueAddPercent(value, percent) {
      let _value = Number(value);
      let _percent = Number(percent);
      return Number(_value + _value * (_percent / 100));
    },
    // Calcula el precio del producto conforme se va rellenando el formulario
    // en el form. Mediante eventos se llama a este método.
    handleTmpStackProductExtraFields(extra_fields) {
      var _price = {};
      _price = {
        userprice: true,
        all_included_price: Number(extra_fields.userprice),
        all_included_price_currency: extra_fields.userprice_currency,
        base: Number(extra_fields.userprice),
        base_currency: extra_fields.userprice_currency,
        discounts: [],
        no_tax_price: Number(extra_fields.userprice),
        no_tax_price_currency: extra_fields.userprice_currency,
        taxes: [],
      };
      if (extra_fields.userprice_has_vat) {
        _price.taxes.push({
          percent_value: 16,
          tax_type: 1,
          calc_mode: 1,
        });
        _price.all_included_price = this.valueAddPercent(
          _price.no_tax_price,
          16
        );
      }
      let _product = Object.assign({}, this.tmp_stack_product, extra_fields, {
        price: _price,
      });
      this.tmp_stack_product = _product;
    },
    // Almacena un producto seleccionado de la lista de productos en una
    // variable temporal. Útil para añadirlo más tarde a una factura.
    temporallyStackProduct(payload) {
      this.tmp_stack_product = Object.assign(
        {},
        payload.product,
        payload.extra_fields
      );
    },
    // Al cerrar el modal de listado de productos
    handleInvoiceDrugModalCancelButton(evt) {
      evt.preventDefault();
      // Limpia la variable temporal de stack
      this.tmp_stack_product = undefined;
    },

    // Elimina un producto de una factura sin llamar a la api.
    // Lo guarda en una variable si necesita ser borrado de core.
    removeProductFromInvoiceForm(evt, item) {
      let index_of = this.invoice_products.indexOf(item);
      if (index_of < 0) {
        // producto no encontrado
        return;
      }
      let deleted_product = this.invoice_products.splice(index_of, 1);
      if (!deleted_product[0]) {
        return;
      }
      let invoice_uuid = this.invoice_form.uuid;
      let snapdrug_uuid = deleted_product[0].uuid;
      // Almacena el producto a borrar sólo si esta factura tiene uuid y el
      // producto tiene uuid.
      // Si tiene uuid: la factura y el producto existe en core
      // Si no tiene uuid: la factura/producto no existe en core, no hace falta
      // llamada a la api para borrar el producto.
      if (invoice_uuid && snapdrug_uuid) {
        // Almacena el uuid de la factura del que proviene este producto,
        // necesario para la posterior llamada a la api.
        deleted_product[0]["_invoice_uuid"] = invoice_uuid;
        this.invoice_products_to_be_deleted.push(deleted_product[0]);
      }
    },
    // Carga la factura pasada por args y abre el modal de edición de factura
    loadInvoiceAndShowForm(invoice) {
      this.cleanInvoiceGarbage();
      let invoice_form = Object.assign({}, invoice);
      invoice_form["_invoice_file"] = invoice.invoice_file;
      invoice_form.invoice_file = undefined;
      invoice_form["_invoice_file_xml"] = invoice.invoice_file_xml;
      invoice_form.invoice_file_xml = undefined;
      let invoice_products = [];
      invoice.item_list.drugs.forEach((element) => {
        invoice_products.push(Object.assign({}, element));
      });
      delete invoice_form.item_list;
      delete invoice_form.amount;

      let parsed = moment(
        invoice_form.invoice_datetime,
        "YYYY-MM-DDTHH:MM"
      ).format("DD/MM/YYYY hh:mm A");
      invoice_form.invoice_datetime = parsed;

      this.invoice_form = invoice_form;
      this.invoice_products = invoice_products;
      this.invoiceModalShow = true;
    },
    loadNewInvoice() {
      this.cleanInvoiceGarbage();
      this.invoiceModalShow = true;
    },
    toggleDeductibleCurrency(currency) {
      this.form.insurance.deductible_currency = currency;
    },
    toggleCoinsuranceType(type) {
      //this.form.insurance.coinsurance_type = type;
      if (type == 0) {
        this.form.coinsurance_type_string = "%";
        this.form.insurance.coinsurance_currency = undefined;
        this.form.insurance.coinsurance_type = 0;
      } else {
        this.form.coinsurance_type_string = type;
        this.form.insurance.coinsurance_currency = type;
        this.form.insurance.coinsurance_type = 1;
      }
    },
  },
  components: {
    InsuranceRefundInvoiceDrugSelector,
    VMajorMedicalInsurancePatientSelector,
    VDrugsSummary,
    VPlusLink,
    VBootstrapFormGroup,
    VBootstrapSearchInput,
  },
};
</script>


<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}
</style>
