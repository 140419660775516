<template>
  <v-layout>
    {{ isAuthenticated }}
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  watch: {
    isAuthenticated: function(newAuthState) {
      if (newAuthState) {
        this.$router.push({ name: "userhome" });
      }
    },
  },
  mounted: function() {
    this.$nextTick(function() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "userhome" });
      } else {
        this.$router.push({ name: "login" });
      }
    });
  },
  components: {
    VLayout,
  },
};
</script>

<style>
</style>
