<template>
  <div class="cardlist-home">
    <b-container class="mb-3 pt-3">
      <div class="p-custom">
        <vue-element-loading :active="!show" :is-full-screen="true" />
        <b-row class="d-flex bd-highlight mb-3">
          <b-col
            cols="6"
            sm="3"
            md="3"
            v-for="(section, index) in sections"
            :key="index"
          >
            <b-card
              v-on:click="onItemClick(section.to)"
              footer-tag="footer"
              class="clickable homecard bd-highlight"
            >
              <div class="imgcontainer mx-5 p-2">
                <component v-bind:is="section.svg" :alt="section.text" />
              </div>
              <p class="text-center section-text" slot="footer">
                {{ section.text }}
              </p>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AccountLogo from "@/static/home_account_logo.svg";
import ProductsLogo from "@/static/home_products_logo.svg";
import InsuranceAuthorizationLogo from "@/static/home_insuranceauthorization_logo.svg";
import InsuranceBridgeLogo from "@/static/home_insurancebridge_logo.svg";
import ProviderLogo from "@/static/home_provider_logo.svg";
import RefundCatchLogo from "@/static/home_refundcatch_logo.svg";
import ContactLogo from "@/static/home_contact_logo.svg";
import PaymentLogo from "@/static/home_payment_logo.svg";
import FAQLogo from "@/static/home_faqs_logo.svg";

export default {
  name: "cardlist-home",
  components: {
    AccountLogo,
    ProductsLogo,
    InsuranceAuthorizationLogo,
    InsuranceBridgeLogo,
    ProviderLogo,
    RefundCatchLogo,
    ContactLogo,
    PaymentLogo,
    FAQLogo,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      has_bridge_insurances: state => state.organization.has_bridge_insurances,
      has_authorization_insurances: state =>
        state.organization.has_authorization_insurances,
      has_refund_insurances: state => state.organization.has_refund_insurances,
      allow_pdv_coadedpayment_guide: state =>
        state.siteconf.allow_pdv_coadedpayment_guide,
    }),
    sections() {
      let sections = [];
      sections.push({
        svg: AccountLogo,
        to: { name: "account" },
        text: this.$t("homebuttons.my_account"),
      });
      if (this.has_authorization_insurances) {
        sections.push({
          svg: InsuranceAuthorizationLogo,
          to: { name: "nopatient_insuranceauthorization_insurance_selection" },
          text: this.$t("homebuttons.pdv_authorization"),
        });
      }
      if (this.has_refund_insurances) {
        sections.push({
          svg: RefundCatchLogo,
          to: { name: "nopatient_insurancerefund_insurance_selection" },
          text: this.$t("homebuttons.pdv_refund"),
        });
      }

      if (this.allow_pdv_coadedpayment_guide) {
        sections.push({
          svg: PaymentLogo,
          to: { name: "insurance-risk-payment" },
          text: this.$t("homebuttons.payment_gateway"),
        });
      }

      if (this.has_bridge_insurances) {
        sections.push({
          svg: InsuranceBridgeLogo,
          to: { name: "nopatient_insurancebridge_insurance_selection" },
          text: this.$t("homebuttons.pdv_bridge"),
        });
      }
      sections.push({
        svg: ContactLogo,
        to: { name: "contact" },
        text: this.$t("homebuttons.contact"),
      });
      sections.push({
        svg: FAQLogo,
        to: { name: "faq" },
        text: this.$t("homebuttons.faq"),
      });

      return sections;
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        homebuttons: {
          pdv_authorization: "PDV Authorization",
          pdv_refund: "Refund",
          payment_gateway: "Payment of Deductible and Coinsurance",
          my_account: "My Account",
          contact: "Contact",
          faq: "Frequently Asked Questions",
          disabled: "Disabled",
          pdv_bridge: "Insurance bridge",
        },
      },
    },
  },
  mounted: function() {
    this.show = true;
  },
  methods: {
    onItemClick(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.cardlist-home {
  .homecard {
    border: 0px !important;
    background-color: transparent;
    color: $corp-color1;

    .imgcontainer {
      background-color: $white;
      border: 1px solid $gray-450 !important;
      border-radius: 0.55rem;
      box-shadow: 4px 4px $gray-450;

      svg {
        fill: $corp-color1;
        stroke: $corp-color1;
      }
    }

    &:hover {
      .imgcontainer {
        background-color: $corp-color1;
      }

      svg {
        fill: $white;
        stroke: $white;
      }

      .card-footer {
        color: #666666;
      }
    }
  }

  .homecard .card-footer {
    background-color: transparent;
    border: 0px;
    text-transform: uppercase;
    padding: 0px;
    font-weight: 500;
  }
}

.section-text {
  font-size: $font-size-lg;
}

@include media-breakpoint-down(md) {
  .section-text {
    font-size: $font-size-base;
  }
}

@include media-breakpoint-down(sm) {
  .card-body {
    padding: 1.5rem;
  }
}

@include media-breakpoint-down(lg) {
  .imgcontainer {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}
</style>
