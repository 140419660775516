<template>
  <div>
    <b-modal
      size="xl"
      modal-class="modal-square-theme"
      v-model="modal_show"
      centered
      :title="$t('insurancerefund_confirm_feedback.title')"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12" lg="7">
            <p>
              {{ $t("insurancerefund_confirm_feedback.patient") }}
              <strong v-if="insurancerefund">
                {{ insurancerefund.patient_address.firstname }}
              </strong>
            </p>
            <p>
              {{ $t("insurancerefund_confirm_feedback.policy_num") }}
              <strong v-if="insurancerefund">
                {{ insurancerefund.policy_num }}
              </strong>
            </p>
          </b-col>
          <b-col cols="12 d-flex" lg="5">
            <div class="d-flex">
              <component
                class="spech_ico pull-left pt-1 mr-3"
                v-bind:is="spech_icon"
                alt="Info"
              />
              <p class="font-italic">
                {{ $t("insurancerefund_confirm_feedback.confirm_text") }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6 class="mt-5 mb-4 text-uppercase font-weight-bold">
              {{ $t("insurancerefund_confirm_feedback.table_title") }}
            </h6>

            <div v-if="invoices">
              <div
                v-for="(invoice, index) in invoices"
                :key="index"
              >
                <strong>
                  {{ $t('insurancerefund_confirm_feedback.invoice_druglist_title') }}
                  {{ invoice.tax_folio_num }}
                  {{ invoice.folio_num }}
                </strong>
              </div>
            </div>

            <b-table
              responsive
              stacked="sm"
              class="mb-0"
              :items="allproducts"
              :fields="items_fields"
              outlined
              hover
              head-variant="gray"
            >
              <!-- <template v-slot:cell(batch_expire)="data">
                <div>{{$t('drugs_summary.short_expiry_date')}}: {{data.item.expiry_date}}</div>
                <div>{{$t('drugs_summary.short_batch')}}: {{data.item.batch}}</div>
              </template> -->
              <template v-slot:cell(picture)="data">
                <b-img
                  thumbnail
                  alt="Thumbnail"
                  :src="
                    data.item.picture && data.item.picture.picture
                      ? data.item.picture.picture
                      : fallback
                  "
                  class="img-thumbnail img-fluid picture-cell"
                  @error="imageLoadError"
                />
              </template>
              <template v-slot:cell(unit_price)="data">
                {{
                  formatPrice(
                    data.item.price.no_tax_price,
                    data.item.price.no_tax_price_currency
                  )
                }}
              </template>
              <template v-slot:cell(taxes)="data">
                {{
                  formatPrice(
                    data.item.price.all_included_price -
                      data.item.price.no_tax_price,
                    data.item.price.all_included_price_currency
                  )
                }}
              </template>
              <template v-slot:cell(total)="data">
                {{
                  formatPrice(
                    data.item.price.all_included_price * data.item.quantity,
                    data.item.price.all_included_price_currency
                  )
                }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-button
          size="sm"
          class="float-right"
          variant="primary"
          @click="modal_show = false"
        >
          {{ $t("insurancerefund_confirm_feedback.close_button") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SpeechBubbleIcon from "@/static/ico/speech-bubble-with-letter-i.svg";
import { mapGetters } from "vuex";
import { Money } from "@/lib/money.js";

export default {
  name: "insurancerefund-confirm-feedback",
  data() {
    return {
      modal_show: false,
      invoices: undefined,
      insurancerefund: undefined,
      spech_icon: SpeechBubbleIcon,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancerefund_confirm_feedback: {
          title: "Request done",
          close_button: "Close",
          table_title: "Invoices to refund",
          patient: "Patient:",
          policy_num: "Policy",
          serial_num: "Serial",
          folio_num: "Folio",
          headtable_provider_name: "Provider",
          headtable_drug_name: "Product",
          headtable_unit_price: "Unit price",
          headtable_quantity: "Quantity",
          headtable_taxes: "Tax",
          headtable_total: "Total",
          invoice_druglist_title: "Invoice",
          confirm_text:
            "The refund will be sended to the insurance. After your request has been processed, you will receive an email",
        },
      },
    },
  },
  computed: {
    ...mapGetters("insurancerefund", ["getFeedbackInsuranceRefund"]),
    items_fields() {
      return [
        {
          key: "invoice.serial_num",
          label: this.$t("insurancerefund_confirm_feedback.serial_num"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "invoice.folio_num",
          label: this.$t("insurancerefund_confirm_feedback.folio_num"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "invoice.provider_name",
          label: this.$t(
            "insurancerefund_confirm_feedback.headtable_provider_name"
          ),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "default_description",
          label: this.$t(
            "insurancerefund_confirm_feedback.headtable_drug_name"
          ),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "unit_price",
          label: this.$t(
            "insurancerefund_confirm_feedback.headtable_unit_price"
          ),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "quantity",
          label: this.$t("insurancerefund_confirm_feedback.headtable_quantity"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "taxes",
          label: this.$t("insurancerefund_confirm_feedback.headtable_taxes"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "total",
          label: this.$t("insurancerefund_confirm_feedback.headtable_total"),
          class: "selectable",
          thClass: "unselectable",
        },
      ];
    },
    allproducts: {
      cache: false,
      get() {
        if (!this.invoices) {
          return undefined;
        }
        let products = [];
        this.invoices.forEach(invoice => {
          if (!invoice.item_list) {
            return;
          }
          if (!invoice.item_list.drugs) {
            return;
          }
          invoice.item_list.drugs.forEach(element => {
            let _invoice = Object.assign({}, invoice, { item_list: undefined });
            products.push(Object.assign({}, element, { invoice: _invoice }));
          });
        });
        return products;
      },
    },
  },
  mounted: function() {
    this.showFeedbackRefund();
  },
  methods: {
    formatPrice(raw, currency) {
      return new Money(raw, currency).toLocaleString();
    },
    showFeedbackRefund() {
      if (!this.getFeedbackInsuranceRefund) {
        return;
      }
      this.modal_show = true;
      this.insurancerefund = Object.assign({}, this.getFeedbackInsuranceRefund);
      this.$store.commit(
        "insurancerefund/MUTATE_FEEDBACK_INSURANCEREFUND",
        undefined
      );
      if (this.insurancerefund.invoices) {
        this.invoices = this.insurancerefund.invoices;
      }
    },
  },
  components: {
    SpeechBubbleIcon,
  },
};
</script>

<style lang="scss" scoped>
.spech_ico {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 4rem;
  height: 3rem;
  color: $corp-color1;
  fill: $corp-color1;
  stroke: $white;
}
</style>
