<template>
  <VLayout upline :title="$t('insurance_risk_payment_view.title')">
    <div>
      <h5 class="mb-5 text-center w-50 ml-auto mr-auto" v-if="$t('insurance_risk_payment_view.intro_text')">
        {{ $t('insurance_risk_payment_view.intro_text') }}
      </h5>
    </div>

    <InsuranceRiskPaymentForm class="mb-3" />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import InsuranceRiskPaymentForm from "@/components/InsuranceRiskPaymentForm";

export default {
  name: "insurance-risk-payment",
  props: {
    pt_uuid: String,
  },
  components: {
    VLayout,
    InsuranceRiskPaymentForm,
  },
  data() {
    return {
      aUuid: this.$route.params.aUuid,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_risk_payment_view: {
          title: "Deductible and coinsurance payment",
          intro_text:
            "With us it is possible to make the deductible and / or coinsurance payment of your request in an easy, fast and secure way",
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  computed: {},
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.insurance_risk_payment_title",
        },
      ]);
    },
  },
};
</script>
