<template>
  <VLayout upline :title="$t('insurance_risk_payment_list_view.title')">
    <InsuranceRiskPaymentConfirmFeedback v-if="show_feedback" :feedback_payment_pending="feedback_payment_pending" />
    <InsuranceRiskPaymentTableList class="mb-3" />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import InsuranceRiskPaymentTableList from "@/components/InsuranceRiskPaymentTableList";
import InsuranceRiskPaymentConfirmFeedback from "@/components/InsuranceRiskPaymentConfirmFeedback";

export default {
  name: "insurance-risk-payment",
  props: {
    pt_uuid: String,
  },
  components: {
    VLayout,
    InsuranceRiskPaymentTableList,
    InsuranceRiskPaymentConfirmFeedback,
  },
  data() {
    return {
      show_feedback: false,
      feedback_payment_pending: false,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_risk_payment_list_view: {
          title: "Deductible and coinsurance payment list",
        },
      },
    },
  },
  mounted: function() {
    if (this.$route.params.action) {
      if (this.$route.params.action == "feedback-pay-pending") {
        this.feedback_payment_pending = true;
      }
      this.show_feedback = true;
    }
    this.putBreadCrumb();
  },
  computed: {},
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
        {
          langkey: "breadcrumb.insurance_risk_payment_list_title",
        },
      ]);
    },
  },
};
</script>
