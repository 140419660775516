<template>
  <div>
    <b-button
      variant="primary-inline"
      @click="addressSelectorShow = true"
      class="h-100"
    >
      {{ $t('insurance_prescription_address_selector.button') }}
    </b-button>
    <b-modal
      size="xl"
      modal-class="modal-square-theme"
      v-model="addressSelectorShow"
      centered
      :title="$t('insurance_prescription_address_selector.button')"
    >
      <PatientAddressTableList
        :perPage="perPage"
        v-on:address-selected="onAddressSelected"
      />
    </b-modal>
  </div>
</template>


<script>
import PatientAddressTableList from "@/components/PatientAddressTableList";

export default {
  name: "insuranceauthorization-recipe-address-selector",
  components: {
    PatientAddressTableList,
  },
  props: {
    perPage: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      addressSelectorShow: false,
    };
  },
  methods: {
    onAddressSelected(patient) {
      this.addressSelectorShow = false;
      this.$emit("address-selected", patient);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_prescription_address_selector: {
          button: "Load address",
        },
      },
    },
  },
};
</script>
