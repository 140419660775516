<template>
  <section class="jumbo">
    <div class="full-bg-img">
      <b-container>
        <b-row align-h="end">
          <b-col lg="7" md="6" sm="12">
            <slot name="left"></slot>
          </b-col>
          <b-col lg="5" md="6" sm="12">
            <slot name="right"></slot>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.jumbo {
  background: url(../static/login_bg.jpg) no-repeat center center;
  background-size: cover;
  background-position-y: 0px;
  .full-bg-img {
    padding-top: $spacing * 2 !important;
    padding-bottom: $spacing * 4 !important;
    /* For background tint: */
    /* background-color: rgba(0, 0, 0, 0.35); */
  }
}


@include media-breakpoint-down(md) {
.jumbo {
  .full-bg-img {
    padding-top: $spacing !important;
  }
}
}
</style>
