<template>
  <div>
    <DefaultLayout>
      <template slot="navigation">
        <InsuranceBridgeNavigation />
      </template>
      <vue-element-loading :active="!show" :is-full-screen="true" />
      <div v-if="show">
        <OrganizationSubHeader :i_uuid="i_uuid">
          <div v-if="files_config && active_insurancebridge">
            <!-- <h6 class="file-loader-title border-bottom font-weight-bold text-uppercase pb-1">
              {{ $t('insurancebridge_home.see_documents') }}
            </h6> -->

            <h5 class="text-uppercase">
              {{ $t('insurancebridge_home.documents_help_title') }}
            </h5>
            <div class="lh-md w-75">
              <small>
                <strong>
                  <em>
                    {{ $t('insurancebridge_home.documents_help_text1') }}
                  </em>
                </strong>
              </small>
            </div>
            <div class="lh-md w-75 pt-3">
            </div>

            <div v-for="(item, index) in files_config" :key="index">
              <InsuranceBridgeFileLoader
                :i_uuid="i_uuid"
                :pt_uuid="pt_uuid"
                :fileconfig="item"
                :insurancebridge_uuid="active_insurancebridge.uuid"
              />
            </div>
          </div>

          <div class="lh-md w-75 pt-2">
          </div>
          <v-plus-link @click="routeToRecipe()" class="mr-2">
            {{ $t('insurancebridge_home.recipe_link') }}
            <b-badge
              v-for="(recipe, index) in patient_insurancebridge_recipes"
              v-bind:key="index"
              pill
              variant="secondary"
              class="ml-3"
            >{{ $t('insurancebridge_home.recipe_done') }}</b-badge>
          </v-plus-link>

        </OrganizationSubHeader>

        <b-container fluid v-if="active_insurancebridge" class="mt-5">
          <b-row>
            <b-col col lg="12" md="auto">
              <div v-for="(recipe, index) in patient_insurancebridge_recipes" v-bind:key="index">
                <h4 class="border-bottom pb-1 mb-3">
                  {{ $t('insurancebridge_home.drugs') }}
                </h4>
                <VDrugsSummary
                  :insurance="recipe.insurance"
                  :items="recipe.drugs"
                  :amount="new amount_object(recipe.amount)"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="pr-0" v-if="is_valid">
              <b-button
                class="bridge pull-right mt-5"
                variant="primary"
                @click="onSaveButtonClick"
              >
                <SVGBridge class="mr-2" />
                {{ $t('insurancebridge_home.save_button') }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>

      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import InsuranceBridgeNavigation from "@/components/insurancebridge/InsuranceBridgeNavigation";
import OrganizationSubHeader from "@/components/OrganizationSubHeader";
import InsuranceBridgeFileLoader from "@/components/insurancebridge/InsuranceBridgeFileLoader";
import VPlusLink from "@/components/VPlusLink";
import VDrugsSummary from "@/components/insurancebridge/VDrugsSummary";

import SVGBridge from "@/static/clock.svg";

import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { Amount } from "@/lib/amount.js";

export default {
  name: "insurancebridge-home",
  data() {
    return {
      amount_object: Amount,
      show: false,
      files_config: undefined,
      i_uuid: this.$route.params.i_uuid,
      pt_uuid: this.$route.params.pt_uuid,
      insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancebridge_home: {
          recipe_link: "Recipe",
          save_button: "Save and send",
          drugs: "Recipe drugs",
          see_documents: "See documents",
          recipe_done: "Done",
          documents_help_title: "How to make your request?",
          documents_help_text1:
            "To process your reimbursement request, you must attach the documents requested by your insurer along with the medical prescription in file, pdf, jpg, tiff or png format.",
          error: {
            retrieving_organization_info: "Cannot get organization info",
            create_draft_refund: "Cannot create initial refund data",
            saving_refund: "Cannot save refund",
            refound_not_found: "Refund not found",
            // no_drugs: "Please, add at least one drug to your recipe.",
            // no_recipe: "Please, add at least one recipe to your insurance bridge.",
          },
        },
      },
    },
  },
  computed: {
    ...mapGetters("insurancebridge", [
      "getDraftInsuranceBridgeByInsuranceUUID",
    ]),
    ...mapGetters("drugtransaction", ["getBridgeConfigFilesByIUUID"]),
    ...mapGetters("organization", ["getInsuranceDataByUUID"]),
    ...mapState({
      active_insurancebridge: (state) =>
        state.insurancebridge.active_insurancebridge,
      latest_params: (state) => state.routehelper.latest_params,
      patient_insurancebridge_recipes: (state) =>
        state.recipe.patient_insurancebridge_recipes,
    }),
    is_valid() {
      if (!this.active_insurancebridge) {
        return false;
      }
      if (!this.active_insurancebridge.recipe) {
        return false;
      }
      if (!this.active_insurancebridge.recipe.drugs) {
        return false;
      }
      if (this.active_insurancebridge.recipe.drugs.length <= 0) {
        return false;
      }
      return true;
    },
  },
  mounted: function () {
    // this.loadInsuranceBridgeData();
    this.loadConfigFiles();
    this.loadInsuranceBridge();
    this.putBreadCrumb();
  },
  methods: {
    _putBreadCrumb(organization) {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.refund_insurance_selection",
          to: { name: "nopatient_insurancebridge_insurance_selection" },
        },
        {
          langkey: "breadcrumb.insurancebridge_home",
          langparams: {
            insurance_name: (organization || {}).public_name || "Insurance",
          },
          to: { name: "nopatient_insurancebridge_home" },
        },
      ]);
    },
    putBreadCrumb() {
      var organization = this.getInsuranceDataByUUID(this.i_uuid);
      if (!organization) {
        this.$store.dispatch("organization/INSURANCE_BRIDGE_LIST").then(
          () => {
            organization = this.getInsuranceDataByUUID(this.i_uuid);
            this._putBreadCrumb(organization);
          },
          () => {
            this.mnotify_error({
              text: this.$t(
                "insurancebridge_home.error.retrieving_organization_info"
              ),
            });
          }
        );
      } else {
        this._putBreadCrumb(organization);
      }
    },
    loadInsuranceBridge() {
      if (this.latest_params.insurancebridge_uuid == "new") {
        this.loadNewInsuranceBridge();
        return;
      }

      // esto cargará una autorización por uuid o cargará uno cualquiera
      // en borrador si no lo encuentra
      this.loadOneInsuranceBridge();
    },
    loadOneInsuranceBridge() {
      // vacía la lista de recetas borrador de este paciente
      this.$store.commit(
        "recipe/MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES_RESET"
      );
      // vuelve a cargar la lista de reembolsos borrador
      this.$store
        .dispatch(
          "insurancebridge/LOAD_PATIENT_ALL_INSURANCE_DRAFT_INSURANCEBRIDGE",
          {
            pt_uuid: this.pt_uuid,
          }
        )
        .then(
          () => {
            let insurancebridge = this.getDraftInsuranceBridgeByInsuranceUUID(
              this.latest_params.i_uuid,
              this.latest_params.insurancebridge_uuid
            );
            if (insurancebridge) {
              this.$store.commit(
                "insurancebridge/MUTATE_DRAFT_INSURANCEBRIDGE",
                insurancebridge
              );

              this.$store
                .dispatch(
                  "recipe/LOAD_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES",
                  {
                    pt_uuid: this.pt_uuid,
                    i_uuid: this.i_uuid,
                    insurancebridge_uuid: insurancebridge.uuid,
                  }
                )
                .then(undefined, (err) => {
                  this.$sentry({ capture: err });
                  this.$router.push({ name: "fallback-error-page" });
                });

              this.show = true;
              this.routeToRefund(insurancebridge.uuid);
            } else {
              this.refoundNotFound();
              this.loadNewInsuranceBridge();
            }
          },
          (err) => {
            this.$sentry({ capture: err });
            this.$router.push({ name: "fallback-error-page" });
          }
        );
    },
    refoundNotFound() {
      this.show = true;
      this.mnotify_error({
        text: this.$t("insurancebridge_home.error.refound_not_found"),
      });
      this.$router.push({
        name: "nopatient_insurancebridge_insurance_selection",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
        },
      });
    },
    routeToRefund(insurancebridge_uuid) {
      this.$router.replace({
        name: "nopatient_insurancebridge_home",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
          i_uuid: this.latest_params.i_uuid,
          insurancebridge_uuid: insurancebridge_uuid,
        },
      });
    },
    routeToRecipe() {
      this.$router.push({
        name: "nopatient_insurancebridge_recipe",
        params: {
          i_uuid: this.latest_params.i_uuid,
          insurancebridge_uuid: this.latest_params.insurancebridge_uuid,
        },
      });
    },
    loadConfigFiles() {
      this.files_config = this.getBridgeConfigFilesByIUUID(this.i_uuid);
      if (!this.files_config) {
        this.$store
          .dispatch(
            "drugtransaction/LOAD_INSURANCE_INSURANCEBRIDGE_CONFIG_FILES",
            {
              i_uuid: this.i_uuid,
            }
          )
          .then(
            () => {
              this.files_config = this.getBridgeConfigFilesByIUUID(this.i_uuid);
            },
            (err) => {
              this.$sentry({ capture: err });
              this.$router.push({ name: "fallback-error-page" });
            }
          );
      }
    },
    loadNewInsuranceBridge() {
      // vacía la lista de recetas borrador de este paciente
      this.$store.commit(
        "recipe/MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES_RESET"
      );
      // create and load new insurancebridge
      this.$store
        .dispatch(
          "insurancebridge/CREATE_PATIENT_INSURANCE_DRAFT_INSURANCEBRIDGE",
          {
            data: {},
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
          }
        )
        .then(
          (response) => {
            // update route to put insurancebridge uuid
            this.routeToRefund(response.data.uuid);
            // show view content
            this.show = true;
          },
          (err) => {
            this.$sentry({ capture: err });
            this.mnotify_center_retry({
              text: this.$t("insurancebridge_home.error.create_draft_refund"),
            });
            this.mnotify_error({
              text: this.$t("insurancebridge_home.error.create_draft_refund"),
            });
          }
        );
    },
    onSaveButtonClick() {
      if (!this.is_valid) {
        return false;
      }
      this.$store
        .dispatch(
          "insurancebridge/DONE_PATIENT_INSURANCE_DRAFT_INSURANCEBRIDGE",
          {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            insurancebridge_uuid: this.insurancebridge_uuid,
          }
        )
        .then(
          () => {
            // clean the list of recipes asociated to this insurancebridge
            this.$router.push({
              name: "nopatient_insurancebridge_historial",
            });
          },
          (err) => {
            this.mnotify_error({
              text: this.$t("insurancebridge_home.error.saving_refund"),
            });
            this.showResponseError(err);
            this.putFormFeedback(err.response);
          }
        );
    },
  },
  components: {
    DefaultLayout,
    OrganizationSubHeader,
    InsuranceBridgeFileLoader,
    VPlusLink,
    InsuranceBridgeNavigation,
    VDrugsSummary,
    SVGBridge,
  },
};
</script>

<style lang="scss" scoped>
.file-loader-title {
  font-size: $font-size-base * 1.4;
}

.bridge {
  svg {
    fill: $white;
    height: 1rem;
  }

  &:hover svg {
    fill: $corp-color1;
  }
}
</style>
