<template>
  <div>

    <div v-if="form_sended">

      <b-alert show variant="success">{{ $t('register.mail_sent') }}</b-alert>
      <b-button
        type="submit"
        variant="tertiary"
        class="mt-4"
        size="sm"
        @click="routeToLogin()"
      >
        {{ $t('register.go_to_login_button') }}
      </b-button>
    </div>

    <b-form @submit="doSubmit" v-if="show && !form_sended">
      <b-form-group
        id="gusername"
        label-for="username"
        :invalid-feedback="form_invalid_feedback.username"
        :state="form_state.username"
      >
        <div class="d-flex flex-column-reverse">
          <b-form-input
            id="username"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            v-model="form.username"
            required
            :placeholder="$t('register.username_placeholder')"
          ></b-form-input>
          <label class="col-form-label pt-0" for="mailinput">{{ $t('register.username') }}</label>
        </div>
      </b-form-group>
      <b-form-group
        id="group1"
        label-for="mailinput"
        :invalid-feedback="form_invalid_feedback.email"
        :state="form_state.email"
      >
        <div class="d-flex flex-column-reverse">
          <b-form-input
            id="mailinput"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="username email"
            v-model="form.email"
            required
            :placeholder="$t('register.email_placeholder')"
          ></b-form-input>
          <label class="col-form-label pt-0" for="mailinput">{{ $t('register.email') }}</label>
        </div>
      </b-form-group>
      <b-form-group
        id="group2"
        label-for="passinput"
        :invalid-feedback="form_invalid_feedback.password"
        :state="form_state.password"
      >
        <div class="d-flex flex-column-reverse">
          <PasswordInput
            id="passinput"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="new-password"
            v-model="form.password"
            required
            :placeholder="$t('register.password_placeholder')"
            v-on:blur="handleOnBlurPassInput()"
            v-on:focus="handleOnFocusPassInput()"
            v-bind:class="{ 'has-focus': pass_input_has_focus }"
          ></PasswordInput>
          <label class="col-form-label pt-0" for="passinput">{{ $t('register.password') }}</label>
        </div>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validatePassLength">
          {{ $t('register.pass_validator_help_size', {count: validator_help_size_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validatePassDigits">
          {{ $t('register.pass_validator_help_digit', {count: validator_help_digit_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateUppercaseLength">
          {{ $t('register.pass_validator_help_uppercase', {count: validator_help_uppercase_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateLowercaseLength">
          {{ $t('register.pass_validator_help_lowercase', {count: validator_help_lowercase_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateSymbolLength">
          {{ $t('register.pass_validator_help_symbol', {count: validator_help_symbol_count}) }} ()[]{}|\`~!@#$%^&*_-+=;:'",&lt;&gt;./?"]
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateConsecutiveCharacters">
          {{ $t('register.pass_validator_help_repeated', {count: validator_help_repeated_count}) }}
        </b-form-invalid-feedback>

      </b-form-group>
      <b-form-group id="group3">
        <b-form-checkbox-group v-model="form.checked" id="registerchecks" required>
          <b-form-checkbox value="eula">{{ $t('I accept the EULA / Legal terms &amp; conditions') }}</b-form-checkbox>
          <b-form-checkbox value="privacy">{{ $t('I accept the Privacy Policy') }}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <b-row class="text-left" no-gutters>
        <b-col cols="12">
          <b-button type="submit" :disabled="!validatePass" size="sm" variant="tertiary">
            {{ $t('register.submit_button') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-left mt-5" no-gutters>
        <b-col cols="12">
          <div>
            <router-link :to="{name: 'login'}">{{ $t('register.login_link') }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>


<script>
const MIN_PASS_LENGTH = 8;
const MIN_PASS_DIGITS = 1;
const MIN_UPPERCASE_COUNT = 1;
const MIN_LOWERCASE_COUNT = 1;
const MIN_SYMBOL_COUNT = 1;
const MAX_REPEATED_CHARACTERS = 3;

import PasswordInput from "@/components/PasswordInput";

export default {
  name: "register",
  components: {
    PasswordInput,
  },
  data() {
    return {
      validator_help_size_count: String(MIN_PASS_LENGTH),
      validator_help_digit_count: String(MIN_PASS_DIGITS),
      validator_help_uppercase_count: String(MIN_UPPERCASE_COUNT),
      validator_help_lowercase_count: String(MIN_LOWERCASE_COUNT),
      validator_help_symbol_count: String(MIN_SYMBOL_COUNT),
      validator_help_repeated_count: String(MAX_REPEATED_CHARACTERS),
      pass_input_has_focus: false,
      show_pass_helper: false,
      form_sended: undefined,
      form: {
        username: "",
        email: "",
        password: "",
        checked: [],
      },
      form_invalid_feedback: {
        username: undefined,
        email: undefined,
        password: undefined,
      },
      form_state: {
        username: undefined,
        email: undefined,
        password: undefined,
      },
      show: true,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        register: {
          username: "Username",
          username_placeholder: "Enter username",
          email: "Email",
          email_placeholder: "Enter email",
          password: "Password",
          password_placeholder: "Enter password",
          login_link: "Back to login",
          password_label: "Password",
          submit_button: "Register",
          accept_conditions: "You must accept EULA and Privacy Policy",
          go_to_login_button: "Go to login",
          pass_validator_help_size: "Must contain at least {count} characters.",
          pass_validator_help_digit: "Must contain at least {count} digit.",
          pass_validator_help_uppercase:
            "Must contain at least {count} uppercase letter.",
          pass_validator_help_lowercase:
            "Must contain at least {count} lowercase letter.",
          pass_validator_help_symbol: "Must contain at least {count} symbol.",
          pass_validator_help_repeated:
            "Must not contain {count} repeated characters (Example: aaa)",
          mail_sent:
            "We have sent an email with instructions to activate your account.",
        },
      },
    },
  },
  computed: {
    validatePassLength() {
      if (this.form.password.length >= MIN_PASS_LENGTH) {
        return true;
      }
      return false;
    },
    validatePassDigits() {
      let digits_match = this.form.password.match(/\d+/gi);
      if (!digits_match) {
        return false;
      }
      if (digits_match.join("").length >= MIN_PASS_DIGITS) {
        return true;
      }
      return false;
    },
    validateUppercaseLength() {
      let uppercase_count = this.form.password.match(/[A-Z]/g);
      if (!uppercase_count) {
        return false;
      }
      if (uppercase_count.join("").length >= MIN_UPPERCASE_COUNT) {
        return true;
      }
      return false;
    },
    validateLowercaseLength() {
      let lowercase_count = this.form.password.match(/[a-z]/g);
      if (!lowercase_count) {
        return false;
      }
      if (lowercase_count.join("").length >= MIN_LOWERCASE_COUNT) {
        return true;
      }
      return false;
    },
    validateSymbolLength() {
      let symbol_count = this.form.password.match(
        /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/g
      );
      if (!symbol_count) {
        return false;
      }
      if (symbol_count.join("").length >= MIN_SYMBOL_COUNT) {
        return true;
      }
      return false;
    },
    validateConsecutiveCharacters() {
      let regex_string =
        "((\\w)\\2{" + String(MAX_REPEATED_CHARACTERS - 1) + ",})";
      let regex = new RegExp(regex_string, "g");
      let consecutive_count = this.form.password.match(regex);
      if (!consecutive_count) {
        return true;
      }
      return false;
    },
    validatePass() {
      if (!this.validatePassLength) {
        return false;
      }
      if (!this.validatePassDigits) {
        return false;
      }
      if (!this.validateUppercaseLength) {
        return false;
      }
      if (!this.validateLowercaseLength) {
        return false;
      }
      if (!this.validateSymbolLength) {
        return false;
      }
      if (!this.validateConsecutiveCharacters) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleOnBlurPassInput() {
      this.pass_input_has_focus = false;
    },
    handleOnFocusPassInput() {
      this.show_pass_helper = true;
      this.pass_input_has_focus = true;
    },
    routeToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    doSubmit(evt) {
      evt.preventDefault();
      this.$recaptcha("register_form").then(
        token => {
          this.$store.dispatch("auth/REGISTER", {
            ...this.form,
            recaptcha_token: String(token),
          }).then(
            () => {
              this.form_sended = true;
            },
            err => {
              this.showResponseError(err);
              this.putFormFeedback(err.response);
            }
          );
        },
        err => {
          this.$sentry({ capture: err });
          this.showResponseError(err);
          this.putFormFeedback(err.response);
        }
      );
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      /* Trick to reset/clear native browser form validation state */
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
input + label,
div + label {
  visibility: hidden;
  opacity: 0;
  font-size: 1rem;
}

/deep/ input:focus,
div.has-focus {
  &::placeholder {
    color: transparent;
  }

  + label {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
    color: $corp-color1;
  }
}

input#username,
input#mailinput,
/deep/ input#passinput {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 0px;
}

/deep/ label::before {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none;
}

/deep/ input.form-control,
/deep/ input.form-control:focus {
  border: none;
  box-shadow: none;
}

input#mailinput::placeholder,
input#username::placeholder,
/deep/ input#passinput::placeholder {
  color: $text-main-color;
  font-style: normal;
  font-size: $font-size-base;
  transition: color 0.2s;
}
input#mailinput:focus::placeholder,
input#username:focus::placeholder,
/deep/ input#passinput:focus::placeholder {
  color: transparent;
}
</style>
