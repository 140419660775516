/****************************************
  ConsoleOut
  console-out.js
  (C) Develatio Technologies S.L. 2018
*****************************************

Wrapper for console.log
****************************************/


const ConsoleOut = {
  // eslint-disable-next-line
  install(Vue, options) {
    Vue.mixin({
      methods: {
        consoleout(...args) {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(...args);
          }
        },
        consoleoutObj(obj) {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(obj, null, 4));
          }
        },
      },
    });
  },
};

export default ConsoleOut;
