<template>
  <v-layout
    :title="$t('insurancebridgerecipe.content_title')"
    :subtitle="$t('insurancebridgerecipe.content_subtitle')"
  >
    <template slot="navigation">
      <InsuranceBridgeNavigation />
    </template>
    <template slot="logo" v-if="logo_url">
      <b-img :src="logo_url" class="mw-100" />
    </template>
    <InsuranceBridgeRecipe v-on:recipe:done="onRecipeDone" />
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import InsuranceBridgeNavigation from "@/components/insurancebridge/InsuranceBridgeNavigation";
import InsuranceBridgeRecipe from "@/components/insurancebridge/InsuranceBridgeRecipe";
import { mapGetters } from "vuex";

export default {
  name: "insurancebridge-recipe-view",
  data() {
    return {
      logo_url: undefined,
    };
  },
  computed: {
    ...mapGetters("organization", ["getInsuranceDataByUUID"]),
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    putLogo(organization) {
      if (
        organization &&
        organization.configuration &&
        organization.configuration.show_form_logo
      ) {
        this.logo_url = organization.configuration.form_logo;
      }
    },
    putBreadCrumb(organization) {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.refund_insurance_selection",
          to: { name: "nopatient_insurancebridge_insurance_selection" },
        },
        {
          langkey: "breadcrumb.insurancebridge_home",
          langparams: {
            insurance_name: organization.public_name || "Store",
          },
          to: { name: "nopatient_insurancebridge_home" },
        },
        {
          langkey: "breadcrumb.bridge_recipe",
          to: { name: "nopatient_insurancebridge_recipe" },
        },
      ]);
    },
    loadData() {
      var organization = this.getInsuranceDataByUUID(this.$route.params.i_uuid);
      if (!organization) {
        this.$store.dispatch("organization/INSURANCE_AUTHORIZATION_LIST").then(
          () => {
            organization = this.getInsuranceDataByUUID(
              this.$route.params.i_uuid
            );
            this.putBreadCrumb(organization);
            this.putLogo(organization);
          },
          (err) => {
            throw new Error(err);
            //this.consoleout(err);
          }
        );
      } else {
        this.putBreadCrumb(organization);
        this.putLogo(organization);
      }
    },
    onRecipeDone: function () {
      this.$router.push({
        name: "nopatient_insurancebridge_home",
        params: {
          pt_uuid: this.$route.params.pt_uuid,
          i_uuid: this.$route.params.i_uuid,
          insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
        },
      });
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancebridgerecipe: {
          content_title: "Recipe",
          content_subtitle:
            "Please fill in the data, the fields marked with * are essential",
        },
      },
    },
  },
  components: {
    VLayout,
    InsuranceBridgeRecipe,
    InsuranceBridgeNavigation,
  },
};
</script>

<style>
</style>
