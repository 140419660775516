<template>
  <v-layout :showbreadcrumbs="false" fullwidth>
    <VHomeImgJumbo>
      <template v-slot:right>
        <VHomeImgJumboCard :title="$t('recover_pass.box_title')">
          <RecoverPassForm />
        </VHomeImgJumboCard>
      </template>
    </VHomeImgJumbo>
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import RecoverPassForm from "@/components/RecoverPassForm";
import VHomeImgJumbo from "@/components/VHomeImgJumbo";
import VHomeImgJumboCard from "@/components/VHomeImgJumboCard";

export default {
  fallbackI18n: {
    messages: {
      und: {
        recover_pass: {
          box_title: "Recover account",
        },
      },
    },
  },
  components: {
    VLayout,
    RecoverPassForm,
    VHomeImgJumbo,
    VHomeImgJumboCard,
  },
};
</script>

<style>
</style>
