<template>
  <div>
    <vue-element-loading :active="loading" />
    <b-row class="mb-5">
      <b-col cols="12" md="4" lg="4">
        <b-form @submit="onSearchSubmit($event)">
          <VBootstrapSearchInput
            v-model="search_term"
            :placeholder="$t('major_medical_insurance_table_list.search_placeholder')"
            v-on:submit="onSearchSubmit($event)"
          ></VBootstrapSearchInput>
        </b-form>
      </b-col>
      <b-col cols="0" md="8" lg="8"></b-col>
    </b-row>
    <b-table
      id="insuranceauthorization-table-list"
      ref="table_list"
      responsive
      outlined
      head-variant="gray"
      stacked="sm"
      :busy.sync="isBusy"
      :items="tableProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      v-show="!selected_drug"
    >
      <template v-slot:cell(actions)="data">
        <div v-if="allowEditAddress" class="edit-address clickable">
          <font-awesome-icon
            @click="
              routeTo({
                name: 'patient_customer_data',
                params: { patient_customer_uuid: data.item.uuid },
              })
            "
            icon="eye"
          />
        </div>
        <b-button
          v-if="allowSelectPatient"
          variant="primary"
          size="sm"
          @click="selectPatient(data.item)"
        >
          {{ $t("major_medical_insurance_table_list.select_patient") }}
        </b-button>
      </template>

      <template v-slot:cell(active)="data">
        <b-badge
          class="px-2 pt-2 pb-2 w-75 text-uppercase text-weight-semibold"
          variant="success"
          v-if="data.item.active"
        >
          {{ $t("major_medical_insurance_table_list.status_active") }}
        </b-badge>
        <b-badge
          class="px-2 pt-2 pb-2 w-75 text-uppercase text-weight-semibold"
          variant="danger"
          v-else
        >
          {{ $t("major_medical_insurance_table_list.status_inactive") }}
        </b-badge>
      </template>
    </b-table>
    <b-row>
      <b-col md="12">
        <b-pagination
          align="center"
          v-show="!selected_drug"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="float-md-right"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { Money } from "@/lib/money.js";
import get from "@/../node_modules/lodash/get";
import VBootstrapSearchInput from "@/components/VBootstrapSearchInput";

export default {
  name: "major-medical-insurance-table-list",
  components: { VBootstrapSearchInput },
  props: {
    pt_uuid: String,
    perPage: {
      type: Number,
      default: 20,
    },
    allowEditAddress: {
      type: Boolean,
      default: true,
    },
    allowSelectPatient: {
      type: Boolean,
      default: false,
    },
    "preset-filters": {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      search_term: "",
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      loading: true,
      selected_drug: undefined,
    };
  },
  // computed: {
  //   ...mapState({
  //     country_fields: state => state.siteconf.country_fields,
  //   }),
  // },
  computed: {
    fields() {
      return [
        {
          key: "firstname",
          label: this.$t("major_medical_insurance_table_list.headtable_name"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            var firstname = item.firstname || "";
            var lastname = item.lastname || "";

            return firstname + " " + lastname;
          },
        },
        {
          key: "policy_num",
          label: this.$t(
            "major_medical_insurance_table_list.headtable_policy_number"
          ),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "manager",
          label: this.$t(
            "major_medical_insurance_table_list.headtable_insurance"
          ),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            return get(item, "manager_public_name") || "";
          },
        },
        {
          key: "validity_date",
          formatter: value => {
            return this.i18nFormatDate(value);
          },
          label: this.$t(
            "major_medical_insurance_table_list.headtable_validity_date"
          ),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "active",
          label: this.$t("major_medical_insurance_table_list.headtable_status"),
          class: "selectable",
          thClass: "unselectable active",
        },
        { key: "actions", label: "" },
      ];
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        major_medical_insurance_table_list: {
          headtable_name: "Name",
          headtable_policy_number: "Policy",
          headtable_insurance: "Insurance",
          headtable_validity_date: "Validity",
          headtable_status: "Status",
          status_active: "Active",
          status_inactive: "Inactive",
          search_placeholder: "Search",
          error: {
            cannot_load_items: "Cannot load items",
          },
          select_patient: "Select",
        },
      },
    },
  },
  methods: {
    onSearchSubmit(evt) {
      evt.preventDefault();
      this.$refs.table_list.refresh();
    },
    routeTo(route) {
      this.$router.push(route);
    },
    tableProvider() {
      try {
        var payloadopts = {
          per_page: this.perPage,
          current_page: this.currentPage,
          search: this.search_term,
        };
        if (this.presetFilters) {
          payloadopts = Object.assign({}, payloadopts, this.presetFilters);
        }
        return this.$store
          .dispatch(
            "organization/REQUEST_PATIENT_CUSTOMER_SELF_LIST",
            payloadopts
          )
          .then(
            response => {
              const items = response.data.results;
              this.isBusy = false;
              this.loading = false;
              this.totalRows = response.data.count;
              return items;
            },
            err => {
              this.$sentry({ capture: err });
              this.mnotify_error({
                text: this.$t(
                  "major_medical_insurance_table_list.error.cannot_load_items"
                ),
              });
              this.isBusy = false;
              this.loading = false;
              return [];
            }
          );
      } catch (e) {
        this.$sentry({ capture: e });
        this.mnotify_error({
          text: this.$t(
            "major_medical_insurance_table_list.error.cannot_load_items"
          ),
        });
        this.isBusy = false;
        this.loading = false;
        return [];
      }
    },
    selectPatient(patient) {
      this.$emit("patient-selected", patient);
    },
  },
};
</script>

<style lang="scss" scoped>
#insuranceauthorization-table-list /deep/ thead th:last-child {
  width: 10%;
}

#insuranceauthorization-table-list /deep/ thead th.active {
  width: 10%;
}

#insuranceauthorization-table-list /deep/ td {
  padding-bottom: $table-cell-padding * 2;
  padding-top: $table-cell-padding * 2;
}

@include media-breakpoint-down(sm) {
  /deep/ table.b-table > tbody > tr > td {
    grid-template-columns: 30% auto !important;

    &::before {
      text-align: left !important;
    }
  }
}
</style>
