<template>
  <div deck class="column-max-4">
    <div
      class="column-max-item sep-4"
      v-for="(section, index) in sections"
      :key="index"
    >
      <div class="section-menu" v-if="section.show">
        <router-link
          :to="section.to"
          tag="div"
          class="box_menu d-flex align-items-center w-100"
        >
          <component v-bind:is="section.svg" class="p-2 p-lg-3 image-menu" />
          <span class="ml-3">{{ section.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SVGSGMMData from "@/static/ico/sgmm_data.svg";
import SVGUserInformation from "@/static/ico/datos_personales.svg";
import SVGUserHistorial from "@/static/ico/historial_pedidos.svg";
import SVGUserAddress from "@/static/ico/mis_direcciones.svg";
import SVGPaymentInformation from "@/static/ico/payment_information.svg";
import { mapState } from "vuex";

export default {
  name: "cardlist-home",
  components: {
    SVGSGMMData,
    SVGUserInformation,
    SVGUserHistorial,
    SVGUserAddress,
    SVGPaymentInformation,
  },
  computed: {
    ...mapState({
      allow_pdv_coadedpayment_guide: state =>
        state.siteconf.allow_pdv_coadedpayment_guide,
    }),
  },
  data() {
    return {
      sections: [
        {
          svg: SVGSGMMData,
          to: { name: "account_major_medical_insurance_list" },
          text: this.$t("home_user.sgmm_data"),
          show: true,
        },
        {
          svg: SVGUserHistorial,
          to: {
            name: "nopatient_insuranceauthorization_historial",
          },
          text: this.$t("home_user.history"),
          show: true,
        },
        {
          svg: SVGUserAddress,
          to: { name: "patientselector_addresses" },
          text: this.$t("home_user.my_addresses"),
          show: true,
        },
        {
          svg: SVGPaymentInformation,
          to: { name: "insurance-risk-payment-list" },
          text: this.$t("home_user.payment_information"),
          show: this.allow_pdv_coadedpayment_guide,
        },
      ],
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        home_user: {
          personal_information: "Personal Information",
          history: "Refund Historial",
          my_addresses: "My Addresses",
          sgmm_data: "SGMM Data",
          payment_information: "Payment information",
          manage_insured: "Manage insured",
          return: "Return",
        },
      },
    },
  },
  mounted: function() {
    this.show = true;
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.box_menu {
  text-transform: uppercase;
  font-size: $font-size-base;
  border: 1px solid $corp-color1;
  cursor: pointer;
  color: $black;
  border-radius: 0 $btn-border-radius $btn-border-radius 0;
}

.box_menu span {
  //color: $grey3-color;
  display: inline-block;
}

.box_menu svg {
  flex-shrink: 0;
  display: inline-block;
  border-right: 1px solid $corp-color1;
}

.image-menu {
  background-color: $corp-color1;
  fill: $white;
  height: 5em;
  width: 19%;
}
</style>
