<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-html="markdownText()"></div>
</template>

<script>
let marked = require("marked");

export default {
  name: "v-markdown-view",
  data() {
    return {};
  },
  props: {
    mdtext: String,
  },
  methods: {
    markdownText() {
      marked.setOptions({
        renderer: new marked.Renderer(),
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        sanitize: true,
        smartLists: true,
        smartypants: false,
      });
      return marked(this.mdtext);
    },
  },
};
</script>


<style scoped>
</style>
