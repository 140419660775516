<template>
  <div>
    <b-container fluid v-if="show">
      <b-row no-gutters>
        <b-col cols="12" lg="7" md="12" sm="12">
          <b-row no-gutters class="d-md-none">
            <h2 class="selectable mt-2 mb-4 font-weight-semibold">{{ organization.public_name }}</h2>
          </b-row>

          <b-row no-gutters>
            <b-col cols="5" lg="4" md="4" sm="12">
              <b-media-aside class="media-img-content" style="--aspect-ratio:1/1;" vertical-align="top">
                <b-img slot="aside" thumbnail :src="organization.logo" alt="Thumbnail" class="organization-logo" />
              </b-media-aside>
            </b-col>
            <b-col cols="7" lg="8" md="8" sm="12" class="pl-4">
              <h2 class="selectable mt-0 mb-3 font-weight-semibold d-none d-md-block">{{ organization.public_name }}</h2>
              <p class="mb-1">{{ $t('sub_header.contact_information') }}</p>
              <div class="selectable pb-2 pt-2">
                <PhoneIcon width="22" class="img-fluid" /> {{ organization.phone }}
              </div>
              <div class="selectable pb-2 pt-2" v-if="organization.phone2">
                <PhoneIcon width="22" class="img-fluid" /> {{ organization.phone2 }}
              </div>
              <div class="selectable pb-2 pt-2" v-if="organization.web_page">
                <WebIcon width="22" class="img-fluid" /> {{ organization.web_page }}
              </div>

              <div class="selectable pb-2 pt-2" v-if="organization.email">
                <MailIcon width="22" class="img-fluid" /> {{ organization.email }}
              </div>

            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="5" md="12" sm="12">
          <div class="mt-5 d-lg-none"></div>
          <slot></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import WebIcon from "@/static/web_dataicon.svg";
import PhoneIcon from "@/static/phone_dataicon.svg";
import MailIcon from "@/static/mail_dataicon.svg";

export default {
  name: "organization-sub-header",
  data() {
    return {
      show: false,
      organization: undefined,
    };
  },
  props: {
    i_uuid: String,
  },
  fallbackI18n: {
    messages: {
      und: {
        sub_header: {
          contact_information: "Contact information:",
          phone: "Phone",
          web_page: "Web page",
          direct_chat: "Direct chat",
          error: {
            retrieving_organization_info: "Cannot get organization info",
          },
        },
      },
    },
  },
  computed: {
    ...mapGetters("organization", ["getInsuranceDataByUUID"]),
  },
  mounted: function() {
    this.organization = this.getInsuranceDataByUUID(this.i_uuid);
    if (this.i_uuid && !this.organization) {
      this.$store.dispatch("organization/INSURANCE_AUTHORIZATION_LIST").then(
        () => {
          this.organization = this.getInsuranceDataByUUID(this.i_uuid);
          if (this.organization) {
            this.show = true;
          } else {
            this.$store.dispatch("organization/INSURANCE_REFUND_LIST").then(
              () => {
                this.organization = this.getInsuranceDataByUUID(this.i_uuid);
                if (this.organization) {
                  this.show = true;
                }
              },
              e => {
                this.$sentry({ capture: e });
                this.mnotify_error({
                  text: this.$t(
                    "sub_header.error.retrieving_organization_info"
                  ),
                });
              }
            );
          }
        },
        e => {
          this.$sentry({ capture: e });
          this.mnotify_error({
            text: this.$t("sub_header.error.retrieving_organization_info"),
          });
        }
      );
    } else {
      this.show = true;
    }
  },
  components: {
    WebIcon,
    MailIcon,
    PhoneIcon,
  },
};
</script>

<style lang="scss" scoped>
.organization-logo {
  padding: $spacing;
  width: 100%;
  height: 100%;
  border-radius: 2rem;

  @include media-breakpoint-down(md) {
    padding: $spacing / 4;
  }
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}
</style>
