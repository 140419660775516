<template>
  <div>
    <b-form @submit="onSubmit" v-if="show">
      <b-form-group
        id="group1"
        class="text-left"
        :invalid-feedback="form_invalid_feedback.new_password"
        label-for="passinput"
      >
        <div class="d-flex flex-column-reverse">
          <PasswordInput
            id="passinput"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="new-password"
            v-model="form.new_password"
            required
            :placeholder="$t('recover_pass_confirm.password_placeholder')"
            v-on:blur="handleOnBlurPassInput()"
            v-on:focus="handleOnFocusPassInput()"
            v-bind:class="{ 'has-focus': pass_input_has_focus }"
          ></PasswordInput>
          <label class="col-form-label pt-0" for="passinput">{{ $t('recover_pass_confirm.password_label') }}</label>
        </div>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validatePassLength">
          {{ $t('recover_pass_confirm.pass_validator_help_size', {count: validator_help_size_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validatePassDigits">
          {{ $t('recover_pass_confirm.pass_validator_help_digit', {count: validator_help_digit_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateUppercaseLength">
          {{ $t('recover_pass_confirm.pass_validator_help_uppercase', {count: validator_help_uppercase_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateLowercaseLength">
          {{ $t('recover_pass_confirm.pass_validator_help_lowercase', {count: validator_help_lowercase_count}) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateSymbolLength">
          {{ $t('recover_pass_confirm.pass_validator_help_symbol', {count: validator_help_symbol_count}) }} ()[]{}|\`~!@#$%^&*_-+=;:'",&lt;&gt;./?"]
        </b-form-invalid-feedback>
        <b-form-invalid-feedback class="text-info" :force-show="show_pass_helper && !validateConsecutiveCharacters">
          {{ $t('recover_pass_confirm.pass_validator_help_repeated', {count: validator_help_repeated_count}) }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="group2"
        class="text-left"
        :invalid-feedback="form_invalid_feedback.password_retype"
        label-for="passinput"
      >
        <div class="d-flex flex-column-reverse">
          <b-form-input
            id="passinput_retype"
            type="password"
            v-model="form.password_retype"
            :state="form_state.password_retype"
            required
            :placeholder="$t('recover_pass_confirm.password_retype_placeholder')"
          >
          </b-form-input>
          <label class="col-form-label pt-0" for="mailinput">{{ $t('recover_pass_confirm.password_retype_label') }}</label>
        </div>
        <b-form-invalid-feedback class="text-danger" :force-show="form.password_retype.length > 0 && validatePass && !validateSamePass">
          {{ $t('recover_pass_confirm.password_mismatch') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-row class="text-left" no-gutters>
        <b-col cols="6">
          <b-button type="submit" :disabled="!validatePass || !validateSamePass" size="lg" variant="primary">{{ $t('recover_pass_confirm.submit_button') }}</b-button>
        </b-col>
        <b-col cols="6">
        </b-col>
      </b-row>
      <div class="mt-5">
        <router-link :to="{name: 'login'}">{{ $t('recover_pass_confirm.back_to_login') }}</router-link>
      </div>

    </b-form>
  </div>
</template>


<script>
const MIN_PASS_LENGTH = 8;
const MIN_PASS_DIGITS = 1;
const MIN_UPPERCASE_COUNT = 1;
const MIN_LOWERCASE_COUNT = 1;
const MIN_SYMBOL_COUNT = 1;
const MAX_REPEATED_CHARACTERS = 3;

import PasswordInput from "@/components/PasswordInput";

export default {
  name: "recover-pass",
  data() {
    return {
      validator_help_size_count: String(MIN_PASS_LENGTH),
      validator_help_digit_count: String(MIN_PASS_DIGITS),
      validator_help_uppercase_count: String(MIN_UPPERCASE_COUNT),
      validator_help_lowercase_count: String(MIN_LOWERCASE_COUNT),
      validator_help_symbol_count: String(MIN_SYMBOL_COUNT),
      validator_help_repeated_count: String(MAX_REPEATED_CHARACTERS),
      pass_input_has_focus: false,
      form: {
        new_password: "",
        password_retype: "",
      },
      form_invalid_feedback: {
        new_password: undefined,
        password_retype: undefined,
      },
      form_state: {
        new_password: undefined,
        password_retype: undefined,
      },
      show: true,
      show_pass_helper: false,
    };
  },
  computed: {
    validatePassLength() {
      if (this.form.new_password.length >= MIN_PASS_LENGTH) {
        return true;
      }
      return false;
    },
    validatePassDigits() {
      let digits_match = this.form.new_password.match(/\d+/gi);
      if (!digits_match) {
        return false;
      }
      if (digits_match.join("").length >= MIN_PASS_DIGITS) {
        return true;
      }
      return false;
    },
    validateUppercaseLength() {
      let uppercase_count = this.form.new_password.match(/[A-Z]/g);
      if (!uppercase_count) {
        return false;
      }
      if (uppercase_count.join("").length >= MIN_UPPERCASE_COUNT) {
        return true;
      }
      return false;
    },
    validateLowercaseLength() {
      let lowercase_count = this.form.new_password.match(/[a-z]/g);
      if (!lowercase_count) {
        return false;
      }
      if (lowercase_count.join("").length >= MIN_LOWERCASE_COUNT) {
        return true;
      }
      return false;
    },
    validateSymbolLength() {
      let symbol_count = this.form.new_password.match(
        /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/g
      );
      if (!symbol_count) {
        return false;
      }
      if (symbol_count.join("").length >= MIN_SYMBOL_COUNT) {
        return true;
      }
      return false;
    },
    validateConsecutiveCharacters() {
      let regex_string =
        "((\\w)\\2{" + String(MAX_REPEATED_CHARACTERS - 1) + ",})";
      let regex = new RegExp(regex_string, "g");
      let consecutive_count = this.form.new_password.match(regex);
      if (!consecutive_count) {
        return true;
      }
      return false;
    },
    validatePass() {
      if (!this.validatePassLength) {
        return false;
      }
      if (!this.validatePassDigits) {
        return false;
      }
      if (!this.validateUppercaseLength) {
        return false;
      }
      if (!this.validateLowercaseLength) {
        return false;
      }
      if (!this.validateSymbolLength) {
        return false;
      }
      if (!this.validateConsecutiveCharacters) {
        return false;
      }
      return true;
    },
    validateSamePass() {
      if (this.form.new_password != this.form.password_retype) {
        return false;
      }
      return true;
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        recover_pass_confirm: {
          password_placeholder: "Enter password",
          password_label: "Password",
          password_retype_placeholder: "Retype password",
          pass_not_match: "Password does not match.",
          password_retype_label: "Retype:",
          submit_button: "Recover pass",
          back_to_login: "Back to login",
          pass_validator_help_size: "Must contain at least {count} characters.",
          pass_validator_help_digit: "Must contain at least {count} digit.",
          pass_validator_help_uppercase:
            "Must contain at least {count} uppercase letter.",
          pass_validator_help_lowercase:
            "Must contain at least {count} lowercase letter.",
          pass_validator_help_symbol: "Must contain at least {count} symbol.",
          pass_validator_help_repeated:
            "Must not contain {count} repeated characters (Example: aaa)",
          password_mismatch: "Passwords don't match",
        },
      },
    },
  },
  methods: {
    handleOnBlurPassInput() {
      this.pass_input_has_focus = false;
    },
    handleOnFocusPassInput() {
      this.show_pass_helper = true;
      this.pass_input_has_focus = true;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.cleanFormErrors();
      if (this.form.new_password != this.form.password_retype) {
        this.putFormFeedback({
          password_retype: this.$t("recover_pass_confirm.pass_not_match"),
        });
        return;
      }

      var data = {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
        new_password: this.form.new_password,
      };

      this.$store.dispatch("auth/RECOVER_PASS_CONFIRM", data).then(
        response => {
          this.$emit("pass:recovered:success", {
            state: true,
            response: response,
          });
        },
        err => {
          this.$emit("pass:recovered:error", {
            state: false,
            response: err.response,
          });
          this.showResponseError(err);
          this.putFormFeedback(err.response);
        }
      );
    },
  },
  components: {
    PasswordInput,
  },
};
</script>


<style lang="scss" scoped>
input + label,
div + label {
  visibility: hidden;
  opacity: 0;
  font-size: 1rem;
}

/deep/ input:focus,
div.has-focus {
  &::placeholder {
    color: transparent;
  }

  + label {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
    color: $corp-color1;
  }
}

input#username,
input#mailinput,
/deep/ input#passinput,
/deep/ input#passinput_retype {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 0px;
}

/deep/ label::before {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none;
}

/deep/ input.form-control,
/deep/ input.form-control:focus {
  border: none;
  box-shadow: none;
}

input#mailinput::placeholder,
input#username::placeholder,
/deep/ input#passinput::placeholder,
/deep/ input#passinput_retype::placeholder {
  color: $text-main-color;
  font-style: normal;
  font-size: $font-size-base;
  transition: color 0.2s;
}
input#mailinput:focus::placeholder,
input#username:focus::placeholder,
/deep/ input#passinput:focus::placeholder,
/deep/ input#passinput_retype:focus::placeholder {
  color: transparent;
}
</style>
