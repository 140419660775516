<template>
  <div>
    <b-link class="plus_link" @click="handleClick($event)">
      <plus-circle-outline-icon v-if="icon=='plus-circle'" class="plusicon" :size="32" />
      <font-awesome-icon v-else :icon="icon" class="elseicon" />
      <slot>
      </slot>
    </b-link>
  </div>
</template>

<script>
export default {
  name: "v-plus-link",
  data() {
    return {};
  },
  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    icon: {
      default: "plus-circle",
      type: String,
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        v_plus_link: {},
      },
    },
  },
  methods: {
    handleClick(evt) {
      evt.preventDefault();
      if (this.to) {
        this.$router.push(this.to);
      }
      this.$emit("click", evt);
    },
  },
};
</script>


<style lang="scss" scoped>
.plus_link {
  color: $corp-color1;
  text-transform: uppercase;
  font-size: $font-size-base;
  font-weight: bold;
  text-decoration: none;
  padding: $spacing/6;
  padding-top: $spacing/3;
  padding-bottom: $spacing/3;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: $spacing/5;

  &:hover {
    color: $text-second-color;
    background-color: $corp-color1;
    text-decoration: none;
  }

  .plusicon, .elseicon {
    margin-right: 0.5rem;
  }

  .elseicon {
    font-size: $font-size-base * 1.5;
  }
}
</style>
