<template>
  <div>
    <vue-element-loading :active="loading" is-full-screen />
    <b-alert variant="danger" :show="isLoginError">
      {{ $t(errorMessage) }}
    </b-alert>
    <b-form @submit="doLogin" v-if="show">
      <b-form-group
        id="group1"
        class="text-left"
        label-for="mailinput"
        :invalid-feedback="form_invalid_feedback.email"
        :state="form_state.email"
      >
        <div class="d-flex flex-column-reverse">
          <b-form-input
            id="mailinput"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            v-model.trim="form.email"
            autocomplete="username email"
            required
            :state="form_state.email"
            :placeholder="$t('login.email_placeholder')"
          ></b-form-input>
          <label class="col-form-label pt-0" for="mailinput">{{
            $t("login.email_label")
          }}</label>
        </div>
      </b-form-group>
      <b-form-group id="group2" class="text-left" label-for="passinput">
        <div class="d-flex flex-column-reverse">
          <b-form-input
            id="passinput"
            type="password"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="current-password"
            v-model="form.password"
            required
            :placeholder="$t('login.password_placeholder')"
          ></b-form-input>
          <label class="col-form-label pt-0" for="mailinput">{{
            $t("login.password_label")
          }}</label>
        </div>
      </b-form-group>
      <b-form-group id="group3" class="text-left" v-if="!isApp()">
        <b-form-checkbox id="loginchecks" v-model="form.keep_logged">{{
          $t("login.stay_logged")
        }}</b-form-checkbox>
      </b-form-group>
      <b-row class="text-left" no-gutters>
        <b-col cols="12" xl="6">
          <b-button type="submit" variant="tertiary" size="sm">
            {{ $t("login.submit_button") }}
          </b-button>
        </b-col>
        <b-col cols="12" xl="6"></b-col>
      </b-row>
      <b-row class="text-left pt-4" no-gutters>
        <b-col cols="12" xl="6">
          <div>
            <router-link :to="{ name: 'recover_pass' }">{{
              $t("login.recover_link")
            }}</router-link>
          </div>
        </b-col>
        <b-col cols="12" xl="6">
          <div>
            <router-link :to="{ name: 'register' }">{{
              $t("login.register_link")
            }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <b-container v-if="!isApp()" fluid class="social-box mt-4 p-0 pt-4 pb-4">
      <b-row>
        <b-col cols="12" md="12" xl="12" class="d-flex align-items-center">
          <span class="mr-2">{{ $t("login.login_with_social") }}</span>
          <router-link :to="{ name: 'social_auth_facebook' }">
            <FB class="mr-2" thumbnail fluid alt="Facebook" />
          </router-link>
          <router-link :to="{ name: 'social_auth_twitter' }">
            <TW class="mr-2" thumbnail fluid alt="Twitter" />
          </router-link>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="!isApp()" fluid class="mt-0 p-0 pt-4 pb-4">
      <b-row>
        <b-col cols="12">
          {{ $t("login.download_app") }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="6">
          <a :href="app_android" target="_blank">
            <PlayStoreLogo />
          </a>
        </b-col>
        <b-col cols="6">
          <a :href="app_ios" target="_blank">
            <AppStoreLogo />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FB from "@/static/social/fb.svg";
import TW from "@/static/social/x.svg";
import PlayStoreLogo from "@/static/google-play-badge.svg";
import AppStoreLogo from "@/static/app-store-badge.svg";
import PlatformDetectMixin from "@/plugins/platform-detect-mixin";

export default {
  name: "login",
  components: {
    FB,
    TW,
    AppStoreLogo,
    PlayStoreLogo,
  },
  data() {
    return {
      isLoginError: false,
      errorCode: "",
      form: {
        email: "",
        password: "",
        keep_logged: PlatformDetectMixin.methods.isApp(),
      },
      form_invalid_feedback: {},
      form_state: {},
      show: true,
      app_android:
        "https://play.google.com/store/apps/details?id=com.medika.globalpdv",
      app_ios: "https://apps.apple.com/us/app/id1493832516",
      loading: false,
    };
  },
  computed: {
    foo() {
      return window.app_version;
    },
    errorMessage() {
      if (this.errorCode == "social-5") {
        return "login.error.no_mail_provided";
      } else {
        return "login.error.feedback";
      }
    },
  },
  mounted: function() {
    if (this.$route.meta.error) {
      this.isLoginError = true;
      this.errorCode = this.$route.query.code;
    }
  },
  fallbackI18n: {
    messages: {
      und: {
        login: {
          accept_legal_checkbox: "I accept the EULA / Legal terms & conditions",
          email_label: "Email",
          password_placeholder: "Enter password",
          email_placeholder: "Enter email",
          password_label: "Password",
          register_link: "I dont have account, register",
          recover_link: "I dont remember my password",
          submit_button: "Login",
          stay_logged: "Stay Logged In",
          login_with_social: "Log in with",
          box_title: "Login",
          download_app: "Download app",
          error: {
            feedback: "Unable to log in with provided credentials.",
            no_mail_provided:
              "No email was provided from this social network account.",
          },
        },
      },
    },
  },
  methods: {
    doLogin(evt) {
      this.isLoginError = false;
      this.errorCode = "";
      evt.preventDefault();
      var data = this.form;
      this.loading = true;

      this.$store.dispatch("auth/LOGIN", { data: data }).then(
        () => {
          this.$store.dispatch("auth/ME").then(undefined, e => {
            this.$sentry({ capture: e });
            this.$router.push({ name: "fallback-error-page" });
          });

          // Volvemos a cargar la config ya que los datos de usuario
          // logeado pueden ser sensiblemente distintos a los de un
          // usuario no logeado
          this.$store
            .dispatch("siteconf/LOAD_INITIAL_CONFIG", {
              data: {},
            })
            .then(
              () => {
                this.loading = false;
                this.$router.push({
                  name: "userhome",
                });
              },
              () => {
                this.loading = false;
                this.$router.push({ name: "fallback-error-page" });
              }
            );
        },
        err => {
          this.loading = false;
          this.isLoginError = true;
          // this.showResponseError(err);
          this.putFormFeedback(err.response);
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
input + label {
  visibility: hidden;
  opacity: 0;
  font-size: 1rem;
}

input:focus {
  &::placeholder {
    color: transparent;
  }

  + label {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
    color: $corp-color1;
  }
}

input#mailinput,
input#passinput {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 0px;
}

/deep/ label::before {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none;
}

.social-box {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;

  svg {
    filter: grayscale(100%);
    width: 26px;
    height: 26px;

    &:hover,
    &:focus {
      filter: grayscale(0%);
    }
  }
}
input.form-control,
input.form-control:focus {
  border: none;
  box-shadow: none;
}

input#mailinput::placeholder,
input#passinput::placeholder {
  color: $text-main-color;
  font-style: normal;
  font-size: $font-size-base;
  transition: color 0.2s;
}
input#mailinput:focus::placeholder,
input#passinput:focus::placeholder {
  color: transparent;
}
</style>
