<template>
  <div>
    <vue-element-loading :active="loading" />

    <b-table
      id="patient-address-table-list"
      responsive
      outlined
      head-variant="gray"
      stacked="sm"
      :busy.sync="isBusy"
      :items="tableProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
    >
      <template v-slot:cell(actions)="data">
        <b-button variant="primary" size="sm" @click="selectAddress(data.item)">
          {{ $t("patient_address_table_list.select_address") }}
        </b-button>
      </template>
    </b-table>
    <b-row>
      <b-col md="12">
        <b-pagination
          align="center"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="float-md-right"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "patient-address-table-list",
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      totalRows: 0,
      loading: true,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "firstname",
          label: this.$t("patient_address_table_list.headtable_name"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            const firstname = item.firstname || "";
            const lastname = item.lastname || "";
            return `${firstname} ${lastname}`.trim();
          },
        },
        {
          key: "address",
          label: this.$t("patient_address_table_list.headtable_address"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "federal_entity",
          label: this.$t("patient_address_table_list.headtable_federal_entity"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "town",
          label: this.$t("patient_address_table_list.headtable_town"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "postal_code",
          label: this.$t("patient_address_table_list.headtable_postal_code"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    tableProvider() {
      try {
        return this.$store
          .dispatch("naturalperson/REQUEST_ALL_ADDRESSES", {
            per_page: this.perPage,
            current_page: this.currentPage,
          })
          .then(
            response => {
              const items = response.data.results;
              this.isBusy = false;
              this.loading = false;
              this.totalRows = response.data.count;
              return items;
            },
            err => {
              this.$sentry({ capture: err });
              this.mnotify_error({
                text: this.$t(
                  "patient_address_table_list.error.cannot_load_items"
                ),
              });
              this.isBusy = false;
              this.loading = false;
              return [];
            }
          );
      } catch (e) {
        this.$sentry({ capture: e });
        this.mnotify_error({
          text: this.$t(
            "major_medical_insurance_table_list.error.cannot_load_items"
          ),
        });
        this.isBusy = false;
        this.loading = false;
        return [];
      }
    },
    selectAddress(address) {
      this.$emit("address-selected", address);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        patient_address_table_list: {
          headtable_name: "Name",
          headtable_address: "Address",
          headtable_colony: "Colony",
          headtable_federal_entity: "Federal entity",
          headtable_town: "Town",
          headtable_postal_code: "Postal Code",
          error: {
            cannot_load_items: "Cannot load items",
          },
          select_address: "Select",
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
#patient-address-table-list /deep/ thead th:last-child {
  width: 10%;
}

#patient-address-table-list /deep/ td {
  padding-bottom: $table-cell-padding * 2;
  padding-top: $table-cell-padding * 2;
}

@include media-breakpoint-down(sm) {
  /deep/ table.b-table > tbody > tr > td {
    grid-template-columns: 30% auto !important;

    &::before {
      text-align: left !important;
    }
  }
}
</style>
