<template>
  <div>
    <vue-element-loading :active="loading_insurancerefunds" />
    <VTableToolbarFilter
      :is_filtered="is_filtered"
      :filters_count="filters_count"
      v-on:showFilters="showFilters=!showFilters"
      v-on:clearFilters="clearFilters"
      v-on:applyFilter="applyFilter"
    />
    <b-table
      id="insurancerefund-table-list"
      ref="table_list"
      responsive
      outlined
      head-variant="gray"
      stacked="md"
      :busy.sync="isBusy"
      :items="tableProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      v-show="!selected_drug"
    >
      <template v-if="showFilters" slot="top-row" slot-scope="{ fields }">
        <td v-for="field in fields" :key="field.key">
          <b-form @submit.prevent="applyFilter">
            <VBootstrapFormGroup
              :type="filters[field.key].type ? filters[field.key].type : 'input'"
              label-sr-only
              stack-form-group
              v-if="filters && filters[field.key]"
              :name="field.key"
              :options="filters[field.key].options"
              :placeholder="field.label"
              v-model.trim="filters[field.key]['model']"
              v-on:input="if (filters[field.key].type == 'select') {applyFilter();}"
            >
            </VBootstrapFormGroup>
          </b-form>
        </td>
      </template>

      <template v-slot:cell(insurance_organization)="data">
        {{ data.item.insurance_organization.public_name }}
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
          v-if="data.item.object_status == 1"
          size="sm"
          variant="secondary-b"
          @click="routeToInsuranceRefund(data.item)"
        >
          {{ $t("insurancerefund_table_list.continue_refund_button") }}
        </b-button>

        <b-button
          v-if="data.item.object_status == 2"
          size="sm"
          variant="secondary-b"
          @click="routeToDetails(data.item)"
        >
          {{ $t("insurancerefund_table_list.details_refund_button") }}
        </b-button>
      </template>

      <template v-slot:cell(patient)="data">
        <div v-if="data.item && data.item.patient_address">
          {{ data.item.patient_address.firstname }}
        </div>
      </template>

      <template v-slot:cell(drugs)="data">
        <div
          class="btn"
          :id="'insurancerefund-invoicedrugs-popover' + data.item.uuid"
          v-if="parseRefundDrugs(data.item)"
        >
          <font-awesome-icon icon="eye" />
        </div>
        <b-popover
          v-if="parseRefundDrugs(data.item)"
          triggers="hover focus click"
          :target="'insurancerefund-invoicedrugs-popover' + data.item.uuid"
          :placement="'auto'"
        >
          <div
            v-for="(drug, index) in parseRefundDrugs(data.item)"
            :key="index"
          >
            <small>{{ drug.quantity }} x</small>
            {{ drug.default_description }}
          </div>
        </b-popover>
      </template>
    </b-table>
    <b-row v-if="show_pagination">
      <b-col md="12">
        <b-pagination
          align="center"
          v-show="!selected_drug"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="float-md-right"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
// import { Money } from "@/lib/money.js";
import VTableToolbarFilter from "@/components/VTableToolbarFilter";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";

const INSURANCE_REQUEST_STATUS_PENDING = 0;
const INSURANCE_REQUEST_STATUS_BUDGED = 1;
const INSURANCE_REQUEST_STATUS_AUTHLETTER = 2;
const INSURANCE_REQUEST_STATUS_AUTHORIZED = 3;
const INSURANCE_REQUEST_STATUS_ORDERED = 4;
const INSURANCE_REQUEST_STATUS_NOTAPPLICABLE = 5;
// El key en el orden de las traducciones coincide con su valor
// desde la api
const REQUEST_STATUS_TRANSLATOR = [
  "insuranceauthorization_table_list.request_status_pending",
  "insuranceauthorization_table_list.request_status_budged",
  "insuranceauthorization_table_list.request_status_authletter",
  "insuranceauthorization_table_list.request_status_authorized",
  "insuranceauthorization_table_list.request_status_ordered",
  "insuranceauthorization_table_list.request_status_notapplicable",
];
export default {
  name: "insurancerefund-table-list",
  components: { VTableToolbarFilter, VBootstrapFormGroup },
  props: {
    pt_uuid: String,
    i_uuid: String,
    static_items: {
      type: Array,
      default: undefined,
    },
    show_action_column: {
      type: Boolean,
      default: true,
    },
    show_pagination: {
      type: Boolean,
      default: true,
    },
  },
  // computed: {
  //   ...mapGetters("organization", ["getInsuranceDataByUUID"]),
  //   ...mapGetters("naturalperson", ["getPatientDataByUUID"])
  // },
  data() {
    return {
      search_term: "",
      datepicker_options: {
        format: "YYYY-MM-DD",
      },
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      loading_insurancerefunds: true,
      selected_drug: undefined,
      showFilters: false,
      sortBy: "created_date",
      sortDesc: true,
      is_filtered: false,
      filters: {
        policy_num: {
          filter_key: "policyNum",
          model: null,
        },
        insurance_organization: {
          filter_key: "insuranceName",
          model: null,
        },
        patient: {
          filter_key: "insuredName",
          model: null,
        },
        object_status: {
          type: "select",
          filter_key: "requestStatus",
          // De cara al usuario se han condensado los distintos estados en
          // tres estados, por esto se muestran 3 filtros como uno solo
          options: [
            {
              value: null,
              text: "",
            },
            {
              value: [
                INSURANCE_REQUEST_STATUS_PENDING,
                INSURANCE_REQUEST_STATUS_BUDGED,
                INSURANCE_REQUEST_STATUS_AUTHLETTER,
              ].join(),
              text: this.$t(
                REQUEST_STATUS_TRANSLATOR[INSURANCE_REQUEST_STATUS_PENDING]
              ),
            },
            {
              value: [
                INSURANCE_REQUEST_STATUS_AUTHORIZED,
                INSURANCE_REQUEST_STATUS_ORDERED,
              ].join(),
              text: this.$t(
                REQUEST_STATUS_TRANSLATOR[INSURANCE_REQUEST_STATUS_AUTHORIZED]
              ),
            },
            {
              value: String(INSURANCE_REQUEST_STATUS_NOTAPPLICABLE),
              text: this.$t(
                REQUEST_STATUS_TRANSLATOR[
                  INSURANCE_REQUEST_STATUS_NOTAPPLICABLE
                ]
              ),
            },
            {
              value: "-1",
              text: this.$t("insuranceauthorization_table_list.status_draft"),
            },
          ],
          model: null,
        },
      },
    };
  },
  // computed: {
  //   ...mapState({
  //     country_fields: state => state.siteconf.country_fields,
  //   }),
  // },
  computed: {
    filters_count() {
      let filters_count = 0;
      this._.forIn(this.filters, function(item) {
        if (item.model) {
          filters_count++;
        }
      });
      return filters_count;
    },
    fields() {
      return [
        {
          key: "created_date",
          label: this.$t("insurancerefund_table_list.headtable_date"),
          class: "selectable",
          thClass: "unselectable",
          formatter: value => {
            return this.i18nFormatDate(value);
          },
          sortable: true,
        },
        {
          key: "human_code",
          label: this.$t("insurancerefund_table_list.headtable_id"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
        },
        {
          key: "policy_num",
          label: this.$t("insurancerefund_table_list.headtable_policy_num"),
          class: "selectable d-md-none d-lg-table-cell",
          thClass: "unselectable d-md-none d-lg-table-cell",
        },
        {
          key: "insurance_organization",
          label: this.$t("insurancerefund_table_list.headtable_insurance"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "patient",
          label: this.$t("insurancerefund_table_list.headtable_insured"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "folio_num",
          label: this.$t("insurancerefund_table_list.headtable_folio_num"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "object_status",
          formatter: (value, key, item) => {
            if (value == 1) {
              return this.$t("insurancerefund_table_list.status_draft");
            }

            if (value == 2) {
              let request_status = item["request_status"];
              if (REQUEST_STATUS_TRANSLATOR[request_status]) {
                return this.$t(REQUEST_STATUS_TRANSLATOR[request_status]);
              }
              return this.$t("insurancerefund_table_list.status_done");
            }
          },
          label: this.$t("insurancerefund_table_list.headtable_status"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "drugs",
          label: this.$t("insurancerefund_table_list.headtable_drugs"),
          class: "selectable",
          thClass: "unselectable",
        },

        // {
        //   key: "recipe_amount_total",
        //   formatter: (value, key, item) => {
        //     if (!get(item, "recipe.amount.total")) {
        //       return "---";
        //     }

        //     return new Money(
        //       item.recipe.amount.total,
        //       item.recipe.amount.total_currency
        //     ).toLocaleString();
        //   },
        //   label: this.$t("insurancerefund_table_list.headtable_total"),
        //   class: "selectable",
        //   thClass: "unselectable"
        // },
        {
          key: "actions",
          label: "",
          thClass: this.show_action_column ? "" : "d-none",
          tdClass: this.show_action_column ? "" : "d-none",
        },
      ];
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancerefund_table_list: {
          headtable_date: "Date",
          headtable_id: "ID",
          headtable_status: "Status",
          headtable_total: "Total",
          headtable_policy_num: "Nº Policy",
          headtable_insurance: "Insurance",
          headtable_insured: "Insured",
          headtable_folio_num: "Nº Folio",
          headtable_drugs: "Products",
          status_draft: "Draft",
          status_done: "Done",
          continue_refund_button: "Continue",
          details_refund_button: "Details",
          request_status_pending: "Received",
          request_status_budged: "Received",
          request_status_authletter: "Received",
          request_status_authorized: "Authorized",
          request_status_ordered: "Authorized",
          request_status_notapplicable: "Rejected",
          error: {
            cannot_load_refunds: "Cannot load refunds",
          },
        },
      },
    },
  },
  methods: {
    clearFilters() {
      this._.forIn(this.filters, function(item) {
        item.model = null;
      });
      this.applyFilter();
    },
    applyFilter() {
      if (this.filters_count > 0) {
        this.is_filtered = true;
      } else {
        this.is_filtered = false;
      }
      this.$refs.table_list.refresh();
    },
    parseRefundDrugs(refund) {
      if (!refund) {
        return undefined;
      }
      if (!refund.invoices) {
        return undefined;
      }
      let drugs = [];
      refund.invoices.forEach(invoice => {
        invoice.item_list.drugs.forEach(drug => {
          drugs.push(drug);
        });
      });
      if (drugs.length <= 0) {
        return undefined;
      }
      return drugs;
    },
    routeToDetails(insurancerefund) {
      this.$router.replace({
        name: "nopatient_insurancerefund_detail",
        params: {
          i_uuid: insurancerefund.insurance_organization.uuid,
          insurancerefund_uuid: insurancerefund.uuid,
        },
      });
    },
    routeToInsuranceRefund(insurancerefund) {
      this.$router.replace({
        name: "nopatient_insurancerefund_home",
        params: {
          i_uuid: insurancerefund.insurance_organization.uuid,
          insurancerefund_uuid: insurancerefund.uuid,
        },
      });
    },
    tableProvider() {
      if (this.static_items) {
        this.isBusy = false;
        this.loading_insurancerefunds = false;
        return this.static_items;
      }

      let filters = {};
      this._.forIn(this.filters, function(item, key) {
        if (!item.model) {
          return;
        }
        let filter_key = key;
        if (item.filter_key) {
          filter_key = item.filter_key;
        }
        filters[filter_key] = item.model;
      });

      if (this.sortBy) {
        filters["ordering"] = this.sortBy;
        if (this.sortDesc) {
          filters["ordering"] = "-" + filters["ordering"];
        }
      }

      var source =
        "insurancerefund/REQUEST_PATIENT_INSURANCE_INSURANCEREFUND_LIST";
      if (this.i_uuid == undefined) {
        source = "insurancerefund/LOAD_PATIENT_ALL_INSURANCEREFUND";
      }

      try {
        return this.$store
          .dispatch(source, {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            per_page: this.perPage,
            current_page: this.currentPage,
            search: this.search_term,
            ...filters,
          })
          .then(
            response => {
              const items = response.data.results;
              this.isBusy = false;
              this.loading_insurancerefunds = false;
              this.totalRows = response.data.count;
              return items;
            },
            err => {
              this.$sentry({ capture: err });
              this.mnotify_error({
                text: this.$t(
                  "insurancerefund_table_list.error.cannot_load_refunds"
                ),
              });
              this.isBusy = false;
              this.loading_insurancerefunds = false;
              return [];
            }
          );
      } catch (e) {
        this.$sentry({ capture: e });
        this.mnotify_error({
          text: this.$t("insurancerefund_table_list.error.cannot_load_refunds"),
        });
        this.isBusy = false;
        this.loading_insurancerefunds = false;
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#insurancerefund-table-list /deep/ thead th:last-child {
  width: 10%;
}

@include media-breakpoint-down(sm) {
  /deep/ table.b-table > tbody > tr > td {
    grid-template-columns: 30% auto !important;

    &::before {
      text-align: left !important;
    }
  }
}
</style>
