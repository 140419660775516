import Vue from "vue";
//import i18n from "@/lib/i18n";
import * as Sentry from "@sentry/browser";
//import "@/../node_modules/noty/lib/noty.css";
//import "@/../node_modules/noty/lib/themes/metroui.css";
//import Noty from "noty";

//var lastNoty = null;

/*
const notifyError = (msg, force) => {
  // Only show error when forced or when last error is closed
  if (force || !lastNoty || lastNoty.closed) {
    lastNoty = new Noty({
      theme: "metroui",
      type: "error",
      text: msg
    });
    lastNoty.show();
  }
};
*/

export const handleAppErrors = () => {
  // Global handler
  var handleError = err => {
    Sentry.captureException(err);
    //notifyError(i18n.t("global.unexpected_error"));
  };

  // Subscribe to errors
  window.addEventListener("error", event => {
    handleError(event.error);
    return false;
  });
  window.addEventListener("unhandledrejection", event => {
    handleError(event.reason);
    return false;
  });
  Vue.config.errorHandler = err => {
    handleError(err);
    // eslint-disable-next-line no-console
    console.error(err);
  };
};
