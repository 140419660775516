<template>
  <div>
    <b-modal
      ref="first_login_popup"
      size="xl"
      modal-class="modal-square-minimalist-theme"
      centered
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-col class="text-center mb-5">
            <GlobalPDVLogo class="w-25 mt-5 mx-auto d-none d-sm-block" alt="Global PDV Logo" />
            <h2 class="title text-uppercase mt-5 font-weight-semibold">{{ $t('bridge_first_login_popup.title') }}</h2>
            <div deck class="column-max-5 mt-5 mb-5">
              <div class="column-max-item pl-3 pr-3">
                <div class="d-flex flex-column justify-content-center">
                  <div class="pl-4 pr-4">
                    <Circle1 />
                  </div>
                  <div class="pt-3">
                    <h5 class="step1-text text-uppercase">{{ $t('bridge_first_login_popup.step1_text') }}</h5>
                  </div>
                </div>
              </div>
              <div class="column-max-item pl-3 pr-3">
                <div class="d-flex flex-column justify-content-center">
                  <div class="pl-4 pr-4">
                    <Circle2 />
                  </div>
                  <div class="pt-3">
                    <h5 class="step2-text text-uppercase">{{ $t('bridge_first_login_popup.step2_text') }}</h5>
                  </div>
                </div>
              </div>
              <div class="column-max-item pl-3 pr-3">
                <div class="d-flex flex-column justify-content-center">
                  <div class="pl-4 pr-4">
                    <Circle3 />
                  </div>
                  <div class="pt-3">
                    <h5 class="step3-text text-uppercase">{{ $t('bridge_first_login_popup.step3_text') }}</h5>
                  </div>
                </div>
              </div>
              <div class="column-max-item pl-3 pr-3">
                <div class="d-flex flex-column justify-content-center">
                  <div class="pl-4 pr-4">
                    <Circle4 />
                  </div>
                  <div class="pt-3">
                    <h5 class="step4-text text-uppercase">{{ $t('bridge_first_login_popup.step4_text') }}</h5>
                  </div>
                </div>
              </div>
              <div class="column-max-item pl-3">
                <div class="d-flex flex-column justify-content-center pr-3">
                  <div class="pl-4 pr-4">
                    <Circle5 />
                  </div>
                  <div class="pt-3">
                    <h5 class="step5-text text-uppercase">{{ $t('bridge_first_login_popup.step5_text') }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import GlobalPDVLogo from "@/static/global_pdv_logo.svg";
// NOTE: estos iconos se usan aquí y en el popup de reembolso
// tener el cuenta a la hora de cambiarlos
import Circle1 from "@/static/first_login/circle1.svg";
import Circle2 from "@/static/first_login/circle2.svg";
import Circle3 from "@/static/first_login/circle3.svg";
import Circle4 from "@/static/first_login/circle4.svg";
import Circle5 from "@/static/first_login/circle5.svg";

export default {
  name: "v-first-login-bridge-guide-popup",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      show_bridge_guide_first_login_popup: (state) =>
        state.appstatus.show_bridge_guide_first_login_popup,
      show_pdv_bridge_guide: (state) => state.siteconf.show_pdv_bridge_guide,
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  fallbackI18n: {
    messages: {
      und: {
        bridge_first_login_popup: {
          title: "PDV bridge guide",
          step1_text: "Select PDV bridge",
          step2_text: "Go to your insurance",
          step3_text: "Upload your documents",
          step4_text: "Fill your insurance application",
          step5_text: "Send to your insurance",
        },
      },
    },
  },
  mounted: function () {
    this.handleModal();
  },
  methods: {
    ...mapActions({
      STOP_BRIDGE_GUIDE_FIRST_LOGIN: "appstatus/STOP_BRIDGE_GUIDE_FIRST_LOGIN",
    }),
    handleModal() {
      if (!this.isAuthenticated) {
        return;
      }

      if (
        this.show_bridge_guide_first_login_popup &&
        this.show_pdv_authorization_guide
      ) {
        this.$refs["first_login_popup"].show();
        // Descomentar esta línea para que sólo se muestre la primera vez
        // this.STOP_BRIDGE_GUIDE_FIRST_LOGIN();
      }
    },
  },
  components: {
    GlobalPDVLogo,
    Circle1,
    Circle2,
    Circle3,
    Circle4,
    Circle5,
  },
};
</script>

<style scoped lang="scss">
.title {
  color: $corp-color1;
}
.step1-text {
  color: #a9c14f;
}
.step2-text {
  color: #ffb200;
}
.step3-text {
  color: #fb9a02;
}
.step4-text {
  color: #fe7e01;
}
.step5-text {
  color: #fe5000;
}
</style>
