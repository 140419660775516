import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    lastest_routes_by_name: [],
    latest_params: {},
    latest_route_name: '',
  },
  actions: {
  },
  mutations: {
    MUTATE_LAST_ROUTE: (state, payload) => {
      let lastest_routes_by_name = state.lastest_routes_by_name;
      lastest_routes_by_name[payload.name] = Object.assign({}, payload);
      Vue.set(state, 'lastest_routes_by_name', lastest_routes_by_name);

      let latest_params = state.latest_params;
      latest_params = Object.assign(latest_params, payload.params);
      Vue.set(state, 'latest_params', latest_params);
      Vue.set(state, 'latest_route_name', payload.name);
    },
  },
  getters: {
    getLatestToObjetByName: (state) => (name) => {
      if (state.lastest_routes_by_name.indexOf(name) < 0) {
        return undefined;
      }

      return {
        name: name,
        params: state.lastest_routes_by_name[name].params,
      };
    },
    getLatestParams: (state) => {
      return state.latest_params;
    },
  },
};
