import {
  drugtransaction_patient_insurance_insurancebridge_create,
  drugtransaction_patient_insurance_insurancebridge_read,
  drugtransaction_patient_insurance_insurancebridge_draft_read,
  drugtransaction_patient_insurancebridge_draft_list,
  drugtransaction_patient_insurance_insurancebridge_partial_update,
  drugtransaction_patient_insurance_insurancebridge_list,
  drugtransaction_patient_insurancebridge_list,
  drugtransaction_nopatient_insurance_insurancebridge_create,
  drugtransaction_nopatient_insurance_insurancebridge_read,
  drugtransaction_nopatient_insurance_insurancebridge_draft_read,
  drugtransaction_nopatient_insurancebridge_draft_list,
  drugtransaction_nopatient_insurance_insurancebridge_partial_update,
  drugtransaction_nopatient_insurance_insurancebridge_list,
  drugtransaction_nopatient_insurancebridge_list,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    draft_insurancebridges: [],
    active_insurancebridge: undefined,
    feedback_insurancebridge: undefined,
  },
  actions: {
    CREATE_PATIENT_INSURANCE_DRAFT_INSURANCEBRIDGE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insurancebridge_create
          : drugtransaction_nopatient_insurance_insurancebridge_create;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          data: payload.data,
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEBRIDGE", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_INSURANCE_INSURANCEBRIDGE_LIST: (undefined, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insurancebridge_list
          : drugtransaction_nopatient_insurance_insurancebridge_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          offset: offset,
          limit: payload.per_page,
          search: payload.search || undefined,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DONE_PATIENT_INSURANCE_DRAFT_INSURANCEBRIDGE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insurancebridge_partial_update
          : drugtransaction_nopatient_insurance_insurancebridge_partial_update;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          data: { object_status: 2 },
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEBRIDGE", response.data);
            commit("MUTATE_FEEDBACK_INSURANCEBRIDGE", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_INSURANCE_INSURANCEBRIDGE: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insurancebridge_read
          : drugtransaction_nopatient_insurance_insurancebridge_read;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_INSURANCE_DRAFT_INSURANCEBRIDGE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insurancebridge_draft_read
          : drugtransaction_nopatient_insurance_insurancebridge_draft_read;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancebridgeUuid: payload.insurancebridge_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEBRIDGE", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_ALL_INSURANCE_DRAFT_INSURANCEBRIDGE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurancebridge_draft_list
          : drugtransaction_nopatient_insurancebridge_draft_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
        }).then(
          response => {
            commit("MUTATE_ALL_DRAFT_INSURANCEBRIDGE", {
              data: response.data.results,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_ALL_INSURANCEBRIDGE: ({ commit }, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurancebridge_list
          : drugtransaction_nopatient_insurancebridge_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          offset: offset,
          limit: payload.per_page,
          ...payload,
        }).then(
          response => {
            commit("MUTATE_ALL_INSURANCEBRIDGE", {
              data: response.data.results,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_FEEDBACK_INSURANCEBRIDGE: (state, payload) => {
      Vue.set(state, "feedback_insurancebridge", payload);
    },
    MUTATE_DRAFT_INSURANCEBRIDGE: (state, payload) => {
      let draft_insurancebridges = state.draft_insurancebridges;
      let new_draft_insurancebridges = [];
      let found_in_state = false;

      // recorremos los draft insurance auth actuales en state
      draft_insurancebridges.forEach(function(draft_insurancebridge) {
        // si el payload ya se encuentra en state
        if (payload.uuid == draft_insurancebridge.uuid) {
          // marcamos banderita de encontrado
          found_in_state = true;
          // y lo añadimos si payload es draft, de lo contrario se trata
          // de un insurancebridge que ha cambiado de draft a done y por
          //tanto no lo queremos
          if (payload.object_status == 1) {
            new_draft_insurancebridges.push(payload);
          }
          return;
        }
        // mantenemos los reembolsos que no soy payload tal y como estaban
        new_draft_insurancebridges.push(draft_insurancebridge);
      });

      // si payload está en estado draft y no ha sido encontrado en
      // state, lo añadimos (es un draft nuevo)
      if (payload.object_status == 1 && !found_in_state) {
        new_draft_insurancebridges.push(payload);
      }

      // ponemos el insurancebridge activo a indefinido
      Vue.set(state, "active_insurancebridge", undefined);
      // si el payload está en modo draft, lo añadimos a activo, de lo contrario
      // dejamos el active_insurancebridge vacío
      if (payload.object_status == 1) {
        Vue.set(state, "active_insurancebridge", payload);
      }

      // actualizamos state con los draft correctos
      Vue.set(state, "draft_insurancebridges", new_draft_insurancebridges);
    },
    MUTATE_ALL_DRAFT_INSURANCEBRIDGE: (state, payload) => {
      Vue.set(state, "draft_insurancebridges", payload.data);
    },
    MUTATE_ALL_INSURANCEBRIDGE: (state, payload) => {
      Vue.set(state, "insurancebridges", payload.data);
    },
  },
  getters: {
    getDraftInsuranceBridgeByInsuranceUUID: state => (
      i_uuid,
      insurancebridge_uuid
    ) => {
      let draft_insurancebridges = state.draft_insurancebridges;
      let found = undefined;
      draft_insurancebridges.forEach(function(insurancebridge) {
        if (!insurancebridge.insurance) {
          return;
        }
        if (
          insurancebridge.insurance.uuid == i_uuid &&
          insurancebridge.uuid == insurancebridge_uuid
        ) {
          found = insurancebridge;
        }
      });
      return found;
    },
    getFeedbackInsuranceBridge: state => {
      return state.feedback_insurancebridge;
    },
  },
};
