<template>
  <VLayout upline :title="$t('cookies.title')">
    <VMarkdownView :mdtext="$t('cookies.legal_text')" class="text-justify" />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VMarkdownView from "@/components/VMarkdownView";
import cookies_es_mx from "raw-loader!@/static/COOKIES_es-MX.txt";
import cookies_en_us from "raw-loader!@/static/COOKIES_en-US.txt";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  fallbackI18n: {
    messages: {
      und: {
        cookies: {
          title: "Global PDV Cookies",
          legal_text: cookies_en_us,
        },
      },
    },
  },
  i18n: {
    messages: {
      "es-MX": {
        cookies: {
          title: "Política de cookies",
          legal_text: cookies_es_mx,
        },
      },
      "en-US": {
        cookies: {
          title: "Global PDV Cookies",
          legal_text: cookies_en_us,
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "cookies.title",
          to: { name: "cookies" },
        },
      ]);
    },
  },

  components: {
    VLayout,
    VMarkdownView,
  },
};
</script>

<style lang="scss">
ol {
  list-style-type: upper-roman;
}
</style>
