// Make the entire project compatible with everything
import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import * as Sentry from "@sentry/browser";
import App from "@/components/App";
import router from "@/router";
import store from "@/store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import Notifications from "vue-notification";
import VueElementLoading from "vue-element-loading";
import VueLodash from "vue-lodash";
import { VueReCaptcha } from "vue-recaptcha-v3";
//import * as VueGoogleMaps from "vue2-google-maps";

// component styles
//import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

// original styles from bootstrap
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// load custom bootstrap theme overriding styles for this project
import "@/assets/css/themes/default/bootstrap-vue-theme.css";

// TODO: Keep in main RTL bootstrap styles:
// https://github.com/DediData/Bootstrap-RTL
// https://github.com/morteza/bootstrap-rtl

// load non bootstrap styles
import "@/assets/css/aspect-ratio.css";
import "@/assets/css/fonts.css";

// load entry point for styles
import "@/assets/scss/themes/default/main.scss";

// Icons and flags
import "@/assets/scss/font-awesome.scss";

// api rest lib generated from swagger.json
import { setDomain } from "@/lib/api-client.js";
// custom lib to configure api requests
import { getApiUri } from "@/lib/api-config.js";

// extra custom libs and plugins
import VueBootstrapUtilForm from "@/plugins/vue-bootstrap-utilform";
import PdvNotifications from "@/plugins/pdv-notifications.js";
import PlatformDetect from "@/plugins/platform-detect";
import VueNumberFormatter from "@/plugins/global-number-formatter";
import PlatformDetectMixin from "@/plugins/platform-detect-mixin";
import ConsoleOut from "@/plugins/console-out.js";
import DevelatioVueSentry from "@/plugins/vue-sentry.js";
import i18n from "@/lib/i18n";
import VueI18nFallback from "@/plugins/vue-i18n-fallback";

// icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";

// Icons for vue-bootstrap-datetimepicker
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
import PlusCircleOutlineIcon from "vue-material-design-icons/PlusCircleOutline.vue";

import { mapState } from "vuex";
import { mapGetters } from "vuex";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Sentry.Integrations.Vue({ Vue })],
  });
}

// No queremos service workers (de momento)
if (process.browser) {
  if (
    navigator &&
    navigator.serviceWorker &&
    navigator.serviceWorker.getRegistrations &&
    typeof navigator.serviceWorker.getRegistrations === "function"
  ) {
    navigator.serviceWorker
      .getRegistrations()
      .then(rs => rs.map(r => r.unregister()))
      .catch(() => {
        //noop, we failed to remove the SW for some reason
      });
  }
}

// Patch for Samsung Internet (Browser) V 7.2
// see: https://github.com/SamsungInternet/support/issues/56
if (process.browser) {
  if (navigator.userAgent.match(/SamsungBrowser\/7\.2/i)) {
    Function.prototype.ToString = function() {
      return this.toString();
    };
  }
}

//  errors handling
import { handleAppErrors } from "@/lib/errors.js";

// add icons to library
library.add(faPen);
library.add(faInfoCircle);
library.add(faCaretDown);
library.add(faEye);
library.add(faEyeSlash);
library.add(faPencilAlt);
library.add(faPencilRuler);
library.add(faPlusCircle);
library.add(faExclamationTriangle);
library.add(faCheckCircle);
library.add(faTrashAlt);
library.add(faHome);
library.add(faSave);
library.add(faClock);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faCalendar);
library.add(faArrowUp);
library.add(faArrowDown);
library.add(faCalendarCheck);
library.add(faTimesCircle);
library.add(faSearch);
library.add(faFilter);

// Processing i tags into svg using Font Awesome

// A basic installation of Font Awesome has the ability to automatically
// transform <i class="fas fa-coffee"></i> into <svg class="...">...</svg> icons.
// This technology works with the browser's DOM, requestAnimationFrame, and
// MutationObserver.

// When using the @fortawesome/fontawesome-svg-core package this behavior is
// disabled by default. This project uses that package so you will have
// to explicitly enable it like this:
dom.watch();

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("VueElementLoading", VueElementLoading);
Vue.component("plus-circle-outline-icon", PlusCircleOutlineIcon);
Vue.config.productionTip = false;

// set configured url for api
setDomain(getApiUri());

// Load  plugins and modules into Vue
Vue.prototype.$http = axios;

// Set properties in app
Vue.prototype.$phoneHelp =
  "5215611409779" +
  "&text=" +
  encodeURI("¡Hola Global Store PDV! Solicito ayuda con ");

//Vue.use(VueI18n)
Vue.use(VueI18nFallback);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueJwtDecode);
Vue.use(Notifications);
Vue.use(VueBootstrapUtilForm);
Vue.use(PdvNotifications);
Vue.use(PlatformDetect);
Vue.use(ConsoleOut);
Vue.use(DevelatioVueSentry);
Vue.use(VueNumberFormatter);
// Vue.use(datePicker);

Vue.use(VueLodash);

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
});

/*Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places",
  },
});*/

const STATUS_NONE = 0;
// const STATUS_LOADING = 1;
const STATUS_DONE = 2;
const STATUS_DONE_WITH_ERROR = 3;

handleAppErrors();
// init app and export
const app = new Vue({
  data() {
    return {
      status: STATUS_NONE,
      loading_timeout_interval: undefined,
    };
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated,
      jwt: state => state.auth.jwt,
      loading: state => state.appstatus.loading,
      active_lang: state => state.i18nmap.active_lang,
    }),
    ...mapGetters("i18nmap", [
      "bestAvailableLangtag",
      "isI18nLoaded",
      "isI18nError",
    ]),
    ...mapGetters("rates", ["isRatesLoaded", "isRatesError"]),
    ...mapGetters("auth", ["countryFromRequest"]),
    is_all_loaded() {
      return this.isRatesLoaded && this.isI18nLoaded && this.countryFromRequest;
    },
  },
  watch: {
    is_all_loaded: function(loaded) {
      this.consoleout("Loading change found, check");
      if (loaded) {
        if (this.isRatesError || this.isI18nError) {
          this.consoleout("App fully loaded with ERRORS");
          this.$store.dispatch("appstatus/PUT_LOADING_STATUS", {
            status: STATUS_DONE_WITH_ERROR,
          });
        } else {
          this.consoleout("App fully loaded");
          this.$store.dispatch("appstatus/PUT_LOADING_STATUS", {
            status: STATUS_DONE,
          });
        }
      }
    },
    loading: function(loading) {
      if (!loading) {
        // si ya no está cargando, paramos la comprobación del timeout
        this._stopCheckLoadingTimeout();
        return;
      }

      // si la app cambia a estado loading, volvemos a iniciar el timeout check
      if (loading && !this.loading_timeout_interval) {
        this.consoleout("Start timeout check scheduled task");
        // comprobamos cada 5 segundos el estado de la carga
        this._startCheckLoadingTimeout();
      }
    },
  },
  methods: {
    _checkAuthToken: function() {
      if (!this.authenticated) {
        return;
      }
      store.dispatch("auth/CHECK_TOKEN").then(undefined, () => {
        // Not authenticated in protected view. Go Login.'
        // go to login'
        this.$router.push({ name: "login" });
      });
    },
    _checkTranslations: function() {
      if (this.active_lang && this.active_lang.langtag) {
        this.$store.dispatch("i18nmap/LOAD_PUBLIC_LANGUAGE_TRANSLATIONS", {
          langtag: this.active_lang.langtag,
        });
      }
    },
    _checkLoadingTimeout: function() {
      if (this.loading) {
        this.consoleout("Loading timeout check ...");
        this.$store.dispatch("appstatus/CHECK_LOADING_TIMEOUT");
        return;
      }
      // ya no está cargando, dejamos de comprobar el timeout
      this._stopCheckLoadingTimeout();
    },
    _startCheckLoadingTimeout: function() {
      this.consoleout("Start: loading timeout scheduled check.");
      this.loading_timeout_interval = setInterval(
        function() {
          this._checkLoadingTimeout();
        }.bind(this),
        5000
      );
    },
    _stopCheckLoadingTimeout: function() {
      this.consoleout("Stop: loading timeout scheduled check.");
      clearInterval(this.loading_timeout_interval);
      this.loading_timeout_interval = undefined;
    },
    scheduledActions: function() {
      this._checkAuthToken();
      this._checkTranslations();
    },
    initApp: function() {
      var init = async () => {
        // Estamos en una app movil
        if (PlatformDetectMixin.methods.isApp()) {
          this.$store.commit("siteconf/MUTATE_ACCEPT_COOKIES", true);

          // If we're on an iOS app, we can have a bidirectional JS <-> Swift bridge
          // Thanks to https://github.com/Lision/WKWebViewJavascriptBridge
          if (PlatformDetectMixin.methods.isiOSApp()) {
            const setupWKWebViewJavascriptBridge = callback => {
              if (window.WKWebViewJavascriptBridge) {
                return callback(window.WKWebViewJavascriptBridge);
              }

              if (window.WKWVJBCallbacks) {
                return window.WKWVJBCallbacks.push(callback);
              }

              window.WKWVJBCallbacks = [callback];
              window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(
                null
              );
            };

            setupWKWebViewJavascriptBridge(bridge => {
              window.bridge = bridge;
            });
          }
        }

        // El cliente tiene un JWT.
        if (this.jwt) {
          // Validamos JWT
          var promise = this.$store
            .dispatch("auth/CHECK_TOKEN")
            .then(undefined, () => {
              // No ha validado, volvemos a login
              this.$router.push({ name: "login" });
            });
          // esperamos la validación
          await promise;
        }

        // Cada 60 segundos ejecutamos scheduledActions()
        setInterval(
          function() {
            this.scheduledActions();
          }.bind(this),
          60000
        );

        // Arrancamos las tareas programadas la primera vez a mano
        this.scheduledActions();
        // En una carga inicial, arrancamos el test de loading timeout.
        // Esto inicia un setInterval cada 5 segundos, cuando la app deja de
        // estar en estado loading, el interval se elimina.
        this._startCheckLoadingTimeout();

        this.$store
          .dispatch("siteconf/LOAD_INITIAL_CONFIG", {
            data: {},
            i18nbakend: i18n,
          })
          .then(
            () => {
              this.$store.dispatch("appstatus/PUT_LOADING_STATUS", {
                status: STATUS_DONE,
              });
              this.routeToPending();
            },
            () => {
              this.$store.dispatch("appstatus/PUT_LOADING_STATUS", {
                status: STATUS_DONE_WITH_ERROR,
              });
              this.routeToPending();
            }
          );
      };
      init();
    },
    routeToPending() {
      if (this.$router.history.pending) {
        let pending = this.$router.history.pending;
        this.$router.push({
          name: pending.name,
          params: pending.params,
          query: pending.query,
          hash: pending.hash,
        });
      }
    },
  },
  beforeMount: function() {
    this.initApp();
  },
  i18n,
  VueI18nFallback,
  router,
  store,
  ...App,
});
export { app, router, store };
