<template>
  <v-layout>
    <vue-element-loading :active="true" />
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import { mapGetters } from "vuex";
import { getApiUri } from "@/lib/api-config.js";

export default {
  props: {
    code: String,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted: function() {
    const ALLOWED_PROVIDERS = {
      facebook: true,
      instagram: false,
      twitter: true,
    };

    if (this.$route.meta.done) {
      this.doLogin();
      return;
    }

    if (this.$route.meta.error) {
      this.$router.push({
        name: "login-error",
        query: { code: "social-" + this.code },
      });
      return;
    }

    try {
      const apiurl = getApiUri();
      const provider = this.$route.meta.provider;

      if (!ALLOWED_PROVIDERS[provider]) {
        this.$router.push({ name: "login" });
        return;
      }

      const frontend = process.env.VUE_APP_SITE_CONTEXT;
      const auth_url = `${apiurl}/authx/guest/1/auth/social/${provider}/to/${frontend}/`;
      window.location = auth_url;
    } catch (e) {
      this.$sentry({ capture: e });
      this.$router.push({ name: "login" });
    }
  },
  fallbackI18n: {
    messages: {
      und: {
        social_auth: {
          error: {
            cannot_login: "Cannot perform login",
          },
        },
      },
    },
  },
  methods: {
    doLogin() {
      const access = this.$route.query.access;
      const refresh = this.$route.query.refresh;
      this.$store.commit("auth/UPDATE_TOKEN", access);
      this.$store.commit("auth/UPDATE_TOKEN_REFRESH", refresh);
      this.$router.push({ name: "login" });
    },
  },
  components: {
    VLayout,
  },
};
</script>

<style>
</style>
