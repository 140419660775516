<template>
  <v-layout :bgImage="true" class="home-layout">
    <div>
      <VListCardHomeLinks />
    </div>
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VListCardHomeLinks from "@/components/VListCardHomeLinks";

export default {
  mounted: function() {
    this.$store.commit("siteconf/MUTATE_BREADCRUMB", undefined);
  },

  components: {
    VLayout,
    VListCardHomeLinks,
  },
};
</script>

<style lang="scss" scoped>
</style>
