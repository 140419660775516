<template>
  <v-layout :showbreadcrumbs="false" fullwidth>
    <VHomeImgJumbo>
      <template v-slot:right>
        <VHomeImgJumboCard :title="$t('register_confirm.box_title')">
          <div v-if="loading">{{ $t('register_confirm.wait_text') }}</div>
          <b-alert :show="!valid && !loading" variant="danger">{{ $t('register_confirm.error.cannot_validate') }}</b-alert>
          <b-alert :show="valid && !loading" variant="success">{{ $t('register_confirm.account_validated') }}</b-alert>
          <b-button
            v-if="valid"
            type="submit"
            variant="tertiary"
            class="mt-4"
            size="sm"
            @click="routeToLogin()"
          >
            {{ $t('register_confirm.go_to_login_button') }}
          </b-button>
        </VHomeImgJumboCard>
      </template>
    </VHomeImgJumbo>
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VHomeImgJumbo from "@/components/VHomeImgJumbo";
import VHomeImgJumboCard from "@/components/VHomeImgJumboCard";

export default {
  data() {
    return {
      valid: undefined,
      loading: true,
    };
  },
  mounted: function() {
    this.validateAccount();
  },
  methods: {
    routeToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    validateAccount: function() {
      const token = this.$route.params.token;
      const uid = this.$route.params.uid;

      if (!token || !uid) {
        this.mnotify_error({
          text: this.$t("register_confirm.error.cannot_validate"),
        });
        this.valid = false;
        this.loading = false;
        return;
      }

      this.$store
        .dispatch("auth/ACCOUNT_CONFIRMATION", {
          token: token,
          uid: uid,
        })
        .then(
          () => {
            this.valid = true;
            this.loading = false;
          },
          () => {
            this.valid = false;
            this.loading = false;
          }
        );
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        register_confirm: {
          box_title: "Register confirmation",
          wait_text: "Please, wait...",
          go_to_login_button: "Go to login",
          account_validated: "Account validated",
          error: {
            cannot_validate: "Cannot validate your account",
          },
        },
      },
    },
  },
  components: {
    VLayout,
    VHomeImgJumbo,
    VHomeImgJumboCard,
  },
};
</script>

<style>
</style>
