<template>
  <v-layout :showbreadcrumbs="false" fullwidth>
    <VHomeImgJumbo>
      <template v-slot:right>
        <VHomeImgJumboCard :title="$t('recover_pass_confirm.box_title')">
          <RecoverPassFormConfirm v-on:pass:recovered:success="onPassRecovered" />
        </VHomeImgJumboCard>
      </template>
    </VHomeImgJumbo>
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import RecoverPassFormConfirm from "@/components/RecoverPassFormConfirm";
import VHomeImgJumbo from "@/components/VHomeImgJumbo";
import VHomeImgJumboCard from "@/components/VHomeImgJumboCard";

export default {
  methods: {
    onPassRecovered: function() {
      this.$router.push({
        name: "login",
      });
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        recover_pass_confirm: {
          box_title: "New password",
        },
      },
    },
  },
  components: {
    VLayout,
    RecoverPassFormConfirm,
    VHomeImgJumbo,
    VHomeImgJumboCard,
  },
};
</script>

<style>
</style>
