<template>
  <v-layout :title="$t('pharmacy_new.content_title')">
    <VPharmacyCreateForm v-on:pharmacy-created="onPharmacyCreated" />
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/AccountLayout";
import VPharmacyCreateForm from "@/components/PharmacyCreateForm";

export default {
  methods: {
    onPharmacyCreated: function() {
      this.$router.push({
        name: "userhome",
      });
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        pharmacy_new: {
          content_title: "New pharmacy",
        },
      },
    },
  },
  components: {
    VLayout,
    VPharmacyCreateForm,
  },
};
</script>

<style>
</style>
