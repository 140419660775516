/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else if (method === 'delete') {
    return axios[method](queryUrl, config);
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Vooy CORE (R) API.
 ==========================================================*/
/**
 * 
 * request: authx_auth_country_list
 * url: authx_auth_country_listURL
 * method: authx_auth_country_list_TYPE
 * raw_url: authx_auth_country_list_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_country_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/country/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const authx_auth_country_list_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/country/'
}
export const authx_auth_country_list_TYPE = function() {
  return 'get'
}
export const authx_auth_country_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/country/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Takes a set of user credentials and returns an access and refresh JSON web
token pair to prove the authentication of those credentials.
* request: authx_auth_jwt_create_create
* url: authx_auth_jwt_create_createURL
* method: authx_auth_jwt_create_create_TYPE
* raw_url: authx_auth_jwt_create_create_RAW_URL
     * @param data - 
     * @param role - 
     * @param roleUuid - 
*/
export const authx_auth_jwt_create_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/jwt/create/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_jwt_create_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/jwt/create/'
}
export const authx_auth_jwt_create_create_TYPE = function() {
  return 'post'
}
export const authx_auth_jwt_create_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/jwt/create/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
* request: authx_auth_jwt_refresh_create
* url: authx_auth_jwt_refresh_createURL
* method: authx_auth_jwt_refresh_create_TYPE
* raw_url: authx_auth_jwt_refresh_create_RAW_URL
     * @param data - 
     * @param role - 
     * @param roleUuid - 
*/
export const authx_auth_jwt_refresh_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/jwt/refresh/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_jwt_refresh_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/jwt/refresh/'
}
export const authx_auth_jwt_refresh_create_TYPE = function() {
  return 'post'
}
export const authx_auth_jwt_refresh_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/jwt/refresh/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Takes a token and indicates if it is valid.  This view provides no
information about a token's fitness for a particular use.
* request: authx_auth_jwt_verify_create
* url: authx_auth_jwt_verify_createURL
* method: authx_auth_jwt_verify_create_TYPE
* raw_url: authx_auth_jwt_verify_create_RAW_URL
     * @param data - 
     * @param role - 
     * @param roleUuid - 
*/
export const authx_auth_jwt_verify_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/jwt/verify/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_jwt_verify_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/jwt/verify/'
}
export const authx_auth_jwt_verify_create_TYPE = function() {
  return 'post'
}
export const authx_auth_jwt_verify_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/jwt/verify/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_me_read
 * url: authx_auth_me_readURL
 * method: authx_auth_me_read_TYPE
 * raw_url: authx_auth_me_read_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_me_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/me/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const authx_auth_me_read_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/me/'
}
export const authx_auth_me_read_TYPE = function() {
  return 'get'
}
export const authx_auth_me_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/me/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_me_update
 * url: authx_auth_me_updateURL
 * method: authx_auth_me_update_TYPE
 * raw_url: authx_auth_me_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_me_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/me/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const authx_auth_me_update_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/me/'
}
export const authx_auth_me_update_TYPE = function() {
  return 'put'
}
export const authx_auth_me_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/me/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_me_partial_update
 * url: authx_auth_me_partial_updateURL
 * method: authx_auth_me_partial_update_TYPE
 * raw_url: authx_auth_me_partial_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_me_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/me/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const authx_auth_me_partial_update_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/me/'
}
export const authx_auth_me_partial_update_TYPE = function() {
  return 'patch'
}
export const authx_auth_me_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/me/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_set_password
 * url: authx_auth_set_passwordURL
 * method: authx_auth_set_password_TYPE
 * raw_url: authx_auth_set_password_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_set_password = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/password/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_set_password_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/password/'
}
export const authx_auth_set_password_TYPE = function() {
  return 'post'
}
export const authx_auth_set_passwordURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/password/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_password_reset_reset_password_confirm
 * url: authx_auth_password_reset_reset_password_confirmURL
 * method: authx_auth_password_reset_reset_password_confirm_TYPE
 * raw_url: authx_auth_password_reset_reset_password_confirm_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_password_reset_reset_password_confirm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/password/reset/confirm/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_password_reset_reset_password_confirm_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/password/reset/confirm/'
}
export const authx_auth_password_reset_reset_password_confirm_TYPE = function() {
  return 'post'
}
export const authx_auth_password_reset_reset_password_confirmURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/password/reset/confirm/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_password_reset_password
 * url: authx_auth_password_reset_passwordURL
 * method: authx_auth_password_reset_password_TYPE
 * raw_url: authx_auth_password_reset_password_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param site - 
 */
export const authx_auth_password_reset_password = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/password/reset/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_password_reset_password_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/password/reset/{site}/'
}
export const authx_auth_password_reset_password_TYPE = function() {
  return 'post'
}
export const authx_auth_password_reset_passwordURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/password/reset/{site}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Genera la url adecuada de la red social y redirecciona a ésta, que
a su vez redireccionará de nuevo aquí, la segunda vez se llamará a
self.create() que creará los tokens y redireccionará al frontend.
* request: authx_auth_social_to_read
* url: authx_auth_social_to_readURL
* method: authx_auth_social_to_read_TYPE
* raw_url: authx_auth_social_to_read_RAW_URL
     * @param frontend - 
     * @param provider - 
     * @param role - 
     * @param roleUuid - 
*/
export const authx_auth_social_to_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/social/{provider}/to/{frontend}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  if (parameters['frontend'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: frontend'))
  }
  path = path.replace('{provider}', `${parameters['provider']}`)
  if (parameters['provider'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provider'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const authx_auth_social_to_read_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/social/{provider}/to/{frontend}/'
}
export const authx_auth_social_to_read_TYPE = function() {
  return 'get'
}
export const authx_auth_social_to_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/social/{provider}/to/{frontend}/'
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  path = path.replace('{provider}', `${parameters['provider']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Wrap del sistema de autenticación de djoser. El backend realiza
la autenticación y una vez hecha redirecciona al frontend con los tokens
`access` y `refresh` como query en la url.
* request: authx_auth_social_to_create
* url: authx_auth_social_to_createURL
* method: authx_auth_social_to_create_TYPE
* raw_url: authx_auth_social_to_create_RAW_URL
     * @param data - 
     * @param frontend - 
     * @param provider - 
     * @param role - 
     * @param roleUuid - 
*/
export const authx_auth_social_to_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/social/{provider}/to/{frontend}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  if (parameters['frontend'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: frontend'))
  }
  path = path.replace('{provider}', `${parameters['provider']}`)
  if (parameters['provider'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provider'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_social_to_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/social/{provider}/to/{frontend}/'
}
export const authx_auth_social_to_create_TYPE = function() {
  return 'post'
}
export const authx_auth_social_to_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/social/{provider}/to/{frontend}/'
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  path = path.replace('{provider}', `${parameters['provider']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_someone_create
 * url: authx_auth_someone_createURL
 * method: authx_auth_someone_create_TYPE
 * raw_url: authx_auth_someone_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_someone_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/someone/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_someone_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/someone/'
}
export const authx_auth_someone_create_TYPE = function() {
  return 'post'
}
export const authx_auth_someone_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/someone/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_users_activation
 * url: authx_auth_users_activationURL
 * method: authx_auth_users_activation_TYPE
 * raw_url: authx_auth_users_activation_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param site - 
 */
export const authx_auth_users_activation = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/users/confirm/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_users_activation_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/users/confirm/{site}/'
}
export const authx_auth_users_activation_TYPE = function() {
  return 'post'
}
export const authx_auth_users_activationURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/users/confirm/{site}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_users_create_create
 * url: authx_auth_users_create_createURL
 * method: authx_auth_users_create_create_TYPE
 * raw_url: authx_auth_users_create_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param site - 
 */
export const authx_auth_users_create_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/users/create/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_users_create_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/users/create/{site}/'
}
export const authx_auth_users_create_create_TYPE = function() {
  return 'post'
}
export const authx_auth_users_create_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/users/create/{site}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_auth_users_cross_create
 * url: authx_auth_users_cross_createURL
 * method: authx_auth_users_cross_create_TYPE
 * raw_url: authx_auth_users_cross_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const authx_auth_users_cross_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/auth/users/cross/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_auth_users_cross_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/auth/users/cross/'
}
export const authx_auth_users_cross_create_TYPE = function() {
  return 'post'
}
export const authx_auth_users_cross_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/auth/users/cross/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: authx_logout_create
 * url: authx_logout_createURL
 * method: authx_logout_create_TYPE
 * raw_url: authx_logout_create_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const authx_logout_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/authx/{role}/{role_uuid}/logout/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const authx_logout_create_RAW_URL = function() {
  return '/authx/{role}/{role_uuid}/logout/'
}
export const authx_logout_create_TYPE = function() {
  return 'post'
}
export const authx_logout_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/authx/{role}/{role_uuid}/logout/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: blog_posts_list
 * url: blog_posts_listURL
 * method: blog_posts_list_TYPE
 * raw_url: blog_posts_list_RAW_URL
 * @param title - 
 * @param topic - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const blog_posts_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/blog/{role}/{role_uuid}/posts/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['topic'] !== undefined) {
    queryParameters['topic'] = parameters['topic']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const blog_posts_list_RAW_URL = function() {
  return '/blog/{role}/{role_uuid}/posts/'
}
export const blog_posts_list_TYPE = function() {
  return 'get'
}
export const blog_posts_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/blog/{role}/{role_uuid}/posts/'
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['topic'] !== undefined) {
    queryParameters['topic'] = parameters['topic']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: blog_posts_read
 * url: blog_posts_readURL
 * method: blog_posts_read_TYPE
 * raw_url: blog_posts_read_RAW_URL
 * @param postId - 
 * @param role - 
 * @param roleUuid - 
 */
export const blog_posts_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/blog/{role}/{role_uuid}/posts/{post_id}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{post_id}', `${parameters['postId']}`)
  if (parameters['postId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postId'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const blog_posts_read_RAW_URL = function() {
  return '/blog/{role}/{role_uuid}/posts/{post_id}/'
}
export const blog_posts_read_TYPE = function() {
  return 'get'
}
export const blog_posts_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/blog/{role}/{role_uuid}/posts/{post_id}/'
  path = path.replace('{post_id}', `${parameters['postId']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: blog_topics_list
 * url: blog_topics_listURL
 * method: blog_topics_list_TYPE
 * raw_url: blog_topics_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const blog_topics_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/blog/{role}/{role_uuid}/topics/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const blog_topics_list_RAW_URL = function() {
  return '/blog/{role}/{role_uuid}/topics/'
}
export const blog_topics_list_TYPE = function() {
  return 'get'
}
export const blog_topics_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/blog/{role}/{role_uuid}/topics/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: contact_site_create
 * url: contact_site_createURL
 * method: contact_site_create_TYPE
 * raw_url: contact_site_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param site - 
 */
export const contact_site_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/contact/{role}/{role_uuid}/site/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const contact_site_create_RAW_URL = function() {
  return '/contact/{role}/{role_uuid}/site/{site}/'
}
export const contact_site_create_TYPE = function() {
  return 'post'
}
export const contact_site_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/contact/{role}/{role_uuid}/site/{site}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Muestra todos los carriers de los que el usuario tiene permiso para ver
 * request: courier_organization_carrier_list
 * url: courier_organization_carrier_listURL
 * method: courier_organization_carrier_list_TYPE
 * raw_url: courier_organization_carrier_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const courier_organization_carrier_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const courier_organization_carrier_list_RAW_URL = function() {
  return '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/'
}
export const courier_organization_carrier_list_TYPE = function() {
  return 'get'
}
export const courier_organization_carrier_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Muestra todas las opciones del carrier enviando
 * request: courier_organization_carrier_all_quote_create
 * url: courier_organization_carrier_all_quote_createURL
 * method: courier_organization_carrier_all_quote_create_TYPE
 * raw_url: courier_organization_carrier_all_quote_create_RAW_URL
 * @param data - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const courier_organization_carrier_all_quote_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/all/quote/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const courier_organization_carrier_all_quote_create_RAW_URL = function() {
  return '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/all/quote/'
}
export const courier_organization_carrier_all_quote_create_TYPE = function() {
  return 'post'
}
export const courier_organization_carrier_all_quote_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/all/quote/'
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crea un pickup
 * request: courier_organization_carrier_pickup_create
 * url: courier_organization_carrier_pickup_createURL
 * method: courier_organization_carrier_pickup_create_TYPE
 * raw_url: courier_organization_carrier_pickup_create_RAW_URL
 * @param data - 
 * @param carrierUuid - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const courier_organization_carrier_pickup_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/pickup/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  if (parameters['carrierUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: carrierUuid'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const courier_organization_carrier_pickup_create_RAW_URL = function() {
  return '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/pickup/'
}
export const courier_organization_carrier_pickup_create_TYPE = function() {
  return 'post'
}
export const courier_organization_carrier_pickup_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/pickup/'
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Muestra todas las opciones del carrier enviando
 * request: courier_organization_carrier_quote_create
 * url: courier_organization_carrier_quote_createURL
 * method: courier_organization_carrier_quote_create_TYPE
 * raw_url: courier_organization_carrier_quote_create_RAW_URL
 * @param data - 
 * @param carrierUuid - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const courier_organization_carrier_quote_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/quote/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  if (parameters['carrierUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: carrierUuid'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const courier_organization_carrier_quote_create_RAW_URL = function() {
  return '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/quote/'
}
export const courier_organization_carrier_quote_create_TYPE = function() {
  return 'post'
}
export const courier_organization_carrier_quote_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/quote/'
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crea un envío
 * request: courier_organization_carrier_shipment_create
 * url: courier_organization_carrier_shipment_createURL
 * method: courier_organization_carrier_shipment_create_TYPE
 * raw_url: courier_organization_carrier_shipment_create_RAW_URL
 * @param data - 
 * @param carrierUuid - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const courier_organization_carrier_shipment_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/shipment/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  if (parameters['carrierUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: carrierUuid'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const courier_organization_carrier_shipment_create_RAW_URL = function() {
  return '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/shipment/'
}
export const courier_organization_carrier_shipment_create_TYPE = function() {
  return 'post'
}
export const courier_organization_carrier_shipment_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/shipment/'
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Tracking
 * request: courier_organization_carrier_tracking_create
 * url: courier_organization_carrier_tracking_createURL
 * method: courier_organization_carrier_tracking_create_TYPE
 * raw_url: courier_organization_carrier_tracking_create_RAW_URL
 * @param data - 
 * @param carrierUuid - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const courier_organization_carrier_tracking_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/tracking/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  if (parameters['carrierUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: carrierUuid'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const courier_organization_carrier_tracking_create_RAW_URL = function() {
  return '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/tracking/'
}
export const courier_organization_carrier_tracking_create_TYPE = function() {
  return 'post'
}
export const courier_organization_carrier_tracking_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/courier/{role}/{role_uuid}/organization/{organization_uuid}/carrier/{carrier_uuid}/tracking/'
  path = path.replace('{carrier_uuid}', `${parameters['carrierUuid']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Shopping carts not confirmed to orders.
 * request: drugtransaction_carts_list
 * url: drugtransaction_carts_listURL
 * method: drugtransaction_carts_list_TYPE
 * raw_url: drugtransaction_carts_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/'
}
export const drugtransaction_carts_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Shopping carts not confirmed to orders.
 * request: drugtransaction_carts_create
 * url: drugtransaction_carts_createURL
 * method: drugtransaction_carts_create_TYPE
 * raw_url: drugtransaction_carts_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/'
}
export const drugtransaction_carts_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Active shopping cart detail
 * request: drugtransaction_carts_active_read
 * url: drugtransaction_carts_active_readURL
 * method: drugtransaction_carts_active_read_TYPE
 * raw_url: drugtransaction_carts_active_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/'
}
export const drugtransaction_carts_active_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_active_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Cart, document creation
 * request: drugtransaction_carts_active_documentation_document_create
 * url: drugtransaction_carts_active_documentation_document_createURL
 * method: drugtransaction_carts_active_documentation_document_create_TYPE
 * raw_url: drugtransaction_carts_active_documentation_document_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_documentation_document_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/documentation/document/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_documentation_document_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/documentation/document/'
}
export const drugtransaction_carts_active_documentation_document_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_active_documentation_document_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/documentation/document/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Cart, document delete
 * request: drugtransaction_carts_active_documentation_document_delete
 * url: drugtransaction_carts_active_documentation_document_deleteURL
 * method: drugtransaction_carts_active_documentation_document_delete_TYPE
 * raw_url: drugtransaction_carts_active_documentation_document_delete_RAW_URL
 * @param documentUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_documentation_document_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/documentation/document/{document_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{document_uuid}', `${parameters['documentUuid']}`)
  if (parameters['documentUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: documentUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_documentation_document_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/documentation/document/{document_uuid}/'
}
export const drugtransaction_carts_active_documentation_document_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_carts_active_documentation_document_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/documentation/document/{document_uuid}/'
  path = path.replace('{document_uuid}', `${parameters['documentUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside the active shopping cart
 * request: drugtransaction_carts_active_items_list
 * url: drugtransaction_carts_active_items_listURL
 * method: drugtransaction_carts_active_items_list_TYPE
 * raw_url: drugtransaction_carts_active_items_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/'
}
export const drugtransaction_carts_active_items_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_active_items_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside the active shopping cart
 * request: drugtransaction_carts_active_items_create
 * url: drugtransaction_carts_active_items_createURL
 * method: drugtransaction_carts_active_items_create_TYPE
 * raw_url: drugtransaction_carts_active_items_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/'
}
export const drugtransaction_carts_active_items_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_active_items_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create many items in the active shopping cart
 * request: drugtransaction_carts_active_items_many_create
 * url: drugtransaction_carts_active_items_many_createURL
 * method: drugtransaction_carts_active_items_many_create_TYPE
 * raw_url: drugtransaction_carts_active_items_many_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_many_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/many/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_many_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/many/'
}
export const drugtransaction_carts_active_items_many_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_active_items_many_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/many/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_active_items_read
 * url: drugtransaction_carts_active_items_readURL
 * method: drugtransaction_carts_active_items_read_TYPE
 * raw_url: drugtransaction_carts_active_items_read_RAW_URL
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
}
export const drugtransaction_carts_active_items_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_active_items_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_active_items_update
 * url: drugtransaction_carts_active_items_updateURL
 * method: drugtransaction_carts_active_items_update_TYPE
 * raw_url: drugtransaction_carts_active_items_update_RAW_URL
 * @param data - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
}
export const drugtransaction_carts_active_items_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_carts_active_items_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_active_items_partial_update
 * url: drugtransaction_carts_active_items_partial_updateURL
 * method: drugtransaction_carts_active_items_partial_update_TYPE
 * raw_url: drugtransaction_carts_active_items_partial_update_RAW_URL
 * @param data - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
}
export const drugtransaction_carts_active_items_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_carts_active_items_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_active_items_delete
 * url: drugtransaction_carts_active_items_deleteURL
 * method: drugtransaction_carts_active_items_delete_TYPE
 * raw_url: drugtransaction_carts_active_items_delete_RAW_URL
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_active_items_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_active_items_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
}
export const drugtransaction_carts_active_items_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_carts_active_items_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/active/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Active shopping cart detail
 * request: drugtransaction_carts_food_read
 * url: drugtransaction_carts_food_readURL
 * method: drugtransaction_carts_food_read_TYPE
 * raw_url: drugtransaction_carts_food_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/'
}
export const drugtransaction_carts_food_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_food_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside the active shopping cart
 * request: drugtransaction_carts_food_items_list
 * url: drugtransaction_carts_food_items_listURL
 * method: drugtransaction_carts_food_items_list_TYPE
 * raw_url: drugtransaction_carts_food_items_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/'
}
export const drugtransaction_carts_food_items_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_food_items_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside the active shopping cart
 * request: drugtransaction_carts_food_items_create
 * url: drugtransaction_carts_food_items_createURL
 * method: drugtransaction_carts_food_items_create_TYPE
 * raw_url: drugtransaction_carts_food_items_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/'
}
export const drugtransaction_carts_food_items_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_food_items_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create many items in the active shopping cart
 * request: drugtransaction_carts_food_items_many_create
 * url: drugtransaction_carts_food_items_many_createURL
 * method: drugtransaction_carts_food_items_many_create_TYPE
 * raw_url: drugtransaction_carts_food_items_many_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_many_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/many/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_many_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/many/'
}
export const drugtransaction_carts_food_items_many_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_food_items_many_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/many/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_food_items_read
 * url: drugtransaction_carts_food_items_readURL
 * method: drugtransaction_carts_food_items_read_TYPE
 * raw_url: drugtransaction_carts_food_items_read_RAW_URL
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
}
export const drugtransaction_carts_food_items_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_food_items_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_food_items_update
 * url: drugtransaction_carts_food_items_updateURL
 * method: drugtransaction_carts_food_items_update_TYPE
 * raw_url: drugtransaction_carts_food_items_update_RAW_URL
 * @param data - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
}
export const drugtransaction_carts_food_items_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_carts_food_items_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_food_items_partial_update
 * url: drugtransaction_carts_food_items_partial_updateURL
 * method: drugtransaction_carts_food_items_partial_update_TYPE
 * raw_url: drugtransaction_carts_food_items_partial_update_RAW_URL
 * @param data - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
}
export const drugtransaction_carts_food_items_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_carts_food_items_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: drugtransaction_carts_food_items_delete
 * url: drugtransaction_carts_food_items_deleteURL
 * method: drugtransaction_carts_food_items_delete_TYPE
 * raw_url: drugtransaction_carts_food_items_delete_RAW_URL
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_food_items_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_food_items_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
}
export const drugtransaction_carts_food_items_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_carts_food_items_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/food/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Shopping cart detail
 * request: drugtransaction_carts_read
 * url: drugtransaction_carts_readURL
 * method: drugtransaction_carts_read_TYPE
 * raw_url: drugtransaction_carts_read_RAW_URL
 * @param cartUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/'
}
export const drugtransaction_carts_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/'
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside a shopping cart
 * request: drugtransaction_carts_items_list
 * url: drugtransaction_carts_items_listURL
 * method: drugtransaction_carts_items_list_TYPE
 * raw_url: drugtransaction_carts_items_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param cartUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_items_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_items_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/'
}
export const drugtransaction_carts_items_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_items_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside a shopping cart
 * request: drugtransaction_carts_items_create
 * url: drugtransaction_carts_items_createURL
 * method: drugtransaction_carts_items_create_TYPE
 * raw_url: drugtransaction_carts_items_create_RAW_URL
 * @param data - 
 * @param cartUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_items_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_items_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/'
}
export const drugtransaction_carts_items_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_carts_items_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/'
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in a shopping cart
 * request: drugtransaction_carts_items_read
 * url: drugtransaction_carts_items_readURL
 * method: drugtransaction_carts_items_read_TYPE
 * raw_url: drugtransaction_carts_items_read_RAW_URL
 * @param cartUuid - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_items_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_items_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
}
export const drugtransaction_carts_items_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_carts_items_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in a shopping cart
 * request: drugtransaction_carts_items_update
 * url: drugtransaction_carts_items_updateURL
 * method: drugtransaction_carts_items_update_TYPE
 * raw_url: drugtransaction_carts_items_update_RAW_URL
 * @param data - 
 * @param cartUuid - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_items_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_items_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
}
export const drugtransaction_carts_items_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_carts_items_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in a shopping cart
 * request: drugtransaction_carts_items_partial_update
 * url: drugtransaction_carts_items_partial_updateURL
 * method: drugtransaction_carts_items_partial_update_TYPE
 * raw_url: drugtransaction_carts_items_partial_update_RAW_URL
 * @param data - 
 * @param cartUuid - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_items_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_items_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
}
export const drugtransaction_carts_items_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_carts_items_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in a shopping cart
 * request: drugtransaction_carts_items_delete
 * url: drugtransaction_carts_items_deleteURL
 * method: drugtransaction_carts_items_delete_TYPE
 * raw_url: drugtransaction_carts_items_delete_RAW_URL
 * @param cartUuid - 
 * @param itemUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_carts_items_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  if (parameters['cartUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cartUuid'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_carts_items_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
}
export const drugtransaction_carts_items_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_carts_items_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/carts/{cart_uuid}/items/{item_uuid}/'
  path = path.replace('{cart_uuid}', `${parameters['cartUuid']}`)
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_active_read
 * url: drugtransaction_checkout_active_readURL
 * method: drugtransaction_checkout_active_read_TYPE
 * raw_url: drugtransaction_checkout_active_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_active_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/active/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_active_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/active/'
}
export const drugtransaction_checkout_active_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_checkout_active_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/active/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_active_update
 * url: drugtransaction_checkout_active_updateURL
 * method: drugtransaction_checkout_active_update_TYPE
 * raw_url: drugtransaction_checkout_active_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_active_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/active/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_active_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/active/'
}
export const drugtransaction_checkout_active_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_checkout_active_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/active/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_active_partial_update
 * url: drugtransaction_checkout_active_partial_updateURL
 * method: drugtransaction_checkout_active_partial_update_TYPE
 * raw_url: drugtransaction_checkout_active_partial_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_active_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/active/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_active_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/active/'
}
export const drugtransaction_checkout_active_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_checkout_active_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/active/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_activeg_read
 * url: drugtransaction_checkout_activeg_readURL
 * method: drugtransaction_checkout_activeg_read_TYPE
 * raw_url: drugtransaction_checkout_activeg_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_activeg_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_activeg_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
}
export const drugtransaction_checkout_activeg_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_checkout_activeg_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_activeg_update
 * url: drugtransaction_checkout_activeg_updateURL
 * method: drugtransaction_checkout_activeg_update_TYPE
 * raw_url: drugtransaction_checkout_activeg_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_activeg_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_activeg_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
}
export const drugtransaction_checkout_activeg_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_checkout_activeg_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_activeg_partial_update
 * url: drugtransaction_checkout_activeg_partial_updateURL
 * method: drugtransaction_checkout_activeg_partial_update_TYPE
 * raw_url: drugtransaction_checkout_activeg_partial_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_activeg_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_activeg_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
}
export const drugtransaction_checkout_activeg_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_checkout_activeg_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/activeg/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_read
 * url: drugtransaction_checkout_readURL
 * method: drugtransaction_checkout_read_TYPE
 * raw_url: drugtransaction_checkout_read_RAW_URL
 * @param checkoutUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
}
export const drugtransaction_checkout_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_checkout_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_update
 * url: drugtransaction_checkout_updateURL
 * method: drugtransaction_checkout_update_TYPE
 * raw_url: drugtransaction_checkout_update_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
}
export const drugtransaction_checkout_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_checkout_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: drugtransaction_checkout_partial_update
 * url: drugtransaction_checkout_partial_updateURL
 * method: drugtransaction_checkout_partial_update_TYPE
 * raw_url: drugtransaction_checkout_partial_update_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
}
export const drugtransaction_checkout_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_checkout_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * terminar un checkout por farmacia
 * request: drugtransaction_checkout_doneinsuranceriskpayment_create
 * url: drugtransaction_checkout_doneinsuranceriskpayment_createURL
 * method: drugtransaction_checkout_doneinsuranceriskpayment_create_TYPE
 * raw_url: drugtransaction_checkout_doneinsuranceriskpayment_create_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_doneinsuranceriskpayment_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/doneinsuranceriskpayment/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_doneinsuranceriskpayment_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/doneinsuranceriskpayment/'
}
export const drugtransaction_checkout_doneinsuranceriskpayment_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_checkout_doneinsuranceriskpayment_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/doneinsuranceriskpayment/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * terminar un checkout por farmacia
 * request: drugtransaction_checkout_to_retail_order_create
 * url: drugtransaction_checkout_to_retail_order_createURL
 * method: drugtransaction_checkout_to_retail_order_create_TYPE
 * raw_url: drugtransaction_checkout_to_retail_order_create_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_checkout_to_retail_order_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/to_retail_order/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_checkout_to_retail_order_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/to_retail_order/'
}
export const drugtransaction_checkout_to_retail_order_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_checkout_to_retail_order_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/checkout/{checkout_uuid}/to_retail_order/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, read and update
 * request: drugtransaction_importation_cart_read
 * url: drugtransaction_importation_cart_readURL
 * method: drugtransaction_importation_cart_read_TYPE
 * raw_url: drugtransaction_importation_cart_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/'
}
export const drugtransaction_importation_cart_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_importation_cart_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, read and update
 * request: drugtransaction_importation_cart_update
 * url: drugtransaction_importation_cart_updateURL
 * method: drugtransaction_importation_cart_update_TYPE
 * raw_url: drugtransaction_importation_cart_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/'
}
export const drugtransaction_importation_cart_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_importation_cart_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, read and update
 * request: drugtransaction_importation_cart_partial_update
 * url: drugtransaction_importation_cart_partial_updateURL
 * method: drugtransaction_importation_cart_partial_update_TYPE
 * raw_url: drugtransaction_importation_cart_partial_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/'
}
export const drugtransaction_importation_cart_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_importation_cart_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, document creation
 * request: drugtransaction_importation_cart_documentation_document_create
 * url: drugtransaction_importation_cart_documentation_document_createURL
 * method: drugtransaction_importation_cart_documentation_document_create_TYPE
 * raw_url: drugtransaction_importation_cart_documentation_document_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_documentation_document_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/documentation/document/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_documentation_document_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/documentation/document/'
}
export const drugtransaction_importation_cart_documentation_document_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_importation_cart_documentation_document_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/documentation/document/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, document delete
 * request: drugtransaction_importation_cart_documentation_document_delete
 * url: drugtransaction_importation_cart_documentation_document_deleteURL
 * method: drugtransaction_importation_cart_documentation_document_delete_TYPE
 * raw_url: drugtransaction_importation_cart_documentation_document_delete_RAW_URL
 * @param documentUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_documentation_document_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/documentation/document/{document_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{document_uuid}', `${parameters['documentUuid']}`)
  if (parameters['documentUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: documentUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_documentation_document_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/documentation/document/{document_uuid}/'
}
export const drugtransaction_importation_cart_documentation_document_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_importation_cart_documentation_document_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/documentation/document/{document_uuid}/'
  path = path.replace('{document_uuid}', `${parameters['documentUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crea datos de importación como nombre, dirección, etc
 * request: drugtransaction_importation_cart_importation_create
 * url: drugtransaction_importation_cart_importation_createURL
 * method: drugtransaction_importation_cart_importation_create_TYPE
 * raw_url: drugtransaction_importation_cart_importation_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_importation_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_importation_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/'
}
export const drugtransaction_importation_cart_importation_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_importation_cart_importation_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crea datos de importación como nombre, dirección, etc
 * request: drugtransaction_importation_cart_importation_update
 * url: drugtransaction_importation_cart_importation_updateURL
 * method: drugtransaction_importation_cart_importation_update_TYPE
 * raw_url: drugtransaction_importation_cart_importation_update_RAW_URL
 * @param data - 
 * @param importationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_importation_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/{importation_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{importation_uuid}', `${parameters['importationUuid']}`)
  if (parameters['importationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: importationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_importation_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/{importation_uuid}/'
}
export const drugtransaction_importation_cart_importation_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_importation_cart_importation_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/{importation_uuid}/'
  path = path.replace('{importation_uuid}', `${parameters['importationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crea datos de importación como nombre, dirección, etc
 * request: drugtransaction_importation_cart_importation_partial_update
 * url: drugtransaction_importation_cart_importation_partial_updateURL
 * method: drugtransaction_importation_cart_importation_partial_update_TYPE
 * raw_url: drugtransaction_importation_cart_importation_partial_update_RAW_URL
 * @param data - 
 * @param importationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_importation_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/{importation_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{importation_uuid}', `${parameters['importationUuid']}`)
  if (parameters['importationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: importationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_importation_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/{importation_uuid}/'
}
export const drugtransaction_importation_cart_importation_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_importation_cart_importation_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/importation/{importation_uuid}/'
  path = path.replace('{importation_uuid}', `${parameters['importationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, drug creation
 * request: drugtransaction_importation_cart_item_list_drug_create
 * url: drugtransaction_importation_cart_item_list_drug_createURL
 * method: drugtransaction_importation_cart_item_list_drug_create_TYPE
 * raw_url: drugtransaction_importation_cart_item_list_drug_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_item_list_drug_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_item_list_drug_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/'
}
export const drugtransaction_importation_cart_item_list_drug_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_importation_cart_item_list_drug_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, drug deletion
 * request: drugtransaction_importation_cart_item_list_drug_read
 * url: drugtransaction_importation_cart_item_list_drug_readURL
 * method: drugtransaction_importation_cart_item_list_drug_read_TYPE
 * raw_url: drugtransaction_importation_cart_item_list_drug_read_RAW_URL
 * @param drugsnapshotUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_item_list_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  if (parameters['drugsnapshotUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: drugsnapshotUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_item_list_drug_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
}
export const drugtransaction_importation_cart_item_list_drug_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_importation_cart_item_list_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, drug deletion
 * request: drugtransaction_importation_cart_item_list_drug_update
 * url: drugtransaction_importation_cart_item_list_drug_updateURL
 * method: drugtransaction_importation_cart_item_list_drug_update_TYPE
 * raw_url: drugtransaction_importation_cart_item_list_drug_update_RAW_URL
 * @param data - 
 * @param drugsnapshotUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_item_list_drug_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  if (parameters['drugsnapshotUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: drugsnapshotUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_item_list_drug_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
}
export const drugtransaction_importation_cart_item_list_drug_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_importation_cart_item_list_drug_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, drug deletion
 * request: drugtransaction_importation_cart_item_list_drug_partial_update
 * url: drugtransaction_importation_cart_item_list_drug_partial_updateURL
 * method: drugtransaction_importation_cart_item_list_drug_partial_update_TYPE
 * raw_url: drugtransaction_importation_cart_item_list_drug_partial_update_RAW_URL
 * @param data - 
 * @param drugsnapshotUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_item_list_drug_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  if (parameters['drugsnapshotUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: drugsnapshotUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_item_list_drug_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
}
export const drugtransaction_importation_cart_item_list_drug_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_importation_cart_item_list_drug_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Importation cart, drug deletion
 * request: drugtransaction_importation_cart_item_list_drug_delete
 * url: drugtransaction_importation_cart_item_list_drug_deleteURL
 * method: drugtransaction_importation_cart_item_list_drug_delete_TYPE
 * raw_url: drugtransaction_importation_cart_item_list_drug_delete_RAW_URL
 * @param drugsnapshotUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_importation_cart_item_list_drug_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  if (parameters['drugsnapshotUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: drugsnapshotUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_importation_cart_item_list_drug_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
}
export const drugtransaction_importation_cart_item_list_drug_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_importation_cart_item_list_drug_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/importation/cart/item_list/drug/{drugsnapshot_uuid}/'
  path = path.replace('{drugsnapshot_uuid}', `${parameters['drugsnapshotUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_insurance_insurancerefund_list
 * url: drugtransaction_insurance_insurancerefund_listURL
 * method: drugtransaction_insurance_insurancerefund_list_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/'
}
export const drugtransaction_insurance_insurancerefund_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear reembolso
 * request: drugtransaction_insurance_insurancerefund_create
 * url: drugtransaction_insurance_insurancerefund_createURL
 * method: drugtransaction_insurance_insurancerefund_create_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/'
}
export const drugtransaction_insurance_insurancerefund_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_insurance_insurancerefund_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Reembolsos en borrador asociados a la
aseguradora (i_uuid) y filtradas por los pacientes asociados al usuario
actual.
* request: drugtransaction_insurance_insurancerefund_draft_list
* url: drugtransaction_insurance_insurancerefund_draft_listURL
* method: drugtransaction_insurance_insurancerefund_draft_list_TYPE
* raw_url: drugtransaction_insurance_insurancerefund_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_insurance_insurancerefund_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/draft/'
}
export const drugtransaction_insurance_insurancerefund_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso borrador indicando insurancerefund_uuid
 * request: drugtransaction_insurance_insurancerefund_draft_read
 * url: drugtransaction_insurance_insurancerefund_draft_readURL
 * method: drugtransaction_insurance_insurancerefund_draft_read_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_draft_read_RAW_URL
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_draft_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/draft/{insurancerefund_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_draft_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/draft/{insurancerefund_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_draft_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_draft_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/draft/{insurancerefund_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso indicando insurancerefund_uuid
 * request: drugtransaction_insurance_insurancerefund_read
 * url: drugtransaction_insurance_insurancerefund_readURL
 * method: drugtransaction_insurance_insurancerefund_read_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_read_RAW_URL
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_update
 * url: drugtransaction_insurance_insurancerefund_updateURL
 * method: drugtransaction_insurance_insurancerefund_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_insurance_insurancerefund_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_partial_update
 * url: drugtransaction_insurance_insurancerefund_partial_updateURL
 * method: drugtransaction_insurance_insurancerefund_partial_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_insurance_insurancerefund_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un reembolso indicando insurancerefund_uuid
 * request: drugtransaction_insurance_insurancerefund_delete
 * url: drugtransaction_insurance_insurancerefund_deleteURL
 * method: drugtransaction_insurance_insurancerefund_delete_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_delete_RAW_URL
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_insurance_insurancerefund_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de archivos asociados a un reembolso
 * request: drugtransaction_insurance_insurancerefund_file_list
 * url: drugtransaction_insurance_insurancerefund_file_listURL
 * method: drugtransaction_insurance_insurancerefund_file_list_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_file_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_file_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_file_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/'
}
export const drugtransaction_insurance_insurancerefund_file_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_file_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir archivo a un reembolso
 * request: drugtransaction_insurance_insurancerefund_file_create
 * url: drugtransaction_insurance_insurancerefund_file_createURL
 * method: drugtransaction_insurance_insurancerefund_file_create_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_file_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_file_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_file_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/'
}
export const drugtransaction_insurance_insurancerefund_file_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_insurance_insurancerefund_file_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener archivo de reembolso indicando ref_uuid
 * request: drugtransaction_insurance_insurancerefund_file_read
 * url: drugtransaction_insurance_insurancerefund_file_readURL
 * method: drugtransaction_insurance_insurancerefund_file_read_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_file_read_RAW_URL
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_file_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_file_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_file_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_file_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_file_update
 * url: drugtransaction_insurance_insurancerefund_file_updateURL
 * method: drugtransaction_insurance_insurancerefund_file_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_file_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_file_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_file_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_file_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_insurance_insurancerefund_file_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_file_partial_update
 * url: drugtransaction_insurance_insurancerefund_file_partial_updateURL
 * method: drugtransaction_insurance_insurancerefund_file_partial_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_file_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_file_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_file_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_file_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_insurance_insurancerefund_file_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un archivo de reembolso indicando ref_uuid
 * request: drugtransaction_insurance_insurancerefund_file_delete
 * url: drugtransaction_insurance_insurancerefund_file_deleteURL
 * method: drugtransaction_insurance_insurancerefund_file_delete_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_file_delete_RAW_URL
 * @param iUuid - 
 * @param insurancerefundUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_file_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_file_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_file_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_insurance_insurancerefund_file_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{i_uuid}/insurancerefund/{insurancerefund_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar/Crear Facturas (Asociadas a un rembolso)
 * request: drugtransaction_insurance_insurancerefund_invoice_list
 * url: drugtransaction_insurance_insurancerefund_invoice_listURL
 * method: drugtransaction_insurance_insurancerefund_invoice_list_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/'
}
export const drugtransaction_insurance_insurancerefund_invoice_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_invoice_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear una factura sociada a un reembolso.
 * request: drugtransaction_insurance_insurancerefund_invoice_create
 * url: drugtransaction_insurance_insurancerefund_invoice_createURL
 * method: drugtransaction_insurance_insurancerefund_invoice_create_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/'
}
export const drugtransaction_insurance_insurancerefund_invoice_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_insurance_insurancerefund_invoice_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener factura de paciente a aseguradora asociada a un reembolso
 * request: drugtransaction_insurance_insurancerefund_invoice_read
 * url: drugtransaction_insurance_insurancerefund_invoice_readURL
 * method: drugtransaction_insurance_insurancerefund_invoice_read_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_read_RAW_URL
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_invoice_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_invoice_update
 * url: drugtransaction_insurance_insurancerefund_invoice_updateURL
 * method: drugtransaction_insurance_insurancerefund_invoice_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_update_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_insurance_insurancerefund_invoice_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_invoice_partial_update
 * url: drugtransaction_insurance_insurancerefund_invoice_partial_updateURL
 * method: drugtransaction_insurance_insurancerefund_invoice_partial_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_partial_update_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_insurance_insurancerefund_invoice_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar factura
 * request: drugtransaction_insurance_insurancerefund_invoice_delete
 * url: drugtransaction_insurance_insurancerefund_invoice_deleteURL
 * method: drugtransaction_insurance_insurancerefund_invoice_delete_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_delete_RAW_URL
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_insurance_insurancerefund_invoice_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir producto a una factura
 * request: drugtransaction_insurance_insurancerefund_invoice_drug_create
 * url: drugtransaction_insurance_insurancerefund_invoice_drug_createURL
 * method: drugtransaction_insurance_insurancerefund_invoice_drug_create_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_drug_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_drug_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_invoice_drug_read
 * url: drugtransaction_insurance_insurancerefund_invoice_drug_readURL
 * method: drugtransaction_insurance_insurancerefund_invoice_drug_read_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_drug_read_RAW_URL
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param snapdrugUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters['snapdrugUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: snapdrugUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_invoice_drug_update
 * url: drugtransaction_insurance_insurancerefund_invoice_drug_updateURL
 * method: drugtransaction_insurance_insurancerefund_invoice_drug_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_drug_update_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param snapdrugUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_drug_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters['snapdrugUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: snapdrugUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_invoice_drug_partial_update
 * url: drugtransaction_insurance_insurancerefund_invoice_drug_partial_updateURL
 * method: drugtransaction_insurance_insurancerefund_invoice_drug_partial_update_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_drug_partial_update_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param snapdrugUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_drug_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters['snapdrugUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: snapdrugUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_insurance_insurancerefund_invoice_drug_delete
 * url: drugtransaction_insurance_insurancerefund_invoice_drug_deleteURL
 * method: drugtransaction_insurance_insurancerefund_invoice_drug_delete_TYPE
 * raw_url: drugtransaction_insurance_insurancerefund_invoice_drug_delete_RAW_URL
 * @param insuranceUuid - 
 * @param insurancerefundUuid - 
 * @param invoiceUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param snapdrugUuid - 
 */
export const drugtransaction_insurance_insurancerefund_invoice_drug_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  if (parameters['insurancerefundUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancerefundUuid'))
  }
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  if (parameters['invoiceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: invoiceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters['snapdrugUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: snapdrugUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_insurance_insurancerefund_invoice_drug_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurance/{insurance_uuid}/insurancerefund/{insurancerefund_uuid}/invoice/{invoice_uuid}/drug/{snapdrug_uuid}/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancerefund_uuid}', `${parameters['insurancerefundUuid']}`)
  path = path.replace('{invoice_uuid}', `${parameters['invoiceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{snapdrug_uuid}', `${parameters['snapdrugUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list
 * url: drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_listURL
 * method: drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list_TYPE
 * raw_url: drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insuranceauthorization/insurance/{i_uuid}/insuranceauthorizationconfig/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insuranceauthorization/insurance/{i_uuid}/insuranceauthorizationconfig/'
}
export const drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insuranceauthorization/insurance/{i_uuid}/insuranceauthorizationconfig/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list
 * url: drugtransaction_insurancebridge_insurance_insurancebridgeconfig_listURL
 * method: drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list_TYPE
 * raw_url: drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurancebridge/insurance/{i_uuid}/insurancebridgeconfig/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurancebridge/insurance/{i_uuid}/insurancebridgeconfig/'
}
export const drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurancebridge_insurance_insurancebridgeconfig_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurancebridge/insurance/{i_uuid}/insurancebridgeconfig/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual.
* request: drugtransaction_insurancerefund_list
* url: drugtransaction_insurancerefund_listURL
* method: drugtransaction_insurancerefund_list_TYPE
* raw_url: drugtransaction_insurancerefund_list_RAW_URL
     * @param ordering - Which field to use when ordering the results.
     * @param insuranceName - Filter by insurance name
     * @param insuredName - Filter by insured name
     * @param policyNum - Filter by policy num
     * @param requestStatus - Filter by request status. Also, -1 means object_status draft and -2 status done
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_insurancerefund_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurancerefund/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['requestStatus'] !== undefined) {
    queryParameters['request_status'] = parameters['requestStatus']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurancerefund_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurancerefund/'
}
export const drugtransaction_insurancerefund_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurancerefund_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurancerefund/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['requestStatus'] !== undefined) {
    queryParameters['request_status'] = parameters['requestStatus']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos en borrador asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual.
* request: drugtransaction_insurancerefund_draft_list
* url: drugtransaction_insurancerefund_draft_listURL
* method: drugtransaction_insurancerefund_draft_list_TYPE
* raw_url: drugtransaction_insurancerefund_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_insurancerefund_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurancerefund/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurancerefund_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurancerefund/draft/'
}
export const drugtransaction_insurancerefund_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurancerefund_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurancerefund/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_insurancerefund_insurance_insurancerefundconfig_list
 * url: drugtransaction_insurancerefund_insurance_insurancerefundconfig_listURL
 * method: drugtransaction_insurancerefund_insurance_insurancerefundconfig_list_TYPE
 * raw_url: drugtransaction_insurancerefund_insurance_insurancerefundconfig_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_insurancerefund_insurance_insurancerefundconfig_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/insurancerefund/insurance/{i_uuid}/insurancerefundconfig/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_insurancerefund_insurance_insurancerefundconfig_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/insurancerefund/insurance/{i_uuid}/insurancerefundconfig/'
}
export const drugtransaction_insurancerefund_insurance_insurancerefundconfig_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_insurancerefund_insurance_insurancerefundconfig_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/insurancerefund/insurance/{i_uuid}/insurancerefundconfig/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Devuelve una imágen QR en formato SVG con la url del pedido en PRO
 * request: drugtransaction_internalorder_prositeqrcode_list
 * url: drugtransaction_internalorder_prositeqrcode_listURL
 * method: drugtransaction_internalorder_prositeqrcode_list_TYPE
 * raw_url: drugtransaction_internalorder_prositeqrcode_list_RAW_URL
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_internalorder_prositeqrcode_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/internalorder/{order_uuid}/prositeqrcode/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_internalorder_prositeqrcode_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/internalorder/{order_uuid}/prositeqrcode/'
}
export const drugtransaction_internalorder_prositeqrcode_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_internalorder_prositeqrcode_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/internalorder/{order_uuid}/prositeqrcode/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listar productos, precio incluido, que un paciente puede ver de una
aseguradora.
* request: drugtransaction_nopatient_insurance_drugs_in_list
* url: drugtransaction_nopatient_insurance_drugs_in_listURL
* method: drugtransaction_nopatient_insurance_drugs_in_list_TYPE
* raw_url: drugtransaction_nopatient_insurance_drugs_in_list_RAW_URL
     * @param search - A search term in elasticsearch
     * @param letter - Starts with...
     * @param uuid - 
     * @param description - Description
     * @param substance - Substance
     * @param ean - 
     * @param category - Category uuid
     * @param groupedCategory - Category uuid
     * @param top - 
     * @param topHightSpeciality - 
     * @param topDerma - 
     * @param hightSpeciality - 
     * @param organization - Organization uuid
     * @param publicOrganization - Organization uuid with pricelistdestination public
     * @param updatedStartDate - 
     * @param updatedEndDate - 
     * @param brands - List of Brands uuid
     * @param topGeneric - 
     * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insurance_drugs_in_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/drugs/in/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_drugs_in_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/drugs/in/'
}
export const drugtransaction_nopatient_insurance_drugs_in_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_drugs_in_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/drugs/in/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_list
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_listURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear reembolso
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_create
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_createURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Reembolsos en borrador asociados a la
aseguradora (i_uuid) y filtradas por los pacientes asociados al usuario
actual.
* request: drugtransaction_nopatient_insurance_insuranceauthorization_draft_list
* url: drugtransaction_nopatient_insurance_insuranceauthorization_draft_listURL
* method: drugtransaction_nopatient_insurance_insuranceauthorization_draft_list_TYPE
* raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/draft/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso borrador indicando insuranceauthorization_uuid
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_draft_read
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_draft_readURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_draft_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_draft_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/draft/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/draft/{insuranceauthorization_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_draft_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/draft/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso indicando insuranceauthorization_uuid
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_read
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_readURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_partial_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un reembolso indicando insuranceauthorization_uuid
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_delete
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_deleteURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de archivos asociados a un reembolso
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_file_list
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_file_listURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_file_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_file_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir archivo a un reembolso
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_file_create
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_file_createURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_file_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_file_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener archivo de reembolso indicando ref_uuid
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_file_read
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_file_readURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_file_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_file_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_file_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_file_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_file_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_file_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un archivo de reembolso indicando ref_uuid
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_file_delete
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_file_deleteURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_file_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_file_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_file_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener receta de paciente a aseguradora asociada a un reembolso
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_read
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_readURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar receta
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_delete
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_deleteURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_read
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_readURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_deleteURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_nopatient_insurance_insurancebridge_list
 * url: drugtransaction_nopatient_insurance_insurancebridge_listURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear reembolso
 * request: drugtransaction_nopatient_insurance_insurancebridge_create
 * url: drugtransaction_nopatient_insurance_insurancebridge_createURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insurancebridge_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Reembolsos en borrador asociados a la
aseguradora (i_uuid) y filtradas por los pacientes asociados al usuario
actual.
* request: drugtransaction_nopatient_insurance_insurancebridge_draft_list
* url: drugtransaction_nopatient_insurance_insurancebridge_draft_listURL
* method: drugtransaction_nopatient_insurance_insurancebridge_draft_list_TYPE
* raw_url: drugtransaction_nopatient_insurance_insurancebridge_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insurance_insurancebridge_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/draft/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso borrador indicando insurancebridge_uuid
 * request: drugtransaction_nopatient_insurance_insurancebridge_draft_read
 * url: drugtransaction_nopatient_insurance_insurancebridge_draft_readURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_draft_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_draft_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_draft_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/draft/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_draft_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/draft/{insurancebridge_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_draft_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_draft_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/draft/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso indicando insurancebridge_uuid
 * request: drugtransaction_nopatient_insurance_insurancebridge_read
 * url: drugtransaction_nopatient_insurance_insurancebridge_readURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insurancebridge_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_partial_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insurancebridge_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un reembolso indicando insurancebridge_uuid
 * request: drugtransaction_nopatient_insurance_insurancebridge_delete
 * url: drugtransaction_nopatient_insurance_insurancebridge_deleteURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insurancebridge_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de archivos asociados a un reembolso
 * request: drugtransaction_nopatient_insurance_insurancebridge_file_list
 * url: drugtransaction_nopatient_insurance_insurancebridge_file_listURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_file_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_file_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_file_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir archivo a un reembolso
 * request: drugtransaction_nopatient_insurance_insurancebridge_file_create
 * url: drugtransaction_nopatient_insurance_insurancebridge_file_createURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_file_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_file_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_file_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener archivo de reembolso indicando ref_uuid
 * request: drugtransaction_nopatient_insurance_insurancebridge_file_read
 * url: drugtransaction_nopatient_insurance_insurancebridge_file_readURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_file_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_file_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_file_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_file_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_file_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_file_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_file_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_file_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_file_partial_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_file_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_file_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_file_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_file_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un archivo de reembolso indicando ref_uuid
 * request: drugtransaction_nopatient_insurance_insurancebridge_file_delete
 * url: drugtransaction_nopatient_insurance_insurancebridge_file_deleteURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_file_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_file_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_file_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insurancebridge_file_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener receta de paciente a aseguradora asociada a un reembolso
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_read
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_readURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar receta
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_delete
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_deleteURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_read
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_readURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_read_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_updateURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_deleteURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar/Crear Recetas (Asociadas a un rembolso)
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_listURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear una receta sociada a un reembolso.
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_createURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insuranceauthorizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar los productos que contiene una receta
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_list
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_listURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insuranceauthorizationUuid - 
 * @param recipeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{recipe_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  if (parameters['recipeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recipeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{recipe_uuid}/drug/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{recipe_uuid}/drug/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir producto a una receta
 * request: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create
 * url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_createURL
 * method: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insuranceauthorizationUuid - 
 * @param recipeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{recipe_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  if (parameters['recipeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recipeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{recipe_uuid}/drug/'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insuranceauthorization_recipe_drug_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{recipe_uuid}/drug/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar/Crear Recetas (Asociadas a un rembolso)
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_list
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_listURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear una receta sociada a un reembolso.
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_create
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_createURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancebridgeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar los productos que contiene una receta
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_list
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_listURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_list_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insurancebridgeUuid - 
 * @param recipeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{recipe_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  if (parameters['recipeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recipeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{recipe_uuid}/drug/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{recipe_uuid}/drug/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir producto a una receta
 * request: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create
 * url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_createURL
 * method: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create_TYPE
 * raw_url: drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancebridgeUuid - 
 * @param recipeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{recipe_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  if (parameters['recipeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recipeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{recipe_uuid}/drug/'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_nopatient_insurance_insurancebridge_recipe_drug_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{recipe_uuid}/drug/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual.
* request: drugtransaction_nopatient_insuranceauthorization_list
* url: drugtransaction_nopatient_insuranceauthorization_listURL
* method: drugtransaction_nopatient_insuranceauthorization_list_TYPE
* raw_url: drugtransaction_nopatient_insuranceauthorization_list_RAW_URL
     * @param ordering - Which field to use when ordering the results.
     * @param insuranceName - Filter by insurance name
     * @param insuredName - Filter by insured name
     * @param policyNum - Filter by policy num
     * @param requestStatus - Filter by request status. Also, -1 means object_status draft and -2 status done
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insuranceauthorization_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['requestStatus'] !== undefined) {
    queryParameters['request_status'] = parameters['requestStatus']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insuranceauthorization_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insuranceauthorization/'
}
export const drugtransaction_nopatient_insuranceauthorization_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insuranceauthorization_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insuranceauthorization/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['requestStatus'] !== undefined) {
    queryParameters['request_status'] = parameters['requestStatus']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos en borrador asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual.
* request: drugtransaction_nopatient_insuranceauthorization_draft_list
* url: drugtransaction_nopatient_insuranceauthorization_draft_listURL
* method: drugtransaction_nopatient_insuranceauthorization_draft_list_TYPE
* raw_url: drugtransaction_nopatient_insuranceauthorization_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insuranceauthorization_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insuranceauthorization/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insuranceauthorization_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insuranceauthorization/draft/'
}
export const drugtransaction_nopatient_insuranceauthorization_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insuranceauthorization_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insuranceauthorization/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual.
* request: drugtransaction_nopatient_insurancebridge_list
* url: drugtransaction_nopatient_insurancebridge_listURL
* method: drugtransaction_nopatient_insurancebridge_list_TYPE
* raw_url: drugtransaction_nopatient_insurancebridge_list_RAW_URL
     * @param ordering - Which field to use when ordering the results.
     * @param insuranceName - Filter by insurance name
     * @param insuredName - Filter by insured name
     * @param policyNum - Filter by policy num
     * @param requestStatus - Filter by request status. Also, -1 means object_status draft and -2 status done
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insurancebridge_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['requestStatus'] !== undefined) {
    queryParameters['request_status'] = parameters['requestStatus']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurancebridge_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurancebridge/'
}
export const drugtransaction_nopatient_insurancebridge_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurancebridge_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurancebridge/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['requestStatus'] !== undefined) {
    queryParameters['request_status'] = parameters['requestStatus']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos en borrador asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual.
* request: drugtransaction_nopatient_insurancebridge_draft_list
* url: drugtransaction_nopatient_insurancebridge_draft_listURL
* method: drugtransaction_nopatient_insurancebridge_draft_list_TYPE
* raw_url: drugtransaction_nopatient_insurancebridge_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_nopatient_insurancebridge_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurancebridge/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_nopatient_insurancebridge_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/nopatient/insurancebridge/draft/'
}
export const drugtransaction_nopatient_insurancebridge_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_nopatient_insurancebridge_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/nopatient/insurancebridge/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar/Crear Recetas (Asociadas a un rembolso)
 * request: drugtransaction_patient_insurance_insuranceauthorization_recipe_list
 * url: drugtransaction_patient_insurance_insuranceauthorization_recipe_listURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_recipe_list_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_recipe_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insuranceauthorizationUuid - 
 * @param patientUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  if (parameters['patientUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear una receta sociada a un reembolso.
 * request: drugtransaction_patient_insurance_insuranceauthorization_recipe_create
 * url: drugtransaction_patient_insurance_insuranceauthorization_recipe_createURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_recipe_create_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_recipe_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insuranceauthorizationUuid - 
 * @param patientUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  if (parameters['patientUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar/Crear Recetas (Asociadas a un rembolso)
 * request: drugtransaction_patient_insurance_insurancebridge_recipe_list
 * url: drugtransaction_patient_insurance_insurancebridge_recipe_listURL
 * method: drugtransaction_patient_insurance_insurancebridge_recipe_list_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_recipe_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param insurancebridgeUuid - 
 * @param patientUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_recipe_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  if (parameters['patientUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear una receta sociada a un reembolso.
 * request: drugtransaction_patient_insurance_insurancebridge_recipe_create
 * url: drugtransaction_patient_insurance_insurancebridge_recipe_createURL
 * method: drugtransaction_patient_insurance_insurancebridge_recipe_create_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_recipe_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param insurancebridgeUuid - 
 * @param patientUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_recipe_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  if (parameters['patientUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar los productos que contiene una receta
 * request: drugtransaction_patient_insurance_recipe_drug_list
 * url: drugtransaction_patient_insurance_recipe_drug_listURL
 * method: drugtransaction_patient_insurance_recipe_drug_list_TYPE
 * raw_url: drugtransaction_patient_insurance_recipe_drug_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param insuranceUuid - 
 * @param patientUuid - 
 * @param recipeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_recipe_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/recipe/{recipe_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  if (parameters['patientUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientUuid'))
  }
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  if (parameters['recipeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recipeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_recipe_drug_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/recipe/{recipe_uuid}/drug/'
}
export const drugtransaction_patient_insurance_recipe_drug_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_recipe_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/recipe/{recipe_uuid}/drug/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir producto a una receta
 * request: drugtransaction_patient_insurance_recipe_drug_create
 * url: drugtransaction_patient_insurance_recipe_drug_createURL
 * method: drugtransaction_patient_insurance_recipe_drug_create_TYPE
 * raw_url: drugtransaction_patient_insurance_recipe_drug_create_RAW_URL
 * @param data - 
 * @param insuranceUuid - 
 * @param patientUuid - 
 * @param recipeUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_recipe_drug_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/recipe/{recipe_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  if (parameters['insuranceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceUuid'))
  }
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  if (parameters['patientUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientUuid'))
  }
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  if (parameters['recipeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recipeUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_recipe_drug_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/recipe/{recipe_uuid}/drug/'
}
export const drugtransaction_patient_insurance_recipe_drug_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_recipe_drug_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{patient_uuid}/insurance/{insurance_uuid}/recipe/{recipe_uuid}/drug/'
  path = path.replace('{insurance_uuid}', `${parameters['insuranceUuid']}`)
  path = path.replace('{patient_uuid}', `${parameters['patientUuid']}`)
  path = path.replace('{recipe_uuid}', `${parameters['recipeUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listar productos, precio incluido, que un paciente puede ver de una
aseguradora.
* request: drugtransaction_patient_insurance_drugs_in_list
* url: drugtransaction_patient_insurance_drugs_in_listURL
* method: drugtransaction_patient_insurance_drugs_in_list_TYPE
* raw_url: drugtransaction_patient_insurance_drugs_in_list_RAW_URL
     * @param search - A search term in elasticsearch
     * @param letter - Starts with...
     * @param uuid - 
     * @param description - Description
     * @param substance - Substance
     * @param ean - 
     * @param category - Category uuid
     * @param groupedCategory - Category uuid
     * @param top - 
     * @param topHightSpeciality - 
     * @param topDerma - 
     * @param hightSpeciality - 
     * @param organization - Organization uuid
     * @param publicOrganization - Organization uuid with pricelistdestination public
     * @param updatedStartDate - 
     * @param updatedEndDate - 
     * @param brands - List of Brands uuid
     * @param topGeneric - 
     * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insurance_drugs_in_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/drugs/in/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_drugs_in_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/drugs/in/'
}
export const drugtransaction_patient_insurance_drugs_in_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_drugs_in_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/drugs/in/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_patient_insurance_insuranceauthorization_list
 * url: drugtransaction_patient_insurance_insuranceauthorization_listURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_list_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear reembolso
 * request: drugtransaction_patient_insurance_insuranceauthorization_create
 * url: drugtransaction_patient_insurance_insuranceauthorization_createURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_create_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_insuranceauthorization_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Reembolsos en borrador asociados al paciente (pt_uuid), asociados a la
aseguradora (i_uuid) y filtradas por los pacientes asociados al usuario
actual. Si el pt_uuid no está asociado al usuario actual, no se mostrará
el reembolso.
* request: drugtransaction_patient_insurance_insuranceauthorization_draft_list
* url: drugtransaction_patient_insurance_insuranceauthorization_draft_listURL
* method: drugtransaction_patient_insurance_insuranceauthorization_draft_list_TYPE
* raw_url: drugtransaction_patient_insurance_insuranceauthorization_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insurance_insuranceauthorization_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/draft/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso borrador indicando insuranceauthorization_uuid
 * request: drugtransaction_patient_insurance_insuranceauthorization_draft_read
 * url: drugtransaction_patient_insurance_insuranceauthorization_draft_readURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_draft_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_draft_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_draft_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/draft/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_draft_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/draft/{insuranceauthorization_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_draft_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_draft_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/draft/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso indicando insuranceauthorization_uuid
 * request: drugtransaction_patient_insurance_insuranceauthorization_read
 * url: drugtransaction_patient_insurance_insuranceauthorization_readURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insuranceauthorization_update
 * url: drugtransaction_patient_insurance_insuranceauthorization_updateURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_insuranceauthorization_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insuranceauthorization_partial_update
 * url: drugtransaction_patient_insurance_insuranceauthorization_partial_updateURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_insuranceauthorization_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un reembolso indicando insuranceauthorization_uuid
 * request: drugtransaction_patient_insurance_insuranceauthorization_delete
 * url: drugtransaction_patient_insurance_insuranceauthorization_deleteURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_insuranceauthorization_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de archivos asociados a un reembolso
 * request: drugtransaction_patient_insurance_insuranceauthorization_file_list
 * url: drugtransaction_patient_insurance_insuranceauthorization_file_listURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_file_list_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_file_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_file_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir archivo a un reembolso
 * request: drugtransaction_patient_insurance_insuranceauthorization_file_create
 * url: drugtransaction_patient_insurance_insuranceauthorization_file_createURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_file_create_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_file_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_file_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener archivo de reembolso indicando ref_uuid
 * request: drugtransaction_patient_insurance_insuranceauthorization_file_read
 * url: drugtransaction_patient_insurance_insuranceauthorization_file_readURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_file_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_file_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_file_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insuranceauthorization_file_update
 * url: drugtransaction_patient_insurance_insuranceauthorization_file_updateURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_file_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_file_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_file_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insuranceauthorization_file_partial_update
 * url: drugtransaction_patient_insurance_insuranceauthorization_file_partial_updateURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_file_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_file_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_file_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un archivo de reembolso indicando ref_uuid
 * request: drugtransaction_patient_insurance_insuranceauthorization_file_delete
 * url: drugtransaction_patient_insurance_insuranceauthorization_file_deleteURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_file_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_file_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_file_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_insuranceauthorization_file_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener receta de paciente a aseguradora asociada a un reembolso
 * request: drugtransaction_patient_insurance_insuranceauthorization_recipe_read
 * url: drugtransaction_patient_insurance_insuranceauthorization_recipe_readURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_recipe_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_recipe_read_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insuranceauthorization_recipe_update
 * url: drugtransaction_patient_insurance_insuranceauthorization_recipe_updateURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_recipe_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_recipe_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_update
 * url: drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_updateURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar receta
 * request: drugtransaction_patient_insurance_insuranceauthorization_recipe_delete
 * url: drugtransaction_patient_insurance_insuranceauthorization_recipe_deleteURL
 * method: drugtransaction_patient_insurance_insuranceauthorization_recipe_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_insuranceauthorization_recipe_delete_RAW_URL
 * @param iUuid - 
 * @param insuranceauthorizationUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  if (parameters['insuranceauthorizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceauthorizationUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_insuranceauthorization_recipe_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insuranceauthorization/{insuranceauthorization_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insuranceauthorization_uuid}', `${parameters['insuranceauthorizationUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de reembolsos
 * request: drugtransaction_patient_insurance_insurancebridge_list
 * url: drugtransaction_patient_insurance_insurancebridge_listURL
 * method: drugtransaction_patient_insurance_insurancebridge_list_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/'
}
export const drugtransaction_patient_insurance_insurancebridge_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crear reembolso
 * request: drugtransaction_patient_insurance_insurancebridge_create
 * url: drugtransaction_patient_insurance_insurancebridge_createURL
 * method: drugtransaction_patient_insurance_insurancebridge_create_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/'
}
export const drugtransaction_patient_insurance_insurancebridge_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_insurancebridge_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Reembolsos en borrador asociados al paciente (pt_uuid), asociados a la
aseguradora (i_uuid) y filtradas por los pacientes asociados al usuario
actual. Si el pt_uuid no está asociado al usuario actual, no se mostrará
el reembolso.
* request: drugtransaction_patient_insurance_insurancebridge_draft_list
* url: drugtransaction_patient_insurance_insurancebridge_draft_listURL
* method: drugtransaction_patient_insurance_insurancebridge_draft_list_TYPE
* raw_url: drugtransaction_patient_insurance_insurancebridge_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insurance_insurancebridge_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/draft/'
}
export const drugtransaction_patient_insurance_insurancebridge_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso borrador indicando insurancebridge_uuid
 * request: drugtransaction_patient_insurance_insurancebridge_draft_read
 * url: drugtransaction_patient_insurance_insurancebridge_draft_readURL
 * method: drugtransaction_patient_insurance_insurancebridge_draft_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_draft_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_draft_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/draft/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_draft_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/draft/{insurancebridge_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_draft_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_draft_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/draft/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un reemboso indicando insurancebridge_uuid
 * request: drugtransaction_patient_insurance_insurancebridge_read
 * url: drugtransaction_patient_insurance_insurancebridge_readURL
 * method: drugtransaction_patient_insurance_insurancebridge_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insurancebridge_update
 * url: drugtransaction_patient_insurance_insurancebridge_updateURL
 * method: drugtransaction_patient_insurance_insurancebridge_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_insurancebridge_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insurancebridge_partial_update
 * url: drugtransaction_patient_insurance_insurancebridge_partial_updateURL
 * method: drugtransaction_patient_insurance_insurancebridge_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_insurancebridge_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un reembolso indicando insurancebridge_uuid
 * request: drugtransaction_patient_insurance_insurancebridge_delete
 * url: drugtransaction_patient_insurance_insurancebridge_deleteURL
 * method: drugtransaction_patient_insurance_insurancebridge_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_insurancebridge_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de archivos asociados a un reembolso
 * request: drugtransaction_patient_insurance_insurancebridge_file_list
 * url: drugtransaction_patient_insurance_insurancebridge_file_listURL
 * method: drugtransaction_patient_insurance_insurancebridge_file_list_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_file_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_file_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_file_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
}
export const drugtransaction_patient_insurance_insurancebridge_file_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_file_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Añadir archivo a un reembolso
 * request: drugtransaction_patient_insurance_insurancebridge_file_create
 * url: drugtransaction_patient_insurance_insurancebridge_file_createURL
 * method: drugtransaction_patient_insurance_insurancebridge_file_create_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_file_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_file_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_file_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
}
export const drugtransaction_patient_insurance_insurancebridge_file_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_patient_insurance_insurancebridge_file_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener archivo de reembolso indicando ref_uuid
 * request: drugtransaction_patient_insurance_insurancebridge_file_read
 * url: drugtransaction_patient_insurance_insurancebridge_file_readURL
 * method: drugtransaction_patient_insurance_insurancebridge_file_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_file_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_file_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_file_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_file_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_file_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insurancebridge_file_update
 * url: drugtransaction_patient_insurance_insurancebridge_file_updateURL
 * method: drugtransaction_patient_insurance_insurancebridge_file_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_file_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_file_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_file_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_file_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_insurancebridge_file_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insurancebridge_file_partial_update
 * url: drugtransaction_patient_insurance_insurancebridge_file_partial_updateURL
 * method: drugtransaction_patient_insurance_insurancebridge_file_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_file_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_file_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_file_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_file_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_insurancebridge_file_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar un archivo de reembolso indicando ref_uuid
 * request: drugtransaction_patient_insurance_insurancebridge_file_delete
 * url: drugtransaction_patient_insurance_insurancebridge_file_deleteURL
 * method: drugtransaction_patient_insurance_insurancebridge_file_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_file_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param refUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_file_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  if (parameters['refUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: refUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_file_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_file_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_insurancebridge_file_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/file/{ref_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{ref_uuid}', `${parameters['refUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener receta de paciente a aseguradora asociada a un reembolso
 * request: drugtransaction_patient_insurance_insurancebridge_recipe_read
 * url: drugtransaction_patient_insurance_insurancebridge_recipe_readURL
 * method: drugtransaction_patient_insurance_insurancebridge_recipe_read_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_recipe_read_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_recipe_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insurancebridge_recipe_update
 * url: drugtransaction_patient_insurance_insurancebridge_recipe_updateURL
 * method: drugtransaction_patient_insurance_insurancebridge_recipe_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_recipe_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_recipe_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_insurancebridge_recipe_partial_update
 * url: drugtransaction_patient_insurance_insurancebridge_recipe_partial_updateURL
 * method: drugtransaction_patient_insurance_insurancebridge_recipe_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_recipe_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_recipe_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Eliminar receta
 * request: drugtransaction_patient_insurance_insurancebridge_recipe_delete
 * url: drugtransaction_patient_insurance_insurancebridge_recipe_deleteURL
 * method: drugtransaction_patient_insurance_insurancebridge_recipe_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_insurancebridge_recipe_delete_RAW_URL
 * @param iUuid - 
 * @param insurancebridgeUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_insurancebridge_recipe_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  if (parameters['insurancebridgeUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insurancebridgeUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_insurancebridge_recipe_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/insurancebridge/{insurancebridge_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{insurancebridge_uuid}', `${parameters['insurancebridgeUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_recipe_drug_read
 * url: drugtransaction_patient_insurance_recipe_drug_readURL
 * method: drugtransaction_patient_insurance_recipe_drug_read_TYPE
 * raw_url: drugtransaction_patient_insurance_recipe_drug_read_RAW_URL
 * @param iUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_recipe_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_recipe_drug_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_patient_insurance_recipe_drug_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurance_recipe_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_recipe_drug_update
 * url: drugtransaction_patient_insurance_recipe_drug_updateURL
 * method: drugtransaction_patient_insurance_recipe_drug_update_TYPE
 * raw_url: drugtransaction_patient_insurance_recipe_drug_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_recipe_drug_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_recipe_drug_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_patient_insurance_recipe_drug_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_patient_insurance_recipe_drug_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_recipe_drug_partial_update
 * url: drugtransaction_patient_insurance_recipe_drug_partial_updateURL
 * method: drugtransaction_patient_insurance_recipe_drug_partial_update_TYPE
 * raw_url: drugtransaction_patient_insurance_recipe_drug_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_recipe_drug_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_recipe_drug_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_patient_insurance_recipe_drug_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_patient_insurance_recipe_drug_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_patient_insurance_recipe_drug_delete
 * url: drugtransaction_patient_insurance_recipe_drug_deleteURL
 * method: drugtransaction_patient_insurance_recipe_drug_delete_TYPE
 * raw_url: drugtransaction_patient_insurance_recipe_drug_delete_RAW_URL
 * @param iUuid - 
 * @param ptUuid - 
 * @param rUuid - 
 * @param rdUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_patient_insurance_recipe_drug_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  if (parameters['rdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rdUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurance_recipe_drug_delete_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
}
export const drugtransaction_patient_insurance_recipe_drug_delete_TYPE = function() {
  return 'delete'
}
export const drugtransaction_patient_insurance_recipe_drug_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{rd_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{rd_uuid}', `${parameters['rdUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual. Si el pt_uuid no está asociado al usuario actual, no
se mostrará el reembolso.
* request: drugtransaction_patient_insuranceauthorization_list
* url: drugtransaction_patient_insuranceauthorization_listURL
* method: drugtransaction_patient_insuranceauthorization_list_TYPE
* raw_url: drugtransaction_patient_insuranceauthorization_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insuranceauthorization_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insuranceauthorization_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insuranceauthorization/'
}
export const drugtransaction_patient_insuranceauthorization_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insuranceauthorization_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insuranceauthorization/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos en borrador asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual. Si el pt_uuid no está asociado al usuario actual, no
se mostrará el reembolso.
* request: drugtransaction_patient_insuranceauthorization_draft_list
* url: drugtransaction_patient_insuranceauthorization_draft_listURL
* method: drugtransaction_patient_insuranceauthorization_draft_list_TYPE
* raw_url: drugtransaction_patient_insuranceauthorization_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insuranceauthorization_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insuranceauthorization/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insuranceauthorization_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insuranceauthorization/draft/'
}
export const drugtransaction_patient_insuranceauthorization_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insuranceauthorization_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insuranceauthorization/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual. Si el pt_uuid no está asociado al usuario actual, no
se mostrará el reembolso.
* request: drugtransaction_patient_insurancebridge_list
* url: drugtransaction_patient_insurancebridge_listURL
* method: drugtransaction_patient_insurancebridge_list_TYPE
* raw_url: drugtransaction_patient_insurancebridge_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insurancebridge_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurancebridge_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurancebridge/'
}
export const drugtransaction_patient_insurancebridge_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurancebridge_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurancebridge/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listado de reembolsos en borrador asociados al paciente (pt_uuid),
asociados a cualquier aseguradora y filtradas por los pacientes asociados
al usuario actual. Si el pt_uuid no está asociado al usuario actual, no
se mostrará el reembolso.
* request: drugtransaction_patient_insurancebridge_draft_list
* url: drugtransaction_patient_insurancebridge_draft_listURL
* method: drugtransaction_patient_insurancebridge_draft_list_TYPE
* raw_url: drugtransaction_patient_insurancebridge_draft_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param ptUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_patient_insurancebridge_draft_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurancebridge/draft/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_patient_insurancebridge_draft_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurancebridge/draft/'
}
export const drugtransaction_patient_insurancebridge_draft_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_patient_insurancebridge_draft_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/patient/{pt_uuid}/insurancebridge/draft/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Lista las intenciones de pago aún pendientes, ignora aquellas
    que han terminado ya sea por error, por expiración o por completadas
* request: drugtransaction_payment_intention_list
* url: drugtransaction_payment_intention_listURL
* method: drugtransaction_payment_intention_list_TYPE
* raw_url: drugtransaction_payment_intention_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const drugtransaction_payment_intention_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/intention/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_intention_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/intention/'
}
export const drugtransaction_payment_intention_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_payment_intention_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/intention/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Crea una nueva intención de pago.
 * request: drugtransaction_payment_intention_create
 * url: drugtransaction_payment_intention_createURL
 * method: drugtransaction_payment_intention_create_TYPE
 * raw_url: drugtransaction_payment_intention_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_payment_intention_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/intention/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_intention_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/intention/'
}
export const drugtransaction_payment_intention_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_payment_intention_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/intention/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_payment_intention_read
 * url: drugtransaction_payment_intention_readURL
 * method: drugtransaction_payment_intention_read_TYPE
 * raw_url: drugtransaction_payment_intention_read_RAW_URL
 * @param paymentIntentionUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_payment_intention_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/intention/{payment_intention_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{payment_intention_uuid}', `${parameters['paymentIntentionUuid']}`)
  if (parameters['paymentIntentionUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: paymentIntentionUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_intention_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/intention/{payment_intention_uuid}/'
}
export const drugtransaction_payment_intention_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_payment_intention_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/intention/{payment_intention_uuid}/'
  path = path.replace('{payment_intention_uuid}', `${parameters['paymentIntentionUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Métodos de pago permitidos
 * request: drugtransaction_payment_method_list
 * url: drugtransaction_payment_method_listURL
 * method: drugtransaction_payment_method_list_TYPE
 * raw_url: drugtransaction_payment_method_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_payment_method_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/method/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_method_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/method/'
}
export const drugtransaction_payment_method_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_payment_method_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/method/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detalle del método de pago
 * request: drugtransaction_payment_method_read
 * url: drugtransaction_payment_method_readURL
 * method: drugtransaction_payment_method_read_TYPE
 * raw_url: drugtransaction_payment_method_read_RAW_URL
 * @param pmUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_payment_method_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/method/{pm_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{pm_uuid}', `${parameters['pmUuid']}`)
  if (parameters['pmUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pmUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_method_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/method/{pm_uuid}/'
}
export const drugtransaction_payment_method_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_payment_method_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/method/{pm_uuid}/'
  path = path.replace('{pm_uuid}', `${parameters['pmUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* WebHook para que OpenPay nos mande confirmaciones de pagos. La url
a proporcionar para el webhook es:
* request: drugtransaction_payment_opwebhook_create
* url: drugtransaction_payment_opwebhook_createURL
* method: drugtransaction_payment_opwebhook_create_TYPE
* raw_url: drugtransaction_payment_opwebhook_create_RAW_URL
     * @param data - 
     * @param role - 
     * @param roleUuid - 
     * @param webhookUuid - 
*/
export const drugtransaction_payment_opwebhook_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/opwebhook/{webhook_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{webhook_uuid}', `${parameters['webhookUuid']}`)
  if (parameters['webhookUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: webhookUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_opwebhook_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/opwebhook/{webhook_uuid}/'
}
export const drugtransaction_payment_opwebhook_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_payment_opwebhook_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/opwebhook/{webhook_uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{webhook_uuid}', `${parameters['webhookUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* WebHook para que WebPayPlus nos mande confirmaciones de pagos. La url
a proporcionar para el webhook es:
* request: drugtransaction_payment_wppwebhook_create
* url: drugtransaction_payment_wppwebhook_createURL
* method: drugtransaction_payment_wppwebhook_create_TYPE
* raw_url: drugtransaction_payment_wppwebhook_create_RAW_URL
     * @param data - 
     * @param role - 
     * @param roleUuid - 
     * @param webhookUuid - 
*/
export const drugtransaction_payment_wppwebhook_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/payment/wppwebhook/{webhook_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{webhook_uuid}', `${parameters['webhookUuid']}`)
  if (parameters['webhookUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: webhookUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_payment_wppwebhook_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/payment/wppwebhook/{webhook_uuid}/'
}
export const drugtransaction_payment_wppwebhook_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_payment_wppwebhook_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/payment/wppwebhook/{webhook_uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{webhook_uuid}', `${parameters['webhookUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Internal Order detail
 * request: drugtransaction_protected_internalorder_read
 * url: drugtransaction_protected_internalorder_readURL
 * method: drugtransaction_protected_internalorder_read_TYPE
 * raw_url: drugtransaction_protected_internalorder_read_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param orderHumanCode - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_protected_internalorder_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/protected/internalorder/{order_human_code}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{order_human_code}', `${parameters['orderHumanCode']}`)
  if (parameters['orderHumanCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderHumanCode'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_protected_internalorder_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/protected/internalorder/{order_human_code}/'
}
export const drugtransaction_protected_internalorder_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_protected_internalorder_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/protected/internalorder/{order_human_code}/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{order_human_code}', `${parameters['orderHumanCode']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_protected_organization_insuranceriskpayment_list
 * url: drugtransaction_protected_organization_insuranceriskpayment_listURL
 * method: drugtransaction_protected_organization_insuranceriskpayment_list_TYPE
 * raw_url: drugtransaction_protected_organization_insuranceriskpayment_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_protected_organization_insuranceriskpayment_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/protected/organization/{organization_uuid}/insuranceriskpayment/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_protected_organization_insuranceriskpayment_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/protected/organization/{organization_uuid}/insuranceriskpayment/'
}
export const drugtransaction_protected_organization_insuranceriskpayment_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_protected_organization_insuranceriskpayment_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/protected/organization/{organization_uuid}/insuranceriskpayment/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Retail Order detail
 * request: drugtransaction_protected_retailorder_read
 * url: drugtransaction_protected_retailorder_readURL
 * method: drugtransaction_protected_retailorder_read_TYPE
 * raw_url: drugtransaction_protected_retailorder_read_RAW_URL
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_protected_retailorder_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/protected/retailorder/{order_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_protected_retailorder_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/protected/retailorder/{order_uuid}/'
}
export const drugtransaction_protected_retailorder_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_protected_retailorder_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/protected/retailorder/{order_uuid}/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_list
 * url: drugtransaction_ra_insuranceriskpayment_listURL
 * method: drugtransaction_ra_insuranceriskpayment_list_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_list_RAW_URL
 * @param ordering - Which field to use when ordering the results.
 * @param insuranceName - Filter by insurance name
 * @param insuredName - 
 * @param under - Filter by has_deductible (1), has_coinsurance (2), both (3)
 * @param policyNum - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['under'] !== undefined) {
    queryParameters['under'] = parameters['under']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/'
}
export const drugtransaction_ra_insuranceriskpayment_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_ra_insuranceriskpayment_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['insuranceName'] !== undefined) {
    queryParameters['insurance_name'] = parameters['insuranceName']
  }
  if (parameters['insuredName'] !== undefined) {
    queryParameters['insured_name'] = parameters['insuredName']
  }
  if (parameters['under'] !== undefined) {
    queryParameters['under'] = parameters['under']
  }
  if (parameters['policyNum'] !== undefined) {
    queryParameters['policy_num'] = parameters['policyNum']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_create
 * url: drugtransaction_ra_insuranceriskpayment_createURL
 * method: drugtransaction_ra_insuranceriskpayment_create_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/'
}
export const drugtransaction_ra_insuranceriskpayment_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_ra_insuranceriskpayment_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_availinsurances_list
 * url: drugtransaction_ra_insuranceriskpayment_availinsurances_listURL
 * method: drugtransaction_ra_insuranceriskpayment_availinsurances_list_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_availinsurances_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_availinsurances_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/availinsurances/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_availinsurances_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/availinsurances/'
}
export const drugtransaction_ra_insuranceriskpayment_availinsurances_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_ra_insuranceriskpayment_availinsurances_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/availinsurances/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_read
 * url: drugtransaction_ra_insuranceriskpayment_readURL
 * method: drugtransaction_ra_insuranceriskpayment_read_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_read_RAW_URL
 * @param insuranceriskpaymentUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  if (parameters['insuranceriskpaymentUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceriskpaymentUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
}
export const drugtransaction_ra_insuranceriskpayment_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_ra_insuranceriskpayment_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_update
 * url: drugtransaction_ra_insuranceriskpayment_updateURL
 * method: drugtransaction_ra_insuranceriskpayment_update_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_update_RAW_URL
 * @param data - 
 * @param insuranceriskpaymentUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  if (parameters['insuranceriskpaymentUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceriskpaymentUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
}
export const drugtransaction_ra_insuranceriskpayment_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_ra_insuranceriskpayment_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_partial_update
 * url: drugtransaction_ra_insuranceriskpayment_partial_updateURL
 * method: drugtransaction_ra_insuranceriskpayment_partial_update_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_partial_update_RAW_URL
 * @param data - 
 * @param insuranceriskpaymentUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  if (parameters['insuranceriskpaymentUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceriskpaymentUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
}
export const drugtransaction_ra_insuranceriskpayment_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_ra_insuranceriskpayment_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/'
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_ra_insuranceriskpayment_request_invoice_create
 * url: drugtransaction_ra_insuranceriskpayment_request_invoice_createURL
 * method: drugtransaction_ra_insuranceriskpayment_request_invoice_create_TYPE
 * raw_url: drugtransaction_ra_insuranceriskpayment_request_invoice_create_RAW_URL
 * @param data - 
 * @param insuranceriskpaymentUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_ra_insuranceriskpayment_request_invoice_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/request_invoice/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  if (parameters['insuranceriskpaymentUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: insuranceriskpaymentUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_ra_insuranceriskpayment_request_invoice_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/request_invoice/'
}
export const drugtransaction_ra_insuranceriskpayment_request_invoice_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_ra_insuranceriskpayment_request_invoice_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/ra/insuranceriskpayment/{insuranceriskpayment_uuid}/request_invoice/'
  path = path.replace('{insuranceriskpayment_uuid}', `${parameters['insuranceriskpaymentUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listar pedidos
 * request: drugtransaction_retailorder_list
 * url: drugtransaction_retailorder_listURL
 * method: drugtransaction_retailorder_list_TYPE
 * raw_url: drugtransaction_retailorder_list_RAW_URL
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_retailorder_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_list_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/'
}
export const drugtransaction_retailorder_list_TYPE = function() {
  return 'get'
}
export const drugtransaction_retailorder_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un pedido
 * request: drugtransaction_retailorder_read
 * url: drugtransaction_retailorder_readURL
 * method: drugtransaction_retailorder_read_TYPE
 * raw_url: drugtransaction_retailorder_read_RAW_URL
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_retailorder_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/'
}
export const drugtransaction_retailorder_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_retailorder_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_retailorder_message_create
 * url: drugtransaction_retailorder_message_createURL
 * method: drugtransaction_retailorder_message_create_TYPE
 * raw_url: drugtransaction_retailorder_message_create_RAW_URL
 * @param data - 
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_retailorder_message_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/message/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_message_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/message/'
}
export const drugtransaction_retailorder_message_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_retailorder_message_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/message/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_retailorder_payment_read
 * url: drugtransaction_retailorder_payment_readURL
 * method: drugtransaction_retailorder_payment_read_TYPE
 * raw_url: drugtransaction_retailorder_payment_read_RAW_URL
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param transactionUuid - 
 */
export const drugtransaction_retailorder_payment_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{transaction_uuid}', `${parameters['transactionUuid']}`)
  if (parameters['transactionUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: transactionUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_payment_read_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
}
export const drugtransaction_retailorder_payment_read_TYPE = function() {
  return 'get'
}
export const drugtransaction_retailorder_payment_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{transaction_uuid}', `${parameters['transactionUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_retailorder_payment_update
 * url: drugtransaction_retailorder_payment_updateURL
 * method: drugtransaction_retailorder_payment_update_TYPE
 * raw_url: drugtransaction_retailorder_payment_update_RAW_URL
 * @param data - 
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param transactionUuid - 
 */
export const drugtransaction_retailorder_payment_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{transaction_uuid}', `${parameters['transactionUuid']}`)
  if (parameters['transactionUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: transactionUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_payment_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
}
export const drugtransaction_retailorder_payment_update_TYPE = function() {
  return 'put'
}
export const drugtransaction_retailorder_payment_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{transaction_uuid}', `${parameters['transactionUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_retailorder_payment_partial_update
 * url: drugtransaction_retailorder_payment_partial_updateURL
 * method: drugtransaction_retailorder_payment_partial_update_TYPE
 * raw_url: drugtransaction_retailorder_payment_partial_update_RAW_URL
 * @param data - 
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param transactionUuid - 
 */
export const drugtransaction_retailorder_payment_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{transaction_uuid}', `${parameters['transactionUuid']}`)
  if (parameters['transactionUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: transactionUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_payment_partial_update_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
}
export const drugtransaction_retailorder_payment_partial_update_TYPE = function() {
  return 'patch'
}
export const drugtransaction_retailorder_payment_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/payment/{transaction_uuid}/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{transaction_uuid}', `${parameters['transactionUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: drugtransaction_retailorder_request_invoice_create
 * url: drugtransaction_retailorder_request_invoice_createURL
 * method: drugtransaction_retailorder_request_invoice_create_TYPE
 * raw_url: drugtransaction_retailorder_request_invoice_create_RAW_URL
 * @param data - 
 * @param orderUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const drugtransaction_retailorder_request_invoice_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/request_invoice/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  if (parameters['orderUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: orderUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const drugtransaction_retailorder_request_invoice_create_RAW_URL = function() {
  return '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/request_invoice/'
}
export const drugtransaction_retailorder_request_invoice_create_TYPE = function() {
  return 'post'
}
export const drugtransaction_retailorder_request_invoice_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/drugtransaction/{role}/{role_uuid}/retailorder/{order_uuid}/request_invoice/'
  path = path.replace('{order_uuid}', `${parameters['orderUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: initial_configuration_create
 * url: initial_configuration_createURL
 * method: initial_configuration_create_TYPE
 * raw_url: initial_configuration_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param site - 
 */
export const initial_configuration_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/initial/{role}/{role_uuid}/configuration/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const initial_configuration_create_RAW_URL = function() {
  return '/initial/{role}/{role_uuid}/configuration/{site}/'
}
export const initial_configuration_create_TYPE = function() {
  return 'post'
}
export const initial_configuration_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/initial/{role}/{role_uuid}/configuration/{site}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: monetary_programs_list
 * url: monetary_programs_listURL
 * method: monetary_programs_list_TYPE
 * raw_url: monetary_programs_list_RAW_URL
 * @param phdrug - PhDrug uuid
 * @param patientExclude - Patient uuid
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const monetary_programs_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/monetary/{role}/{role_uuid}/programs/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['phdrug'] !== undefined) {
    queryParameters['phdrug'] = parameters['phdrug']
  }
  if (parameters['patientExclude'] !== undefined) {
    queryParameters['patient_exclude'] = parameters['patientExclude']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const monetary_programs_list_RAW_URL = function() {
  return '/monetary/{role}/{role_uuid}/programs/'
}
export const monetary_programs_list_TYPE = function() {
  return 'get'
}
export const monetary_programs_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/monetary/{role}/{role_uuid}/programs/'
  if (parameters['phdrug'] !== undefined) {
    queryParameters['phdrug'] = parameters['phdrug']
  }
  if (parameters['patientExclude'] !== undefined) {
    queryParameters['patient_exclude'] = parameters['patientExclude']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: monetary_programs_read
 * url: monetary_programs_readURL
 * method: monetary_programs_read_TYPE
 * raw_url: monetary_programs_read_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const monetary_programs_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const monetary_programs_read_RAW_URL = function() {
  return '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/'
}
export const monetary_programs_read_TYPE = function() {
  return 'get'
}
export const monetary_programs_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: monetary_programs_create
 * url: monetary_programs_createURL
 * method: monetary_programs_create_TYPE
 * raw_url: monetary_programs_create_RAW_URL
 * @param data - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const monetary_programs_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const monetary_programs_create_RAW_URL = function() {
  return '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/'
}
export const monetary_programs_create_TYPE = function() {
  return 'post'
}
export const monetary_programs_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: monetary_programs_delete
 * url: monetary_programs_deleteURL
 * method: monetary_programs_delete_TYPE
 * raw_url: monetary_programs_delete_RAW_URL
 * @param pUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const monetary_programs_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/{p_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const monetary_programs_delete_RAW_URL = function() {
  return '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/{p_uuid}/'
}
export const monetary_programs_delete_TYPE = function() {
  return 'delete'
}
export const monetary_programs_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/monetary/{role}/{role_uuid}/programs/{pt_uuid}/{p_uuid}/'
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: monetary_rates_list
 * url: monetary_rates_listURL
 * method: monetary_rates_list_TYPE
 * raw_url: monetary_rates_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const monetary_rates_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/monetary/{role}/{role_uuid}/rates/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const monetary_rates_list_RAW_URL = function() {
  return '/monetary/{role}/{role_uuid}/rates/'
}
export const monetary_rates_list_TYPE = function() {
  return 'get'
}
export const monetary_rates_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/monetary/{role}/{role_uuid}/rates/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: naturalperson_doctor_list
 * url: naturalperson_doctor_listURL
 * method: naturalperson_doctor_list_TYPE
 * raw_url: naturalperson_doctor_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_doctor_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/doctor/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_doctor_list_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/doctor/'
}
export const naturalperson_doctor_list_TYPE = function() {
  return 'get'
}
export const naturalperson_doctor_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/doctor/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: naturalperson_doctor_create
 * url: naturalperson_doctor_createURL
 * method: naturalperson_doctor_create_TYPE
 * raw_url: naturalperson_doctor_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_doctor_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/doctor/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const naturalperson_doctor_create_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/doctor/'
}
export const naturalperson_doctor_create_TYPE = function() {
  return 'post'
}
export const naturalperson_doctor_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/doctor/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su doctor
 * request: naturalperson_doctor_read
 * url: naturalperson_doctor_readURL
 * method: naturalperson_doctor_read_TYPE
 * raw_url: naturalperson_doctor_read_RAW_URL
 * @param dtUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_doctor_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  if (parameters['dtUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dtUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_doctor_read_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
}
export const naturalperson_doctor_read_TYPE = function() {
  return 'get'
}
export const naturalperson_doctor_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su doctor
 * request: naturalperson_doctor_update
 * url: naturalperson_doctor_updateURL
 * method: naturalperson_doctor_update_TYPE
 * raw_url: naturalperson_doctor_update_RAW_URL
 * @param data - 
 * @param dtUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_doctor_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  if (parameters['dtUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dtUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const naturalperson_doctor_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
}
export const naturalperson_doctor_update_TYPE = function() {
  return 'put'
}
export const naturalperson_doctor_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su doctor
 * request: naturalperson_doctor_partial_update
 * url: naturalperson_doctor_partial_updateURL
 * method: naturalperson_doctor_partial_update_TYPE
 * raw_url: naturalperson_doctor_partial_update_RAW_URL
 * @param data - 
 * @param dtUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_doctor_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  if (parameters['dtUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dtUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const naturalperson_doctor_partial_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
}
export const naturalperson_doctor_partial_update_TYPE = function() {
  return 'patch'
}
export const naturalperson_doctor_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su doctor
 * request: naturalperson_doctor_delete
 * url: naturalperson_doctor_deleteURL
 * method: naturalperson_doctor_delete_TYPE
 * raw_url: naturalperson_doctor_delete_RAW_URL
 * @param dtUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_doctor_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  if (parameters['dtUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dtUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const naturalperson_doctor_delete_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
}
export const naturalperson_doctor_delete_TYPE = function() {
  return 'delete'
}
export const naturalperson_doctor_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/doctor/{dt_uuid}/'
  path = path.replace('{dt_uuid}', `${parameters['dtUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: naturalperson_patient_list
 * url: naturalperson_patient_listURL
 * method: naturalperson_patient_list_TYPE
 * raw_url: naturalperson_patient_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_list_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/'
}
export const naturalperson_patient_list_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: naturalperson_patient_create
 * url: naturalperson_patient_createURL
 * method: naturalperson_patient_create_TYPE
 * raw_url: naturalperson_patient_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_create_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/'
}
export const naturalperson_patient_create_TYPE = function() {
  return 'post'
}
export const naturalperson_patient_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para devolver todas las direcciones de un usuario
 * request: naturalperson_patient_all_addresses_list
 * url: naturalperson_patient_all_addresses_listURL
 * method: naturalperson_patient_all_addresses_list_TYPE
 * raw_url: naturalperson_patient_all_addresses_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_all_addresses_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/all/addresses/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_all_addresses_list_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/all/addresses/'
}
export const naturalperson_patient_all_addresses_list_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_all_addresses_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/all/addresses/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: naturalperson_patient_main_read
 * url: naturalperson_patient_main_readURL
 * method: naturalperson_patient_main_read_TYPE
 * raw_url: naturalperson_patient_main_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_main_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/main/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_main_read_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/main/'
}
export const naturalperson_patient_main_read_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_main_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/main/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su paciente
 * request: naturalperson_patient_read
 * url: naturalperson_patient_readURL
 * method: naturalperson_patient_read_TYPE
 * raw_url: naturalperson_patient_read_RAW_URL
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_read_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
}
export const naturalperson_patient_read_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su paciente
 * request: naturalperson_patient_update
 * url: naturalperson_patient_updateURL
 * method: naturalperson_patient_update_TYPE
 * raw_url: naturalperson_patient_update_RAW_URL
 * @param data - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
}
export const naturalperson_patient_update_TYPE = function() {
  return 'put'
}
export const naturalperson_patient_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su paciente
 * request: naturalperson_patient_partial_update
 * url: naturalperson_patient_partial_updateURL
 * method: naturalperson_patient_partial_update_TYPE
 * raw_url: naturalperson_patient_partial_update_RAW_URL
 * @param data - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_partial_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
}
export const naturalperson_patient_partial_update_TYPE = function() {
  return 'patch'
}
export const naturalperson_patient_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para que un usuario pueda actualizar, borrar o ver en detalle su paciente
 * request: naturalperson_patient_delete
 * url: naturalperson_patient_deleteURL
 * method: naturalperson_patient_delete_TYPE
 * raw_url: naturalperson_patient_delete_RAW_URL
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_delete_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
}
export const naturalperson_patient_delete_TYPE = function() {
  return 'delete'
}
export const naturalperson_patient_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para devolver todas las direcciones de un paciente
 * request: naturalperson_patient_addresses_list
 * url: naturalperson_patient_addresses_listURL
 * method: naturalperson_patient_addresses_list_TYPE
 * raw_url: naturalperson_patient_addresses_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_list_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/'
}
export const naturalperson_patient_addresses_list_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_addresses_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para devolver todas las direcciones de un paciente
 * request: naturalperson_patient_addresses_create
 * url: naturalperson_patient_addresses_createURL
 * method: naturalperson_patient_addresses_create_TYPE
 * raw_url: naturalperson_patient_addresses_create_RAW_URL
 * @param data - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_create_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/'
}
export const naturalperson_patient_addresses_create_TYPE = function() {
  return 'post'
}
export const naturalperson_patient_addresses_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para obtener la dirección principal de un paciente en concreto
 * request: naturalperson_patient_addresses_main_read
 * url: naturalperson_patient_addresses_main_readURL
 * method: naturalperson_patient_addresses_main_read_TYPE
 * raw_url: naturalperson_patient_addresses_main_read_RAW_URL
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_main_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_main_read_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
}
export const naturalperson_patient_addresses_main_read_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_addresses_main_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para obtener la dirección principal de un paciente en concreto
 * request: naturalperson_patient_addresses_main_update
 * url: naturalperson_patient_addresses_main_updateURL
 * method: naturalperson_patient_addresses_main_update_TYPE
 * raw_url: naturalperson_patient_addresses_main_update_RAW_URL
 * @param data - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_main_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_main_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
}
export const naturalperson_patient_addresses_main_update_TYPE = function() {
  return 'put'
}
export const naturalperson_patient_addresses_main_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para obtener la dirección principal de un paciente en concreto
 * request: naturalperson_patient_addresses_main_partial_update
 * url: naturalperson_patient_addresses_main_partial_updateURL
 * method: naturalperson_patient_addresses_main_partial_update_TYPE
 * raw_url: naturalperson_patient_addresses_main_partial_update_RAW_URL
 * @param data - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_main_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_main_partial_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
}
export const naturalperson_patient_addresses_main_partial_update_TYPE = function() {
  return 'patch'
}
export const naturalperson_patient_addresses_main_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/main/'
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: naturalperson_patient_addresses_read
 * url: naturalperson_patient_addresses_readURL
 * method: naturalperson_patient_addresses_read_TYPE
 * raw_url: naturalperson_patient_addresses_read_RAW_URL
 * @param aUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_read_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
}
export const naturalperson_patient_addresses_read_TYPE = function() {
  return 'get'
}
export const naturalperson_patient_addresses_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: naturalperson_patient_addresses_update
 * url: naturalperson_patient_addresses_updateURL
 * method: naturalperson_patient_addresses_update_TYPE
 * raw_url: naturalperson_patient_addresses_update_RAW_URL
 * @param data - 
 * @param aUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
}
export const naturalperson_patient_addresses_update_TYPE = function() {
  return 'put'
}
export const naturalperson_patient_addresses_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: naturalperson_patient_addresses_partial_update
 * url: naturalperson_patient_addresses_partial_updateURL
 * method: naturalperson_patient_addresses_partial_update_TYPE
 * raw_url: naturalperson_patient_addresses_partial_update_RAW_URL
 * @param data - 
 * @param aUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_partial_update_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
}
export const naturalperson_patient_addresses_partial_update_TYPE = function() {
  return 'patch'
}
export const naturalperson_patient_addresses_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: naturalperson_patient_addresses_delete
 * url: naturalperson_patient_addresses_deleteURL
 * method: naturalperson_patient_addresses_delete_TYPE
 * raw_url: naturalperson_patient_addresses_delete_RAW_URL
 * @param aUuid - 
 * @param ptUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_patient_addresses_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  if (parameters['ptUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ptUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const naturalperson_patient_addresses_delete_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
}
export const naturalperson_patient_addresses_delete_TYPE = function() {
  return 'delete'
}
export const naturalperson_patient_addresses_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/patient/{pt_uuid}/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  path = path.replace('{pt_uuid}', `${parameters['ptUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: naturalperson_persons_list
 * url: naturalperson_persons_listURL
 * method: naturalperson_persons_list_TYPE
 * raw_url: naturalperson_persons_list_RAW_URL
 * @param patient - Patient uuid
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const naturalperson_persons_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/naturalperson/{role}/{role_uuid}/persons/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['patient'] !== undefined) {
    queryParameters['patient'] = parameters['patient']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const naturalperson_persons_list_RAW_URL = function() {
  return '/naturalperson/{role}/{role_uuid}/persons/'
}
export const naturalperson_persons_list_TYPE = function() {
  return 'get'
}
export const naturalperson_persons_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/naturalperson/{role}/{role_uuid}/persons/'
  if (parameters['patient'] !== undefined) {
    queryParameters['patient'] = parameters['patient']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_insurance_list
 * url: organization_insurance_listURL
 * method: organization_insurance_list_TYPE
 * raw_url: organization_insurance_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_insurance_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/'
}
export const organization_insurance_list_TYPE = function() {
  return 'get'
}
export const organization_insurance_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_insurance_create
 * url: organization_insurance_createURL
 * method: organization_insurance_create_TYPE
 * raw_url: organization_insurance_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_insurance_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/'
}
export const organization_insurance_create_TYPE = function() {
  return 'post'
}
export const organization_insurance_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Aseguradoras configuradas para aparecer en reembolso o autorización de pdv
 * request: organization_insurance_insuranceallmodule_list
 * url: organization_insurance_insuranceallmodule_listURL
 * method: organization_insurance_insuranceallmodule_list_TYPE
 * raw_url: organization_insurance_insuranceallmodule_list_RAW_URL
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_insurance_insuranceallmodule_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/insuranceallmodule/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_insuranceallmodule_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/insuranceallmodule/'
}
export const organization_insurance_insuranceallmodule_list_TYPE = function() {
  return 'get'
}
export const organization_insurance_insuranceallmodule_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/insuranceallmodule/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Aseguradoras configuradas para aparecer en autorización de pdv
 * request: organization_insurance_insuranceauthorization_list
 * url: organization_insurance_insuranceauthorization_listURL
 * method: organization_insurance_insuranceauthorization_list_TYPE
 * raw_url: organization_insurance_insuranceauthorization_list_RAW_URL
 * @param ordering - Which field to use when ordering the results.
 * @param uuid - 
 * @param publicName - Public name
 * @param country - 
 * @param town - 
 * @param colony - 
 * @param address - 
 * @param legalName - 
 * @param withPubPricesToCountry - Pharmacies with pub prices
 * @param distance - Filter by organization with presence near to point.Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param insuranceAgainstUser - Filtra aseguradoras que están conectadas (sgmm) (patientcustomer) con el usuario actual
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_insurance_insuranceauthorization_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/insuranceauthorization/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_insuranceauthorization_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/insuranceauthorization/'
}
export const organization_insurance_insuranceauthorization_list_TYPE = function() {
  return 'get'
}
export const organization_insurance_insuranceauthorization_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/insuranceauthorization/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Aseguradoras configuradas para aparecer en reembolso de pdv
 * request: organization_insurance_insurancebridge_list
 * url: organization_insurance_insurancebridge_listURL
 * method: organization_insurance_insurancebridge_list_TYPE
 * raw_url: organization_insurance_insurancebridge_list_RAW_URL
 * @param ordering - Which field to use when ordering the results.
 * @param uuid - 
 * @param publicName - Public name
 * @param country - 
 * @param town - 
 * @param colony - 
 * @param address - 
 * @param legalName - 
 * @param withPubPricesToCountry - Pharmacies with pub prices
 * @param distance - Filter by organization with presence near to point.Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param insuranceAgainstUser - Filtra aseguradoras que están conectadas (sgmm) (patientcustomer) con el usuario actual
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_insurance_insurancebridge_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/insurancebridge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_insurancebridge_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/insurancebridge/'
}
export const organization_insurance_insurancebridge_list_TYPE = function() {
  return 'get'
}
export const organization_insurance_insurancebridge_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/insurancebridge/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Aseguradoras configuradas para aparecer en reembolso de pdv
 * request: organization_insurance_insurancerefund_list
 * url: organization_insurance_insurancerefund_listURL
 * method: organization_insurance_insurancerefund_list_TYPE
 * raw_url: organization_insurance_insurancerefund_list_RAW_URL
 * @param ordering - Which field to use when ordering the results.
 * @param uuid - 
 * @param publicName - Public name
 * @param country - 
 * @param town - 
 * @param colony - 
 * @param address - 
 * @param legalName - 
 * @param withPubPricesToCountry - Pharmacies with pub prices
 * @param distance - Filter by organization with presence near to point.Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param insuranceAgainstUser - Filtra aseguradoras que están conectadas (sgmm) (patientcustomer) con el usuario actual
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_insurance_insurancerefund_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/insurancerefund/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_insurancerefund_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/insurancerefund/'
}
export const organization_insurance_insurancerefund_list_TYPE = function() {
  return 'get'
}
export const organization_insurance_insurancerefund_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/insurancerefund/'
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Listar los proveedores de tipo farmacia de una aseguradora para mostrarse
en un reembolso como opción de filtro de productos.
* request: organization_insurance_insuranceauthorization_providers_list
* url: organization_insurance_insuranceauthorization_providers_listURL
* method: organization_insurance_insuranceauthorization_providers_list_TYPE
* raw_url: organization_insurance_insuranceauthorization_providers_list_RAW_URL
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param iUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const organization_insurance_insuranceauthorization_providers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/{i_uuid}/insuranceauthorization/providers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_insuranceauthorization_providers_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/{i_uuid}/insuranceauthorization/providers/'
}
export const organization_insurance_insuranceauthorization_providers_list_TYPE = function() {
  return 'get'
}
export const organization_insurance_insuranceauthorization_providers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/{i_uuid}/insuranceauthorization/providers/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_insurance_read
 * url: organization_insurance_readURL
 * method: organization_insurance_read_TYPE
 * raw_url: organization_insurance_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_insurance_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/{uuid}/'
}
export const organization_insurance_read_TYPE = function() {
  return 'get'
}
export const organization_insurance_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_insurance_update
 * url: organization_insurance_updateURL
 * method: organization_insurance_update_TYPE
 * raw_url: organization_insurance_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_insurance_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/{uuid}/'
}
export const organization_insurance_update_TYPE = function() {
  return 'put'
}
export const organization_insurance_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_insurance_partial_update
 * url: organization_insurance_partial_updateURL
 * method: organization_insurance_partial_update_TYPE
 * raw_url: organization_insurance_partial_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_insurance_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/{uuid}/'
}
export const organization_insurance_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_insurance_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_insurance_delete
 * url: organization_insurance_deleteURL
 * method: organization_insurance_delete_TYPE
 * raw_url: organization_insurance_delete_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_insurance_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_insurance_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/insurance/{uuid}/'
}
export const organization_insurance_delete_TYPE = function() {
  return 'delete'
}
export const organization_insurance_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/insurance/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_internationaltradeconfiguration_read
 * url: organization_internationaltradeconfiguration_readURL
 * method: organization_internationaltradeconfiguration_read_TYPE
 * raw_url: organization_internationaltradeconfiguration_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const organization_internationaltradeconfiguration_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/internationaltradeconfiguration/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_internationaltradeconfiguration_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/internationaltradeconfiguration/'
}
export const organization_internationaltradeconfiguration_read_TYPE = function() {
  return 'get'
}
export const organization_internationaltradeconfiguration_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/internationaltradeconfiguration/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_my_list
 * url: organization_my_listURL
 * method: organization_my_list_TYPE
 * raw_url: organization_my_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_my_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/my/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_my_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/my/'
}
export const organization_my_list_TYPE = function() {
  return 'get'
}
export const organization_my_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/my/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de patient customer del usuario actual.
 * request: organization_patientcustomer_insurance_self_list
 * url: organization_patientcustomer_insurance_self_listURL
 * method: organization_patientcustomer_insurance_self_list_TYPE
 * raw_url: organization_patientcustomer_insurance_self_list_RAW_URL
 * @param managerUuid - 
 * @param managerConfigurationShowInDeductibleCoinsurancePayment - 
 * @param search - A search term.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_patientcustomer_insurance_self_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['managerUuid'] !== undefined) {
    queryParameters['manager__uuid'] = parameters['managerUuid']
  }
  if (parameters['managerConfigurationShowInDeductibleCoinsurancePayment'] !== undefined) {
    queryParameters['manager__configuration__show_in_deductible_coinsurance_payment'] = parameters['managerConfigurationShowInDeductibleCoinsurancePayment']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_patientcustomer_insurance_self_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/'
}
export const organization_patientcustomer_insurance_self_list_TYPE = function() {
  return 'get'
}
export const organization_patientcustomer_insurance_self_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/'
  if (parameters['managerUuid'] !== undefined) {
    queryParameters['manager__uuid'] = parameters['managerUuid']
  }
  if (parameters['managerConfigurationShowInDeductibleCoinsurancePayment'] !== undefined) {
    queryParameters['manager__configuration__show_in_deductible_coinsurance_payment'] = parameters['managerConfigurationShowInDeductibleCoinsurancePayment']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Creación de un nuevo patient customer por parte de un paciente.
 * request: organization_patientcustomer_insurance_self_create
 * url: organization_patientcustomer_insurance_self_createURL
 * method: organization_patientcustomer_insurance_self_create_TYPE
 * raw_url: organization_patientcustomer_insurance_self_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_patientcustomer_insurance_self_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_patientcustomer_insurance_self_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/'
}
export const organization_patientcustomer_insurance_self_create_TYPE = function() {
  return 'post'
}
export const organization_patientcustomer_insurance_self_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Obtener un patient customer. Se filtra por el usuario actual.
 * request: organization_patientcustomer_insurance_self_read
 * url: organization_patientcustomer_insurance_self_readURL
 * method: organization_patientcustomer_insurance_self_read_TYPE
 * raw_url: organization_patientcustomer_insurance_self_read_RAW_URL
 * @param patientCustomerUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_patientcustomer_insurance_self_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  if (parameters['patientCustomerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientCustomerUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_patientcustomer_insurance_self_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
}
export const organization_patientcustomer_insurance_self_read_TYPE = function() {
  return 'get'
}
export const organization_patientcustomer_insurance_self_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Este modelo fue diseñado para ser usado por la organización, por lo que
debería quedar oculto para el usuario final. No obstante por necesidades
en el front, se hace necesario que un paciente vea estos datos. En concreto
para permitir al paciente hacer clientes de una aseguradora en PDV.
* request: organization_patientcustomer_insurance_self_update
* url: organization_patientcustomer_insurance_self_updateURL
* method: organization_patientcustomer_insurance_self_update_TYPE
* raw_url: organization_patientcustomer_insurance_self_update_RAW_URL
     * @param data - 
     * @param patientCustomerUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const organization_patientcustomer_insurance_self_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  if (parameters['patientCustomerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientCustomerUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_patientcustomer_insurance_self_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
}
export const organization_patientcustomer_insurance_self_update_TYPE = function() {
  return 'put'
}
export const organization_patientcustomer_insurance_self_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Este modelo fue diseñado para ser usado por la organización, por lo que
debería quedar oculto para el usuario final. No obstante por necesidades
en el front, se hace necesario que un paciente vea estos datos. En concreto
para permitir al paciente hacer clientes de una aseguradora en PDV.
* request: organization_patientcustomer_insurance_self_partial_update
* url: organization_patientcustomer_insurance_self_partial_updateURL
* method: organization_patientcustomer_insurance_self_partial_update_TYPE
* raw_url: organization_patientcustomer_insurance_self_partial_update_RAW_URL
     * @param data - 
     * @param patientCustomerUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const organization_patientcustomer_insurance_self_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  if (parameters['patientCustomerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientCustomerUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_patientcustomer_insurance_self_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
}
export const organization_patientcustomer_insurance_self_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_patientcustomer_insurance_self_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Este modelo fue diseñado para ser usado por la organización, por lo que
debería quedar oculto para el usuario final. No obstante por necesidades
en el front, se hace necesario que un paciente vea estos datos. En concreto
para permitir al paciente hacer clientes de una aseguradora en PDV.
* request: organization_patientcustomer_insurance_self_delete
* url: organization_patientcustomer_insurance_self_deleteURL
* method: organization_patientcustomer_insurance_self_delete_TYPE
* raw_url: organization_patientcustomer_insurance_self_delete_RAW_URL
     * @param patientCustomerUuid - 
     * @param role - 
     * @param roleUuid - 
*/
export const organization_patientcustomer_insurance_self_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  if (parameters['patientCustomerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: patientCustomerUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_patientcustomer_insurance_self_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
}
export const organization_patientcustomer_insurance_self_delete_TYPE = function() {
  return 'delete'
}
export const organization_patientcustomer_insurance_self_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/patientcustomer/insurance/self/{patient_customer_uuid}/'
  path = path.replace('{patient_customer_uuid}', `${parameters['patientCustomerUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de farmacias (Organization).
 * request: organization_pharmacy_list
 * url: organization_pharmacy_listURL
 * method: organization_pharmacy_list_TYPE
 * raw_url: organization_pharmacy_list_RAW_URL
 * @param uuid - 
 * @param publicName - Public name
 * @param country - 
 * @param town - 
 * @param colony - 
 * @param address - 
 * @param legalName - 
 * @param withPubPricesToCountry - Pharmacies with pub prices
 * @param distance - Filter by organization with presence near to point.Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param insuranceAgainstUser - Filtra aseguradoras que están conectadas (sgmm) (patientcustomer) con el usuario actual
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/'
}
export const organization_pharmacy_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/'
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Creación de Organization de tipo farmacia.
 * request: organization_pharmacy_create
 * url: organization_pharmacy_createURL
 * method: organization_pharmacy_create_TYPE
 * raw_url: organization_pharmacy_create_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/'
}
export const organization_pharmacy_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_public_read
 * url: organization_pharmacy_public_readURL
 * method: organization_pharmacy_public_read_TYPE
 * raw_url: organization_pharmacy_public_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_pharmacy_public_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/public/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_public_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/public/{uuid}/'
}
export const organization_pharmacy_public_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_public_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/public/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_list
 * url: organization_pharmacy_carrier_listURL
 * method: organization_pharmacy_carrier_list_TYPE
 * raw_url: organization_pharmacy_carrier_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/'
}
export const organization_pharmacy_carrier_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_carrier_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_create
 * url: organization_pharmacy_carrier_createURL
 * method: organization_pharmacy_carrier_create_TYPE
 * raw_url: organization_pharmacy_carrier_create_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/'
}
export const organization_pharmacy_carrier_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_carrier_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_read
 * url: organization_pharmacy_carrier_readURL
 * method: organization_pharmacy_carrier_read_TYPE
 * raw_url: organization_pharmacy_carrier_read_RAW_URL
 * @param cUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
}
export const organization_pharmacy_carrier_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_carrier_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_update
 * url: organization_pharmacy_carrier_updateURL
 * method: organization_pharmacy_carrier_update_TYPE
 * raw_url: organization_pharmacy_carrier_update_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
}
export const organization_pharmacy_carrier_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_carrier_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_partial_update
 * url: organization_pharmacy_carrier_partial_updateURL
 * method: organization_pharmacy_carrier_partial_update_TYPE
 * raw_url: organization_pharmacy_carrier_partial_update_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
}
export const organization_pharmacy_carrier_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_carrier_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delete
 * url: organization_pharmacy_carrier_deleteURL
 * method: organization_pharmacy_carrier_delete_TYPE
 * raw_url: organization_pharmacy_carrier_delete_RAW_URL
 * @param cUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
}
export const organization_pharmacy_carrier_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_carrier_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_list
 * url: organization_pharmacy_carrier_delivery_listURL
 * method: organization_pharmacy_carrier_delivery_list_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param cUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/'
}
export const organization_pharmacy_carrier_delivery_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_carrier_delivery_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_create
 * url: organization_pharmacy_carrier_delivery_createURL
 * method: organization_pharmacy_carrier_delivery_create_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_create_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/'
}
export const organization_pharmacy_carrier_delivery_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_carrier_delivery_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_read
 * url: organization_pharmacy_carrier_delivery_readURL
 * method: organization_pharmacy_carrier_delivery_read_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_read_RAW_URL
 * @param cUuid - 
 * @param cdUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
}
export const organization_pharmacy_carrier_delivery_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_carrier_delivery_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_update
 * url: organization_pharmacy_carrier_delivery_updateURL
 * method: organization_pharmacy_carrier_delivery_update_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_update_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param cdUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
}
export const organization_pharmacy_carrier_delivery_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_carrier_delivery_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_partial_update
 * url: organization_pharmacy_carrier_delivery_partial_updateURL
 * method: organization_pharmacy_carrier_delivery_partial_update_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_partial_update_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param cdUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
}
export const organization_pharmacy_carrier_delivery_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_carrier_delivery_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_delete
 * url: organization_pharmacy_carrier_delivery_deleteURL
 * method: organization_pharmacy_carrier_delivery_delete_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_delete_RAW_URL
 * @param cUuid - 
 * @param cdUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
}
export const organization_pharmacy_carrier_delivery_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_carrier_delivery_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_cost_list
 * url: organization_pharmacy_carrier_delivery_cost_listURL
 * method: organization_pharmacy_carrier_delivery_cost_list_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_cost_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param cUuid - 
 * @param cdUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_cost_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_cost_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/'
}
export const organization_pharmacy_carrier_delivery_cost_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_carrier_delivery_cost_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_cost_create
 * url: organization_pharmacy_carrier_delivery_cost_createURL
 * method: organization_pharmacy_carrier_delivery_cost_create_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_cost_create_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param cdUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_cost_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_cost_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/'
}
export const organization_pharmacy_carrier_delivery_cost_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_carrier_delivery_cost_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_cost_read
 * url: organization_pharmacy_carrier_delivery_cost_readURL
 * method: organization_pharmacy_carrier_delivery_cost_read_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_cost_read_RAW_URL
 * @param cUuid - 
 * @param cdUuid - 
 * @param dcUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_cost_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  if (parameters['dcUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dcUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_cost_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
}
export const organization_pharmacy_carrier_delivery_cost_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_carrier_delivery_cost_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_cost_update
 * url: organization_pharmacy_carrier_delivery_cost_updateURL
 * method: organization_pharmacy_carrier_delivery_cost_update_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_cost_update_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param cdUuid - 
 * @param dcUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_cost_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  if (parameters['dcUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dcUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_cost_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
}
export const organization_pharmacy_carrier_delivery_cost_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_carrier_delivery_cost_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_cost_partial_update
 * url: organization_pharmacy_carrier_delivery_cost_partial_updateURL
 * method: organization_pharmacy_carrier_delivery_cost_partial_update_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_cost_partial_update_RAW_URL
 * @param data - 
 * @param cUuid - 
 * @param cdUuid - 
 * @param dcUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_cost_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  if (parameters['dcUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dcUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_cost_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
}
export const organization_pharmacy_carrier_delivery_cost_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_carrier_delivery_cost_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_carrier_delivery_cost_delete
 * url: organization_pharmacy_carrier_delivery_cost_deleteURL
 * method: organization_pharmacy_carrier_delivery_cost_delete_TYPE
 * raw_url: organization_pharmacy_carrier_delivery_cost_delete_RAW_URL
 * @param cUuid - 
 * @param cdUuid - 
 * @param dcUuid - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_carrier_delivery_cost_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  if (parameters['cUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cUuid'))
  }
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  if (parameters['cdUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cdUuid'))
  }
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  if (parameters['dcUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dcUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_carrier_delivery_cost_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
}
export const organization_pharmacy_carrier_delivery_cost_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_carrier_delivery_cost_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/carrier/{c_uuid}/delivery/{cd_uuid}/cost/{dc_uuid}/'
  path = path.replace('{c_uuid}', `${parameters['cUuid']}`)
  path = path.replace('{cd_uuid}', `${parameters['cdUuid']}`)
  path = path.replace('{dc_uuid}', `${parameters['dcUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_users_list
 * url: organization_pharmacy_users_listURL
 * method: organization_pharmacy_users_list_TYPE
 * raw_url: organization_pharmacy_users_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_users_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_users_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/'
}
export const organization_pharmacy_users_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_users_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_users_create
 * url: organization_pharmacy_users_createURL
 * method: organization_pharmacy_users_create_TYPE
 * raw_url: organization_pharmacy_users_create_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_users_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_users_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/'
}
export const organization_pharmacy_users_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_users_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_users_read
 * url: organization_pharmacy_users_readURL
 * method: organization_pharmacy_users_read_TYPE
 * raw_url: organization_pharmacy_users_read_RAW_URL
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param uUuid - 
 */
export const organization_pharmacy_users_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters['uUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_users_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
}
export const organization_pharmacy_users_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_users_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_users_update
 * url: organization_pharmacy_users_updateURL
 * method: organization_pharmacy_users_update_TYPE
 * raw_url: organization_pharmacy_users_update_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param uUuid - 
 */
export const organization_pharmacy_users_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters['uUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_users_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
}
export const organization_pharmacy_users_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_users_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_users_partial_update
 * url: organization_pharmacy_users_partial_updateURL
 * method: organization_pharmacy_users_partial_update_TYPE
 * raw_url: organization_pharmacy_users_partial_update_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param uUuid - 
 */
export const organization_pharmacy_users_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters['uUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_users_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
}
export const organization_pharmacy_users_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_users_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_users_delete
 * url: organization_pharmacy_users_deleteURL
 * method: organization_pharmacy_users_delete_TYPE
 * raw_url: organization_pharmacy_users_delete_RAW_URL
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 * @param uUuid - 
 */
export const organization_pharmacy_users_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters['uUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_users_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
}
export const organization_pharmacy_users_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_users_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{o_uuid}/users/{u_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{u_uuid}', `${parameters['uUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List products availabe for buy from pharmacy and their prices
 * request: organization_pharmacy_drugs_in_list
 * url: organization_pharmacy_drugs_in_listURL
 * method: organization_pharmacy_drugs_in_list_TYPE
 * raw_url: organization_pharmacy_drugs_in_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param pUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_drugs_in_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/drugs/in/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_drugs_in_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/drugs/in/'
}
export const organization_pharmacy_drugs_in_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_drugs_in_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/drugs/in/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_list
 * url: organization_pharmacy_insurance_recipe_listURL
 * method: organization_pharmacy_insurance_recipe_list_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param pUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/'
}
export const organization_pharmacy_insurance_recipe_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_insurance_recipe_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_create
 * url: organization_pharmacy_insurance_recipe_createURL
 * method: organization_pharmacy_insurance_recipe_create_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param pUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/'
}
export const organization_pharmacy_insurance_recipe_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_insurance_recipe_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_read
 * url: organization_pharmacy_insurance_recipe_readURL
 * method: organization_pharmacy_insurance_recipe_read_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_read_RAW_URL
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
}
export const organization_pharmacy_insurance_recipe_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_insurance_recipe_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_update
 * url: organization_pharmacy_insurance_recipe_updateURL
 * method: organization_pharmacy_insurance_recipe_update_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
}
export const organization_pharmacy_insurance_recipe_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_insurance_recipe_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_partial_update
 * url: organization_pharmacy_insurance_recipe_partial_updateURL
 * method: organization_pharmacy_insurance_recipe_partial_update_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_partial_update_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
}
export const organization_pharmacy_insurance_recipe_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_insurance_recipe_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_delete
 * url: organization_pharmacy_insurance_recipe_deleteURL
 * method: organization_pharmacy_insurance_recipe_delete_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_delete_RAW_URL
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
}
export const organization_pharmacy_insurance_recipe_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_insurance_recipe_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_drug_list
 * url: organization_pharmacy_insurance_recipe_drug_listURL
 * method: organization_pharmacy_insurance_recipe_drug_list_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_drug_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_drug_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/'
}
export const organization_pharmacy_insurance_recipe_drug_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_insurance_recipe_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_drug_create
 * url: organization_pharmacy_insurance_recipe_drug_createURL
 * method: organization_pharmacy_insurance_recipe_drug_create_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_drug_create_RAW_URL
 * @param data - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_drug_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_drug_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/'
}
export const organization_pharmacy_insurance_recipe_drug_create_TYPE = function() {
  return 'post'
}
export const organization_pharmacy_insurance_recipe_drug_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/'
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_drug_read
 * url: organization_pharmacy_insurance_recipe_drug_readURL
 * method: organization_pharmacy_insurance_recipe_drug_read_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_drug_read_RAW_URL
 * @param dUuid - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_drug_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
}
export const organization_pharmacy_insurance_recipe_drug_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_insurance_recipe_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_drug_update
 * url: organization_pharmacy_insurance_recipe_drug_updateURL
 * method: organization_pharmacy_insurance_recipe_drug_update_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_drug_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_drug_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_drug_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
}
export const organization_pharmacy_insurance_recipe_drug_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_insurance_recipe_drug_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_drug_partial_update
 * url: organization_pharmacy_insurance_recipe_drug_partial_updateURL
 * method: organization_pharmacy_insurance_recipe_drug_partial_update_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_drug_partial_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_drug_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_drug_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
}
export const organization_pharmacy_insurance_recipe_drug_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_insurance_recipe_drug_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_insurance_recipe_drug_delete
 * url: organization_pharmacy_insurance_recipe_drug_deleteURL
 * method: organization_pharmacy_insurance_recipe_drug_delete_TYPE
 * raw_url: organization_pharmacy_insurance_recipe_drug_delete_RAW_URL
 * @param dUuid - 
 * @param iUuid - 
 * @param pUuid - 
 * @param rUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_insurance_recipe_drug_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  if (parameters['iUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iUuid'))
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  if (parameters['rUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_insurance_recipe_drug_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
}
export const organization_pharmacy_insurance_recipe_drug_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_insurance_recipe_drug_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/insurance/{i_uuid}/recipe/{r_uuid}/drug/{d_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{i_uuid}', `${parameters['iUuid']}`)
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{r_uuid}', `${parameters['rUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_user_roles_list
 * url: organization_pharmacy_user_roles_listURL
 * method: organization_pharmacy_user_roles_list_TYPE
 * raw_url: organization_pharmacy_user_roles_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param pUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pharmacy_user_roles_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/user_roles/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  if (parameters['pUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_user_roles_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/user_roles/'
}
export const organization_pharmacy_user_roles_list_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_user_roles_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{p_uuid}/user_roles/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{p_uuid}', `${parameters['pUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_read
 * url: organization_pharmacy_readURL
 * method: organization_pharmacy_read_TYPE
 * raw_url: organization_pharmacy_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_pharmacy_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
}
export const organization_pharmacy_read_TYPE = function() {
  return 'get'
}
export const organization_pharmacy_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_update
 * url: organization_pharmacy_updateURL
 * method: organization_pharmacy_update_TYPE
 * raw_url: organization_pharmacy_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_pharmacy_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
}
export const organization_pharmacy_update_TYPE = function() {
  return 'put'
}
export const organization_pharmacy_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_partial_update
 * url: organization_pharmacy_partial_updateURL
 * method: organization_pharmacy_partial_update_TYPE
 * raw_url: organization_pharmacy_partial_update_RAW_URL
 * @param data - 
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_pharmacy_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
}
export const organization_pharmacy_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pharmacy_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pharmacy_delete
 * url: organization_pharmacy_deleteURL
 * method: organization_pharmacy_delete_TYPE
 * raw_url: organization_pharmacy_delete_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_pharmacy_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pharmacy_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
}
export const organization_pharmacy_delete_TYPE = function() {
  return 'delete'
}
export const organization_pharmacy_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/pharmacy/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_restaurant_list
 * url: organization_protected_restaurant_listURL
 * method: organization_protected_restaurant_list_TYPE
 * raw_url: organization_protected_restaurant_list_RAW_URL
 * @param country - 
 * @param postalCode - 
 * @param search - A search term.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_restaurant_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/restaurant/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['postalCode'] !== undefined) {
    queryParameters['postal_code'] = parameters['postalCode']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_restaurant_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/restaurant/'
}
export const organization_protected_restaurant_list_TYPE = function() {
  return 'get'
}
export const organization_protected_restaurant_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/restaurant/'
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['postalCode'] !== undefined) {
    queryParameters['postal_code'] = parameters['postalCode']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_restaurant_read
 * url: organization_protected_restaurant_readURL
 * method: organization_protected_restaurant_read_TYPE
 * raw_url: organization_protected_restaurant_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_protected_restaurant_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/restaurant/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_restaurant_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/restaurant/{uuid}/'
}
export const organization_protected_restaurant_read_TYPE = function() {
  return 'get'
}
export const organization_protected_restaurant_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/restaurant/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de productos dentro de un catálogo de organización (Organization).
 * request: organization_protected_catalogue_product_list
 * url: organization_protected_catalogue_product_listURL
 * method: organization_protected_catalogue_product_list_TYPE
 * raw_url: organization_protected_catalogue_product_list_RAW_URL
 * @param medikadescription - Description field
 * @param ean - Ean field
 * @param medikacode - medikacode field
 * @param divisionShortName - DivisionShortName field
 * @param attrssize - Attr size field
 * @param ordering - Which field to use when ordering the results.
 * @param search - A search term.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param catalogueType - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_catalogue_product_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['medikadescription'] !== undefined) {
    queryParameters['medikadescription'] = parameters['medikadescription']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['medikacode'] !== undefined) {
    queryParameters['medikacode'] = parameters['medikacode']
  }
  if (parameters['divisionShortName'] !== undefined) {
    queryParameters['DivisionShortName'] = parameters['divisionShortName']
  }
  if (parameters['attrssize'] !== undefined) {
    queryParameters['Attrssize'] = parameters['attrssize']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  if (parameters['catalogueType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: catalogueType'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_catalogue_product_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/'
}
export const organization_protected_catalogue_product_list_TYPE = function() {
  return 'get'
}
export const organization_protected_catalogue_product_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/'
  if (parameters['medikadescription'] !== undefined) {
    queryParameters['medikadescription'] = parameters['medikadescription']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['medikacode'] !== undefined) {
    queryParameters['medikacode'] = parameters['medikacode']
  }
  if (parameters['divisionShortName'] !== undefined) {
    queryParameters['DivisionShortName'] = parameters['divisionShortName']
  }
  if (parameters['attrssize'] !== undefined) {
    queryParameters['Attrssize'] = parameters['attrssize']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_catalogue_product_read
 * url: organization_protected_catalogue_product_readURL
 * method: organization_protected_catalogue_product_read_TYPE
 * raw_url: organization_protected_catalogue_product_read_RAW_URL
 * @param catalogueType - 
 * @param organizationUuid - 
 * @param productMdkid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_catalogue_product_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  if (parameters['catalogueType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: catalogueType'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  if (parameters['productMdkid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: productMdkid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_catalogue_product_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
}
export const organization_protected_catalogue_product_read_TYPE = function() {
  return 'get'
}
export const organization_protected_catalogue_product_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_catalogue_product_update
 * url: organization_protected_catalogue_product_updateURL
 * method: organization_protected_catalogue_product_update_TYPE
 * raw_url: organization_protected_catalogue_product_update_RAW_URL
 * @param data - 
 * @param catalogueType - 
 * @param organizationUuid - 
 * @param productMdkid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_catalogue_product_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  if (parameters['catalogueType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: catalogueType'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  if (parameters['productMdkid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: productMdkid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_protected_catalogue_product_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
}
export const organization_protected_catalogue_product_update_TYPE = function() {
  return 'put'
}
export const organization_protected_catalogue_product_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_catalogue_product_partial_update
 * url: organization_protected_catalogue_product_partial_updateURL
 * method: organization_protected_catalogue_product_partial_update_TYPE
 * raw_url: organization_protected_catalogue_product_partial_update_RAW_URL
 * @param data - 
 * @param catalogueType - 
 * @param organizationUuid - 
 * @param productMdkid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_catalogue_product_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  if (parameters['catalogueType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: catalogueType'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  if (parameters['productMdkid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: productMdkid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_protected_catalogue_product_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
}
export const organization_protected_catalogue_product_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_protected_catalogue_product_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_catalogue_product_delete
 * url: organization_protected_catalogue_product_deleteURL
 * method: organization_protected_catalogue_product_delete_TYPE
 * raw_url: organization_protected_catalogue_product_delete_RAW_URL
 * @param catalogueType - 
 * @param organizationUuid - 
 * @param productMdkid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_catalogue_product_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  if (parameters['catalogueType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: catalogueType'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  if (parameters['productMdkid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: productMdkid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_protected_catalogue_product_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
}
export const organization_protected_catalogue_product_delete_TYPE = function() {
  return 'delete'
}
export const organization_protected_catalogue_product_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/catalogue/{catalogue_type}/product/{product_mdkid}/'
  path = path.replace('{catalogue_type}', `${parameters['catalogueType']}`)
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{product_mdkid}', `${parameters['productMdkid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Listado de farmacias (Organization).
 * request: organization_protected_presence_list
 * url: organization_protected_presence_listURL
 * method: organization_protected_presence_list_TYPE
 * raw_url: organization_protected_presence_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_presence_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_presence_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/'
}
export const organization_protected_presence_list_TYPE = function() {
  return 'get'
}
export const organization_protected_presence_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Creación de Organization de tipo farmacia.
 * request: organization_protected_presence_create
 * url: organization_protected_presence_createURL
 * method: organization_protected_presence_create_TYPE
 * raw_url: organization_protected_presence_create_RAW_URL
 * @param data - 
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_presence_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_protected_presence_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/'
}
export const organization_protected_presence_create_TYPE = function() {
  return 'post'
}
export const organization_protected_presence_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/'
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_presence_read
 * url: organization_protected_presence_readURL
 * method: organization_protected_presence_read_TYPE
 * raw_url: organization_protected_presence_read_RAW_URL
 * @param organizationUuid - 
 * @param presenceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_presence_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  if (parameters['presenceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: presenceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_presence_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
}
export const organization_protected_presence_read_TYPE = function() {
  return 'get'
}
export const organization_protected_presence_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_presence_update
 * url: organization_protected_presence_updateURL
 * method: organization_protected_presence_update_TYPE
 * raw_url: organization_protected_presence_update_RAW_URL
 * @param data - 
 * @param organizationUuid - 
 * @param presenceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_presence_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  if (parameters['presenceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: presenceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_protected_presence_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
}
export const organization_protected_presence_update_TYPE = function() {
  return 'put'
}
export const organization_protected_presence_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_presence_partial_update
 * url: organization_protected_presence_partial_updateURL
 * method: organization_protected_presence_partial_update_TYPE
 * raw_url: organization_protected_presence_partial_update_RAW_URL
 * @param data - 
 * @param organizationUuid - 
 * @param presenceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_presence_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  if (parameters['presenceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: presenceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_protected_presence_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
}
export const organization_protected_presence_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_protected_presence_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_presence_delete
 * url: organization_protected_presence_deleteURL
 * method: organization_protected_presence_delete_TYPE
 * raw_url: organization_protected_presence_delete_RAW_URL
 * @param organizationUuid - 
 * @param presenceUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_presence_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  if (parameters['presenceUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: presenceUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_protected_presence_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
}
export const organization_protected_presence_delete_TYPE = function() {
  return 'delete'
}
export const organization_protected_presence_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/presence/{presence_uuid}/'
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{presence_uuid}', `${parameters['presenceUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_protected_provider_list
 * url: organization_protected_provider_listURL
 * method: organization_protected_provider_list_TYPE
 * raw_url: organization_protected_provider_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param organizationUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_protected_provider_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/provider/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  if (parameters['organizationUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_protected_provider_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/protected/{organization_uuid}/provider/'
}
export const organization_protected_provider_list_TYPE = function() {
  return 'get'
}
export const organization_protected_provider_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/protected/{organization_uuid}/provider/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{organization_uuid}', `${parameters['organizationUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_restaurant_list
 * url: organization_restaurant_listURL
 * method: organization_restaurant_list_TYPE
 * raw_url: organization_restaurant_list_RAW_URL
 * @param uuid - 
 * @param publicName - Public name
 * @param country - 
 * @param town - 
 * @param colony - 
 * @param address - 
 * @param legalName - 
 * @param withPubPricesToCountry - Pharmacies with pub prices
 * @param distance - Filter by organization with presence near to point.Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param insuranceAgainstUser - Filtra aseguradoras que están conectadas (sgmm) (patientcustomer) con el usuario actual
 * @param search - A search term.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const organization_restaurant_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/restaurant/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_restaurant_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/restaurant/'
}
export const organization_restaurant_list_TYPE = function() {
  return 'get'
}
export const organization_restaurant_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/restaurant/'
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_restaurant_read
 * url: organization_restaurant_readURL
 * method: organization_restaurant_read_TYPE
 * raw_url: organization_restaurant_read_RAW_URL
 * @param role - 
 * @param roleUuid - 
 * @param uuid - 
 */
export const organization_restaurant_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/restaurant/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_restaurant_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/restaurant/{uuid}/'
}
export const organization_restaurant_read_TYPE = function() {
  return 'get'
}
export const organization_restaurant_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/restaurant/{uuid}/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pricelists
 * request: organization_pricelist_list
 * url: organization_pricelist_listURL
 * method: organization_pricelist_list_TYPE
 * raw_url: organization_pricelist_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/'
}
export const organization_pricelist_list_TYPE = function() {
  return 'get'
}
export const organization_pricelist_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pricelists
 * request: organization_pricelist_create
 * url: organization_pricelist_createURL
 * method: organization_pricelist_create_TYPE
 * raw_url: organization_pricelist_create_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/'
}
export const organization_pricelist_create_TYPE = function() {
  return 'post'
}
export const organization_pricelist_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_read
 * url: organization_pricelist_readURL
 * method: organization_pricelist_read_TYPE
 * raw_url: organization_pricelist_read_RAW_URL
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
}
export const organization_pricelist_read_TYPE = function() {
  return 'get'
}
export const organization_pricelist_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_update
 * url: organization_pricelist_updateURL
 * method: organization_pricelist_update_TYPE
 * raw_url: organization_pricelist_update_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
}
export const organization_pricelist_update_TYPE = function() {
  return 'put'
}
export const organization_pricelist_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_partial_update
 * url: organization_pricelist_partial_updateURL
 * method: organization_pricelist_partial_update_TYPE
 * raw_url: organization_pricelist_partial_update_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
}
export const organization_pricelist_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pricelist_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_delete
 * url: organization_pricelist_deleteURL
 * method: organization_pricelist_delete_TYPE
 * raw_url: organization_pricelist_delete_RAW_URL
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
}
export const organization_pricelist_delete_TYPE = function() {
  return 'delete'
}
export const organization_pricelist_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pricelists.
 * request: organization_pricelist_destination_list
 * url: organization_pricelist_destination_listURL
 * method: organization_pricelist_destination_list_TYPE
 * raw_url: organization_pricelist_destination_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_destination_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_destination_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/'
}
export const organization_pricelist_destination_list_TYPE = function() {
  return 'get'
}
export const organization_pricelist_destination_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pricelists.
 * request: organization_pricelist_destination_create
 * url: organization_pricelist_destination_createURL
 * method: organization_pricelist_destination_create_TYPE
 * raw_url: organization_pricelist_destination_create_RAW_URL
 * @param data - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_destination_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_destination_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/'
}
export const organization_pricelist_destination_create_TYPE = function() {
  return 'post'
}
export const organization_pricelist_destination_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/'
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_destination_read
 * url: organization_pricelist_destination_readURL
 * method: organization_pricelist_destination_read_TYPE
 * raw_url: organization_pricelist_destination_read_RAW_URL
 * @param dstUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_destination_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  if (parameters['dstUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dstUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_destination_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
}
export const organization_pricelist_destination_read_TYPE = function() {
  return 'get'
}
export const organization_pricelist_destination_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_destination_update
 * url: organization_pricelist_destination_updateURL
 * method: organization_pricelist_destination_update_TYPE
 * raw_url: organization_pricelist_destination_update_RAW_URL
 * @param data - 
 * @param dstUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_destination_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  if (parameters['dstUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dstUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_destination_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
}
export const organization_pricelist_destination_update_TYPE = function() {
  return 'put'
}
export const organization_pricelist_destination_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_destination_partial_update
 * url: organization_pricelist_destination_partial_updateURL
 * method: organization_pricelist_destination_partial_update_TYPE
 * raw_url: organization_pricelist_destination_partial_update_RAW_URL
 * @param data - 
 * @param dstUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_destination_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  if (parameters['dstUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dstUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_destination_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
}
export const organization_pricelist_destination_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pricelist_destination_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_destination_delete
 * url: organization_pricelist_destination_deleteURL
 * method: organization_pricelist_destination_delete_TYPE
 * raw_url: organization_pricelist_destination_delete_RAW_URL
 * @param dstUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_destination_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  if (parameters['dstUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dstUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_destination_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
}
export const organization_pricelist_destination_delete_TYPE = function() {
  return 'delete'
}
export const organization_pricelist_destination_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/destination/{dst_uuid}/'
  path = path.replace('{dst_uuid}', `${parameters['dstUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create prices in a pricelist.
 * request: organization_pricelist_drug_price_list
 * url: organization_pricelist_drug_price_listURL
 * method: organization_pricelist_drug_price_list_TYPE
 * raw_url: organization_pricelist_drug_price_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param dUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_drug_price_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_drug_price_list_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/'
}
export const organization_pricelist_drug_price_list_TYPE = function() {
  return 'get'
}
export const organization_pricelist_drug_price_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create prices in a pricelist.
 * request: organization_pricelist_drug_price_create
 * url: organization_pricelist_drug_price_createURL
 * method: organization_pricelist_drug_price_create_TYPE
 * raw_url: organization_pricelist_drug_price_create_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_drug_price_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_drug_price_create_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/'
}
export const organization_pricelist_drug_price_create_TYPE = function() {
  return 'post'
}
export const organization_pricelist_drug_price_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_drug_price_read
 * url: organization_pricelist_drug_price_readURL
 * method: organization_pricelist_drug_price_read_TYPE
 * raw_url: organization_pricelist_drug_price_read_RAW_URL
 * @param dUuid - 
 * @param dpUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_drug_price_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  if (parameters['dpUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dpUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_drug_price_read_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
}
export const organization_pricelist_drug_price_read_TYPE = function() {
  return 'get'
}
export const organization_pricelist_drug_price_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_drug_price_update
 * url: organization_pricelist_drug_price_updateURL
 * method: organization_pricelist_drug_price_update_TYPE
 * raw_url: organization_pricelist_drug_price_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param dpUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_drug_price_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  if (parameters['dpUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dpUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_drug_price_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
}
export const organization_pricelist_drug_price_update_TYPE = function() {
  return 'put'
}
export const organization_pricelist_drug_price_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_drug_price_partial_update
 * url: organization_pricelist_drug_price_partial_updateURL
 * method: organization_pricelist_drug_price_partial_update_TYPE
 * raw_url: organization_pricelist_drug_price_partial_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param dpUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_drug_price_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  if (parameters['dpUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dpUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_drug_price_partial_update_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
}
export const organization_pricelist_drug_price_partial_update_TYPE = function() {
  return 'patch'
}
export const organization_pricelist_drug_price_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: organization_pricelist_drug_price_delete
 * url: organization_pricelist_drug_price_deleteURL
 * method: organization_pricelist_drug_price_delete_TYPE
 * raw_url: organization_pricelist_drug_price_delete_RAW_URL
 * @param dUuid - 
 * @param dpUuid - 
 * @param oUuid - 
 * @param plUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const organization_pricelist_drug_price_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  if (parameters['dpUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dpUuid'))
  }
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  if (parameters['oUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oUuid'))
  }
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  if (parameters['plUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: plUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const organization_pricelist_drug_price_delete_RAW_URL = function() {
  return '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
}
export const organization_pricelist_drug_price_delete_TYPE = function() {
  return 'delete'
}
export const organization_pricelist_drug_price_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organization/{role}/{role_uuid}/{o_uuid}/pricelist/{pl_uuid}/drug/{d_uuid}/price/{dp_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{dp_uuid}', `${parameters['dpUuid']}`)
  path = path.replace('{o_uuid}', `${parameters['oUuid']}`)
  path = path.replace('{pl_uuid}', `${parameters['plUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Gets the Serial Numbers for Passes Associated with a Device
 * request: passes_v1_devices_registrations_list
 * url: passes_v1_devices_registrations_listURL
 * method: passes_v1_devices_registrations_list_TYPE
 * raw_url: passes_v1_devices_registrations_list_RAW_URL
 * @param deviceLibraryId - 
 * @param passTypeId - 
 */
export const passes_v1_devices_registrations_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{device_library_id}', `${parameters['deviceLibraryId']}`)
  if (parameters['deviceLibraryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: deviceLibraryId'))
  }
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  if (parameters['passTypeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passTypeId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const passes_v1_devices_registrations_list_RAW_URL = function() {
  return '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}'
}
export const passes_v1_devices_registrations_list_TYPE = function() {
  return 'get'
}
export const passes_v1_devices_registrations_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}'
  path = path.replace('{device_library_id}', `${parameters['deviceLibraryId']}`)
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: passes_v1_devices_registrations_create
 * url: passes_v1_devices_registrations_createURL
 * method: passes_v1_devices_registrations_create_TYPE
 * raw_url: passes_v1_devices_registrations_create_RAW_URL
 * @param deviceLibraryId - 
 * @param passTypeId - 
 * @param serialNumber - 
 */
export const passes_v1_devices_registrations_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}/{serial_number}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{device_library_id}', `${parameters['deviceLibraryId']}`)
  if (parameters['deviceLibraryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: deviceLibraryId'))
  }
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  if (parameters['passTypeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passTypeId'))
  }
  path = path.replace('{serial_number}', `${parameters['serialNumber']}`)
  if (parameters['serialNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: serialNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const passes_v1_devices_registrations_create_RAW_URL = function() {
  return '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}/{serial_number}'
}
export const passes_v1_devices_registrations_create_TYPE = function() {
  return 'post'
}
export const passes_v1_devices_registrations_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}/{serial_number}'
  path = path.replace('{device_library_id}', `${parameters['deviceLibraryId']}`)
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  path = path.replace('{serial_number}', `${parameters['serialNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Unregister a Device
 * request: passes_v1_devices_registrations_delete
 * url: passes_v1_devices_registrations_deleteURL
 * method: passes_v1_devices_registrations_delete_TYPE
 * raw_url: passes_v1_devices_registrations_delete_RAW_URL
 * @param deviceLibraryId - 
 * @param passTypeId - 
 * @param serialNumber - 
 */
export const passes_v1_devices_registrations_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}/{serial_number}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{device_library_id}', `${parameters['deviceLibraryId']}`)
  if (parameters['deviceLibraryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: deviceLibraryId'))
  }
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  if (parameters['passTypeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passTypeId'))
  }
  path = path.replace('{serial_number}', `${parameters['serialNumber']}`)
  if (parameters['serialNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: serialNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const passes_v1_devices_registrations_delete_RAW_URL = function() {
  return '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}/{serial_number}'
}
export const passes_v1_devices_registrations_delete_TYPE = function() {
  return 'delete'
}
export const passes_v1_devices_registrations_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/passes/v1/devices/{device_library_id}/registrations/{pass_type_id}/{serial_number}'
  path = path.replace('{device_library_id}', `${parameters['deviceLibraryId']}`)
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  path = path.replace('{serial_number}', `${parameters['serialNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Logs messages from devices
 * request: passes_v1_log_create
 * url: passes_v1_log_createURL
 * method: passes_v1_log_create_TYPE
 * raw_url: passes_v1_log_create_RAW_URL
 */
export const passes_v1_log_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/passes/v1/log'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const passes_v1_log_create_RAW_URL = function() {
  return '/passes/v1/log'
}
export const passes_v1_log_create_TYPE = function() {
  return 'post'
}
export const passes_v1_log_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/passes/v1/log'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: passes_v1_passes_read
 * url: passes_v1_passes_readURL
 * method: passes_v1_passes_read_TYPE
 * raw_url: passes_v1_passes_read_RAW_URL
 * @param passTypeId - 
 * @param serialNumber - 
 */
export const passes_v1_passes_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/passes/v1/passes/{pass_type_id}/{serial_number}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  if (parameters['passTypeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passTypeId'))
  }
  path = path.replace('{serial_number}', `${parameters['serialNumber']}`)
  if (parameters['serialNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: serialNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const passes_v1_passes_read_RAW_URL = function() {
  return '/passes/v1/passes/{pass_type_id}/{serial_number}'
}
export const passes_v1_passes_read_TYPE = function() {
  return 'get'
}
export const passes_v1_passes_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/passes/v1/passes/{pass_type_id}/{serial_number}'
  path = path.replace('{pass_type_id}', `${parameters['passTypeId']}`)
  path = path.replace('{serial_number}', `${parameters['serialNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List best seller products
 * request: phdrug_best_seller_list
 * url: phdrug_best_seller_listURL
 * method: phdrug_best_seller_list_TYPE
 * raw_url: phdrug_best_seller_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_best_seller_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/best-seller/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_best_seller_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/best-seller/'
}
export const phdrug_best_seller_list_TYPE = function() {
  return 'get'
}
export const phdrug_best_seller_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/best-seller/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List of drug categories, new generation
 * request: phdrug_categories_ng_list
 * url: phdrug_categories_ng_listURL
 * method: phdrug_categories_ng_list_TYPE
 * raw_url: phdrug_categories_ng_list_RAW_URL
 * @param name - 
 * @param uuid - 
 * @param menuHeader - 
 * @param department - Department id
 * @param site - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_categories_ng_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/categories/ng/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['menuHeader'] !== undefined) {
    queryParameters['menu_header'] = parameters['menuHeader']
  }
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_categories_ng_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/categories/ng/'
}
export const phdrug_categories_ng_list_TYPE = function() {
  return 'get'
}
export const phdrug_categories_ng_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/categories/ng/'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['menuHeader'] !== undefined) {
    queryParameters['menu_header'] = parameters['menuHeader']
  }
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* List drugs without country filter. Using distance filter the prices
showed will be also filtered.
* request: phdrug_collection_any_country_drug_list
* url: phdrug_collection_any_country_drug_listURL
* method: phdrug_collection_any_country_drug_list_TYPE
* raw_url: phdrug_collection_any_country_drug_list_RAW_URL
     * @param search - A search term in elasticsearch
     * @param letter - Starts with...
     * @param uuid - 
     * @param description - Description
     * @param substance - Substance
     * @param ean - 
     * @param category - Category uuid
     * @param groupedCategory - Category uuid
     * @param top - 
     * @param topHightSpeciality - 
     * @param topDerma - 
     * @param hightSpeciality - 
     * @param organization - Organization uuid
     * @param publicOrganization - Organization uuid with pricelistdestination public
     * @param updatedStartDate - 
     * @param updatedEndDate - 
     * @param brands - List of Brands uuid
     * @param topGeneric - 
     * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
     * @param ordering - Which field to use when ordering the results.
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const phdrug_collection_any_country_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/any-country-drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_any_country_drug_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/any-country-drug/'
}
export const phdrug_collection_any_country_drug_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_any_country_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/any-country-drug/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_collection_drug_list
 * url: phdrug_collection_drug_listURL
 * method: phdrug_collection_drug_list_TYPE
 * raw_url: phdrug_collection_drug_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/'
}
export const phdrug_collection_drug_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_collection_drug_brands_list
 * url: phdrug_collection_drug_brands_listURL
 * method: phdrug_collection_drug_brands_list_TYPE
 * raw_url: phdrug_collection_drug_brands_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_brands_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_brands_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/brands/'
}
export const phdrug_collection_drug_brands_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_brands_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_collection_drug_brands_bestsellers_list
 * url: phdrug_collection_drug_brands_bestsellers_listURL
 * method: phdrug_collection_drug_brands_bestsellers_list_TYPE
 * raw_url: phdrug_collection_drug_brands_bestsellers_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_brands_bestsellers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/bestsellers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_brands_bestsellers_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/brands/bestsellers/'
}
export const phdrug_collection_drug_brands_bestsellers_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_brands_bestsellers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/bestsellers/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_collection_drug_brands_news_list
 * url: phdrug_collection_drug_brands_news_listURL
 * method: phdrug_collection_drug_brands_news_list_TYPE
 * raw_url: phdrug_collection_drug_brands_news_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_brands_news_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/news/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_brands_news_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/brands/news/'
}
export const phdrug_collection_drug_brands_news_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_brands_news_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/news/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_collection_drug_brands_offers_list
 * url: phdrug_collection_drug_brands_offers_listURL
 * method: phdrug_collection_drug_brands_offers_list_TYPE
 * raw_url: phdrug_collection_drug_brands_offers_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_brands_offers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/offers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_brands_offers_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/brands/offers/'
}
export const phdrug_collection_drug_brands_offers_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_brands_offers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/offers/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_collection_drug_brands_pack_list
 * url: phdrug_collection_drug_brands_pack_listURL
 * method: phdrug_collection_drug_brands_pack_list_TYPE
 * raw_url: phdrug_collection_drug_brands_pack_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_brands_pack_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/pack/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_brands_pack_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/brands/pack/'
}
export const phdrug_collection_drug_brands_pack_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_brands_pack_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/brands/pack/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail drug
 * request: phdrug_collection_drug_read
 * url: phdrug_collection_drug_readURL
 * method: phdrug_collection_drug_read_TYPE
 * raw_url: phdrug_collection_drug_read_RAW_URL
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_read_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/'
}
export const phdrug_collection_drug_read_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List drugs bought with the given drug
 * request: phdrug_collection_drug_bought_with_list
 * url: phdrug_collection_drug_bought_with_listURL
 * method: phdrug_collection_drug_bought_with_list_TYPE
 * raw_url: phdrug_collection_drug_bought_with_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_bought_with_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/bought_with/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_bought_with_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/bought_with/'
}
export const phdrug_collection_drug_bought_with_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_bought_with_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/bought_with/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pictures for drugs
 * request: phdrug_collection_drug_picture_list
 * url: phdrug_collection_drug_picture_listURL
 * method: phdrug_collection_drug_picture_list_TYPE
 * raw_url: phdrug_collection_drug_picture_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_picture_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_picture_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
}
export const phdrug_collection_drug_picture_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_picture_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pictures for drugs
 * request: phdrug_collection_drug_picture_create
 * url: phdrug_collection_drug_picture_createURL
 * method: phdrug_collection_drug_picture_create_TYPE
 * raw_url: phdrug_collection_drug_picture_create_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_picture_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_picture_create_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
}
export const phdrug_collection_drug_picture_create_TYPE = function() {
  return 'post'
}
export const phdrug_collection_drug_picture_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: phdrug_collection_drug_picture_read
 * url: phdrug_collection_drug_picture_readURL
 * method: phdrug_collection_drug_picture_read_TYPE
 * raw_url: phdrug_collection_drug_picture_read_RAW_URL
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_picture_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_picture_read_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const phdrug_collection_drug_picture_read_TYPE = function() {
  return 'get'
}
export const phdrug_collection_drug_picture_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: phdrug_collection_drug_picture_update
 * url: phdrug_collection_drug_picture_updateURL
 * method: phdrug_collection_drug_picture_update_TYPE
 * raw_url: phdrug_collection_drug_picture_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_picture_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_picture_update_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const phdrug_collection_drug_picture_update_TYPE = function() {
  return 'put'
}
export const phdrug_collection_drug_picture_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: phdrug_collection_drug_picture_partial_update
 * url: phdrug_collection_drug_picture_partial_updateURL
 * method: phdrug_collection_drug_picture_partial_update_TYPE
 * raw_url: phdrug_collection_drug_picture_partial_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_picture_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_picture_partial_update_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const phdrug_collection_drug_picture_partial_update_TYPE = function() {
  return 'patch'
}
export const phdrug_collection_drug_picture_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: phdrug_collection_drug_picture_delete
 * url: phdrug_collection_drug_picture_deleteURL
 * method: phdrug_collection_drug_picture_delete_TYPE
 * raw_url: phdrug_collection_drug_picture_delete_RAW_URL
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_drug_picture_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_drug_picture_delete_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const phdrug_collection_drug_picture_delete_TYPE = function() {
  return 'delete'
}
export const phdrug_collection_drug_picture_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: phdrug_collection_food_list
 * url: phdrug_collection_food_listURL
 * method: phdrug_collection_food_list_TYPE
 * raw_url: phdrug_collection_food_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_food_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/food/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_food_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/food/'
}
export const phdrug_collection_food_list_TYPE = function() {
  return 'get'
}
export const phdrug_collection_food_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/food/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail product
 * request: phdrug_collection_food_read
 * url: phdrug_collection_food_readURL
 * method: phdrug_collection_food_read_TYPE
 * raw_url: phdrug_collection_food_read_RAW_URL
 * @param fUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_collection_food_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/collection/food/{f_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{f_uuid}', `${parameters['fUuid']}`)
  if (parameters['fUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_collection_food_read_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/collection/food/{f_uuid}/'
}
export const phdrug_collection_food_read_TYPE = function() {
  return 'get'
}
export const phdrug_collection_food_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/collection/food/{f_uuid}/'
  path = path.replace('{f_uuid}', `${parameters['fUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List featured products
 * request: phdrug_featured_list
 * url: phdrug_featured_listURL
 * method: phdrug_featured_list_TYPE
 * raw_url: phdrug_featured_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_featured_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/featured/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_featured_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/featured/'
}
export const phdrug_featured_list_TYPE = function() {
  return 'get'
}
export const phdrug_featured_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/featured/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List of grouped categories
 * request: phdrug_grouped_categories_list
 * url: phdrug_grouped_categories_listURL
 * method: phdrug_grouped_categories_list_TYPE
 * raw_url: phdrug_grouped_categories_list_RAW_URL
 * @param name - 
 * @param uuid - 
 * @param site - 
 * @param orderingLangtag - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_grouped_categories_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/grouped-categories/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['orderingLangtag'] !== undefined) {
    queryParameters['ordering_langtag'] = parameters['orderingLangtag']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_grouped_categories_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/grouped-categories/'
}
export const phdrug_grouped_categories_list_TYPE = function() {
  return 'get'
}
export const phdrug_grouped_categories_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/grouped-categories/'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['orderingLangtag'] !== undefined) {
    queryParameters['ordering_langtag'] = parameters['orderingLangtag']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Mostrar todos aquellos productos que no se vendan en el país actual
 y que sean parte de una lista de precios marcada como "Exportados".
Sólo las sucursales de globalstore.
* request: phdrug_importation_list
* url: phdrug_importation_listURL
* method: phdrug_importation_list_TYPE
* raw_url: phdrug_importation_list_RAW_URL
     * @param search - A search term in elasticsearch
     * @param letter - Starts with...
     * @param uuid - 
     * @param description - Description
     * @param substance - Substance
     * @param ean - 
     * @param category - Category uuid
     * @param groupedCategory - Category uuid
     * @param top - 
     * @param topHightSpeciality - 
     * @param topDerma - 
     * @param hightSpeciality - 
     * @param organization - Organization uuid
     * @param publicOrganization - Organization uuid with pricelistdestination public
     * @param updatedStartDate - 
     * @param updatedEndDate - 
     * @param brands - List of Brands uuid
     * @param topGeneric - 
     * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const phdrug_importation_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/importation/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_importation_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/importation/'
}
export const phdrug_importation_list_TYPE = function() {
  return 'get'
}
export const phdrug_importation_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/importation/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: phdrug_macro_master_list
 * url: phdrug_macro_master_listURL
 * method: phdrug_macro_master_list_TYPE
 * raw_url: phdrug_macro_master_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_macro_master_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/macro/master/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_macro_master_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/macro/master/'
}
export const phdrug_macro_master_list_TYPE = function() {
  return 'get'
}
export const phdrug_macro_master_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/macro/master/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List new products
 * request: phdrug_new_list
 * url: phdrug_new_listURL
 * method: phdrug_new_list_TYPE
 * raw_url: phdrug_new_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_new_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/new/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_new_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/new/'
}
export const phdrug_new_list_TYPE = function() {
  return 'get'
}
export const phdrug_new_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/new/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List offers products
 * request: phdrug_offers_list
 * url: phdrug_offers_listURL
 * method: phdrug_offers_list_TYPE
 * raw_url: phdrug_offers_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_offers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/offers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_offers_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/offers/'
}
export const phdrug_offers_list_TYPE = function() {
  return 'get'
}
export const phdrug_offers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/offers/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: phdrug_offers_pack_list
 * url: phdrug_offers_pack_listURL
 * method: phdrug_offers_pack_list_TYPE
 * raw_url: phdrug_offers_pack_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_offers_pack_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/offers/pack/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_offers_pack_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/offers/pack/'
}
export const phdrug_offers_pack_list_TYPE = function() {
  return 'get'
}
export const phdrug_offers_pack_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/offers/pack/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: phdrug_top_generic_list
 * url: phdrug_top_generic_listURL
 * method: phdrug_top_generic_list_TYPE
 * raw_url: phdrug_top_generic_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const phdrug_top_generic_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/phdrug/{role}/{role_uuid}/top/generic/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const phdrug_top_generic_list_RAW_URL = function() {
  return '/phdrug/{role}/{role_uuid}/top/generic/'
}
export const phdrug_top_generic_list_TYPE = function() {
  return 'get'
}
export const phdrug_top_generic_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/phdrug/{role}/{role_uuid}/top/generic/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Devuelve la encuesta si la encuentra en medika,
en el caso de que no exista devuelve un 404
* request: polls_poll_order_read
* url: polls_poll_order_readURL
* method: polls_poll_order_read_TYPE
* raw_url: polls_poll_order_read_RAW_URL
     * @param pollId - 
     * @param role - 
     * @param roleUuid - 
*/
export const polls_poll_order_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{poll_id}', `${parameters['pollId']}`)
  if (parameters['pollId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pollId'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const polls_poll_order_read_RAW_URL = function() {
  return '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
}
export const polls_poll_order_read_TYPE = function() {
  return 'get'
}
export const polls_poll_order_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
  path = path.replace('{poll_id}', `${parameters['pollId']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Devuelve la encuesta si la encuentra en medika,
en el caso de que no exista devuelve un 404
* request: polls_poll_order_update
* url: polls_poll_order_updateURL
* method: polls_poll_order_update_TYPE
* raw_url: polls_poll_order_update_RAW_URL
     * @param data - 
     * @param pollId - 
     * @param role - 
     * @param roleUuid - 
*/
export const polls_poll_order_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{poll_id}', `${parameters['pollId']}`)
  if (parameters['pollId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pollId'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const polls_poll_order_update_RAW_URL = function() {
  return '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
}
export const polls_poll_order_update_TYPE = function() {
  return 'put'
}
export const polls_poll_order_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
  path = path.replace('{poll_id}', `${parameters['pollId']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Devuelve la encuesta si la encuentra en medika,
en el caso de que no exista devuelve un 404
* request: polls_poll_order_partial_update
* url: polls_poll_order_partial_updateURL
* method: polls_poll_order_partial_update_TYPE
* raw_url: polls_poll_order_partial_update_RAW_URL
     * @param data - 
     * @param pollId - 
     * @param role - 
     * @param roleUuid - 
*/
export const polls_poll_order_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{poll_id}', `${parameters['pollId']}`)
  if (parameters['pollId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pollId'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const polls_poll_order_partial_update_RAW_URL = function() {
  return '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
}
export const polls_poll_order_partial_update_TYPE = function() {
  return 'patch'
}
export const polls_poll_order_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/polls/{role}/{role_uuid}/poll/order/{poll_id}/'
  path = path.replace('{poll_id}', `${parameters['pollId']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List best seller products
 * request: products_best_seller_list
 * url: products_best_seller_listURL
 * method: products_best_seller_list_TYPE
 * raw_url: products_best_seller_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_best_seller_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/best-seller/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_best_seller_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/best-seller/'
}
export const products_best_seller_list_TYPE = function() {
  return 'get'
}
export const products_best_seller_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/best-seller/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List of drug categories, new generation
 * request: products_categories_ng_list
 * url: products_categories_ng_listURL
 * method: products_categories_ng_list_TYPE
 * raw_url: products_categories_ng_list_RAW_URL
 * @param name - 
 * @param uuid - 
 * @param menuHeader - 
 * @param department - Department id
 * @param site - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_categories_ng_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/categories/ng/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['menuHeader'] !== undefined) {
    queryParameters['menu_header'] = parameters['menuHeader']
  }
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_categories_ng_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/categories/ng/'
}
export const products_categories_ng_list_TYPE = function() {
  return 'get'
}
export const products_categories_ng_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/categories/ng/'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['menuHeader'] !== undefined) {
    queryParameters['menu_header'] = parameters['menuHeader']
  }
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* List drugs without country filter. Using distance filter the prices
showed will be also filtered.
* request: products_collection_any_country_drug_list
* url: products_collection_any_country_drug_listURL
* method: products_collection_any_country_drug_list_TYPE
* raw_url: products_collection_any_country_drug_list_RAW_URL
     * @param search - A search term in elasticsearch
     * @param letter - Starts with...
     * @param uuid - 
     * @param description - Description
     * @param substance - Substance
     * @param ean - 
     * @param category - Category uuid
     * @param groupedCategory - Category uuid
     * @param top - 
     * @param topHightSpeciality - 
     * @param topDerma - 
     * @param hightSpeciality - 
     * @param organization - Organization uuid
     * @param publicOrganization - Organization uuid with pricelistdestination public
     * @param updatedStartDate - 
     * @param updatedEndDate - 
     * @param brands - List of Brands uuid
     * @param topGeneric - 
     * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
     * @param ordering - Which field to use when ordering the results.
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const products_collection_any_country_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/any-country-drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_any_country_drug_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/any-country-drug/'
}
export const products_collection_any_country_drug_list_TYPE = function() {
  return 'get'
}
export const products_collection_any_country_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/any-country-drug/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_collection_drug_list
 * url: products_collection_drug_listURL
 * method: products_collection_drug_list_TYPE
 * raw_url: products_collection_drug_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/'
}
export const products_collection_drug_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_collection_drug_brands_list
 * url: products_collection_drug_brands_listURL
 * method: products_collection_drug_brands_list_TYPE
 * raw_url: products_collection_drug_brands_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_brands_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_brands_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/brands/'
}
export const products_collection_drug_brands_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_brands_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_collection_drug_brands_bestsellers_list
 * url: products_collection_drug_brands_bestsellers_listURL
 * method: products_collection_drug_brands_bestsellers_list_TYPE
 * raw_url: products_collection_drug_brands_bestsellers_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_brands_bestsellers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/bestsellers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_brands_bestsellers_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/brands/bestsellers/'
}
export const products_collection_drug_brands_bestsellers_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_brands_bestsellers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/bestsellers/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_collection_drug_brands_news_list
 * url: products_collection_drug_brands_news_listURL
 * method: products_collection_drug_brands_news_list_TYPE
 * raw_url: products_collection_drug_brands_news_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_brands_news_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/news/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_brands_news_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/brands/news/'
}
export const products_collection_drug_brands_news_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_brands_news_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/news/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_collection_drug_brands_offers_list
 * url: products_collection_drug_brands_offers_listURL
 * method: products_collection_drug_brands_offers_list_TYPE
 * raw_url: products_collection_drug_brands_offers_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_brands_offers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/offers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_brands_offers_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/brands/offers/'
}
export const products_collection_drug_brands_offers_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_brands_offers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/offers/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_collection_drug_brands_pack_list
 * url: products_collection_drug_brands_pack_listURL
 * method: products_collection_drug_brands_pack_list_TYPE
 * raw_url: products_collection_drug_brands_pack_list_RAW_URL
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_brands_pack_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/pack/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_brands_pack_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/brands/pack/'
}
export const products_collection_drug_brands_pack_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_brands_pack_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/brands/pack/'
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail drug
 * request: products_collection_drug_read
 * url: products_collection_drug_readURL
 * method: products_collection_drug_read_TYPE
 * raw_url: products_collection_drug_read_RAW_URL
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_read_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/'
}
export const products_collection_drug_read_TYPE = function() {
  return 'get'
}
export const products_collection_drug_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List drugs bought with the given drug
 * request: products_collection_drug_bought_with_list
 * url: products_collection_drug_bought_with_listURL
 * method: products_collection_drug_bought_with_list_TYPE
 * raw_url: products_collection_drug_bought_with_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_bought_with_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/bought_with/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_bought_with_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/bought_with/'
}
export const products_collection_drug_bought_with_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_bought_with_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/bought_with/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pictures for drugs
 * request: products_collection_drug_picture_list
 * url: products_collection_drug_picture_listURL
 * method: products_collection_drug_picture_list_TYPE
 * raw_url: products_collection_drug_picture_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_picture_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_picture_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
}
export const products_collection_drug_picture_list_TYPE = function() {
  return 'get'
}
export const products_collection_drug_picture_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List and create pictures for drugs
 * request: products_collection_drug_picture_create
 * url: products_collection_drug_picture_createURL
 * method: products_collection_drug_picture_create_TYPE
 * raw_url: products_collection_drug_picture_create_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_picture_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_picture_create_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
}
export const products_collection_drug_picture_create_TYPE = function() {
  return 'post'
}
export const products_collection_drug_picture_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: products_collection_drug_picture_read
 * url: products_collection_drug_picture_readURL
 * method: products_collection_drug_picture_read_TYPE
 * raw_url: products_collection_drug_picture_read_RAW_URL
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_picture_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_picture_read_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const products_collection_drug_picture_read_TYPE = function() {
  return 'get'
}
export const products_collection_drug_picture_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: products_collection_drug_picture_update
 * url: products_collection_drug_picture_updateURL
 * method: products_collection_drug_picture_update_TYPE
 * raw_url: products_collection_drug_picture_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_picture_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_picture_update_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const products_collection_drug_picture_update_TYPE = function() {
  return 'put'
}
export const products_collection_drug_picture_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: products_collection_drug_picture_partial_update
 * url: products_collection_drug_picture_partial_updateURL
 * method: products_collection_drug_picture_partial_update_TYPE
 * raw_url: products_collection_drug_picture_partial_update_RAW_URL
 * @param data - 
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_picture_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_picture_partial_update_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const products_collection_drug_picture_partial_update_TYPE = function() {
  return 'patch'
}
export const products_collection_drug_picture_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Manage pictures for drugs
 * request: products_collection_drug_picture_delete
 * url: products_collection_drug_picture_deleteURL
 * method: products_collection_drug_picture_delete_TYPE
 * raw_url: products_collection_drug_picture_delete_RAW_URL
 * @param dUuid - 
 * @param picUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_drug_picture_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  if (parameters['dUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dUuid'))
  }
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  if (parameters['picUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const products_collection_drug_picture_delete_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
}
export const products_collection_drug_picture_delete_TYPE = function() {
  return 'delete'
}
export const products_collection_drug_picture_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/drug/{d_uuid}/picture/{pic_uuid}/'
  path = path.replace('{d_uuid}', `${parameters['dUuid']}`)
  path = path.replace('{pic_uuid}', `${parameters['picUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: products_collection_food_list
 * url: products_collection_food_listURL
 * method: products_collection_food_list_TYPE
 * raw_url: products_collection_food_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_food_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/food/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_food_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/food/'
}
export const products_collection_food_list_TYPE = function() {
  return 'get'
}
export const products_collection_food_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/food/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail product
 * request: products_collection_food_read
 * url: products_collection_food_readURL
 * method: products_collection_food_read_TYPE
 * raw_url: products_collection_food_read_RAW_URL
 * @param fUuid - 
 * @param role - 
 * @param roleUuid - 
 */
export const products_collection_food_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/collection/food/{f_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{f_uuid}', `${parameters['fUuid']}`)
  if (parameters['fUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fUuid'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_collection_food_read_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/collection/food/{f_uuid}/'
}
export const products_collection_food_read_TYPE = function() {
  return 'get'
}
export const products_collection_food_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/collection/food/{f_uuid}/'
  path = path.replace('{f_uuid}', `${parameters['fUuid']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List featured products
 * request: products_featured_list
 * url: products_featured_listURL
 * method: products_featured_list_TYPE
 * raw_url: products_featured_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_featured_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/featured/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_featured_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/featured/'
}
export const products_featured_list_TYPE = function() {
  return 'get'
}
export const products_featured_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/featured/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List of grouped categories
 * request: products_grouped_categories_list
 * url: products_grouped_categories_listURL
 * method: products_grouped_categories_list_TYPE
 * raw_url: products_grouped_categories_list_RAW_URL
 * @param name - 
 * @param uuid - 
 * @param site - 
 * @param orderingLangtag - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_grouped_categories_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/grouped-categories/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['orderingLangtag'] !== undefined) {
    queryParameters['ordering_langtag'] = parameters['orderingLangtag']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_grouped_categories_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/grouped-categories/'
}
export const products_grouped_categories_list_TYPE = function() {
  return 'get'
}
export const products_grouped_categories_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/grouped-categories/'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['site'] !== undefined) {
    queryParameters['site'] = parameters['site']
  }
  if (parameters['orderingLangtag'] !== undefined) {
    queryParameters['ordering_langtag'] = parameters['orderingLangtag']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Mostrar todos aquellos productos que no se vendan en el país actual
 y que sean parte de una lista de precios marcada como "Exportados".
Sólo las sucursales de globalstore.
* request: products_importation_list
* url: products_importation_listURL
* method: products_importation_list_TYPE
* raw_url: products_importation_list_RAW_URL
     * @param search - A search term in elasticsearch
     * @param letter - Starts with...
     * @param uuid - 
     * @param description - Description
     * @param substance - Substance
     * @param ean - 
     * @param category - Category uuid
     * @param groupedCategory - Category uuid
     * @param top - 
     * @param topHightSpeciality - 
     * @param topDerma - 
     * @param hightSpeciality - 
     * @param organization - Organization uuid
     * @param publicOrganization - Organization uuid with pricelistdestination public
     * @param updatedStartDate - 
     * @param updatedEndDate - 
     * @param brands - List of Brands uuid
     * @param topGeneric - 
     * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
     * @param limit - Number of results to return per page.
     * @param offset - The initial index from which to return the results.
     * @param role - 
     * @param roleUuid - 
*/
export const products_importation_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/importation/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_importation_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/importation/'
}
export const products_importation_list_TYPE = function() {
  return 'get'
}
export const products_importation_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/importation/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: products_macro_master_list
 * url: products_macro_master_listURL
 * method: products_macro_master_list_TYPE
 * raw_url: products_macro_master_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_macro_master_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/macro/master/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_macro_master_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/macro/master/'
}
export const products_macro_master_list_TYPE = function() {
  return 'get'
}
export const products_macro_master_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/macro/master/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List new products
 * request: products_new_list
 * url: products_new_listURL
 * method: products_new_list_TYPE
 * raw_url: products_new_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_new_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/new/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_new_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/new/'
}
export const products_new_list_TYPE = function() {
  return 'get'
}
export const products_new_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/new/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List offers products
 * request: products_offers_list
 * url: products_offers_listURL
 * method: products_offers_list_TYPE
 * raw_url: products_offers_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_offers_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/offers/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_offers_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/offers/'
}
export const products_offers_list_TYPE = function() {
  return 'get'
}
export const products_offers_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/offers/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: products_offers_pack_list
 * url: products_offers_pack_listURL
 * method: products_offers_pack_list_TYPE
 * raw_url: products_offers_pack_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_offers_pack_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/offers/pack/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_offers_pack_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/offers/pack/'
}
export const products_offers_pack_list_TYPE = function() {
  return 'get'
}
export const products_offers_pack_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/offers/pack/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: products_top_generic_list
 * url: products_top_generic_listURL
 * method: products_top_generic_list_TYPE
 * raw_url: products_top_generic_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const products_top_generic_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/products/{role}/{role_uuid}/top/generic/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const products_top_generic_list_RAW_URL = function() {
  return '/products/{role}/{role_uuid}/top/generic/'
}
export const products_top_generic_list_TYPE = function() {
  return 'get'
}
export const products_top_generic_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/products/{role}/{role_uuid}/top/generic/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_country_list
 * url: segment_vooyfood_authx_auth_country_listURL
 * method: segment_vooyfood_authx_auth_country_list_TYPE
 * raw_url: segment_vooyfood_authx_auth_country_list_RAW_URL
 */
export const segment_vooyfood_authx_auth_country_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/country/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_country_list_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/country/'
}
export const segment_vooyfood_authx_auth_country_list_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_authx_auth_country_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/country/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Takes a set of user credentials and returns an access and refresh JSON web
token pair to prove the authentication of those credentials.
* request: segment_vooyfood_authx_auth_jwt_create_create
* url: segment_vooyfood_authx_auth_jwt_create_createURL
* method: segment_vooyfood_authx_auth_jwt_create_create_TYPE
* raw_url: segment_vooyfood_authx_auth_jwt_create_create_RAW_URL
     * @param data - 
*/
export const segment_vooyfood_authx_auth_jwt_create_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/jwt/create/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_jwt_create_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/jwt/create/'
}
export const segment_vooyfood_authx_auth_jwt_create_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_jwt_create_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/jwt/create/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
* request: segment_vooyfood_authx_auth_jwt_refresh_create
* url: segment_vooyfood_authx_auth_jwt_refresh_createURL
* method: segment_vooyfood_authx_auth_jwt_refresh_create_TYPE
* raw_url: segment_vooyfood_authx_auth_jwt_refresh_create_RAW_URL
     * @param data - 
*/
export const segment_vooyfood_authx_auth_jwt_refresh_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/jwt/refresh/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_jwt_refresh_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/jwt/refresh/'
}
export const segment_vooyfood_authx_auth_jwt_refresh_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_jwt_refresh_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/jwt/refresh/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Takes a token and indicates if it is valid.  This view provides no
information about a token's fitness for a particular use.
* request: segment_vooyfood_authx_auth_jwt_verify_create
* url: segment_vooyfood_authx_auth_jwt_verify_createURL
* method: segment_vooyfood_authx_auth_jwt_verify_create_TYPE
* raw_url: segment_vooyfood_authx_auth_jwt_verify_create_RAW_URL
     * @param data - 
*/
export const segment_vooyfood_authx_auth_jwt_verify_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/jwt/verify/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_jwt_verify_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/jwt/verify/'
}
export const segment_vooyfood_authx_auth_jwt_verify_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_jwt_verify_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/jwt/verify/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_me_read
 * url: segment_vooyfood_authx_auth_me_readURL
 * method: segment_vooyfood_authx_auth_me_read_TYPE
 * raw_url: segment_vooyfood_authx_auth_me_read_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 */
export const segment_vooyfood_authx_auth_me_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/me/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_me_read_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/me/'
}
export const segment_vooyfood_authx_auth_me_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_authx_auth_me_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/me/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_me_update
 * url: segment_vooyfood_authx_auth_me_updateURL
 * method: segment_vooyfood_authx_auth_me_update_TYPE
 * raw_url: segment_vooyfood_authx_auth_me_update_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_authx_auth_me_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/me/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_me_update_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/me/'
}
export const segment_vooyfood_authx_auth_me_update_TYPE = function() {
  return 'put'
}
export const segment_vooyfood_authx_auth_me_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/me/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_me_partial_update
 * url: segment_vooyfood_authx_auth_me_partial_updateURL
 * method: segment_vooyfood_authx_auth_me_partial_update_TYPE
 * raw_url: segment_vooyfood_authx_auth_me_partial_update_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_authx_auth_me_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/me/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_me_partial_update_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/me/'
}
export const segment_vooyfood_authx_auth_me_partial_update_TYPE = function() {
  return 'patch'
}
export const segment_vooyfood_authx_auth_me_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/me/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_set_password
 * url: segment_vooyfood_authx_auth_set_passwordURL
 * method: segment_vooyfood_authx_auth_set_password_TYPE
 * raw_url: segment_vooyfood_authx_auth_set_password_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_authx_auth_set_password = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/password/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_set_password_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/password/'
}
export const segment_vooyfood_authx_auth_set_password_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_set_passwordURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/password/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_password_reset_reset_password_confirm
 * url: segment_vooyfood_authx_auth_password_reset_reset_password_confirmURL
 * method: segment_vooyfood_authx_auth_password_reset_reset_password_confirm_TYPE
 * raw_url: segment_vooyfood_authx_auth_password_reset_reset_password_confirm_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_authx_auth_password_reset_reset_password_confirm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/password/reset/confirm/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_password_reset_reset_password_confirm_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/password/reset/confirm/'
}
export const segment_vooyfood_authx_auth_password_reset_reset_password_confirm_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_password_reset_reset_password_confirmURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/password/reset/confirm/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_password_reset_password
 * url: segment_vooyfood_authx_auth_password_reset_passwordURL
 * method: segment_vooyfood_authx_auth_password_reset_password_TYPE
 * raw_url: segment_vooyfood_authx_auth_password_reset_password_RAW_URL
 * @param data - 
 * @param site - 
 */
export const segment_vooyfood_authx_auth_password_reset_password = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/password/reset/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_password_reset_password_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/password/reset/{site}/'
}
export const segment_vooyfood_authx_auth_password_reset_password_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_password_reset_passwordURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/password/reset/{site}/'
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Genera la url adecuada de la red social y redirecciona a ésta, que
a su vez redireccionará de nuevo aquí, la segunda vez se llamará a
self.create() que creará los tokens y redireccionará al frontend.
* request: segment_vooyfood_authx_auth_social_to_read
* url: segment_vooyfood_authx_auth_social_to_readURL
* method: segment_vooyfood_authx_auth_social_to_read_TYPE
* raw_url: segment_vooyfood_authx_auth_social_to_read_RAW_URL
     * @param frontend - 
     * @param provider - 
*/
export const segment_vooyfood_authx_auth_social_to_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/social/{provider}/to/{frontend}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  if (parameters['frontend'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: frontend'))
  }
  path = path.replace('{provider}', `${parameters['provider']}`)
  if (parameters['provider'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provider'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_social_to_read_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/social/{provider}/to/{frontend}/'
}
export const segment_vooyfood_authx_auth_social_to_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_authx_auth_social_to_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/social/{provider}/to/{frontend}/'
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  path = path.replace('{provider}', `${parameters['provider']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Wrap del sistema de autenticación de djoser. El backend realiza
la autenticación y una vez hecha redirecciona al frontend con los tokens
`access` y `refresh` como query en la url.
* request: segment_vooyfood_authx_auth_social_to_create
* url: segment_vooyfood_authx_auth_social_to_createURL
* method: segment_vooyfood_authx_auth_social_to_create_TYPE
* raw_url: segment_vooyfood_authx_auth_social_to_create_RAW_URL
     * @param data - 
     * @param frontend - 
     * @param provider - 
*/
export const segment_vooyfood_authx_auth_social_to_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/social/{provider}/to/{frontend}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  if (parameters['frontend'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: frontend'))
  }
  path = path.replace('{provider}', `${parameters['provider']}`)
  if (parameters['provider'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provider'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_social_to_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/social/{provider}/to/{frontend}/'
}
export const segment_vooyfood_authx_auth_social_to_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_social_to_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/social/{provider}/to/{frontend}/'
  path = path.replace('{frontend}', `${parameters['frontend']}`)
  path = path.replace('{provider}', `${parameters['provider']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_someone_create
 * url: segment_vooyfood_authx_auth_someone_createURL
 * method: segment_vooyfood_authx_auth_someone_create_TYPE
 * raw_url: segment_vooyfood_authx_auth_someone_create_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_authx_auth_someone_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/someone/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_someone_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/someone/'
}
export const segment_vooyfood_authx_auth_someone_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_someone_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/someone/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_users_activation
 * url: segment_vooyfood_authx_auth_users_activationURL
 * method: segment_vooyfood_authx_auth_users_activation_TYPE
 * raw_url: segment_vooyfood_authx_auth_users_activation_RAW_URL
 * @param data - 
 * @param site - 
 */
export const segment_vooyfood_authx_auth_users_activation = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/users/confirm/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_users_activation_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/users/confirm/{site}/'
}
export const segment_vooyfood_authx_auth_users_activation_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_users_activationURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/users/confirm/{site}/'
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_users_create_create
 * url: segment_vooyfood_authx_auth_users_create_createURL
 * method: segment_vooyfood_authx_auth_users_create_create_TYPE
 * raw_url: segment_vooyfood_authx_auth_users_create_create_RAW_URL
 * @param data - 
 * @param site - 
 */
export const segment_vooyfood_authx_auth_users_create_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/users/create/{site}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters['site'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: site'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_users_create_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/users/create/{site}/'
}
export const segment_vooyfood_authx_auth_users_create_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_users_create_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/users/create/{site}/'
  path = path.replace('{site}', `${parameters['site']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_auth_users_cross_create
 * url: segment_vooyfood_authx_auth_users_cross_createURL
 * method: segment_vooyfood_authx_auth_users_cross_create_TYPE
 * raw_url: segment_vooyfood_authx_auth_users_cross_create_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_authx_auth_users_cross_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/auth/users/cross/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_auth_users_cross_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/auth/users/cross/'
}
export const segment_vooyfood_authx_auth_users_cross_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_auth_users_cross_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/auth/users/cross/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_authx_logout_create
 * url: segment_vooyfood_authx_logout_createURL
 * method: segment_vooyfood_authx_logout_create_TYPE
 * raw_url: segment_vooyfood_authx_logout_create_RAW_URL
 */
export const segment_vooyfood_authx_logout_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/authx/logout/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_authx_logout_create_RAW_URL = function() {
  return '/segment/vooyfood/authx/logout/'
}
export const segment_vooyfood_authx_logout_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_authx_logout_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/authx/logout/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Active shopping cart detail
 * request: segment_vooyfood_cart_read
 * url: segment_vooyfood_cart_readURL
 * method: segment_vooyfood_cart_read_TYPE
 * raw_url: segment_vooyfood_cart_read_RAW_URL
 */
export const segment_vooyfood_cart_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_read_RAW_URL = function() {
  return '/segment/vooyfood/cart/'
}
export const segment_vooyfood_cart_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_cart_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_active_read
 * url: segment_vooyfood_cart_checkout_active_readURL
 * method: segment_vooyfood_cart_checkout_active_read_TYPE
 * raw_url: segment_vooyfood_cart_checkout_active_read_RAW_URL
 */
export const segment_vooyfood_cart_checkout_active_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/active/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_active_read_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/active/'
}
export const segment_vooyfood_cart_checkout_active_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_cart_checkout_active_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/active/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_active_update
 * url: segment_vooyfood_cart_checkout_active_updateURL
 * method: segment_vooyfood_cart_checkout_active_update_TYPE
 * raw_url: segment_vooyfood_cart_checkout_active_update_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_cart_checkout_active_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/active/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_active_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/active/'
}
export const segment_vooyfood_cart_checkout_active_update_TYPE = function() {
  return 'put'
}
export const segment_vooyfood_cart_checkout_active_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/active/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_active_partial_update
 * url: segment_vooyfood_cart_checkout_active_partial_updateURL
 * method: segment_vooyfood_cart_checkout_active_partial_update_TYPE
 * raw_url: segment_vooyfood_cart_checkout_active_partial_update_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_cart_checkout_active_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/active/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_active_partial_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/active/'
}
export const segment_vooyfood_cart_checkout_active_partial_update_TYPE = function() {
  return 'patch'
}
export const segment_vooyfood_cart_checkout_active_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/active/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_activeg_read
 * url: segment_vooyfood_cart_checkout_activeg_readURL
 * method: segment_vooyfood_cart_checkout_activeg_read_TYPE
 * raw_url: segment_vooyfood_cart_checkout_activeg_read_RAW_URL
 */
export const segment_vooyfood_cart_checkout_activeg_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/activeg/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_activeg_read_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/activeg/'
}
export const segment_vooyfood_cart_checkout_activeg_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_cart_checkout_activeg_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/activeg/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_activeg_update
 * url: segment_vooyfood_cart_checkout_activeg_updateURL
 * method: segment_vooyfood_cart_checkout_activeg_update_TYPE
 * raw_url: segment_vooyfood_cart_checkout_activeg_update_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_cart_checkout_activeg_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/activeg/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_activeg_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/activeg/'
}
export const segment_vooyfood_cart_checkout_activeg_update_TYPE = function() {
  return 'put'
}
export const segment_vooyfood_cart_checkout_activeg_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/activeg/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_activeg_partial_update
 * url: segment_vooyfood_cart_checkout_activeg_partial_updateURL
 * method: segment_vooyfood_cart_checkout_activeg_partial_update_TYPE
 * raw_url: segment_vooyfood_cart_checkout_activeg_partial_update_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_cart_checkout_activeg_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/activeg/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_activeg_partial_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/activeg/'
}
export const segment_vooyfood_cart_checkout_activeg_partial_update_TYPE = function() {
  return 'patch'
}
export const segment_vooyfood_cart_checkout_activeg_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/activeg/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_read
 * url: segment_vooyfood_cart_checkout_readURL
 * method: segment_vooyfood_cart_checkout_read_TYPE
 * raw_url: segment_vooyfood_cart_checkout_read_RAW_URL
 * @param checkoutUuid - 
 */
export const segment_vooyfood_cart_checkout_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_read_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
}
export const segment_vooyfood_cart_checkout_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_cart_checkout_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_update
 * url: segment_vooyfood_cart_checkout_updateURL
 * method: segment_vooyfood_cart_checkout_update_TYPE
 * raw_url: segment_vooyfood_cart_checkout_update_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 */
export const segment_vooyfood_cart_checkout_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
}
export const segment_vooyfood_cart_checkout_update_TYPE = function() {
  return 'put'
}
export const segment_vooyfood_cart_checkout_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Checkout details
 * request: segment_vooyfood_cart_checkout_partial_update
 * url: segment_vooyfood_cart_checkout_partial_updateURL
 * method: segment_vooyfood_cart_checkout_partial_update_TYPE
 * raw_url: segment_vooyfood_cart_checkout_partial_update_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 */
export const segment_vooyfood_cart_checkout_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_partial_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
}
export const segment_vooyfood_cart_checkout_partial_update_TYPE = function() {
  return 'patch'
}
export const segment_vooyfood_cart_checkout_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * terminar un checkout por farmacia
 * request: segment_vooyfood_cart_checkout_doneinsuranceriskpayment_create
 * url: segment_vooyfood_cart_checkout_doneinsuranceriskpayment_createURL
 * method: segment_vooyfood_cart_checkout_doneinsuranceriskpayment_create_TYPE
 * raw_url: segment_vooyfood_cart_checkout_doneinsuranceriskpayment_create_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 */
export const segment_vooyfood_cart_checkout_doneinsuranceriskpayment_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/doneinsuranceriskpayment/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_doneinsuranceriskpayment_create_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/{checkout_uuid}/doneinsuranceriskpayment/'
}
export const segment_vooyfood_cart_checkout_doneinsuranceriskpayment_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_cart_checkout_doneinsuranceriskpayment_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/doneinsuranceriskpayment/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * terminar un checkout por farmacia
 * request: segment_vooyfood_cart_checkout_to_retail_order_create
 * url: segment_vooyfood_cart_checkout_to_retail_order_createURL
 * method: segment_vooyfood_cart_checkout_to_retail_order_create_TYPE
 * raw_url: segment_vooyfood_cart_checkout_to_retail_order_create_RAW_URL
 * @param data - 
 * @param checkoutUuid - 
 */
export const segment_vooyfood_cart_checkout_to_retail_order_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/to_retail_order/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters['checkoutUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkoutUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_checkout_to_retail_order_create_RAW_URL = function() {
  return '/segment/vooyfood/cart/checkout/{checkout_uuid}/to_retail_order/'
}
export const segment_vooyfood_cart_checkout_to_retail_order_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_cart_checkout_to_retail_order_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/checkout/{checkout_uuid}/to_retail_order/'
  path = path.replace('{checkout_uuid}', `${parameters['checkoutUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside the active shopping cart
 * request: segment_vooyfood_cart_items_list
 * url: segment_vooyfood_cart_items_listURL
 * method: segment_vooyfood_cart_items_list_TYPE
 * raw_url: segment_vooyfood_cart_items_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 */
export const segment_vooyfood_cart_items_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_list_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/'
}
export const segment_vooyfood_cart_items_list_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_cart_items_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Items inside the active shopping cart
 * request: segment_vooyfood_cart_items_create
 * url: segment_vooyfood_cart_items_createURL
 * method: segment_vooyfood_cart_items_create_TYPE
 * raw_url: segment_vooyfood_cart_items_create_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_cart_items_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_create_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/'
}
export const segment_vooyfood_cart_items_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_cart_items_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create many items in the active shopping cart
 * request: segment_vooyfood_cart_items_many_create
 * url: segment_vooyfood_cart_items_many_createURL
 * method: segment_vooyfood_cart_items_many_create_TYPE
 * raw_url: segment_vooyfood_cart_items_many_create_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_cart_items_many_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/many/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_many_create_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/many/'
}
export const segment_vooyfood_cart_items_many_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_cart_items_many_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/many/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: segment_vooyfood_cart_items_read
 * url: segment_vooyfood_cart_items_readURL
 * method: segment_vooyfood_cart_items_read_TYPE
 * raw_url: segment_vooyfood_cart_items_read_RAW_URL
 * @param itemUuid - 
 */
export const segment_vooyfood_cart_items_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_read_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/{item_uuid}/'
}
export const segment_vooyfood_cart_items_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_cart_items_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: segment_vooyfood_cart_items_update
 * url: segment_vooyfood_cart_items_updateURL
 * method: segment_vooyfood_cart_items_update_TYPE
 * raw_url: segment_vooyfood_cart_items_update_RAW_URL
 * @param data - 
 * @param itemUuid - 
 */
export const segment_vooyfood_cart_items_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/{item_uuid}/'
}
export const segment_vooyfood_cart_items_update_TYPE = function() {
  return 'put'
}
export const segment_vooyfood_cart_items_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: segment_vooyfood_cart_items_partial_update
 * url: segment_vooyfood_cart_items_partial_updateURL
 * method: segment_vooyfood_cart_items_partial_update_TYPE
 * raw_url: segment_vooyfood_cart_items_partial_update_RAW_URL
 * @param data - 
 * @param itemUuid - 
 */
export const segment_vooyfood_cart_items_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_partial_update_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/{item_uuid}/'
}
export const segment_vooyfood_cart_items_partial_update_TYPE = function() {
  return 'patch'
}
export const segment_vooyfood_cart_items_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail of an item in the active shopping cart
 * request: segment_vooyfood_cart_items_delete
 * url: segment_vooyfood_cart_items_deleteURL
 * method: segment_vooyfood_cart_items_delete_TYPE
 * raw_url: segment_vooyfood_cart_items_delete_RAW_URL
 * @param itemUuid - 
 */
export const segment_vooyfood_cart_items_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters['itemUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itemUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_cart_items_delete_RAW_URL = function() {
  return '/segment/vooyfood/cart/items/{item_uuid}/'
}
export const segment_vooyfood_cart_items_delete_TYPE = function() {
  return 'delete'
}
export const segment_vooyfood_cart_items_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/cart/items/{item_uuid}/'
  path = path.replace('{item_uuid}', `${parameters['itemUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para devolver todas las direcciones de un paciente
 * request: segment_vooyfood_naturalperson_buyer_addresses_list
 * url: segment_vooyfood_naturalperson_buyer_addresses_listURL
 * method: segment_vooyfood_naturalperson_buyer_addresses_list_TYPE
 * raw_url: segment_vooyfood_naturalperson_buyer_addresses_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 */
export const segment_vooyfood_naturalperson_buyer_addresses_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_naturalperson_buyer_addresses_list_RAW_URL = function() {
  return '/segment/vooyfood/naturalperson/buyer/addresses/'
}
export const segment_vooyfood_naturalperson_buyer_addresses_list_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_naturalperson_buyer_addresses_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para devolver todas las direcciones de un paciente
 * request: segment_vooyfood_naturalperson_buyer_addresses_create
 * url: segment_vooyfood_naturalperson_buyer_addresses_createURL
 * method: segment_vooyfood_naturalperson_buyer_addresses_create_TYPE
 * raw_url: segment_vooyfood_naturalperson_buyer_addresses_create_RAW_URL
 * @param data - 
 */
export const segment_vooyfood_naturalperson_buyer_addresses_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_naturalperson_buyer_addresses_create_RAW_URL = function() {
  return '/segment/vooyfood/naturalperson/buyer/addresses/'
}
export const segment_vooyfood_naturalperson_buyer_addresses_create_TYPE = function() {
  return 'post'
}
export const segment_vooyfood_naturalperson_buyer_addresses_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: segment_vooyfood_naturalperson_buyer_addresses_read
 * url: segment_vooyfood_naturalperson_buyer_addresses_readURL
 * method: segment_vooyfood_naturalperson_buyer_addresses_read_TYPE
 * raw_url: segment_vooyfood_naturalperson_buyer_addresses_read_RAW_URL
 * @param aUuid - 
 */
export const segment_vooyfood_naturalperson_buyer_addresses_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_naturalperson_buyer_addresses_read_RAW_URL = function() {
  return '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
}
export const segment_vooyfood_naturalperson_buyer_addresses_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_naturalperson_buyer_addresses_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: segment_vooyfood_naturalperson_buyer_addresses_update
 * url: segment_vooyfood_naturalperson_buyer_addresses_updateURL
 * method: segment_vooyfood_naturalperson_buyer_addresses_update_TYPE
 * raw_url: segment_vooyfood_naturalperson_buyer_addresses_update_RAW_URL
 * @param data - 
 * @param aUuid - 
 */
export const segment_vooyfood_naturalperson_buyer_addresses_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_naturalperson_buyer_addresses_update_RAW_URL = function() {
  return '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
}
export const segment_vooyfood_naturalperson_buyer_addresses_update_TYPE = function() {
  return 'put'
}
export const segment_vooyfood_naturalperson_buyer_addresses_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: segment_vooyfood_naturalperson_buyer_addresses_partial_update
 * url: segment_vooyfood_naturalperson_buyer_addresses_partial_updateURL
 * method: segment_vooyfood_naturalperson_buyer_addresses_partial_update_TYPE
 * raw_url: segment_vooyfood_naturalperson_buyer_addresses_partial_update_RAW_URL
 * @param data - 
 * @param aUuid - 
 */
export const segment_vooyfood_naturalperson_buyer_addresses_partial_update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_naturalperson_buyer_addresses_partial_update_RAW_URL = function() {
  return '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
}
export const segment_vooyfood_naturalperson_buyer_addresses_partial_update_TYPE = function() {
  return 'patch'
}
export const segment_vooyfood_naturalperson_buyer_addresses_partial_updateURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Endpoint para actualizar, borrar o ver en detalle una dirección del usuario que hace la petición
 * request: segment_vooyfood_naturalperson_buyer_addresses_delete
 * url: segment_vooyfood_naturalperson_buyer_addresses_deleteURL
 * method: segment_vooyfood_naturalperson_buyer_addresses_delete_TYPE
 * raw_url: segment_vooyfood_naturalperson_buyer_addresses_delete_RAW_URL
 * @param aUuid - 
 */
export const segment_vooyfood_naturalperson_buyer_addresses_delete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters['aUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_naturalperson_buyer_addresses_delete_RAW_URL = function() {
  return '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
}
export const segment_vooyfood_naturalperson_buyer_addresses_delete_TYPE = function() {
  return 'delete'
}
export const segment_vooyfood_naturalperson_buyer_addresses_deleteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/naturalperson/buyer/addresses/{a_uuid}/'
  path = path.replace('{a_uuid}', `${parameters['aUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * List pack offers products
 * request: segment_vooyfood_product_list
 * url: segment_vooyfood_product_listURL
 * method: segment_vooyfood_product_list_TYPE
 * raw_url: segment_vooyfood_product_list_RAW_URL
 * @param search - A search term in elasticsearch
 * @param letter - Starts with...
 * @param uuid - 
 * @param description - Description
 * @param substance - Substance
 * @param ean - 
 * @param category - Category uuid
 * @param groupedCategory - Category uuid
 * @param top - 
 * @param topHightSpeciality - 
 * @param topDerma - 
 * @param hightSpeciality - 
 * @param organization - Organization uuid
 * @param publicOrganization - Organization uuid with pricelistdestination public
 * @param updatedStartDate - 
 * @param updatedEndDate - 
 * @param brands - List of Brands uuid
 * @param topGeneric - 
 * @param distance - Filter by near public organization in km. Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param ordering - Which field to use when ordering the results.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 */
export const segment_vooyfood_product_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/product/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_product_list_RAW_URL = function() {
  return '/segment/vooyfood/product/'
}
export const segment_vooyfood_product_list_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_product_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/product/'
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['letter'] !== undefined) {
    queryParameters['letter'] = parameters['letter']
  }
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['substance'] !== undefined) {
    queryParameters['substance'] = parameters['substance']
  }
  if (parameters['ean'] !== undefined) {
    queryParameters['ean'] = parameters['ean']
  }
  if (parameters['category'] !== undefined) {
    queryParameters['category'] = parameters['category']
  }
  if (parameters['groupedCategory'] !== undefined) {
    queryParameters['grouped_category'] = parameters['groupedCategory']
  }
  if (parameters['top'] !== undefined) {
    queryParameters['top'] = parameters['top']
  }
  if (parameters['topHightSpeciality'] !== undefined) {
    queryParameters['top_hight_speciality'] = parameters['topHightSpeciality']
  }
  if (parameters['topDerma'] !== undefined) {
    queryParameters['top_derma'] = parameters['topDerma']
  }
  if (parameters['hightSpeciality'] !== undefined) {
    queryParameters['hight_speciality'] = parameters['hightSpeciality']
  }
  if (parameters['organization'] !== undefined) {
    queryParameters['organization'] = parameters['organization']
  }
  if (parameters['publicOrganization'] !== undefined) {
    queryParameters['public_organization'] = parameters['publicOrganization']
  }
  if (parameters['updatedStartDate'] !== undefined) {
    queryParameters['updated_start_date'] = parameters['updatedStartDate']
  }
  if (parameters['updatedEndDate'] !== undefined) {
    queryParameters['updated_end_date'] = parameters['updatedEndDate']
  }
  if (parameters['brands'] !== undefined) {
    queryParameters['brands'] = parameters['brands']
  }
  if (parameters['topGeneric'] !== undefined) {
    queryParameters['top_generic'] = parameters['topGeneric']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['ordering'] !== undefined) {
    queryParameters['ordering'] = parameters['ordering']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Detail product
 * request: segment_vooyfood_product_read
 * url: segment_vooyfood_product_readURL
 * method: segment_vooyfood_product_read_TYPE
 * raw_url: segment_vooyfood_product_read_RAW_URL
 * @param fUuid - 
 */
export const segment_vooyfood_product_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/product/{f_uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{f_uuid}', `${parameters['fUuid']}`)
  if (parameters['fUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_product_read_RAW_URL = function() {
  return '/segment/vooyfood/product/{f_uuid}/'
}
export const segment_vooyfood_product_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_product_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/product/{f_uuid}/'
  path = path.replace('{f_uuid}', `${parameters['fUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_restaurant_list
 * url: segment_vooyfood_restaurant_listURL
 * method: segment_vooyfood_restaurant_list_TYPE
 * raw_url: segment_vooyfood_restaurant_list_RAW_URL
 * @param uuid - 
 * @param publicName - Public name
 * @param country - 
 * @param town - 
 * @param colony - 
 * @param address - 
 * @param legalName - 
 * @param withPubPricesToCountry - Pharmacies with pub prices
 * @param distance - Filter by organization with presence near to point.Format: lat;lng;km. Example: 25.7040066471688;-100.31368999414681;7
 * @param insuranceAgainstUser - Filtra aseguradoras que están conectadas (sgmm) (patientcustomer) con el usuario actual
 * @param search - A search term.
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 */
export const segment_vooyfood_restaurant_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/restaurant/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_restaurant_list_RAW_URL = function() {
  return '/segment/vooyfood/restaurant/'
}
export const segment_vooyfood_restaurant_list_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_restaurant_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/restaurant/'
  if (parameters['uuid'] !== undefined) {
    queryParameters['uuid'] = parameters['uuid']
  }
  if (parameters['publicName'] !== undefined) {
    queryParameters['public_name'] = parameters['publicName']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['town'] !== undefined) {
    queryParameters['town'] = parameters['town']
  }
  if (parameters['colony'] !== undefined) {
    queryParameters['colony'] = parameters['colony']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['legalName'] !== undefined) {
    queryParameters['legal_name'] = parameters['legalName']
  }
  if (parameters['withPubPricesToCountry'] !== undefined) {
    queryParameters['with_pub_prices_to_country'] = parameters['withPubPricesToCountry']
  }
  if (parameters['distance'] !== undefined) {
    queryParameters['distance'] = parameters['distance']
  }
  if (parameters['insuranceAgainstUser'] !== undefined) {
    queryParameters['insurance_against_user'] = parameters['insuranceAgainstUser']
  }
  if (parameters['search'] !== undefined) {
    queryParameters['search'] = parameters['search']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: segment_vooyfood_restaurant_read
 * url: segment_vooyfood_restaurant_readURL
 * method: segment_vooyfood_restaurant_read_TYPE
 * raw_url: segment_vooyfood_restaurant_read_RAW_URL
 * @param uuid - 
 */
export const segment_vooyfood_restaurant_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/segment/vooyfood/restaurant/{uuid}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const segment_vooyfood_restaurant_read_RAW_URL = function() {
  return '/segment/vooyfood/restaurant/{uuid}/'
}
export const segment_vooyfood_restaurant_read_TYPE = function() {
  return 'get'
}
export const segment_vooyfood_restaurant_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/segment/vooyfood/restaurant/{uuid}/'
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: services_place_autocomplete_json_list
 * url: services_place_autocomplete_json_listURL
 * method: services_place_autocomplete_json_list_TYPE
 * raw_url: services_place_autocomplete_json_list_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const services_place_autocomplete_json_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/services/{role}/{role_uuid}/place/autocomplete/json/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const services_place_autocomplete_json_list_RAW_URL = function() {
  return '/services/{role}/{role_uuid}/place/autocomplete/json/'
}
export const services_place_autocomplete_json_list_TYPE = function() {
  return 'get'
}
export const services_place_autocomplete_json_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/services/{role}/{role_uuid}/place/autocomplete/json/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: services_place_details_json_list
 * url: services_place_details_json_listURL
 * method: services_place_details_json_list_TYPE
 * raw_url: services_place_details_json_list_RAW_URL
 * @param role - 
 * @param roleUuid - 
 */
export const services_place_details_json_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/services/{role}/{role_uuid}/place/details/json/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const services_place_details_json_list_RAW_URL = function() {
  return '/services/{role}/{role_uuid}/place/details/json/'
}
export const services_place_details_json_list_TYPE = function() {
  return 'get'
}
export const services_place_details_json_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/services/{role}/{role_uuid}/place/details/json/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ses_bounce_create
 * url: ses_bounce_createURL
 * method: ses_bounce_create_TYPE
 * raw_url: ses_bounce_create_RAW_URL
 * @param data - 
 */
export const ses_bounce_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/ses/bounce/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ses_bounce_create_RAW_URL = function() {
  return '/ses/bounce/'
}
export const ses_bounce_create_TYPE = function() {
  return 'post'
}
export const ses_bounce_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/ses/bounce/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ses_bounce_check_read
 * url: ses_bounce_check_readURL
 * method: ses_bounce_check_read_TYPE
 * raw_url: ses_bounce_check_read_RAW_URL
 * @param emailAddress - 
 */
export const ses_bounce_check_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/ses/bounce/check/{email_address}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{email_address}', `${parameters['emailAddress']}`)
  if (parameters['emailAddress'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: emailAddress'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ses_bounce_check_read_RAW_URL = function() {
  return '/ses/bounce/check/{email_address}/'
}
export const ses_bounce_check_read_TYPE = function() {
  return 'get'
}
export const ses_bounce_check_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/ses/bounce/check/{email_address}/'
  path = path.replace('{email_address}', `${parameters['emailAddress']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Public endpoint to list all availabe localizations
 * request: translation_available_list
 * url: translation_available_listURL
 * method: translation_available_list_TYPE
 * raw_url: translation_available_list_RAW_URL
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const translation_available_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/available/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_available_list_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/available/'
}
export const translation_available_list_TYPE = function() {
  return 'get'
}
export const translation_available_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/available/'
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Match HTTP_ACCEPT_LANGUAGE with available langtags in db and compare
using langcodes library.
* request: translation_bestlangtag_list
* url: translation_bestlangtag_listURL
* method: translation_bestlangtag_list_TYPE
* raw_url: translation_bestlangtag_list_RAW_URL
     * @param role - 
     * @param roleUuid - 
*/
export const translation_bestlangtag_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/bestlangtag/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_bestlangtag_list_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/bestlangtag/'
}
export const translation_bestlangtag_list_TYPE = function() {
  return 'get'
}
export const translation_bestlangtag_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/bestlangtag/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Private (auth required) endpoint to list translations
 * request: translation_private_list
 * url: translation_private_listURL
 * method: translation_private_list_TYPE
 * raw_url: translation_private_list_RAW_URL
 * @param bcp47Langtag - 
 * @param keyText - 
 * @param keyNamespaceText - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const translation_private_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/private/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyText'] !== undefined) {
    queryParameters['key__text'] = parameters['keyText']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_private_list_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/private/'
}
export const translation_private_list_TYPE = function() {
  return 'get'
}
export const translation_private_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/private/'
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyText'] !== undefined) {
    queryParameters['key__text'] = parameters['keyText']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Return a big json:
{
    'langtag': {
        'namespace': {
            'key': 'translation',
            'key2': 'translation2'
        }
    }
}
* request: translation_private_plain_list
* url: translation_private_plain_listURL
* method: translation_private_plain_list_TYPE
* raw_url: translation_private_plain_list_RAW_URL
     * @param bcp47Langtag - 
     * @param keyNamespaceText - 
     * @param role - 
     * @param roleUuid - 
*/
export const translation_private_plain_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/private/plain/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_private_plain_list_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/private/plain/'
}
export const translation_private_plain_list_TYPE = function() {
  return 'get'
}
export const translation_private_plain_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/private/plain/'
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Private (auth required) endpoint retrieve single translation
 * request: translation_private_read
 * url: translation_private_readURL
 * method: translation_private_read_TYPE
 * raw_url: translation_private_read_RAW_URL
 * @param keyText - 
 * @param langtag - 
 * @param namespace - 
 * @param role - 
 * @param roleUuid - 
 */
export const translation_private_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/private/{langtag}/{namespace}/{key__text}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{key__text}', `${parameters['keyText']}`)
  if (parameters['keyText'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: keyText'))
  }
  path = path.replace('{langtag}', `${parameters['langtag']}`)
  if (parameters['langtag'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: langtag'))
  }
  path = path.replace('{namespace}', `${parameters['namespace']}`)
  if (parameters['namespace'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: namespace'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_private_read_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/private/{langtag}/{namespace}/{key__text}/'
}
export const translation_private_read_TYPE = function() {
  return 'get'
}
export const translation_private_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/private/{langtag}/{namespace}/{key__text}/'
  path = path.replace('{key__text}', `${parameters['keyText']}`)
  path = path.replace('{langtag}', `${parameters['langtag']}`)
  path = path.replace('{namespace}', `${parameters['namespace']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Public endpoint to list translations
 * request: translation_public_list
 * url: translation_public_listURL
 * method: translation_public_list_TYPE
 * raw_url: translation_public_list_RAW_URL
 * @param bcp47Langtag - 
 * @param keyText - 
 * @param keyNamespaceText - 
 * @param limit - Number of results to return per page.
 * @param offset - The initial index from which to return the results.
 * @param role - 
 * @param roleUuid - 
 */
export const translation_public_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/public/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyText'] !== undefined) {
    queryParameters['key__text'] = parameters['keyText']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_public_list_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/public/'
}
export const translation_public_list_TYPE = function() {
  return 'get'
}
export const translation_public_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/public/'
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyText'] !== undefined) {
    queryParameters['key__text'] = parameters['keyText']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  if (parameters['limit'] !== undefined) {
    queryParameters['limit'] = parameters['limit']
  }
  if (parameters['offset'] !== undefined) {
    queryParameters['offset'] = parameters['offset']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Return a big json:
{
    'langtag': {
        'namespace': {
            'key': 'translation',
            'key2': 'translation2'
        }
    }
}
* request: translation_public_plain_list
* url: translation_public_plain_listURL
* method: translation_public_plain_list_TYPE
* raw_url: translation_public_plain_list_RAW_URL
     * @param bcp47Langtag - 
     * @param keyNamespaceText - 
     * @param role - 
     * @param roleUuid - 
*/
export const translation_public_plain_list = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/public/plain/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_public_plain_list_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/public/plain/'
}
export const translation_public_plain_list_TYPE = function() {
  return 'get'
}
export const translation_public_plain_listURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/public/plain/'
  if (parameters['bcp47Langtag'] !== undefined) {
    queryParameters['bcp47__langtag'] = parameters['bcp47Langtag']
  }
  if (parameters['keyNamespaceText'] !== undefined) {
    queryParameters['key__namespace__text'] = parameters['keyNamespaceText']
  }
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Public endpoint to retrieve single translation
 * request: translation_public_read
 * url: translation_public_readURL
 * method: translation_public_read_TYPE
 * raw_url: translation_public_read_RAW_URL
 * @param keyText - 
 * @param langtag - 
 * @param namespace - 
 * @param role - 
 * @param roleUuid - 
 */
export const translation_public_read = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translation/{role}/{role_uuid}/public/{langtag}/{namespace}/{key__text}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{key__text}', `${parameters['keyText']}`)
  if (parameters['keyText'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: keyText'))
  }
  path = path.replace('{langtag}', `${parameters['langtag']}`)
  if (parameters['langtag'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: langtag'))
  }
  path = path.replace('{namespace}', `${parameters['namespace']}`)
  if (parameters['namespace'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: namespace'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const translation_public_read_RAW_URL = function() {
  return '/translation/{role}/{role_uuid}/public/{langtag}/{namespace}/{key__text}/'
}
export const translation_public_read_TYPE = function() {
  return 'get'
}
export const translation_public_readURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translation/{role}/{role_uuid}/public/{langtag}/{namespace}/{key__text}/'
  path = path.replace('{key__text}', `${parameters['keyText']}`)
  path = path.replace('{langtag}', `${parameters['langtag']}`)
  path = path.replace('{namespace}', `${parameters['namespace']}`)
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
* Este endpoint hace uso de los permisos básicos de django para modelos.
Sólo los usuarios con permisos de crear objetos de tipo
tradukoj | get text file | add podrán usar este endpoint.

Asegurate de crear un usuario en la admin y darle este permiso.
* request: translations_updatebase_create
* url: translations_updatebase_createURL
* method: translations_updatebase_create_TYPE
* raw_url: translations_updatebase_create_RAW_URL
     * @param data - 
     * @param role - 
     * @param roleUuid - 
*/
export const translations_updatebase_create = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/translations/{role}/{role_uuid}/updatebase/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['data'] !== undefined) {
    body = parameters['data']
  }
  if (parameters['data'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: data'))
  }
  path = path.replace('{role}', `${parameters['role']}`)
  if (parameters['role'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: role'))
  }
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters['roleUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const translations_updatebase_create_RAW_URL = function() {
  return '/translations/{role}/{role_uuid}/updatebase/'
}
export const translations_updatebase_create_TYPE = function() {
  return 'post'
}
export const translations_updatebase_createURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/translations/{role}/{role_uuid}/updatebase/'
  path = path.replace('{role}', `${parameters['role']}`)
  path = path.replace('{role_uuid}', `${parameters['roleUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}