<template>
  <VLayout
    upline
    :title="title"
    :subtitle="$t('addressform.subtitle')"
  >
    <VAddressForm v-bind:a_uuid="aUuid" class="mb-3" />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VAddressForm from "@/components/AddressForm";

export default {
  props: {
    pt_uuid: String,
  },
  components: {
    VLayout,
    VAddressForm,
  },
  data() {
    return {
      aUuid: this.$route.params.aUuid,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        addressform: {
          title_add: "Add address",
          title_edit: "Edit address",
          subtitle: "Please fill in the data, the fields marked with * are essential",
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  computed: {
    title() {
      if (this.aUuid) {
        return this.$t('addressform.title_edit');
      }
      return this.$t('addressform.title_add')
    },
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.my_addresses",
          to: { name: "addresses", props: {pt_uuid: this.pt_uuid} },
        },
        {
          langkey: "breadcrumb.addressform_title",
          to: { name: "addresses" },
        },
      ]);
    },
  },
};
</script>
