<template>
  <div v-if="show">
    <div v-if="showdrugtable">
      <b-table
        responsive
        :stacked="stacked"
        class="mb-0 table-drugs"
        :items="items"
        :fields="items_fields"
        outlined
        hover
        head-variant="gray"
      >
        <template v-slot:cell(dirty)="data" v-if="dirtycolumn">
          <div v-show="isInsuranceRefundProductDirty(data.item)">
            <font-awesome-icon
              class="mr-2"
              icon="exclamation-triangle"
              v-b-tooltip.hover
              :title="$t('drugs_summary.not_saved')"
            />
            <span class="d-sm-none">{{ $t("drugs_summary.not_saved") }}</span>
          </div>
          <div v-show="!isInsuranceRefundProductDirty(data.item)">
            <font-awesome-icon
              class="mr-2"
              icon="check-circle"
              v-b-tooltip.hover
              :title="$t('drugs_summary.is_saved')"
            />
            <span class="d-sm-none">{{ $t("drugs_summary.is_saved") }}</span>
          </div>
        </template>
        <!-- <template v-slot:cell(batch_expire)="data">
          <div>{{$t('drugs_summary.short_expiry_date')}}: {{data.item.expiry_date}}</div>
          <div>{{$t('drugs_summary.short_batch')}}: {{data.item.batch}}</div>
        </template> -->
        <template v-slot:cell(picture)="data">
          <b-img
            thumbnail
            alt="Thumbnail"
            :src="
              data.item.picture && data.item.picture.picture
                ? data.item.picture.picture
                : fallback
            "
            class="img-thumbnail img-fluid picture-cell"
            @error="imageLoadError"
          />
        </template>
        <template v-slot:cell(provider_name)="data" v-if="showprovider">
          <div v-if="data.item.price.provider">
            {{ data.item.price.provider.public_name }}
          </div>
          <div v-if="data.item.price.manager_public_name">
            {{ data.item.price.manager_public_name }}
          </div>
        </template>
        <template v-slot:cell(unit_price)="data">
          {{
            $formatMoney(
              data.item.price.no_tax_price,
              data.item.price.no_tax_price_currency
            )
          }}
        </template>
        <template v-slot:cell(taxes)="data">
          {{
            $formatMoney(
              data.item.price.all_included_price - data.item.price.no_tax_price,
              data.item.price.all_included_price_currency
            )
          }}
        </template>
        <template v-slot:cell(total)="data">
          {{
            $formatMoney(
              data.item.price.all_included_price * data.item.quantity,
              data.item.price.all_included_price_currency
            )
          }}
        </template>
        <template v-slot:cell(actions)="data" v-if="actionscolumn">
          <b-link
            @click="onDeteleButtonClick($event, data.item)"
            class="table-link-action"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-link>
        </template>
      </b-table>
      <div
        class="w-100 mt-4 mt-sm-0 pb-3 pt-3 font-weight-bold text-center add-button-container border-right border-left border-bottom"
        v-if="actionbutton"
      >
        <b-button
          type="button"
          variant="outline-primary-inline-compact"
          @click="onActionButtonClick()"
        >
          <plus-circle-outline-icon
            v-if="actionbuttonicon == 'plus-icon'"
            class="plusicon"
            :size="22"
          />
          <font-awesome-icon :icon="actionbuttonicon" v-else />
          {{ actionButtonText }}
        </b-button>
        <b-form-invalid-feedback :state="state">
          {{ invalidFeedback }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <b-container
      fluid
      class="summary-container px-0 mx-0 test"
      v-if="showamount"
    >
      <b-row no-gutters>
        <b-col
          cols="12"
          xs="12"
          lg="6"
          offset-lg="6"
          class="summary-column pr-0"
        >
          <b-container fluid class="px-0 mx-0 test2">
            <b-row no-gutters>
              <b-col cols="12" xs="12" lg="6">
                <table
                  v-if="amount"
                  class="table b-table table-summary table-summary-left table-bordered table-hover mr-2 pr-0 selectable"
                >
                  <tr>
                    <td>
                      {{ $t("drugs_summary.deductible") }}
                      <small v-if="insurance && !isNaN(Number(insurance.deductible))">
                        ({{ $formatMoney(insurance.deductible, insurance.deductible_currency) }})
                      </small>
                    </td>
                    <td class="text-right">{{ amount_deductible }}</td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("drugs_summary.coinsurance") }}
                      <small v-if="insurance && insurance.coinsurance_type == 0 && !isNaN(Number(insurance.coinsurance))">
                        ({{ $formatPercent(insurance.coinsurance) }})
                      </small>
                    </td>
                    <td class="text-right">{{ amount_coinsurance }}</td>
                  </tr>
                  <tr class="total-row">
                    <td>{{ $t("drugs_summary.patient_total") }}</td>
                    <td class="text-right">
                      <span v-if="amount.buyer_total">{{
                        $formatMoney(amount.buyer_total)
                      }}</span
                      ><span v-else>0</span>
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" xs="12" lg="6" class="px-0 mx-0">
                <table
                  v-if="amount"
                  class="table b-table table-summary table-summary-right table-bordered table-hover ml-0 mr-0 pr-2 selectable"
                >
                  <tr
                    v-for="(subtotal, index) in amount_subtotals"
                    :key="index"
                  >
                    <td>{{ $t("drugs_summary.subtotal") }}</td>
                    <td class="text-right">{{ $formatMoney(subtotal) }}</td>
                  </tr>
                  <tr
                    v-for="(subtotal, index) in amount_subtotal_tax"
                    :key="'tax' + index"
                  >
                    <td>{{ $t("drugs_summary.taxes") }}</td>
                    <td class="text-right">{{ $formatMoney(subtotal) }}</td>
                  </tr>
                  <tr class="total-row">
                    <td>{{ $t("drugs_summary.insurance_total") }}</td>
                    <td class="text-right">
                      <span v-if="amount.provider_total">{{
                        $formatMoney(amount.provider_total)
                      }}</span
                      ><span v-else>0</span>
                    </td>
                  </tr>
                  <tr class="total-row" @click="handleTotalClick()">
                    <td class="h5">
                      <strong>{{ $t("drugs_summary.total") }}</strong>
                    </td>
                    <td class="text-right h5">
                      <strong class="text-nowrap" v-if="amount.total">{{
                        $formatMoney(amount.total)
                      }}</strong
                      ><strong v-else>0</strong>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import {
  AMOUNT_TYPE_SUBTOTAL,
  AMOUNT_TYPE_SUBTOTAL_TAX,
  AMOUNT_TYPE_DEDUCTIBLE,
  AMOUNT_TYPE_COINSURANCE,
  // AMOUNT_TYPE_HANDLING_FEE,
  // AMOUNT_TYPE_SHIPPING,
  // AMOUNT_TYPE_SHIPPING_DISCOUNT,
  // AMOUNT_TYPE_CUSTOM_FEE,
  // COINSURANCE_TYPE_PERCENT,
  // COINSURANCE_TYPE_STATIC
} from "@/lib/amount.js";

export default {
  name: "v-drugs-summary",
  props: {
    items: Array,
    dirtycolumn: {
      default: false,
      type: Boolean,
    },
    actionscolumn: {
      default: false,
      type: Boolean,
    },
    actionbutton: {
      default: false,
      type: Boolean,
    },
    actionbuttontext: {
      default: undefined,
      type: String,
    },
    actionbuttonicon: {
      default: "plus-icon",
      type: String,
    },
    amount: {
      default: undefined,
      type: Object,
    },
    insurance: {
      default: undefined,
      type: Object,
    },
    showprovider: {
      default: false,
      type: Boolean,
    },
    showamount: {
      default: true,
      type: Boolean,
    },
    stacked: {
      default: "sm",
      type: String,
    },
    showdrugtable: {
      default: true,
      type: Boolean,
    },
    state: {
      default: null,
      type: Boolean,
    },
    "invalid-feedback": {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      show: true,
      fallback: require("@/static/fallback_drug_picture.jpg"),
    };
  },
  computed: {
    items_fields() {
      return [
        {
          key: "dirty",
          label: "",
        },
        {
          key: "picture",
          label: this.$t("drugs_summary.picture"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "ean",
          label: this.$t("drugs_summary.headtable_ean"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            if (item.manual_product_ean) {
              return item.manual_product_ean;
            }
            return value;
          },
        },
        {
          key: "default_description",
          label: this.$t("drugs_summary.headtable_drug_name"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            if (item.manual_product_name) {
              return item.manual_product_name;
            }
            return value;
          },
        },
        // {
        //   key: "batch_expire",
        //   label: this.$t("drugs_summary.headtable_batch_expire"),
        //   class: "selectable",
        //   thClass: "unselectable"
        // },
        {
          key: "laboratory_name",
          label: this.$t("drugs_summary.headtable_laboratory"),
          class: "selectable",
          thClass: "unselectable",
          formatter: (value, key, item) => {
            if (item.manual_laboratory_name) {
              return item.manual_laboratory_name;
            }
            return value;
          },
        },
        {
          key: "provider_name",
          label: this.$t("drugs_summary.provider_name"),
          class: "selectable",
          thClass: this.showprovider ? "unselectable" : "unselectable d-none",
          tdClass: this.showprovider ? "selectable" : "selectable d-none",
        },
        {
          key: "unit_price",
          label: this.$t("drugs_summary.headtable_unit_price"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "quantity",
          label: this.$t("drugs_summary.headtable_quantity"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "taxes",
          label: this.$t("drugs_summary.headtable_taxes"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "total",
          label: this.$t("drugs_summary.headtable_total"),
          class: "selectable",
          thClass: "unselectable",
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
    // TODO: obsoleto
    internal_summary: function () {
      var subtotal = 0;
      var taxes = 0;
      var total = 0;
      var currency = "XXX";
      this.items.forEach((element) => {
        total += element.price.all_included_price * element.quantity;
        subtotal += element.price.no_tax_price * element.quantity;
        taxes += element.price.all_included_price - element.price.no_tax_price;
        currency = element.price.base_currency;
      });
      return {
        subtotal: subtotal,
        taxes: taxes,
        total: total,
        currency: currency,
      };
    },
    amount_deductible: function () {
      if (!this.amount) {
        return "0";
      }
      if (!this.amount.details) {
        return "0";
      }

      let detail = this.amount.getDetailByType(AMOUNT_TYPE_DEDUCTIBLE);
      if (!detail) {
        return "0";
      }
      return this.$formatMoney(detail);
    },
    amount_coinsurance: function () {
      if (!this.amount) {
        return "0";
      }
      if (!this.amount.details) {
        return "0";
      }

      let detail = this.amount.getDetailByType(AMOUNT_TYPE_COINSURANCE);
      if (!detail) {
        return "0";
      }
      return this.$formatMoney(detail);
    },
    amount_subtotals: function () {
      if (!this.amount) {
        return "0";
      }
      if (!this.amount.details) {
        return "0";
      }
      // Aquí devolvemos un array, ojo con eso
      return this.amount.getAllDetailByType(AMOUNT_TYPE_SUBTOTAL);
    },
    amount_subtotal_tax: function () {
      if (!this.amount) {
        return "0";
      }
      if (!this.amount.details) {
        return "0";
      }
      return this.amount.getAllDetailByType(AMOUNT_TYPE_SUBTOTAL_TAX);
    },
    actionButtonText: function () {
      if (this.actionbuttontext) {
        return this.actionbuttontext;
      }
      return this.$t("drugs_summary.add_drug_button");
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        drugs_summary: {
          picture: "Image",
          provider_name: "Provider",
          headtable_ean: "Ean",
          headtable_drug_name: "Drug name",
          headtable_laboratory: "Laboratory",
          headtable_quantity: "Quantity",
          headtable_batch_expire: "Batch/Expiry date",
          headtable_unit_price: "Unit price",
          headtable_taxes: "Taxes",
          headtable_total: "Total",
          subtotal: "Subtotal",
          taxes: "Taxes",
          total: "Total",
          is_saved: "This product is saved",
          not_saved: "This product is not saved yet!",
          short_expiry_date: "ED",
          short_batch: "B",
          deductible: "Deductible",
          coinsurance: "Coinsurance",
          patient_total: "Patient total",
          insurance_total: "Insurance total",
          add_drug_button: "Add new drug",
        },
      },
    },
  },
  // mounted: function () {
  // },
  methods: {
    isInsuranceRefundProductDirty(item) {
      return item.dirt;
    },
    imageLoadError(evt) {
      evt.srcElement.src = require("@/static/fallback_drug_picture.jpg");
    },
    handleTotalClick() {
      this.$emit("summary:currency_switch");
    },
    onDeteleButtonClick(evt, item) {
      evt.preventDefault();
      this.$emit("deteleButtonClick", evt, item);
    },
    onActionButtonClick() {
      this.$emit("actionButtonClick");
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.picture-cell {
  width: 5rem;
}

@include media-breakpoint-down(sm) {
  /deep/ .table-drugs {
    tr > td:first-of-type {
      grid-template-columns: 100% !important;
      text-align: center;
    }
  }
}

.add-button {
  background-color: $gray-100 !important;
  color: black !important;
  border-color: $table-gray-border-color !important;
  border-radius: 0 !important;
  display: block !important;

  span {
    font-size: 1rem;
    margin: initial !important;
    padding: 0 !important;
  }
  @include media-breakpoint-up(sm) {
    border-top: 0;
  }
}

.total-row {
  font-weight: bold;
  cursor: pointer;
}

.table-summary {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  margin-bottom: 0 !important;

  > tr:first-of-type > td {
    border-top: 0;
  }
}

.table-summary-right {
  tr td:last-child {
    border-right: 0;
  }
  tr:last-child {
    td {
      border: 0;
    }
  }
}

.table-summary-left {
  width: 90%;
  @include media-breakpoint-down(ipad-horizontal) {
    width: 100%;
  }
}

.summary-container {
  border-left: 1px solid $table-gray-border-color;
  border-right: 1px solid $table-gray-border-color;
  border-bottom: 1px solid $table-gray-border-color;
}

.summary-column {
  border-left: 0;
}

@include media-breakpoint-up(lg) {
  // .summary-column {
  //   border-left: 1px solid $table-gray-border-color;
  // }
}
</style>
