import {
  drugtransaction_patient_insurance_insuranceauthorization_create,
  drugtransaction_patient_insurance_insuranceauthorization_read,
  drugtransaction_patient_insurance_insuranceauthorization_draft_read,
  drugtransaction_patient_insuranceauthorization_draft_list,
  drugtransaction_patient_insurance_insuranceauthorization_partial_update,
  drugtransaction_patient_insurance_insuranceauthorization_list,
  drugtransaction_patient_insuranceauthorization_list,
  drugtransaction_nopatient_insurance_insuranceauthorization_create,
  drugtransaction_nopatient_insurance_insuranceauthorization_read,
  drugtransaction_nopatient_insurance_insuranceauthorization_draft_read,
  drugtransaction_nopatient_insuranceauthorization_draft_list,
  drugtransaction_nopatient_insurance_insuranceauthorization_partial_update,
  drugtransaction_nopatient_insurance_insuranceauthorization_list,
  drugtransaction_nopatient_insuranceauthorization_list,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    draft_insuranceauthorizations: [],
    active_insuranceauthorization: undefined,
    feedback_insuranceauthorization: undefined,
  },
  actions: {
    CREATE_PATIENT_INSURANCE_DRAFT_INSURANCEAUTHORIZATION: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_create
          : drugtransaction_nopatient_insurance_insuranceauthorization_create;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          data: payload.data,
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEAUTHORIZATION", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_LIST: (
      undefined,
      payload
    ) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_list
          : drugtransaction_nopatient_insurance_insuranceauthorization_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          offset: offset,
          limit: payload.per_page,
          search: payload.search || undefined,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DONE_PATIENT_INSURANCE_DRAFT_INSURANCEAUTHORIZATION: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_partial_update
          : drugtransaction_nopatient_insurance_insuranceauthorization_partial_update;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          data: { object_status: 2 },
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEAUTHORIZATION", response.data);
            commit("MUTATE_FEEDBACK_INSURANCEAUTHORIZATION", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_INSURANCE_INSURANCEAUTHORIZATION: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_read
          : drugtransaction_nopatient_insurance_insuranceauthorization_read;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_INSURANCE_DRAFT_INSURANCEAUTHORIZATION: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_draft_read
          : drugtransaction_nopatient_insurance_insuranceauthorization_draft_read;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEAUTHORIZATION", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_ALL_INSURANCE_DRAFT_INSURANCEAUTHORIZATION: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insuranceauthorization_draft_list
          : drugtransaction_nopatient_insuranceauthorization_draft_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
        }).then(
          response => {
            commit("MUTATE_ALL_DRAFT_INSURANCEAUTHORIZATION", {
              data: response.data.results,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_ALL_INSURANCEAUTHORIZATION: ({ commit }, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insuranceauthorization_list
          : drugtransaction_nopatient_insuranceauthorization_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          offset: offset,
          limit: payload.per_page,
          ...payload,
        }).then(
          response => {
            commit("MUTATE_ALL_INSURANCEAUTHORIZATION", {
              data: response.data.results,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_FEEDBACK_INSURANCEAUTHORIZATION: (state, payload) => {
      Vue.set(state, "feedback_insuranceauthorization", payload);
    },
    MUTATE_DRAFT_INSURANCEAUTHORIZATION: (state, payload) => {
      let draft_insuranceauthorizations = state.draft_insuranceauthorizations;
      let new_draft_insuranceauthorizations = [];
      let found_in_state = false;

      // recorremos los draft insurance auth actuales en state
      draft_insuranceauthorizations.forEach(function(
        draft_insuranceauthorization
      ) {
        // si el payload ya se encuentra en state
        if (payload.uuid == draft_insuranceauthorization.uuid) {
          // marcamos banderita de encontrado
          found_in_state = true;
          // y lo añadimos si payload es draft, de lo contrario se trata
          // de un insuranceauthorization que ha cambiado de draft a done y por
          //tanto no lo queremos
          if (payload.object_status == 1) {
            new_draft_insuranceauthorizations.push(payload);
          }
          return;
        }
        // mantenemos los reembolsos que no soy payload tal y como estaban
        new_draft_insuranceauthorizations.push(draft_insuranceauthorization);
      });

      // si payload está en estado draft y no ha sido encontrado en
      // state, lo añadimos (es un draft nuevo)
      if (payload.object_status == 1 && !found_in_state) {
        new_draft_insuranceauthorizations.push(payload);
      }

      // ponemos el insuranceauthorization activo a indefinido
      Vue.set(state, "active_insuranceauthorization", undefined);
      // si el payload está en modo draft, lo añadimos a activo, de lo contrario
      // dejamos el active_insuranceauthorization vacío
      if (payload.object_status == 1) {
        Vue.set(state, "active_insuranceauthorization", payload);
      }

      // actualizamos state con los draft correctos
      Vue.set(
        state,
        "draft_insuranceauthorizations",
        new_draft_insuranceauthorizations
      );
    },
    MUTATE_ALL_DRAFT_INSURANCEAUTHORIZATION: (state, payload) => {
      Vue.set(state, "draft_insuranceauthorizations", payload.data);
    },
    MUTATE_ALL_INSURANCEAUTHORIZATION: (state, payload) => {
      Vue.set(state, "insuranceauthorizations", payload.data);
    },
  },
  getters: {
    getDraftInsuranceAuthorizationByInsuranceUUID: state => (
      i_uuid,
      insuranceauthorization_uuid
    ) => {
      let draft_insuranceauthorizations = state.draft_insuranceauthorizations;
      let found = undefined;
      draft_insuranceauthorizations.forEach(function(insuranceauthorization) {
        if (!insuranceauthorization.insurance) {
          return;
        }
        if (
          insuranceauthorization.insurance.uuid == i_uuid &&
          insuranceauthorization.uuid == insuranceauthorization_uuid
        ) {
          found = insuranceauthorization;
        }
      });
      return found;
    },
    getFeedbackInsuranceAuthorization: state => {
      return state.feedback_insuranceauthorization;
    },
  },
};
