<template>
  <b-container fluid class="p-0 m-0">
    <b-row>
      <b-col
        cols="12"
        md="4"
        v-for="(person, index) in items"
        :key="index"
        class="pb-5"
      >
        <b-card
          :title="person.firstname"
          title-tag="h5"
          class="text-uppercase"
        >
          <b-button
            size="lg"
            class="float-left mb-3"
            variant="secondary-inline-reverse"
            v-on:click="onDeleteClick(person)"
          >
            {{ $t('patient_card.delete_button') }}
          </b-button>
          <b-button size="lg" class="float-right mb-3" variant="primary" v-on:click="onItemClick(person)">
            {{ $t('patient_card.enter_button') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  name: "natural-person-card-list",
  props: {
    items: Array,
  },
  fallbackI18n: {
    messages: {
      und: {
        patient_card: {
          firstname: "First name",
          address: "Address",
          enter_button: "Select",
          delete_button: "Delete",
        },
      },
    },
  },
  methods: {
    onItemClick(item) {
      this.$emit("org:click", item);
    },
    onDeleteClick(item) {
      this.$emit("org:delete", item);
    },
  },
};
</script>


<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}
</style>
