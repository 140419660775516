/****************************************
  PlatformDetect
  platform-detect.js
  (C) Develatio Technologies S.L. 2018
*****************************************

Detects on what platform we're currently running
****************************************/

export default {
  methods: {
    isApp() {
      const v = String(window.app_version);
      return v != "null" && v != "undefined";
    },

    isiOSApp() {
      return this.isApp() && String(window.app_version).indexOf("iOSApp") > -1;
    },

    isAndroid() {
      return this.isApp() && String(window.app_version).indexOf("AndroidApp") > -1;
    },

    AppVersion() {
      return this.isApp() && String(window.app_version).split("/")[1];
    },
  },
};
