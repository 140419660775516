<template>
  <div>
    <vue-element-loading :active="loading" />
    <b-img
      :src="source"
      :alt="alt"
      :thumbnail="thumbnail"
      fluid-grow
      @error="imageLoadError"
      @load="imageLoad"
    ></b-img>
  </div>
</template>


<script>
export default {
  name: "picture-fallback",
  props: {
    picture: Object,
    src: String,
    alt: String,
    type: {
      type: String,
      default: "product",
    },
    thumbnail: Boolean,
  },
  data () {
    return {
      loading: true,
      fallbacks: {
        product: require("@/static/fallback_drug_picture.jpg"),
        client: require("@/static/fallback_client.png"),
      },
    };
  },
  computed: {
    fallback () {
      return this.fallbacks[this.type];
    },
    source () {
      if (this.picture) {
        return this.picture.picture || this.fallback;
      }
      return this.src || this.fallback;
    },
  },
  methods: {
    imageLoadError(evt) {
      evt.srcElement.src = this.fallback;
    },
    imageLoad() {
      this.loading = false;
    },
  },
};
</script>
