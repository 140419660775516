<template>
  <div>
    <vue-element-loading
      class="behind_modal_z_index"
      :active="hasPaymentIntention"
      color="#ffffff"
      background-color="rgba(0, 0, 0, .7)"
      :text="$t('checkout_control_payment.payment_in_progress')"
    />
    <div v-if="options">
      <div v-for="(payment_configuration, index) in options" :key="index">
        <!-- Allow only webpay and banktransfer -->
        <div
          class="align-middle border p-3 mb-3"
          v-if="computedAmount && payment_configuration.payment_method.payment_method == 9 || payment_configuration.payment_method.payment_method == 4"
        >
          <strong>
            {{ $t("checkout_control_payment.method") }}:
            {{ payment_configuration.payment_method.name }}
          </strong>
          <br />
          <strong>
            {{ $t("checkout_control_payment.total") }}:
            {{ computedAmount.money.toLocaleString() }}
            <span v-if="payment_configuration.percent_increment">
              + {{ $formatPercent(payment_configuration.percent_increment) }}
            </span>
          </strong>
          <b-alert
            show
            variant="danger"
            v-if="
              payment_configuration.payment_method.payment_method == 1 &&
                payment_configuration.paypal_sandbox
            "
          >
            {{ $t("checkout_control_payment.error.paypal_sandbox_mode") }}
          </b-alert>
          <div
            v-if="
              payment_configuration.payment_method.payment_method == 1 &&
                payment_configuration.paypal_client_id
            "
          >
            <PayPal
              :amount="computedAmount.total"
              :currency="computedAmount.currency"
              :client="
                payment_configuration.paypal_sandbox
                  ? { sandbox: payment_configuration.paypal_client_id }
                  : { production: payment_configuration.paypal_client_id }
              "
              :env="
                payment_configuration.paypal_sandbox ? 'sandbox' : 'production'
              "
              :commit="false"
              v-on:payment-authorized="
                setPaymentConf(payment_configuration);
                onPaymentAuthorized($event);
              "
              v-on:payment-completed="
                setPaymentConf(payment_configuration);
                onPaymentCompleted($event);
              "
              v-on:payment-cancelled="
                setPaymentConf(payment_configuration);
                onPaymentCancelled($event);
              "
            >
            </PayPal>
          </div>
          <div
            v-if="
              payment_configuration.payment_method.payment_method == 4 &&
                payment_configuration.bank_wire_number
            "
          >
            <CheckoutControlPaymentBankTransfer
              :payment_configuration="payment_configuration"
              :pharmacy_uuid="pharmacy_uuid"
              v-on:payment-authorized="
                setPaymentConf(payment_configuration);
                onPaymentAuthorized($event);
              "
            />
          </div>
          <div v-if="payment_configuration.payment_method.payment_method == 9">
            <CheckoutControlPaymentWebPayPlus
              :payment_configuration="payment_configuration"
              :pharmacy_uuid="pharmacy_uuid"
              :checkout_uuid="checkout_uuid"
              v-on:payment-authorized="
                setPaymentConf(payment_configuration);
                onPaymentAuthorized($event);
              "
              v-on:payment-intention="$emit('payment-intention', arguments[0])"
              v-on:payment-error="
                $emit('payment-error', arguments[0]);
                pushWebPayError(arguments[0]);
              "
              v-on:payment-intention-expires="
                $emit('payment-intention-expires', arguments[0]);
                pushWebPayError(arguments[0]);
              "
            />
            <b-alert
              show
              variant="danger"
              v-for="(error, index) in computed_webpay_pay_errors"
              :key="index"
            >
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>{{ $t("checkout_control_payment.error.payment_error") }}</span>
            </b-alert>
          </div>
        </div>
        <div v-else>
          <b-alert show variant="danger">
            {{ $t("checkout_control_payment.error.cannot_calculate_amount") }}
            <div>
              1. {{ $t("checkout_control_payment.error.amount_reason1") }}
            </div>
            <div>
              2. {{ $t("checkout_control_payment.error.amount_reason2") }}
            </div>
            <div>
              3. {{ $t("checkout_control_payment.error.amount_reason3") }}
            </div>
          </b-alert>
        </div>
      </div>
    </div>
    <div v-else>
      <b-alert show variant="danger">
        {{ $t("checkout_control_payment.no_methods_available") }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Money } from "@/lib/money.js";
import CheckoutControlPaymentBankTransfer from "@/components/CheckoutControlPaymentBankTransfer";
import CheckoutControlPaymentWebPayPlus from "@/components/CheckoutControlPaymentWebPayPlus";
// for SSR compatibility
const PayPal = process.browser
  ? require("vue-paypal-checkout").default
  : undefined;

const error_to_langkey_translator = {
  "1": "checkout_control_payment.error.webpay_denied_rejected_by_bank",
  "2": "checkout_control_payment.error.webpay_denied_refer_to_card_issuer",
  "3":
    "checkout_control_payment.error.webpay_denied_refer_to_special_conditions_card_issuer",
  "4": "checkout_control_payment.error.webpay_denied_pickup_card",
  "5": "checkout_control_payment.error.webpay_denied_do_not_honor",
  "6": "checkout_control_payment.error.webpay_denied_error_or_invalid_card",
  "7": "checkout_control_payment.error.webpay_denied_invalid_transaction",
  "8": "checkout_control_payment.error.webpay_denied_invalid_amount",
  "9": "checkout_control_payment.error.webpay_denied_invalid_card_number",
  "10": "checkout_control_payment.error.webpay_denied_restricted_card",
  "11": "checkout_control_payment.error.webpay_denied_lost_card",
  "12": "checkout_control_payment.error.webpay_denied_stolen_card",
  "13": "checkout_control_payment.error.webpay_denied_no_sufficient_funds",
  "14": "checkout_control_payment.error.webpay_denied_expired_card",
  "15": "checkout_control_payment.error.webpay_denied_no_card_record",
  "16":
    "checkout_control_payment.error.webpay_denied_transaction_not_permitted_to_cardholder",
  "17": "checkout_control_payment.error.webpay_denied_exceeds_amount_limit",
  "18": "checkout_control_payment.error.webpay_denied_restricted_card",
  "19": "checkout_control_payment.error.webpay_denied_frequency_limit",
  "20": "checkout_control_payment.error.webpay_denied_timeout",
  "21": "checkout_control_payment.error.webpay_denied_pin_tries_exceeded",
  "22": "checkout_control_payment.error.webpay_denied_invalid_account",
  "23": "checkout_control_payment.error.webpay_denied_invalid_track2",
  "24": "checkout_control_payment.error.webpay_denied_bad_amount",
  "25": "checkout_control_payment.error.webpay_denied_invalid_card",
  "26": "checkout_control_payment.error.webpay_denied_not_supported_card",
  "27": "checkout_control_payment.error.webpay_denied_invalid_route_service",
  "28": "checkout_control_payment.error.webpay_service_error_get_not_allowed",
  "29": "checkout_control_payment.error.webpay_service_error_bad_parameters",
  "30": "checkout_control_payment.error.webpay_service_error_bad_xml_data",
  "31": "checkout_control_payment.error.webpay_service_error_bad_xml",
  "32": "checkout_control_payment.error.webpay_service_error_bad_user_data",
  "33": "checkout_control_payment.error.webpay_service_error_system_error",
  "34": "checkout_control_payment.error.webpay_service_error_no_valid_card",
  "35": "checkout_control_payment.error.webpay_service_error_auth_failed",
  "36": "checkout_control_payment.error.webpay_service_error_3dsauth_failed",
};

export default {
  name: "cart-checkout-payment",
  components: {
    PayPal,
    CheckoutControlPaymentBankTransfer,
    CheckoutControlPaymentWebPayPlus,
  },
  props: {
    options: {
      type: Array,
      default: undefined,
    },
    pharmacy_uuid: {
      type: String,
      default: undefined,
    },
    checkout_uuid: {
      type: String,
      default: undefined,
    },
    amount: {
      type: Object,
      default: undefined,
    },
    webpay_pay_errors: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      wrong_amount: false,
      payment_conf: undefined,
      inner_webpay_pay_errors: [],
    };
  },
  computed: {
    ...mapState({
      payment_intentions: state => state.drugtransaction.payment_intentions,
    }),
    computedAmount() {
      var amount = this.amount;
      if (!amount) {
        return undefined;
      }
      var total = Number(amount.total.amount);
      var total_currency = amount.total.currency;
      return {
        total: total.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        currency: total_currency,
        money: new Money(total, total_currency),
      };
    },
    hasPaymentIntention() {
      if (!this.payment_intentions) {
        return false;
      }
      let filtered_payments = this.payment_intentions.filter(p => {
        // Search seller participant
        return p.pharmacy_uuid == this.pharmacy_uuid;
      });
      if (filtered_payments.length > 0) {
        return true;
      }
      return false;
    },
    computed_webpay_pay_errors() {
      return this.webpay_pay_errors || this.inner_webpay_pay_errors;
    },
  },
  mounted() {
    if (this.hasPaymentIntention) {
      this.$emit("payment-intention", null);
    }
  },
  methods: {
    pushWebPayError(err) {
      this.inner_webpay_pay_errors = [];
      let err_obj = {};
      if (err.has_expired) {
        err_obj = {
          message: this.$t(
            "checkout_control_payment.error.webpay_error_intention_expires"
          ),
        };
      } else if (err.intention) {
        let err_code = String(err.intention["response_code"]);
        if (error_to_langkey_translator[err_code]) {
          err_obj = {
            message: this.$t(error_to_langkey_translator[err_code]),
          };
        }
      }
      this.inner_webpay_pay_errors.push(err_obj);
      this.$emit("update:webpay_pay_errors", this.inner_webpay_pay_errors);
    },
    // hasPaymentIntention: function() {
    //   if (!this.payment_intentions) {
    //     return false;
    //   }
    //   let filtered_payments = this.payment_intentions.filter(p => {
    //     // Search seller participant
    //     return p.pharmacy_uuid == this.pharmacy_uuid;
    //   });
    //   if (filtered_payments.length > 0) {
    //     return true;
    //   }
    //   return false;
    // },
    setPaymentConf(conf) {
      this.payment_conf = Object.assign({}, conf);
    },
    onPaymentAuthorized: function(data) {
      this.$emit("payment-authorized", {
        payment_intention_uuid: data.payment_intention_uuid,
        pharmacy_uuid: this.pharmacy_uuid,
        payment_conf: Object.assign({}, this.payment_conf, {
          payer_id: data.payerID,
          payment_id: data.paymentID,
        }),
        paypal: {
          orderID: data.orderID,
          paymentID: data.paymentID,
          payerID: data.payerID,
        },
      });
    },
    onPaymentCompleted: function(response) {
      this.$emit("payment-completed", response);
    },
    onPaymentCancelled: function(data) {
      this.$emit("payment-cancelled", data);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        checkout_control_payment: {
          total: "Total",
          method: "Method",
          no_methods_available:
            "Sorry, this pharmacy doesn't have any payment methods for the selected shipping address",
          payment_in_progress: "Payment in progress...",
          error: {
            set_payment_method: "Cannot set payment method for this pharmacy",
            cannot_calculate_amount:
              "Sorry, we cannot calculate the total amount. Possible reasons:",
            amount_reason1: "Incompatible products and delivery currencies",
            amount_reason2: "Products or delivery option without currency",
            amount_reason3: "An error during total calculation",
            paypal_sandbox_mode: "Paypal is in sandbox mode",
            payment_error:
              "An error occurred and the intended transaction was not completed. Attempt the transaction again.",
            webpay_error_intention_expires:
              "Payment window has expired. Attempt the transaction again.",

            webpay_denied_rejected_by_bank:
              "Error: Payment has been declined. Your card may have been declined by your bank or card issuer.",
            webpay_denied_refer_to_card_issuer:
              "Error: Payment has been declined by by your bank or card issuer. Please, contact your bank or card issuer.",
            webpay_denied_refer_to_special_conditions_card_issuer:
              "Error: Payment has been declined. Please, contact your bank or card issuer for special conditions",
            webpay_denied_pickup_card:
              "Error: Payment has been declined. Please, pickup card.",
            webpay_denied_do_not_honor:
              "Error: Payment has been declined. Your card has been rejected.",
            webpay_denied_error_or_invalid_card:
              "Error: Payment has been declined. Your card is not valid.",
            webpay_denied_invalid_transaction:
              "Error: Payment has been declined. This transaction is invalid.",
            webpay_denied_invalid_amount:
              "Error: Payment has been declined. The amount is invalid.",
            webpay_denied_invalid_card_number:
              "Error: Payment has been declined. Invalid card number.",
            webpay_denied_restricted_card:
              "Error: Payment has been declined. Restricted card.",
            webpay_denied_lost_card:
              "Error: Payment has been declined. Lost card.",
            webpay_denied_stolen_card:
              "Error: Payment has been declined. STOLEN CARD.",
            webpay_denied_no_sufficient_funds:
              "Error: Payment has been declined. There are not enough funds.",
            webpay_denied_expired_card:
              "Error: Payment has been declined. Expired card.",
            webpay_denied_no_card_record:
              "Error: Payment has been declined. Unregistered card.",
            webpay_denied_transaction_not_permitted_to_cardholder:
              "Error: Payment has been declined. Transaction not allowed.",
            webpay_denied_exceeds_amount_limit:
              "Error: Payment has been declined. Exceeds withdrawal amount limit.",
            webpay_denied_frequency_limit:
              "Error: Payment has been declined. Exceeds withdrawal amount limit.",
            webpay_denied_timeout:
              "Error: Payment has been declined. Exceeds withdrawal frequency limit.",
            webpay_denied_pin_tries_exceeded:
              "Error: Payment has been declined. Allowable number of PIN tries exceeded.",
            webpay_denied_invalid_account:
              "Error: Payment has been declined. Invalid account.",
            webpay_denied_invalid_track2:
              "Error: Payment has been declined. Invalid Track2.",
            webpay_denied_bad_amount:
              "Error: Payment has been declined. Incorrect amount.",
            webpay_denied_invalid_card:
              "Error: Payment has been declined. Invalid card.",
            webpay_denied_not_supported_card:
              "Error: Payment has been declined. Not supported card.",
            webpay_denied_invalid_route_service:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_get_not_allowed:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_bad_parameters:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_bad_xml_data:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_bad_xml:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_bad_user_data:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_system_error:
              "Error: Payment has been declined. Attempt the transaction again.",
            webpay_service_error_no_valid_card:
              "Error: Payment has been declined. Invalid card.",
            webpay_service_error_auth_failed:
              "Error: Payment has been declined. The card cannot be authenticated.",
            webpay_service_error_3dsauth_failed:
              "Error: Payment has been declined. The card cannot be authenticated in 3DSecure.",
          },
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
.behind_modal_z_index {
  z-index: 999 !important;
}
</style>
