<template>
  <v-layout upline :bgImage="false" :title="$t('account.title')">
    <AccountMenu class="mb-3" />

    <div class="buttons-container">
      <b-button
        @click="$router.go(-1)"
        variant="secondary"
        class="d-none d-sm-none d-md-block"
      >{{ $t('home_user.return') }}</b-button>

      <b-button
        @click="signOut"
        variant="primary"
        class="d-none d-sm-none d-md-block"
      >{{ $t('navigation_bar.logout') }}</b-button>
    </div>

  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import AccountMenu from "@/components/AccountMenu";

export default {
  components: {
    VLayout,
    AccountMenu,
  },
  fallbackI18n: {
    messages: {
      und: {
        account: {
          title: "My Account",
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/LOGOUT").then(
        () => {
          this.$store.commit("auth/LOGOUT");
          this.routeToLogin();
        },
        () => {
          this.$store.commit("auth/LOGOUT");
          this.routeToLogin();
        }
      );
    },
    routeToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
      ]);
    },
  },
};
</script>

<style>
</style>
