<template>
  <v-b-nav tabs class="navtabs">
    <b-nav-item :to="{name: 'userhome'}" :active="latest_route_name == 'userhome'">{{ $t('navigation_insuranceauthorization.home') }}</b-nav-item>
    <b-nav-item
      :to="insuranceSelectionRoute"
      :active="latest_route_name == 'nopatient_insuranceauthorization_insurance_selection'"
    >
      {{ $t('navigation_insuranceauthorization.insurance_list') }}
    </b-nav-item>
    <!--<b-nav-item :active="latest_route_name == 'faq'">{{$t('navigation_insuranceauthorization.faq')}}</b-nav-item>-->
  </v-b-nav>
</template>

<script>
import { mapState } from "vuex";
import VBNav from "@/components/VBNav";

export default {
  computed: {
    ...mapState({
      latest_params: state => state.routehelper.latest_params,
      latest_route_name: state => state.routehelper.latest_route_name,
    }),
    insuranceSelectionRoute: function() {
      return {
        name: "nopatient_insuranceauthorization_insurance_selection",
      };
    },
  },
  fallbackI18n: {
    name: "insuranceauthorization-navigation",
    messages: {
      und: {
        navigation_insuranceauthorization: {
          home: "Home",
          insurance_list: "Insurance List",
          faq: "Faq's",
        },
      },
    },
  },
  components: {
    VBNav,
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  text-decoration: none;
}

.nav-tabs {
  margin-bottom: 0rem !important;
}
</style>
