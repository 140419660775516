<template>
  <VLayout upline :title="$t('major_medical_insurance.title')" :bgImage="false">
    <MajorMedicalInsuranceTableList :pt_uuid="$route.params.pt_uuid" />
    <div class="buttons-container">
      <b-button
        @click="$router.go(-1)"
        variant="secondary"
      >{{ $t('major_medical_insurance.return') }}</b-button>

      <b-button
        variant="primary"
        @click="routeTo({ name: 'patient_customer_data' })"
      >{{ $t('major_medical_insurance.add_new_patient_customer') }}</b-button>
    </div>

  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import MajorMedicalInsuranceTableList from "@/components/MajorMedicalInsuranceTableList";

export default {
  props: {
    pt_uuid: String,
  },
  components: {
    VLayout,
    MajorMedicalInsuranceTableList,
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
        {
          langkey: "breadcrumb.mmilist",
          to: { name: "breadcrumb.mayor_medical_insurance_list" },
        },
      ]);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        major_medical_insurance: {
          title: "Major medical health insurance list",
          add_new_patient_customer: "Add new",
          return: "Back",
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
};
</script>
<style lang="scss" scoped>
</style>
