import {
  organization_pharmacy_drugs_in_list,
  drugtransaction_patient_insurance_drugs_in_list,
  drugtransaction_nopatient_insurance_drugs_in_list,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    drugslist: {
      items: [],
      currentPage: 0,
      perPage: 20,
      totalRows: 0,
    },
    drugs: [],
  },
  actions: {
    REQUEST_PHARMACY_DRUGS_IN: ({ commit }, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        organization_pharmacy_drugs_in_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          pUuid: payload.p_uuid,
          offset: offset,
          limit: payload.per_page,
        }).then(
          response => {
            commit("MUTATE_DRUGS_IN", {
              per_page: payload.per_page,
              current_page: payload.current_page,
              data: response.data,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_INSURANCE_DRUGS_IN: ({ commit }, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_drugs_in_list
          : drugtransaction_nopatient_insurance_drugs_in_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          offset: offset,
          limit: payload.per_page,
          organization: payload.organization_uuid,
          search: payload.search || undefined,
        }).then(
          response => {
            commit("MUTATE_DRUGS_IN", {
              per_page: payload.per_page,
              current_page: payload.current_page,
              data: response.data,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_DRUGS_IN: (state, payload) => {
      var drugslist = state.drugslist;
      drugslist.items = payload.data.results;
      drugslist.perPage = payload.per_page;
      drugslist.currentPage = payload.current_page;
      drugslist.totalRows = payload.data.count;
      Vue.set(state, "drugslist", drugslist);
    },
    CLEAN_DRUGS_IN: state => {
      Vue.set(state, "drugslist", {
        items: [],
        currentPage: 0,
        perPage: 20,
        totalRows: 0,
      });
    },
  },
  getters: {},
};
