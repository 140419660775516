<template>
  <v-layout :showbreadcrumbs="false" fullwidth>
    <VHomeImgJumbo>
      <template v-slot:right>
        <VHomeImgJumboCard :title="$t('register.content_title')">
          <VRegister />
        </VHomeImgJumboCard>
      </template>
    </VHomeImgJumbo>
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VRegister from "@/components/RegisterForm";
import VHomeImgJumbo from "@/components/VHomeImgJumbo";
import VHomeImgJumboCard from "@/components/VHomeImgJumboCard";

export default {
  fallbackI18n: {
    messages: {
      und: {
        register: {
          box_title: "Register account",
          content_title: "Register",
        },
      },
    },
  },
  components: {
    VLayout,
    VRegister,
    VHomeImgJumbo,
    VHomeImgJumboCard,
  },
};
</script>

<style>
</style>
