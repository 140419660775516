<template>
  <div>
    <b-button
      size="sm"
      variant="primary"
      class="mt-1 mb-1"
      @click="startWebPayPlusPayment()"
    >
      {{ $t("checkout_control_payment_webpayplus.pay") }}
    </b-button>

    <b-modal
      :ref="'modal-webpayplus-' + payment_configuration.uuid"
      centered
      size="md"
      :title="$t('checkout_control_payment_webpayplus.title')"
      :busy="loading_webpayurl"
      ok-only
      ok-variant="secondary"
      :ok-title="$t('checkout_control_payment_webpayplus.cancel_button')"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div v-if="loading_webpayurl" class="mx-5">
        <p class="text-center px-5">
          {{ $t("checkout_control_payment_webpayplus.loading_webpayurl") }}
        </p>
      </div>
      <div class="pb-2">
        <vue-element-loading :active="loading_webpayurl" />
        <div v-if="payment_url">
          <b-embed
            sandbox="allow-same-origin allow-scripts allow-forms"
            class="fullheightiframe"
            type="iframe"
            aspect="fullheight"
            :src="payment_url"
            allowfullscreen
            @load="onIframeLoaded"
            @readystatechange="onIframeLoaded"
          ></b-embed>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "cart-checkout-payment-webpayplus",
  components: {},
  props: {
    payment_configuration: {
      type: Object,
      default: undefined,
    },
    pharmacy_uuid: {
      type: String,
      default: undefined,
    },
    checkout_uuid: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      payment_url: undefined,
      loading_webpayurl: true,
      intention_expires: undefined,
      payment_intention_refresh_interval: undefined,
      payment_intention_uuid: undefined,
    };
  },
  mounted() {
    // const old_on = this.$on;
    // this.$on = (...args) => {
    //   // custom logic here like pushing to a callback array or something
    //   console.log(args);
    //   old_on.apply(this, args);
    // };
  },
  destroyed: function() {
    clearInterval(this.payment_intention_refresh_interval);
  },
  methods: {
    // Refresca la intención de pago creada para determinar si el webhook ha
    // recibido confirmación.
    // También controla si la intención de pago ha expirado para cerrar
    // la ventana y emitir un evento de error.
    refreshPaymentIntention: function() {
      this.$store
        .dispatch("drugtransaction/READ_PAYMENT_INTENTION", {
          payment_intention_uuid: this.payment_intention_uuid,
        })
        .then(
          response => {
            if (response.data.status == 1) {
              return this.intentionPayed(response.data);
            }

            if (response.data.status > 0) {
              return this.intentionError(null, response.data);
            }

            let expires = new Date(response.data.expires);
            if (expires - new Date() <= 0) {
              return this.intentionExpires(response.data);
            }
          },
          err => {
            return this.intentionError(err);
          }
        );
    },
    intentionError(err = null, intention = null) {
      clearInterval(this.payment_intention_refresh_interval);
      this.$refs["modal-webpayplus-" + this.payment_configuration.uuid].hide();
      this.$emit("payment-error", {
        payment_conf: this.payment_configuration,
        error: err,
        intention: intention,
      });
    },
    intentionPayed(intention) {
      clearInterval(this.payment_intention_refresh_interval);
      this.$refs["modal-webpayplus-" + this.payment_configuration.uuid].hide();
      this.$emit("payment-authorized", {
        payment_conf: this.payment_configuration,
        payment_intention_uuid: this.payment_intention_uuid,
        intention: intention,
      });
    },
    intentionExpires(intention) {
      clearInterval(this.payment_intention_refresh_interval);
      this.$refs["modal-webpayplus-" + this.payment_configuration.uuid].hide();
      this.$emit("payment-intention-expires", {
        payment_conf: this.payment_configuration,
        intention: intention,
        has_expired: true,
      });
    },
    onIframeLoaded() {
      this.loading_webpayurl = false;
    },
    startWebPayPlusPayment() {
      this.payment_url = undefined;
      this.loading_webpayurl = true;
      this.$refs["modal-webpayplus-" + this.payment_configuration.uuid].show();

      this.$store
        .dispatch("drugtransaction/CREATE_PAYMENT_INTENTION", {
          data: {
            payment_configuration: this.payment_configuration.uuid,
            checkout_uuid: this.checkout_uuid,
          },
        })
        .then(
          response => {
            this.payment_url = response.data.payment_url;
            this.payment_intention_uuid = response.data.uuid;
            this.intention_expires = new Date(response.data.expires);
            this.payment_intention_refresh_interval = setInterval(
              function() {
                this.refreshPaymentIntention();
              }.bind(this),
              // every 5 seconds
              5000
            );
            this.$emit("payment-intention", response.data);
          },
          err => {
            this.$sentry({ capture: err });
            this.intentionError(err);
          }
        );
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        checkout_control_payment_webpayplus: {
          title: "WebPay Plus",
          pay: "Pay with WebPay Plus",
          loading_webpayurl: "Connecting to WebPay Plus services...",
          cancel_button: "Cancel",
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.embed-responsive-fullheight::before {
  padding-top: 140%;
}
</style>
