<template>
  <div>
    <vue-element-loading :active="loading" />
    <b-form @submit="sendForm" v-if="show">
      <b-container fluid class="pr-0 pl-0">
        <b-row>
          <b-col cols="12" lg="6" md="6" sm="12">
            <div id="invoice-left-form" class="mr-md-3">
              <legend class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 text-uppercase">
                {{ $t('insurance_risk_payment.insured_data_title') }}
              </legend>
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                class="text-left"
                :label="$t('insurance_risk_payment.insured_name_label')"
                :invalid_feedback="form_invalid_feedback.insured_name"
                :state="form_state.insured_name"
              >
                <template v-slot:label>
                  {{ $t('insurance_risk_payment.insured_name_label') }} <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="insured_name"
                    type="text"
                    v-model.trim="form.insured_name"
                    required
                    :state="form_state.insured_name"
                    :placeholder="$t('insurance_risk_payment.insured_name_placeholder')"
                  ></b-form-input>
                  <b-input-group-append>
                    <VMajorMedicalInsurancePatientSelector
                      v-on:patient-selected="onPatientSelected"
                      :preset-filters="{show_in_risk_payment: true}"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <VBootstrapFormGroup
                name="policy_num"
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                required
                :label="$t('insurance_risk_payment.policy_num_label')"
                :placeholder="$t('insurance_risk_payment.policy_num_placeholder')"
                :invalid_feedback="form_invalid_feedback.policy_num"
                :state="form_state.policy_num"
                v-model.trim="form.policy_num"
              />
              <b-form-group class="text-left">
                <b-form-checkbox
                  v-model="form.is_beneficiary"
                  :state="form_state.is_beneficiary"
                >
                  {{ $t("insurance_risk_payment.is_beneficiary") }}
                </b-form-checkbox>
              </b-form-group>

            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6" sm="12">
            <div id="invoice-right-form" class="ml-md-3">
              <legend class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 text-uppercase">
                {{ $t('insurance_risk_payment.payment_data_title') }}
              </legend>
              <b-form-group
                label-cols="12"
                label-cols-sm="12"
                label-cols-md="12"
                label-cols-lg="3"
                label-cols-xl="3"
                class="text-left"
                :label="$t('insurance_risk_payment.insurance_organization_label')"
                label-for="insurance_organization"
                :invalid-feedback="form_invalid_feedback.insurance_organization"
                :state="form_state.insurance_organization"
              >
                <template v-slot:label>
                  {{ $t('insurance_risk_payment.insurance_organization_label') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  v-model="form.insurance_organization_uuid"
                  :options="insurance_organization_options"
                  required
                ></b-form-select>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group class="text-left">
                    <b-form-checkbox
                      v-model="form.has_deductible"
                      :state="form_state.has_deductible"
                    >
                      {{ $t("insurance_risk_payment.has_deductible") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-form-input
                      :disabled="!form.has_deductible"
                      type="text"
                      :state="(form.has_deductible ?(form.deductible ? !isNaN(Number(form.deductible)) : form_state.deductible) : undefined)"
                      v-model="form.deductible"
                      :placeholder="$t('insurance_risk_payment.deductible_placeholder')"
                    ></b-form-input>
                    <b-dropdown
                      size="sm"
                      :disabled="!form.has_deductible"
                      :text="form.deductible_currency"
                      variant="outline-primary-thin"
                      slot="append"
                    >
                      <b-dropdown-item @click="toggleDeductibleCurrency('MXN')">MXN</b-dropdown-item>
                    </b-dropdown>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group class="text-left">
                    <b-form-checkbox
                      v-model="form.has_coinsurance"
                      :state="form_state.has_coinsurance"
                    >
                      {{ $t("insurance_risk_payment.has_coinsurance") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-form-input
                      :disabled="!form.has_coinsurance"
                      type="text"
                      :state="(form.has_coinsurance ?(form.coinsurance ? !isNaN(Number(form.coinsurance)) : form_state.coinsurance) : undefined)"
                      v-model="form.coinsurance"
                      :placeholder="$t('insurance_risk_payment.coinsurance_placeholder')"
                    ></b-form-input>
                    <b-dropdown
                      size="sm"
                      :disabled="!form.has_coinsurance"
                      :text="form.coinsurance_currency"
                      variant="outline-primary-thin"
                      slot="append"
                    >
                      <b-dropdown-item @click="toggleCoinsuranceCurrency('MXN')">MXN</b-dropdown-item>
                    </b-dropdown>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col></b-col>
          <b-col>
            <div
              class="d-flex justify-content-end text-uppercase h5 mt-3"
              v-if="total_amount_to_pay && !isNaN(Number(total_amount_to_pay.amount))"
            >
              <div class="total_text pl-4 pr-5">
                {{ $t('insurance_risk_payment.total_amount_to_pay') }}
              </div>
              <div class="font-weight-bold">
                {{ total_amount_to_pay.toLocaleString() }}
              </div>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <b-modal
        ref="payment_modal"
        modal-class="modal-square-theme"
        centered
        :title="$t('insurance_risk_payment.select_payment_method')"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
      >
        <!-- El objeto amount siempre debe tener el total actualizado, incluso
        si se cambia el transportista (ya que se hace una nueva petición del
        carrito a la api, lo que se trae los costes actualizados) -->
        <CheckoutControlPayment
          v-if="purchase_options && purchase_options.payments_configurations.length"
          :amount="amount"
          :options="purchase_options.payments_configurations"
          :pharmacy_uuid="transaction.organization_uuid"
          :checkout_uuid="checkout_uuid"
          v-on:payment-authorized="onPaymentAuthorized"
          v-on:payment-intention="onPaymentIntention"
          :webpay_pay_errors.sync="webpay_pay_errors"
        />
      </b-modal>

      <div class="buttons-container">
        <b-button
          @click="$router.go(-1)"
          variant="secondary"
          class="d-none d-sm-none d-md-block"
        >{{ $t('insurance_risk_payment.return_button') }}</b-button>

        <b-button
          :disabled="isNaN(Number(total_amount_to_pay.amount)) || total_amount_to_pay.amount <= 0"
          variant="primary"
          size="lg"
          type="submit"
        >{{ $t('insurance_risk_payment.pay_button') }}</b-button>
      </div>

    </b-form>
  </div>
</template>


<script>
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import CheckoutControlPayment from "@/components/CheckoutControlPayment";
import VMajorMedicalInsurancePatientSelector from "@/components/VMajorMedicalInsurancePatientSelector";
import { mapActions } from "vuex";
import { Money } from "@/lib/money.js";
import { Amount } from "@/lib/amount.js";
// import { mapState } from "vuex";

export default {
  name: "insurance-risk-payment-form",
  components: {
    VBootstrapFormGroup,
    CheckoutControlPayment,
    VMajorMedicalInsurancePatientSelector,
  },
  // props: {
  //   a_uuid: String,
  // },
  data() {
    return {
      transaction: undefined,
      insurance_organization_options: [],
      form: {
        insured_name: null,
        policy_num: null,
        is_beneficiary: false,
        has_deductible: false,
        has_coinsurance: false,
        insurance_organization_uuid: null,
        coinsurance: null,
        deductible: null,
        coinsurance_currency: "MXN",
        deductible_currency: "MXN",
      },
      form_invalid_feedback: {
        coinsurance: undefined,
        deductible: undefined,
        coinsurance_currency: undefined,
        deductible_currency: undefined,
        insurance_organization: undefined,
      },
      form_state: {
        insurance_organization: undefined,
        coinsurance: undefined,
        deductible: undefined,
        coinsurance_currency: undefined,
        deductible_currency: undefined,
        is_beneficiary: undefined,
      },
      show: true,
      updated: false,
      loading: false,
      pt_uuid: this.$route.params.pt_uuid,
      checkout_uuid: undefined,
      purchase_options: undefined,
      webpay_pay_errors: undefined,
    };
  },
  computed: {
    total_amount_to_pay() {
      let d = new Money(0, this.form.deductible_currency);
      let c = new Money(0, this.form.coinsurance_currency);
      if (this.form.has_deductible) {
        d = new Money(
          Number(this.form.deductible) || 0,
          this.form.deductible_currency
        );
      }
      if (this.form.has_coinsurance) {
        c = new Money(
          Number(this.form.coinsurance) || 0,
          this.form.coinsurance_currency
        );
      }

      return new Money(d + c, this.form.coinsurance_currency);
    },
    amount() {
      return new Amount(this.transaction.amount);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_risk_payment: {
          insured_data_title: "Insured data",
          insured_name_label: "Insured name",
          insured_name_placeholder: "Insured name",
          policy_num_label: "Policy #",
          policy_num_placeholder: "Policy #",
          is_beneficiary: "Beneficiary",
          payment_data_title: "Payment data",
          insurance_organization_label: "Insurance",
          has_deductible: "Deductible",
          deductible_placeholder: "",
          has_coinsurance: "Coinsurance",
          coinsurance_placeholder: "",
          pay_button: "Pay",
          return_button: "Back",
          total_amount_to_pay: "Total amount to pay",
          select_payment_method: "Select payment method",
          error: {
            saving: "Error saving data",
            payment: "Payment error",
          },
        },
      },
    },
  },
  created() {},
  mounted: function() {
    this.DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_AVAILINSURANCES_LIST().then(
      res => {
        let options = [];
        res.data.results.forEach(element => {
          options.push({ value: element.uuid, text: element.public_name });
        });
        this.insurance_organization_options = options;
      },
      () => {}
    );
  },
  methods: {
    ...mapActions({
      DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_CREATE:
        "drugtransaction/DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_CREATE",
      DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_UPDATE:
        "drugtransaction/DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_UPDATE",
      DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_AVAILINSURANCES_LIST:
        "drugtransaction/DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_AVAILINSURANCES_LIST",
      DRUGTRANSACTION_CHECKOUT_DONEINSURANCERISKPAYMENT_CREATE:
        "drugtransaction/DRUGTRANSACTION_CHECKOUT_DONEINSURANCERISKPAYMENT_CREATE",
    }),
    toggleDeductibleCurrency(currency) {
      this.form.deductible_currency = currency;
    },
    toggleCoinsuranceCurrency(currency) {
      this.form.coinsurance_currency = currency;
    },
    // loadPerson: function() {
    //   this.$store
    //     .dispatch("naturalperson/REQUEST_PERSON", { pt_uuid: this.pt_uuid })
    //     .then(undefined, e => {
    //       this.$sentry({ capture: e });
    //       this.$router.push({ name: "fallback-error-page" });
    //     });
    // },
    sendForm(evt) {
      evt.preventDefault();
      this.loading = true;
      let action = this.DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_CREATE;
      if (this.form.uuid) {
        action = this.DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_UPDATE;
      }

      let data = Object.assign(this.form);
      if (!data.has_deductible) {
        data["deductible"] = 0;
      }
      if (!data.has_coinsurance) {
        data["coinsurance"] = 0;
      }

      action({
        data: data,
        insurance_risk_payment_uuid: this.form.uuid,
      }).then(
        res => {
          this.transaction = res.data;
          this.form.uuid = res.data.uuid;
          this.checkout_uuid = res.data.checkout;
          this.purchase_options = res.data.purchase_options;
          this.openModalPayment();
          this.loading = false;
        },
        err => {
          this.showResponseError(err);
          this.putFormFeedback(err.response);
          this.mnotify_error({
            text: this.$t("insurance_risk_payment.error.saving"),
          });
          this.loading = false;
        }
      );
    },
    openModalPayment() {
      this.webpay_pay_errors = [];
      this.$refs["payment_modal"].show();
    },
    onPaymentIntention(data) {
      this.$emit(
        "payment-intention",
        Object.assign({}, data, {
          pharmacy_uuid: this.transaction.organization_uuid,
          organization_uuid: this.transaction.organization_uuid,
        })
      );
    },
    onPaymentAuthorized(data) {
      this.$refs["payment_modal"].hide();
      this.loading = true;
      this.$emit(
        "payment-authorized",
        Object.assign({}, data, {
          pharmacy_uuid: this.transaction.organization_uuid,
          organization_uuid: this.transaction.organization_uuid,
        })
      );

      this.DRUGTRANSACTION_CHECKOUT_DONEINSURANCERISKPAYMENT_CREATE({
        checkout_uuid: this.checkout_uuid,
        data: {
          payment_intention_uuid: data.payment_intention_uuid,
          payment_configuration: { uuid: data.payment_conf.uuid },
        },
      }).then(
        () => {
          if (data.payment_conf.payment_method.payment_method == 4) {
            this.$router.push({
              name: "insurance-risk-payment-list",
              params: { action: "feedback-pay-pending" },
            });
          } else {
            this.$router.push({
              name: "insurance-risk-payment-list",
              params: { action: "feedback-pay-done" },
            });
          }
        },
        err => {
          this.showResponseError(err);
          this.mnotify_error({
            text: this.$t("insurance_risk_payment.error.payment"),
          });
        }
      );
    },
    onPatientSelected(patient) {
      const firstname = patient.firstname || "";
      const lastname = patient.lastname || "";
      this.form.insured_name = `${firstname} ${lastname}`.trim();
      this.form.policy_num = patient.policy_num;

      if (patient.has_coinsurance) {
        this.form.has_coinsurance = true;
        this.form.coinsurance = this.$formatInternal(patient.coinsurance);
        this.form.coinsurance_currency = patient.coinsurance_currency;
      } else {
        this.form.has_coinsurance = false;
        this.form.coinsurance = null;
        this.form.coinsurance_currency = "MXN";
      }

      if (patient.has_deductible) {
        this.form.has_deductible = true;
        this.form.deductible = this.$formatInternal(patient.deductible);
        this.form.deductible_currency = patient.deductible_currency;
      } else {
        this.form.has_deductible = false;
        this.form.deductible = null;
        this.form.deductible_currency = "MXN";
      }

      if (patient.is_beneficiary) {
        this.form.is_beneficiary = patient.is_beneficiary;
      } else {
        this.form.is_beneficiary = false;
      }

      this.form.insurance_organization_uuid = patient.manager_uuid;
    },
  },
};
</script>
