<template>
  <div>
    <legend
      class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 title"
    >
      {{ $t("insurance_recipe_doctor_form.recipe_data_title") }}
    </legend>
    <VBootstrapFormGroup
      name="doctor_address.firstname"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurance_recipe_doctor_form.firstname_label')"
      :placeholder="$t('insurance_recipe_doctor_form.firstname_placeholder')"
      :invalid-feedback="form_invalid_feedback.doctor_address.firstname"
      :state="form_state.doctor_address.firstname"
      v-model.trim="form.doctor_address.firstname"
    />
    <VBootstrapFormGroup
      name="doctor_address.specialty"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurance_recipe_doctor_form.specialty_label')"
      :placeholder="$t('insurance_recipe_doctor_form.specialty_placeholder')"
      :invalid-feedback="form_invalid_feedback.doctor_address.specialty"
      :state="form_state.doctor_address.specialty"
      v-model.trim="form.doctor_address.specialty"
    />
    <VBootstrapFormGroup
      name="doctor_address.professional_number"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurance_recipe_doctor_form.professional_number_label')"
      :placeholder="$t('insurance_recipe_doctor_form.professional_number_placeholder')"
      :invalid-feedback="form_invalid_feedback.doctor_address.professional_number"
      :state="form_state.doctor_address.professional_number"
      v-model.trim="form.doctor_address.professional_number"
    />
    <VBootstrapFormGroup
      name="doctor_address.email"
      type="email"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurance_recipe_doctor_form.email_label')"
      :placeholder="$t('insurance_recipe_doctor_form.email_placeholder')"
      :invalid-feedback="form_invalid_feedback.doctor_address.email"
      :state="form_state.doctor_address.email"
      v-model.trim="form.doctor_address.email"
    />
    <VBootstrapFormGroup
      name="doctor_address.phone"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurance_recipe_doctor_form.phone_label')"
      :placeholder="$t('insurance_recipe_doctor_form.phone_placeholder')"
      :invalid-feedback="form_invalid_feedback.doctor_address.phone"
      :state="form_state.doctor_address.phone"
      v-model.trim="form.doctor_address.phone"
    />
    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurance_recipe_doctor_form.recipe_file_label')"
      label-for="recipe_file"
      :invalid-feedback="form_invalid_feedback.recipe_file"
      :state="form_state.recipe_file"
    >
      <template v-slot:label>
        {{ $t('insurance_recipe_doctor_form.recipe_file_label') }} <span class="text-danger">*</span>
      </template>
      <div v-if="form._recipe_file">
        <b-link :href="form._recipe_file" target="_blank">
          {{ $t("insurance_recipe_doctor_form.file") }}:
          {{ form.recipe_file_original_filename }}
        </b-link>
      </div>
      <b-form-file
        v-model="form.recipe_file"
        class="z-i0"
        :required="!form._recipe_file"
        :state="Boolean(form.recipe_file) || form.recipe_file"
        :placeholder="
          $t('insurance_recipe_doctor_form.recipe_file_placeholder')
        "
        :browse-text="$t('insurance_recipe_doctor_form.browse_text')"
      >
      </b-form-file>
    </b-form-group>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
// import { mapState } from "vuex";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";

export default {
  name: "insuranceauthorization-recipe-medic-form",
  components: { VBootstrapFormGroup },
  props: {
    form: Object,
    form_invalid_feedback: Object,
    form_state: Object,
  },
  data() {
    return {
      deliveryAddressShow: false,
    };
  },
  computed: {},
  fallbackI18n: {
    messages: {
      und: {
        insurance_recipe_doctor_form: {
          recipe_data_title: "Medic data",
          file: "File",
          firstname_label: "Medic name",
          firstname_placeholder: "Medic name",
          specialty_label: "Specialty",
          specialty_placeholder: "Specialty",
          professional_number_label: "Professional Number",
          professional_number_placeholder: "Professional Number",
          email_label: "email",
          email_placeholder: "email",
          phone_label: "Phone",
          phone_placeholder: "Phone",
          recipe_file_label: "RX",
          recipe_file_placeholder: "RX",
          browse_text: "Browse",
        },
      },
    },
  },
  mounted: function () {},
  methods: {},
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}

.title {
  text-transform: uppercase;
}

.z-i0 {
  z-index: 0;
}
</style>
