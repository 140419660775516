/****************************************
  DevelatioVueSentry
  vue-sentry.js
  (C) Develatio Technologies S.L. 2019
*****************************************

Wraper for @sentry/browser
****************************************/
import * as Sentry from "@sentry/browser";

const DevelatioVueSentry = {
  // eslint-disable-next-line
  install(Vue, options) {
    Vue.mixin({
      methods: {
        $sentry(conf) {
          if (!conf.capture) {
            throw new TypeError(
              "Expected capture option in $sentry({}). Example: $sentry({capture: err})"
            );
          }

          if (conf.debug || process.env.NODE_ENV === "development") {
            // eslint-disable-next-line
            console.error(conf.capture);
          }
          return Sentry.captureException(conf.capture);
        },
      },
    });
  },
};

export default DevelatioVueSentry;
