import { Money } from "@/lib/money.js";
// import { mapGetters } from "vuex";
// import countries from "countries-list";

const VueNumberFormatter = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {};
      },
      computed: {
        __locale() {
          let supported = ["en-US"];
          try {
            supported = Intl.NumberFormat.supportedLocalesOf(
              this.$i18n.locale.replace("_", "-"),
              {
                localeMatcher: "lookup",
              }
            );
          } catch (error) {
            return "en-US";
          }
          return supported[0];
        },
        // ...mapGetters("auth", ["deviceCountry"]),
        __formatter() {
          // si this.$i18n.locale no es válido, este método falla
          var f = new Intl.NumberFormat(this.__locale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
          return f;
        },
        __percent_formatter() {
          // si this.__locale no es válido, este método falla
          var f = new Intl.NumberFormat(this.__locale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "decimal",
          });
          return f;
        },
      },
      methods: {
        $formatInternal(n) {
          return new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: false,
          }).format(n);
        },
        $formatNumber(n) {
          return this.__formatter.format(n);
        },
        $formatPercent(n) {
          return this.__percent_formatter.format(n) + " %";
        },
        $formatMoney(number, currency = null) {
          if (number instanceof Money) {
            currency = number.currency;
            number = number.amount;
          }
          // El formateador de números de algunos navegadores (Chrome)
          // interpreta 1000,00 sin separador de miles y 10.000,00 con
          // separador de miles. Queremos que 1000 también contenga
          // el separador, así que:
          //
          // separa las distintas partes de un número en un array
          // de objetos
          let parts = this.__formatter.formatToParts(number);
          // determinamos si la primera parte entera contiene 4 digitos,
          // lo que significa que no ha aplicado separador de miles
          if (
            parts[0] &&
            parts[0].value.length == 4 &&
            parts[0].type == "integer"
          ) {
            // obtenemos un array de un número sample que sabemos que
            // tendría separador de miles
            let sample = this.__formatter.formatToParts(10000);
            let thousan_separator = sample[1].value;
            // si el número sample contiene separador de miles (podría
            // ser que en el locale actual los miles no tuvieran separador)
            // vamos a reformatear el número añadiendo el separador en cuestión
            if (sample[1].type == "group") {
              // la parte entera (4 dígitos, ejemplo: 1000)
              let integer = parts[0].value;
              // ahora la primera parte del número contiene 1
              parts[0].value = integer.substring(0, 1);
              // añadimos el separador de miles
              parts.splice(1, 0, { type: "group", value: thousan_separator });
              // añadimos el resto del número (000)
              parts.splice(2, 0, {
                type: "integer",
                value: integer.substring(1),
              });
              // reconstruimos el array para dejarlo en string, añadimos
              // símbolo de moneda y devolvemos resultado
              return (
                currency +
                " " +
                parts
                  .map(({ value }) => {
                    return value;
                  })
                  .reduce((string, part) => string + part)
              );
            }
          }
          if (!currency) {
            return this.__formatter.format(number);
          }
          return currency + " " + this.__formatter.format(number);

          // const money = new Money(number, currency);
          // return money.toLocaleString(this.__locale);
        },
        // $currencyOfCountry(isocountry) {
        //   return countries.countries[isocountry].currency;
        // },
        // // TODO: mirar correctamente el locale y usar aquí
        // $formatMoney(number, currency) {
        //   if (this.auto_convert_money && this.deviceCountry) {
        //     let target_currency = undefined;
        //     try {
        //       target_currency = this.$currencyOfCountry(this.deviceCountry);
        //       // issue #299, que dice así:
        //       // Tenemos que mostrar los precios de los productos en los
        //       // listados, fichas, carrito y demas sitios donde aparezcan
        //       // monedas en:
        //       //  EUR si es europa (zona euro)
        //       //  Pesos mexicanos si es Mexico
        //       //  USD si es USA o resto del mundo
        //       // (basándonos en la IP desde la que se visita la pagina).
        //       //
        //       // Implementación:
        //       // Si la moneda del país indicado en deviceCountry (detectado
        //       // por IP a menos que se haya sobreescrito intencionadamente
        //       // con el hack de ?country=) no es EUR, ni USD ni MXN, estamos
        //       // en un país que no es de la zona euro, ni de EEUU ni de México,
        //       // por lo tanto la moneda a mostrar es USD.
        //       if (
        //         target_currency != "EUR" &&
        //         target_currency != "USD" &&
        //         target_currency != "MXN"
        //       ) {
        //         target_currency = "USD";
        //       }
        //     } catch (error) {
        //       this.$sentry({ capture: error });
        //       return new Money(number, currency).toLocaleString();
        //     }

        //     let source_money = new Money(number, currency);
        //     try {
        //       return convert_money(
        //         source_money,
        //         target_currency
        //       ).toLocaleString();
        //     } catch (error) {
        //       this.$sentry({ capture: error });
        //       return new Money(number, currency).toLocaleString();
        //     }
        //   }
        //   return new Money(number, currency).toLocaleString();
        // },
      },
    });
  },
};

export default VueNumberFormatter;
