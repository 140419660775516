<template>
  <b-form-group
    :id="'group_'+name"
    :label="label"
    :label-sr-only="labelSrOnly"
    :label-cols="labelSrOnly? undefined : labelCols"
    :label-cols-sm="labelSrOnly? undefined : labelColsSm"
    :label-cols-md="labelSrOnly? undefined : labelColsMd"
    :label-cols-lg="labelSrOnly? undefined : labelColsLg"
    :label-cols-xl="labelSrOnly? undefined : labelColsXl"
    :label-for="labelSrOnly? undefined : 'input_'+name"
    :label-class="{'pb-1': stackFormGroup}"
    :label-size="labelSrOnly? undefined : size"
    :invalid-feedback="invalid_feedback"
    :state="state"
    :description="description"
    :class="{'mb-2': stackFormGroup}"
  >

    <template v-slot:label v-if="required">
      {{ label }} <span class="text-danger">*</span>
    </template>

    <b-form-input
      v-if="type=='input'"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      type="text"
      :required="required"
      :size="size"
      :value="value"
      v-on:input.native="$emit('input', $event.target.value)"
      :state="state"
      :placeholder="placeholder"
    ></b-form-input>


    <b-form-input
      v-if="type=='email'"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      type="email"
      :required="required"
      :size="size"
      :value="value"
      v-on:input.native="$emit('input', $event.target.value)"
      :state="state"
      :placeholder="placeholder"
    ></b-form-input>


    <CountrySelect
      v-if="type=='country'"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      v-on:input="onCountryInput"
      :country="value"
      :required="required"
      :placeholder="placeholder"
      :topCountry="countryFromRequest"
      class="custom-select no-radius-select"
    />

    <PhoneNumberInput
      v-if="type=='phone'"
      :disabled="Boolean(disabled)"
      v-bind:class="{ 'no_flag_selected': !is_flag_selected, 'disabled': Boolean(disabled) }"
      class="v-bootstrap-form-phone"
      :id="'input_'+name"
      :required="required"
      :no-validator-state="state"
      :value="value"
      v-on:input="onPhoneInput"
      v-on:update="onPhoneUpdate"
      size="sm"
    ></PhoneNumberInput>

    <b-form-textarea
      v-if="type=='textarea'"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      :value="value"
      :placeholder="placeholder"
      v-on:input="onTextareaInput"
      v-on:change="onTextareaChange"
      v-on:update="onTextareaUpdate"
      :rows="rows"
      :max-rows="max_rows"
    ></b-form-textarea>

    <b-form-select
      v-if="type=='select'"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      :value="value"
      :options="options"
      v-on:input="onSelectInput"
      v-on:change="onSelectChange"
      :size="size"
    >
      <slot name="options"></slot>
    </b-form-select>

    <b-form-radio-group
      v-if="type=='radio'"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      :value="value"
      :options="options"
      :name="'select_'+name"
      :required="required"
      v-on:input="onRadioInput"
      v-on:change="onRadioChange"
      :checked="value"
      :size="size"
    >
      <slot name="options"></slot>
    </b-form-radio-group>

    <b-input-group class="mb-3" v-if="type=='date2'">
      <b-input-group-append>
        <b-form-datepicker
          :disabled="Boolean(disabled)"
          :id="'input_'+name"
          :value="value"
          v-on:input="onDate2Input"
          v-on:change="onDate2Change"
          v-on:update="onDate2Update"
          :size="size"
          :required="required"
          :state="state"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
          :placeholder="placeholder"
          start-weekday="1"
          label-help=""
          locale="es"
          button-variant="outline-primary-thin"
          button-only
          hide-header
          :class="{'form-control form-control-sm': (size=='sm')}"
          class="datepicker2"
          variant="primary"
          @context="onDate2Context"
          :min="min"
        ></b-form-datepicker>
      </b-input-group-append>

      <!--
        - la propiedad readonly hace que no se pueda editar el input ya que es
        sólo informativo, pero esta propiedad rompe la función de required
        - el class hidden_readonly hace que no se muestre gris por la propiedad
        readonly, pero sólo se muestra si no está deshabilitado, que entonces
        sí lo queremos gris
        - el click.prevent es para que no se propague el click y se lance
        el evento blur del input que se muestra cuando este se oculta
        - el v-model es el valor formateado por el componente datepicker
      -->
      <b-form-input
        v-if="!date2_editmode"
        :disabled="Boolean(disabled)"
        @click.prevent="date2StartEdit()"
        :required="required"
        :class="{'hidden_readonly': !Boolean(disabled)}"
        v-model="date2ctx_selectedFormatted"
        type="text"
        :state="state"
        :placeholder="placeholder"
        autocomplete="off"
      ></b-form-input>

      <!--
        - este input se muestra cuando se hace click en el anterior y se oculta
        cuando pierde el foco (se hace click fuera)
        - el class text-danger se muestra cuando el datepicker no puede
        interpretar los datos introducidos, por lo que se considera fecha
        errónea
        el evento input formatea el valor añadido desde dd/mm/yyyy hasta
        yyyy-mm-dd (formato del datepicker) y lanza un evento input como si
        se hubiera hecho click en el calendario, del resto se ocupa el
        componente datepicker
      -->
      <b-form-input
        v-show="date2_editmode"
        :state="state"
        :ref="'date2_edit_'+name"
        v-on:input="onDate2EditInput"
        v-model="date2_editvalue"
        type="text"
        placeholder="DD/MM/YYYY"
        :disabled="Boolean(disabled)"
        v-on:blur="date2StopEdit()"
        autofocus
        :class="{'text-danger': !data2ctx_selectedDate}"
      ></b-form-input>
    </b-input-group>

    <datePicker
      v-if="type=='date' && datepicker_options"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      :value="value"
      :config="datepicker_options"
      v-on:input="onDateInput"
      v-on:change="onDateChange"
      v-on:update="onDateUpdate"
      :size="size"
      :placeholder="placeholder"
      :class="{'form-control form-control-sm': (size=='sm')}"
    ></datePicker>

    <datePicker
      v-if="type=='datetime' && datetimepicker_options"
      :disabled="Boolean(disabled)"
      :id="'input_'+name"
      :value="value"
      :config="datetimepicker_options"
      v-on:input="onDateTimeInput"
      v-on:change="onDateTimeChange"
      v-on:update="onDateTimeUpdate"
      :required="required"
      :size="size"
      :placeholder="placeholder"
      :class="{'form-control form-control-sm': (size=='sm')}"
    ></datePicker>

    <b-input-group v-if="type=='currency'">
      <b-form-input
        :id="'input_'+name"
        :disabled="Boolean(disabled)"
        type="number"
        step="0.01"
        :value="value"
        v-on:input.native="onCurrencyInput"
        v-on:update.native="onCurrencyUpdate"
        v-on:change.native="onCurrencyChange"
        :required="required"
        :state="state"
        :placeholder="placeholder"
        :size="size"
      ></b-form-input>
      <b-dropdown
        size="sm"
        :disabled="Boolean(disabled)"
        :text="currency"
        variant="outline-primary-thin"
        slot="append"
      >
        <b-dropdown-item @click="toggleCurrency('USD')">USD</b-dropdown-item>
        <b-dropdown-item @click="toggleCurrency('MXN')">MXN</b-dropdown-item>
        <b-dropdown-item
          v-for="(option,index) in extra_currency"
          :key="index"
          @click="toggleCurrency(option)"
        >{{ option }}</b-dropdown-item>
      </b-dropdown>
    </b-input-group>

    <b-input-group v-if="type=='currencyraw'">
      <b-form-input
        :id="'input_'+name"
        :disabled="Boolean(disabled)"
        :value="value"
        v-on:input.native="onCurrencyInput"
        v-on:update.native="onCurrencyUpdate"
        v-on:change.native="onCurrencyChange"
        :required="required"
        :state="state"
        :placeholder="placeholder"
        :size="size"
      ></b-form-input>
      <b-dropdown
        size="sm"
        :disabled="Boolean(disabled)"
        :text="currency"
        variant="outline-primary-thin"
        slot="append"
      >
        <b-dropdown-item @click="toggleCurrency('USD')">USD</b-dropdown-item>
        <b-dropdown-item @click="toggleCurrency('MXN')">MXN</b-dropdown-item>
        <b-dropdown-item
          v-for="(option,index) in extra_currency"
          :key="index"
          @click="toggleCurrency(option)"
        >{{ option }}</b-dropdown-item>
      </b-dropdown>
    </b-input-group>
    <slot></slot>
  </b-form-group>
</template>

<script>
import moment from "moment";
import { CountrySelect } from "vue-country-region-select";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import { mapGetters } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
export default {
  name: "v-bootstrap-form-group",
  fallbackI18n: {
    messages: {
      und: {
        form_group_input: {
          invalid_date: "Invalid date",
        },
      },
    },
  },
  data() {
    return {
      formattedPhoneNumber: undefined,
      currency_value: undefined,
      is_flag_selected: false,
      currency: undefined,
      date2ctx_selectedFormatted: undefined,
      date2_editmode: false,
      data2ctx_selectedDate: null,
      date2_editvalue: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["countryFromRequest"]),
    ...mapGetters("i18nmap", ["activeLangtag"]),
    datepicker_options() {
      if (this.activeLangtag == "und") {
        return undefined;
      }
      return {
        format: "L",
        locale: this.activeLangtag,
        keepInvalid: false,
        icons: {
          time: "far fa-clock",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
        },
      };
    },
    datetimepicker_options() {
      if (this.activeLangtag == "und") {
        return undefined;
      }
      return {
        format: "DD/MM/YYYY hh:mm A",
        sideBySide: true,
        locale: this.activeLangtag,
        keepInvalid: false,
        icons: {
          time: "far fa-clock",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
        },
      };
    },
  },
  props: {
    state: {
      default: undefined,
      type: Boolean,
    },
    name: {
      default: "input",
      type: String,
    },
    value: {
      default: undefined,
      type: [String, Object],
    },
    description: {
      default: undefined,
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: "",
      type: String,
    },
    invalid_feedback: {
      default: undefined,
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
    "label-cols": {
      default: "12",
      type: [Boolean, Number, String],
    },
    "label-cols-sm": {
      default: "12",
      type: [Boolean, Number, String],
    },
    "label-cols-md": {
      default: "12",
      type: [Boolean, Number, String],
    },
    "label-cols-lg": {
      default: "4",
      type: [Boolean, Number, String],
    },
    "label-cols-xl": {
      default: "4",
      type: [Boolean, Number, String],
    },
    options: {
      default: undefined,
      type: [Array, Object],
    },
    rows: {
      default: "3",
      type: String,
    },
    max_rows: {
      default: "6",
      type: String,
    },
    extra_currency: {
      default: undefined,
      type: Array,
    },
    preset_currency: {
      default: "MXN",
      type: String,
    },
    size: {
      default: undefined,
      type: String,
    },
    "stack-form-group": {
      default: false,
      type: Boolean,
    },
    "label-sr-only": {
      default: false,
      type: Boolean,
    },
    min: {
      default: undefined,
      type: [String, Object, Date],
    },
  },
  mounted() {
    this.currency = this.preset_currency;
  },
  methods: {
    toggleCurrency(currency) {
      this.currency = currency;
      this.emitCurrencyParsed(this.currency_value);
    },
    onSelectChange(value) {
      this.$emit("change", value);
    },
    onSelectInput(value) {
      this.$emit("input", value);
    },
    onTextareaChange(value) {
      this.$emit("change", value);
    },
    onTextareaInput(value) {
      this.$emit("input", value);
    },
    onTextareaUpdate(value) {
      this.$emit("update", value);
    },
    onRadioChange(checked) {
      this.$emit("change", checked);
    },
    onRadioInput(checked) {
      this.$emit("input", checked);
    },
    onPhoneUpdate(results) {
      // Aañidr clase "no_flag_selected" a componente cuando no se selecciona
      // una bandera
      if (results.countryCode) {
        this.is_flag_selected = true;
      } else {
        this.is_flag_selected = false;
      }

      // Si no se parsea el número (no es válido), devolvemos los datos en
      // crudo sin parsear para evitar que el input se borre.
      if (!results.formattedNumber) {
        this.$emit("input", results.phoneNumber);
      } else {
        this.$emit("input", results.formattedNumber);
      }

      // siempre emitimos un evento "parsed" con los datos en crudo
      //
      this.$emit("parsed", results);
      this.formattedPhoneNumber = results.formattedNumber;
    },
    onPhoneInput() {
      this.$emit("input", this.formattedPhoneNumber);
    },
    onCountryInput(isoCountry) {
      this.$emit("input", isoCountry);
    },
    onDateChange(value) {
      moment.locale(this.activeLangtag);
      let parsed = moment(value, this.datepicker_options.format).format(
        "YYYY-MM-DD"
      );
      this.$emit("change", parsed);
    },
    onDateInput(value) {
      moment.locale(this.activeLangtag);
      let _m = moment(value, this.datepicker_options.format);
      let parsed = _m.format("YYYY-MM-DD");
      this.$emit("input", parsed);
    },
    onDateUpdate(value) {
      moment.locale(this.activeLangtag);
      let parsed = moment(value, this.datepicker_options.format).format(
        "YYYY-MM-DD"
      );
      this.$emit("update", parsed);
    },
    onDate2Change(value) {
      moment.locale(this.activeLangtag);
      let parsed = moment(value, this.datepicker_options.format).format(
        "YYYY-MM-DD"
      );
      this.$emit("change", parsed);
    },
    onDate2EditInput(value) {
      let _m = moment(value, "DD/MM/YYYY", true);
      // si se ha introducido una fecha válida lanzamos evento como si
      // se seleccionase desde el calendario, en caso contrario lanzamos
      // valor vacío. Con esto se evita que se queden guardadas fechas
      // incorrectas ya que estamos usando un input custom y no queremos
      // que haya incoherencias entre el input y el valor recibido por
      // el datepicker
      if (!_m.isValid()) {
        this.onDate2Input("");
        return;
      }
      this.onDate2Input(_m.format("YYYY-MM-DD"));
    },
    onDate2Input(value) {
      // un evento input hace que el v-model actúe
      this.$emit("input", value);
    },
    onDate2Context(ctx) {
      // cuando se selecciona una fecha en el calendario o se lanza un
      // evento input, el datepicker reacciona al valor y parsea el contenido
      // para después lanzar el evento context con la fecha detectada.
      // capturamos los valores que nos serán útiles para mostrarlos
      // en el input de información
      this.data2ctx_selectedDate = ctx.selectedDate;
      if (ctx.selectedDate) {
        this.date2ctx_selectedFormatted = ctx.selectedFormatted;
      } else {
        this.date2ctx_selectedFormatted = "";
      }
    },
    onDate2Update(value) {
      moment.locale(this.activeLangtag);
      let parsed = moment(value, this.datepicker_options.format).format(
        "YYYY-MM-DD"
      );
      this.$emit("update", parsed);
    },
    date2StartEdit() {
      // a pesar de que el input esté desactivado, aún lanza eventos, con esto
      // se evita que se lance el input de edición cuando está desactivado
      if (this.disabled) {
        return;
      }

      // obtenemos el valor actual de fecha que siempre tendrá el mismo
      // formato, lo parseamos al formato del usuario y si es válido
      // lo seteamos como valor, en caso contrario dejamos el input vacío
      let _m = moment(this.value, "YYYY-MM-DD", true);
      if (_m.isValid()) {
        this.date2_editvalue = _m.format("DD/MM/YYYY");
      } else {
        this.date2_editvalue = null;
      }

      this.date2_editmode = true;
      // es necesario forzar el foco cuando el botón esté efectivamente
      // mostrado, por eso se lanza con nextTick
      this.$nextTick(() => {
        this.$refs["date2_edit_" + this.name].focus();
      });
    },
    date2StopEdit() {
      // detiene el modo edición
      this.date2_editmode = false;
      // lanza evento de nueva fecha introducida. A pesar de que esto se
      // realiza con cada número introducido en el input, si se juguetea con
      // el ratón y seleccionando cosas, se puede dar el caso de que la fecha
      // final del input no corresponda con la parseada, por esto, el último
      // valor visto en el input de edición se lanza como evento de nueva fecha
      // introducida.
      this.onDate2EditInput(this.date2_editvalue);
    },
    onDateTimeChange(value) {
      let parsed = moment(value, this.datetimepicker_options.format).format(
        "DD/MM/YYYY hh:mm A"
      );
      this.$emit("change", parsed);
    },
    onDateTimeInput(value) {
      let parsed = moment(value, this.datetimepicker_options.format).format(
        "DD/MM/YYYY hh:mm A"
      );
      this.$emit("input", parsed);
    },
    onDateTimeUpdate(value) {
      let parsed = moment(value, this.datetimepicker_options.format).format(
        "DD/MM/YYYY hh:mm A"
      );
      this.$emit("update", parsed);
    },
    emitCurrencyParsed(value) {
      if (!value) {
        value = this.value;
      }
      this.$emit("parsed", {
        value: value,
        currency: this.currency,
      });
    },
    onCurrencyInput(evt) {
      this.currency_value = evt.target.value;
      this.$emit("input", evt.target.value);
      this.emitCurrencyParsed(evt.target.value);
    },
    onCurrencyUpdate(evt) {
      this.currency_value = evt.target.value;
      this.$emit("update", evt.target.value);
      this.emitCurrencyParsed(evt.target.value);
    },
    onCurrencyChange(evt) {
      this.currency_value = evt.target.value;
      this.$emit("change", evt.target.value);
      this.emitCurrencyParsed(evt.target.value);
    },
  },
  components: {
    CountrySelect,
    PhoneNumberInput,
    datePicker,
  },
};
</script>


<style lang="scss" scoped>
// hack para el input phone, parece que el prop "disabled" no funciona
.v-bootstrap-form-phone.disabled {
  pointer-events: none;
  background-color: $input-disabled-bg;
}
.datepicker2 /deep/ button {
  padding: 0.375rem 0.7rem !important;
}

.hidden_readonly {
  background-color: $input-bg !important;
}
</style>
