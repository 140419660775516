import {
  //Persons
  naturalperson_persons_list,
  // Patient
  naturalperson_patient_list,
  naturalperson_patient_create,
  naturalperson_patient_update,
  naturalperson_patient_read,
  naturalperson_patient_delete,
  // Addresses
  naturalperson_patient_addresses_list,
  naturalperson_patient_all_addresses_list,
  naturalperson_patient_addresses_create,
  naturalperson_patient_addresses_delete,
  naturalperson_patient_addresses_read,
  naturalperson_patient_addresses_update,
  // Contact form
  contact_site_create,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    profiles: {
      pharmacist: {
        uuid: null,
      },
      insuranceworker: {
        uuid: null,
      },
    },
    person: null,
    patient: null,
    my_patients: [],
  },
  actions: {
    MY_PATIENTS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit("MUTATE_MY", []);
        naturalperson_patient_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("MUTATE_MY", response.data.results);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    GET_PATIENT: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_read({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT: ({ commit }) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("MUTATE_PATIENT", response.data.results[0]);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    PATCH_PATIENT: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_update({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          data: payload.data,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    NEW_PATIENT: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_create({
          data: payload.data,
          role: "guest",
          roleUuid: "1",
          $config: axconf(),
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DELETE_PATIENT: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_delete({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
        }).then(
          response => {
            commit("MUTATE_MY_DELETE", payload.pt_uuid);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PERSON: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_persons_list({
          role: "guest",
          roleUuid: "1",
          patient: payload.pt_uuid,
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_PERSON", response.data.results[0]);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_ADDRESSES: (_, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      var data_send = {
        $config: axconf(),
        role: "guest",
        roleUuid: "1",
        ptUuid: payload.pt_uuid,
        limit: payload.per_page,
      };
      if (!isNaN(offset)) {
        data_send.push({
          offset: offset,
        });
      }
      return new Promise((resolve, reject) => {
        naturalperson_patient_addresses_list(data_send).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_ALL_ADDRESSES: (_, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      var data_send = {
        $config: axconf(),
        role: "guest",
        roleUuid: "1",
        limit: payload.per_page,
      };
      if (!isNaN(offset)) {
        data_send['offset'] = offset;
      }
      return new Promise((resolve, reject) => {
        naturalperson_patient_all_addresses_list(data_send).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    GET_ADDRESS: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_addresses_read({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          aUuid: payload.a_uuid,
          data: payload.data,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DELETE_ADDRESS: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_addresses_delete({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          aUuid: payload.a_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    PATCH_ADDRESS: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_addresses_update({
          $config: axconf(),
          aUuid: payload.a_uuid,
          ptUuid: payload.pt_uuid,
          role: "guest",
          roleUuid: "1",
          data: payload.data,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    NEW_ADDRESS: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        naturalperson_patient_addresses_create({
          data: payload.data,
          ptUuid: payload.pt_uuid,
          role: "guest",
          roleUuid: "1",
          $config: axconf(),
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    SEND_CONTACT_FORM: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        contact_site_create({
          $config: axconf(),
          /*
            Todas las apps comparten la misma API, asi que necesitamos pasar un
            parametro adicional para poder diferenciar.
          */
          site: process.env.VUE_APP_SITE_CONTEXT,
          /* -- */
          role: "guest",
          roleUuid: "1",
          data: payload,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_MY: (state, my) => {
      Vue.set(state, "my_patients", my);
    },
    MUTATE_MY_DELETE: (state, ptUuid) => {
      var my_patients = state.my_patients.filter(pat => pat.uuid != ptUuid);
      Vue.set(state, "my_patients", my_patients);
    },
    MUTATE_PERSON: (state, person) => {
      Vue.set(state, "person", person);
    },
    MUTATE_PATIENT: (state, patient) => {
      Vue.set(state, "patient", patient);
    },
    // MUTATE_MY_PATIENTDATA: (state, my) => {
    //   Vue.set(state, 'my_patientdata', my);
    // }
  },
  getters: {
    getPatientUUID: state => {
      return state.patient;
    },
    getPerson: state => {
      return state.person;
    },
    getPatientDataByUUID: state => pt_uuid => {
      var found_pat = null;
      state.my_patients.forEach(function(pat) {
        if (pat.uuid == pt_uuid) {
          found_pat = pat;
        }
      });
      return found_pat;
    },
    // isPharmacistProfileLoaded: state => {
    //   return (state.profiles.pharmacist.uuid != null);
    // },
    // getPharmacyDataByUUID: (state) => (p_uuid) => {
    //   var found_org = null;
    //   state.my_pharmacies.forEach(function(org){
    //     if (org.uuid == p_uuid) {
    //       found_org = org;
    //     }
    //   });
    //   return found_org;
    // },
  },
};
