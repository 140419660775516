import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const fallback_messages = {
  und: {
    global: {
      pharmacy: "Pharmacy",
      select_country_helper: "Select country",
      country_field_mx: "Mexico",
      country_field_us: "USA",
      country_field_es: "Spain",
      unexpected_error: "An unexpected error occurred. Don't worry, we're already working on it!",
    },
  },
};

//  init and configure i18n system
const i18n = new VueI18n({
  locale: "und",
  fallbackLocale: "und",
  messages: fallback_messages,
  silentTranslationWarn: true,
});

export default i18n;
