<template>
  <b-container class="layout-footer py-4 d-block px-0">
    <b-row no-gutters>
      <b-col v-if="!isApp()" cols="12" md="8">
        <div class="text-md-left text-center ">
          <strong>Copyright &copy; Global PDV. All rights reserved.</strong>
          Site optimized for viewing in Firefox 29+, IE 10+, Safari 7+, and Chrome 35+.
          <br />
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <div class="text-md-right text-center">
          <router-link :to="{name: 'privacy-policy'}">{{ $t('the_footer.privacy_policy') }}</router-link> |
          <router-link :to="{name: 'cookies'}">{{ $t('the_footer.cookies') }}</router-link> |
          <router-link :to="{name: 'terms-conditions'}">{{ $t('the_footer.terms_and_conditions') }}</router-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  name: "the-footer",
  fallbackI18n: {
    messages: {
      und: {
        the_footer: {
          privacy_policy: "Privacy Policy",
          cookies: "Cookies",
          terms_and_conditions: "Terms & Conditions",
        },
      },
    },
  },
};
</script>


<style lang="scss" scoped>
.layout-footer {
  background-color: $white;
  font-size: $font-size-base/1.3;

  a {
    color: $text-main-color;
  }
  max-width: 1440px;
}
</style>
