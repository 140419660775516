<template>
  <div>
    <legend
      class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 title"
    >
      {{ $t("insurancebridge_recipe_patient_form.patient_data_title") }}
    </legend>
    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurancebridge_recipe_patient_form.firstname_label')"
      label-for="patient_address-firstname"
      :invalid-feedback="form_invalid_feedback.patient_address.firstname"
      :state="form_state.patient_address.firstname"
    >
      <template v-slot:label>
        {{ $t('insurancebridge_recipe_patient_form.firstname_label') }} <span class="text-danger">*</span>
      </template>
      <b-input-group>
        <b-form-input
          id="patient_address-firstname"
          type="text"
          v-model.trim="form.patient_address.firstname"
          required
          :state="form_state.patient_address.firstname"
          :placeholder="
            $t('insurancebridge_recipe_patient_form.firstname_placeholder')
          "
        ></b-form-input>
        <b-input-group-append>
          <VMajorMedicalInsurancePatientSelector
            v-if="$route.params.i_uuid"
            v-on:patient-selected="onPatientSelected"
            :preset-filters="{manager_uuid: $route.params.i_uuid}"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <VBootstrapFormGroup
      name="patient_address-email"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurancebridge_recipe_patient_form.email_label')"
      :placeholder="$t('insurancebridge_recipe_patient_form.email_placeholder')"
      :invalid-feedback="form_invalid_feedback.patient_address.email"
      :state="form_state.patient_address.email"
      v-model.trim="form.patient_address.email"
    />
    <VBootstrapFormGroup
      name="patient_address-policy_num"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurancebridge_recipe_patient_form.policy_num_label')"
      :placeholder="$t('insurancebridge_recipe_patient_form.policy_num_placeholder')"
      :invalid-feedback="form_invalid_feedback.patient_address.policy_num"
      :state="form_state.patient_address.policy_num"
      v-model.trim="form.patient_address.policy_num"
    />

    <VBootstrapFormGroup
      name="patient_address-email"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurancebridge_recipe_patient_form.insurance_name_label')"
      :placeholder="$t('insurancebridge_recipe_patient_form.insurance_name_placeholder')"
      :invalid-feedback="form_invalid_feedback.patient_address.insurance_name"
      :state="form_state.patient_address.insurance_name"
      v-model.trim="form.patient_address.insurance_name"
    />

    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
    >
    </b-form-group>

    <InsuranceBridgeRecipeDeliveryForm
      :form="form"
      :form_state="form_state"
      :form_invalid_feedback="form_invalid_feedback"
    />

    <b-form-group v-if="allow_pdv_pickup" class="text-left">
      <b-form-checkbox v-model="form.pharmacy_pickup">
        {{ $t("insurancebridge_recipe_patient_form.pick_up") }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left d-lg-none d-xl-none"
    >
    </b-form-group>

    <!--
    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurancebridge_recipe_patient_form.auth_num_label')"
      label-for="auth_num"
      :invalid-feedback="form_invalid_feedback.auth_num"
      :state="form_state.auth_num"
    >
      <b-form-input
        id="auth_num"
        type="text"
        v-model.trim="form.auth_num"
        :state="form_state.auth_num"
        :placeholder="$t('insurancebridge_recipe_patient_form.auth_num_placeholder')"
      ></b-form-input>
    </b-form-group>
    -->
  </div>
</template>

<script>
import InsuranceBridgeRecipeDeliveryForm from "@/components/insurancebridge/InsuranceBridgeRecipeDeliveryForm";
import VMajorMedicalInsurancePatientSelector from "@/components/VMajorMedicalInsurancePatientSelector";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";

import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "insurancebridge-recipe-patient-form-a",
  components: {
    InsuranceBridgeRecipeDeliveryForm,
    VMajorMedicalInsurancePatientSelector,
    VBootstrapFormGroup,
  },
  props: {
    form: Object,
    form_invalid_feedback: Object,
    form_state: Object,
  },
  data() {
    return {
      deliveryAddressShow: false,
      allow_pdv_pickup: undefined,
    };
  },
  computed: {
    ...mapGetters("organization", ["getInsuranceConfiguration"]),
    ...mapState({
      is_insurance_bridge_list_loaded: (state) =>
        state.organization.is_insurance_bridge_list_loaded,
    }),
    delivery_to_home() {
      return this.form.delivery_to_home;
    },
  },
  watch: {
    delivery_to_home: function (newState, oldState) {
      if (newState != oldState && this.form.delivery_to_home) {
        this.form.pharmacy_pickup = false;
      }
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancebridge_recipe_patient_form: {
          insurance_name_label: "Insurance name",
          insurance_name_placeholder: "Insurance name",
          firstname_label: "Patient name",
          firstname_placeholder: "Patient name",
          email_label: "Email",
          email_placeholder: "Email",
          policy_num_label: "Policy number",
          policy_num_placeholder: "Policy number",
          incident_num_label: "Incident number",
          incident_num_placeholder: "Incident number",
          folio_num_label: "Folio number",
          folio_num_placeholder: "Folio number",
          recipe_num_label: "Recipe number",
          recipe_num_placeholder: "Recipe number",
          // auth_num_label: "Auth number",
          // auth_num_placeholder: "Auth number",
          recipe_data_title: "Recipe data",
          patient_data_title: "Patient data",
          pick_up: "Pick up in-pharmacy",
        },
      },
    },
  },
  mounted: function () {
    this.handleAllowPdvPickup();
  },
  methods: {
    ...mapActions({
      INSURANCE_BRIDGE_LIST: "organization/INSURANCE_BRIDGE_LIST",
    }),
    _setAllowPdvPickup() {
      var conf = this.getInsuranceConfiguration(this.$route.params.i_uuid);
      if (!conf) {
        this.allow_pdv_pickup = false;
        return;
      }

      if (conf.allow_pdv_pickup) {
        this.allow_pdv_pickup = true;
        return;
      }
      this.allow_pdv_pickup = false;
    },
    handleAllowPdvPickup() {
      if (!this.is_insurance_bridge_list_loaded) {
        this.INSURANCE_BRIDGE_LIST().then(() => {
          this._setAllowPdvPickup();
        });
      } else {
        this._setAllowPdvPickup();
      }
    },
    onPatientSelected(patient) {
      const firstname = patient.firstname || "";
      const lastname = patient.lastname || "";
      this.form.patient_address.firstname = `${firstname} ${lastname}`.trim();
      this.form.patient_address.email = patient.email;
      this.form.patient_address.policy_num = patient.policy_num;
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}

.title {
  text-transform: uppercase;
}
</style>
