<template>
  <VLayout upline :title="$t('history.title')" :bgImage="false">
    <InsuranceAuthorizationConfirmFeedback />
    <InsuranceRefundConfirmFeedback />
    <InsuranceBridgeConfirmFeedback />
    <b-tabs
      class="tabs-big-theme pt-5 nav-fill"
    >

      <b-tab :active="active=='insuranceauthorization'">
        <template slot="title">
          <div class="tab-item-content h5"><InsuranceAuthorizationLogo width="26" /> <span>{{ $t('history.authorizaton_tab_text') }}</span></div>
        </template>
        <InsuranceAuthorizationTableList :pt_uuid="this.$route.params.pt_uuid" />
      </b-tab>

      <b-tab :active="active=='insurancerefund'">
        <template slot="title">
          <div class="tab-item-content h5 "><RefundCatchLogo width="26" /> <span>{{ $t('history.refund_tab_text') }}</span></div>
        </template>
        <InsuranceRefundTableList :pt_uuid="this.$route.params.pt_uuid" />
      </b-tab>

      <b-tab :active="active=='insurancebridge'">
        <template slot="title">
          <div class="tab-item-content h5 "><InsuranceBridgeLogo width="26" /> <span>{{ $t('history.bridge_tab_text') }}</span></div>
        </template>
        <InsuranceBridgeTableList :pt_uuid="this.$route.params.pt_uuid" />
      </b-tab>


      <!-- Pasarela PDV eliminada temporalmente, volverá a existir -->
      <!-- <b-tab :active="active=='gateway'">
        <template slot="title">
          <div class="tab-item-content h5 "><PaymentLogo width="26" /> <span>{{$t('history.gateway_tab_text')}}</span></div>
        </template>
        <p class="p-3">Coming soon</p>
      </b-tab> -->
    </b-tabs>
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import InsuranceAuthorizationTableList from "@/components/insuranceauthorization/InsuranceAuthorizationTableList";
import InsuranceRefundTableList from "@/components/insurancerefund/InsuranceRefundTableList";
import InsuranceBridgeTableList from "@/components/insurancebridge/InsuranceBridgeTableList";
import RefundCatchLogo from "@/static/home_refundcatch_logo.svg";
import InsuranceAuthorizationConfirmFeedback from "@/components/insuranceauthorization/InsuranceAuthorizationConfirmFeedback";
import InsuranceRefundConfirmFeedback from "@/components/insurancerefund/InsuranceRefundConfirmFeedback";
import InsuranceBridgeConfirmFeedback from "@/components/insurancebridge/InsuranceBridgeConfirmFeedback";
import InsuranceAuthorizationLogo from "@/static/home_insuranceauthorization_logo.svg";
import InsuranceBridgeLogo from "@/static/home_insurancebridge_logo.svg";
//import PaymentLogo from "@/static/home_payment_logo.svg";

export default {
  props: {
    pt_uuid: String,
    active: {
      type: String,
      default: "insuranceauthorization",
    },
  },
  components: {
    VLayout,
    InsuranceAuthorizationTableList,
    InsuranceRefundTableList,
    InsuranceAuthorizationLogo,
    RefundCatchLogo,
    //PaymentLogo,
    InsuranceAuthorizationConfirmFeedback,
    InsuranceBridgeConfirmFeedback,
    InsuranceRefundConfirmFeedback,
    InsuranceBridgeTableList,
    InsuranceBridgeLogo,
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
        {
          langkey: "breadcrumb.historial",
          to: { name: "nopatient_insuranceauthorization_historial" },
        },
      ]);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        history: {
          title: "Request history",
          authorizaton_tab_text: "PDV Authorization",
          refund_tab_text: "Refund",
          gateway_tab_text: "Gateway of PDV",
          bridge_tab_text: "Bridge",
        },
      },
    },
  },
  mounted: function () {
    this.putBreadCrumb();
  },
};
</script>
<style lang="scss" scoped>
.tab-item-content {
  svg {
    fill: $corp-color1;
    stroke: $corp-color1;
    padding-bottom: 4px;
  }
  span {
    line-height: 0.8rem;
    padding-left: 0.5rem;
  }
}
</style>
