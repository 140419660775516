// import axios from 'axios';
import {
  authx_auth_jwt_create_create,
  authx_logout_create,
  authx_auth_users_create_create,
  authx_auth_jwt_refresh_create,
  authx_auth_jwt_verify_create,
  authx_auth_me_read,
  authx_auth_password_reset_password,
  authx_auth_password_reset_reset_password_confirm,
  authx_auth_users_activation,
  authx_auth_country_list,
} from "@/lib/api-client.js";
import { getAxiosConfig } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    jwt: localStorage.getItem("jwt"),
    jwt_refresh: localStorage.getItem("jwt_refresh"),
    authenticated: false,
    keep_logged: JSON.parse(localStorage.getItem("keep_logged")) || false,
    current_username: null,
    current_user_email: null,
    countryRequest: null,
  },
  actions: {
    OPEN_AUTH_DIALOG: ({ commit }, config) => {
      commit("SET_AUTH_DIALOG_STATUS", { status: true, config });
    },
    CLOSE_AUTH_DIALOG: ({ commit }, config) => {
      commit("SET_AUTH_DIALOG_STATUS", { status: false, config });
    },
    REGISTER: (undefined, data) => {
      return new Promise((resolve, reject) => {
        authx_auth_users_create_create({
          data: {
            username: data.username,
            email: data.email,
            password: data.password,
            recaptcha_token: data.recaptcha_token,
          },
          role: "guest",
          roleUuid: "1",
          site: process.env.VUE_APP_SITE_CONTEXT,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            //debug
            reject(err);
          }
        );
      });
    },
    RECOVER_PASS: (undefined, data) => {
      return new Promise((resolve, reject) => {
        authx_auth_password_reset_password({
          data: {
            email: data.email,
            recaptcha_token: data.recaptcha_token,
          },
          role: "guest",
          roleUuid: "1",
          site: process.env.VUE_APP_SITE_CONTEXT,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    RECOVER_PASS_CONFIRM: (undefined, data) => {
      return new Promise((resolve, reject) => {
        authx_auth_password_reset_reset_password_confirm({
          data: {
            uid: data.uid,
            token: data.token,
            new_password: data.new_password,
          },
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    ACCOUNT_CONFIRMATION: (undefined, data) => {
      return new Promise((resolve, reject) => {
        authx_auth_users_activation({
          data: {
            uid: data.uid,
            token: data.token,
          },
          $config: getAxiosConfig(),
          role: "guest",
          roleUuid: "1",
          site: process.env.VUE_APP_SITE_CONTEXT,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    ME: ({ commit }) => {
      return new Promise((resolve, reject) => {
        authx_auth_me_read({
          $config: getAxiosConfig(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("MUTATE_ME", response.data);
            resolve(response);
          },
          err => {
            //debug
            reject(err);
          }
        );
      });
    },
    LOGIN: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        authx_auth_jwt_create_create({
          data: { email: data.data.email, password: data.data.password },
          $config: getAxiosConfig(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            // console.log('Updating token...');
            commit("UPDATE_TOKEN", response.data.access);
            commit("UPDATE_TOKEN_REFRESH", response.data.refresh);
            commit("SET_AUTHENTICATED");
            commit("SET_KEEP_LOGGED", { keep_logged: data.data.keep_logged });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOGOUT: ({ commit }) => {
      return new Promise((resolve, reject) => {
        authx_logout_create({
          data: {},
          $config: getAxiosConfig(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("REMOVE_TOKEN");
            commit("LOGOUT");
            resolve(response);
          },
          err => {
            // well, logout on error anyway
            commit("REMOVE_TOKEN");
            commit("LOGOUT");
            reject(err);
          }
        );
      });
    },
    SET_COUNTRY: ({ commit }, country) => {
      return new Promise(resolve => {
        commit("SET_COUNTRY", country);
        resolve(country);
      });
    },
    COUNTRY_REQUEST: ({ commit }) => {
      return new Promise((resolve, reject) => {
        authx_auth_country_list({
          $config: getAxiosConfig(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            commit("SET_COUNTRY", response.data.country);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    CHECK_TOKEN: ({ commit, state, dispatch }) => {
      // console.log("test for token...");

      const token = localStorage.getItem("jwt");
      if (token != state.jwt) {
        commit("UPDATE_TOKEN", token);
      }

      // console.log("Token is ", token);
      return new Promise((resolve, reject) => {
        // test if token exists and reject if not.
        if (!token) {
          // console.log("No token found to check, ensure unauthenticated.");
          commit("REMOVE_TOKEN");
          commit("LOGOUT");
          reject({ e: "Not authenticated, need login" });
          return;
        }

        // decode token or reject if fails
        try {
          var base64Url = token.split(".")[1];
          var base64 = base64Url.replace("-", "+").replace("_", "/");
          var decoded = JSON.parse(window.atob(base64));
          // console.log("decoded:", decoded);
        } catch (e) {
          // console.log("Malformed token, force logout.");
          commit("REMOVE_TOKEN");
          commit("LOGOUT");
          reject(e);
          return;
        }

        // console.log(decoded);
        var exp = decoded.exp;
        // var orig_iat = decoded.orig_iat;
        // console.log("expiration: ", new Date(exp * 1000));
        // console.log("orig_iat: ", new Date(orig_iat * 1000));

        // at this point, token exists and has been decoded, test for
        // expire and refresh if needed. If token cannot be refresh, remove
        // token and set authenticated state to false:

        var now = new Date(Date.now());
        var expires = new Date(exp * 1000);
        var diff = (expires - now) / 1000 / 60;

        // console.log("now: ", now);
        // console.log("expires: ", expires);
        // console.log("diff: ", diff);

        if (diff <= 3) {
          // Token expired or near to expiration,
          // try to refresh or logout if cannot
          // console.log("Token expired or near to expire.");
          // console.log("exp:", exp);
          // console.log("exp-300:", exp - 300);
          // console.log("Date.now() / 1000:", Date.now() / 1000);

          if (!state.keep_logged && diff <= 0) {
            // here we have expired token and keep_logged is false, so skip
            // refresh
            commit("LOGOUT");
            commit("REMOVE_TOKEN");
            reject("Not authenticated");
            return;
          }

          // here we have (expired token %% keep_loged) or near to expire token
          authx_auth_jwt_refresh_create({
            data: { refresh: localStorage.getItem("jwt_refresh") },
            $config: getAxiosConfig(),
            role: "guest",
            roleUuid: "1",
          }).then(
            response => {
              // console.log("Refresh token...");
              commit("UPDATE_TOKEN", response.data.access);
              // commit("UPDATE_TOKEN_REFRESH", response.data.refresh);
              // console.log("Setting user on refresh...");
              commit("SET_AUTHENTICATED");
              resolve(response);
            },
            err => {
              commit("REMOVE_TOKEN");
              commit("LOGOUT");
              reject(err);
            }
          );
        } else if (!state.authenticated && diff > 3) {
          // we have valid token but state is not authenticated, maybe the
          // browser has come back after close.
          // call backend to verify token and set authenticated if
          // verification pass (HTTP 200) or force logout if not (HTTP 400)
          // console.log("Valid token, but not authenticated state.");
          authx_auth_jwt_verify_create({
            data: { token: state.jwt },
            $config: getAxiosConfig(),
            role: "guest",
            roleUuid: "1",
          }).then(
            response => {
              // Do not refresh :)
              commit("SET_AUTHENTICATED");
              // console.log(
              //   "Token verify after not authenticated found and valid."
              // );
              dispatch("ME").then(
                () => {
                  resolve(response);
                },
                e => {
                  reject(e);
                }
              );
            },
            err => {
              // Token verified in local, but untrusted by backend
              commit("REMOVE_TOKEN");
              commit("LOGOUT");
              reject(err);
            }
          );
        } else if (state.authenticated && diff > 3) {
          resolve("True");
          // There is a valid token, this code perform a validation for extra
          // security, but could logout if the user has lost the connection.
          // (for example in mobile devices)
          //
          // authx_auth_jwt_verify_create({
          //   data: { token: state.jwt },
          //   $config: getAxiosConfig()
          // }).then(
          //   () => {
          //     console.log('Verification OK');
          //     resolve("True");
          //   },
          //   err => {
          //     // Token verified in local, but untrusted by backend
          //     console.log('Invalid token.');
          //     commit("REMOVE_TOKEN");
          //     commit("LOGOUT");
          //     reject(err);
          //   }
          // );
          // console.log('User authenticated and valid token');
        } else {
          // unknown state, force logout by default.
          // console.log("Token expired. Login.");
          commit("REMOVE_TOKEN");
          commit("LOGOUT");
          reject("Not authenticated, need login");
        }
      });
    },
  },
  mutations: {
    SET_COUNTRY: (state, country) => {
      Vue.set(state, "countryRequest", country);
    },
    UPDATE_TOKEN: (state, jwt) => {
      localStorage.setItem("jwt", jwt);
      Vue.set(state, "jwt", jwt);
    },
    UPDATE_TOKEN_REFRESH: (state, jwt_refresh) => {
      localStorage.setItem("jwt_refresh", jwt_refresh);
      Vue.set(state, "jwt_refresh", jwt_refresh);
    },
    REMOVE_TOKEN: state => {
      localStorage.removeItem("jwt");
      localStorage.removeItem("jwt_refresh");
      Vue.set(state, "authenticated", false);
      state.jwt = null;
    },
    SET_AUTH_DIALOG_STATUS: (state, { status, config }) => {
      Vue.set(state, "auth_dialog_visible", status);
      if (typeof config !== "undefined") {
        Vue.set(state, "auth_dialog_config", config);
      }
    },
    SET_AUTHENTICATED: state => {
      Vue.set(state, "authenticated", true);
    },
    SET_KEEP_LOGGED: (state, payload) => {
      localStorage.setItem("keep_logged", payload.keep_logged);
      Vue.set(state, "keep_logged", payload.keep_logged);
    },
    MUTATE_ME: (state, me) => {
      Vue.set(state, "current_username", me.username);
      Vue.set(state, "current_user_email", me.email);
    },
    LOGOUT: state => {
      Vue.set(state, "authenticated", false);
    },
  },
  getters: {
    // isAuthDialogVisible: state => {
    //   return state.auth_dialog_visible;
    // },
    countryFromRequest: state => {
      return state.countryRequest;
    },
    isAuthenticated: state => {
      return state.authenticated;
    },
    // getIdentity: state => {
    //   return state.user;
    // },
    // getAuthDialogSuccessRoute: state => {
    //   return state.auth_dialog_config.success_route;
    // }
  },
};
