import store from "@/store";

export const CurrencyException = function(message) {
  this.message = message;
  this.name = "CurrencyException";
};

export const convert_value_currency_money = function(source, target, value) {
  if (!source || source.length <= 0) {
    throw new CurrencyException("Source currency is empty");
  }
  if (!target || source.length <= 0) {
    throw new CurrencyException("Target currency is empty");
  }
  if (source == target) {
    return new Money(value, source);
  }

  let rates = store.getters["rates/getRates"];

  if (!rates || !rates[String(source)]) {
    throw new CurrencyException(
      "Unknown currency source in rates: (" + source + ")"
    );
  }
  if (!rates || !rates[String(target)]) {
    throw new CurrencyException(
      "Unknown currency target in rates: (" + target + ")"
    );
  }
  let source_base_rate = rates[source].value;
  let target_base_rate = rates[target].value;

  var converted = value * (1 / source_base_rate) * (target_base_rate / 1);
  return new Money(converted, target);
};

export const convert_money = function(money, target) {
  return convert_value_currency_money(money.currency, target, money.amount);
};

export var Money = function(amount, currency) {
  Number.call(this, amount);
  this.currency = currency;
  this.amount = amount;
};

Money.prototype = Object.create(Number.prototype);

Money.prototype.toString = function() {
  return this.toLocaleString();
};

Money.prototype.valueOf = function() {
  return this.amount;
};

Money.prototype.add = function(_money) {
  let money = Object.assign(
    Object.create(Object.getPrototypeOf(_money)),
    _money
  );

  if (money.currency != this.currency) {
    money = convert_money(_money, this.currency);
  }

  this.amount += money.amount;
};

Money.prototype.subs = function(_money) {
  if (_money.amount == 0) {
    return;
  }
  let money = Object.assign(
    Object.create(Object.getPrototypeOf(_money)),
    _money
  );

  if (money.currency != this.currency) {
    money = convert_money(_money, this.currency);
  }

  this.amount -= money.amount;
};

Money.prototype.toLocaleString = function(
  locales = undefined,
  options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
) {
  let amount_string = Number(this.amount).toLocaleString(locales, options);
  return this.currency + " " + amount_string;
};

Money.prototype.toLocaleStringNoCurrency = function(
  locales = undefined,
  options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
) {
  return Number(this.amount).toLocaleString(locales, options);
};
