import axios from "axios";
import {
  dummyResponseInterceptor,
  notificationErrorInterceptor,
} from "@/lib/api-interceptors.js";

axios.defaults.headers.post["Content-Type"] = "application/json";

// Add a response interceptor.
axios.interceptors.response.use(
  response => {
    return dummyResponseInterceptor(response);
  },
  error => {
    notificationErrorInterceptor(error);
    return Promise.reject(error);
  }
);

const handleI18nHeaders = () => {
  if (localStorage.getItem("force_country_code")) {
    axios.defaults.headers.post["X-Force-Country"] = localStorage.getItem(
      "force_country_code"
    );
    axios.defaults.headers.get["X-Force-Country"] = localStorage.getItem(
      "force_country_code"
    );
  } else {
    delete axios.defaults.headers.post["X-Force-Country"];
    delete axios.defaults.headers.get["X-Force-Country"];
  }

  if (localStorage.getItem("active_langtag")) {
    var lang = localStorage
      .getItem("active_langtag")
      .replace("_", "-")
      .toLowerCase();
    axios.defaults.headers.post["Accept-Language"] = lang;
    axios.defaults.headers.get["Accept-Language"] = lang;
    axios.defaults.headers.put["Accept-Language"] = lang;
    axios.defaults.headers.patch["Accept-Language"] = lang;
  } else {
    axios.defaults.headers.post["Accept-Language"] = navigator.language;
    axios.defaults.headers.get["Accept-Language"] = navigator.language;
    axios.defaults.headers.put["Accept-Language"] = navigator.language;
    axios.defaults.headers.patch["Accept-Language"] = navigator.language;
  }
};

const handleSiteContextHeaders = () => {
  axios.defaults.headers.get["Site-Context"] = process.env.VUE_APP_SITE_CONTEXT;
  axios.defaults.headers.post["Site-Context"] =
    process.env.VUE_APP_SITE_CONTEXT;
  axios.defaults.headers.put["Site-Context"] = process.env.VUE_APP_SITE_CONTEXT;
  axios.defaults.headers.patch["Site-Context"] =
    process.env.VUE_APP_SITE_CONTEXT;
  axios.defaults.headers.delete["Site-Context"] =
    process.env.VUE_APP_SITE_CONTEXT;
};

export const getAxiosConfig = (extraconf = {}, rmI18n = false) => {
  if (rmI18n) {
    delete axios.defaults.headers.post["X-Force-Country"];
    delete axios.defaults.headers.get["X-Force-Country"];
    axios.defaults.headers.post["Accept-Language"] = navigator.language;
    axios.defaults.headers.get["Accept-Language"] = navigator.language;
    axios.defaults.headers.put["Accept-Language"] = navigator.language;
    axios.defaults.headers.patch["Accept-Language"] = navigator.language;
  }
  handleI18nHeaders();

  // Make sure to set the site context. We need that in the backend so we can
  // find out from where did the request originated.
  handleSiteContextHeaders();

  // strange bug found with this header. Its necessary to set
  // a config globally to take effect
  axios.defaults.headers.get["Authorization"] =
    "JWT " + localStorage.getItem("jwt");

  if (localStorage.getItem("jwt")) {
    return Object.assign(
      {
        //baseURL: `http://localhost:8000/apiv1/`,
        crossDomain: true,
        headers: {
          Authorization: "JWT " + localStorage.getItem("jwt"),
        },
      },
      extraconf
    );
  }

  axios.defaults.headers.get["Authorization"] = null;
  return Object.assign(
    {
      crossDomain: true,
    },
    extraconf
  );
};

export const getAxiosConfigNoAuth = (extraconf = {}, rmI18n = false) => {
  if (rmI18n) {
    delete axios.defaults.headers.post["X-Force-Country"];
    delete axios.defaults.headers.get["X-Force-Country"];
    axios.defaults.headers.post["Accept-Language"] = navigator.language;
    axios.defaults.headers.get["Accept-Language"] = navigator.language;
    axios.defaults.headers.put["Accept-Language"] = navigator.language;
    axios.defaults.headers.patch["Accept-Language"] = navigator.language;
  }
  handleI18nHeaders();

  // Make sure to set the site context. We need that in the backend so we can
  // find out from where did the request originated.
  handleSiteContextHeaders();

  axios.defaults.headers.get["Authorization"] = null;
  delete axios.defaults.headers.get["Authorization"];
  return Object.assign(
    {
      crossDomain: true,
    },
    extraconf
  );
};

export const getApiUri = () => {
  const hostname =
    process.env.VUE_APP_BACKEND_ADDRESS || window.location.hostname;
  let port = process.env.VUE_APP_BACKEND_PORT;
  const protocol = process.env.VUE_APP_BACKEND_PROTOCOL;
  const suffix = process.env.VUE_APP_BACKEND_API_SUFFIX;

  let uri = `${protocol}://${hostname}`;
  if (port.length > 0) {
    return `${uri}:${port}${suffix}`; // do not put ending slash
  }
  return `${uri}${suffix}`; // do not put ending slash
};
