<template>
  <div>
    <b-container fluid class="px-0">
      <b-row>
        <b-col cols="12" md="6">
          <ContactImage />
        </b-col>
        <b-col cols="12" md="6">
          <vue-element-loading :active="sending_form" />
          <b-form @submit="sendForm">
            <div class="title-container">
              <h2 class="title mb-3">{{ $t('contact_form.title') }}</h2>
            </div>
            <p>
              {{ $t('contact_form.explanation') }}
            </p>
            <VBootstrapFormGroup
              name="fullname"
              required
              :label="$t('contact_form.fullname_label') + ':'"
              label-cols-xl="12"
              label-cols-lg="12"
              :placeholder="$t('contact_form.fullname_placeholder')"
              :invalid_feedback="form_invalid_feedback.fullname"
              :state="form_state.fullname"
              v-model.trim="form.fullname"
            />
            <VBootstrapFormGroup
              name="email"
              required
              :label="$t('contact_form.email_label') + ':'"
              label-cols-xl="12"
              label-cols-lg="12"
              :placeholder="$t('contact_form.email_placeholder')"
              :invalid_feedback="form_invalid_feedback.email"
              :state="form_state.email"
              v-model.trim="form.email"
            />
            <VBootstrapFormGroup
              name="cellphone"
              required
              :label="$t('contact_form.cellphone_label') + ':'"
              label-cols-xl="12"
              label-cols-lg="12"
              :placeholder="$t('contact_form.cellphone_placeholder')"
              :invalid_feedback="form_invalid_feedback.cellphone"
              :state="form_state.cellphone"
              v-model.trim="form.cellphone"
            />
            <VBootstrapFormGroup
              name="message"
              type="textarea"
              required
              :label="$t('contact_form.message_label') + ':'"
              label-cols-xl="12"
              label-cols-lg="12"
              :placeholder="$t('contact_form.message_placeholder')"
              :invalid_feedback="form_invalid_feedback.message"
              :state="form_state.message"
              v-model.trim="form.message"
            />
            <b-button
              variant="primary"
              size="lg"
              class="ml-auto"
              type="submit"
            >{{ $t('contact_form.send_button') }}</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import ContactImage from "@/static/contact_image.svg";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";

export default {
  components: {
    ContactImage,
    VBootstrapFormGroup,
  },
  data() {
    return {
      sending_form: false,
      form: {
        fullname: "",
        email: "",
        cellphone: "",
        message: "",
      },
      form_invalid_feedback: {},
      form_state: {},
    };
  },
  methods: {
    sendForm(evt) {
      this.sending_form = true;
      evt.preventDefault();
      this.$recaptcha("contact_form").then((token) => {
        this.$store
          .dispatch("naturalperson/SEND_CONTACT_FORM", {
            fullname: this.form.fullname,
            email: this.form.email,
            cellphone: this.form.cellphone,
            message: this.form.message,
            recaptcha_token: String(token),
          })
          .then(
            () => {
              this.sending_form = false;
              this.$store.dispatch("appstatus/PUSH_AFTER_ROUTE_NOTIFICATION", {
                type: "success",
                text: this.$t("contact_form.data_sent"),
              });
              this.$router.push({
                name: "userhome",
              });
            },
            (err) => {
              this.sending_form = false;
              this.$sentry({ capture: err });
              this.mnotify_error({
                text: this.$t("contact_form.error.data_not_sent"),
              });
            }
          );
      });
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        contact_form: {
          fullname_label: "Full Name",
          fullname_placeholder: "Full Name",
          email_label: "Email",
          email_placeholder: "Email",
          cellphone_label: "Cell Phone",
          cellphone_placeholder: "Cell Phone",
          message_label: "Subject",
          message_placeholder: "Subject",
          explanation:
            "To get in touch, fill out the form or send us an email to clientes@globalpdv.com. We will respond to you as soon as possible!",
          send_button: "Send",
          data_sent: "Your request has been sent",
          error: {
            data_not_sent: "Your request cannot be sent",
          },
        },
      },
    },
  },
};
</script>


<style lang="scss" scoped>
.title-container /deep/ .title {
  color: $corp-color1;
  font-weight: 300;
}
</style>
