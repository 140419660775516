import {
  drugtransaction_insurance_insurancerefund_file_list,
  drugtransaction_insurance_insurancerefund_file_create,
  drugtransaction_insurance_insurancerefund_file_delete,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    insurancerefund_files: [],
  },
  actions: {
    CREATE_PATIENT_INSURANCE_INSURANCEREFUND_FILE: (undefined, payload) => {
      let extraconf = {};
      if (payload.onUploadProgress) {
        extraconf["onUploadProgress"] = payload.onUploadProgress;
      }
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_file_create({
          $config: axconf(extraconf),
          role: "guest",
          roleUuid: "1",
          data: payload.data,
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_INSURANCE_INSURANCEREFUND_FILES: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_file_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
        }).then(
          response => {
            commit("MUTATE_INSURANCEREFUND_FILES", {
              data: response.data.results,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DELETE_PATIENT_INSURANCE_INSURANCEREFUND_FILE: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_file_delete({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
          refUuid: payload.ref_uuid,
        }).then(
          response => {
            // commit('MUTATE_INSURANCEREFUND_FILES', {
            //   data: response.data.results
            // });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_INSURANCEREFUND_FILES: (state, payload) => {
      Vue.set(state, "insurancerefund_files", payload.data);
    },
  },
  getters: {},
};
