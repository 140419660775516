<template>
  <div class="d-none d-sm-none d-md-block">
    <b-breadcrumb v-if="raw_breadcrumb" ref="breadcrumb">
      <b-breadcrumb-item
        v-for="(item,index) in raw_breadcrumb"
        :to="item.to"
        :key="index"
        :active="index+1==raw_breadcrumb.length"
      >
        <font-awesome-icon v-if="index == 0" icon="home" />
        <span v-else>{{ $t(item.langkey, item.langparams || null) }}</span>
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "the-breadcrumb",
  computed: {
    ...mapState({
      raw_breadcrumb: (state) => state.siteconf.raw_breadcrumb,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        breadcrumb: {
          home: "Home",
          refund: "PDV Authorization",
          refund_insurance_selection: "Insurances",
          insuranceauthorization_home: "Authorization for {insurance_name}",
          insurancebridge_home: "Bridge for {insurance_name}",
          insurancerefund_home: "Refund for {insurance_name}",
          refund_list: "Refund list for {insurance_name}",
          refund_list_single: "Refund list",
          refund_recipe: "Refund",
          authorization_recipe: "Recipe",
          bridge_recipe: "Recipe",
          userhome: "Home",
          register: "Register",
          login: "Login",
          account: "My Account",
          recover_pass: "Recover password",
          recover_pass_confirm: "Recover password. Enter new password.",
          my_addresses: "My Addresses",
          addressform_title: "Address",
          historial: "Historial",
          patientdata: "Personal Data",
          patient_customer_data: "Major medical health insurance data",
          mmilist: "Major medical health insurance",
          refund_detail: "Refund detail",
          insuranceauthorization_detail: "Insurance authorization detail",
          insurancebridge_detail: "Detail",
          faq: "Frequent questions",
          insurance_risk_payment_title: "Deductible and coinsurance payment",
          insurance_risk_payment_list_title:
            "Deductible and coinsurance payment list",
        },
      },
    },
  },
};
</script>


<style scoped>
</style>
