<template>
  <div>
    <b-form @submit="sendForm" @reset="onReset" v-if="show">

      <b-form-group
        id="group1"
        class="text-left"
        :label="$t('pharmacy_create.public_name_label')"
        label-for="public_name"
        :description="$t('pharmacy_create.public_name_desc')"
        :invalid-feedback="form_invalid_feedback.public_name"
        :state="form_state.public_name"
      >
        <b-form-input
          id="public_name"
          type="text"
          v-model.trim="form.public_name"
          required
          :state="form_state.public_name"
          :placeholder="$t('pharmacy_create.public_name_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group2"
        class="text-left"
        :label="$t('pharmacy_create.business_line_label')"
        label-for="business_line"
        :description="$t('pharmacy_create.business_line_desc')"
        :invalid-feedback="form_invalid_feedback.business_line"
        :state="form_state.business_line"
      >
        <b-form-input
          id="business_line"
          type="text"
          v-model.trim="form.business_line"
          required
          :state="form_state.business_line"
          :placeholder="$t('pharmacy_create.business_line_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group3"
        class="text-left"
        :label="$t('pharmacy_create.address_label')"
        label-for="address"
        :description="$t('pharmacy_create.address_desc')"
        :invalid-feedback="form_invalid_feedback.address"
        :state="form_state.address"
      >
        <b-form-input
          id="address"
          type="text"
          v-model.trim="form.address"
          required
          :state="form_state.address"
          :placeholder="$t('pharmacy_create.address_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group4"
        class="text-left"
        :label="$t('pharmacy_create.colony_label')"
        label-for="colony"
        :description="$t('pharmacy_create.colony_desc')"
        :invalid-feedback="form_invalid_feedback.colony"
        :state="form_state.colony"
      >
        <b-form-input
          id="colony"
          type="text"
          v-model.trim="form.colony"
          required
          :state="form_state.colony"
          :placeholder="$t('pharmacy_create.colony_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group5"
        class="text-left"
        :label="$t('pharmacy_create.country_label')"
        label-for="country"
        :description="$t('pharmacy_create.country_desc')"
        :invalid-feedback="form_invalid_feedback.country"
        :state="form_state.country"
      >
        <b-form-select id="country" v-model="form.country" class="mb-3">
          <option :value="null">{{ $t('global.select_country_helper') }}</option>
          <option 
            v-for="(field) in country_fields"
            :value="field.value"
            v-bind:key="field.langkey">{{ $t(field.langkey) }}
          </option>

        </b-form-select>
      </b-form-group>


      <b-form-group
        id="group6"
        class="text-left"
        :label="$t('pharmacy_create.tax_id_label')"
        label-for="tax_id"
        :description="$t('pharmacy_create.tax_id_desc')"
        :invalid-feedback="form_invalid_feedback.tax_id"
        :state="form_state.tax_id"
      >
        <b-form-input
          id="tax_id"
          type="text"
          v-model.trim="form.tax_id"
          required
          :state="form_state.tax_id"
          :placeholder="$t('pharmacy_create.tax_id_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group7"
        class="text-left"
        :label="$t('pharmacy_create.legal_name_label')"
        label-for="legal_name"
        :description="$t('pharmacy_create.legal_name_desc')"
        :invalid-feedback="form_invalid_feedback.legal_name"
        :state="form_state.legal_name"
      >
        <b-form-input
          id="legal_name"
          type="text"
          v-model.trim="form.legal_name"
          required
          :state="form_state.legal_name"
          :placeholder="$t('pharmacy_create.legal_name_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group8"
        class="text-left"
        :label="$t('pharmacy_create.postal_code_label')"
        label-for="postal_code"
        :description="$t('pharmacy_create.postal_code_desc')"
        :invalid-feedback="form_invalid_feedback.postal_code"
        :state="form_state.postal_code"
      >
        <b-form-input
          id="postal_code"
          type="text"
          v-model.trim="form.postal_code"
          required
          :state="form_state.postal_code"
          :placeholder="$t('pharmacy_create.postal_code_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="group9"
        class="text-left"
        :label="$t('pharmacy_create.town_label')"
        label-for="town"
        :description="$t('pharmacy_create.town_desc')"
        :invalid-feedback="form_invalid_feedback.town"
        :state="form_state.town"
      >
        <b-form-input
          id="town"
          type="text"
          v-model.trim="form.town"
          required
          :state="form_state.town"
          :placeholder="$t('pharmacy_create.town_placeholder')"
        ></b-form-input>
      </b-form-group>


      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "profile-pharmacy-create-form",
  data() {
    return {
      form: {
        public_name: "",
        legal_name: "",
        id: "",
        address: "",
        town: "",
        country: "",
        colony: "",
        postal_code: "",
        business_line: "",
      },
      form_invalid_feedback: {},
      form_state: {},
      show: true,
    };
  },
  computed: {
    ...mapState({
      country_fields: state => state.siteconf.country_fields,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        pharmacy_create: {
          public_name_label: "Public name",
          public_name_desc: " ",
          public_name_placeholder: " ",
          legal_name_label: "Tax name",
          legal_name_desc: " ",
          legal_name_placeholder: " ",
          tax_id_label: "Tax id",
          tax_id_desc: " ",
          tax_id_placeholder: " ",
          address_label: "Tax Address",
          address_desc: " ",
          address_placeholder: " ",
          town_label: "Tax Town",
          town_desc: " ",
          town_placeholder: " ",
          country_label: "Tax country",
          country_desc: " ",
          country_placeholder: " ",
          colony_label: "Tax colony",
          colony_desc: " ",
          colony_placeholder: " ",
          postal_code_label: "Tax postal code",
          postal_code_desc: " ",
          postal_code_placeholder: " ",
          business_line_label: "Business line",
          business_line_desc: " ",
          business_line_placeholder: " ",
          submit_button: "Submit",
          reset_button: "Reset",
          error: {
            saving_pharmacy: "Cannot save pharmacy",
          },
        },
      },
    },
  },
  methods: {
    sendForm(evt) {
      evt.preventDefault();
      var data = this.form;
      this.$store.dispatch("organization/NEW_PHARMACY", { data: data }).then(
        () => {
          this.$emit("pharmacy-created");
        },
        () => {
          this.mnotify_error({
            text: this.$t("pharmacy_create.error.saving_pharmacy"),
          });
        }
      );
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      /* Trick to reset/clear native browser form validation state */
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>


<style scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}
</style>
