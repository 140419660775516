import { monetary_rates_list } from "@/lib/api-client.js";
import { getAxiosConfigNoAuth as axconf_noauth } from "@/lib/api-config.js";

import Vue from "vue";

const RATES_STATUS_NOT_LOADED = 0;
const RATES_STATUS_LOADING = 1;
const RATES_STATUS_LOADED = 2;
const RATES_STATUS_ERROR = 3;

export default {
  namespaced: true,
  state: {
    rates: {},
    rates_status: RATES_STATUS_NOT_LOADED,
  },
  actions: {
    SET_RATES: ({ commit }, rates) => {
      return new Promise(resolve => {
        commit("MUTATE_RATES", {
          data: { results: rates },
        });
        commit("SET_RATES_STATUS", RATES_STATUS_LOADED);
        resolve(true);
      });
    },
    FETCH_RATES: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        let offset = 0;
        let limit = 100;

        if (payload && payload.offset) {
          offset = payload.offset;
        }

        if (payload && payload.limit) {
          limit = payload.limit;
        }
        commit("SET_RATES_STATUS", RATES_STATUS_LOADING);
        monetary_rates_list({
          $config: axconf_noauth(),
          role: "guest",
          roleUuid: "1",
          offset: offset,
          limit: limit,
        }).then(
          response => {
            commit("MUTATE_RATES", {
              data: response.data,
            });
            if (response.data.next) {
              var nexturl = new URL(response.data.next);
              let indexOfLimit = nexturl.search.indexOf("limit");
              let indexOfOffset = nexturl.search.indexOf("offset");
              if (indexOfLimit >= 0 && indexOfOffset >= 0) {
                let limit = response.data.next.match(
                  "[?&]" + "limit" + "=([^&]+)"
                ) || [undefined, undefined];
                let offset = response.data.next.match(
                  "[?&]" + "offset" + "=([^&]+)"
                ) || [undefined, undefined];
                dispatch(
                  "FETCH_RATES",
                  {
                    limit: limit[1],
                    offset: offset[1],
                  },
                  undefined,
                  err => {
                    reject(err);
                  }
                );
              }
            } else {
              commit("SET_RATES_STATUS", RATES_STATUS_LOADED);
            }
            resolve(response);
          },
          err => {
            commit("SET_RATES_STATUS", RATES_STATUS_ERROR);
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    SET_RATES_STATUS: (state, status) => {
      Vue.set(state, "rates_status", status);
    },
    MUTATE_RATES: (state, payload) => {
      let rates = state.rates;
      for (let idx in payload.data.results) {
        let currency = payload.data.results[idx].currency;
        rates[currency] = payload.data.results[idx];
      }
      Vue.set(state, "rates", rates);
    },
  },
  getters: {
    getRates: state => {
      return state.rates;
    },
    isRatesLoaded: state => {
      return state.rates_status == RATES_STATUS_LOADED;
    },
    isRatesError: state => {
      return state.rates_status == RATES_STATUS_ERROR;
    },
  },
};
