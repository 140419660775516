import {
  drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list,
  drugtransaction_insurancerefund_insurance_insurancerefundconfig_list,
  drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list,
  drugtransaction_ra_insuranceriskpayment_create,
  drugtransaction_ra_insuranceriskpayment_update,
  drugtransaction_ra_insuranceriskpayment_list,
  drugtransaction_ra_insuranceriskpayment_availinsurances_list,
  drugtransaction_ra_insuranceriskpayment_request_invoice_create,
  drugtransaction_payment_intention_create,
  drugtransaction_payment_intention_read,
  drugtransaction_checkout_doneinsuranceriskpayment_create,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    insuranceriskpayment: undefined,
    insuranceauthorization_config_files: [],
    insurancebridge_config_files: [],
    insurancerefund_config_files: [],
    payment_intentions: [],
  },
  actions: {
    LOAD_INSURANCE_INSURANCEAUTHORIZATION_CONFIG_FILES: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insuranceauthorization_insurance_insuranceauthorizationconfig_list(
          {
            $config: axconf(),
            role: "guest",
            roleUuid: "1",
            iUuid: payload.i_uuid,
          }
        ).then(
          response => {
            commit("MUTATE_INSURANCE_INSURANCEAUTHORIZATION_CONFIG_FILES", {
              data: response.data,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_INSURANCE_INSURANCEBRIDGE_CONFIG_FILES: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurancebridge_insurance_insurancebridgeconfig_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          iUuid: payload.i_uuid,
        }).then(
          response => {
            commit("MUTATE_INSURANCE_INSURANCEBRIDGE_CONFIG_FILES", {
              data: response.data,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_INSURANCE_INSURANCEREFUND_CONFIG_FILES: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurancerefund_insurance_insurancerefundconfig_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          iUuid: payload.i_uuid,
        }).then(
          response => {
            commit("MUTATE_INSURANCE_INSURANCEREFUND_CONFIG_FILES", {
              data: response.data,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_CREATE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_ra_insuranceriskpayment_create({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          data: payload.data,
        }).then(
          response => {
            commit("MUTATE_INSURANCERISKPAYMENT", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_UPDATE: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_ra_insuranceriskpayment_update({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          insuranceriskpaymentUuid: payload.insurance_risk_payment_uuid,
          data: payload.data,
        }).then(
          response => {
            commit("MUTATE_INSURANCERISKPAYMENT", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_AVAILINSURANCES_LIST: () => {
      return new Promise((resolve, reject) => {
        drugtransaction_ra_insuranceriskpayment_availinsurances_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DRUGTRANSACTION_RA_INSURANCERISKPAYMENT_LIST: (undefined, payload) => {
      const offset = payload.limit * (payload.current_page - 1);
      delete payload["current_page"];
      return new Promise((resolve, reject) => {
        drugtransaction_ra_insuranceriskpayment_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ...payload,
          offset: offset,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    CREATE_PAYMENT_INTENTION: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_payment_intention_create({
          data: payload.data,
          role: "guest",
          roleUuid: "1",
          $config: axconf(),
        }).then(
          response => {
            commit("MUTATE_PUSH_PAYMENT_INTENTION", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DRUGTRANSACTION_CHECKOUT_DONEINSURANCERISKPAYMENT_CREATE: (
      undefined,
      payload
    ) => {
      return new Promise((resolve, reject) => {
        drugtransaction_checkout_doneinsuranceriskpayment_create({
          role: "guest",
          roleUuid: "1",
          checkoutUuid: payload.checkout_uuid,
          data: payload.data,
          $config: axconf(),
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    INSURANCERISKPAYMENT_REQUEST_INVOICE: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_ra_insuranceriskpayment_request_invoice_create({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          insuranceriskpaymentUuid: payload.insuranceriskpayment_uuid,
          data: payload.data,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    READ_PAYMENT_INTENTION: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_payment_intention_read({
          role: "guest",
          roleUuid: "1",
          paymentIntentionUuid: payload.payment_intention_uuid,
          $config: axconf(),
        }).then(
          response => {
            if (response.data.done == true) {
              commit("MUTATE_DELETE_PAYMENT_INTENTION", response.data);
            }
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_DELETE_PAYMENT_INTENTION: (state, intention) => {
      let found_index = null;
      if (!state.payment_intentions) {
        return;
      }

      state.payment_intentions.forEach((element, index) => {
        if (element.uuid == intention.uuid) {
          found_index = index;
        }
      });
      if (found_index) {
        state.payment_intentions.splice(found_index, 1);
      }
    },
    MUTATE_PUSH_PAYMENT_INTENTION: (state, intention) => {
      state.payment_intentions.push(intention);
    },
    MUTATE_INSURANCERISKPAYMENT: (state, payload) => {
      state.insuranceriskpayment = Object.assign({}, payload);
    },
    MUTATE_INSURANCE_INSURANCEAUTHORIZATION_CONFIG_FILES: (state, payload) => {
      let insuranceauthorization_config_files =
        state.insuranceauthorization_config_files;
      insuranceauthorization_config_files[payload.i_uuid] =
        payload.data.results;
      Vue.set(
        state,
        "insuranceauthorization_config_files",
        insuranceauthorization_config_files
      );
    },
    MUTATE_INSURANCE_INSURANCEBRIDGE_CONFIG_FILES: (state, payload) => {
      let insurancebridge_config_files = state.insurancebridge_config_files;
      insurancebridge_config_files[payload.i_uuid] = payload.data.results;
      Vue.set(
        state,
        "insurancebridge_config_files",
        insurancebridge_config_files
      );
    },

    MUTATE_INSURANCE_INSURANCEREFUND_CONFIG_FILES: (state, payload) => {
      let insurancerefund_config_files = state.insurancerefund_config_files;
      insurancerefund_config_files[payload.i_uuid] = payload.data.results;
      Vue.set(
        state,
        "insurancerefund_config_files",
        insurancerefund_config_files
      );
    },
  },
  getters: {
    getConfigFilesByIUUID: state => i_uuid => {
      if (!(i_uuid in state.insuranceauthorization_config_files)) {
        return null;
      }
      return state.insuranceauthorization_config_files[i_uuid];
    },
    getBridgeConfigFilesByIUUID: state => i_uuid => {
      if (!(i_uuid in state.insurancebridge_config_files)) {
        return null;
      }
      return state.insurancebridge_config_files[i_uuid];
    },
    getRefundConfigFilesByIUUID: state => i_uuid => {
      if (!(i_uuid in state.insurancerefund_config_files)) {
        return null;
      }
      return state.insurancerefund_config_files[i_uuid];
    },
  },
};
