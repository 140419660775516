<template>
  <VLayout
    upline
    :title="$t('home_user.my_addresses')"
  >
    <AddressList class="mb-3" />

    <div class="buttons-container">
      <b-button
        @click="$router.go(-1)"
        variant="secondary"
        class="d-none d-sm-none d-md-block"
      >{{ $t('my_addresses.return') }}</b-button>

      <b-button
        variant="primary"
        size="lg"
        @click="routeTo({ name: 'useraddressnew' })"
      >{{ $t('my_addresses.add_address') }}</b-button>
    </div>

  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import AddressList from "@/components/AddressList";

export default {
  props: {
    pt_uuid: String,
  },
  components: {
    VLayout,
    AddressList,
  },
  fallbackI18n: {
    messages: {
      und: {
        my_addresses: {
          add_address: "Add address",
          return: "Back",
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
        {
          langkey: "breadcrumb.my_addresses",
          to: { name: "addresses" },
        },
      ]);
    },
  },
};
</script>
