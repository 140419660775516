<template>
  <div>
    <b-row v-if="show">
      <b-col sm="3">
        <b-form-group class="text-left">
          <b-form-checkbox
            v-model="form.delivery_to_home"
            :state="delivery_address_state"
          >
            {{ $t("insurancebridge_prescription_delivery_address.send_home") }}
          </b-form-checkbox>
          <b-form-invalid-feedback :state="delivery_address_state">
            {{
              $t(
                "insurancebridge_prescription_delivery_address.invelid_delivery_data_feedback"
              )
            }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="9" class="pl-1">
        <div class="pl-2">
          <b-btn
            variant="primary-inline"
            @click="deliveryAddressShow = true"
            :disabled="!form.delivery_to_home || form.pharmacy_pickup"
          >
            {{ $t("insurancebridge_prescription_delivery_address.button") }}
          </b-btn>
          <div class="btn pl-2" id="delivery-address-popover">
            <font-awesome-icon icon="eye" />
          </div>
          <b-popover
            triggers="hover focus click"
            target="delivery-address-popover"
            :title="$t('insurancebridge_prescription_delivery_address.button')"
            :placement="'auto'"
          >
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.firstname_label")
              }}</strong
              >: {{ form.delivery_address.firstname }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.town_label")
              }}</strong
              >: {{ form.delivery_address.town }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.phone_label")
              }}</strong
              >: {{ form.delivery_address.phone }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.mobile_label")
              }}</strong
              >: {{ form.delivery_address.mobile }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.address_label")
              }}</strong
              >: {{ form.delivery_address.address }}
            </div>
            <div>
              <strong>{{
                $t(
                  "insurancebridge_prescription_delivery_address.federal_entity_label"
                )
              }}</strong
              >: {{ form.delivery_address.federal_entity }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.colony_label")
              }}</strong
              >: {{ form.delivery_address.colony }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.postal_code_label")
              }}</strong
              >: {{ form.delivery_address.postal_code }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.hours_label")
              }}</strong
              >: {{ form.delivery_address.hours }}
            </div>
            <div>
              <strong>{{
                $t("insurancebridge_prescription_delivery_address.date_label")
              }}</strong
              >: {{ form.delivery_address.date }}
            </div>
          </b-popover>
        </div>
      </b-col>
    </b-row>
    <b-modal
      size="xl"
      modal-class="modal-square-theme"
      v-model="deliveryAddressShow"
      centered
      :title="$t('insurancebridge_prescription_delivery_address.button')"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12" lg="6" md="12" sm="12" xs="12">
            <b-form-group
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              :label="
                $t('insurancebridge_prescription_delivery_address.firstname_label')
              "
              label-for="delivery_address-firstname"
              :invalid-feedback="
                form_invalid_feedback.delivery_address.firstname
              "
              :state="form_state.delivery_address.firstname"
            >
              <template v-slot:label>
                {{ $t('insurancebridge_prescription_delivery_address.firstname_label') }} <span class="text-danger">*</span>
              </template>
              <b-input-group>
                <b-form-input
                  id="delivery_address-firstname"
                  type="text"
                  v-model.trim="form.delivery_address.firstname"
                  required
                  :state="form_state.delivery_address.firstname"
                  v-on:input="form_state.delivery_address.firstname = undefined"
                  :placeholder="
                    $t(
                      'insurancebridge_prescription_delivery_address.firstname_placeholder'
                    )
                  "
                ></b-form-input>
                <b-input-group-append>
                  <InsuranceBridgeRecipeAddressSelector
                    v-on:address-selected="onAddressSelected"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <VBootstrapFormGroup
              name="delivery_address-colony"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="$t('insurancebridge_prescription_delivery_address.colony_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.colony_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.colony"
              :state="form_state.delivery_address.colony"
              v-model.trim="form.delivery_address.colony"
              v-on:input="form_state.delivery_address.colony = undefined"
            />
            <VBootstrapFormGroup
              name="delivery_address-federal_entity"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="
                $t('insurancebridge_prescription_delivery_address.federal_entity_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.federal_entity_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.federal_entity"
              :state="form_state.delivery_address.federal_entity"
              v-model.trim="form.delivery_address.federal_entity"
              v-on:input="form_state.delivery_address.federal_entity = undefined"
            />
            <VBootstrapFormGroup
              name="delivery_address-phone"
              type="phone"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              required
              :label="$t('insurancebridge_prescription_delivery_address.phone_label')"
              :placeholder="
                $t('insurancebridge_prescription_delivery_address.phone_placeholder')
              "
              :invalid_feedback="form_invalid_feedback.delivery_address.phone"
              :state="form_state.delivery_address.phone"
              v-on:input="handleOnPhoneInput"
              v-model.trim="form.delivery_address.phone"
            />
            <VBootstrapFormGroup
              name="delivery_address-mobile"
              type="phone"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              required
              :label="$t('insurancebridge_prescription_delivery_address.mobile_label')"
              :placeholder="
                $t('insurancebridge_prescription_delivery_address.mobile_placeholder')
              "
              :invalid_feedback="form_invalid_feedback.delivery_address.mobile"
              :state="form_state.delivery_address.mobile"
              v-on:input="handleOnMobileInput"
              v-model.trim="form.delivery_address.mobile"
            />


            <VBootstrapFormGroup
              name="delivery_address-country"
              type="country"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              required
              :label="$t('insurancebridge_prescription_delivery_address.country_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.country_placeholder')"
              :invalid_feedback="form_invalid_feedback.delivery_address.country"
              :state="form_state.delivery_address.country"
              v-model.trim="form.delivery_address.country"
            />
          </b-col>

          <b-col cols="12" lg="6" md="12" sm="12" xs="12">
            <VBootstrapFormGroup
              name="delivery_address-address"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="$t('insurancebridge_prescription_delivery_address.address_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.address_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.address"
              :state="form_state.delivery_address.address"
              v-model.trim="form.delivery_address.address"
              v-on:input="form_state.delivery_address.address = undefined"
            />
            <VBootstrapFormGroup
              name="delivery_address-town"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="$t('insurancebridge_prescription_delivery_address.town_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.town_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.town"
              :state="form_state.delivery_address.town"
              v-model.trim="form.delivery_address.town"
              v-on:input="form_state.delivery_address.town = undefined"
            />

            <VBootstrapFormGroup
              name="delivery_address-postal_code"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="$t('insurancebridge_prescription_delivery_address.postal_code_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.postal_code_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.postal_code"
              :state="form_state.delivery_address.postal_code"
              v-model.trim="form.delivery_address.postal_code"
              v-on:input="form_state.delivery_address.postal_code = undefined"
            />
            <VBootstrapFormGroup
              name="delivery_address-hours"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="$t('insurancebridge_prescription_delivery_address.hours_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.hours_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.hours"
              :state="form_state.delivery_address.hours"
              v-model.trim="form.delivery_address.hours"
              v-on:input="form_state.delivery_address.hours = undefined"
            />
            <VBootstrapFormGroup
              name="delivery_address-date"
              type="date2"
              label-cols="12"
              label-cols-sm="12"
              label-cols-md="12"
              label-cols-lg="4"
              label-cols-xl="4"
              class="text-left"
              required
              :label="$t('insurancebridge_prescription_delivery_address.date_label')"
              :placeholder="$t('insurancebridge_prescription_delivery_address.date_placeholder')"
              :invalid-feedback="form_invalid_feedback.delivery_address.date"
              :state="form_state.delivery_address.date"
              :min="new Date()"
              v-model.trim="form.delivery_address.date"
              v-on:input="form_state.delivery_address.date = undefined"
            >
              <b-form-text id="input-live-help">*{{ $t('insurancebridge_prescription_delivery_address.date_help_text') }}</b-form-text>
            </VBootstrapFormGroup>

          </b-col>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-btn
          size="sm"
          id="save-button"
          class="float-right"
          variant="primary"
          @click="deliveryAddressModalButtonClick"
        >
          {{ $t("insurancebridge_prescription_delivery_address.save") }}
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";
import InsuranceBridgeRecipeAddressSelector from "@/components/insurancebridge/InsuranceBridgeRecipeAddressSelector";

export default {
  name: "insurancebridge-recipe-delivery-form",
  components: {
    VBootstrapFormGroup,
    InsuranceBridgeRecipeAddressSelector,
  },
  props: {
    form: Object,
    form_invalid_feedback: Object,
    form_state: Object,
  },
  data() {
    return {
      deliveryAddressShow: false,
      form_valid: undefined,
      show: true,
    };
  },
  computed: {
    pharmacy_pickup() {
      return this.form.pharmacy_pickup;
    },
    delivery_address_state() {
      if (this.pharmacy_pickup) {
        return undefined;
      }
      if (!this.form.delivery_to_home) {
        return undefined;
      }
      if (this.form_valid === false) {
        return false;
      }
      for (const prop in this.form_state.delivery_address) {
        if (this.form_state.delivery_address[prop] === false) {
          return false;
        }
      }
      return undefined;
    },
  },
  watch: {
    pharmacy_pickup: function (newState, oldState) {
      if (newState != oldState && this.form.pharmacy_pickup) {
        this.form.delivery_to_home = false;
      }
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancebridge_prescription_delivery_address: {
          firstname_label: "Firstname",
          firstname_placeholder: "Firstname",
          town_label: "Town",
          town_placeholder: "Town",
          colony_label: "Colony",
          colony_placeholder: "Colony",
          federal_entity_label: "Tax federal entity",
          federal_entity_placeholder: "Tax federal entity",
          phone_label: "Phone",
          phone_placeholder: "Phone",
          mobile_label: "Mobile",
          mobile_placeholder: "Mobile",
          country_label: "Country",
          country_placeholder: "Country",
          address_label: "Address",
          address_placeholder: "Address",
          postal_code_label: "Postal Code",
          postal_code_placeholder: "Postal Code",
          hours_label: "Hours",
          hours_placeholder: "Hours",
          date_label: "Delivery date",
          date_placeholder: "Delivery date",
          date_help_text:
            "The delivery date is subject to the date of bridge by the insurance company. The delivery process is done within 48 or 72 hours after bridge.",
          save: "Save",
          button: "Delivery address",
          send_home: "Send home",
          invelid_delivery_data_feedback: "Please, review delivery information",
          required_field: "This field cannot be blank.",
        },
      },
    },
  },
  mounted: function () {},
  methods: {
    handleOnPhoneInput(val) {
      if (val) {
        this.form_state.delivery_address.phone = undefined;
        this.form_invalid_feedback.delivery_address.phone = "";
      }
    },
    handleOnMobileInput(val) {
      if (val) {
        this.form_state.delivery_address.mobile = undefined;
        this.form_invalid_feedback.delivery_address.mobile = "";
      }
    },
    validateForm() {
      this.form_valid = true;
      const validate_fields = {
        firstname: true,
        town: true,
        colony: true,
        federal_entity: true,
        phone: true,
        mobile: true,
        country: true,
        address: true,
        postal_code: true,
        hours: true,
        date: true,
      };
      for (let item in validate_fields) {
        if (!validate_fields[item]) {
          continue;
        }
        if (!this.form.delivery_address[item]) {
          this.form_valid = false;
          this.$nextTick(() => {
            this.form_state.delivery_address[item] = false;
            this.form_invalid_feedback.delivery_address[item] = this.$t(
              "insurancebridge_prescription_delivery_address.required_field"
            );
          });
        }
      }
      return this.form_valid;
    },
    deliveryAddressModalButtonClick(evt) {
      evt.preventDefault();
      if (this.validateForm()) {
        this.deliveryAddressShow = false;
      }
    },
    onAddressSelected(address) {
      this.show = false;
      const firstname = address.firstname || "";
      const lastname = address.lastname || "";
      this.form.delivery_address.firstname = `${firstname} ${lastname}`.trim();
      this.form.delivery_address.address = address.address;
      this.form.delivery_address.colony = address.colony;
      this.form.delivery_address.town = address.town;
      this.form.delivery_address.federal_entity = address.federal_entity;
      this.form.delivery_address.postal_code = address.postal_code;
      this.form.delivery_address.phone = address.phone;
      this.form.delivery_address.mobile = address.mobile;
      this.form.delivery_address.country = address.country;
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}

.modal-title {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
