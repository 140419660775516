<template>
  <VLayout upline :title="title">
    <div>
      <vue-element-loading :active="loading" />
      <div v-if="show_no_patient_found">
        <b-alert
          variant="warning"
          show
        >{{ $t('insuranceauthorization_patient_selection.create_first_patient_alert') }}</b-alert>
        <div class="buttons-container">
          <b-button
            variant="primary"
            @click="routeToSgmmForm()"
            class="push-right"
          >{{ $t('insuranceauthorization_patient_selection.create_sgmm_button_text') }}</b-button>
        </div>
      </div>
      <NaturalPersonCardList
        class="mt-3"
        v-if="my_patients"
        :items="my_patients"
        v-on:org:click="handlePersonRoute"
        v-on:org:delete="handlePersonDelete"
      />

      <div class="buttons-container mb-5">
        <b-button
          @click="$router.go(-1)"
          variant="secondary"
          class="d-none d-sm-none d-md-block"
        >{{ $t('insuranceauthorization_patient_selection.return') }}</b-button>
      </div>
    </div>

    <DeletePatientConfirm
      ref="delete-patient-confirm"
      @ok="handleDeleteConfirmation"
    />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import NaturalPersonCardList from "@/components/NaturalPersonCardList";
import DeletePatientConfirm from "@/components/DeletePatientConfirm";

import { mapState } from "vuex";

export default {
  props: {
    next_url: String,
  },
  data() {
    return {
      from: null,
      show_no_patient_found: false,
      loading: false,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insuranceauthorization_patient_selection: {
          return: "Back",
          select_patient: "Select patient",
          create_first_patient_alert:
            "In order to create your first address, insert at least one major medical health insurance data",
          create_sgmm_button_text: "Create one",
          error: {
            retrieving_patients: "Cannot load patients",
            delete_patient: "Cannot delete patient",
          },
        },
      },
    },
  },
  computed: {
    ...mapState({
      my_patients: state => state.naturalperson.my_patients,
    }),
    title() {
      if (!this.$route.meta.title) {
        return this.$t(
          "insuranceauthorization_patient_selection.select_patient"
        );
      }
      return this.$t(this.$route.meta.title);
    },
  },
  methods: {
    routeToSgmmForm() {
      this.$router.push({
        name: "patient_customer_data",
      });
    },
    handlePersonRoute: function(item) {
      if (!this.$route.meta.next_route_name) {
        return;
      }
      this.$router.push({
        name: this.$route.meta.next_route_name,
        params: {
          pt_uuid: item.uuid,
        },
      });
    },
    handlePersonDelete: function(item) {
      this.$refs["delete-patient-confirm"].show(item);
    },
    handleDeleteConfirmation: function(patient) {
      this.loading = true;
      this.$store
        .dispatch("naturalperson/DELETE_PATIENT", {
          pt_uuid: patient.uuid,
        })
        .then(
          () => {
            this.loading = false;
          },
          err => {
            this.$sentry({ capture: err });
            this.mnotify_error({
              text: this.$t(
                "insuranceauthorization_patient_selection.error.delete_patient"
              ),
            });
          }
        );
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from;
    });
  },
  mounted: function() {
    this.loading = true;
    this.$store.dispatch("naturalperson/MY_PATIENTS").then(
      () => {
        if (this.my_patients.length == 1) {
          history.replaceState({}, this.from.name, this.from.path);
          this.handlePersonRoute(this.my_patients[0]);
        } else if (this.my_patients.length <= 0) {
          // history.replaceState({}, this.from.name, this.from.path);
          this.show_no_patient_found = true;
        }
        this.loading = false;
      },
      () => {
        this.mnotify_error({
          text: this.$t(
            "insuranceauthorization_patient_selection.error.retrieving_patients"
          ),
        });
        this.loading = false;
      }
    );
  },
  components: {
    VLayout,
    NaturalPersonCardList,
    DeletePatientConfirm,
  },
};
</script>

<style>
</style>
