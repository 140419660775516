<template>
  <div>
    <legend
      class="col-form-label col-form-label-lg font-weight-bold pt-0 mb-3 title"
    >
      {{ $t("insurance_recipe_patient_form.patient_data_title") }}
    </legend>
    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurance_recipe_patient_form.firstname_label')"
      label-for="patient_address-firstname"
      :invalid-feedback="form_invalid_feedback.patient_address.firstname"
      :state="form_state.patient_address.firstname"
    >
      <template v-slot:label>
        {{ $t('insurance_recipe_patient_form.firstname_label') }} <span class="text-danger">*</span>
      </template>
      <b-input-group>
        <b-form-input
          id="patient_address-firstname"
          type="text"
          v-model.trim="form.patient_address.firstname"
          required
          :state="form_state.patient_address.firstname"
          :placeholder="
            $t('insurance_recipe_patient_form.firstname_placeholder')
          "
        ></b-form-input>
        <b-input-group-append>
          <VMajorMedicalInsurancePatientSelector
            v-if="$route.params.i_uuid"
            v-on:patient-selected="onPatientSelected"
            :preset-filters="{manager_uuid: $route.params.i_uuid}"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <VBootstrapFormGroup
      name="patient_address-email"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurance_recipe_patient_form.email_label')"
      :placeholder="$t('insurance_recipe_patient_form.email_placeholder')"
      :invalid-feedback="form_invalid_feedback.patient_address.email"
      :state="form_state.patient_address.email"
      v-model.trim="form.patient_address.email"
    />
    <VBootstrapFormGroup
      name="patient_address-policy_num"
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      required
      :label="$t('insurance_recipe_patient_form.policy_num_label')"
      :placeholder="$t('insurance_recipe_patient_form.policy_num_placeholder')"
      :invalid-feedback="form_invalid_feedback.patient_address.policy_num"
      :state="form_state.patient_address.policy_num"
      v-model.trim="form.patient_address.policy_num"
    />

    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurance_recipe_patient_form.incident_num_label')"
      label-for="patient_address-sinister_num"
      :invalid-feedback="form_invalid_feedback.patient_address.sinister_num"
      :state="form_state.patient_address.sinister_num"
    >
      <b-form-input
        id="patient_address-sinister_num"
        type="text"
        v-model.trim="form.patient_address.sinister_num"
        :state="form_state.patient_address.sinister_num"
        :placeholder="
          $t('insurance_recipe_patient_form.incident_num_placeholder')
        "
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col cols="0" lg="4"> </b-col>
      <b-col cols="12" lg="4">
        <b-form-group
          vertical
          class="text-left"
          :label="$t('insurance_recipe_patient_form.deductible_label')"
          label-for="deductible"
          :invalid-feedback="form_invalid_feedback.insurance.deductible"
          :state="form_state.insurance.deductible"
        >
          <b-input-group>
            <b-form-input
              id="deductible"
              type="text"
              v-model.trim="form.insurance.deductible"
              :state="form_state.insurance.deductible"
              :placeholder="
                $t('insurance_recipe_patient_form.deductible_placeholder')
              "
              v-on:input="emitCoinsuranceDeductibleChange()"
            ></b-form-input>
            <b-dropdown
              size="sm"
              :text="form.insurance.deductible_currency"
              variant="outline-primary-thin"
              slot="append"
            >
              <b-dropdown-item
                @click="
                  toggleDeductibleCurrency('USD');
                  emitCoinsuranceDeductibleChange();
                "
              >
                USD
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  toggleDeductibleCurrency('MXN');
                  emitCoinsuranceDeductibleChange();
                "
              >
                MXN
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="4" class="pl-1">
        <b-form-group
          vertical
          class="text-left pl-2"
          :label="$t('insurance_recipe_patient_form.coinsurance_label')"
          label-for="coinsurance"
          :invalid-feedback="form_invalid_feedback.insurance.coinsurance"
          :state="form_state.insurance.coinsurance"
        >
          <b-input-group>
            <b-form-input
              id="coinsurance"
              type="text"
              v-model.trim="form.insurance.coinsurance"
              :state="form_state.insurance.coinsurance"
              :placeholder="
                $t('insurance_recipe_patient_form.coinsurance_placeholder')
              "
              v-on:input="emitCoinsuranceDeductibleChange()"
            ></b-form-input>
            <b-dropdown
              size="sm"
              :text="form.coinsurance_type_string"
              variant="outline-primary-thin"
              slot="append"
            >
              <b-dropdown-item
                @click="
                  toggleCoinsuranceType('MXN');
                  emitCoinsuranceDeductibleChange();
                "
              >
                MXN
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  toggleCoinsuranceType('USD');
                  emitCoinsuranceDeductibleChange();
                "
              >
                USD
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  toggleCoinsuranceType(0);
                  emitCoinsuranceDeductibleChange();
                "
              >
                %
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <InsuranceAuthorizationRecipeDeliveryForm
      :form="form"
      :form_state="form_state"
      :form_invalid_feedback="form_invalid_feedback"
    />

    <b-form-group v-if="allow_pdv_pickup" class="text-left">
      <b-form-checkbox v-model="form.pharmacy_pickup">
        {{ $t("insurance_recipe_patient_form.pick_up") }}
      </b-form-checkbox>
    </b-form-group>

    <!--
    <b-form-group
      label-cols="12"
      label-cols-sm="12"
      label-cols-md="12"
      label-cols-lg="4"
      label-cols-xl="4"
      class="text-left"
      :label="$t('insurance_recipe_patient_form.auth_num_label')"
      label-for="auth_num"
      :invalid-feedback="form_invalid_feedback.auth_num"
      :state="form_state.auth_num"
    >
      <b-form-input
        id="auth_num"
        type="text"
        v-model.trim="form.auth_num"
        :state="form_state.auth_num"
        :placeholder="$t('insurance_recipe_patient_form.auth_num_placeholder')"
      ></b-form-input>
    </b-form-group>
    -->
  </div>
</template>

<script>
import InsuranceAuthorizationRecipeDeliveryForm from "@/components/insuranceauthorization/InsuranceAuthorizationRecipeDeliveryForm";
import VMajorMedicalInsurancePatientSelector from "@/components/VMajorMedicalInsurancePatientSelector";
import VBootstrapFormGroup from "@/components/VBootstrapFormGroup";

import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "insuranceauthorization-recipe-patient-form-a",
  components: {
    InsuranceAuthorizationRecipeDeliveryForm,
    VMajorMedicalInsurancePatientSelector,
    VBootstrapFormGroup,
  },
  props: {
    form: Object,
    form_invalid_feedback: Object,
    form_state: Object,
  },
  data() {
    return {
      deliveryAddressShow: false,
      allow_pdv_pickup: undefined,
    };
  },
  computed: {
    ...mapGetters("organization", ["getInsuranceConfiguration"]),
    ...mapState({
      is_insurance_authorization_list_loaded: state =>
        state.organization.is_insurance_authorization_list_loaded,
    }),
    delivery_to_home() {
      return this.form.delivery_to_home;
    },
  },
  watch: {
    delivery_to_home: function(newState, oldState) {
      if (newState != oldState && this.form.delivery_to_home) {
        this.form.pharmacy_pickup = false;
      }
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurance_recipe_patient_form: {
          firstname_label: "Patient name",
          firstname_placeholder: "Patient name",
          email_label: "Email",
          email_placeholder: "Email",
          policy_num_label: "Policy number",
          policy_num_placeholder: "Policy number",
          incident_num_label: "Incident number",
          incident_num_placeholder: "Incident number",
          folio_num_label: "Folio number",
          folio_num_placeholder: "Folio number",
          recipe_num_label: "Recipe number",
          recipe_num_placeholder: "Recipe number",
          // auth_num_label: "Auth number",
          // auth_num_placeholder: "Auth number",
          recipe_data_title: "Recipe data",
          patient_data_title: "Patient data",
          deductible_label: "Deductible",
          deductible_placeholder: "",
          coinsurance_label: "Coinsurance",
          coinsurance_placeholder: "",
          pick_up: "Pick up in-pharmacy",
        },
      },
    },
  },
  mounted: function() {
    this.handleAllowPdvPickup();
  },
  methods: {
    ...mapActions({
      INSURANCE_AUTHORIZATION_LIST: "organization/INSURANCE_AUTHORIZATION_LIST",
    }),
    _setAllowPdvPickup() {
      var conf = this.getInsuranceConfiguration(this.$route.params.i_uuid);
      if (!conf) {
        this.allow_pdv_pickup = false;
        return;
      }

      if (conf.allow_pdv_pickup) {
        this.allow_pdv_pickup = true;
        return;
      }
      this.allow_pdv_pickup = false;
    },
    handleAllowPdvPickup() {
      if (!this.is_insurance_authorization_list_loaded) {
        this.INSURANCE_AUTHORIZATION_LIST().then(() => {
          this._setAllowPdvPickup();
        });
      } else {
        this._setAllowPdvPickup();
      }
    },
    toggleDeductibleCurrency(currency) {
      this.form.insurance.deductible_currency = currency;
    },
    toggleCoinsuranceType(type) {
      //this.form.insurance.coinsurance_type = type;
      if (type == 0) {
        this.form.coinsurance_type_string = "%";
        this.form.insurance.coinsurance_currency = undefined;
        this.form.insurance.coinsurance_type = 0;
      } else {
        this.form.coinsurance_type_string = type;
        this.form.insurance.coinsurance_currency = type;
        this.form.insurance.coinsurance_type = 1;
      }
    },
    emitCoinsuranceDeductibleChange() {
      this.$emit("insurance-costs-change");
    },
    onPatientSelected(patient) {
      const firstname = patient.firstname || "";
      const lastname = patient.lastname || "";
      this.form.patient_address.firstname = `${firstname} ${lastname}`.trim();
      this.form.patient_address.email = patient.email;
      this.form.patient_address.policy_num = patient.policy_num;
      this.form.insurance.deductible = this.$formatInternal(patient.deductible);
      this.toggleDeductibleCurrency(patient.deductible_currency);
      this.form.insurance.coinsurance = this.$formatInternal(
        patient.coinsurance
      );
      if (patient.coinsurance_type === 0) {
        this.toggleCoinsuranceType(0);
      } else {
        this.toggleCoinsuranceType(patient.coinsurance_currency);
      }
      this.emitCoinsuranceDeductibleChange();
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}

.title {
  text-transform: uppercase;
}
</style>
