<template>
  <div>
    <b-modal
      size="xl"
      modal-class="modal-square-theme"
      v-model="modal_show"
      centered
      :title="$t('insuranceriskpayment_confirm_feedback.title')"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12" class="d-flex align-items-center">
            <div>
              <component class="spech_ico mr-3" v-bind:is="spech_icon" alt="Info" />
            </div>
            <div
              v-if="!feedback_payment_pending"
              class="align-bottom font-italic"
            >
              {{ $t('insuranceriskpayment_confirm_feedback.confirm_text') }}
            </div>
            <div
              v-else
              class="align-bottom font-italic"
            >
              {{ $t('insuranceriskpayment_confirm_feedback.payment_pending_confirm_text') }}
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-button size="sm" class="float-right" variant="primary" @click="modal_show=false">{{ $t('insuranceriskpayment_confirm_feedback.close_button') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SpeechBubbleIcon from "@/static/ico/speech-bubble-with-letter-i.svg";
import { mapGetters } from "vuex";

export default {
  name: "insurance-risk-payment-confirm-feedback",
  props: {
    feedback_payment_pending: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      modal_show: true,
      recipes: undefined,
      insuranceauthorization: undefined,
      spech_icon: SpeechBubbleIcon,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insuranceriskpayment_confirm_feedback: {
          title: "Payment made correctly",
          close_button: "Close",
          confirm_text:
            "The deductible and/or coinsurance payment will be sent to the insurer. You will receive an email when the payment has been processed.",
          payment_pending_confirm_text:
            "The deductible and/or coinsurance payment intention has sent to the insurer. Once you made the payment, you will receive an email when the payment has been processed.",
        },
      },
    },
  },
  computed: {
    ...mapGetters("insuranceauthorization", [
      "getFeedbackInsuranceAuthorization",
    ]),
  },
  mounted: function() {
    this.showFeedbackRefund();
  },
  methods: {
    showFeedbackRefund() {
      if (!this.getFeedbackInsuranceAuthorization) {
        return;
      }
      this.modal_show = true;
      this.insuranceauthorization = Object.assign(
        {},
        this.getFeedbackInsuranceAuthorization
      );
      this.$store.commit(
        "insuranceauthorization/MUTATE_FEEDBACK_INSURANCEAUTHORIZATION",
        undefined
      );
      if (this.insuranceauthorization.recipe) {
        this.recipes = [this.insuranceauthorization.recipe];
      }
    },
  },
  components: {
    SpeechBubbleIcon,
  },
};
</script>

<style lang="scss" scoped>
.spech_ico {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 4rem;
  height: 3rem;
  color: $corp-color1;
  fill: $corp-color1;
  stroke: $white;
}
</style>
