import {
  drugtransaction_insurance_insurancerefund_create,
  drugtransaction_insurance_insurancerefund_read,
  drugtransaction_insurance_insurancerefund_draft_read,
  drugtransaction_insurancerefund_draft_list,
  drugtransaction_insurance_insurancerefund_partial_update,
  drugtransaction_insurance_insurancerefund_list,
  drugtransaction_insurancerefund_list,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    draft_insurancerefunds: [],
    active_insurancerefund: undefined,
    feedback_insurancerefund: undefined,
  },
  actions: {
    CREATE_PATIENT_INSURANCE_DRAFT_INSURANCEREFUND: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_create({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          data: payload.data,
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEREFUND", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    REQUEST_PATIENT_INSURANCE_INSURANCEREFUND_LIST: (undefined, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          offset: offset,
          limit: payload.per_page,
          search: payload.search || undefined,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    // Termina un reembolso poniéndolo en object_status done (2)
    REST_DONE_DRAFT_INSURANCEREFUND: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_partial_update({
          $config: axconf(),
          role: "couldbepatient",
          roleUuid: "1",
          data: { object_status: 2 },
          iUuid: payload.i_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEREFUND", response.data);
            commit("MUTATE_FEEDBACK_INSURANCEREFUND", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    // Actualiza un reembolso
    REST_UPDATE_DRAFT_INSURANCEREFUND: (undefined, payload) => {
      const promise = drugtransaction_insurance_insurancerefund_partial_update({
        $config: axconf(),
        role: "couldbepatient",
        roleUuid: "1",
        data: payload.data,
        iUuid: payload.i_uuid,
        insurancerefundUuid: payload.insurancerefund_uuid,
      });
      return promise;
    },
    REST_INSURANCE_INSURANCEREFUND_READ: (undefined, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_read({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_INSURANCE_DRAFT_INSURANCEREFUND: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurance_insurancerefund_draft_read({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
        }).then(
          response => {
            commit("MUTATE_DRAFT_INSURANCEREFUND", response.data);
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_ALL_INSURANCE_DRAFT_INSURANCEREFUND: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        drugtransaction_insurancerefund_draft_list({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
        }).then(
          response => {
            commit("MUTATE_ALL_DRAFT_INSURANCEREFUND", {
              data: response.data.results,
              i_uuid: payload.i_uuid,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_ALL_INSURANCEREFUND: ({ commit }, payload) => {
      const offset = payload.per_page * (payload.current_page - 1);
      return new Promise((resolve, reject) => {
        drugtransaction_insurancerefund_list({
          $config: axconf(),
          role: "guest",
          ptUuid: payload.pt_uuid,
          roleUuid: "1",
          offset: offset,
          limit: payload.per_page,
          ...payload,
        }).then(
          response => {
            commit("MUTATE_ALL_INSURANCEREFUND", {
              data: response.data.results,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_FEEDBACK_INSURANCEREFUND: (state, payload) => {
      Vue.set(state, "feedback_insurancerefund", payload);
    },
    MUTATE_DRAFT_INSURANCEREFUND: (state, payload) => {
      let draft_insurancerefunds = state.draft_insurancerefunds;
      let new_draft_insurancerefunds = [];
      let found_in_state = false;

      // recorremos los draft insurance refund actuales en state
      draft_insurancerefunds.forEach(function(draft_insurancerefund) {
        // si el payload ya se encuentra en state
        if (payload.uuid == draft_insurancerefund.uuid) {
          // marcamos banderita de encontrado
          found_in_state = true;
          // y lo añadimos si payload es draft, de lo contrario se trata
          // de un insurancerefund que ha cambiado de draft a done y por
          //tanto no lo queremos
          if (payload.object_status == 1) {
            new_draft_insurancerefunds.push(payload);
          }
          return;
        }
        // mantenemos los reembolsos que no son payload tal y como estaban
        new_draft_insurancerefunds.push(draft_insurancerefund);
      });

      // si payload está en estado draft y no ha sido encontrado en
      // state, lo añadimos (es un draft nuevo)
      if (payload.object_status == 1 && !found_in_state) {
        new_draft_insurancerefunds.push(payload);
      }

      // ponemos el insurancerefund activo a indefinido
      Vue.set(state, "active_insurancerefund", undefined);
      // si el payload está en modo draft, lo añadimos a activo, de lo contrario
      // dejamos el active_insurancerefund vacío
      if (payload.object_status == 1) {
        state.active_insurancerefund = Object.assign({}, payload);
        // Vue.set(state, "active_insurancerefund", payload);
      }

      // actualizamos state con los draft correctos
      Vue.set(state, "draft_insurancerefunds", new_draft_insurancerefunds);
    },
    MUTATE_ALL_DRAFT_INSURANCEREFUND: (state, payload) => {
      Vue.set(state, "draft_insurancerefunds", payload.data);
    },
    MUTATE_ALL_INSURANCEREFUND: (state, payload) => {
      Vue.set(state, "insurancerefunds", payload.data);
    },
  },
  getters: {
    getDraftInsuranceRefundByUUID: state => (i_uuid, insurancerefund_uuid) => {
      let draft_insurancerefunds = state.draft_insurancerefunds;
      let found = undefined;
      draft_insurancerefunds.forEach(function(insurancerefund) {
        if (!insurancerefund.insurance_organization) {
          return;
        }
        if (
          insurancerefund.insurance_organization.uuid == i_uuid &&
          insurancerefund.uuid == insurancerefund_uuid
        ) {
          found = insurancerefund;
        }
      });
      return found;
    },
    getFeedbackInsuranceRefund: state => {
      return state.feedback_insurancerefund;
    },
  },
};
