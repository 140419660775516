// import Vue from 'vue'
// import Vuex from 'vuex'
//
// Vue.use(Vuex)
//
// const state = {
//   count: 0
// }
//
// const mutations = {
//   INCREMENT (state) {
//     state.count++
//   },
//   DECREMENT (state) {
//     state.count--
//   }
// }
//
// const actions = {
//   incrementAsync ({ commit }) {
//     setTimeout(() => {
//       commit('INCREMENT')
//     }, 200)
//   }
// }
//
// const store = new Vuex.Store({
//   state,
//   mutations,
//   actions
// })
//
// export default store

import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import organization from "./modules/organization";
import siteconf from "./modules/siteconf";
import i18nmap from "./modules/i18nmap";
import recipe from "./modules/recipe";
import refundinvoice from "./modules/refundinvoice";
import drugs from "./modules/drugs";
import naturalperson from "./modules/naturalperson";
import insuranceauthorization from "./modules/insuranceauthorization";
import insurancebridge from "./modules/insurancebridge";
import insurancerefund from "./modules/insurancerefund";
import drugtransaction from "./modules/drugtransaction";
import insuranceauthorizationfiles from "./modules/insuranceauthorizationfiles";
import insurancebridgefiles from "./modules/insurancebridgefiles";
import insurancerefundfiles from "./modules/insurancerefundfiles";
import routehelper from "./modules/routehelper";
import rates from "./modules/rates";
import appstatus from "./modules/appstatus";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    siteconf,
    organization,
    i18nmap,
    recipe,
    refundinvoice,
    drugs,
    naturalperson,
    insuranceauthorization,
    insurancebridge,
    insurancerefund,
    drugtransaction,
    insuranceauthorizationfiles,
    insurancebridgefiles,
    routehelper,
    rates,
    appstatus,
    insurancerefundfiles,
  },
});
