import { convert_money, Money } from "@/lib/money.js";

export const AMOUNT_TYPE_SUBTOTAL = 0;
// Tasas de productos
export const AMOUNT_TYPE_SUBTOTAL_TAX = 1;
export const AMOUNT_TYPE_DEDUCTIBLE = 2;
export const AMOUNT_TYPE_COINSURANCE = 3;
// Impuestos de manipulación
// export const AMOUNT_TYPE_HANDLING_FEE = 4;
// export const AMOUNT_TYPE_SHIPPING = 5;
// export const AMOUNT_TYPE_SHIPPING_DISCOUNT = 6;
// // Impuestos de aduana
// export const AMOUNT_TYPE_CUSTOM_FEE = 7;

export const COINSURANCE_TYPE_PERCENT = 0;
export const COINSURANCE_TYPE_STATIC = 1;

export const Amount = function(amount, preferred_currency = "MXN") {
  if (!(amount.total instanceof Money) && amount.total_currency) {
    this.total = new Money(amount.total, amount.total_currency);
  } else {
    this.total = amount.total;
  }

  if (!(amount.buyer_total instanceof Money) && amount.buyer_total_currency) {
    this.buyer_total = new Money(
      amount.buyer_total,
      amount.buyer_total_currency
    );
  } else {
    this.buyer_total = amount.buyer_total;
  }

  if (
    !(amount.provider_total instanceof Money) &&
    amount.provider_total_currency
  ) {
    this.provider_total = new Money(
      amount.provider_total,
      amount.provider_total_currency
    );
  } else {
    this.provider_total = amount.provider_total;
  }

  this.details = amount.details;
  this.preferred_currency = preferred_currency;
};

Amount.prototype.getDetailByType = function(type) {
  let found = undefined;
  this.details.forEach(detail => {
    if (detail.amount_type == type) {
      found = new Money(detail.value, detail.value_currency);
    }
  });
  return found;
};

Amount.prototype.getAllDetailByType = function(type) {
  let found = [];
  this.details.forEach(detail => {
    if (detail.amount_type == type) {
      found.push(new Money(detail.value, detail.value_currency));
    }
  });
  return found;
};

export const buildAmount = function(
  products,
  insurance,
  final_currency,
  spread_risk
) {
  // si no se indica una moneda final para los calculos totales,
  // buscamos una moneda de entre los productos añadidos
  if (!final_currency) {
    let found_currencies = [];
    products.forEach(element => {
      if (!element.price) {
        return;
      }
      let price = element.price;
      if (!price.no_tax_price_currency) {
        return;
      }
      found_currencies.push(price.no_tax_price_currency);
      // final_currency = price.no_tax_price_currency;
    });

    if (found_currencies.length <= 0) {
      final_currency = "MXN";
    } else if (found_currencies.indexOf("MXN") >= 0) {
      final_currency = "MXN";
    } else if (found_currencies.indexOf("USD") >= 0) {
      final_currency = "USD";
    } else {
      final_currency = "MXN";
    }
  }

  // objeto amount para ser pasado a Amount() como parámetro al constructor
  // será rellenado en el codigo más abajo
  var amount = {
    total: new Money(0, final_currency),
    buyer_total: new Money(0, final_currency),
    provider_total: new Money(0, final_currency),
  };

  var details = [];
  // BUILDING SUBTOTALS
  let _subtotals = buildSubtotals(products, final_currency);

  // BUILDING COINSURANCE AND DEDUCTIBLE
  let _insurance_costs = buildInsuranceCost(_subtotals, insurance);

  // BUILDING DETAILS
  for (let idx in _subtotals.subtotals) {
    details.push(_subtotals.subtotals[idx]);
  }
  for (let idx in _subtotals.subtotals_tax) {
    details.push(_subtotals.subtotals_tax[idx]);
  }
  if (_insurance_costs) {
    details.push({
      amount_type: AMOUNT_TYPE_COINSURANCE,
      value: _insurance_costs.coinsurance,
      value_currency: _insurance_costs.currency,
    });
    details.push({
      amount_type: AMOUNT_TYPE_DEDUCTIBLE,
      value: _insurance_costs.deductible,
      value_currency: _insurance_costs.currency,
    });
  }

  // BUILDING TOTALS
  amount.details = details;
  amount = buildTotals(amount, final_currency, spread_risk);
  return new Amount(amount, final_currency);
};

export const buildTotals = (amount, final_currency, spread_risk = false) => {
  let _subtotal = new Money(0, final_currency);
  let _subtotal_tax = new Money(0, final_currency);
  let _deductible_and_coinsurance = new Money(0, final_currency);
  let _anything_else = new Money(0, final_currency);
  let details = amount.details;

  details.forEach(item => {
    if (item.amount_type == AMOUNT_TYPE_SUBTOTAL) {
      _subtotal.add(new Money(item.value, item.value_currency));
      // _subtotal += item.value;
      return;
    }
    if (item.amount_type == AMOUNT_TYPE_SUBTOTAL_TAX) {
      _subtotal_tax.add(new Money(item.value, item.value_currency));
      // _subtotal_tax += item.value;
      return;
    }
    if (
      item.amount_type == AMOUNT_TYPE_DEDUCTIBLE ||
      item.amount_type == AMOUNT_TYPE_COINSURANCE
    ) {
      _deductible_and_coinsurance.add(
        new Money(item.value, item.value_currency)
      );
      // _deductible_and_coinsurance += item.value;
      return;
    }
    _anything_else.add(new Money(item.value, item.value_currency));
    // _anything_else = item.value;
  });

  if (spread_risk) {
    if (_deductible_and_coinsurance > 0) {
      amount.buyer_total.add(_deductible_and_coinsurance);
      amount.buyer_total.add(_anything_else);
      //amount.buyer_total = _deductible_and_coinsurance + _anything_else;
      // amount.provider_total =
      //   _subtotal - _deductible_and_coinsurance + _subtotal_tax;
      amount.provider_total.add(_subtotal_tax);

      amount.provider_total.add(
        new Money(_subtotal - _deductible_and_coinsurance, _subtotal.currency)
      );
    } else {
      //amount.buyer_total = _subtotal + _subtotal_tax + _anything_else;
      amount.provider_total.add(_subtotal);
      amount.provider_total.add(_subtotal_tax);
      amount.provider_total.add(_anything_else);
    }
  } else {
    amount.buyer_total.add(_subtotal);
    amount.buyer_total.add(_subtotal_tax);
    amount.buyer_total.add(_anything_else);
  }
  // amount.total = amount.buyer_total + amount.provider_total;
  amount.total.add(amount.buyer_total);
  amount.total.add(amount.provider_total);

  amount.details = details;
  return amount;
};

export const buildInsuranceCost = (subtotals, _insurance) => {
  var subtotal = subtotals.subtotal;
  var currency = subtotals.subtotal.currency;
  var deductible_to_pay = 0;
  var coinsurance_to_pay = 0;

  var insurance = Object.assign({}, _insurance);

  if (insurance.coinsurance) {
    insurance.coinsurance = parseFloat(insurance.coinsurance);
  }
  if (insurance.deductible) {
    insurance.deductible = parseFloat(insurance.deductible);
  }

  if (insurance.deductible && insurance.deductible > 0) {
    // convertimos la moneda del deducible a la moneda del subtotal
    insurance.deductible = convert_money(
      new Money(insurance.deductible, insurance.deductible_currency),
      currency
    );

    // si el subtotal es 0, no se aplica deducible
    if (subtotal == 0) {
      deductible_to_pay = 0;
      // si el deducible es mayor que el subtotal, el deducible a pagar
      // es el subtotal al completo
    } else if (insurance.deductible > subtotal) {
      deductible_to_pay = subtotal;
      // si el subtotal es mayor que el deducible, se paga el deducible
      // por completo
    } else if (insurance.deductible < subtotal) {
      deductible_to_pay = insurance.deductible;
    }
  }

  // coaseguro de tipo fijo, moneda
  if (
    insurance.coinsurance &&
    insurance.coinsurance > 0 &&
    insurance.coinsurance_type == COINSURANCE_TYPE_STATIC &&
    insurance.coinsurance_currency
  ) {
    // convertimos la moneda del coaseguro a la moneda del subtotal
    insurance.coinsurance = convert_money(
      new Money(insurance.coinsurance, insurance.coinsurance_currency),
      currency
    );

    // debemos aplicar el coaseguro sobre el subtotal menos el deducible
    let deductible_subtotal = subtotal - deductible_to_pay;
    if (deductible_subtotal == 0) {
      coinsurance_to_pay = 0;
      // si el coaseguro es mayor que el subtotal deducido, el coaseguro
      // es el subtotal deducido al completo
    } else if (deductible_subtotal < insurance.coinsurance) {
      coinsurance_to_pay = deductible_subtotal;
      // si el coaseguro a pagar es menor que el subtotal deducido,
      // el coaseguro es la cantidad de coaseguro establecida
    } else if (deductible_subtotal > insurance.coinsurance) {
      coinsurance_to_pay = insurance.coinsurance.amount;
    }
  }

  // coaseguro de tipo porcentaje
  if (
    insurance.coinsurance &&
    insurance.coinsurance > 0 &&
    insurance.coinsurance_type == COINSURANCE_TYPE_PERCENT
  ) {
    let deductible_subtotal = subtotal - deductible_to_pay;

    if (deductible_subtotal == 0) {
      coinsurance_to_pay = 0;
    } else {
      coinsurance_to_pay =
        (deductible_subtotal * insurance.coinsurance) / 100.0;
    }
  }

  return {
    coinsurance: coinsurance_to_pay,
    deductible: deductible_to_pay,
    currency: currency,
  };
};

export const buildSubtotals = (products, final_currency) => {
  var subtotals = {};

  var subtotal = new Money(0, final_currency);
  // var subtotal_currency = "USD";

  var subtotals_tax = {};
  var currencies_count = 0;

  products.forEach(element => {
    if (!element.price) {
      return;
    }
    let price = element.price;
    let quantity = element.quantity;

    let currency = price.no_tax_price_currency;
    // price with tax
    let all_included_price = parseFloat(price.all_included_price) * quantity;
    // price without tax
    let no_tax_price = parseFloat(price.no_tax_price) * quantity;
    let tax = all_included_price - no_tax_price;

    if (!subtotals[currency]) {
      currencies_count++;
      subtotals[currency] = {
        amount_type: AMOUNT_TYPE_SUBTOTAL,
        value: 0,
        value_currency: currency,
      };
    }
    subtotals[currency].value += no_tax_price;

    if (!subtotals_tax[currency]) {
      subtotals_tax[currency] = {
        amount_type: AMOUNT_TYPE_SUBTOTAL_TAX,
        value: 0,
        value_currency: currency,
      };
    }
    subtotals_tax[currency].value += tax;

    subtotal.add(new Money(no_tax_price, currency));
  });

  return {
    subtotals: subtotals,
    subtotals_tax: subtotals_tax,
    currencies_count: currencies_count,
    subtotal: subtotal,
    subtotal_currency: subtotal.currency,
  };
};
