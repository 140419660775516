import {
  drugtransaction_patient_insurance_insuranceauthorization_file_list,
  drugtransaction_patient_insurance_insuranceauthorization_file_create,
  drugtransaction_patient_insurance_insuranceauthorization_file_delete,
  drugtransaction_nopatient_insurance_insuranceauthorization_file_list,
  drugtransaction_nopatient_insurance_insuranceauthorization_file_create,
  drugtransaction_nopatient_insurance_insuranceauthorization_file_delete,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    insuranceauthorization_files: [],
  },
  actions: {
    CREATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_FILE: (
      undefined,
      payload
    ) => {
      let extraconf = {};
      if (payload.onUploadProgress) {
        extraconf["onUploadProgress"] = payload.onUploadProgress;
      }
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_file_create
          : drugtransaction_nopatient_insurance_insuranceauthorization_file_create;
        functioncall({
          $config: axconf(extraconf),
          role: "guest",
          roleUuid: "1",
          data: payload.data,
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
        }).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LOAD_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_FILES: (
      { commit },
      payload
    ) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_file_list
          : drugtransaction_nopatient_insurance_insuranceauthorization_file_list;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
        }).then(
          response => {
            commit("MUTATE_INSURANCEAUTHORIZATION_FILES", {
              data: response.data.results,
            });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    DELETE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_FILE: (
      undefined,
      payload
    ) => {
      return new Promise((resolve, reject) => {
        const functioncall = payload.pt_uuid
          ? drugtransaction_patient_insurance_insuranceauthorization_file_delete
          : drugtransaction_nopatient_insurance_insuranceauthorization_file_delete;
        functioncall({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          ptUuid: payload.pt_uuid,
          iUuid: payload.i_uuid,
          insuranceauthorizationUuid: payload.insuranceauthorization_uuid,
          refUuid: payload.ref_uuid,
        }).then(
          response => {
            // commit('MUTATE_INSURANCEAUTHORIZATION_FILES', {
            //   data: response.data.results
            // });
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
    },
  },
  mutations: {
    MUTATE_INSURANCEAUTHORIZATION_FILES: (state, payload) => {
      Vue.set(state, "insuranceauthorization_files", payload.data);
    },
  },
  getters: {},
};
