<template>
  <v-layout>
    <b-alert show variant="danger">
      <h4 class="alert-heading">{{ $t('fallback_error_page.error_title') }}</h4>
      <p>
        {{ $t('fallback_error_page.error_body') }}
      </p>
      <hr />
      <p class="mb-0 d-flex justify-content-between">

        <b-button size="lg" variant="primary" v-on:click="$router.go(-1)">
          {{ $t('fallback_error_page.retry_button') }}
        </b-button>

        <b-button size="lg" class="pull-right" variant="primary" v-on:click="$router.push('/')">
          {{ $t('fallback_error_page.reload_app_button') }}
        </b-button>

      </p>
    </b-alert>
  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";

export default {
  fallbackI18n: {
    messages: {
      und: {
        fallback_error_page: {
          error_title: "Unexpected error",
          error_body:
            "An unexpected error occurred. Don't worry, we're already working on it!",
          reload_app_button: "Reload app",
          retry_button: "Retry",
        },
      },
    },
  },
  components: {
    VLayout,
  },
};
</script>

<style>
</style>
