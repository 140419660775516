<template>
  <div>
    <DefaultLayout>
      <VFirstLoginRefundGuidePopUp v-if="allow_insurance_refund_guide" />
      <template slot="navigation">
        <InsuranceRefundNavigation />
      </template>
      <vue-element-loading :active="!show" :is-full-screen="true" />
      <div v-if="show" class="mb-4">
        <OrganizationCardList
          :items="insurance_refund_list"
          v-on:org:click="onOrgClick"
        />
      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import InsuranceRefundNavigation from "@/components/insurancerefund/InsuranceRefundNavigation";
import OrganizationCardList from "@/components/OrganizationCardList";
import VFirstLoginRefundGuidePopUp from "@/components/VFirstLoginRefundGuidePopUp";

import { mapState } from "vuex";

export default {
  data() {
    return {
      show: false,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancerefund_insurance_selection: {
          error: {
            retrieving_insurances: "Cannot load insurances",
          },
        },
      },
    },
  },
  computed: {
    ...mapState({
      // TODO: Separar este listado entre autorizaciones y reembolso
      // el backend debería soportar el filtrado según aseguradora
      insurance_refund_list: (state) =>
        state.organization.insurance_refund_list,
      allow_insurance_refund_guide: (state) =>
        state.organization.allow_insurance_refund_guide,
    }),
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.refund_insurance_selection",
          to: { name: "nopatient_insurancerefund_insurance_selection" },
        },
      ]);
    },
    onOrgClick: function (item) {
      // Route to new insurancerefund
      this.$router.push({
        name: "nopatient_insurancerefund_home_with_promo",
        params: {
          pt_uuid: this.$route.params.pt_uuid,
          i_uuid: item.uuid,
          insurancerefund_uuid: "new",
        },
      });
    },
  },
  mounted: function () {
    this.putBreadCrumb();
    this.$store.dispatch("organization/INSURANCE_REFUND_LIST").then(
      () => {
        this.show = true;
      },
      () => {
        this.mnotify_error({
          text: this.$t(
            "insurancerefund_insurance_selection.error.retrieving_insurances"
          ),
        });
      }
    );
  },
  components: {
    DefaultLayout,
    InsuranceRefundNavigation,
    OrganizationCardList,
    VFirstLoginRefundGuidePopUp,
  },
};
</script>

<style></style>
