var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading_insuranceauthorizations}}),_c('VTableToolbarFilter',{attrs:{"is_filtered":_vm.is_filtered,"filters_count":_vm.filters_count},on:{"showFilters":function($event){_vm.showFilters=!_vm.showFilters},"clearFilters":_vm.clearFilters,"applyFilter":_vm.applyFilter}}),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selected_drug),expression:"!selected_drug"}],ref:"table_list",attrs:{"id":"insuranceauthorization-table-list","responsive":"","outlined":"","head-variant":"gray","stacked":"md","busy":_vm.isBusy,"items":_vm.tableProvider,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top-row",fn:function(ref){
var fields = ref.fields;
return (_vm.showFilters)?_vm._l((fields),function(field){return _c('td',{key:field.key},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.applyFilter($event)}}},[(_vm.filters && _vm.filters[field.key])?_c('VBootstrapFormGroup',{attrs:{"type":_vm.filters[field.key].type ? _vm.filters[field.key].type : 'input',"label-sr-only":"","stack-form-group":"","name":field.key,"options":_vm.filters[field.key].options,"placeholder":field.label},on:{"input":function($event){if (_vm.filters[field.key].type == 'select') {_vm.applyFilter();}}},model:{value:(_vm.filters[field.key]['model']),callback:function ($$v) {_vm.$set(_vm.filters[field.key], 'model', (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filters[field.key]['model']"}}):_vm._e()],1)],1)}):undefined}},{key:"cell(insurance)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.public_name)+" ")]}},{key:"cell(actions)",fn:function(data){return [(data.item.object_status == 1)?_c('b-button',{attrs:{"size":"sm","variant":"secondary-b"},on:{"click":function($event){return _vm.routeToInsuranceAuthorization(data.item)}}},[_vm._v(" "+_vm._s(_vm.$t("insuranceauthorization_table_list.continue_refund_button"))+" ")]):_vm._e(),(data.item.object_status == 2)?_c('b-button',{attrs:{"size":"sm","variant":"secondary-b"},on:{"click":function($event){return _vm.routeToDetails(data.item)}}},[_vm._v(" "+_vm._s(_vm.$t("insuranceauthorization_table_list.details_refund_button"))+" ")]):_vm._e()]}},{key:"cell(patient)",fn:function(data){return [(data.item.recipe && data.item.recipe.patient_address)?_c('div',[_vm._v(" "+_vm._s(data.item.recipe.patient_address.firstname)+" ")]):_vm._e()]}},{key:"cell(drugs)",fn:function(data){return [(
          data.item.recipe &&
            data.item.recipe.drugs &&
            data.item.recipe.drugs.length > 0
        )?_c('div',{staticClass:"btn",attrs:{"id":'insuranceauthorization-recipe-popover' + data.item.uuid}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1):_vm._e(),(
          data.item.recipe &&
            data.item.recipe.drugs &&
            data.item.recipe.drugs.length > 0
        )?_c('b-popover',{attrs:{"triggers":"hover focus click","target":'insuranceauthorization-recipe-popover' + data.item.uuid,"placement":'auto'}},_vm._l((data.item.recipe.drugs),function(drug,index){return _c('div',{key:index},[_c('small',[_vm._v(_vm._s(drug.quantity)+" x")]),_vm._v(" "+_vm._s(drug.default_description)+" ")])}),0):_vm._e()]}}],null,true)}),(_vm.show_pagination)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-pagination',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selected_drug),expression:"!selected_drug"}],staticClass:"float-md-right",attrs:{"align":"center","total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }