/****************************************
  VueBootstrapUtilForm
  vue-bootstrap-utilform.js
  (C) Develatio Technologies S.L. 2018
*****************************************

Usage example:

1) Put this in your component http post method
$yourRextFunction('url/to/request', {data: data}).then((response) => {
  // success stuff
}, (err) => {
  this.putFormFeedback(err.response);
});



2) Define form_invalid_feedback and form_state in your component data section:
data () {
  return {
    form: {
      email: '',
      password: '',
      checked: []
    },
    form_invalid_feedback: {},
    form_state: {},
  }
},



3) Configure your inputs to reference form_invalid_feedback and form_state:
<b-form-group id="group1"
              :label="$t('Email')"
              label-for="mailinput"
              :description="$t('We\'ll never share your email with anyone else.')"
              :invalid-feedback="form_invalid_feedback.email"
              :state="form_state.email">
  <b-form-input id="mailinput"
                type="text"
                v-model.trim="form.email"
                required
                :state="form_state.email"
                :placeholder="$t('Enter email')">
  </b-form-input>
</b-form-group>

****************************************/

const VueBootstrapUtilForm = {
  // eslint-disable-next-line
  install(Vue, options) {
    Vue.mixin({
      methods: {
        // clean feedback messages in form_invalid_feedback
        _cleanFormFeedback(data) {
          for (var key in data) {
            if (typeof data[key] == "object") {
              this._cleanFormFeedback(data[key]);
              continue;
            }
            data[key] = "";
            //this.form_state[key] = true;
          }
        },
        cleanFormFeedback(config) {
          this._cleanFormFeedback(this[config.feedbackattr]);
          // this._cleanFormFeedback(this.form_invalid_feedback);
        },
        _cleanFormState(data) {
          for (var key in data) {
            if (typeof data[key] == "object") {
              this._cleanFormState(data[key]);
              continue;
            }
            data[key] = true;
          }
        },
        cleanFormState(config) {
          this._cleanFormState(this[config.stateattr]);
        },
        _isObject(item) {
          return item && typeof item === "object" && !Array.isArray(item);
        },
        _isArray(item) {
          return item && Array.isArray(item);
        },
        /**
         * Deep merge two objects. If one of keys inside objects are array,
         * this will not be merged but extract first subkey (0) and merge it
         * instead the complete array.
         * @param target
         * @param ...sources
         */
        _mergeErrMsgDeep(target, ...sources) {
          if (!sources.length) return target;
          const source = sources.shift();
          if (this._isObject(target) && this._isObject(source)) {
            for (const key in source) {
              if (this._isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                this._mergeErrMsgDeep(target[key], source[key]);
              } else if (this._isArray(source[key])) {
                Object.assign(target, { [key]: source[key][0] });
              } else {
                Object.assign(target, { [key]: source[key] });
              }
            }
          }

          return this._mergeErrMsgDeep(target, ...sources);
        },
        /**
         * Merge sources to target but put false instead s trings.
         * This code do not expect array inside objects.
         * @param {Object} target
         * @param  {...any} sources
         */
        _mergeErrMsgDeepToState(target, ...sources) {
          if (!sources.length) return target;
          const source = sources.shift();
          if (this._isObject(target) && this._isObject(source)) {
            for (const key in source) {
              if (this._isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                this._mergeErrMsgDeepToState(target[key], source[key]);
              } else if (source[key].length > 0) {
                Object.assign(target, { [key]: false });
              }
            }
          }

          return this._mergeErrMsgDeepToState(target, ...sources);
        },
        // this method expects object form_invalid_feedback and form_state in
        // the data of your component, otherwise this will not work.
        // You have to pass the api response data from drf, this function will
        // catch the error messagess and will put them in form_invalid_feedback.
        // To end, this will call $nextTick to refresh state and force vue-bootstrap
        // to show error messages.
        putFormFeedback(response, _config = {}) {
          // mezcla config pasada por arg y config by default
          var config = Object.assign(
            {
              formattr: "form",
              stateattr: "form_state",
              feedbackattr: "form_invalid_feedback",
            },
            _config
          );
          this.cleanFormFeedback(config);
          if (!response || !response.data) {
            return;
          }

          let data = response.data;
          const validation = this._mergeErrMsgDeep(
            this[config.feedbackattr],
            data
          );
          this.cleanFormState(config);
          const formstate = this._mergeErrMsgDeepToState(
            this[config.stateattr],
            validation
          );
          this.$nextTick(() => {
            this[config.feedbackattr] = validation;
          });
          this.$nextTick(() => {
            this[config.stateattr] = formstate;
          });
          this.$forceUpdate();
        },
        showResponseError(err) {
          if (!err) {
            return;
          }

          let response = err.response;
          if (!response) {
            response = err;
          }

          if (response.data && response.data["non_field_errors"]) {
            this.mnotify_warn({
              title: "Error",
              text: response.data["non_field_errors"],
            });
          } else if (response.data && response.data["detail"]) {
            this.mnotify_error({
              title: "Error",
              text: response.data["detail"],
            });
          } else if (response && response.status >= 500) {
            this.mnotify_error({
              title: "Error",
              text:
                "There is a problem with the system. We have been notificated and a member of our staff is working to fix it.",
            });
          } else if (response && response.message) {
            // To enable connection notifications, uncomment this line.
            // These notification are already managed in api-config.js
            // this.mnotify_error({
            //   title: "Error",
            //   text: response.message
            // });
          }
        },
        putInputError(inputname, errortxt) {
          var validation = this.form_invalid_feedback;
          validation[inputname] = errortxt;
          this.form_state[inputname] = false;
          this.$nextTick(() => {
            this.form_invalid_feedback = validation;
          });
        },
        cleanFormErrors() {
          this.form_state = {};
          this.$nextTick(() => {
            this.form_invalid_feedback = {};
          });
        },
      },
    });
  },
};

export default VueBootstrapUtilForm;
