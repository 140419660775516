var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{staticClass:"behind_modal_z_index",attrs:{"active":_vm.hasPaymentIntention,"color":"#ffffff","background-color":"rgba(0, 0, 0, .7)","text":_vm.$t('checkout_control_payment.payment_in_progress')}}),(_vm.options)?_c('div',_vm._l((_vm.options),function(payment_configuration,index){return _c('div',{key:index},[(_vm.computedAmount && payment_configuration.payment_method.payment_method == 9 || payment_configuration.payment_method.payment_method == 4)?_c('div',{staticClass:"align-middle border p-3 mb-3"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("checkout_control_payment.method"))+": "+_vm._s(payment_configuration.payment_method.name)+" ")]),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("checkout_control_payment.total"))+": "+_vm._s(_vm.computedAmount.money.toLocaleString())+" "),(payment_configuration.percent_increment)?_c('span',[_vm._v(" + "+_vm._s(_vm.$formatPercent(payment_configuration.percent_increment))+" ")]):_vm._e()]),(
            payment_configuration.payment_method.payment_method == 1 &&
              payment_configuration.paypal_sandbox
          )?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("checkout_control_payment.error.paypal_sandbox_mode"))+" ")]):_vm._e(),(
            payment_configuration.payment_method.payment_method == 1 &&
              payment_configuration.paypal_client_id
          )?_c('div',[_c('PayPal',{attrs:{"amount":_vm.computedAmount.total,"currency":_vm.computedAmount.currency,"client":payment_configuration.paypal_sandbox
                ? { sandbox: payment_configuration.paypal_client_id }
                : { production: payment_configuration.paypal_client_id },"env":payment_configuration.paypal_sandbox ? 'sandbox' : 'production',"commit":false},on:{"payment-authorized":function($event){_vm.setPaymentConf(payment_configuration);
              _vm.onPaymentAuthorized($event);},"payment-completed":function($event){_vm.setPaymentConf(payment_configuration);
              _vm.onPaymentCompleted($event);},"payment-cancelled":function($event){_vm.setPaymentConf(payment_configuration);
              _vm.onPaymentCancelled($event);}}})],1):_vm._e(),(
            payment_configuration.payment_method.payment_method == 4 &&
              payment_configuration.bank_wire_number
          )?_c('div',[_c('CheckoutControlPaymentBankTransfer',{attrs:{"payment_configuration":payment_configuration,"pharmacy_uuid":_vm.pharmacy_uuid},on:{"payment-authorized":function($event){_vm.setPaymentConf(payment_configuration);
              _vm.onPaymentAuthorized($event);}}})],1):_vm._e(),(payment_configuration.payment_method.payment_method == 9)?_c('div',[_c('CheckoutControlPaymentWebPayPlus',{attrs:{"payment_configuration":payment_configuration,"pharmacy_uuid":_vm.pharmacy_uuid,"checkout_uuid":_vm.checkout_uuid},on:{"payment-authorized":function($event){_vm.setPaymentConf(payment_configuration);
              _vm.onPaymentAuthorized($event);},"payment-intention":function($event){return _vm.$emit('payment-intention', arguments[0])},"payment-error":function($event){_vm.$emit('payment-error', arguments[0]);
              _vm.pushWebPayError(arguments[0]);},"payment-intention-expires":function($event){_vm.$emit('payment-intention-expires', arguments[0]);
              _vm.pushWebPayError(arguments[0]);}}}),_vm._l((_vm.computed_webpay_pay_errors),function(error,index){return _c('b-alert',{key:index,attrs:{"show":"","variant":"danger"}},[(error.message)?_c('span',[_vm._v(_vm._s(error.message))]):_c('span',[_vm._v(_vm._s(_vm.$t("checkout_control_payment.error.payment_error")))])])})],2):_vm._e()],1):_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("checkout_control_payment.error.cannot_calculate_amount"))+" "),_c('div',[_vm._v(" 1. "+_vm._s(_vm.$t("checkout_control_payment.error.amount_reason1"))+" ")]),_c('div',[_vm._v(" 2. "+_vm._s(_vm.$t("checkout_control_payment.error.amount_reason2"))+" ")]),_c('div',[_vm._v(" 3. "+_vm._s(_vm.$t("checkout_control_payment.error.amount_reason3"))+" ")])])],1)])}),0):_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("checkout_control_payment.no_methods_available"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }