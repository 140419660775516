/* eslint-disable indent */
export const generate_cda = function(self, info) {
  const $t = key => self.$t(key);

  return `<?xml version="1.0"?>
    <?xml-stylesheet type="text/xsl" href="CDA.xsl"?>

    <ClinicalDocument xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="urn:hl7-org:v3 http://xreg2.nist.gov:8080/hitspValidation/schema/cdar2c32/infrastructure/cda/C32_CDA.xsd"
    xmlns="urn:hl7-org:v3"
    xmlns:mif="urn:hl7-org:v3/mif">
        <realmCode code="US"/>
        <typeId root="2.16.840.1.113883.1.3" extension="POCD_HD000040"/>
        <templateId root="2.16.840.1.113883.10.20.22.1.1"/>
        <templateId root="2.16.840.1.113883.10.20.22.1.2"/>
        <id extension="${info.uuid}" root="2.16.840.1.113883.19"/>
        <code codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" code="34133-9" displayName="Summarization of Episode Note"/>
        <title>${info.title || ""}</title>
        <effectiveTime value="${info.date}+0000"/>
        <confidentialityCode code="N" codeSystem="2.16.840.1.113883.5.25"/>
        <languageCode code="en-US"/>
        <setId extension="111199021" root="2.16.840.1.113883.19"/>
        <versionNumber value="1"/>
        <recordTarget>
            <patientRole>
                <id extension="${info.patient.uuid || ""}" root="2.16.840.1.113883.19"/>
                <!-- Fake ID using HL7 example OID. -->

                <patient>
                    <name use="L"><!-- L is "Legal" from HL7 EntityNameUse 2.16.840.1.113883.5.45 -->
                        <prefix></prefix>
                        <given>${info.patient.name || ""}</given>
                        <family>${info.patient.surname || ""} ${info.patient.family || ""}</family>
                    </name>
                    <!--
                    <administrativeGenderCode code="M" codeSystem="2.16.840.1.113883.5.1" displayName="Male"/>
                    <birthTime value="19541125"/>
                    -->
                </patient>
            </patientRole>
        </recordTarget>

        <author>
            <time value="${info.date}+0000"/>
            <assignedAuthor>
                <id extension="${info.title || ""}" root="2.16.840.1.113883.19.5"/>
            </assignedAuthor>
        </author>

        <custodian>
            <assignedCustodian>
                <representedCustodianOrganization>
                    <id root="2.16.840.1.113883.19.5"/>
                    <name>${info.organization.public_name || ""}</name>
                </representedCustodianOrganization>
            </assignedCustodian>
        </custodian>

        <component>
            <structuredBody>
                <component>
                    <section>
                        <templateId root="2.16.840.1.113883.10.20.22.2.3.1"/>
                        <code code="30954-2" codeSystem="2.16.840.1.113883.6.1" codeSystemName="LOINC" displayName="RESULTS"/>
                        <title></title>
                        <text>
                            <table>
                                <tbody>

                                    <tr>
                                        <td colspan="2">${ $t('transaction_details.insurance_title') }</td>
                                    </tr>

                                    <tr>
                                        <td>${ $t('transaction_details.organization_name') }</td>
                                        <td>${ info.organization.public_name || "" }</td>
                                    </tr>
                                    <tr>
                                        <td>${ $t('transaction_details.organization_email') }</td>
                                        <td>${ info.organization.email || "" }</td>
                                    </tr>
                                    <tr>
                                        <td>${ $t('transaction_details.organization_country') }</td>
                                        <td>${ info.organization.country || "" }</td>
                                    </tr>
                                    <tr>
                                        <td>${ $t('transaction_details.organization_town') }</td>
                                        <td>${ info.organization.town || "" }</td>
                                    </tr>
                                    <tr>
                                        <td>${ $t('transaction_details.organization_postal_codel') }</td>
                                        <td>${ info.organization.postal_code || "" }</td>
                                    </tr>
                                    <tr>
                                        <td>${ $t('transaction_details.organization_phone') }</td>
                                        <td>${ info.organization.phone || "" }</td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">${ $t('transaction_details.documents_title') }</td>
                                    </tr>

                                    ${
                                        info.files_config.map((file) => {
                                            return `
                                                <tr>
                                                    <td colspan="2">
                                                        <linkHtml href="${ file.file_model }">${ file.short_description }</linkHtml>
                                                    </td>
                                                </tr>
                                            `;
                                        }).join("")
                                    }

                                    ${
                                        info.extra_files.map((obj) => {
                                            return obj.files.map((file) => {
                                                return `<tr>
                                                    <td colspan="2">
                                                        <linkHtml href="${ file.file }">${ obj.fileconfig.short_description }</linkHtml>
                                                    </td>
                                                </tr>`;
                                            }).join("")
                                        }).join("")
                                    }

                                    <tr>
                                        <td colspan="2">${ $t('transaction_details.drugs_title') }</td>
                                    </tr>

                                    ${
                                        info.items_set.map((obj) => {
                                            return obj.drugs.map((drug) => {
                                                return `<tr>
                                                    <td>
                                                        <content>${ drug.default_description }</content>
                                                    </td>
                                                    <td>${ drug.quantity }</td>
                                                </tr>`;
                                            }).join("")
                                        }).join("")
                                    }

                                </tbody>
                            </table>
                        </text>
                    </section>
                </component>
            </structuredBody>
        </component>
    </ClinicalDocument>`;
};