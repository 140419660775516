<template>
  <div>
    <DefaultLayout>
      <template slot="navigation">
        <InsuranceRefundNavigation />
      </template>
      <vue-element-loading :active="!show" :is-full-screen="true" />
      <div v-if="show">
        <b-modal
          size="xl"
          modal-class="modal-square-minimalist-no-spacing-theme"
          v-model="modal_promotional_video_show"
          centered
          hide-footer
        >
          <b-embed type="video" controls autoplay playsinline>
            <source :src="promotional_video_url" />
          </b-embed>
        </b-modal>

        <OrganizationSubHeader :i_uuid="i_uuid">
          <div v-if="files_config && active_insurancerefund">
            <!-- <h6
              class="file-loader-title border-bottom font-weight-bold text-uppercase pb-1"
            >
              {{ $t("insurancerefund_home.see_documents") }}
            </h6> -->

            <h5 class="text-uppercase">
              {{ $t('insurancerefund_home.documents_help_title') }}
            </h5>
            <div class="lh-md w-75">
              <small>
                <strong>
                  <em>
                    {{ $t('insurancerefund_home.documents_help_text1') }}
                  </em>
                </strong>
              </small>
            </div>
            <div class="lh-md w-75 pt-3">
              <small>
                <strong>{{ $t('insurancerefund_home.documents_help_text2') }}</strong>
              </small>
            </div>

            <div v-for="(item, index) in files_config" :key="index">
              <InsuranceRefundFileLoader
                :i_uuid="i_uuid"
                :pt_uuid="pt_uuid"
                :fileconfig="item"
                :insurancerefund_uuid="active_insurancerefund.uuid"
              />
            </div>
          </div>

          <div class="lh-md w-75 pt-2">
            <small>
              <strong>{{ $t('insurancerefund_home.documents_help_text3') }}</strong>
            </small>
          </div>
          <v-plus-link
            @click="routeToInvoices()"
            class="mr-2"
            v-if="invoicelist.results.length <= 0"
          >
            {{ $t("insurancerefund_home.add_invoice_link") }}
          </v-plus-link>
          <v-plus-link
            @click="routeToInvoices()"
            icon="pen"
            class="mr-2"
            v-else
          >
            {{ $t("insurancerefund_home.edit_invoice_link") }}
            <b-badge pill variant="secondary" class="ml-3">{{
              $t("insurancerefund_home.invoice_done")
            }}</b-badge>
          </v-plus-link>
        </OrganizationSubHeader>
        <b-container fluid v-if="invoicelist.results.length > 0" class="mt-5">
          <b-row>
            <b-col col lg="12" md="auto">
              <div>
                <h4 class="border-bottom pb-1 mb-3">
                  {{ $t("insurancerefund_home.drugs") }}
                </h4>

                <h5
                  class=""
                  v-for="(invoice, index) in invoicelist.results"
                  :key="index"
                >
                  {{ $t('insurancerefund_home.invoice_druglist_title') }}
                  {{ invoice.tax_folio_num }}
                  {{ invoice.folio_num }}
                </h5>

                <VDrugsSummary
                  v-if="active_insurancerefund"
                  :insurance="active_insurancerefund.insurance"
                  :items="allproducts"
                  :amount="new amount_object(active_insurancerefund.amount)"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="pr-0">
              <b-button
                class="authorization pull-right mt-5"
                variant="primary"
                @click="onSaveButtonClick"
              >
                <SVGAuthorization class="mr-2" />
                {{ $t("insurancerefund_home.save_button") }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import InsuranceRefundNavigation from "@/components/insurancerefund/InsuranceRefundNavigation";
import OrganizationSubHeader from "@/components/OrganizationSubHeader";
import InsuranceRefundFileLoader from "@/components/insurancerefund/InsuranceRefundFileLoader";
import VPlusLink from "@/components/VPlusLink";
import VDrugsSummary from "@/components/insurancerefund/VDrugsSummary";

import SVGAuthorization from "@/static/authorization.svg";

import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { Amount } from "@/lib/amount.js";

export default {
  name: "insurancerefund-home",
  data() {
    return {
      amount_object: Amount,
      show: false,
      files_config: undefined,
      i_uuid: this.$route.params.i_uuid,
      pt_uuid: this.$route.params.pt_uuid,
      insurancerefund_uuid: this.$route.params.insurancerefund_uuid,
      insurancerefund: undefined,
      modal_promotional_video_show: false,
      promotional_video_url: undefined,
    };
  },
  props: {
    showPromo: {
      type: Boolean,
      default: false,
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancerefund_home: {
          add_invoice_link: "Add invoices",
          edit_invoice_link: "Edit invoices",
          save_button: "Save and send",
          drugs: "Invoice drugs",
          see_documents: "See documents",
          invoice_done: "Done",
          documents_help_title: "How to make your request?",
          documents_help_text1:
            "To process your reimbursement request, you must attach the documents requested by your insurer along with the medical prescription in file, pdf, jpg, tiff or png format.",
          documents_help_text2: "Start by clicking on each of them.",
          documents_help_text3:
            "To add the invoices click on the following button:",
          invoice_druglist_title: "Invoice",
          error: {
            retrieving_organization_info: "Cannot get   organization info",
            create_draft_refund: "Cannot create initial refund data",
            saving_refund: "Cannot save refund",
            refound_not_found: "Refund not found",
          },
        },
      },
    },
  },
  computed: {
    ...mapGetters("insurancerefund", ["getDraftInsuranceRefundByUUID"]),
    // TODO: separar los config files según sea autorización o reembolso
    ...mapGetters("drugtransaction", ["getRefundConfigFilesByIUUID"]),
    ...mapGetters("organization", ["getInsuranceDataByUUID"]),
    ...mapState({
      active_insurancerefund: (state) =>
        state.insurancerefund.active_insurancerefund,
      latest_params: (state) => state.routehelper.latest_params,
      invoicelist: (state) => state.refundinvoice.invoicelist,
    }),
    allproducts: {
      cache: true,
      get() {
        if (!this.active_insurancerefund) {
          return undefined;
        }
        if (!this.active_insurancerefund.invoices) {
          return undefined;
        }
        let products = [];
        this.active_insurancerefund.invoices.forEach((invoice) => {
          if (!invoice.item_list) {
            return;
          }
          if (!invoice.item_list.drugs) {
            return;
          }
          invoice.item_list.drugs.forEach((element) => {
            products.push(Object.assign({}, element));
          });
        });
        return products;
      },
    },
  },
  mounted: function () {
    // this.loadRefundData();
    this.loadInvoices();
    this.loadConfigFiles();
    this.loadRefund();
    this.putBreadCrumb();
    var organization = this.getInsuranceDataByUUID(this.i_uuid);
    if (
      this.showPromo &&
      organization &&
      organization.configuration &&
      organization.configuration.show_pdv_insurancerefund_promotional_video
    ) {
      if (organization.configuration.pdv_insurancerefund_promotional_video) {
        this.modal_promotional_video_show = true;
        this.promotional_video_url =
          organization.configuration.pdv_insurancerefund_promotional_video;
      }
    }
  },
  methods: {
    ...mapActions({
      REST_LOAD_INSURANCEREFUND_ALL_INVOICES:
        "refundinvoice/REST_LOAD_INSURANCEREFUND_ALL_INVOICES",
    }),
    _putBreadCrumb(organization) {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.refund_insurance_selection",
          to: { name: "nopatient_insurancerefund_insurance_selection" },
        },
        {
          langkey: "breadcrumb.insurancerefund_home",
          langparams: {
            insurance_name: (organization || {}).public_name || "Insurance",
          },
          to: { name: "nopatient_insurancerefund_home" },
        },
      ]);
    },
    putBreadCrumb() {
      var organization = this.getInsuranceDataByUUID(this.i_uuid);
      if (!organization) {
        this.$store.dispatch("organization/INSURANCE_REFUND_LIST").then(
          () => {
            organization = this.getInsuranceDataByUUID(this.i_uuid);
            if (organization) {
              this._putBreadCrumb(organization);
            }
          },
          () => {
            this.mnotify_error({
              text: this.$t(
                "insurancerefund_home.error.retrieving_organization_info"
              ),
            });
          }
        );
      } else {
        this._putBreadCrumb(organization);
      }
    },
    loadInvoices() {
      // Limpiamos el store de facturas (puede quedarse sucio de un reembolso
      // anterior)
      this.$store.commit("refundinvoice/MUTATE_INSURANCEREFUND_ALL_INVOICES", {
        results: [],
      });
      if (this.insurancerefund_uuid == "new") {
        return;
      }
      this.REST_LOAD_INSURANCEREFUND_ALL_INVOICES({
        insurancerefund_uuid: this.insurancerefund_uuid,
        insurance_uuid: this.i_uuid,
      });
    },
    loadRefund() {
      if (this.latest_params.insurancerefund_uuid == "new") {
        this.loadNewRefund();
        return;
      }

      // esto cargará un reembolos por uuid o cargará uno cualquiera
      // en borrador si no lo encuentra
      this.loadOneRefund();
    },
    loadOneRefund() {
      // vuelve a cargar la lista de reembolsos borrador
      this.$store
        .dispatch("insurancerefund/LOAD_ALL_INSURANCE_DRAFT_INSURANCEREFUND", {
          pt_uuid: this.pt_uuid,
        })
        .then(
          () => {
            let insurancerefund = this.getDraftInsuranceRefundByUUID(
              this.latest_params.i_uuid,
              this.latest_params.insurancerefund_uuid
            );
            if (insurancerefund) {
              this.$store.commit(
                "insurancerefund/MUTATE_DRAFT_INSURANCEREFUND",
                insurancerefund
              );
              this.show = true;
              this.routeToRefund(insurancerefund.uuid);
            } else {
              this.refoundNotFound();
              this.loadNewRefund();
            }
          },
          (err) => {
            this.$sentry({ capture: err });
            this.$router.push({ name: "fallback-error-page" });
          }
        );
    },
    refoundNotFound() {
      this.show = true;
      this.mnotify_error({
        text: this.$t("insurancerefund_home.error.refound_not_found"),
      });
      this.$router.push({
        name: "nopatient_insurancerefund_insurance_selection",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
        },
      });
    },
    routeToRefund(insurancerefund_uuid) {
      this.$router.replace({
        name: "nopatient_insurancerefund_home",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
          i_uuid: this.latest_params.i_uuid,
          insurancerefund_uuid: insurancerefund_uuid,
        },
      });
    },
    routeToInvoices() {
      this.$router.push({
        name: "nopatient_insurancerefund_invoice",
        params: {
          i_uuid: this.latest_params.i_uuid,
          insurancerefund_uuid: this.latest_params.insurancerefund_uuid,
        },
      });
    },
    loadConfigFiles() {
      this.files_config = this.getRefundConfigFilesByIUUID(this.i_uuid);
      if (!this.files_config) {
        this.$store
          .dispatch(
            "drugtransaction/LOAD_INSURANCE_INSURANCEREFUND_CONFIG_FILES",
            {
              i_uuid: this.i_uuid,
            }
          )
          .then(
            () => {
              this.files_config = this.getRefundConfigFilesByIUUID(this.i_uuid);
            },
            (err) => {
              this.$sentry({ capture: err });
              this.$router.push({ name: "fallback-error-page" });
            }
          );
      }
    },
    loadNewRefund() {
      // create and load new insurancerefund
      this.$store
        .dispatch(
          "insurancerefund/CREATE_PATIENT_INSURANCE_DRAFT_INSURANCEREFUND",
          {
            data: { preferred_currency: "MXN" },
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
          }
        )
        .then(
          (response) => {
            // update route to put insurancerefund uuid
            this.routeToRefund(response.data.uuid);
            // show view content
            this.show = true;
          },
          (err) => {
            this.$sentry({ capture: err });
            this.mnotify_center_retry({
              text: this.$t("insurancerefund_home.error.create_draft_refund"),
            });
            this.mnotify_error({
              text: this.$t("insurancerefund_home.error.create_draft_refund"),
            });
          }
        );
    },
    onSaveButtonClick() {
      this.$store
        .dispatch("insurancerefund/REST_DONE_DRAFT_INSURANCEREFUND", {
          i_uuid: this.i_uuid,
          insurancerefund_uuid: this.insurancerefund_uuid,
        })
        .then(
          () => {
            this.$router.push({
              name: "nopatient_insurancerefund_historial",
            });
          },
          (err) => {
            this.mnotify_error({
              text: this.$t("insurancerefund_home.error.saving_refund"),
            });
            this.showResponseError(err);
            this.putFormFeedback(err.response);
          }
        );
    },
  },
  components: {
    DefaultLayout,
    OrganizationSubHeader,
    InsuranceRefundFileLoader,
    VPlusLink,
    InsuranceRefundNavigation,
    VDrugsSummary,
    SVGAuthorization,
  },
};
</script>

<style lang="scss" scoped>
.file-loader-title {
  font-size: $font-size-base * 1.4;
}

.authorization {
  svg {
    fill: $white;
    height: 1rem;
  }

  &:hover svg {
    fill: $corp-color1;
  }
}
</style>
