<template>
  <DefaultLayout>
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <b-nav vertical class="w-25">
            <b-nav-item :to="{name: 'patientdata_create'}">New patient</b-nav-item>
            <b-nav-item :to="{name: 'pharmacy_new'}">New pharmacy</b-nav-item>
            <b-nav-item :to="{ name: 'account_organizations_List' }">Org list</b-nav-item>

          </b-nav>
        </b-col>
        <b-col cols="8">
          <div v-if="title" class="container-title">
            <h1>{{ title }}</h1>
          </div>
          <slot></slot>
        </b-col>
      </b-row>
    </b-container>
  </DefaultLayout>
</template>


<script scoped>
import DefaultLayout from "@/layouts/Default";

export default {
  name: "default-layout",
  props: {
    // a string to be used in v-if to determine wich navigation should be showed
    navigation: String,
    title: String,
    showbreadcrumbs: {
      default: true,
      type: Boolean,
    },
  },
  computed: {},
  methods: {
    // logout() {
    //   this.$store.dispatch('auth/logout');
    // },
  },
  components: {
    DefaultLayout,
  },
};
</script>


<style lang="scss" scoped>
.container-full {
  border-top: 1px solid $gray-400;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: $spacing;
}

body,
#app,
.layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

// .content-wrapper {
//   /*flex: 1 0 auto;*/ /*Uncomment if you want to move the footer to the bottom of the page*/
// }

.layout-footer {
  flex-shrink: 0;
}
</style>
