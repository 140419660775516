<template>
  <b-modal
    ref="modal"
    :title="$t('delete_patient_confirm.title')"
    :ok-disabled="okDisabled"
    @ok="confirmDelete"
  >
    <p class="warning">
      {{ $t('delete_patient_confirm.warning_text', { name: patientName }) }}
    </p>
    <p>
      {{ $t('delete_patient_confirm.confirm_text', { name: patientName }) }}
    </p>
    <input
      type="text"
      v-model="nameConfirmation"
      class="w-100"
    />
  </b-modal>
</template>


<script>
export default {
  name: "delete-patient-confirm",
  data () {
    return {
      patient: undefined,
      nameConfirmation: "",
    }
  },
  computed: {
    patientName () {
      return this.patient ? (this.patient.firstname + " " + this.patient.lastname).trim() : "";
    },
    okDisabled () {
      return this.patientName.trim().toLowerCase() != this.nameConfirmation.trim().toLowerCase();
    },
  },
  methods: {
    show (patient) {
      this.patient = patient;
      this.nameConfirmation = "";
      this.$refs.modal.show();
    },
    confirmDelete () {
      this.$emit("ok", this.patient);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        delete_patient_confirm: {
          title: "Patient Delete Confirmation",
          warning_text: "You are going to remove {name}. Removed patient CANNOT be restored! Are you ABSOLUTELY sure?",
          confirm_text: "This action can lead to data loss. To prevent accidental actions we ask you to confirm your intention. Please type \"{name}\" to proceed or close this modal to cancel.",
        },
      },
    },
  },
};
</script>


<style lang="scss" scoped>
.warning {
  color: $red;
  font-weight: bold;
}
</style>
