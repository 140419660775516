<template>
  <VuePhoneNumberInput
    :id="id"
    :size="size"
    :required="required"
    :translations="phoneFieldTranslations"
    :defaultCountryCode="myCountryCode"
    :preferredCountries="['MX', 'US', 'ES']"
    :value="myPhoneNumber"
    @update="onUpdate"
    no-validator-state
  ></VuePhoneNumberInput>
</template>


<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapGetters } from "vuex";

export default {
  name: "phone-number-input",
  extends: VuePhoneNumberInput,
  components: {
    VuePhoneNumberInput,
  },
  props: {
    value: String,
    id: String,
    size: String,
    required: Boolean,
  },
  data () {
    return {
      myCountryCode: "-",
      myPhoneNumber: "",
    }
  },
  computed: {
    ...mapGetters("auth", ["countryFromRequest"]),
    countryCodes () {
      return this.codesCountries.reduce((codes, country) => {
        codes[country.dialCode] = country.iso2;
        return codes;
      }, { });
    },
    phoneFieldTranslations () {
      return {
        countrySelectorLabel: "",
        countrySelectorError: "",
        phoneNumberLabel: "",
        example: "",
      }
    },
  },
  created () {
    this.parsePhoneNumber();
  },
  watch: {
    value () {
      this.parsePhoneNumber();
    },
  },
  methods: {
    onUpdate (payload) {
      this.$emit('update', payload);
    },
    parsePhoneNumber() {
      if (this.value && this.value[0] === "+") {
        const phone = this.value.slice(1);
        for (var i = 4; i > 0; i--) {
          const code = this.countryCodes[phone.slice(0, i)];
          if (code) {
            this.myCountryCode = code;
            this.myPhoneNumber = this.getAsYouTypeFormat({
              countryCode: code,
              phoneNumber: phone.slice(i),
            });
            return;
          }
        }
      }

      // Number doesn't start with '+' or code not found
      this.myCountryCode = this.countryFromRequest;
      this.myPhoneNumber = this.value;
    },
  },
};
</script>


<style lang="scss" scoped>
.vue-phone-number-input /deep/ .input-phone-number input{
  margin-left: 0 !important;
}
</style>
