<template>
  <div>
    <DefaultLayout>
      <template slot="navigation">
        <InsuranceAuthorizationNavigation />
      </template>
      <vue-element-loading :active="!show" :is-full-screen="true" />
      <div v-if="show">
        <OrganizationSubHeader :i_uuid="i_uuid">
          <div v-if="files_config && active_insuranceauthorization">
            <!-- <h6 class="file-loader-title border-bottom font-weight-bold text-uppercase pb-1">
              {{ $t('insuranceauthorization_home.see_documents') }}
            </h6> -->

            <h5 class="text-uppercase">
              {{ $t('insuranceauthorization_home.documents_help_title') }}
            </h5>
            <div class="lh-md w-75">
              <small>
                <strong>
                  <em>
                    {{ $t('insuranceauthorization_home.documents_help_text1') }}
                  </em>
                </strong>
              </small>
            </div>
            <div class="lh-md w-75 pt-3">
              <small>
                <strong>{{ $t('insuranceauthorization_home.documents_help_text2') }}</strong>
              </small>
            </div>

            <div v-for="(item, index) in files_config" :key="index">
              <InsuranceAuthorizationFileLoader
                :i_uuid="i_uuid"
                :pt_uuid="pt_uuid"
                :fileconfig="item"
                :insuranceauthorization_uuid="active_insuranceauthorization.uuid"
              />
            </div>
          </div>

          <div class="lh-md w-75 pt-2">
            <small>
              <strong>{{ $t('insuranceauthorization_home.documents_help_text3') }}</strong>
            </small>
          </div>
          <v-plus-link @click="routeToRecipe()" class="mr-2">
            {{ $t('insuranceauthorization_home.recipe_link') }}
            <b-badge
              v-for="(recipe, index) in patient_insuranceauthorization_recipes"
              v-bind:key="index"
              pill
              variant="secondary"
              class="ml-3"
            >{{ $t('insuranceauthorization_home.recipe_done') }}</b-badge>
          </v-plus-link>

        </OrganizationSubHeader>

        <b-container fluid v-if="active_insuranceauthorization" class="mt-5">
          <b-row>
            <b-col col lg="12" md="auto">
              <div v-for="(recipe, index) in patient_insuranceauthorization_recipes" v-bind:key="index">
                <h4 class="border-bottom pb-1 mb-3">
                  {{ $t('insuranceauthorization_home.drugs') }}
                </h4>
                <VDrugsSummary
                  :insurance="recipe.insurance"
                  :items="recipe.drugs"
                  :amount="new amount_object(recipe.amount)"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="pr-0" v-if="is_valid">
              <b-button
                class="authorization pull-right mt-5"
                variant="primary"
                @click="onSaveButtonClick"
              >
                <SVGAuthorization class="mr-2" />
                {{ $t('insuranceauthorization_home.save_button') }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>

      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import InsuranceAuthorizationNavigation from "@/components/insuranceauthorization/InsuranceAuthorizationNavigation";
import OrganizationSubHeader from "@/components/OrganizationSubHeader";
import InsuranceAuthorizationFileLoader from "@/components/insuranceauthorization/InsuranceAuthorizationFileLoader";
import VPlusLink from "@/components/VPlusLink";
import VDrugsSummary from "@/components/insuranceauthorization/VDrugsSummary";

import SVGAuthorization from "@/static/authorization.svg";

import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { Amount } from "@/lib/amount.js";

export default {
  name: "insuranceauthorization-home",
  data() {
    return {
      amount_object: Amount,
      show: false,
      files_config: undefined,
      i_uuid: this.$route.params.i_uuid,
      pt_uuid: this.$route.params.pt_uuid,
      insuranceauthorization_uuid: this.$route.params
        .insuranceauthorization_uuid,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insuranceauthorization_home: {
          recipe_link: "Recipe",
          save_button: "Save and send",
          drugs: "Recipe drugs",
          see_documents: "See documents",
          recipe_done: "Done",
          documents_help_title: "How to make your request?",
          documents_help_text1:
            "To process your reimbursement request, you must attach the documents requested by your insurer along with the medical prescription in file, pdf, jpg, tiff or png format.",
          documents_help_text2: "Start by clicking on each of them.",
          documents_help_text3:
            "To add the medications indicated by your doctor, click on the following button:",
          error: {
            retrieving_organization_info: "Cannot get organization info",
            create_draft_refund: "Cannot create initial refund data",
            saving_refund: "Cannot save refund",
            refound_not_found: "Refund not found",
            // no_drugs: "Please, add at least one drug to your recipe.",
            // no_recipe: "Please, add at least one recipe to your insurance authorization.",
          },
        },
      },
    },
  },
  computed: {
    ...mapGetters("insuranceauthorization", [
      "getDraftInsuranceAuthorizationByInsuranceUUID",
    ]),
    ...mapGetters("drugtransaction", ["getConfigFilesByIUUID"]),
    ...mapGetters("organization", ["getInsuranceDataByUUID"]),
    ...mapState({
      active_insuranceauthorization: (state) =>
        state.insuranceauthorization.active_insuranceauthorization,
      latest_params: (state) => state.routehelper.latest_params,
      patient_insuranceauthorization_recipes: (state) =>
        state.recipe.patient_insuranceauthorization_recipes,
    }),
    is_valid() {
      if (!this.active_insuranceauthorization) {
        return false;
      }
      if (!this.active_insuranceauthorization.recipe) {
        return false;
      }
      if (!this.active_insuranceauthorization.recipe.drugs) {
        return false;
      }
      if (this.active_insuranceauthorization.recipe.drugs.length <= 0) {
        return false;
      }
      return true;
    },
  },
  mounted: function () {
    // this.loadInsuranceAuthorizationData();
    this.loadConfigFiles();
    this.loadInsuranceAuthorization();
    this.putBreadCrumb();
  },
  methods: {
    _putBreadCrumb(organization) {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.refund_insurance_selection",
          to: { name: "nopatient_insuranceauthorization_insurance_selection" },
        },
        {
          langkey: "breadcrumb.insuranceauthorization_home",
          langparams: {
            insurance_name: (organization || {}).public_name || "Insurance",
          },
          to: { name: "nopatient_insuranceauthorization_home" },
        },
      ]);
    },
    putBreadCrumb() {
      var organization = this.getInsuranceDataByUUID(this.i_uuid);
      if (!organization) {
        this.$store.dispatch("organization/INSURANCE_AUTHORIZATION_LIST").then(
          () => {
            organization = this.getInsuranceDataByUUID(this.i_uuid);
            this._putBreadCrumb(organization);
          },
          () => {
            this.mnotify_error({
              text: this.$t(
                "insuranceauthorization_home.error.retrieving_organization_info"
              ),
            });
          }
        );
      } else {
        this._putBreadCrumb(organization);
      }
    },
    loadInsuranceAuthorization() {
      if (this.latest_params.insuranceauthorization_uuid == "new") {
        this.loadNewInsuranceAuthorization();
        return;
      }

      // esto cargará una autorización por uuid o cargará uno cualquiera
      // en borrador si no lo encuentra
      this.loadOneInsuranceAuthorization();
    },
    loadOneInsuranceAuthorization() {
      // vacía la lista de recetas borrador de este paciente
      this.$store.commit(
        "recipe/MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES_RESET"
      );
      // vuelve a cargar la lista de reembolsos borrador
      this.$store
        .dispatch(
          "insuranceauthorization/LOAD_PATIENT_ALL_INSURANCE_DRAFT_INSURANCEAUTHORIZATION",
          {
            pt_uuid: this.pt_uuid,
          }
        )
        .then(
          () => {
            let insuranceauthorization = this.getDraftInsuranceAuthorizationByInsuranceUUID(
              this.latest_params.i_uuid,
              this.latest_params.insuranceauthorization_uuid
            );
            if (insuranceauthorization) {
              this.$store.commit(
                "insuranceauthorization/MUTATE_DRAFT_INSURANCEAUTHORIZATION",
                insuranceauthorization
              );

              this.$store
                .dispatch(
                  "recipe/LOAD_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES",
                  {
                    pt_uuid: this.pt_uuid,
                    i_uuid: this.i_uuid,
                    insuranceauthorization_uuid: insuranceauthorization.uuid,
                  }
                )
                .then(undefined, (err) => {
                  this.$sentry({ capture: err });
                  this.$router.push({ name: "fallback-error-page" });
                });

              this.show = true;
              this.routeToRefund(insuranceauthorization.uuid);
            } else {
              this.refoundNotFound();
              this.loadNewInsuranceAuthorization();
            }
          },
          (err) => {
            this.$sentry({ capture: err });
            this.$router.push({ name: "fallback-error-page" });
          }
        );
    },
    refoundNotFound() {
      this.show = true;
      this.mnotify_error({
        text: this.$t("insuranceauthorization_home.error.refound_not_found"),
      });
      this.$router.push({
        name: "nopatient_insuranceauthorization_insurance_selection",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
        },
      });
    },
    routeToRefund(insuranceauthorization_uuid) {
      this.$router.replace({
        name: "nopatient_insuranceauthorization_home",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
          i_uuid: this.latest_params.i_uuid,
          insuranceauthorization_uuid: insuranceauthorization_uuid,
        },
      });
    },
    routeToRecipe() {
      this.$router.push({
        name: "nopatient_insuranceauthorization_recipe",
        params: {
          i_uuid: this.latest_params.i_uuid,
          insuranceauthorization_uuid: this.latest_params
            .insuranceauthorization_uuid,
        },
      });
    },
    loadConfigFiles() {
      this.files_config = this.getConfigFilesByIUUID(this.i_uuid);
      if (!this.files_config) {
        this.$store
          .dispatch(
            "drugtransaction/LOAD_INSURANCE_INSURANCEAUTHORIZATION_CONFIG_FILES",
            {
              i_uuid: this.i_uuid,
            }
          )
          .then(
            () => {
              this.files_config = this.getConfigFilesByIUUID(this.i_uuid);
            },
            (err) => {
              this.$sentry({ capture: err });
              this.$router.push({ name: "fallback-error-page" });
            }
          );
      }
    },
    loadNewInsuranceAuthorization() {
      // vacía la lista de recetas borrador de este paciente
      this.$store.commit(
        "recipe/MUTATE_PATIENT_INSURANCE_INSURANCEAUTHORIZATION_ALL_RECIPES_RESET"
      );
      // create and load new insuranceauthorization
      this.$store
        .dispatch(
          "insuranceauthorization/CREATE_PATIENT_INSURANCE_DRAFT_INSURANCEAUTHORIZATION",
          {
            data: {},
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
          }
        )
        .then(
          (response) => {
            // update route to put insuranceauthorization uuid
            this.routeToRefund(response.data.uuid);
            // show view content
            this.show = true;
          },
          (err) => {
            this.$sentry({ capture: err });
            this.mnotify_center_retry({
              text: this.$t(
                "insuranceauthorization_home.error.create_draft_refund"
              ),
            });
            this.mnotify_error({
              text: this.$t(
                "insuranceauthorization_home.error.create_draft_refund"
              ),
            });
          }
        );
    },
    onSaveButtonClick() {
      if (!this.is_valid) {
        return false;
      }
      this.$store
        .dispatch(
          "insuranceauthorization/DONE_PATIENT_INSURANCE_DRAFT_INSURANCEAUTHORIZATION",
          {
            pt_uuid: this.pt_uuid,
            i_uuid: this.i_uuid,
            insuranceauthorization_uuid: this.insuranceauthorization_uuid,
          }
        )
        .then(
          () => {
            // clean the list of recipes asociated to this insuranceauthorization
            this.$router.push({
              name: "nopatient_insuranceauthorization_historial",
            });
          },
          (err) => {
            this.mnotify_error({
              text: this.$t("insuranceauthorization_home.error.saving_refund"),
            });
            this.showResponseError(err);
            this.putFormFeedback(err.response);
          }
        );
    },
  },
  components: {
    DefaultLayout,
    OrganizationSubHeader,
    InsuranceAuthorizationFileLoader,
    VPlusLink,
    InsuranceAuthorizationNavigation,
    VDrugsSummary,
    SVGAuthorization,
  },
};
</script>

<style lang="scss" scoped>
.file-loader-title {
  font-size: $font-size-base * 1.4;
}

.authorization {
  svg {
    fill: $white;
    height: 1rem;
  }

  &:hover svg {
    fill: $corp-color1;
  }
}
</style>
