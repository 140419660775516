import Vue from "vue";
import PdvNotifications from "@/plugins/pdv-notifications.js";
Vue.use(PdvNotifications);
let cross = new Vue();

const STATUS_NONE = 0;
const STATUS_LOADING = 1;
const STATUS_DONE = 2;
const STATUS_DONE_WITH_ERROR = 3;

const LOADING_STATUS_REASON_NONE = 0;
const LOADING_STATUS_REASON_TIMEOUT = 1;

var getLocalStorageItem = function(item_name, default_value = undefined) {
  if (!localStorage.hasOwnProperty(item_name)) {
    return default_value;
  }
  try {
    return JSON.parse(localStorage.getItem(item_name));
  } catch (err) {
    return default_value;
  }
};

export default {
  namespaced: true,
  state: {
    notifications: [],
    show_authorization_guide_first_login_popup: getLocalStorageItem(
      "show_authorization_guide_first_login_popup",
      true
    ),
    show_refund_guide_first_login_popup: getLocalStorageItem(
      "show_authorization_guide_first_login_popup",
      true
    ),
    network_noty_errors: undefined,
    loading: true,
    loading_status: STATUS_NONE,
    loading_status_reason: LOADING_STATUS_REASON_NONE,
    // Cuándo inició la app
    init_timestamp: new Date(Date.now()),
    // La última vez que loading se puso a true
    timeout_expires: new Date(Date.now() + 10 * 1000),
  },
  actions: {
    STOP_AUTHORIZATION_GUIDE_FIRST_LOGIN: ({ commit }) => {
      commit("MUTATE_AUTHORIZATION_GUIDE_FIRST_LOGIN");
    },
    STOP_REFUND_GUIDE_FIRST_LOGIN: ({ commit }) => {
      commit("MUTATE_REFUND_GUIDE_FIRST_LOGIN");
    },
    PUSH_NETWORK_ERROR: ({ commit }, payload) => {
      commit("MUTATE_NETWORK_ERROR", payload);
    },
    PUSH_AFTER_ROUTE_NOTIFICATION: ({ commit }, payload) => {
      commit("MUTATE_PUSH_AFTER_ROUTE_NOTIFICATION", payload);
    },
    SHOW_AFTER_ROUTE_NOTIFICATION: ({ commit }) => {
      commit("MUTATE_SHOW_AFTER_ROUTE_NOTIFICATION");
    },
    PUT_LOADING_STATUS: ({ commit }, payload) => {
      commit("MUTATE_LOADING", payload);
    },
    CHECK_LOADING_TIMEOUT: ({ commit }) => {
      commit("MUTATE_LOADING");
    },
  },
  mutations: {
    MUTATE_AUTHORIZATION_GUIDE_FIRST_LOGIN: state => {
      localStorage.setItem("show_authorization_guide_first_login_popup", false);
      Vue.set(state, "show_authorization_guide_first_login_popup", false);
    },
    MUTATE_REFUND_GUIDE_FIRST_LOGIN: state => {
      localStorage.setItem("show_refund_guide_first_login_popup", false);
      Vue.set(state, "show_refund_guide_first_login_popup", false);
    },
    MUTATE_LOADING: (state, payload) => {
      // cambiamos el estado de loading si se ha indicado
      if (payload && typeof payload.loading == "boolean") {
        Vue.set(state, "loading", payload.loading);

        // si se ha indicado como loading == true
        if (payload.loading) {
          Vue.set(state, "timeout_expires", new Date(Date.now() + 10 * 1000));
          Vue.set(state, "loading_status", STATUS_LOADING);
          Vue.set(state, "loading_status_reason", LOADING_STATUS_REASON_NONE);
        } else {
          // si se ha indicado como loading == false
          Vue.set(state, "loading_status", STATUS_DONE);
          Vue.set(state, "loading_status_reason", LOADING_STATUS_REASON_NONE);
        }
      }

      // si hay timeout
      if (new Date() >= state.timeout_expires) {
        Vue.set(state, "loading", false);
        Vue.set(state, "loading_status", STATUS_DONE_WITH_ERROR);
        Vue.set(state, "loading_status_reason", LOADING_STATUS_REASON_TIMEOUT);
      }

      // si se ha indicado status
      if (payload && payload.status) {
        Vue.set(state, "loading_status", payload.status);
      }

      // si se ha indicado la razón del status
      if (payload && payload.loading_status_reason) {
        Vue.set(state, "loading_status_reason", payload.loading_status_reason);
      }

      // evitamos incongruencias. Si la app está a status DONE,
      // eliminamos loading=true
      if (
        state.loading &&
        (state.loading_status == STATUS_DONE_WITH_ERROR ||
          state.loading_status == STATUS_DONE)
      ) {
        Vue.set(state, "loading", false);
      }
    },
    MUTATE_PUSH_AFTER_ROUTE_NOTIFICATION: (state, payload) => {
      state.notifications.push(payload);
    },
    MUTATE_SHOW_AFTER_ROUTE_NOTIFICATION: state => {
      let notifications = state.notifications;

      state.notifications.forEach(function() {
        let notification = notifications.pop();
        if (notification && notification.type == "success") {
          cross.mnotify_success({ text: notification.text });
        }
      });
      state.notifications = notifications;
    },
    MUTATE_NETWORK_ERROR: (state, payload) => {
      // si aún no se han mo strado notificaciones, mostramos esta.
      if (!state.network_noty_errors) {
        Vue.set(state, "network_noty_errors", [payload]);
        payload.noty.show();
        return;
      }

      let network_noty_errors = state.network_noty_errors;

      // recorremos las notificaciones actuales, si encontramos una con el
      // mismo código de error la reemplazamos con el payload actual
      // y la mostramos
      for (let index = 0; index < state.network_noty_errors.length; index++) {
        if (
          state.network_noty_errors[index].errcode == payload.errcode &&
          state.network_noty_errors[index].noty.options.text ==
            payload.noty.options.text
        ) {
          network_noty_errors[index].noty.close();
          network_noty_errors[index].noty = payload.noty;
          Vue.set(state, "network_noty_errors", network_noty_errors);
          payload.noty.show();
          return;
        }
      }

      // aquí tenemos una notificación nueva, la añadimos a las que ya existen
      // y la mostramos
      network_noty_errors.push(payload);
      Vue.set(state, "network_noty_errors", network_noty_errors);
      payload.noty.show();
    },
  },
  getters: {
    isAppLoaded: state => {
      return state.loading_status > 0;
    },
  },
};
