<template>
  <div>
    <vue-element-loading :active="!show" :is-full-screen="true" />
    <InsuranceAuthorizationTableList
      class="mt-4"
      v-if="transaction && type=='insuranceauthorization'"
      :static_items="[transaction]"
      :show_pagination="false"
      :show_action_column="false"
    />
    <InsuranceBridgeTableList
      class="mt-4"
      v-if="transaction && type=='insurancebridge'"
      :static_items="[transaction]"
      :show_pagination="false"
      :show_action_column="false"
    />
    <InsuranceRefundTableList
      class="mt-4"
      v-if="transaction && type=='insurancerefund'"
      :static_items="[transaction]"
      :show_pagination="false"
      :show_action_column="false"
    />
    <div v-if="show">
      <div deck class="column-max-2">

        <div class="organization-info column-max-item sep-4 mt-1">
          <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.insurance_title') }}</h6>
          <div class="box-info border px-3 py-3 mx-0 my-0">
            <div class="column-max-2">
              <b-container>
                <b-row>
                  <b-col cols="8">
                    <div><strong>{{ $t('transaction_details.organization_name') }}</strong>: {{ organization.public_name }}</div>
                    <div><strong>{{ $t('transaction_details.organization_email') }}</strong>: {{ organization.email }}</div>
                    <div><strong>{{ $t('transaction_details.organization_country') }}</strong>: {{ organization.country }}</div>
                    <div><strong>{{ $t('transaction_details.organization_town') }}</strong>: {{ organization.town }}</div>
                    <div><strong>{{ $t('transaction_details.organization_postal_codel') }}</strong>: {{ organization.postal_code }}</div>
                    <div><strong>{{ $t('transaction_details.organization_phone') }}</strong>: {{ organization.phone }}</div>
                  </b-col>
                  <b-col cols="4">
                    <b-card class="border-0">
                      <div class="imgcontainer mb-0" style="--aspect-ratio:1/1;">
                        <b-img :src="organization.logo" alt="Logo" class="card-img p-2" @error="imageLoadError" />
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>

        <div class="provider-info column-max-item sep-4 mt-1" v-if="type=='insuranceauthorization'">
          <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.provider_title') }}</h6>
          <div class="box-info border px-3 py-3 mx-0 my-0">
            <b-container v-for="(provider, index) in providers" :key="index">
              <b-row>
                <b-col cols="8">
                  <div><strong>{{ $t('transaction_details.provider_name') }}</strong>: {{ provider.public_name }}</div>
                  <div><strong>{{ $t('transaction_details.provider_email') }}</strong>: {{ provider.email }}</div>
                  <div><strong>{{ $t('transaction_details.provider_country') }}</strong>: {{ provider.country }}</div>
                  <div><strong>{{ $t('transaction_details.provider_town') }}</strong>: {{ provider.town }}</div>
                  <div><strong>{{ $t('transaction_details.provider_postal_codel') }}</strong>: {{ provider.postal_code }}</div>
                  <div><strong>{{ $t('transaction_details.provider_phone') }}</strong>: {{ provider.phone }}</div>
                </b-col>
                <b-col cols="4">
                  <b-card class="border-0">
                    <div class="imgcontainer mb-0" style="--aspect-ratio:1/1;">
                      <b-img :src="provider.logo" alt="Logo" class="card-img p-2" @error="imageLoadError" />
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>

        <div class="delivery-info column-max-item sep-4 mt-1" v-if="type=='insuranceauthorization'">
          <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.delivery_address_title') }}</h6>
          <div class="box-info border px-3 py-3 mx-0 my-0">
            <b-container>
              <b-row>
                <b-col cols="8">
                  <div v-if="delivery">
                    <div><strong>{{ $t('transaction_details.delivery_firstname') }}</strong>: {{ delivery.firstname }}</div>
                    <div v-if="delivery.lastname"><strong>{{ $t('transaction_details.delivery_lastname') }}</strong>: {{ delivery.lastname }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_email') }}</strong>: {{ delivery.email }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_address') }}</strong>: {{ delivery.address }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_colony') }}</strong>: {{ delivery.colony }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_town') }}</strong>: {{ delivery.town }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_country') }}</strong>: {{ delivery.country }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_federal_entity') }}</strong>: {{ delivery.federal_entity }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_postal_code') }}</strong>: {{ delivery.postal_code }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_phone') }}</strong>: {{ delivery.phone }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_mobile') }}</strong>: {{ delivery.mobile }}</div>
                    <div v-if="delivery.hours || delivery.date">
                      <strong>{{ $t('transaction_details.delivery_date_hours') }}</strong>:
                      <span v-if="delivery.date">{{ delivery.date }}</span>
                      <span v-if="delivery.hours"> {{ delivery.hours }}</span>
                      <div>
                        <small>*{{ $t('transaction_details.delivery_date_help_text') }}</small>
                      </div>

                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <!-- <GoogleMap
                    :place="mapPlace"
                    class="w-100 h-100"
                  /> -->
                  <!-- <b-card class="border-0">
                    <div class="imgcontainer mb-0" style="--aspect-ratio:1/1;">
                      <img :src="organization.logo" alt="Logo" class="card-img p-2">
                    </div>
                  </b-card> -->
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>

        <div class="provider-info column-max-item sep-4 mt-1" v-if="type=='insurancebridge'">
          <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.provider_title') }}</h6>
          <div class="box-info border px-3 py-3 mx-0 my-0">
            <b-container v-for="(provider, index) in providers" :key="index">
              <b-row>
                <b-col cols="8">
                  <div><strong>{{ $t('transaction_details.provider_name') }}</strong>: {{ provider.public_name }}</div>
                  <div><strong>{{ $t('transaction_details.provider_email') }}</strong>: {{ provider.email }}</div>
                  <div><strong>{{ $t('transaction_details.provider_country') }}</strong>: {{ provider.country }}</div>
                  <div><strong>{{ $t('transaction_details.provider_town') }}</strong>: {{ provider.town }}</div>
                  <div><strong>{{ $t('transaction_details.provider_postal_codel') }}</strong>: {{ provider.postal_code }}</div>
                  <div><strong>{{ $t('transaction_details.provider_phone') }}</strong>: {{ provider.phone }}</div>
                </b-col>
                <b-col cols="4">
                  <b-card class="border-0">
                    <div class="imgcontainer mb-0" style="--aspect-ratio:1/1;">
                      <b-img :src="provider.logo" alt="Logo" class="card-img p-2" @error="imageLoadError" />
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>

        <div class="delivery-info column-max-item sep-4 mt-1" v-if="type=='insurancebridge'">
          <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.delivery_address_title') }}</h6>
          <div class="box-info border px-3 py-3 mx-0 my-0">
            <b-container>
              <b-row>
                <b-col cols="8">
                  <div v-if="delivery">
                    <div><strong>{{ $t('transaction_details.delivery_firstname') }}</strong>: {{ delivery.firstname }}</div>
                    <div v-if="delivery.lastname"><strong>{{ $t('transaction_details.delivery_lastname') }}</strong>: {{ delivery.lastname }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_email') }}</strong>: {{ delivery.email }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_address') }}</strong>: {{ delivery.address }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_colony') }}</strong>: {{ delivery.colony }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_town') }}</strong>: {{ delivery.town }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_country') }}</strong>: {{ delivery.country }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_federal_entity') }}</strong>: {{ delivery.federal_entity }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_postal_code') }}</strong>: {{ delivery.postal_code }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_phone') }}</strong>: {{ delivery.phone }}</div>
                    <div><strong>{{ $t('transaction_details.delivery_mobile') }}</strong>: {{ delivery.mobile }}</div>
                    <div v-if="delivery.hours || delivery.date">
                      <strong>{{ $t('transaction_details.delivery_date_hours') }}</strong>:
                      <span v-if="delivery.date">{{ delivery.date }}</span>
                      <span v-if="delivery.hours"> {{ delivery.hours }}</span>
                      <div>
                        <small>*{{ $t('transaction_details.delivery_date_help_text') }}</small>
                      </div>

                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <!-- <GoogleMap
                    :place="mapPlace"
                    class="w-100 h-100"
                  /> -->
                  <!-- <b-card class="border-0">
                    <div class="imgcontainer mb-0" style="--aspect-ratio:1/1;">
                      <img :src="organization.logo" alt="Logo" class="card-img p-2">
                    </div>
                  </b-card> -->
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>

        <div class="files-info column-max-item sep-4 mt-1">
          <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.documents_title') }}</h6>
          <div class="box-info border px-3 py-3 mx-0 my-0">
            <div v-for="(item, index) in files_config" :key="index">
              <InsuranceAuthorizationFileLoader
                v-if="type=='insuranceauthorization'"
                :i_uuid="organization_uuid"
                :pt_uuid="patient_uuid"
                :fileconfig="item"
                :insuranceauthorization_uuid="transaction_uuid"
                :read_only="true"
              />
              <InsuranceBridgeFileLoader
                v-if="type=='insurancebridge'"
                :i_uuid="organization_uuid"
                :pt_uuid="patient_uuid"
                :fileconfig="item"
                :insurancebridge_uuid="transaction_uuid"
                :read_only="true"
              />
              <InsuranceRefundFileLoader
                v-if="type=='insurancerefund'"
                :i_uuid="organization_uuid"
                :pt_uuid="patient_uuid"
                :fileconfig="item"
                :insurancerefund_uuid="transaction_uuid"
                :read_only="true"
              />
            </div>

            <div v-for="(item, index) in extra_files" :key="'2-' + index">
              <InsuranceAuthorizationFileLoader
                v-if="type=='insuranceauthorization'"
                :i_uuid="organization_uuid"
                :pt_uuid="patient_uuid"
                :fileconfig="item.fileconfig"
                :staticfiles="item.files"
                :insuranceauthorization_uuid="transaction_uuid"
                :read_only="true"
              />
              <InsuranceBridgeFileLoader
                v-if="type=='insurancebridge'"
                :i_uuid="organization_uuid"
                :pt_uuid="patient_uuid"
                :fileconfig="item.fileconfig"
                :staticfiles="item.files"
                :insurancebridge_uuid="transaction_uuid"
                :read_only="true"
              />
              <InsuranceRefundFileLoader
                v-if="type=='insurancerefund'"
                :i_uuid="organization_uuid"
                :pt_uuid="patient_uuid"
                :fileconfig="item.fileconfig"
                :staticfiles="item.files"
                :insurancerefund_uuid="transaction_uuid"
                :read_only="true"
              />
            </div>
          </div>
        </div>
      </div>


      <b-container>
        <b-row>
          <b-col class="" cols="12" lg="6" sm="12">
            <div class="text-center align-middle p-5" v-if="transaction.walletpass && transaction.recipe && transaction.recipe.pharmacy_pickup">
              {{ $t('transaction_details.wallet_info_text') }}
              <b-link :href="transaction.walletpass.data">
                <AddToAppleWalletEN class="wallet" />
              </b-link>
            </div>
          </b-col>
          <b-col class="" cols="12" lg="6" sm="12">
            <div class="text-center align-middle p-5" v-if="isiOSApp()">
              {{ $t('transaction_details.cda_info_text') }}
              <b-link @click="addCda()">
                <AddToAppleHealthKitEN class="cda" />
              </b-link>
            </div>
          </b-col>
          <b-col class="" cols="12" lg="6" sm="12">
            <div v-if="(!delivery && transaction.internal_orders && transaction.internal_orders.length > 0)">
              <div class="pl-3 pt-3" v-if="transaction.walletpass">
                {{ $t('transaction_details.alternative_qr_text') }}
              </div>
              <div class="pl-3 pt-3" v-else>
                {{ $t('transaction_details.no_alternative_qr_text') }}
              </div>

              <div v-for="(item, index) in transaction.internal_orders" :key="index">
                <!-- TODO: borrar esta basura y generar esto de forma decente -->
                <!-- TODO: enviar la url desde el backend -->
                <!-- Esto está así para dar solución rápida -->
                <!-- TODO: PERO QUE SE ARREGLE! EH! -->
                <img :src="api_uri + '/drugtransaction/guest/1/internalorder/' + item.uuid + '/prositeqrcode/'" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>


      <div class="drug-list mt-2 pt-1">
        <h6 class="pl-1 mt-4 text-uppercase font-weight-bold">{{ $t('transaction_details.drugs_title') }}</h6>
        <div class="border py-3 px-3" v-if="type=='insuranceauthorization'">
          <div v-for="(item_set, index) in items_set" v-bind:key="index">
            <InsuranceAuthorizationVDrugSummary
              v-if="item_set && item_set.drugs && item_set.amount"
              :items="item_set.drugs"
              :amount="new amount_object(item_set.amount)"
              :insurance="transaction.recipe.insurance"
            />
          </div>
        </div>
        <div class="border py-3 px-3" v-if="type=='insurancebridge'">
          <div v-for="(item_set, index) in items_set" v-bind:key="index">
            <InsuranceBridgeVDrugSummary
              v-if="item_set && item_set.drugs && item_set.amount"
              :items="item_set.drugs"
              :amount="new amount_object(item_set.amount)"
              :insurance="transaction.recipe.insurance"
            />
          </div>
        </div>
        <div class="border py-3 px-3" v-if="type=='insurancerefund'">
          <div v-for="(invoice, index) in items_set" v-bind:key="index">
            <h5 class="text-uppercase mt-5">
              {{ $t('transaction_details.invoice_druglist_title') }} {{ invoice.tax_folio_num }} {{ invoice.folio_num }}
            </h5>
            <InsuranceRefundVDrugSummary
              :items="invoice.item_list.drugs"
              :showprovider="false"
            />
          </div>
          <InsuranceRefundVDrugSummary
            :amount="new amount_object(transaction.amount)"
            :insurance="transaction.insurance"
            :showdrugtable="false"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import InsuranceAuthorizationTableList from "@/components/insuranceauthorization/InsuranceAuthorizationTableList";
import InsuranceBridgeTableList from "@/components/insurancebridge/InsuranceBridgeTableList";
import InsuranceRefundTableList from "@/components/insurancerefund/InsuranceRefundTableList";
import InsuranceAuthorizationFileLoader from "@/components/insuranceauthorization/InsuranceAuthorizationFileLoader";
import InsuranceBridgeFileLoader from "@/components/insurancebridge/InsuranceBridgeFileLoader";
import InsuranceRefundFileLoader from "@/components/insurancerefund/InsuranceRefundFileLoader";
import InsuranceAuthorizationVDrugSummary from "@/components/insuranceauthorization/VDrugsSummary";
import InsuranceBridgeVDrugSummary from "@/components/insurancebridge/VDrugsSummary";
import InsuranceRefundVDrugSummary from "@/components/insurancerefund/VDrugsSummary";
//import GoogleMap from "@/components/GoogleMap";
import get from "@/../node_modules/lodash/get";
import AddToAppleWalletEN from "@/static/apple_wallet_badge/en.svg";
import AddToAppleHealthKitEN from "@/static/apple_healthkit_badge/en.svg";

import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { Amount } from "@/lib/amount.js";
import { getApiUri } from "@/lib/api-config.js";
import { generate_cda } from "@/lib/cda.js";

export default {
  name: "transaction-detail",
  data() {
    return {
      amount_object: Amount,
      show: false,
      show_alternative_qr: false,
      transaction: undefined,
      files_config: undefined,
      organization: undefined,
      provider: undefined,
      delivery: undefined,
      items_set: undefined,
      api_uri: getApiUri(),
    };
  },
  props: {
    type: {
      default: "insuranceauthorization",
      type: String,
    },
    transaction_uuid: {
      default: undefined,
      type: String,
    },
    organization_uuid: {
      default: undefined,
      type: String,
    },
    patient_uuid: {
      default: undefined,
      type: String,
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        transaction_details: {
          recipe_link: "Recipe",
          save_button: "Save and send",
          drugs_title: "Drugs",
          delivery_address_title: "Delivery address",
          insurance_title: "Insurance",
          provider_title: "Provider",
          see_documents: "See documents",
          recipe_done: "Done",
          documents_title: "Documents",
          organization_name: "Name",
          organization_email: "Email",
          organization_country: "Country",
          organization_town: "Town",
          organization_postal_codel: "Postal code",
          organization_phone: "Phone",
          provider_name: "Name",
          provider_email: "Email",
          provider_country: "Country",
          provider_town: "Town",
          provider_postal_codel: "Postal code",
          provider_phone: "Phone",
          delivery_firstname: "Firstname",
          delivery_lastname: "Lastname",
          delivery_email: "Email",
          delivery_address: "Address",
          delivery_colony: "Colony",
          delivery_town: "Town",
          delivery_country: "Country",
          delivery_federal_entity: "Federal entity",
          delivery_postal_code: "Postal code",
          delivery_phone: "Phone",
          delivery_mobile: "Mobile",
          delivery_date_hours: "Delivery date",
          delivery_date_help_text:
            "The delivery date is subject to the date of authorization by the insurance company. The delivery process is done within 48 or 72 hours after authorization.",
          invoice_druglist_title: "Invoice",
          invoice_files: "Invoice",
          invoice_xml_files: "XML Invoice",
          recipe_files: "Recipe",
          coinsurance_files: "Coinsurance",
          deductible_files: "Deductible",
          alternative_qr_text:
            "Alternatively you can show this QR code to your pharmacist",
          no_alternative_qr_text: "Show this QR code to your pharmacist",
          wallet_info_text: "Use this pass card to receive updates",
          cda_info_text: "Click here to add the CDA to your Health app",
          cda_import_ok: "The CDA file was imported correctly",
          error: {
            retrieving_organization_info: "Cannot get organization info",
            create_draft_refund: "Cannot create initial refund data",
            saving_refund: "Cannot save refund",
            refound_not_found: "Refund not found",
            cda_import_error: "The CDA file couldn't be imported",
            healthkit_not_available:
              "HealthKit is not available on this device",
          },
        },
      },
    },
  },
  computed: {
    mapPlace: function () {
      return (
        (this.delivery.address || "") +
        " " +
        (this.delivery.town || "") +
        " " +
        (this.delivery.country || "")
      );
    },
    providers: function () {
      if (this.type == "insurancerefund") {
        return [];
      }

      var providers = [];
      var providers_index = {};

      this.items_set.forEach((item_set) => {
        item_set.drugs.forEach((drug) => {
          if (
            !drug.price ||
            !drug.price.provider ||
            !drug.price.provider.uuid
          ) {
            return;
          }
          if (providers_index[drug.price.provider.uuid]) {
            return;
          }
          providers.push(drug.price.provider);
          providers_index[drug.price.provider.uuid] = drug.price.provider.uuid;
        });
      });
      return providers;
    },
    extra_files: function () {
      if (this.type == "insurancebridge") {
        if (!this.transaction.recipe) {
          return [];
        }
        if (!this.transaction.recipe.recipe_file) {
          return [];
        }
        const res = [
          {
            fileconfig: {
              uuid: "static0",
              short_description: this.$t("transaction_details.recipe_files"),
            },
            files: [
              {
                file: this.transaction.recipe.recipe_file,
                file_original_filename: this.transaction.recipe
                  .recipe_file_original_filename,
                uuid: "recipe1",
              },
            ],
          },
        ];
        return res;
      }
      if (this.type == "insuranceauthorization") {
        if (!this.transaction.recipe) {
          return [];
        }
        if (!this.transaction.recipe.recipe_file) {
          return [];
        }
        const res = [
          {
            fileconfig: {
              uuid: "static0",
              short_description: this.$t("transaction_details.recipe_files"),
            },
            files: [
              {
                file: this.transaction.recipe.recipe_file,
                file_original_filename: this.transaction.recipe
                  .recipe_file_original_filename,
                uuid: "recipe1",
              },
            ],
          },
        ];
        return res;
      }
      if (this.type == "insurancerefund") {
        let normal_files = {
          fileconfig: {
            uuid: "static0",
            short_description: this.$t("transaction_details.invoice_files"),
          },
          files: [],
        };

        let xml_files = {
          fileconfig: {
            uuid: "static1",
            short_description: this.$t("transaction_details.invoice_xml_files"),
          },
          files: [],
        };

        this.items_set.forEach((element, key) => {
          if (element.invoice_file) {
            normal_files.files.push({
              file: element.invoice_file,
              file_original_filename: element.invoice_file_original_filename,
              uuid: "invoicefile" + key,
            });
          }

          if (element.invoice_file_xml) {
            xml_files.files.push({
              file: element.invoice_file_xml,
              file_original_filename:
                element.invoice_file_xml_original_filename,
              uuid: "invoicexmlfile" + key,
            });
          }
        });

        let res = [];
        if (normal_files.files && normal_files.files.length > 0) {
          res.push(normal_files);
        }
        if (xml_files.files && xml_files.files.length > 0) {
          res.push(xml_files);
        }

        if (this.transaction.coinsurance_payment_file) {
          res.push({
            fileconfig: {
              uuid: "static0",
              short_description: this.$t(
                "transaction_details.coinsurance_files"
              ),
            },
            files: [
              {
                file: this.transaction.coinsurance_payment_file,
                file_original_filename: this.transaction
                  .coinsurance_payment_file_original_filename,
                uuid: "coinsurance",
              },
            ],
          });
        }

        if (this.transaction.deductible_payment_file) {
          res.push({
            fileconfig: {
              uuid: "static0",
              short_description: this.$t(
                "transaction_details.deductible_files"
              ),
            },
            files: [
              {
                file: this.transaction.deductible_payment_file,
                file_original_filename: this.transaction
                  .deductible_payment_file_original_filename,
                uuid: "deductible",
              },
            ],
          });
        }

        if (this.transaction.recipe_file) {
          res.push({
            fileconfig: {
              uuid: "static0",
              short_description: this.$t("transaction_details.recipe_files"),
            },
            files: [
              {
                file: this.transaction.recipe_file,
                file_original_filename: this.transaction
                  .recipe_file_original_filename,
                uuid: "recipe_file",
              },
            ],
          });
        }

        return res;
      }
      return undefined;
    },
    ...mapGetters("drugtransaction", [
      "getConfigFilesByIUUID",
      "getRefundConfigFilesByIUUID",
      "getBridgeConfigFilesByIUUID",
    ]),
    ...mapState({
      latest_params: (state) => state.routehelper.latest_params,
    }),
  },
  mounted: function () {
    this.loadConfigFiles();
    this.loadTransaction();
  },
  methods: {
    ...mapActions({
      REQUEST_PATIENT_INSURANCE_INSURANCEAUTHORIZATION:
        "insuranceauthorization/REQUEST_PATIENT_INSURANCE_INSURANCEAUTHORIZATION",
      REQUEST_PATIENT_INSURANCE_INSURANCEBRIDGE:
        "insurancebridge/REQUEST_PATIENT_INSURANCE_INSURANCEBRIDGE",
      REST_INSURANCE_INSURANCEREFUND_READ:
        "insurancerefund/REST_INSURANCE_INSURANCEREFUND_READ",
    }),
    imageLoadError(evt) {
      evt.srcElement.src = require("@/static/fallback_client.png");
    },
    loadTransaction() {
      if (this.type == "insuranceauthorization") {
        this.loadOneAuthorization();
      } else if (this.type == "insurancerefund") {
        this.loadOneRefund();
      } else if (this.type == "insurancebridge") {
        this.loadOneBridge();
      }
    },
    loadOneRefund() {
      this.REST_INSURANCE_INSURANCEREFUND_READ({
        i_uuid: this.organization_uuid,
        insurancerefund_uuid: this.transaction_uuid,
      }).then(
        (response) => {
          if (!response) {
            return this.refoundNotFound();
          }
          this.items_set = response.data.invoices;
          this.transaction = response.data;
          this.organization = response.data.insurance_organization;
          this.show = true;
        },
        () => {}
      );
    },
    loadOneAuthorization() {
      this.REQUEST_PATIENT_INSURANCE_INSURANCEAUTHORIZATION({
        pt_uuid: this.patient_uuid,
        i_uuid: this.organization_uuid,
        insuranceauthorization_uuid: this.transaction_uuid,
      }).then(
        (response) => {
          this.transaction = response.data;
          this.organization = response.data.insurance;
          if (response.data.recipe && response.data.recipe.drugs) {
            // este componente soporta multiples recetas, por lo que
            // el listado de productos se duplica por receta.
            // necesitamos almacenarlo en un array.
            this.items_set = [response.data.recipe];
          } else {
            this.items_set = [{ drugs: [] }];
          }

          this.delivery = {
            patient_uuid: get(response.data, "recipe.patient_address.uuid"),
            firstname: get(
              response.data,
              "recipe.patient_address.firstname",
              ""
            ),
            lastname: get(response.data, "recipe.patient_address.lastname", ""),
            email: get(response.data, "recipe.patient_address.email", ""),
            address: get(response.data, "recipe.delivery_address.address"),
            colony: get(response.data, "recipe.delivery_address.colony"),
            town: get(response.data, "recipe.delivery_address.town"),
            country: get(response.data, "recipe.delivery_address.country"),
            hours: get(response.data, "recipe.delivery_address.hours"),
            date: get(response.data, "recipe.delivery_address.date"),
            federal_entity: get(
              response.data,
              "recipe.delivery_address.federal_entity"
            ),
            postal_code: get(
              response.data,
              "recipe.delivery_address.postal_code"
            ),
            phone: get(response.data, "recipe.delivery_address.phone"),
            mobile: get(response.data, "recipe.delivery_address.mobile"),
          };
          this.show = true;
          if (!response) {
            this.refoundNotFound();
          }
        },
        (err) => {
          this.$sentry({ capture: err });
          //this.$router.push({ name: "fallback-error-page" });
        }
      );
    },
    loadOneBridge() {
      this.REQUEST_PATIENT_INSURANCE_INSURANCEBRIDGE({
        pt_uuid: this.patient_uuid,
        i_uuid: this.organization_uuid,
        insurancebridge_uuid: this.transaction_uuid,
      }).then(
        (response) => {
          this.transaction = response.data;
          this.organization = response.data.insurance;
          if (response.data.recipe && response.data.recipe.drugs) {
            // este componente soporta multiples recetas, por lo que
            // el listado de productos se duplica por receta.
            // necesitamos almacenarlo en un array.
            this.items_set = [response.data.recipe];
          } else {
            this.items_set = [{ drugs: [] }];
          }

          this.delivery = {
            patient_uuid: get(response.data, "recipe.patient_address.uuid"),
            firstname: get(
              response.data,
              "recipe.patient_address.firstname",
              ""
            ),
            lastname: get(response.data, "recipe.patient_address.lastname", ""),
            email: get(response.data, "recipe.patient_address.email", ""),
            address: get(response.data, "recipe.delivery_address.address"),
            colony: get(response.data, "recipe.delivery_address.colony"),
            town: get(response.data, "recipe.delivery_address.town"),
            country: get(response.data, "recipe.delivery_address.country"),
            hours: get(response.data, "recipe.delivery_address.hours"),
            date: get(response.data, "recipe.delivery_address.date"),
            federal_entity: get(
              response.data,
              "recipe.delivery_address.federal_entity"
            ),
            postal_code: get(
              response.data,
              "recipe.delivery_address.postal_code"
            ),
            phone: get(response.data, "recipe.delivery_address.phone"),
            mobile: get(response.data, "recipe.delivery_address.mobile"),
          };
          this.show = true;
          if (!response) {
            this.refoundNotFound();
          }
        },
        (err) => {
          this.$sentry({ capture: err });
          //this.$router.push({ name: "fallback-error-page" });
        }
      );
    },
    refoundNotFound() {
      this.show = true;
      this.mnotify_error({
        text: this.$t("insuranceauthorization_home.error.refound_not_found"),
      });
      this.$router.push({
        name: "nopatient_insuranceauthorization_insurance_selection",
        params: {
          pt_uuid: this.latest_params.pt_uuid,
        },
      });
    },
    loadConfigFiles() {
      if (this.type == "insuranceauthorization") {
        this.loadInsuranceAuthorizationConfigFiles();
      } else if (this.type == "insurancerefund") {
        this.loadInsuranceRefundConfigFiles();
      } else if (this.type == "insurancebridge") {
        this.loadInsuranceBridgeConfigFiles();
      }
    },
    loadInsuranceRefundConfigFiles() {
      this.files_config = this.getRefundConfigFilesByIUUID(
        this.$route.params.i_uuid
      );
      if (!this.files_config) {
        this.$store
          .dispatch(
            "drugtransaction/LOAD_INSURANCE_INSURANCEREFUND_CONFIG_FILES",
            {
              i_uuid: this.$route.params.i_uuid,
            }
          )
          .then(
            () => {
              this.files_config = this.getRefundConfigFilesByIUUID(
                this.$route.params.i_uuid
              );
            },
            (err) => {
              this.$sentry({ capture: err });
              this.$router.push({ name: "fallback-error-page" });
            }
          );
      }
    },
    loadInsuranceAuthorizationConfigFiles() {
      this.files_config = this.getConfigFilesByIUUID(this.organization_uuid);
      if (!this.files_config) {
        this.$store
          .dispatch(
            "drugtransaction/LOAD_INSURANCE_INSURANCEAUTHORIZATION_CONFIG_FILES",
            {
              i_uuid: this.organization_uuid,
            }
          )
          .then(
            () => {
              this.files_config = this.getConfigFilesByIUUID(
                this.organization_uuid
              );
            },
            (err) => {
              this.$sentry({ capture: err });
              //  this.$router.push({ name: "fallback-error-page" });
            }
          );
      }
    },
    loadInsuranceBridgeConfigFiles() {
      this.files_config = this.getBridgeConfigFilesByIUUID(
        this.organization_uuid
      );
      if (!this.files_config) {
        this.$store
          .dispatch(
            "drugtransaction/LOAD_INSURANCE_INSURANCEBRIDGE_CONFIG_FILES",
            {
              i_uuid: this.organization_uuid,
            }
          )
          .then(
            () => {
              this.files_config = this.getBridgeConfigFilesByIUUID(
                this.organization_uuid
              );
            },
            (err) => {
              this.$sentry({ capture: err });
              //  this.$router.push({ name: "fallback-error-page" });
            }
          );
      }
    },
    addCda() {
      let created_date = this.transaction.created_date;
      created_date = created_date.match(/(.*?)\./i)[1];
      created_date = created_date.replace(/[-T:]/g, "");

      const info = {
        title: "GlobalPDV CDA",
        uuid: this.transaction.human_code,
        date: created_date,
        patient: {
          uuid: this.delivery.patient_uuid,
          name: this.delivery.firstname,
          surname: "",
          family: this.delivery.lastname,
        },
        organization: this.organization,
        //"providers": this.providers,
        files_config: this.files_config,
        extra_files: this.extra_files,
        items_set: this.items_set,
      };
      const xml = generate_cda(this, info);

      window.bridge.callHandler(
        "addCda",
        {
          xml: xml,
        },
        (response) => {
          if (response.status) {
            this.mnotify_success({
              text: this.$t("transaction_details.cda_import_ok"),
            });
          } else {
            if (response.healthkit_available) {
              // Error
              this.$sentry({ capture: response.msg });
              this.mnotify_error({
                text: this.$t("transaction_details.error.cda_import_error"),
              });
            } else {
              this.mnotify_warn({
                text: this.$t(
                  "transaction_details.error.healthkit_not_available"
                ),
              });
            }
          }
        }
      );
    },
  },
  components: {
    InsuranceAuthorizationFileLoader,
    InsuranceBridgeFileLoader,
    InsuranceRefundFileLoader,
    InsuranceAuthorizationVDrugSummary,
    InsuranceBridgeVDrugSummary,
    InsuranceAuthorizationTableList,
    InsuranceBridgeTableList,
    //GoogleMap,
    AddToAppleWalletEN,
    AddToAppleHealthKitEN,
    InsuranceRefundTableList,
    InsuranceRefundVDrugSummary,
  },
};
</script>


<style lang="scss" scoped>
.box-info {
  height: calc(100% - 3rem);
}
.cda,
.wallet {
  width: 100%;
  height: 80px;
}
</style>
