<template>
  <b-card class="scoped-card" :title="title">
    <slot></slot>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.card.scoped-card {
  @include card-form-box;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.7);
}

.card-title {
  text-align: center;
}

.card-body {
  @include media-breakpoint-up(md) {
    padding: 2rem 3rem;
  }
}

</style>
