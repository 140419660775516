import Noty from "noty";
/****************************************
  PdvNotifications
  pdv-notifications.js
  (C) Develatio Technologies S.L. 2018
  (C) Develatio Technologies S.L. 2019
*****************************************

Wrap for notification system to easily switch between notification libs.
Dependence: vue-notification
****************************************/

function copyObj(aobj) {
  let bobj = {};
  let key;
  for (key in aobj) {
    bobj[key] = aobj[key];
  }
  return bobj;
}

const PdvNotifications = {
  // eslint-disable-next-line
  install(Vue, options) {
    Vue.mixin({
      methods: {
        mnotify(conf) {
          this.$nextTick(() => {
            if (typeof conf["group"] == "undefined") {
              conf["group"] = "main";
            }
            if (Array.isArray(conf["text"])) {
              var these = this;
              conf["text"].forEach(function(element) {
                let conf_part = copyObj(conf);
                conf_part["text"] = element;
                these.$notify(conf_part);
              });
            } else {
              this.$notify(conf);
            }
            if (conf["debug"]) {
              throw new Error(conf["debug"]);
            }
          });
        },
        mnotify_warn(conf) {
          conf["type"] = "warn";
          conf["duration"] = 60000;
          this.mnotify(conf);
        },
        mnotify_error(conf) {
          conf["type"] = "error";
          conf["duration"] = -1;
          this.mnotify(conf);
        },
        mnotify_success(conf) {
          conf["type"] = "success";
          this.mnotify(conf);
        },
        mnotify_center_retry(conf) {
          var n = new Noty({
            theme: "metroui",
            type: "error",
            modal: true,
            layout: "center",
            text: conf["text"],
            buttons: [
              Noty.button(
                "Retry",
                "btn btn-primary btn-lg center-block w-100",
                () => {
                  this.$router.go();
                  n.close();
                }
              ),
            ],
          }).show();
        },
      },
    });
  },
};

export default PdvNotifications;
