<template>
  <VLayout
    upline
    :title="$t('account_patient_customer.title')"
    :subtitle="$t('account_patient_customer.subtitle')"
    :bgImage="false"
  >
    <PatientCustomerDataForm class="mb-3" />
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import PatientCustomerDataForm from "@/components/PatientCustomerDataForm";

export default {
  components: {
    VLayout,
    PatientCustomerDataForm,
  },
  fallbackI18n: {
    messages: {
      und: {
        account_patient_customer: {
          title: "Major medical health insurance data",
          subtitle: "Please fill in the data, the fields marked with * are essential",
        },
      },
    },
  },
  mounted: function() {
    this.putBreadCrumb();
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
        {
          langkey: "breadcrumb.mmilist",
          to: { name: "account_major_medical_insurance_list" },
        },
        {
          langkey: "breadcrumb.patient_customer_data",
        },
      ]);
    },
  },
};
</script>
