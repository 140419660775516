/****************************************
  PlatformDetect
  platform-detect.js
  (C) Develatio Technologies S.L. 2018
*****************************************

Detects on what platform we're currently running
****************************************/

import PlatformDetectMixin from "./platform-detect-mixin";

export default {
  // eslint-disable-next-line
  install(Vue) {
    Vue.mixin(PlatformDetectMixin);
  },
};
