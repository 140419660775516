import { initial_configuration_create } from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    cookies_accepted:
      JSON.parse(localStorage.getItem("cookies_accepted")) || false,
    raw_breadcrumb: undefined,
    vue_bootstrap_breadcrumb: [],
    show_pdv_authorization_guide: undefined,
    show_pdv_refund_guide: undefined,
    allow_pdv_coadedpayment_guide: undefined,
    country_fields: [
      { value: "MX", langkey: "global.country_field_mx" },
      { value: "US", langkey: "global.country_field_us" },
      { value: "ES", langkey: "global.country_field_es" },
    ],
  },
  actions: {
    LOAD_INITIAL_CONFIG: ({ commit, dispatch }, payload) => {
      // se se ha indicado i18nbackend, se setea. Se permite que este campo
      // sea vacío ya que esto sólo debería setearse al arrancar la app (app.js)
      // y en las subsiguientes llamadas, se sobreentiende que ya está seteado
      // y no es necesario volver a hacerlo.
      if (payload.i18nbakend) {
        commit("i18nmap/SET_I18N_BACKEND", payload.i18nbakend, { root: true });
      }
      return new Promise((resolve, reject) => {
        initial_configuration_create({
          $config: axconf(),
          role: "guest",
          roleUuid: "1",
          site: process.env.VUE_APP_SITE_CONTEXT,
          data: payload.data,
        }).then(
          response => {
            let promises = [];

            // lang data
            promises.push(
              dispatch("i18nmap/SET_INITIAL_LANG_DATA", response.data, {
                root: true,
              })
            );

            // country detection
            promises.push(
              dispatch("auth/SET_COUNTRY", response.data.country, {
                root: true,
              })
            );

            // currency rates
            promises.push(
              dispatch("rates/SET_RATES", response.data.rates, { root: true })
            );

            // if has sgmm account
            promises.push(
              dispatch("organization/SET_HAS_SGMM", response.data.has_sgmm, {
                root: true,
              })
            );
            promises.push(
              dispatch(
                "organization/SET_HAS_AUTHORIZATION_INSURANCES",
                response.data.has_authorization_insurances,
                {
                  root: true,
                }
              )
            );
            promises.push(
              dispatch(
                "organization/SET_HAS_BRIDGE_INSURANCES",
                response.data.has_bridge_insurances,
                {
                  root: true,
                }
              )
            );
            promises.push(
              dispatch(
                "organization/SET_HAS_REFUND_INSURANCES",
                response.data.has_refund_insurances,
                {
                  root: true,
                }
              )
            );

            commit("MUTATE_SHOW_GUIDE", response.data);
            commit("MUTATE_SHOW_COADEDPAYMENT", response.data);
            return Promise.all(promises)
              .then(
                () => {
                  resolve(response);
                },
                err => {
                  reject(err);
                }
              )
              .catch(err => {
                reject(err);
              });
          },
          err => {
            reject(err);
          }
        );
      });
    },
    LANGUAGE: ({ commit }, lang) => {
      commit("SET_LANGUAGE", { lang });
    },
    CHANGE_BREADCRUMB: ({ commit }, breadcrumb) => {
      commit("MUTATE_BREADCRUMB", breadcrumb);
    },
  },
  mutations: {
    MUTATE_BREADCRUMB: (state, payload) => {
      Vue.set(state, "raw_breadcrumb", payload);
    },
    MUTATE_ACCEPT_COOKIES: (state, payload) => {
      Vue.set(state, "cookies_accepted", payload);
      localStorage.setItem("cookies_accepted", payload);
    },
    MUTATE_SHOW_COADEDPAYMENT: (state, payload) => {
      Vue.set(
        state,
        "allow_pdv_coadedpayment_guide",
        payload.allow_pdv_coadedpayment_guide
      );
    },
    MUTATE_SHOW_GUIDE: (state, payload) => {
      Vue.set(
        state,
        "show_pdv_authorization_guide",
        payload.show_pdv_authorization_guide
      );
      Vue.set(state, "show_pdv_refund_guide", payload.show_pdv_refund_guide);
    },
  },
};
