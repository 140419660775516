<template>
  <v-layout navigation="">
    <div v-if="show && my_pharmacies">
      <h4>Pharmacies</h4>
      <b-card-group
        columns
        class="mb-3 card-group-3 card-columns-3 column-gap-2"
      >
        <b-card
          v-for="(org,index) in my_pharmacies"
          :key="index"
          footer-tag="footer"
          class="clickable"
        >

          <p class="card-text selectable">
            <strong>{{ $t('account_organizations_list.public_name') }}</strong>: {{ org.public_name }}
          </p>
          <p class="card-text selectable">
            <strong>{{ $t('account_organizations_list.address') }}</strong>: {{ org.address }}
          </p>

          <v-token-form :o_uuid="org.uuid" :connected="false" />

        </b-card>
      </b-card-group>
    </div>


    <div v-if="show && my_insurances">
      <h4>Insurances</h4>
      <b-card-group
        columns
        class="mb-5 card-group-5 card-columns-5 column-gap-2"
      >
        <b-card
          v-for="(org,index) in my_insurances"
          :key="index"
          footer-tag="footer"
          class="clickable"
        >

          <p class="card-text selectable">
            <strong>{{ $t('account_organizations_list.public_name') }}</strong>: {{ org.public_name }}
          </p>
          <p class="card-text selectable">
            <strong>{{ $t('account_organizations_list.address') }}</strong>: {{ org.address }}
          </p>

          <v-token-form :o_uuid="org.uuid" :connected="false" />

        </b-card>
      </b-card-group>
    </div>

  </v-layout>
</template>

<script>
import VLayout from "@/layouts/Default";
import { mapState } from "vuex";

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      my_insurances: state => state.organization.my_insurances,
      my_pharmacies: state => state.organization.my_pharmacies,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        account_organizations_list: {
          enter_button: "Enter",
          public_name: "Public name",
          address: "Address",
          error: {
            retrieving_my:
              "Cannot retrieve your organizations. Try again later.",
          },
        },
      },
    },
  },
  mounted: function() {
    this.fetchOrganizations();
  },
  methods: {
    fetchOrganizations() {
      this.$store.dispatch("organization/MY").then(
        () => {
          this.show = true;
        },
        err => {
          this.mnotify_error({
            text: this.$t("account_organizations_list.error.retrieving_my"),
            debug: err,
          });
        }
      );
    },
  },

  components: {
    VLayout,
  },
};
</script>

<style>
</style>
