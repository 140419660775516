<template>
  <div class="v-bootstrap-search-input">
    <input
      class="form-control"
      type="text"
      :placeholder="placeholder"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      v-on:submit="onSubmit($event)"
      :required="false"
    />
    <button v-on:click="onSubmit($event)"><i class="fa fa-search"></i></button>
  </div>
</template>

<script>
export default {
  name: "v-bootstrap-search-input",
  data() {
    return {};
  },
  props: {
    "value": {
      "type": String,
      default: undefined,
    },
    "placeholder": {
      "type": String,
      default: undefined,
    },
  },
  methods: {
    onChange(evt) {
      this.$emit("change", evt.target.value);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("submit", evt);
    },
  },
};
</script>


<style lang="scss" scoped>
$line-size: $input-height;
.v-bootstrap-search-input input[type="text"] {
  float: left;
  padding-right: calc(#{$input-height-inner} + #{$input-height-border} + 5px);
}

.v-bootstrap-search-input button {
  float: left;
  width: $line-size;
  height: $line-size;
  margin-left: calc(0px - #{$input-height-inner} - #{$input-height-border});
  background: $primary;
  color: white;
  font-size: $font-size-base;
  border: 0 !important;
  cursor: pointer;
}

.v-bootstrap-search-input::after {
  content: "";
  clear: both;
  display: table;
}
</style>
