<template>
  <div class="header">
    <div class="top-nav">
      <TheHeadingNavigationBar />
    </div>
    <div class="header-middle py-4">
      <b-container fluid class="bv-example-row">
        <b-row>
          <b-col class="px-1 d-none d-sm-none d-md-block" cols="5">
            <div class="logowrap">
              <router-link :to="{name: 'home'}">
                <GlobalPDVLogo alt="Global PDV Logo" />
              </router-link>
            </div>
          </b-col>
          <b-col class="px-1 d-none d-sm-none d-md-block" cols="7" align-self="end">
            <div class="contact text-right">
              <a target="_blank" :href="helpUrl">
                <PhoneLogo alt="Phone" />
                {{ $t('the_heading.need_help') }}
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import TheHeadingNavigationBar from "@/components/TheHeadingNavigationBar";
import GlobalPDVLogo from "@/static/global_pdv_logo.svg";
import PhoneLogo from "@/static/logo_phone.svg";

export default {
  name: "the-heading",
  data() {
    return {
      helpUrl: "https://api.whatsapp.com/send?phone=" + this.$phoneHelp,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        the_heading: {
          need_help: "Need help?",
        },
      },
    },
  },
  components: {
    TheHeadingNavigationBar,
    GlobalPDVLogo,
    PhoneLogo,
  },
};
</script>

<style lang="scss" scoped>
.top-nav {
  height: 99%;
  padding-left: 0%;
  padding-right: 0%;
  background-color: $corp-color1;
}

.logowrap svg {
  height: 6rem;
  padding: 0px;
  margin: 0px;
}

.header-middle {
  width: 90%;
  max-width: $content-max-width;
  position: relative;
  margin: 0 auto;
}

.contact svg {
  width: 2.5rem;
}
.contact a{
  color: $gray-600;
  text-decoration: none;
}
</style>
