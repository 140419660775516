<template>
  <div>
    <b-form @submit="onSubmit" v-if="show">
      <b-alert variant="success" :show="sucessMailSend">{{ $t('recover_pass.mail_sended') }}</b-alert>
      <b-alert variant="warning" :show="sucessMailAlreadySend">{{ $t('recover_pass.mail_sended_already') }}</b-alert>
      <b-alert variant="danger" :show="sucessMailAlreadyDangerSend">{{ $t('recover_pass.mail_sended_already_danger') }}</b-alert>
      <b-form-group
        id="group1"
        class="text-left"
        label-for="mailinput"
        :invalid-feedback="form_invalid_feedback.email"
        :state="form_state.email"
      >
        <div class="d-flex flex-column-reverse">
          <b-form-input
            id="mailinput"
            type="text"
            v-model.trim="form.email"
            required
            :state="form_state.email"
            :placeholder="$t('recover_pass.email_placeholder')"
          >
          </b-form-input>
          <label class="col-form-label pt-0" for="mailinput">{{ $t('recover_pass.email_label') }}</label>
        </div>
      </b-form-group>

      <b-row class="text-left" no-gutters>
        <b-col cols="12">
          <b-button type="submit" size="sm" variant="tertiary">
            {{ $t('recover_pass.submit_button') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-left mt-5" no-gutters>
        <b-col cols="12">
          <div>
            <router-link :to="{name: 'login'}">{{ $t('recover_pass.back_to_login') }}</router-link>
          </div>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>


<script>
export default {
  name: "recover-pass",
  data() {
    return {
      sucessMailSend: false,
      sucessMailAlreadySend: false,
      sucessMailAlreadyDangerSend: false,
      form: {
        email: "",
      },
      form_invalid_feedback: {},
      form_state: {},
      show: true,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        recover_pass: {
          email_label: "Email",
          email_placeholder: "Enter email",
          submit_button: "Request",
          back_to_login: "Back to login",
          mail_sended: "We have sent an email with instructions.",
          mail_sended_already:
            "We have already sent an email with instructions. Please, check your inbox.",
          mail_sended_already_danger:
            "Sorry for the inconvenience. We have already sent an email with instructions. Contact with our team if you have not received it.",
        },
      },
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$recaptcha("recover_pass_form").then(
        token => {
          this.$store.dispatch("auth/RECOVER_PASS", {
            ...this.form,
            recaptcha_token: String(token),
          }).then(
            response => {
              this.showFeedback({ state: true, response: response });
              this.$emit("recover:submit", { state: true, response: response });
            },
            err => {
              this.$emit("recover:submit", {
                state: false,
                response: err.response,
              });
              this.showFeedback({ state: false, response: err.response });
              this.showResponseError(err);
              this.putFormFeedback(err.response);
            }
          );
        },
        err => {
          this.$sentry({ capture: err });
          this.$emit("recover:submit", {
            state: false,
            response: err.response,
          });
          this.showFeedback({ state: false, response: err.response });
          this.showResponseError(err);
          this.putFormFeedback(err.response);
        }
      );
    },
    showFeedback(data) {
      // danger message
      if (data.state && this.sucessMailAlreadySend) {
        this.sucessMailAlreadySend = false;
        this.sucessMailSend = false;
        this.sucessMailAlreadyDangerSend = true;
        // warning message
      } else if (data.state && this.sucessMailSend) {
        this.sucessMailAlreadySend = true;
        this.sucessMailSend = false;
        this.sucessMailAlreadyDangerSend = false;
        // success message
      } else if (data.state && !this.sucessMailAlreadyDangerSend) {
        this.sucessMailSend = true;
        this.sucessMailAlreadyDangerSend = false;
        this.sucessMailAlreadySend = false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
input + label {
  visibility: hidden;
  opacity: 0;
  font-size: 1rem;
}

input:focus {
  &::placeholder {
    color: transparent;
  }

  + label {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
    color: $corp-color1;
  }
}

input#mailinput {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 0px;
}

/deep/ label::before {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none;
}

input.form-control,
input.form-control:focus {
  border: none;
  box-shadow: none;
}

input#mailinput::placeholder {
  color: $text-main-color;
  font-style: normal;
  font-size: $font-size-base;
  transition: color 0.2s;
}
input#mailinput:focus::placeholder {
  color: transparent;
}
</style>
