<template>
  <VLayout :showbreadcrumbs="false" fullwidth>
    <VHomeImgJumbo>
      <template v-slot:left>
        <iframe 
          class="lvideo"
          height="315"
          src="https://www.youtube.com/embed/cyQajdu3c0Q?controls=0&autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </template>
      <template v-slot:right>
        <VHomeImgJumboCard :title="$t('login.box_title')">
          <VLogin />
        </VHomeImgJumboCard>
      </template>
    </VHomeImgJumbo>
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import VLogin from "@/components/Login";
import VHomeImgJumbo from "@/components/VHomeImgJumbo";
import VHomeImgJumboCard from "@/components/VHomeImgJumboCard";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted: function() {
    this.$nextTick(function() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "userhome" });
      }
    });
  },
  fallbackI18n: {
    messages: {
      und: {
        login: {
          box_title: "Login",
        },
      },
    },
  },
  components: {
    VLayout,
    VLogin,
    VHomeImgJumbo,
    VHomeImgJumboCard,
  },
};
</script>

<style lang="scss" scoped>
.lvideo {
  max-width: 560px;
  width: 100%;
}
</style>
