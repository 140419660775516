import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home";
import UserHome from "../views/UserHome";
import AccountNewPharmacy from "../views/AccountNewPharmacy";
import InsuranceAuthorizationInsuranceSelection from "../views/insuranceauthorization/InsuranceAuthorizationInsuranceSelection";
import InsuranceBridgeInsuranceSelection from "../views/insurancebridge/InsuranceBridgeInsuranceSelection";
import InsuranceRefundInsuranceSelection from "../views/insurancerefund/InsuranceRefundInsuranceSelection";
import InsuranceAuthorizationHome from "../views/insuranceauthorization/InsuranceAuthorizationHome";
import InsuranceRefundHome from "../views/insurancerefund/InsuranceRefundHome";
import InsuranceAuthorizationRecipe from "../views/insuranceauthorization/InsuranceAuthorizationRecipe";
import InsuranceBridgeRecipe from "../views/insurancebridge/InsuranceBridgeRecipe";
import InsuranceRefundInvoice from "../views/insurancerefund/InsuranceRefundInvoice";
import InsuranceAuthorizationPatientSelection from "../views/insuranceauthorization/InsuranceAuthorizationPatientSelection";
import InsuranceBridgeHome from "../views/insurancebridge/InsuranceBridgeHome";
import RecoverPass from "../views/RecoverPass";
import RecoverPassConfirm from "../views/RecoverPassConfirm";
import RegisterConfirm from "../views/RegisterConfirm";
// import AccountNewPatientData from "../views/AccountNewPatientData";
import AccountHome from "../views/AccountHome";
import AccountOrganizationsList from "../views/AccountOrganizationsList";
import PrivacyPolicy from "../views/PrivacyPolicy";
import Cookies from "../views/Cookies";
import TermsConditions from "../views/TermsConditions";
import Login from "../views/Login";
import Register from "../views/Register";
// import PatientData from "../views/PatientData";
import AccountPatientCustomerData from "../views/AccountPatientCustomerData";
import AccountInsuranceAuthorizationDetail from "../views/AccountInsuranceAuthorizationDetail";
import AccountInsuranceBridgeDetail from "../views/AccountInsuranceBridgeDetail";
import AccountInsuranceRefundDetail from "../views/AccountInsuranceRefundDetail";
import AccountHistory from "../views/AccountHistory";
import AccountMajorMedicalInsurance from "../views/AccountMajorMedicalInsurance";
import AddressList from "../views/AddressList";
import SocialAuth from "../views/SocialAuth";
import AddressForm from "../views/AddressForm";
import ContactForm from "../views/ContactForm";
import FallBackErrorPage from "../views/FallBackErrorPage";
import InsuranceRiskPayment from "../views/insuranceriskpayment/InsuranceRiskPayment";
import InsuranceRiskPaymentList from "../views/insuranceriskpayment/InsuranceRiskPaymentList";
import Faq from "../views/Faq";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/error",
      name: "fallback-error-page",
      component: FallBackErrorPage,
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/cookies",
      name: "cookies",
      component: Cookies,
    },
    {
      path: "/terms-conditions",
      name: "terms-conditions",
      component: TermsConditions,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        auth: false,
      },
    },
    {
      path: "/login-error",
      name: "login-error",
      component: Login,
      props: route => ({
        errorCode: route.query.code,
      }),
      meta: {
        auth: false,
        error: true,
      },
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        auth: false,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: Faq,
      meta: {
        auth: false,
      },
    },
    {
      path: "/recover/",
      name: "recover_pass",
      component: RecoverPass,
      meta: {
        auth: false,
      },
    },
    {
      path: "/recover/confirm/:uid/:token",
      name: "recover_pass_confirm",
      component: RecoverPassConfirm,
      meta: {
        auth: false,
      },
    },
    {
      path: "/register/confirm/:uid/:token",
      name: "register_confirm",
      component: RegisterConfirm,
      meta: {
        auth: false,
      },
    },
    {
      path: "/userhome",
      name: "userhome",
      component: UserHome,
      meta: {
        auth: true,
      },
    },
    // {
    //   path: "/patientdata/:pt_uuid?",
    //   name: "patientdata",
    //   component: PatientData,
    //   meta: {
    //     auth: true
    //   }
    // },
    {
      path: "/account/patient_customer_data/:patient_customer_uuid?",
      name: "patient_customer_data",
      component: AccountPatientCustomerData,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patientselector",
      name: "patientselector",
      component: InsuranceAuthorizationPatientSelection,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patientselector_addresses",
      name: "patientselector_addresses",
      component: InsuranceAuthorizationPatientSelection,
      meta: {
        auth: true,
        title: "home_user.my_addresses",
        next_route_name: "addresses",
      },
    },
    {
      path: "/account/new_pharmacy",
      name: "pharmacy_new",
      meta: { auth: true },
      component: AccountNewPharmacy,
    },
    // {
    //   path:
    //     "/patient/:pt_uuid/insurance/:i_uuid/insuranceauthorization/:insuranceauthorization_uuid",
    //   name: "insuranceauthorization_home",
    //   meta: { auth: true },
    //   component: InsuranceAuthorizationHome
    // },
    {
      path:
        "/nopatient/insurance/:i_uuid/insuranceauthorization/:insuranceauthorization_uuid",
      name: "nopatient_insuranceauthorization_home",
      meta: { auth: true },
      component: InsuranceAuthorizationHome,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/insurancebridge/:insurancebridge_uuid",
      name: "nopatient_insurancebridge_home",
      meta: { auth: true },
      component: InsuranceBridgeHome,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/insurancerefund/:insurancerefund_uuid",
      name: "nopatient_insurancerefund_home",
      meta: { auth: true },
      component: InsuranceRefundHome,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/insurancerefund/:insurancerefund_uuid/with_promo",
      name: "nopatient_insurancerefund_home_with_promo",
      meta: { auth: true },
      component: InsuranceRefundHome,
      props: {
        showPromo: true,
      },
    },
    {
      path:
        "/patient/:pt_uuid/insurance/:i_uuid/insuranceauthorization/:insuranceauthorization_uuid/recipe/:r_uuid",
      name: "insuranceauthorization_recipe",
      meta: { auth: true },
      component: InsuranceAuthorizationRecipe,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/insuranceauthorization/:insuranceauthorization_uuid/recipe/:r_uuid?",
      name: "nopatient_insuranceauthorization_recipe",
      meta: { auth: true },
      component: InsuranceAuthorizationRecipe,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/insurancebridge/:insurancebridge_uuid/recipe/:r_uuid?",
      name: "nopatient_insurancebridge_recipe",
      meta: { auth: true },
      component: InsuranceBridgeRecipe,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/insurancerefund/:insurancerefund_uuid/invoice/",
      name: "nopatient_insurancerefund_invoice",
      meta: { auth: true },
      component: InsuranceRefundInvoice,
    },
    // Seleccionar una aseguradora tras darle al botón de autorización en
    // la home
    {
      path: "/nopatient/insuranceauthorization/list-insurances/",
      name: "nopatient_insuranceauthorization_insurance_selection",
      component: InsuranceAuthorizationInsuranceSelection,
      meta: {
        auth: true,
      },
    },
    // Seleccionar una aseguradora tras darle al botón de reembolso en
    // la home
    {
      path: "/nopatient/insurancerefund/list-insurances/",
      name: "nopatient_insurancerefund_insurance_selection",
      component: InsuranceRefundInsuranceSelection,
      meta: {
        auth: true,
      },
    },
    {
      path: "/nopatient/insurancebridge/list-insurances/",
      name: "nopatient_insurancebridge_insurance_selection",
      component: InsuranceBridgeInsuranceSelection,
      meta: {
        auth: true,
      },
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/details/insuranceauthorization/:insuranceauthorization_uuid",
      name: "nopatient_insuranceauthorization_detail",
      meta: { auth: true },
      component: AccountInsuranceAuthorizationDetail,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/details/insurancebridge/:insurancebridge_uuid",
      name: "nopatient_insurancebridge_detail",
      meta: { auth: true },
      component: AccountInsuranceBridgeDetail,
    },
    {
      path:
        "/nopatient/insurance/:i_uuid/details/insurancerefund/:insurancerefund_uuid",
      name: "nopatient_insurancerefund_detail",
      meta: { auth: true },
      component: AccountInsuranceRefundDetail,
    },

    // {
    //   path: "/account/patientdata_create",
    //   name: "patientdata_create",
    //   meta: { auth: true },
    //   component: AccountNewPatientData
    // },
    {
      path: "/account/organizations_list",
      name: "account_organizations_List",
      meta: { auth: true },
      component: AccountOrganizationsList,
    },
    {
      path: "/account",
      name: "account",
      meta: { auth: true },
      component: AccountHome,
    },
    {
      path: "/historial/:pt_uuid",
      name: "historial",
      meta: { auth: true },
      component: AccountHistory,
    },
    {
      path: "/historial_nopatient",
      name: "nopatient_insuranceauthorization_historial",
      meta: { auth: true },
      component: AccountHistory,
    },
    {
      path: "/historial_nopatient",
      name: "nopatient_insurancebridge_historial",
      meta: { auth: true },
      component: AccountHistory,
    },
    {
      path: "/historial_nopatient",
      name: "nopatient_insurancerefund_historial",
      meta: { auth: true },
      props: {
        active: "insurancerefund",
      },
      component: AccountHistory,
    },
    {
      path: "/account/major_medical_insurance_list",
      name: "account_major_medical_insurance_list",
      meta: { auth: true },
      component: AccountMajorMedicalInsurance,
    },

    {
      path: "/addresses/:pt_uuid",
      name: "addresses",
      meta: { auth: true },
      component: AddressList,
    },
    {
      path: "/address/:pt_uuid/new",
      name: "useraddressnew",
      component: AddressForm,
      meta: {
        auth: true,
      },
    },
    {
      path: "/address/:pt_uuid/:aUuid",
      name: "useraddressedit",
      component: AddressForm,
      meta: {
        auth: true,
      },
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactForm,
      meta: {
        auth: true,
      },
    },
    {
      path: "/social/auth/fb", // facebook
      name: "social_auth_facebook",
      component: SocialAuth,
      meta: {
        auth: false,
        provider: "facebook",
      },
    },
    {
      path: "/social/auth/in", // linkedin
      name: "social_auth_linkedin",
      component: SocialAuth,
      meta: {
        auth: false,
        provider: "linkedin",
      },
    },
    {
      path: "/social/auth/ins", // instagram
      name: "social_auth_instagram",
      component: SocialAuth,
      meta: {
        auth: false,
        provider: "instagram",
      },
    },
    {
      path: "/social/auth/tw", // twitter
      name: "social_auth_twitter",
      component: SocialAuth,
      meta: {
        auth: false,
        provider: "twitter",
      },
    },
    {
      path: "/social/auth/done", // path final una vez authenticado
      name: "social_auth_done",
      component: SocialAuth,
      meta: {
        auth: false,
        done: true,
      },
    },
    {
      path: "/social/auth/error", //
      name: "social_auth_error",
      component: SocialAuth,
      props: route => ({
        code: route.query.code,
      }),
      meta: {
        auth: false,
        done: false,
        error: true,
      },
    },
    {
      path: "/insuranceriskpayment/",
      name: "insurance-risk-payment",
      component: InsuranceRiskPayment,
      meta: {
        auth: true,
      },
    },
    {
      path: "/insuranceriskpayment/list/:action?",
      name: "insurance-risk-payment-list",
      component: InsuranceRiskPaymentList,
      meta: {
        auth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!store.getters["appstatus/isAppLoaded"]) {
    // esto provoca que esta ruta quede almacenada en router.history.pending
    // permitiéndonos recuperarla (en app.js) una vez que la app esté
    // completamente cargada.
    // Esto es útil para que las comprobaciones de login/sgmm que se
    // hacen durante este hook tengan toda la información necesaria
    // para realizar dicha comprobación.
    return;
  }
  const needAuth = to.matched.some(route => route.meta.auth);
  store.commit("routehelper/MUTATE_LAST_ROUTE", Object.assign({}, to));
  if (!needAuth) {
    if (to.name == "login") {
      store.dispatch("auth/CHECK_TOKEN").then(
        () => {
          next({ name: "userhome" });
        },
        () => {
          next();
        }
      );
    }
    // No auth need, skip token check and login redirect
    next();
  } else {
    store.dispatch("auth/CHECK_TOKEN").then(
      () => {
        // Si el usuario está logeado pero no tiene
        // una entrada de customer data (datos de sgmm)
        // se le redirecciona hacia la pantalla de entrada
        // de datos sgmm
        if (
          !store.getters["organization/userHasSgmm"] &&
          to.name != "patient_customer_data"
        ) {
          next({ name: "patient_customer_data" });
        } else {
          next();
        }
      },
      () => {
        // Not authenticated in protected view. Go Login
        next("/login");
      }
    );
  }
});

router.afterEach(to => {
  store.dispatch("appstatus/SHOW_AFTER_ROUTE_NOTIFICATION");
  var _ga = window.ga || function() {};
  _ga("set", "page", to.fullPath);
  _ga("send", "pageview");
});

export default router;
