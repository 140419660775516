<template>
  <div class="layout-wrapper" v-bind:dir="active_lang.direction ? 'rtl' : 'ltr'">
    <VCookiesBottom />
    <notifications group="main" />
    <div class="content-wrapper d-flex flex-column">
      <div class="layout-header">
        <VHeading class="heading" />
      </div>
      <div v-bind:class="{ bgImage: bgImage, grayBackground: grayBackground }">
        <div
          class="container-full"
          v-bind:class="{ fullwidth: fullwidth, 'up-line': upline }"
        >
          <div class="mb-3">
            <slot name="navigation"></slot>
          </div>

          <div class="clearfix">
            <VBreadcrumb class="float-left" v-if="showbreadcrumbs" />
            <div v-if="$slots.logo" class="layout-logo float-right">
              <slot name="logo"></slot>
            </div>
          </div>

          <div v-if="title" class="container-title">
            <h2 class="title">{{ title }}</h2>
          </div>
          <div v-if="subtitle">
            <h5 class="mb-5 text-center w-50 ml-auto mr-auto">
              {{ subtitle }}
            </h5>
          </div>
          <slot></slot>
        </div>
      </div>
      <div
        class="separator container-full"
        v-bind:class="{ 'mt-4': !footerNoMargin, 'mt-0': footerNoMargin}"
      ></div>
      <VFooter />
    </div>
  </div>
</template>


<script scoped>
import VHeading from "@/components/TheHeading";
import VFooter from "@/components/TheFooter";
import VBreadcrumb from "@/components/TheBreadcrumb";
import VCookiesBottom from "@/components/VCookiesBottom";
import { mapState } from "vuex";

export default {
  name: "default-layout",
  props: {
    title: String,
    subtitle: {
      default: undefined,
      type: String,
    },
    showbreadcrumbs: {
      default: true,
      type: Boolean,
    },
    fullwidth: {
      default: false,
      type: Boolean,
    },
    grayBackground: {
      default: false,
      type: Boolean,
    },
    upline: {
      default: false,
      type: Boolean,
    },
    bgImage: {
      default: false,
      type: Boolean,
    },
    footerNoMargin: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      active_lang: state => state.i18nmap.active_lang,
    }),
  },
  methods: {
    // logout() {
    //   this.$store.dispatch('auth/logout');
    // },
  },
  components: {
    VHeading,
    VFooter,
    VBreadcrumb,
    VCookiesBottom,
  },
};
</script>


<style lang="scss" scoped>
.layout-logo {
  width: 13rem;
}

.bgImage {
  padding-top: $spacing;

  background-image: url(../static/home_bg.jpg);
  background-size: cover;
  background-clip: content-box;

  background-position: center;
  background-repeat: repeat-x;
  height: 100%;
  position: relative;
  width: 100%;
}

.grayBackground {
  background-color: $gray-100;
}

.container-full,
.heading /deep/ .navbar-wrap {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
}

.fullwidth {
  max-width: none;
  width: 100%;
}

body,
#app,
.layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-wrapper {
  /*flex: 1 0 auto;*/ /*Uncomment if you want to move the footer to the bottom of the page*/
  height: 100%;
}

.layout-footer {
  flex-shrink: 0;
  width: 90%;
  margin: 0 auto;
}

.title {
  margin-top: 1em;
  margin-bottom: 2%;
  text-align: center;
  color: $corp-color1;
  font-weight: 300;
}

.separator {
  border-top: 1px solid $border-color;
}

@include media-breakpoint-down(md) {
  .layout-header {
    height: 6.5rem;
    margin-bottom: 4em;
  }
  .bgImage {
    padding-top: 0px;
  }
}

@include media-breakpoint-down(sm) {
  .layout-header {
    margin-bottom: 0px;
  }
}

@include media-breakpoint-up(md) {
  .container-full.up-line {
    border-top: 1px solid $gray-400;
  }
}

@include media-breakpoint-up(max) {
  .layout-header {
    margin-bottom: 0px;
  }
}
</style>
