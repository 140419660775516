import {
  // Gestión de facturas
  drugtransaction_insurance_insurancerefund_invoice_list,
  drugtransaction_insurance_insurancerefund_invoice_create,
  drugtransaction_insurance_insurancerefund_invoice_partial_update,
  // Gestión de productos en una factura
  drugtransaction_insurance_insurancerefund_invoice_drug_create,
  drugtransaction_insurance_insurancerefund_invoice_drug_partial_update,
  drugtransaction_insurance_insurancerefund_invoice_drug_delete,
} from "@/lib/api-client.js";
import { getAxiosConfig as axconf } from "@/lib/api-config.js";

// import Vue from "vue";

export default {
  namespaced: true,
  state: {
    invoicelist: {},
  },
  actions: {
    // Carga desde core las facturas del insurancerefund indicado
    REST_LOAD_INSURANCEREFUND_ALL_INVOICES: ({ commit }, payload) => {
      const request_promise = drugtransaction_insurance_insurancerefund_invoice_list(
        {
          $config: axconf(),
          role: "couldbepatient",
          roleUuid: 1,
          insuranceUuid: payload.insurance_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
        }
      );

      request_promise.then(response => {
        commit("MUTATE_INSURANCEREFUND_ALL_INVOICES", response.data);
      });
      return request_promise;
    },
    // Crea o actualiza en core una factura
    REST_UPSERT_INSURANCEREFUND_INVOICE: ({ commit }, payload) => {
      var endpoint = drugtransaction_insurance_insurancerefund_invoice_create;
      if (payload.invoice_uuid) {
        endpoint = drugtransaction_insurance_insurancerefund_invoice_partial_update;
      }
      const request_promise = endpoint({
        $config: axconf(),
        role: "couldbepatient",
        roleUuid: 1,
        insuranceUuid: payload.insurance_uuid,
        insurancerefundUuid: payload.insurancerefund_uuid,
        invoiceUuid: payload.invoice_uuid,
        data: payload.data,
      });

      request_promise.then(response => {
        commit("UPSERT_INSURANCEREFUND_INVOICE", response.data);
      });
      return request_promise;
    },
    // Borra producto de una factura
    REST_DELETE_INSURANCEREFUND_INVOICE_PRODUCT: ({ commit }, payload) => {
      const request_promise = drugtransaction_insurance_insurancerefund_invoice_drug_delete(
        {
          $config: axconf(),
          role: "couldbepatient",
          roleUuid: 1,
          insuranceUuid: payload.insurance_uuid,
          insurancerefundUuid: payload.insurancerefund_uuid,
          invoiceUuid: payload.invoice_uuid,
          snapdrugUuid: payload.snapdrug_uuid,
        }
      );
      request_promise.then(() => {
        commit("DELETE_INVOICE_DRUG", {
          invoice_uuid: payload.invoice_uuid,
          snapdrug_uuid: payload.snapdrug_uuid,
        });
      });
      return request_promise;
    },
    // Crea o actualiza un producto dentro de una factura
    REST_UPSERT_INSURANCEREFUND_INVOICE_DRUG: ({ commit }, payload) => {
      let endpoint = drugtransaction_insurance_insurancerefund_invoice_drug_create;
      if (payload.snapdrug_uuid) {
        endpoint = drugtransaction_insurance_insurancerefund_invoice_drug_partial_update;
      }
      const request_promise = endpoint({
        $config: axconf(),
        role: "couldbepatient",
        roleUuid: 1,
        insuranceUuid: payload.insurance_uuid,
        insurancerefundUuid: payload.insurancerefund_uuid,
        invoiceUuid: payload.invoice_uuid,
        snapdrugUuid: payload.snapdrug_uuid,
        data: payload.data,
      });
      request_promise.then(response => {
        commit("UPSERT_INVOICE_DRUG", {
          invoice_uuid: payload.invoice_uuid,
          snapdrug: response.data,
        });
      });
      return request_promise;
    },
    // CREATE_PATIENT_INSURANCE_INSURANCEREFUND_RECIPE: ({ commit }, payload) => {
    //   return new Promise((resolve, reject) => {
    //     drugtransaction_insurance_insurancerefund_recipe_create({
    //       $config: axconf(),
    //       role: "patient",
    //       roleUuid: payload.pt_uuid || 1,
    //       patientUuid: payload.pt_uuid,
    //       insuranceUuid: payload.i_uuid,
    //       insurancerefundUuid: payload.insurancerefund_uuid,
    //       data: payload.data
    //     }).then(
    //       response => {
    //         commit(
    //           "MUTATE_ACTIVE_PATIENT_INSURANCEREFUND_RECIPE",
    //           response.data
    //         );
    //         resolve(response);
    //       },
    //       err => {
    //         reject(err);
    //       }
    //     );
    //   });
    // }
  },
  mutations: {
    DELETE_INVOICE_DRUG: (state, payload) => {
      let invoice_uuid = payload.invoice_uuid;
      let snapdrug_uuid = payload.snapdrug_uuid;

      // busca la factura por su uuid
      let invoice_array_key = -1;
      state.invoicelist.results.forEach((invoice, key) => {
        if (invoice.uuid == invoice_uuid) {
          invoice_array_key = key;
        }
      });
      if (invoice_array_key < 0) {
        // factura no encontrada
        return;
      }

      // busca el producto por su uuid dentro de la factura encontrada
      let found_snapdrug_array_key = -1;
      state.invoicelist.results[invoice_array_key].item_list.drugs.forEach(
        (snapdrug, key) => {
          if (snapdrug.uuid == snapdrug_uuid) {
            found_snapdrug_array_key = key;
          }
        }
      );
      if (found_snapdrug_array_key < 0) {
        // producto no encontrado
        return;
      }
      state.invoicelist.results[invoice_array_key].item_list.drugs.splice(
        found_snapdrug_array_key,
        1
      );
    },
    UPSERT_INVOICE_DRUG: (state, payload) => {
      let invoice_uuid = payload.invoice_uuid;
      let snapdrug_uuid = payload.snapdrug.uuid;

      // busca la factura por su uuid
      let invoice_array_key = -1;
      state.invoicelist.results.forEach((invoice, key) => {
        if (invoice.uuid == invoice_uuid) {
          invoice_array_key = key;
        }
      });
      if (invoice_array_key < 0) {
        // factura no encontrada
        return;
      }

      // busca el producto por su uuid dentro de la factura encontrada
      let found_snapdrug_array_key = -1;
      state.invoicelist.results[invoice_array_key].item_list.drugs.forEach(
        (snapdrug, key) => {
          if (snapdrug.uuid == snapdrug_uuid) {
            found_snapdrug_array_key = key;
          }
        }
      );

      // No encontrado, es nuevo, añadimos
      if (found_snapdrug_array_key < 0) {
        state.invoicelist.results[invoice_array_key].item_list.drugs.push(
          Object.assign({}, payload.snapdrug)
        );
        return;
      }

      // Encontrado, ya existía, actualizamos
      state.invoicelist.results[invoice_array_key].item_list.drugs[
        found_snapdrug_array_key
      ] = Object.assign({}, payload.snapdrug);
    },
    // Crea o actualiza en el store un objeto de factura
    UPSERT_INSURANCEREFUND_INVOICE: (state, payload) => {
      let found = false;
      state.invoicelist.results.forEach((invoice, key) => {
        if (invoice.uuid == payload.uuid) {
          found = true;
          state.invoicelist.results[key] = payload;
        }
      });
      if (!found) {
        state.invoicelist.results.push(payload);
      }
    },
    // Carga todas las facturas obtenidas desde la api
    MUTATE_INSURANCEREFUND_ALL_INVOICES: (state, payload) => {
      state.invoicelist = Object.assign({}, payload);
    },
  },
  getters: {
    getAllProducts: state => {
      if (!state.invoicelist.results) {
        return undefined;
      }
      let products = [];
      state.invoicelist.results.forEach(invoice => {
        invoice.item_list.drugs.forEach(element => {
          products.push(Object.assign({}, element));
        });
      });
      return products;
    },
  },
};
