<template>
  <div>
    <vue-element-loading :active="loading" :is-full-screen="true" />
    <b-form v-if="show" @submit="sendForm">
      <b-container fluid class="pr-0 pl-0">
        <b-row>
          <b-col cols="12" lg="6" md="6" sm="12">
            <InsuranceBridgeRecipePatientFormA
              class="mr-md-3"
              :form="form"
              :form_state="form_state"
              :form_invalid_feedback="form_invalid_feedback"
              v-on:insurance-costs-change="buildAmount()"
            />
          </b-col>
          <b-col cols="12" lg="6" md="6" sm="12">
            <InsuranceBridgeRecipeMedicForm
              class="ml-md-3"
              :form="form"
              :form_state="form_state"
              :form_invalid_feedback="form_invalid_feedback"
            />
          </b-col>
        </b-row>
      </b-container>
      <b-modal
        size="xl"
        modal-class="modal-square-theme"
        v-model="drugSelectorShow"
        centered
        :title="$t('insurancebridge_recipe.drug_selector_title')"
        :hide-footer="!selected_product_payload"
        @ok="handleOkModal($event)"
        @cancel="handleCancelModal($event)"
        :ok-title="$t('insurancebridge_recipe.drug_selector_primary_button')"
        :cancel-title="$t('insurancebridge_recipe.drug_selector_secondary_button')"
        cancel-variant="secondary"
        ok-variant="primary"
      >
        <b-container fluid>
          <b-row>
            <b-col>
              <span v-if="selected_product_payload == undefined">
                {{ $t('insurancebridge_recipe.drug_selector_help_text') }}
              </span>
              <InsuranceBridgeRecipeDrugSelector
                v-on:product:selected="onProductSelected"
                :show_selected="!(selected_product_payload == undefined)"
                :pt_uuid="this.$route.params.pt_uuid"
                :i_uuid="this.$route.params.i_uuid"
                :show_provider_filter="form.pharmacy_pickup"
                :perPage="5"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-container fluid class="mt-5 pl-0 pr-0" no-gutters>
        <b-row no-gutters>
          <b-col col lg="12" md="auto">
            <vue-element-loading :active="products_summary_loading" />
            <i18n path="insurancebridge_recipe.up_button_explain_text" tag="div" class="mb-3">
              <span place="add_new_drug_button">
                <strong class="text-uppercase">
                  {{ $t('insurancebridge_recipe.add_new_drug_button') }}*
                </strong>
              </span>
            </i18n>
            <legend
              class="text-uppercase col-form-label col-form-label-lg font-weight-bold pt-0 mb-4 title"
            >
              {{ $t('insurancebridge_recipe.drugs') }}
            </legend>
            <VDrugsSummary
              :items="products"
              :summary="summary"
              :amount="amount"
              :state="drugs_validation_state"
              :invalid-feedback="$t('insurancebridge_recipe.error.no_drugs')"
              dirtycolumn
              actionscolumn
              adddrugbutton
              :adddrugbuttontext="$t('insurancebridge_recipe.add_new_drug_button')"
              :showprovider="true"
              v-on:drugsButtonClick="drugSelectorShow = true"
              v-on:deteleButtonClick="onDeteleButtonClick"
              v-on:summary:currency_switch="switchCurrency()"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid class="mt-5 pr-0" no-gutters>
        <b-row no-gutters class="pr-0">
          <b-col col lg="12" md="auto">
            <vue-element-loading :active="save_loading" />
            <b-button
              type="submit"
              id="save-button"
              variant="primary"
              class="pull-right"
            >
              {{ $t('insurancebridge_recipe.button_save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>

    </b-form>
  </div>
</template>


<script>
import InsuranceBridgeRecipeDrugSelector from "@/components/insurancebridge/InsuranceBridgeRecipeDrugSelector";
import VDrugsSummary from "@/components/insurancebridge/VDrugsSummary";
import InsuranceBridgeRecipePatientFormA from "@/components/insurancebridge/InsuranceBridgeRecipePatientFormA";
import InsuranceBridgeRecipeMedicForm from "@/components/insurancebridge/InsuranceBridgeRecipeMedicForm";

import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { buildAmount } from "@/lib/amount.js";

export default {
  name: "insurancebridge-recipe",
  data() {
    return {
      drugs_validation_state: null,
      drugSelectorShow: false,
      deliveryAddressShow: false,
      selected_product_payload: undefined,
      recipe_products_fields: [
        {
          key: "dirty",
          label: "",
        },
        {
          key: "ean",
          label: this.$t("insurancebridge_recipe.headtable_ean"),
        },
        {
          key: "default_description",
          label: this.$t("insurancebridge_recipe.headtable_drug_name"),
        },
        {
          key: "batch",
          label: this.$t("insurancebridge_recipe.headtable_batch_expire"),
        },
        {
          key: "quantity",
          label: this.$t("insurancebridge_recipe.headtable_quantity"),
        },
        {
          key: "unit_price",
          label: this.$t("insurancebridge_recipe.headtable_unit_price"),
        },
        {
          key: "price",
          label: this.$t("insurancebridge_recipe.headtable_price"),
        },
        {
          key: "actions",
          label: "",
        },
      ],
      form: {
        patient_address: {
          firstname: "",
          lastname: " ",
          email: "",
        },
        delivery_address: {
          firstname: "",
          town: "",
          colony: "",
          federal_entity: "",
          phone: "",
          country: "",
          address: "",
          postal_code: "",
          hours: "",
          date: "",
        },
        doctor_address: {
          firstname: "",
          specialty: "",
          professional_number: "",
          email: "",
          phone: "",
        },
        delivery_to_home: true,
        pharmacy_pickup: false,
        recipe_num: "",
        // auth_num: "",
        recipe_file: null,
        preferred_currency: undefined,
      },
      // Moneda especificada manualmente, será el mismo valor que
      // preferred_currency siempre que se haya especificado
      // manualmente, en caso contrario esto permanecerá undefined
      manual_currency: undefined,
      form_invalid_feedback: {
        patient_address: {
          firstname: undefined,
          lastname: undefined,
          email: undefined,
          insurance_name: undefined,
        },
        delivery_address: {
          firstname: undefined,
          town: undefined,
          colony: undefined,
          federal_entity: undefined,
          phone: undefined,
          country: undefined,
          address: undefined,
          postal_code: undefined,
          hours: undefined,
          date: undefined,
        },
        doctor_address: {
          firstname: undefined,
          specialty: undefined,
          professional_number: undefined,
          email: undefined,
          phone: undefined,
        },
      },
      form_state: {
        patient_address: {
          firstname: undefined,
          lastname: undefined,
          email: undefined,
          insurance_name: undefined,
        },
        delivery_address: {
          firstname: undefined,
          town: undefined,
          colony: undefined,
          federal_entity: undefined,
          phone: undefined,
          country: undefined,
          address: undefined,
          postal_code: undefined,
          hours: undefined,
          date: undefined,
        },
        doctor_address: {
          firstname: undefined,
          specialty: undefined,
          professional_number: undefined,
          email: undefined,
          phone: undefined,
        },
      },
      show: false,
      loading: true,
      products_summary_loading: false,
      save_loading: false,
      amount: {},
    };
  },
  computed: {
    ...mapGetters("recipe", ["getPatientInsuranceRecipeByInsuranceBridgeUUID"]),
    ...mapGetters("recipe", ["getPatientInsuranceBridgeRecipeByRecipeUUID"]),
    ...mapGetters("recipe", ["getPatientInsuraceBridgeActiveRecipe"]),
    ...mapState({
      summary: (state) =>
        state.recipe.active_patient_insurancebridge_recipe_summary,
    }),
    ...mapState({
      products: (state) =>
        state.recipe.active_patient_insurancebridge_recipe_products,
    }),
    ...mapState({
      dirty_products: (state) =>
        state.recipe.active_patient_insurancebridge_recipe_dirty_products,
    }),
    ...mapState({
      state_active_recipe: (state) =>
        state.recipe.active_patient_insurancebridge_recipe,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        insurancebridge_recipe: {
          recipe_file_label: "File",
          recipe_file_placeholder: "Select a file...",
          recipe_num_label: "Recipe number",
          recipe_num_placeholder: "Recipe number",
          // auth_num_label: "Auth number",
          // auth_num_placeholder: "Auth number",
          recipe_data_title: "Recipe data",
          patient_data_title: "Patient data",
          button_save: "Save",
          button_wait_auth: "Wait bridge",
          drug_selector_title: "Select drug",
          drug_selector_primary_button: "Append",
          drug_selector_secondary_button: "Back",
          drug_selector_help_text:
            "Enter your medication in the search box and click the orange button to add it",
          delivery_address_button: "Delivery address",
          send_home: "Send home",
          headtable_ean: "Ean",
          headtable_drug_name: "Drug name",
          headtable_quantity: "Quantity",
          headtable_price: "Price",
          headtable_batch_expire: "Batch/Expiry date",
          headtable_unit_price: "Unit price",
          subtotal: "Subtotal",
          taxes: "Taxes",
          total: "Total",
          add_new_drug_button: "Add new drug",
          up_button_explain_text:
            "Please add the medications that come in your prescription by clicking on the {add_new_drug_button} button",
          is_saved: "This product is saved",
          not_saved: 'This product will be saved on "Save" button click',
          drugs: "Drugs",
          only_one_provider:
            "If you select the 'pick up' shipping option, you can add products from a single provider",
          error: {
            no_drugs: "Please, select one drug",
            saving_recipe: "Error saving recipe, check form errors.",
            load_recipes: "Cannot load recipes",
            only_one_provider:
              "If you select the 'pick up' shipping option, you can add products from a single provider",
          },
        },
      },
    },
  },
  mounted: function () {
    // this.loadRefundData();
    this.loadRecipe();
  },
  methods: {
    switchCurrency() {
      if (this.form.preferred_currency == "USD") {
        this.form.preferred_currency = "MXN";
        this.form.manual_currency = "MXN";
      } else {
        this.form.preferred_currency = "USD";
        this.form.manual_currency = "USD";
      }
      this.buildAmount();
    },
    formatPrice(raw, currency) {
      // TODO: get current locale
      // TODO: add try catch for old not supported toLocaleString browsers
      // TODO: Search for a lib to do this
      const number = Number(raw).toLocaleString(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
      return currency + " " + number;
    },
    buildAmount() {
      this.amount = buildAmount(
        this.products,
        undefined,
        // Usa este para que se mantenga la moneda inicialmente detectada
        // this.form.preferred_currency,
        //
        // Usa este para que se continuamente se determine la moneda
        // a menos que se seleccione una manualmente
        this.form.manual_currency,
        // true to spread risk using coinsurance and deductible
        false
      );
      // la primera vez que se llama a buildAmount, form.preferred_currency
      // es == undefined. buildAmount buscará la moneda más adecuada (mirando
      // las monedas de los productos), con lo cual, una vez llamado a
      // buildAmount, podemos colocar la moneda encontrada en
      // form.preferred_currency
      if (this.amount.preferred_currency) {
        this.form.preferred_currency = this.amount.preferred_currency;
      }
    },
    handleOkModal() {
      this.pushSelectedProduct();
    },
    handleCancelModal(evt) {
      evt.preventDefault();
      this.selected_product_payload = undefined;
    },
    handleProductSelectOk() {
      this.drugSelectorShow = false;
      this.pushSelectedProduct();
    },
    pushSelectedProduct() {
      this.products_summary_loading = true;
      var _payload = Object.assign({}, this.selected_product_payload);
      this.$store
        .dispatch(
          "recipe/ACTIVE_PATIENT_INSURANCEBRIDGE_RECIPE_PUSH_PRODUCT",
          _payload
        )
        .then(
          () => {
            this.products_summary_loading = false;
            this.selected_product_payload = undefined;
            this.buildAmount();
          },
          () => {
            this.products_summary_loading = false;
          }
        );
    },
    onProductSelected(payload) {
      this.drugs_validation_state = true;
      this.selected_product_payload = payload;
    },
    onDeteleButtonClick(item) {
      this.products_summary_loading = true;
      this.$store
        .dispatch(
          "recipe/DELETE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE_DRUG",
          {
            pt_uuid: this.$route.params.pt_uuid,
            i_uuid: this.$route.params.i_uuid,
            insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
            r_uuid: this.$route.params.r_uuid,
            data: item,
          }
        )
        .then(
          () => {
            this.products_summary_loading = false;
            this.buildAmount();
          },
          () => {
            this.products_summary_loading = false;
            this.buildAmount();
          }
        );
    },
    loadRecipe() {
      // limpia la receta activa actualmente y todos sus estados relacionados
      this.$store.commit(
        "recipe/MUTATE_PATIENT_INSURANCE_INSURANCEBRIDGE_ACTIVE_RESET"
      );
      // TODO: cargar por id de receta si existe o por id de reembolso si no
      this.$store
        .dispatch("recipe/LOAD_PATIENT_INSURANCE_INSURANCEBRIDGE_ALL_RECIPES", {
          pt_uuid: this.$route.params.pt_uuid,
          i_uuid: this.$route.params.i_uuid,
          insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
        })
        .then(
          () => {
            let recipe = undefined;
            if (this.$route.params.r_uuid) {
              // get by recipe uuid
              recipe = this.getPatientInsuranceBridgeRecipeByRecipeUUID(
                this.$route.params.r_uuid
              );
            } else {
              // get by insurance bridge uuid
              recipe = this.getPatientInsuranceRecipeByInsuranceBridgeUUID(
                this.$route.params.insurancebridge_uuid
              );
            }
            if (recipe) {
              this.$store
                .dispatch(
                  "recipe/SET_PATIENT_INSURANCE_INSURANCEBRIDGE_ACTIVE_RECIPE",
                  recipe
                )
                .then(
                  () => {
                    this.$nextTick(() => {
                      this.syncFormWithState();
                    });
                    this.show = true;
                    this.loading = false;
                    this.routeToRecipe(recipe.uuid);
                  },
                  (err) => {
                    this.$sentry({ capture: err });
                    this.$route.push({ name: "fallback-error-page" });
                  }
                );
            } else {
              this.show = true;
              this.loading = false;
              this.routeToRecipe("not-saved");
            }
          },
          (err) => {
            this.mnotify_error({
              text: this.$t("insurancebridge_recipe.error.load_recipes"),
            });
            this.$sentry({ capture: err });
          }
        );
    },
    routeToRecipe(r_uuid) {
      this.$router.replace({
        name: "nopatient_insurancebridge_recipe",
        params: {
          pt_uuid: this.$route.params.pt_uuid,
          i_uuid: this.$route.params.i_uuid,
          insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
          r_uuid: r_uuid,
        },
      });
    },
    preDataOut(_data) {
      var data = Object.assign({}, _data);
      if (data.delivery_address === null) {
        // backend do not accept a null value for this field
        delete data.delivery_address;
      }
      if (data.doctor_address === null) {
        // backend do not accept a null value for this field
        delete data.doctor_address;
      }
      if (data.delivery_to_home) {
        data.delivery_to_home = true;
      } else {
        data.delivery_to_home = false;
      }
      delete data.recipe_file;

      // esto es sólo para control interno del la aplicación, no es un
      // dato de formulario real
      if (data.coinsurance_type_string) {
        delete data.coinsurance_type_string;
      }
      if (data.insurance) {
        delete data["insurance"];
      }
      return data;
    },
    // preparar datos recibidos de la api listos para ser usados en
    // el componente
    preDataIn(_data) {
      var data = Object.assign({}, _data);
      var address = {
        firstname: "",
        lastname: "",
      };
      data.delivery_address = Object.assign(address, data.delivery_address);
      if (data.delivery_to_home) {
        data.delivery_to_home = true;
      } else {
        data.delivery_to_home = false;
      }
      if (data.recipe_file) {
        data["_recipe_file"] = data.recipe_file;
        data.recipe_file = undefined;
      }
      if (data.insurance) {
        delete data["insurance"];
      }
      return data;
    },
    syncFormWithState() {
      this.form = this.preDataIn(this.getPatientInsuraceBridgeActiveRecipe);
      this.buildAmount();
    },
    _preValidatePickUpProviderForm() {
      if (!this.form.pharmacy_pickup) {
        return true;
      }
      var provider_uuid = undefined;
      var valid_provider = true;

      this.products.forEach((product) => {
        if (!provider_uuid) {
          if (product.price.manager) {
            provider_uuid = product.price.manager;
          }
          if (product.price.provider) {
            provider_uuid = product.price.provider.uuid;
          }
          return;
        }
        if (
          product.price.provider &&
          provider_uuid != product.price.provider.uuid
        ) {
          valid_provider = false;
        }
        if (product.price.manager && provider_uuid != product.price.manager) {
          valid_provider = false;
        }
      });
      if (!valid_provider) {
        this.mnotify_error({
          text: this.$t("insurancebridge_recipe.error.only_one_provider"),
        });
        return false;
      }
      return true;
    },
    preValidateForm() {
      let valid = true;
      if (!this._preValidatePickUpProviderForm()) {
        valid = false;
      }
      if (this.dirty_products.length <= 0 && this.products.length <= 0) {
        this.drugs_validation_state = false;
        valid = false;
      }
      return valid;
    },
    sendForm(evt) {
      evt.preventDefault();

      var valid = this.preValidateForm();
      if (!valid) {
        return;
      }

      var data = this.preDataOut(this.form);
      var dispatch_action = undefined;
      var dispatch_payload = undefined;
      if (!this.form.uuid) {
        // new recipe
        dispatch_action =
          "recipe/CREATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE";
        dispatch_payload = {
          pt_uuid: this.$route.params.pt_uuid,
          insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
          i_uuid: this.$route.params.i_uuid,
          data: data,
        };
      } else {
        // update recipe
        dispatch_action =
          "recipe/UPDATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE";
        dispatch_payload = {
          pt_uuid: this.$route.params.pt_uuid,
          insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
          i_uuid: this.$route.params.i_uuid,
          r_uuid: this.form.uuid,
          data: data,
        };
      }
      this.save_loading = true;
      this.products_summary_loading = true;
      this.$store.dispatch(dispatch_action, dispatch_payload).then(
        (response) => {
          if (!this.form.uuid) {
            this.routeToRecipe(response.data.uuid);
          }

          let promises = this._sendFormProducts();
          promises.push(this._sendFormFiles());
          Promise.all(promises)
            .then(() => {
              this.save_loading = false;
              this.products_summary_loading = false;
              this.$emit("recipe:done");
            })
            .catch((e) => {
              this.save_loading = false;
              this.products_summary_loading = false;
              if (e.response && e.response.data && e.response.data.detail) {
                this.mnotify_error({
                  text: this.$t(e.response.data.detail),
                });
              } else {
                this.mnotify_error({
                  text: this.$t("insurancebridge_recipe.error.saving_recipe"),
                });
              }
            });
        },
        (err) => {
          this.save_loading = false;
          this.products_summary_loading = false;
          this.mnotify_error({
            text: this.$t("insurancebridge_recipe.error.saving_recipe"),
          });
          this.showResponseError(err);
          this.putFormFeedback(err.response);
        }
      );
    },
    _sendFormFiles() {
      if (!this.form.recipe_file) {
        return new Promise((resolve) => {
          resolve("NO FILES");
        });
      }
      let formData = new FormData();
      formData.append("recipe_file", this.form.recipe_file);
      formData.append(
        "recipe_file_original_filename",
        this.form.recipe_file.name
      );
      return this.$store.dispatch(
        "recipe/UPDATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE",
        {
          pt_uuid: this.$route.params.pt_uuid,
          insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
          i_uuid: this.$route.params.i_uuid,
          r_uuid: this.state_active_recipe.uuid,
          data: formData,
        }
      );
    },
    _sendFormProducts() {
      let promises = [];

      this.dirty_products.forEach((element) => {
        // UPDATE
        if (element.uuid) {
          promises.push(
            this.$store.dispatch(
              "recipe/PATCH_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE_DRUG",
              {
                data: {
                  quantity: element.quantity,
                },
                item: element,
                pt_uuid: this.$route.params.pt_uuid,
                i_uuid: this.$route.params.i_uuid,
                insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
                r_uuid: this.state_active_recipe.uuid,
                rd_uuid: element.uuid,
              }
            )
          );

          // skip this iteration, jump next element
          return;
        }

        // CREATE
        element.recipe = this.form.uuid;
        promises.push(
          this.$store.dispatch(
            "recipe/CREATE_PATIENT_INSURANCE_INSURANCEBRIDGE_RECIPE_DRUG",
            {
              data: element,
              pt_uuid: this.$route.params.pt_uuid,
              insurancebridge_uuid: this.$route.params.insurancebridge_uuid,
              i_uuid: this.$route.params.i_uuid,
              r_uuid: this.state_active_recipe.uuid,
            }
          )
        );
      });

      return promises;
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      /* Trick to reset/clear native browser form validation state */
      this.show = false;
      this.loading = true;
      this.$nextTick(() => {
        this.show = true;
        this.loading = false;
      });
    },
  },
  components: {
    InsuranceBridgeRecipeDrugSelector,
    VDrugsSummary,
    InsuranceBridgeRecipePatientFormA,
    InsuranceBridgeRecipeMedicForm,
  },
};
</script>


<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.form-submit .el-button {
  width: 100%;
}
</style>
