<template>
  <b-nav tabs class="navtabs">
    <slot></slot>
  </b-nav>
</template>

<script>
export default {
  name: "v-b-nav",
};
</script>

<style lang="scss">
.nav-tabs {
  border-bottom: 1px solid $gray-450;
  margin-bottom: $spacing !important;
  font-size: $font-size-base;

  .nav-link {
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border-bottom: 2px solid transparent !important;
  }

  .nav-link:hover,
  .nav-link:focus,
  .nav-link.active {
    border-bottom: 2px solid $corp-color1 !important;
  }

  .nav-item {
    margin-bottom: -2px;
    text-transform: uppercase;
  }

  .nav-link.a {
    color: $text-main-color !important;
  }

  a:link {
    color: $text-main-color !important;
  }

  a:active {
    color: $text-main-color !important;
  }

  a:hover {
    color: $text-main-color !important;
  }

  a:visited {
    color: $text-main-color !important;
  }
}
</style>
