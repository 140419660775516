<template>
  <VLayout
    upline
    :title="$t('account_insurancebridge_detail.title')"
    :bgImage="false"
  >
    <TransactionDetail
      type="insurancebridge"
      :organization_uuid="$route.params.i_uuid"
      :patient_uuid="$route.params.pt_uuid"
      :transaction_uuid="$route.params.insurancebridge_uuid"
    />
    <b-button
      @click="$router.go(-1)"
      variant="secondary"
      class="mt-4"
    >{{ $t('account_insurancebridge_detail.return') }}</b-button>
  </VLayout>
</template>

<script>
import VLayout from "@/layouts/Default";
import TransactionDetail from "@/components/transactiondetail/TransactionDetail";

export default {
  components: {
    VLayout,
    TransactionDetail,
  },
  fallbackI18n: {
    messages: {
      und: {
        account_insurancebridge_detail: {
          title: "Refund detail",
          return: "Back",
        },
      },
    },
  },
  mounted: function () {
    this.putBreadCrumb();
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.account",
          to: { name: "account" },
        },
        {
          langkey: "breadcrumb.historial",
          to: { name: "nopatient_insurancebridge_historial" },
        },
        {
          langkey: "breadcrumb.insurancebridge_detail",
          to: { name: "nopatient_insurancebridge_detail" },
        },
      ]);
    },
  },
};
</script>
