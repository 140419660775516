<template>
  <b-button-toolbar>
    <b-button-group size="sm">
      <b-button variant="outline-dark" :title="$t('table_toolbar_filter.toolbar_filters')" @click="showFilters">
        <b-icon icon="filter" aria-hidden="true"></b-icon> {{ $t('table_toolbar_filter.toolbar_filters') }}
      </b-button>
      <b-button
        v-if="filters_count > 0"
        variant="outline-dark"
        :title="$t('table_toolbar_filter.toolbar_apply_filters')"
        @click="applyFilter"
      >
        <b-icon icon="search" aria-hidden="true"></b-icon> {{ $t('table_toolbar_filter.toolbar_apply_filters') }}
      </b-button>
      <b-button
        v-if="is_filtered"
        variant="outline-dark"
        :title="$t('table_toolbar_filter.toolbar_clear_filters')"
        @click="clearFilters"
      >
        <b-iconstack>
          <b-icon stacked icon="filter" aria-hidden="true"></b-icon>
          <b-icon stacked icon="slash-circle" variant="danger"></b-icon>
        </b-iconstack> {{ $t('table_toolbar_filter.toolbar_clear_filters') }}
      </b-button>
    </b-button-group>
  </b-button-toolbar>
</template>

<script>
export default {
  name: "v-table-toolbar-filter",
  data() {
    return {};
  },
  props: {
    is_filtered: {
      type: Boolean,
      default: undefined,
    },
    filters_count: {
      type: Number,
      default: 0,
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        table_toolbar_filter: {
          toolbar_filters: "Filters",
          toolbar_clear_filters: "Clear",
          toolbar_apply_filters: "Apply",
        },
      },
    },
  },
  methods: {
    showFilters(evt) {
      this.$emit("showFilters", evt);
    },
    clearFilters(evt) {
      this.$emit("clearFilters", evt);
    },
    applyFilter(evt) {
      this.$emit("applyFilter", evt);
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
