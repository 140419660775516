<template>
  <v-layout :bgImage="false" :title="$t('faq.title')">
    <b-row class="mt-3">
      <b-col cols="12" md="6" class="mr-0 pr-0">
        <b-list-group id="list-tab" role="tablist" class="questions">
          <b-list-group-item
            :action="true"
            role="tab"
            v-for="(tab, index) in tabs"
            v-bind:key="index"
            data-toggle="list"
            :tag="tab.name"
            :href="'#'+ tab.name"
            :active="activeTabName==tab.name"
            @click.prevent="setActiveTabName(tab.name)"
            class="p-3 pl-5"
          >
            {{ $t(tab.question) }}
            <div class="chevron-right">
              <font-awesome-icon icon="chevron-right" />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="12" md="6" class="col-answers">
        <div class="tab-pane" role="tabpanel" v-for="(tab, index) in tabs" v-bind:key="index">
          <div class="p-4" v-if="displayContents(tab.name)">
            <h3>{{ $t(tab.question) }}</h3>
            <VMarkdownView :mdtext="$t(tab.text)" />
          </div>
        </div>
      </b-col>
    </b-row>
  </v-layout>
</template>

<script>
import VMarkdownView from "@/components/VMarkdownView";
import what_pdv_authorization from "raw-loader!@/static/content/faq/what_pdv_authorization_es-MX.txt";
import what_direct_payment from "raw-loader!@/static/content/faq/what_direct_payment_es-MX.txt";
import how_do_paymenyt from "raw-loader!@/static/content/faq/how_do_payment_es-MX.txt";
import what_refund from "raw-loader!@/static/content/faq/what_refund_es-MX.txt";

import VLayout from "@/layouts/Default";

export default {
  fallbackI18n: {
    messages: {
      und: {
        faq: {
          title: "Frequent questions",
          questions: {
            what_pdv_authorization: "1 - What is the PDV Authorization?",
            what_direct_payment: "2 - What is a Direct Payment?",
            how_do_paymenyt: "3 - How do I make a direct payment request?",
            what_refund: "4 - What is a Refund?",
          },
          answers: {
            what_pdv_authorization: what_pdv_authorization,
            what_direct_payment: what_direct_payment,
            how_do_paymenyt: how_do_paymenyt,
            what_refund: what_refund,
          },
        },
      },
    },
  },
  data() {
    return {
      // List here all available tabs
      tabs: [
        {
          question: "faq.questions.what_pdv_authorization",
          name: "what_pdv_authorization",
          text: "faq.answers.what_pdv_authorization",
        },
        {
          question: "faq.questions.what_direct_payment",
          name: "what_direct_payment",
          text: "faq.answers.what_direct_payment",
        },
        {
          question: "faq.questions.how_do_paymenyt",
          name: "how_do_paymenyt",
          text: "faq.answers.how_do_paymenyt",
        },
        {
          question: "faq.questions.what_refund",
          name: "what_refund",
          text: "faq.answers.what_refund",
        },
      ],
      activeTabName: null,
    };
  },
  mounted: function() {
    if (this.$route.hash != "") {
      this.activeTabName = this.$route.hash.substring(1);
    } else {
      this.activeTabName = this.tabs[0].name;
    }

    this.putBreadCrumb();
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "faq.title",
          to: { name: "cookies" },
        },
      ]);
    },
    setActiveTabName(name) {
      this.activeTabName = name;
      this.$router.replace({
        name: "faq",
        hash: '#' + name,
      });
    },
    displayContents(name) {
      return this.activeTabName === name;
    },
  },

  components: {
    VLayout,
    VMarkdownView,
  },
};
</script>

<style lang="scss" scoped>
.col-answers {
  background-color: $gray-100;
}
.list-group-item {
  text-decoration: none;
}
.list-group-item.active {
  border-color: $gray-300;
  color: $text-main-color;
  background-color: $gray-100;
  border-bottom: 3px solid $corp-color1 !important;
}
.questions {
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.27);
}
.chevron-right {
  float: right;
  font-size: 1.2em;
}
</style>