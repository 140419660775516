<template>
  <div>
    <vue-element-loading :active="loading_addresses" />
    <div deck v-if="addresses" class="column-max-3">
      <div
        class="column-max-item sep-4"
        v-for="(address, index) in addresses"
        :key="index"
      >
        <b-card
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          class="address-card shadow-sm h-100"
          footer-class="border-top-0 pb-3"
          header-class="pb-2"
        >
          <div slot="header" class="d-flex justify-content-between">
            <div>
              <h5 class="mb-0 text-uppercase">
                {{ address.firstname }} {{ address.lastname }}
              </h5>
            </div>
            <div class="edit-address clickable">
              <font-awesome-icon
                @click="routeTo({ name: 'useraddressedit', params: { aUuid: address.uuid, pt_uuid: pt_uuid}})"
                icon="pen"
              />
            </div>
          </div>
          <div v-if="address.address" class="box-address-data">{{ address.address }}</div>
          <div v-if="address.email" class="box-address-data">{{ address.email }}</div>
          <div v-if="address.town" class="box-address-data">{{ address.town }}</div>
          <div v-if="address.colony" class="box-address-data">{{ address.colony }}</div>
          <div v-if="address.postal_code" class="box-address-data">{{ address.postal_code }}</div>
          <div v-if="address.mobile" class="box-address-data">{{ address.mobile }}</div>
          <div slot="footer" class="box-address-footer">
            <b-button
              variant="secondary-inline-reverse"
              class="pull-right"
              v-b-modal.modalDeleteAddress
              @click="address_to_delete=address"
            >{{ $t('addresses.remove') }}</b-button>
          </div>
        </b-card>
      </div>
    </div>

    <!-- Modal Component -->
    <b-modal
      id="modalDeleteAddress"
      modal-class="modal-square-theme"
      ref="modal"
      :title="$t('addresses.help_delete_title')"
      @ok="deleteAddress(address_to_delete)"
    >{{ $t('addresses.help_delete') }}</b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addresses: [],
      loading_addresses: true,
      address_to_delete: null,
      pt_uuid: this.$route.params.pt_uuid,
    };
  },
  created() {
    this.loadAddresses();
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
    loadAddresses() {
      this.loading_addresses = true;
      this.$store
        .dispatch("naturalperson/REQUEST_ADDRESSES", { pt_uuid: this.pt_uuid })
        .then(
          response => {
            this.addresses = response.data.results;
            this.loading_addresses = false;
          },
          err => {
            this.loading_addresses = false;
            this.$sentry({ capture: err });
            this.mnotify_error({
              text: this.$t("addresses.error.address_retrieve_error"),
            });
          }
        );
    },
    deleteAddress: function(address) {
      this.$store
        .dispatch("naturalperson/DELETE_ADDRESS", {
          pt_uuid: this.$route.params.pt_uuid,
          a_uuid: address.uuid,
        })
        .then(
          () => {
            this.loadAddresses();
            this.mnotify_success({
              text: this.$t("addresses.deleted_address"),
            });
          },
          err => {
            this.$sentry({ capture: err });
            this.mnotify_error({
              text: this.$t("addresses.error.address_delete_error"),
            });
          }
        );
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        addresses: {
          add_address: "Add address",
          update: "Update",
          main: "Main",
          remove: "Remove",
          help_delete: "Are you sure you want to delete the address?",
          help_delete_title: "Delete address",
          deleted_address: "Address deleted",
          error: {
            address_retrieve_error: "Cannot retrieve address",
            address_delete_error: "Cannot delete address",
          },
        },
      },
    },
  },
};
</script>
<style scoped lang="scss">
.box-address-footer {
  border-top: 0;
}

.box-address-data {
  font-size: $font-size-base;
  color: $gray-600;
  line-height: 2rem;
}

.edit-address {
  font-size: initial;
  float: right;
  color: #fd5d00;
  position: relative;
  top: -4px;
  /* width: 15px; */
  height: 1.3rem;
}
</style>
