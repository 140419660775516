<template>
  <div>
    <DefaultLayout>
      <VFirstLoginAuthorizationGuidePopUp v-if="allow_insurance_authorization_guide" />
      <template slot="navigation">
        <InsuranceAuthorizationNavigation />
      </template>
      <vue-element-loading :active="!show" :is-full-screen="true" />
      <div v-if="show" class="mb-4">
        <OrganizationCardList :items="insurance_authorization_list" v-on:org:click="onOrgClick" />
      </div>

    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import InsuranceAuthorizationNavigation from "@/components/insuranceauthorization/InsuranceAuthorizationNavigation";
import OrganizationCardList from "@/components/OrganizationCardList";
import VFirstLoginAuthorizationGuidePopUp from "@/components/VFirstLoginAuthorizationGuidePopUp";
import { mapState } from "vuex";

export default {
  data() {
    return {
      show: false,
    };
  },
  fallbackI18n: {
    messages: {
      und: {
        insuranceauthorization_insurance_selection: {
          error: {
            retrieving_insurances: "Cannot load insurances",
          },
        },
      },
    },
  },
  computed: {
    ...mapState({
      insurance_authorization_list: (state) =>
        state.organization.insurance_authorization_list,
      allow_insurance_authorization_guide: (state) =>
        state.organization.allow_insurance_authorization_guide,
    }),
  },
  methods: {
    putBreadCrumb() {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb.home",
          to: { name: "home" },
        },
        {
          langkey: "breadcrumb.refund_insurance_selection",
          to: { name: "nopatient_insuranceauthorization_insurance_selection" },
        },
      ]);
    },
    onOrgClick: function (item) {
      // Route to new insuranceauthorization
      this.$router.push({
        name: "nopatient_insuranceauthorization_home",
        params: {
          pt_uuid: this.$route.params.pt_uuid,
          i_uuid: item.uuid,
          insuranceauthorization_uuid: "new",
        },
      });
    },
  },
  mounted: function () {
    this.putBreadCrumb();
    this.$store.dispatch("organization/INSURANCE_AUTHORIZATION_LIST").then(
      () => {
        this.show = true;
      },
      () => {
        this.mnotify_error({
          text: this.$t(
            "insuranceauthorization_insurance_selection.error.retrieving_insurances"
          ),
        });
      }
    );
  },
  components: {
    DefaultLayout,
    InsuranceAuthorizationNavigation,
    OrganizationCardList,
    VFirstLoginAuthorizationGuidePopUp,
  },
};
</script>

<style>
</style>
