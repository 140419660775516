<template>
  <div deck class="column-max-5 pl-3 pr-3">
    <div
      class="column-max-item sep-4"
      v-for="(org,index) in items"
      :key="index"
    >
      <b-card
        v-on:click="onItemClick(org)"
        footer-tag="footer"
        class="clickable homecard bd-highlight"
      >
        <div class="imgcontainer mb-0" style="--aspect-ratio:1/1;">
          <img :src="org.logo" alt="Insurance Logo" class="card-img p-2" />
        </div>
        <p class="text-center align-middle m-0 text-truncate" slot="footer" v-if="org.public_name">{{ org.public_name }}</p>
        <p class="text-center align-middle m-0 text-truncate" slot="footer" v-else>{{ org.legal_name }}</p>
      </b-card>
    </div>
  </div>
</template>


<script>
export default {
  name: "organization-card-list",
  props: {
    items: Array,
  },
  methods: {
    onItemClick(item) {
      this.$emit("org:click", item);
    },
  },
};
</script>


<style lang="scss" scoped>
.card {
  height: 100%;
}

@include media-breakpoint-up(ipad) {
  .card-body {
    padding: 0px;
  }
}

@include media-breakpoint-up(max) {
  .card-body {
    padding: 1.5em;
  }
}
</style>
