<template>
  <div class="navbar-wrap navbar-light">
    <b-navbar
      toggleable="md p-0"
      class="d-flex justify-content-around"
      type="dark"
    >
      <b-navbar class="mt-2 d-block d-sm-block d-md-none" variant="faded">
        <a
          @click="$router.go(-1)"
          v-if="!_.includes(['home', 'userhome', 'login'], $route.name)"
        >
          <BackSvg class="back" />
        </a>
      </b-navbar>
      <b-navbar class="mt-2" variant="faded" type="light">
        <b-navbar-brand>
          <router-link :to="{ name: 'home' }">
            <GlobalPDVLogo
              class="logo_medika_xs d-block d-sm-block d-md-none"
            />
          </router-link>
        </b-navbar-brand>
      </b-navbar>
      <b-navbar-toggle
        class="mt-2 navbar-dark"
        target="nav_collapse"
        type="dark"
      ></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto py-0 pl-3">
          <!--
          <b-nav-item href="#" disabled v-if="authenticated">
            <Bell class="nav-item-icon d-inline-block align-top" />
            {{$t('navigation_bar.notifications')}}
          </b-nav-item>
          -->
          <b-nav-item-dropdown
            v-if="available_langs && _.size(available_langs) > 1"
            id="nav-lang-selector"
            toggle-class="nav-link-primary"
            right
          >
            <template slot="button-content">
              <Lang class="nav-item-icon d-inline-block align-top" />
              <em>{{ active_lang.name }}</em>
            </template>
            <b-dropdown-item
              v-for="(lang, index) in available_langs"
              :key="index"
              @click="switchLang(lang)"
            >
              {{ lang.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item :to="{ name: 'account' }" href="#" v-if="authenticated">
            <User class="nav-item-icon d-inline-block align-top" />
            {{ current_username }}
          </b-nav-item>

          <b-nav-item @click="signOut" href="#" v-if="authenticated">
            <Logout class="nav-item-icon d-inline-block align-top" />
            {{ $t("navigation_bar.logout") }}
          </b-nav-item>

          <b-navbar-nav v-if="!authenticated" right>
            <b-nav-item href="#" :to="{ name: 'login' }">{{ $t("navigation_bar.login") }}</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav right class="d-block d-sm-none">
            <b-nav-item target="_blank" :href="helpUrl">{{
              $t("the_heading.need_help")
            }}</b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import Bell from "@/static/nav/bell.svg";
import Lang from "@/static/nav/lang.svg";
import User from "@/static/nav/user.svg";
import Logout from "@/static/nav/logout.svg";
import GlobalPDVLogo from "@/static/global_pdv_logo_white.svg";
import BackSvg from "@/static/back.svg";

export default {
  name: "navigation-bar",
  components: {
    //Bell,
    Lang,
    User,
    Logout,
    GlobalPDVLogo,
    BackSvg,
  },
  data() {
    return {
      activeIndex: null,
      defaultOpeneds: [],
      helpUrl: "https://api.whatsapp.com/send?phone=" + this.$phoneHelp,
    };
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated,
      available_langs: state => state.i18nmap.available_langs,
      active_lang: state => state.i18nmap.active_lang,
      current_username: state => state.auth.current_username,
      current_user_email: state => state.auth.current_user_email,
    }),
  },
  fallbackI18n: {
    messages: {
      und: {
        navigation_bar: {
          notifications: "Notifications",
          language: "Language",
          logout: "Logout",
          login: "Login",
          error: {
            cannot_switch_language: "Cannot switch language",
          },
        },
      },
    },
  },
  methods: {
    switchLang(langpayload) {
      this.$store
        .dispatch("i18nmap/LOAD_PUBLIC_LANGUAGE_TRANSLATIONS", {
          langtag: langpayload.langtag,
        })
        .then(
          () => {
            this.$store.commit("i18nmap/SWITCH_ACTIVE_LANG", {
              langtag: langpayload.langtag,
            });
          },
          err => {
            this.$sentry({ capture: err });
            this.mnotify_error({
              text: this.$t("navigation_bar.error.cannot_switch_language"),
            });
          }
        );
    },
    // handleSelect(key, keyPath) {
    // },
    routeToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    signOut() {
      this.$store.dispatch("auth/LOGOUT").then(
        () => {
          this.$store.commit("auth/LOGOUT");
          this.routeToLogin();
        },
        () => {
          this.$store.commit("auth/LOGOUT");
          this.routeToLogin();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  fill: $white;
  width: 2rem;
  height: 2rem;
}
.navbar-wrap {
  color: $nav-bar-color;
}

.navbar-nav .nav-link {
  color: $nav-bar-color !important;
}

.nav-item a {
  text-decoration: none;
}

.navbar-nav .nav-link.disabled {
  color: $nav-bar-link-disabled-color !important;
}

.nav-item-icon {
  width: $font-size-base * 1.4;
  margin-right: 3pt;
}
.nav-item .nav-link-custom {
  color: #fff !important;
}
.logo_medika_xs {
  height: 4rem;
}
</style>
