<template>
  <VLayout upline grayBackground footerNoMargin>
    <VContactForm class="mb-3" />
  </VLayout>
</template>


<script>
import VLayout from "@/layouts/Default";
import VContactForm from "@/components/ContactForm";

export default {
  components: {
    VLayout,
    VContactForm,
  },
  mounted () {
    this.putBreadcrumb();
  },
  methods: {
    putBreadcrumb () {
      this.$store.commit("siteconf/MUTATE_BREADCRUMB", [
        {
          langkey: "breadcrumb_home",
          to: { name: "home" },
        },
        {
          langkey: "contact_form.title",
          to: { name: "contact" },
        },
      ]);
    },
  },
  fallbackI18n: {
    messages: {
      und: {
        contact_form: {
          title: "Contact",
        },
      },
    },
  },
}
</script>


<style lang="scss">
</style>
